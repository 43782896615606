import React from "react";

// This function is used to display a warning message in the console when a deprecated component or hook is loaded.
export const withDeprecationWarning = ({ name, alt }, component) => {
    return function (props) {
        React.useEffect(() => {
            deprecationWarning({ name, alt });
        }, [name, alt]);
        return component(props);
    };
};

// This function is used to display a warning message in the console about deprecated components, hooks or functions.
export const deprecationWarning = ({ name, alt }) => {
    if (process.env.NODE_ENV !== "development") {
        // Do not show deprecation warnings in production
        return;
    }
    let altType;
    if (`${alt}`.includes("()")) {
        altType = "function";
    } else if (`${alt}`.startsWith("use")) {
        altType = "hook";
    } else {
        altType = "component";
    }
    console.warn(
        `DEPRECATION WARNING: ${name} is deprecated and will be removed in the future.${
            alt ? ` Please use the ${altType} ${alt} instead.` : ""
        }`
    );
};
