import React, { useEffect } from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import { useTranslation } from "react-i18next";
import ShowInstallationCode from "./ShowInstallationCode";
import { useDispatch, useSelector } from "react-redux";
import { cleanSectionContent, setSectionContent } from "../../../actions/sectionActions";
import UseCheckBox from "../../Inputs/useCheckBox";
import UseButton from "./../../../components/useButton";
import GetTVSettings from "../../../hooks/GraphqlCalls/Hotel/Settings/GetTVSettings";
import UseLoading from "../../Table/useLoading";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { Session } from "../../../hooks/Utils/Session";
import { useNavigate as useHistory } from "react-router-dom";

const TVSettings = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //Actions
    const dispatch = useDispatch();

    //Store data
    const actionData = useSelector((state) => state.action.actionData);
    const { permanentSocket, statusReport, contentLoaded } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);

    const SET_TV_STATUS = gql`
        mutation SetTVSetting($statusReportVal: Boolean) {
            setTVSettings(statusReport: $statusReportVal) {
                error
                ok
            }
        }
    `;

    const SET_TV_PERMANENT = gql`
        mutation SetTVSetting($permanentSocketVal: Boolean) {
            setTVSettings(permanentSocket: $permanentSocketVal) {
                error
                ok
            }
        }
    `;

    const [executeMutationStatus, dataStatus] = useMutation(SET_TV_STATUS, {
        onError(err) {
            toast.error(err.message);
        },
    });
    const [executeMutationPermanent, dataPermanent] = useMutation(SET_TV_PERMANENT, {
        onError(err) {
            toast.error(err.message);
        },
    });

    //Listeners

    useEffect(() => {
        return () => {
            dispatch(cleanSectionContent());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (dataStatus && dataStatus.data && dataStatus.data.setTVSettings && dataStatus.data.setTVSettings.ok) {
            toast.success(t("operation-successful"));
        } else if (dataStatus.data && dataStatus.data.setTVSettings && dataStatus.data.setTVSettings.error) {
            toast.success(t("mutation-error"));
            dispatch(setSectionContent({ refreshCode: true }));
        }
        // eslint-disable-next-line
    }, [dataStatus]);

    useEffect(() => {
        if (
            dataPermanent &&
            dataPermanent.data &&
            dataPermanent.data.setTVSettings &&
            dataPermanent.data.setTVSettings.ok
        ) {
            toast.success(t("operation-successful"));
            Session.setSessionProp("tvPermanentSocket", actionData["tv-permanent-socket"]);
        } else if (dataPermanent.data && dataPermanent.data.setTVSettings && dataPermanent.data.setTVSettings.error) {
            toast.success(t("mutation-error"));
            dispatch(setSectionContent({ refreshCode: true }));
        }
        // eslint-disable-next-line
    }, [dataPermanent]);

    //Functions

    const saveTVStatus = () => {
        executeMutationStatus({
            variables: {
                statusReportVal: actionData["tv-mail-status"],
            },
        });
    };
    const saveTVPermanent = () => {
        executeMutationPermanent({
            variables: {
                permanentSocketVal: actionData["tv-permanent-socket"],
            },
        });
    };

    GetTVSettings();

    return (
        <>
            {!contentLoaded ? (
                <UseLoading />
            ) : (
                <>
                    <UseSectionHeader title={"SCREEN-TYPE-TV"} />
                    <div className="grid grid-cols-2 gap-4" /*style={{ gridTemplateColumns: "50% 50%" }}*/>
                        {permissions?.hotel?.TVSettings && (
                            <>
                                <div
                                    id="installationCode_container"
                                    className="min-h-40 bg-white rounded  shadow items-center align-middle text-center py-11"
                                >
                                    {/* ICON */}
                                    <div id="installationCode_icon_circle" className={`networkIcon `}>
                                        <div className=" -mt-2">
                                            <i className={`icon-tv-settings text-4xl m-auto text-white`}></i>
                                        </div>
                                    </div>

                                    {/* TITLE */}
                                    <div
                                        id="installationCode_title"
                                        className="mt-4 font-bold text-gray-900 text-2xl leading-7"
                                    >
                                        {t("{{capitalize, capitalize}}", { capitalize: t("installation-code") })}
                                    </div>
                                    <div id="installationCode_text" className="text-gray-800 leading-6 mt-2">
                                        {t("installation-code-text")}
                                    </div>
                                    <ShowInstallationCode />
                                </div>
                                <div className="min-h-40 bg-white rounded  shadow items-center align-middle text-center py-11">
                                    <div className="w-8/12 mx-auto">
                                        {/* ICON */}
                                        <div id="installationCode_icon_circle" className={`networkIcon `}>
                                            <div className=" -mt-2">
                                                <i className={`icon-messaging-widget text-4xl m-auto text-white`}></i>
                                            </div>
                                        </div>

                                        {/* TITLE */}
                                        <div
                                            id="installationCode_title"
                                            className="mt-4 font-bold text-gray-900 text-2xl leading-7"
                                        >
                                            {t("{{capitalize, capitalize}}", { capitalize: t("tv-report-status") })}
                                        </div>
                                        <div id="installationCode_text" className="text-gray-800 leading-6 mt-2">
                                            {t("tv-report-status-description")}
                                        </div>
                                        <div className={"min-h-4 table mt-6 mx-auto"}>
                                            <UseCheckBox
                                                id="tv-mail-status"
                                                checked={statusReport}
                                                name={t("send-report-tv")}
                                            />
                                        </div>
                                        <div className="w-32 mt-8 mx-auto" onClick={() => saveTVStatus()}>
                                            <UseButton
                                                id={"tv-status-save-button"}
                                                buttonName="save"
                                                buttonColor="btn-blue"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-row-2 min-h-40 bg-white rounded  shadow items-center align-middle text-center py-11">
                                    <div className="w-8/12 mx-auto">
                                        {/* ICON */}
                                        <div id="installationCode_icon_circle" className={`networkIcon `}>
                                            <div className=" -mt-2">
                                                <i className={`icon-tv text-4xl m-auto text-white`}></i>
                                            </div>
                                        </div>

                                        {/* TITLE */}
                                        <div
                                            id="installationCode_title"
                                            className="mt-4 font-bold text-gray-900 text-2xl leading-7"
                                        >
                                            {t("{{capitalize, capitalize}}", { capitalize: t("permanent-socket") })}
                                        </div>

                                        <div className={"min-h-4 table mt-10 mx-auto"}>
                                            <UseCheckBox
                                                id="tv-permanent-socket"
                                                checked={permanentSocket}
                                                name={t("permanent-socket-tooltip")}
                                            />
                                        </div>
                                        <div className="w-32 mt-8 mx-auto" onClick={() => saveTVPermanent()}>
                                            <UseButton
                                                id={"tv-permanent-save-button"}
                                                buttonName="save"
                                                buttonColor="btn-blue"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {permissions.hotel.tvInputs ? (
                            <div className="grid-row-2 min-h-40 bg-white rounded  shadow items-center align-middle text-center py-11">
                                <div className="w-8/12 mx-auto">
                                    {/* ICON */}
                                    <div id="input_groups_icon_circle" className={`networkIcon `}>
                                        <div className=" -mt-2">
                                            <i className={`icon-login text-4xl m-auto text-white`}></i>
                                        </div>
                                    </div>

                                    {/* TITLE */}
                                    <div
                                        id="input_groups_title"
                                        className="mt-4 font-bold text-gray-900 text-2xl leading-7"
                                    >
                                        {t("{{capitalize, capitalize}}", { capitalize: t("tv-input-groups") })}
                                    </div>

                                    <div className="text-gray-800 leading-6 mt-2">{t("manage-tv-inputs")}.</div>

                                    <div
                                        id="input_groups_btn"
                                        className="w-60 mt-8 mx-auto"
                                        onClick={() => history("/hotel/property-settings/tv/inputs")}
                                    >
                                        <UseButton buttonName="manage-inputs-groups" buttonColor="btn-blue" />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
};
export default TVSettings;
