import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import Select from "components/Select";
import { useLazyQuery } from "react-apollo";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import currenciesVendure from "../../../../../hooks/Utils/Integrations/vendure-currencies.json";
import { getSymbolCurrency } from "hooks/Utils/SalesUtils";
import { gql } from "apollo-boost";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import { toast } from "react-toastify";
import Button from "components/Button";
import { useMutation } from "react-apollo";
import { setRefreshContentData } from "actions/sectionActions";
import Loading from "components/Loading";

const GET_DATA_USERS = gql(`query{
    users{
        results{
        id
        email
        managerRole 
        ref
        }
    }
  }`);

const GET_TAX_INFO = `{
    zones {
      id
      name
    }
    taxRates {
      items {
        id
        name
        enabled
        value
        category{
            id
        }
        zone{
            id
        }
      }
    }
  }
  `;

const CREATE_SHOP_MUTATION = gql`
    mutation createShop(
        $name: [TranslateString!]!
        $languageCode: String!
        $pricesIncludeTax: Boolean!
        $currencyCode: String!
        $defaultShippingZoneID: Int!
        $defaultTaxZoneID: Int!
        $users: [Int!]
        $customFields: CreateCustomFields!
    ) {
        createShop(
            name: $name
            languageCode: $languageCode
            pricesIncludeTax: $pricesIncludeTax
            currencyCode: $currencyCode
            defaultShippingZoneID: $defaultShippingZoneID
            defaultTaxZoneID: $defaultTaxZoneID
            users: $users
            customFields: $customFields
        ) {
            id
            name
            token
            users
        }
    }
`;

const useMutationCreateShop = ({ closeModal }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [createShop, { loading }] = useMutation(CREATE_SHOP_MUTATION, {
        onCompleted: () => {
            toast.success(t("operation-successful"));
            dispatch(setRefreshContentData(true));
            closeModal();
        },
        onError: (err) => {
            toast.error(err);
            closeModal();
        },
    });

    return {
        createShop,
        loading,
    };
};
const useGetDataUsersTaxInfo = () => {
    const { projectLangs, permissions } = useSelector((state) => state.ui);

    const langDefault = projectLangs ? projectLangs.find((lang) => lang.isDefault === true)?.languageRef : null;
    const hasOrdersManagement = permissions?.services?.shopsOrders;

    const [loading, setLoading] = useState(true);
    const [dataUsersSelect, setDataUsersSelect] = useState([]);
    const [dataCurrenciesSelect] = useState(arrangeCurrenciesData(currenciesVendure, langDefault));
    const [dataTaxesZoneSelect, setDataTaxesZoneSelect] = useState([]);
    const [dataTaxesRatesSelect, setDataTaxesRatesSelect] = useState([]);
    const [dataTaxesDefault, setDataTaxesDefault] = useState([]);

    const [executeQuery, { data: dataUsers }] = useLazyQuery(GET_DATA_USERS);

    const fetchTaxInfo = async () => {
        try {
            const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_TAX_INFO });
            if (response?.data) {
                const zones = response.data.zones || [];
                const taxRatesItems = response.data.taxRates?.items || [];

                const defaultZoneId = getDefaultZoneId(zones);
                setDataTaxesDefault(defaultZoneId);

                setDataTaxesZoneSelect(arrangeDataTaxesZone(zones));
                setDataTaxesRatesSelect(taxRatesItems);
            }
        } catch (error) {
            toast.error("Error: ", error);
        }
    };

    useEffect(() => {
        if (dataUsers?.users?.results) {
            const arrangedUsers = arrangeDataUsers(dataUsers.users.results);
            setDataUsersSelect(arrangedUsers);
        }
    }, [dataUsers]);

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            try {
                await Promise.all([executeQuery(), fetchTaxInfo()]);
            } catch (error) {
                toast.error("Error: ", error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [executeQuery]);

    return {
        loading,
        dataUsersSelect,
        langDefault,
        dataCurrenciesSelect,
        dataTaxesZoneSelect,
        dataTaxesRatesSelect,
        dataTaxesDefault,
        hasOrdersManagement,
    };
};

export const ModalContent = ({ close }) => {
    const { t } = useTranslation();

    const [currencySelected, setCurrencySelected] = useState("EUR");
    const [usersSelected, setUsersSelected] = useState([]);
    const [taxZoneSelected, setTaxZoneSelected] = useState(null);
    const [availableTaxesSelected, setAvailableTaxesSelected] = useState(null);
    const [availableTaxes, setAvailableTaxes] = useState(null);
    const [taxCategorySelected, setTaxCategorySelected] = useState(null);
    const [taxRateSelected, setTaxRateSelected] = useState(null);
    const [shopName, setShopName] = useState("");
    const [requiredFields, setRequiredFields] = useState({ name: false });

    const {
        loading,
        dataUsersSelect,
        dataCurrenciesSelect,
        dataTaxesZoneSelect,
        dataTaxesRatesSelect,
        langDefault,
        hasOrdersManagement,
    } = useGetDataUsersTaxInfo();

    const { createShop, loading: loadingMutation } = useMutationCreateShop({ closeModal: close });

    const showLoading = loading || loadingMutation;

    useEffect(() => {
        if (taxZoneSelected) {
            setAvailableTaxes(arrangeDataTaxesRate(taxZoneSelected, dataTaxesRatesSelect));

            if (arrangeDataTaxesRate(taxZoneSelected, dataTaxesRatesSelect)?.length > 0) {
                setAvailableTaxesSelected(arrangeDataTaxesRate(taxZoneSelected, dataTaxesRatesSelect)[0].id);
            }
        }
    }, [taxZoneSelected]);

    useEffect(() => {
        if (dataTaxesZoneSelect?.length > 0) {
            setTaxZoneSelected(dataTaxesZoneSelect[0]?.id);
        }
    }, [dataTaxesZoneSelect]);

    useEffect(() => {
        if (taxZoneSelected) {
            setTaxCategorySelected(findAvailableTaxesDataById(availableTaxes, availableTaxesSelected)?.category?.id);
            setTaxRateSelected(findAvailableTaxesDataById(availableTaxes, availableTaxesSelected)?.value);
        }
    }, [availableTaxes]);

    const handleCreateShop = () => {
        validateAndCreateShop({
            shopName,
            hasOrdersManagement,
            usersSelected,
            langDefault,
            currencySelected,
            taxZoneSelected,
            availableTaxesSelected,
            taxCategorySelected,
            taxRateSelected,
            createShop,
            setRequiredFields,
            requiredFields,
            t,
        });
    };

    return (
        <Modal
            footer={
                !showLoading ? (
                    <>
                        <Button
                            design="blue-outline"
                            onClick={() => {
                                close();
                            }}
                            id="modal-button-cancel"
                        >
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-save"
                            onClick={() => {
                                handleCreateShop();
                            }}
                        >
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            minWidth="30vw"
            title={t("add-shop")}
        >
            {!showLoading ? (
                <>
                    <div className=" font-bold">{`${t("name")}*`}</div>
                    <div className=" flex items-center space-x-2">
                        <div className=" w-auto whitespace-no-wrap">
                            {t(`language:${langDefault ? langDefault : "en"}`) + ` (${t("default-language")})`}
                        </div>
                        <div className=" flex w-full  ">
                            <TextInput
                                id={"add-shop-name"}
                                value={shopName}
                                onChange={(val) => setShopName(val)}
                                className={`flex-grow w-full ${
                                    requiredFields.name ? " border border-red-100" : "border border-transparent"
                                } `}
                            />
                        </div>
                    </div>

                    {hasOrdersManagement ? (
                        <>
                            <div className=" mt-4 font-bold">{`${t("assigned-users")}`}</div>
                            <div className=" mb-2 ">{`${t("assign-users-so-manage-orders")}`}</div>
                            <div className={`flex items-center rounded`}>
                                <Select
                                    id={"select-shop-assigned-users"}
                                    allowUnselect={true}
                                    options={dataUsersSelect}
                                    multiple={true}
                                    onChange={(value) => setUsersSelected(value)}
                                    search={true}
                                />
                            </div>
                        </>
                    ) : null}

                    <div className=" font-bold mt-5">{`${t("currency")}*`}</div>
                    <div className=" flex items-center mt-2 space-x-2">
                        <Select
                            key={dataCurrenciesSelect}
                            id={"select-shop-currency"}
                            allowUnselect={false}
                            options={dataCurrenciesSelect}
                            value={currencySelected}
                            onChange={(value) => setCurrencySelected(value)}
                            search={true}
                        />
                    </div>

                    <div className=" font-bold mt-5">{`${t("taxes-zone")}*`}</div>
                    <div className=" flex items-center mt-2 space-x-2">
                        <Select
                            id={"select-shop-taxes-zone"}
                            allowUnselect={false}
                            options={dataTaxesZoneSelect}
                            value={taxZoneSelected}
                            onChange={(value) => setTaxZoneSelected(value)}
                            search={true}
                        />
                    </div>
                    {availableTaxes?.length > 0 ? (
                        <div className=" mb-2 mt-4">{t("available-taxes-select-the-default-taxes") + ":"}</div>
                    ) : null}
                    <div className=" mb-4" style={{ minHeight: "10rem", maxHeight: "10rem", overflow: "scroll" }}>
                        {availableTaxes?.map((tax, index) => {
                            return (
                                <div key={tax.id} className=" mb-3">
                                    <Radiobutton
                                        id={"select-shop-available-tax-" + index}
                                        label={tax.name + ": " + tax.value + "%"}
                                        value={tax.id}
                                        checked={tax.id === availableTaxesSelected}
                                        onChange={({ value }) => {
                                            setAvailableTaxesSelected(value);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

export const useSalesModalAddShop = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};

const arrangeCurrenciesData = (data = {}, lang) => {
    return (data.currencies ? Object.keys(data.currencies) : []).map((val) => ({
        id: data.currencies[val].code,
        value: data.currencies[val].code,
        label: `${getSymbolCurrency({ currency_code: data.currencies[val].code })} ${data.currencies[val][lang]}`,
    }));
};

const arrangeDataTaxesZone = (data = []) => {
    return data.map((element) => ({
        id: element.id,
        value: element.id,
        label: element.name,
    }));
};

const arrangeDataUsers = (data = []) => {
    return data
        .filter((item) => !item.managerRole && !item.ref.startsWith("superuser"))
        .map(({ id, email }) => ({
            label: email,
            value: id,
            id: id,
        }));
};

const getDefaultZoneId = (zones) => {
    if (zones instanceof Array && zones.length > 0 && zones[0]?.id) {
        return zones[0].id;
    }
    return "0";
};

const findAvailableTaxesDataById = (availableTaxes = [], id) => {
    const tax = availableTaxes.find((item) => item.id === id);
    return tax;
};

const arrangeDataTaxesRate = (id, data = []) => {
    return data.filter((item) => item.zone?.id && Number(item.zone.id) === Number(id));
};

const validateAndCreateShop = ({
    shopName,
    hasOrdersManagement,
    usersSelected,
    langDefault,
    currencySelected,
    taxZoneSelected,
    availableTaxesSelected,
    taxCategorySelected,
    taxRateSelected,
    createShop,
    setRequiredFields,
    requiredFields,
    t,
}) => {
    const isShopNameValid = shopName?.trim() !== "";

    if (isShopNameValid) {
        createShop({
            variables: {
                name: [{ lang: langDefault, text: shopName }],
                languageCode: langDefault,
                pricesIncludeTax: false,
                currencyCode: currencySelected,
                defaultShippingZoneID: Number(taxZoneSelected),
                defaultTaxZoneID: Number(taxZoneSelected),
                users: hasOrdersManagement ? usersSelected : null,
                customFields: {
                    defaultTaxID: Number(availableTaxesSelected),
                    defaultTaxCategoryID: Number(taxCategorySelected),
                    autoAcceptOrders: hasOrdersManagement ? true : null,
                    shippingConfig: JSON.stringify({
                        pickup: {
                            price: {
                                base: 0,
                                taxRate: taxRateSelected ? taxRateSelected : 0,
                                includesTax: true,
                            },
                            enabled: false,
                        },
                        delivery: {
                            price: {
                                base: 0,
                                taxRate: taxRateSelected ? taxRateSelected : 0,
                                includesTax: true,
                            },
                            enabled: true,
                        },
                    }),
                },
            },
        });
    } else {
        setRequiredFields({
            ...requiredFields,
            name: !isShopNameValid,
        });
        toast.error(t("input error"));
    }
};
