import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { executeVendureQuery, getVendureApiData } from "../../../../../../hooks/Utils/Integrations/useVendure";
import { Session } from "../../../../../../hooks/Utils/Session";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import UseCompactTable from "../../../../../UseCompactTable";
import UseLoading from "../../../../../Table/useLoading";
import { setRefreshData } from "../../../../../../actions/tableActions";
import UseSelectMultipleWithSearchOptionsQuery from "../../../../../useSelectMultipleWithSearchOptionsQuery";
import { getProjectLangs } from "../../../../../../hooks/Utils/SalesUtils";
import { useParams } from "react-router-dom";
import { getProducts } from "./utils";
import { SalesContextDeprecated } from "contexts/Sales";

const ProductsCard = ({ facetCategory, parentsFacets, infoCategory, collectionParentData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { idCat, id } = useParams();
    const { projectLangs } = useSelector((state) => state.ui);
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const { values } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.table);
    const EDIT_SHOP_CATEGORIES_PRODUCTS = "edit-shop-categories-select-products";
    const [errorInput, setErrorInput] = useState(false);
    const [dataProducts, setDataProducts] = useState([]);
    const [dataProductsFacet, setDataProductsFacet] = useState([]);
    const [langDefault, setLangDefault] = useState("en");

    const [loading, setLoading] = useState(true);
    const productsIdsSelected =
        values && values["edit-shop-categories-select-products"]
            ? values["edit-shop-categories-select-products"]
            : null;
    const storedCheckedProducts =
        values && values["products-categories-checkedItames"] ? values["products-categories-checkedItames"] : [];

    const setterData = (data) => {
        setDataProducts(arrangeDataProducts(data.products.items, langDefault, facetCategory, true));
        setDataProductsFacet(arrangeDataProducts(data.products.items, langDefault, facetCategory, false));
        Session.setSessionProp(
            "vendure-categories-products",
            JSON.stringify(arrangeDataProducts(data.products.items, langDefault, facetCategory, false))
        );
        setLoading(false);
    };

    useEffect(() => {
        if (dataContext.products.items && dataContext.collection) {
            setterData(dataContext);
        }
    }, [dataContext]);

    const arrangeDataProducts = (productsArray, langCode, excludeFacetId, exclude) => {
        const findTranslation = (translations, langCode, fallbackLang = "en") => {
            const translation =
                translations.find((t) => t.languageCode === langCode) ||
                translations.find((t) => t.languageCode === fallbackLang) ||
                translations[0];

            return translation ? translation.name : null;
        };

        const getCategoryFacetValues = (facetValues) => {
            return facetValues
                .filter((facetValue) => {
                    const facetName = facetValue.facet.name.toLowerCase();
                    const isCategory = facetName === "categories" || facetName === "categorías";
                    return isCategory;
                })
                .map((facetValue) => facetValue.id);
        };

        const shouldExcludeProduct = (categoryFacetValues, excludeFacetId) => {
            return categoryFacetValues.includes(excludeFacetId);
        };

        const getOtherFacetValues = (facetValues) => {
            return facetValues
                .filter((facetValue) => {
                    const facetName = facetValue.facet.name.toLowerCase();
                    const isNotCategory = facetName !== "categories" && facetName !== "categorías";
                    return isNotCategory;
                })
                .map((facetValue) => facetValue.id);
        };

        return productsArray
            .map((product) => {
                const productName = findTranslation(product.translations, langCode);
                const categoryFacetValues = getCategoryFacetValues(product.facetValues);
                const otherFacetValues = getOtherFacetValues(product.facetValues);
                return {
                    value: product.id,
                    name: productName,
                    enabled: product?.enabled ? true : false,
                    link: `/services/sales/shop/${id}/product/${product.id}`,
                    id: product.id,
                    text: productName,
                    categories: categoryFacetValues,
                    otherFacetValuesSelected: otherFacetValues,
                    collections: product?.collections ? product.collections : null,
                };
            })
            .filter((product) =>
                exclude
                    ? !shouldExcludeProduct(product.categories, excludeFacetId)
                    : shouldExcludeProduct(product.categories, excludeFacetId)
            );
    };

    const findProductById = (arrangedProducts, productId) => {
        const foundProduct = arrangedProducts.find((product) => product.value === productId);
        return foundProduct || null;
    };

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        setLangDefault(getProjectLangs(projectLangs, t).langDefault);
    }, [projectLangs]);

    return (
        <div className=" p-6 w-full shadow-sm relative pb-40">
            <div className=" text-xl font-bold mb-4 w-full ">{t("products")}</div>
            <div className=" my-8">
                {t("add-or-set-at-least-one-product-as-available-to-display-the-category-in-the-interface")}
            </div>
            <div className=" flex">
                <div className=" w-1/2 mr-4 ">
                    <div className=" mb-4 "> {t("select-the-products-you-want-to-add")} </div>
                    <div className=" w-3/4">
                        <div className={`border rounded ${!errorInput ? "border-transparent" : " border-red-500"}`}>
                            <UseSelectMultipleWithSearchOptionsQuery
                                dataSelect={{
                                    name: EDIT_SHOP_CATEGORIES_PRODUCTS,
                                    selectPlaceHolder: "select-products",
                                    optionData: dataProducts,
                                    id: "selector-products-to-add-category",
                                }}
                                key={dataProducts}
                            />
                        </div>
                        <span
                            id="products-card-category-add-products-button"
                            onClick={() => {
                                if (productsIdsSelected && productsIdsSelected.length > 0) {
                                    setLoading(true);
                                    const dataProductsArr = [];
                                    productsIdsSelected.forEach((id) => {
                                        dataProductsArr.push({
                                            idProduct: findProductById(dataProducts, id).value,
                                            categories: findProductById(dataProducts, id).categories,
                                            otherFacetValuesSelected: findProductById(dataProducts, id)
                                                .otherFacetValuesSelected,
                                            idCategory: facetCategory,
                                            idCategoriesParents: parentsFacets,
                                        });
                                    });
                                    dispatch(
                                        setExecuteQuery({
                                            action: "add-category-to-product",
                                            params: {
                                                dataProductsArr: dataProductsArr,
                                            },
                                        })
                                    );
                                    setErrorInput(false);
                                } else {
                                    setErrorInput(true);
                                    toast.error(t("input error"));
                                }
                            }}
                            className=" cursor-pointer rounded block text-white bg-zafiro-600 py-2 mt-14 text-center "
                        >
                            {capitalizeFirst(t("add"))}
                        </span>
                    </div>
                </div>
                <div className=" w-1/2 ml-4 pl-6 pr-10 ">
                    <UseCompactTable
                        data={dataProductsFacet}
                        countName="products"
                        columnName="products"
                        tableName="products-categories"
                        bottomText="delete-selected-products"
                        emptyList="no-products-yet"
                        infoStateCell={true}
                        filterCheckedItems={true}
                        actionButton={(id) => {
                            setLoading(true);
                            const data = findProductById(dataProductsFacet, id);
                            const rootCollection = infoCategory?.rootCollection ? infoCategory.rootCollection : null;
                            const collectionsBreadcrumbs = mergeBreadcrumbs(collectionParentData, rootCollection);
                            const collectionsBreadcrumbsUpdate = addMissingBreadcrumbs(
                                data.collections,
                                collectionsBreadcrumbs
                            );

                            const uniqueCategoriesIds = findUniqueCategoriesPathIds(
                                collectionsBreadcrumbsUpdate,
                                idCat
                            );
                            const facetsToDelete = getFacetValueIdsByIds(collectionParentData, uniqueCategoriesIds);

                            dispatch(
                                setExecuteQuery({
                                    action: "delete-category-from-product",
                                    params: {
                                        idProduct: id,
                                        categories: data.categories,
                                        idCategory: facetCategory,
                                        otherFacetValuesSelected: data.otherFacetValuesSelected,
                                        facetsToDelete: facetsToDelete,
                                    },
                                })
                            );
                        }}
                        actionBottomButton={(checkedItems, finalData) => {
                            setLoading(true);
                            const dataProductsChecked = [];
                            storedCheckedProducts.forEach((id) => {
                                const objData = findProductById(dataProductsFacet, id);

                                dataProductsChecked.push({
                                    ...objData,
                                    idCategory: facetCategory,
                                });
                            });
                            const filteredObjects = finalData
                                .filter((obj) => checkedItems.some((id) => obj.id === id))
                                .map((obj) => {
                                    const data = findProductById(dataProductsFacet, obj.id);
                                    const rootCollection = infoCategory?.rootCollection
                                        ? infoCategory.rootCollection
                                        : null;
                                    const collectionsBreadcrumbs = mergeBreadcrumbs(
                                        collectionParentData,
                                        rootCollection
                                    );
                                    const collectionsBreadcrumbsUpdate = addMissingBreadcrumbs(
                                        obj.collections,
                                        collectionsBreadcrumbs
                                    );
                                    const uniqueCategoriesIds = findUniqueCategoriesPathIds(
                                        collectionsBreadcrumbsUpdate,
                                        idCat
                                    );
                                    const facetsToDelete = getFacetValueIdsByIds(
                                        collectionParentData,
                                        uniqueCategoriesIds
                                    );

                                    return {
                                        ...obj,
                                        idCategory: facetCategory,
                                        facetsToDelete: facetsToDelete,
                                        categories: data.categories,
                                    };
                                });

                            dispatch(
                                setExecuteQuery({
                                    action: "delete-category-from-product-batch",
                                    params: {
                                        itemsData: filteredObjects,
                                    },
                                })
                            );
                        }}
                    />
                </div>
            </div>
            {loading ? (
                <div className=" w-full absolute top-0 left-0 opacity-70 h-full flex items-center justify-center bg-white rounded">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default ProductsCard;

function findUniqueCategoriesPathIds(collectionsArray, searchId) {
    const collectionsWithoutRootCollection = deleteRootCollection(collectionsArray);

    const objIdsCount = {};

    findCollectionBreaadcrumbArray(collectionsWithoutRootCollection, searchId)
        .map((b) => b.id)
        .reverse()
        .forEach((id, index) => {
            objIdsCount[id] = index + 1;
        });

    return UniqueBreadcrumbsCategories(collectionsWithoutRootCollection, objIdsCount);
}

function deleteRootCollection(rootCollectionArray) {
    return rootCollectionArray.map((item) => {
        return {
            ...item,
            breadcrumbs: item.breadcrumbs.filter((bc) => bc.slug !== "__root_collection__"),
        };
    });
}

function findCollectionBreaadcrumbArray(array, searchId) {
    for (const elemento of array) {
        if (elemento.breadcrumbs.some((breadcrumb) => breadcrumb.id === searchId)) {
            return elemento.breadcrumbs;
        }
    }
    return [];
}
function UniqueBreadcrumbsCategories(array, expectedCount) {
    let realCount = {};
    let idsCoincident = [];

    array.forEach((item) => {
        item.breadcrumbs.forEach((breadcrumb) => {
            realCount[breadcrumb.id] = (realCount[breadcrumb.id] || 0) + 1;
        });
    });

    for (const id in expectedCount) {
        if (realCount[id] === expectedCount[id]) {
            idsCoincident.push(id);
        }
    }

    return idsCoincident;
}

function getFacetValueIdsByIds(array, idsToFind) {
    let results = [];

    function searchRecursively(arr, ids) {
        arr.forEach((item) => {
            if (ids.includes(item.id) && item.filters) {
                item.filters.forEach((filter) => {
                    filter.args.forEach((arg) => {
                        if (arg.name === "facetValueIds") {
                            let parsedValue = JSON.parse(arg.value.replace(/[\[\]"]+/g, ""));
                            results = results.concat(parsedValue);
                        }
                    });
                });
            }
            if (item.children && item.children.length > 0) {
                searchRecursively(item.children, ids);
            }
        });
    }

    searchRecursively(array, idsToFind);
    return results;
}

function mergeBreadcrumbs(collectionParentData, rootCollection) {
    const collectionParentDataCopy = [];
    const breadcrumbsData = [];
    if (rootCollection) {
        collectionParentDataCopy.push(rootCollection);
    }
    collectionParentData.forEach((el) => {
        collectionParentDataCopy.push({ id: el.id, slug: el.slug, name: el.name });
    });
    const collectionParentDataCopyLength = collectionParentDataCopy.length;
    for (let i = 0; i < collectionParentDataCopyLength; i++) {
        const tempArr = { breadcrumbs: [] };
        for (let j = 0; j < i + 1; j++) {
            if (collectionParentDataCopy[j] && i !== 0) {
                tempArr.breadcrumbs.push(collectionParentDataCopy[j]);
            }
        }
        if (i !== 0) {
            breadcrumbsData.push(tempArr);
        }
    }

    return breadcrumbsData;
}

function addMissingBreadcrumbs(originalArray, newArray) {
    const breadcrumbsMap = new Map();

    originalArray.forEach((item) => {
        const breadcrumbsKey = item.breadcrumbs.map((crumb) => crumb.id).join("-");
        breadcrumbsMap.set(breadcrumbsKey, item);
    });

    newArray.forEach((item) => {
        const breadcrumbsKey = item.breadcrumbs.map((crumb) => crumb.id).join("-");
        if (!breadcrumbsMap.has(breadcrumbsKey)) {
            originalArray.push(item);
            breadcrumbsMap.set(breadcrumbsKey, item);
        }
    });

    return originalArray;
}
