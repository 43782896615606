import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
//Actions
import { openModal, setModalContent } from "../../../actions/uiActions";
import { changeGlobalAction } from "../../../actions/globalActions";
import { clearProjectRef } from "../../../actions/hotelActions";
import { setToken } from "../../../actions/authActions";
//Utils
import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";

import PasswordReminderModal from "./Modals/PasswordReminderModal";
import UpdatePassword from "../../../hooks/GraphqlCalls/Hotel/UpdatePassword";
import { Session } from "../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";

const Login = () => {
    const { t, i18n } = useTranslation();
    //Data
    const isLoggedIn = Session.getSessionProp("token");
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");
    const history = useHistory();
    // eslint-disable-next-line
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    //States
    const [emailValidated, setEmailValidated] = useState(true);
    const [passwordValidated, setPasswordValidated] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [showContent, setShowContent] = useState(false);

    //API Call

    const MUTATION = gql`
  mutation {
    login(
      user: "${user}",
      pass: "${pass}",
      lang:  "${i18n.language.split("-")[0]}"
    ) { chainId chainName chainRef chainAppURL cloudManagerURL managerLogo error isOk token isSuperuser userID userRef userFullname userManagerRole hasCorporateUser userTOSAccepted warnings { type message } userAdvicedUpdate projects{ id location name timeZone keyBrand ref managerURL managerUsers blockGuestManagement code supportPhone supportEmail zMobileURL }}}`;

    const [loginAttempt, { data }] = useMutation(MUTATION, {
        onError({ graphQLErrors }) {
            if (graphQLErrors) {
                graphQLErrors.map((gError) => toast.error(t("invalid email or password")));
            }
        },
    });

    //Actions
    const dispatch = useDispatch();
    const loginHandler = (e) => {
        if (e && e.key === "Enter") {
            return;
        }
        //Validate Email
        let emailValidation = true;

        if (!user || (user && !user.match(mailformat))) {
            emailValidation = false;
        }
        setEmailValidated(emailValidation);

        //Validate pass
        let passwordValidation = true;
        if (!pass || (pass && pass.length !== 32)) {
            passwordValidation = false;
        }
        setPasswordValidated(passwordValidation);

        if (emailValidation && passwordValidation) {
            loginAttempt();
        } else {
            if (!emailValidation) {
                toast.error(t("invalid email"));
            }
            if (!passwordValidation) {
                toast.error(t("invalid password"));
            }
        }
    };

    const changeEmailHandler = (e) => {
        setUser(e.target.value);
    };

    const changePassHandler = (e) => {
        if (e.target.value.length < 3) {
            setPasswordValidated(false);
        } else {
            let md5 = require("md5");
            setPass(md5(e.target.value));
        }
    };

    useEffect(() => {
        if (data?.login?.isOk) {
            const isSuperUser = data.login.isSuperuser;
            const isCorporateUser = data.login.hasCorporateUser;
            const isRegularUser = !isSuperUser && !isCorporateUser;
            const requireTOS = data.login.userManagerRole && !data.login.userTOSAccepted;
            const userToken = data.login.token;

            Session.login({
                user: {
                    id: data.login.userID,
                    ref: data.login.userRef,
                    pass: pass,
                    fullname: data.login.userFullname,
                    superUser: isSuperUser,
                    email: user,
                    role: data.login.userManagerRole,
                    corporate: isCorporateUser,
                    tos: data.login.userTOSAccepted,
                    userAdvicedUpdate: data.login.userAdvicedUpdate,
                    warnings: data.login.warnings,
                },
                chain: {
                    id: data.login.chainId,
                    name: data.login.chainName,
                    ref: data.login.chainRef,
                    app: data.login.chainAppURL,
                    manager: data.login.cloudManagerURL,
                    logo: data.login.managerLogo,
                },
                projects: getProjects(data.login.projects, isCorporateUser, t),
            });

            if (requireTOS) {
                if (isRegularUser) {
                    Session.setSessionProp("tokentmp", userToken);
                }
                window.location.href = "#/policy";
            } else {
                Session.setToken(userToken);
                dispatch(setToken(userToken));
                if (isSuperUser) {
                    Session.setSessionProp(
                        "superUserData",
                        JSON.stringify({
                            token: userToken,
                            userFullname: data.login.userFullname,
                            email: user,
                            recovery: pass,
                        })
                    );
                    history("/select-profile");
                } else if (isCorporateUser || data.login.projects.length > 1) {
                    history("/select-project");
                } else if (data.login.projects.length > 0) {
                    // TODO if there is only one project, select it automatically
                    history("/select-project");
                } else {
                    toast.error(t("not found projects"));
                }
            }
        } else if (data?.login?.error) {
            toast.error(t("invalid email or password"));
            console.error(data?.login?.error);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (isLoggedIn) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [isLoggedIn]);

    const passwordReminder = () => {
        dispatch(
            changeGlobalAction({
                actionName: "send-recovery-email",
            })
        );
        dispatch(setModalContent(PasswordReminderModal()));
        //Launch modal
        dispatch(openModal());
    };

    const getValidationStyle = (status) => {
        let res = "";
        if (!status) {
            res = "border border-red-100";
        }
        return res;
    };

    const keyDownHandler = (e) => {
        if (e.key === "Enter" && ["email", "password"].includes(e.target.type)) {
            loginHandler({ key: "login" });
        }
    };
    const redirectToLogin = () => {
        history("/");
        return true;
    };

    //Listeners
    useEffect(() => {
        // ensure the session is closed
        Session.close();
        dispatch(clearProjectRef());
        setShowContent(true);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {showContent ? (
                <>
                    {!isAuthenticated ? (
                        <>
                            <UpdatePassword />
                            <div className="absolute top-0 left-0 w-screen h-screen flex items-center justify-center bg-white z-201">
                                <div className="p-8 rounded bg-white w-auto">
                                    <span className="mx-auto">
                                        <div
                                            className="mx-auto -ml-4 mb-4"
                                            style={{
                                                background: `url('${process.env.PUBLIC_URL}/assets/images/logotipo-ZAFIROCLOUD.svg')`,
                                                width: "235px",
                                                height: "92px",
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center center",
                                            }}
                                            alt="Logo"
                                        />
                                    </span>
                                    <div className="w-full text-gray-900 my-8 text-2xl text-center font-bold first-capital">
                                        <h2>{t("welcome to zafiro")}</h2>
                                    </div>
                                    <input
                                        type="email"
                                        className={`t-filter-input w-full p-2 mb-4 block ${getValidationStyle(
                                            emailValidated
                                        )}`}
                                        autoFocus={true}
                                        placeholder={t("email")}
                                        onChange={changeEmailHandler}
                                        onKeyDown={keyDownHandler}
                                        id="email-input"
                                    ></input>
                                    <input
                                        type="password"
                                        className={`t-filter-input w-full p-2 mb-4 ${getValidationStyle(
                                            passwordValidated
                                        )}`}
                                        placeholder={t("password")}
                                        onChange={changePassHandler}
                                        onKeyDown={keyDownHandler}
                                        id="password-input"
                                    ></input>
                                    <button
                                        type="submit"
                                        className="btn-blue mt-8 w-full "
                                        onClick={loginHandler}
                                        onKeyDown={keyDownHandler}
                                        id="access-button"
                                    >
                                        <div className="first-capital">{t("access")}</div>
                                    </button>
                                    <div
                                        className="font-bold text-blue-300 text-center mt-4 cursor-pointer hover:text-blue-200"
                                        onClick={passwordReminder}
                                        id="pass-reminder-button"
                                    >
                                        {t("pass-reminder")}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div onClick={redirectToLogin()}></div>
                    )}
                </>
            ) : null}
        </>
    );
};

const getProjects = (projects, hasCorporateUser, t) => {
    projects.sort((a, b) => String(a.name).localeCompare(String(b.name)));
    projects.map((project) => {
        project.hasMinimalPermissions = true;
    });
    //CORPORATE PROJECT
    if (hasCorporateUser) {
        projects.unshift({
            name: t("global-management"),
            id: 0,
            location: t("global-management-desc"),
            ref: "CORPORATE",
            supportEmail: "zafiro@zafiro.tv",
            supportPhone: "1234",
            timeZone: "UTC",
            global: true,
        });
    }
    return projects;
};

export default Login;
