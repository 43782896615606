import React, { useEffect, useState } from "react";
import { withApollo } from "@apollo/react-hoc";

//Actions

//UTils
import { CSVLink } from "react-csv";
import _ from "lodash";
import { showGlobalLoading } from "actions/uiActions";
import { capitalizeFirst, formatPrice } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { formatDate } from "hooks/Utils/Services/OrdersUtils";

const ExportMovieCharges = ({ startDataExport, setStartDataExport, pagedQuery }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Variables.
    const d = new window.ZDate();
    const exportFileName = `movie_charges_${d.getDate()}_${d.getMonth() + 1}_${d.getFullYear()}.csv`;

    //States.
    const [exportHeaders, setExportHeaders] = useState([]);
    const [exportData, setExportData] = useState([]);

    //remove size and page from query to get all results
    const GET_WATCHED_MOVIES = pagedQuery?.loc?.source?.body
        ?.replace(/(size:\d+,|size: \d+,)/, "")
        .replace(/(page:\d+,|page: \d+,)/, "");
    const [getChargesData, { data }] = useLazyQuery(
        gql`
            ${GET_WATCHED_MOVIES}
        `,
        {
            fetchPolicy: "network-only",
            onError: (err) => {
                dispatch(showGlobalLoading(false));
                toast.error(t("error-mutation"));
                console.log(err);
            },
        }
    );

    useEffect(() => {
        if (startDataExport) {
            dispatch(showGlobalLoading(true));
            getChargesData();
        }
        // eslint-disable-next-line
    }, [startDataExport]);

    useEffect(() => {
        if (startDataExport && data?.charges?.results) {
            setStartDataExport(false);
            generateExportData(data?.charges?.results);
        } else {
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    const generateExportData = (charges) => {
        let rows = [];
        let totalPrice = 0;
        setExportHeaders([
            { label: capitalizeFirst(t("date")), key: "date" },
            { label: capitalizeFirst(t("order")), key: "order" },
            { label: capitalizeFirst(t("type")), key: "type" },
            { label: capitalizeFirst(t("room")), key: "room" },
            { label: capitalizeFirst(t("price")), key: "price" },
        ]);

        charges.forEach((charge) => {
            const dateText = `${formatDate(new Date(charge.createdAt?.slice(0, -1)), "ddMMyyyy")} ${formatDate(
                new Date(charge.createdAt?.slice(0, -1)),
                "time"
            )}`;
            if (!charge.canceledAt) {
                totalPrice = totalPrice + (charge.totalPriceAfterTaxes && parseFloat(charge.totalPriceAfterTaxes));
            }
            rows.push({
                date: dateText,
                order: charge?.detailInfo?.name || "",
                type: t("movie"),
                room: charge?.roomNumber,
                price: charge.canceledAt
                    ? "REVOKED"
                    : charge.totalPriceAfterTaxes
                    ? formatPrice(parseFloat(charge.totalPriceAfterTaxes), "EUR")
                    : "",
            });
        });
        //Add total price row
        rows.push({ date: "", order: "", type: "", room: "", price: "" });
        rows.push({
            date: "",
            order: "",
            type: "",
            room: t("total") + ":",
            price: totalPrice ? formatPrice(parseFloat(totalPrice), "EUR") : "",
        });

        setExportData(rows);
        setTimeout(function () {
            dispatch(showGlobalLoading(false));
            document.querySelector("#exportLink").click();
            setStartDataExport(false);
        }, 200);
    };

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={exportFileName}
        ></CSVLink>
    );
};

export default withApollo(ExportMovieCharges);
