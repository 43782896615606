import uuid from "react-uuid";

export const EnterRecoveryCodeModal = (langStrings, mail) => {
    return {
        title: "enter-code",
        executeSectionQuery: true,
        inputs: [
            {
                text: langStrings["recovery-mail-to"].replace("%0", `<strong>${mail}</strong>`),
                type: "htmltext",
            },
            {
                name: "recoveryCode",
                type: "text",
                focus: true,
                ph: "code",
            },
            {
                type: "resendCode",
                timeout: 59,
                ramdon: uuid(),
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "continue",
                style: "blue",
            },
        ],
    };
};

export default EnterRecoveryCodeModal;
