import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//Components
//Actions
import { openModal, setModalContent } from "../../../actions/uiActions";

const Policy = () => {
    //Actions
    const dispatch = useDispatch();

    //Listeners
    useEffect(() => {
        if (window.location.pathname.includes("privacy")) {
            dispatch(
                setModalContent({
                    title: "privacy-policy-title",
                    bodyAdjust: "w-3/5 text-justify max-h-80 overflow-y-scroll",
                    dontClose: true,
                    inputs: [
                        {
                            text: "privacy-policy-text",
                            type: "htmltext",
                        },
                    ],
                    buttons: [
                        {
                            name: "accept",
                            style: "blue",
                            link: "/policy",
                        },
                    ],
                })
            );
        } else {
            dispatch(
                setModalContent({
                    title: "data-protection",
                    bodyAdjust: "w-2/5",
                    dontClose: true,
                    inputs: [
                        {
                            text: "policy-text",
                            type: "htmltext",
                        },
                        {
                            id: "policy-accepted",
                            name: "accept-conditions",
                            type: "checkbox",
                        },
                    ],
                    buttons: [
                        {
                            name: "cancel",
                            style: "white",
                            link: "/login",
                        },
                        {
                            name: "access",
                            style: "blue",
                            action: "accept-policy",
                            checkEnabled: "policy-accepted",
                        },
                    ],
                })
            );
        }
        dispatch(openModal());
        // eslint-disable-next-line
    }, []);

    return <div></div>;
};

export default Policy;
