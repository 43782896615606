import { Session } from "../Session";
import { CORPORATE_CHANNEL, CUSTOMISED_CHANNEL, PRESET_CHANNEL } from "../Services/ChannelsUtils";
import NewGuestGroupModal from "../../GraphqlCalls/Customers/modals/NewGuestGroupModal";
import NewRoomGroupModal from "../../GraphqlCalls/Hotel/Rooms/modals/NewRoomGroupModal";
import NewRole from "../../GraphqlCalls/Settings/modals/NewRole";
import NewUser from "../../GraphqlCalls/Settings/modals/NewUser";
import NewPropertyGroup from "../../GraphqlCalls/Settings/modals/NewPropertyGroup";
import NewDesign from "../../GraphqlCalls/Design/modals/NewDesign";
import NewTag from "../../GraphqlCalls/Design/modals/NewTag";
import NewPasscode from "../../GraphqlCalls/Customers/modals/NewPasscode";
import NewAlarm from "../../GraphqlCalls/Customers/modals/NewAlarm";
import NewTaxZone from "../../GraphqlCalls/Settings/modals/NewTaxZone";
import NewProduct from "../../GraphqlCalls/Sales/modals/NewProduct";
import NewInputGroup from "../../GraphqlCalls/Hotel/modals/NewInputGroup";
import NewChannel from "../../GraphqlCalls/Services/modals/NewChannel";
import NewBulkChannel from "../../GraphqlCalls/Services/modals/NewBulkChannel";
import NewGroupChannel from "../../GraphqlCalls/Services/modals/NewGroupChannel";
import NewChannelModalGroup from "../../GraphqlCalls/Services/modals/NewChannelModalGroup";
import NewGridChannel from "../../GraphqlCalls/Services/modals/NewGridChannel";
import NewCorporateChannel from "../../GraphqlCalls/Services/modals/NewCorporateChannel";
import { NewBooking } from "hooks/GraphqlCalls/Services/modals/NewBooking";
import ParseChannelsFile from "hooks/GraphqlCalls/Services/modals/ParseChannelsFile";
import NewStaffUser from "hooks/GraphqlCalls/Customers/modals/NewStaffUser";

const GetTableHeaderButton = (type, data, isCorporate, t) => {
    const isSuperUser = Session.getSessionProp("superUser");
    let res;
    switch (type) {
        case "categories":
            res = [
                {
                    name: ["add", "newa"],
                    value: "new-cz-category",
                    color: "btn-blue",
                },
            ];
            break;
        case "areas-category":
        case "areas":
            res = [
                {
                    name: ["add", "newa"],
                    value: "new-cz-area",
                    color: "btn-blue",
                },
            ];
            break;
        case "booking-category":
            res = [
                {
                    name: ["newa", "booking"],
                    value: "new-area-booking",
                    color: "btn-blue",
                    modal: true,
                },
            ];
            break;
        case "list-staff":
            res = [
                // {
                //     name: "add-user",
                //     value: "new-staff-member",
                //     color: "btn-blue",
                // },

                {
                    name: "add-user",
                    value: "new-staff-member",
                    color: "btn-blue",
                    modal: NewStaffUser(),
                },
            ];
            break;
        // case "list-guests-currents":
        //     res = [];
        //     // const currentHotelData = Session.getSessionProp("currentHotel");
        //     // const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};
        //     // if (!currentHotel.blockGuestManagement) {
        //     //     res.push({
        //     //         name: ["check-in"],
        //     //         value: "new-guest",
        //     //         color: "btn-blue",
        //     //         redirect: "check-in",
        //     //     });
        //     // }
        //     break;
        case "list-guests-upcoming-check-ins":
            res = [];
            res.push({
                name: ["add-booking"],
                value: "add-booking",
                color: "btn-blue",
                modal: NewBooking(data),
            });
            break;
        case "list-messages-sent":
        case "list-messages-drafts":
        case "list-messages-templates":
        case "list-messages-bin":
        case "list-messages-scheduled":
            res = [];
            res.push({
                name: ["new message"],
                value: "new-guest",
                color: "btn-blue",
                redirect: "new/",
            });

            break;
        case "guest-groups":
            res = [
                {
                    name: ["newo", "group"],
                    value: "new-guest-group",
                    color: "btn-blue",
                    modal: NewGuestGroupModal(),
                },
            ];
            break;
        case "list-room-groups":
            res = [
                {
                    name: ["add", "group"],
                    value: "new-room-group",
                    color: "btn-blue",
                    modal: NewRoomGroupModal(),
                },
            ];
            break;
        case "list-users":
            res = [
                {
                    name: ["add", "user"],
                    value: "new-manager-user",
                    color: "btn-blue",
                    modal: NewRoomGroupModal(),
                },
            ];
            break;
        case "list-roles":
            res = [
                {
                    name: ["add-role"],
                    value: "add-manager-role",
                    color: "btn-blue",
                    modal: NewRole(),
                },
            ];
            break;
        case "list-manager-users":
            res = [
                {
                    name: ["add-user"],
                    value: "add-manager-user",
                    color: "btn-blue",
                    modal: NewUser(undefined, t),
                },
            ];
            break;
        case "list-languages":
            res = isCorporate
                ? []
                : [
                      {
                          name: ["add", "newo"],
                          value: "new-language",
                          color: "btn-blue",
                      },
                  ];
            break;
        case "list-propertyGroups":
            res = [
                {
                    name: ["add", "group"],
                    value: "new-property-group",
                    color: "btn-blue",
                    modal: NewPropertyGroup(),
                },
            ];
            break;
        case "list-designs":
            res = [
                {
                    name: ["add-design"],
                    value: "add-design",
                    color: "btn-blue",
                    modal: NewDesign(),
                },
            ];
            break;
        case "list-tags":
            res = [
                {
                    name: ["new-tag"],
                    value: "new-tag",
                    color: "btn-blue",
                    modal: NewTag(null, t),
                },
            ];
            break;
        case "list-alarms-":
        case "list-alarms-active":
        case "list-alarms-expired":
            res = [];
            res.push({
                name: ["new-alarm"],
                value: "new-alarm",
                color: "btn-blue",
                modal: [NewAlarm()],
            });
            break;
        case "list-passcodes-":
        case "list-passcodes-active":
        case "list-passcodes-expired":
            res = [
                {
                    name: ["add-passcode"],
                    value: "add-passcode",
                    color: "btn-blue",
                    modal: [NewPasscode()],
                },
            ];

            break;
        case "list-access-profiles":
            res = [
                {
                    name: ["add-profile"],
                    value: "add-access-profile",
                    color: "btn-blue",
                },
            ];
            break;
        case "list-tax-zones":
            res = [
                {
                    name: ["add-zone"],
                    value: "add-tax-zone",
                    color: "btn-blue",
                    modal: [NewTaxZone()],
                },
            ];
            break;
        case "list-products":
            res = [
                {
                    name: ["add-product"],
                    value: "add-product",
                    color: "btn-blue",
                    modal: [NewProduct(data, t)],
                    customAction: () => {
                        data.openModalAddProduct({ data });
                    },
                },
            ];
            break;
        case "list-categories":
            res = [
                {
                    name: data && data.level > 0 ? "add" : "add-category",
                    value: "create-facet-categorie-value",
                    color: "btn-blue",
                    customAction: () => {
                        data.openModalAddCategory(data);
                    },
                },
            ];

            break;
        case "list-input-groups":
            res = [
                {
                    name: "new-input-group",
                    value: "new-input-group",
                    color: "btn-blue",
                    modal: [NewInputGroup(data)],
                },
            ];

            break;
        case `list-channels-`:
        case `list-channels-${CORPORATE_CHANNEL.name}`:
        case `list-channels-${PRESET_CHANNEL.name}`:
        case `list-channels-${CUSTOMISED_CHANNEL.name}`:
            res = [];
            let canAdd = true;
            const corporateTab = data?.folder === CORPORATE_CHANNEL.value;
            const importDvb = isSuperUser && !corporateTab;
            if (data && data.folder === CUSTOMISED_CHANNEL.value) {
                res.push({
                    name: ["add-bulk-channels"],
                    value: "add-bulk-channels",
                    color: "btn-blue-outline",
                    modal: [NewBulkChannel()],
                    adjust: "float-left",
                });
            }
            if (corporateTab && data.dataItemsCount > 0 && !data.hasChannelPremium) {
                canAdd = false;
            }
            let value = "add-channel";
            if (corporateTab) {
                if (data?.corporateChannelsWithoutGlobalManagement) {
                    value = "add-corporate-channel-without-global-management";
                } else {
                    value = "add-corporate-channel-property";
                }
            }

            res.push({
                name: ["add-channel"],
                value: value,
                color: canAdd ? "btn-blue" : "btn-gray",
                adjust: `float-left ${importDvb && "w-52"}`,
                disabled: !canAdd,
                tooltip: !canAdd ? "add-corporate-channels-upgrade-text" : null,
                modal: [
                    NewChannel(
                        null,
                        data?.folder ? data.folder : null,
                        data?.corporateChannelsWithoutGlobalManagement ? true : false
                    ),
                ],
            });
            importDvb &&
                res.push({
                    name: ["import file"],
                    icon: "superuser zafiro-800 text-3xl",
                    value: "upload-dvb-channels-file",
                    color: "btn-blue-outline",
                    adjust: "float-left parsefile-btn w-52",
                    modal: [ParseChannelsFile()],
                });
            break;
        case "list-group-channels":
            res = [
                {
                    name: "add-group",
                    value: "add-group-channels",
                    color: "btn-blue",
                    adjust: "float-left",
                    modal: [NewGroupChannel(t, data)],
                },
            ];
            break;
        case "list-grids-channels":
            res = [
                {
                    name: "add-grid",
                    value: "add-grid-channels",
                    color: "btn-blue",
                    adjust: "float-left",
                    modal: [NewGridChannel(t, data)],
                },
            ];
            break;
        case "list-group-channels-edit":
            res = [
                {
                    name: "add-channel",
                    value: "add-channel-to-group-channel",
                    color: "btn-blue",
                    adjust: "float-left",
                    modal: [NewChannelModalGroup(t, data)],
                },
            ];
            break;
        case "list-corporate-channels":
            res = [
                {
                    name: "add-channel",
                    value: "add-corporate-channel",
                    color: "btn-blue",
                    adjust: "float-left",
                    modal: [NewCorporateChannel(t, data)],
                },
            ];

            break;
        case "list-order-summary":
            res = [
                {
                    name: "monitor-all",
                    value: "monitor-all",
                    color: data.disabled ? "btn-gray" : "btn-blue",
                    adjust: "float-left",
                    disabled: data.disabled ? true : false,
                    modal: [],
                    link: `/services/sales/order-monitor/all`,
                },
            ];
            break;
        default:
            res = [];
    }
    return res;
};

export default GetTableHeaderButton;
