import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//Actions
import { setCurrentProjectsPage } from "../../../actions/authActions";
//Utils
import { CalcPages } from "../../../hooks/Utils/DataCalc/CalcPages";
import { useTranslation } from "react-i18next";

const UseProjectPagination = ({ desiredProjects, perPage, displayPage }) => {
    const { t } = useTranslation();

    //Data
    const count = getCount(desiredProjects);
    const maxPage = Math.ceil(count / perPage);
    const pages = CalcPages(maxPage, displayPage);

    //States
    const [nextVisibility, setNextVisibility] = useState("");
    const [prevVisibility, setPrevVisibility] = useState("");

    //Actions
    const dispatch = useDispatch();

    const handlerClickDirect = (e) => {
        dispatch(setCurrentProjectsPage(Number(e.currentTarget.dataset.id)));
    };

    // Listeners
    useEffect(() => {
        setPrevVisibility(getPrevVisibility(displayPage));
        setNextVisibility(getNextVisibility(displayPage, maxPage));
        // eslint-disable-next-line
    }, [displayPage]);

    return (
        <div className="projects-pagination-container fist-capital">
            {count <= perPage ? (
                <></>
            ) : (
                <>
                    <div className={`t-pagination-item ${prevVisibility === "0" ? "hidden" : ""}`}>
                        <div className="inline">
                            <div className="flex items-center" onClick={handlerClickDirect} data-id={displayPage - 1}>
                                <span className="t-pagination-left icon-chevron-left"></span>
                                <span className="first-capital">{t("previous")}</span>
                            </div>
                        </div>
                    </div>

                    {pages.map((page, index) => (
                        <div
                            className="t-pagination-number"
                            onClick={page === "..." ? null : handlerClickDirect}
                            key={index}
                            data-id={page}
                        >
                            <div className="inline">
                                {displayPage === page ? (
                                    <span className="t-pagination-active" data-id={page}>
                                        {page}
                                    </span>
                                ) : null}
                                {page === "..." ? <span>{page}</span> : null}
                                {displayPage !== page && page !== "..." ? (
                                    <span className="t-pagination-index" onClick={handlerClickDirect} data-id={page}>
                                        {page}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    ))}

                    <div className={`t-pagination-item ${nextVisibility === "0" ? "hidden" : ""}`}>
                        <div className="inline">
                            <div className="flex items-center" onClick={handlerClickDirect} data-id={displayPage + 1}>
                                <span className="first-capital">{t("next")}</span>
                                <span className="t-pagination-right icon-chevron-right"></span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export const getCount = (desiredProjects) => {
    let res;
    if (desiredProjects) {
        res = desiredProjects.length;
    } else {
        res = 0;
    }
    return res;
};

export const getPrevVisibility = (displayPage) => {
    let res;
    if (displayPage > 1) {
        res = "100";
    } else {
        res = "0";
    }
    return res;
};

export const getNextVisibility = (displayPage, maxPage) => {
    let res;
    if (displayPage < maxPage && maxPage > 1) {
        res = "100";
    } else {
        res = "0";
    }
    return res;
};

export default UseProjectPagination;
