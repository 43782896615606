import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkable from "./dvbImport/Checkable";
import ChannelSelector from "./dvbImport/ChannelSelector";
import { useSelector } from "react-redux";
import ReactTooltip from "components/ReactTooltip";
import SynchroniseButton from "./dvbImport/SynchroniseButton";
import { Session } from "hooks/Utils/Session";
import DvbTableFilters from "./dvbImport/DvbTableFilters";

const DvbChannelTable = ({ channels, setChannels, checkedChannels, setCheckedChannels, closeAll, setCloseAll }) => {
    const { t } = useTranslation();

    const statePredefinedChannels = useSelector((state) => state.sectionContent.predefinedChannels);
    const stateTunedChannels = useSelector((state) => state.sectionContent.tunedChannels);

    const [allChecked, setAllChecked] = useState(false);
    const [sort, setSort] = useState();
    const [firstSelection, setFirstSelection] = useState(true);
    const [lastSync, setLastSync] = useState();
    const [filteredChannels, setFilteredChannels] = useState(channels);
    const [unlinkedPredefinedChannels, setUnlinkedPredefinedChannels] = useState(null);
    const [unlinkedTunedChannels, setUnlinkedTunedChannels] = useState(null);

    const getPredefinedChannels = () => {
        const tunedChannels = getTunedChannels();
        let channels = statePredefinedChannels;
        if (!channels) {
            let backup = sessionStorage.getItem("importChannelsBackup");
            backup = JSON.parse(backup);
            channels = backup.predefinedChannels;
        }

        //remove from predefined channels those used in tunedChannels
        return channels?.filter((ch) => !tunedChannels.find((tCh) => tCh?.PredefinedTVChannel?.ID === ch.ID));
    };
    const getTunedChannels = () => {
        let channels = stateTunedChannels;
        if (!channels) {
            let backup = sessionStorage.getItem("importChannelsBackup");
            backup = JSON.parse(backup);
            channels = backup.tunedChannels;
        }
        return channels;
    };

    const [predefinedChannels, setPrededinedChannels] = useState(getPredefinedChannels());
    const [tunedChannels, setTunedChannels] = useState(getTunedChannels());

    useEffect(() => {
        if (!channels?.length > 0) {
            return;
        } else {
            firstSelection && setInitialSelection();
        }
        setUnlinkedPredefinedChannels(
            predefinedChannels.filter((pch) => !channels.find((ch) => ch.PredefinedTvChannel?.ID === pch.ID))
        );
        setUnlinkedTunedChannels(tunedChannels.filter((tch) => !channels.find((ch) => ch.Tune?.id === tch.id)));
        saveBackup();
        setFilteredChannels(channels);
    }, [channels, predefinedChannels, tunedChannels]);

    useEffect(() => {
        channels?.length > 0 && setAllChecked(checkedChannels?.length === channels?.length);
    }, [checkedChannels]);

    useEffect(() => {
        switch (sort) {
            case "down":
                setFilteredChannels([...filteredChannels.sort((a, b) => (a.Name < b.Name ? 1 : -1))]);
                break;

            default:
                setFilteredChannels([...filteredChannels.sort((a, b) => (a.Name > b.Name ? 1 : -1))]);
                break;
        }
    }, [sort]);

    const sortName = () => {
        if (!sort) {
            setSort("up");
        } else if (sort === "up") {
            setSort("down");
        } else if (sort === "down") {
            setSort(null);
        }
    };

    const saveBackup = () => {
        Session.setSessionProp(
            "importChannelsBackup",
            JSON.stringify({ parsedChannels: channels, predefinedChannels, tunedChannels })
        );
        Session.saveBackup();
    };

    const setInitialSelection = (channelsInput) => {
        if (!channelsInput) {
            channelsInput = channels;
        }
        let initialChecked = [];
        channelsInput.forEach((ch) => {
            (ch.Tune || ch.PredefinedTvChannel) && initialChecked.push(ch.Name);
        });
        setCheckedChannels(initialChecked);
        setFirstSelection(false);
    };

    const channelSelectionHandler = (e) => {
        e.stopPropagation();
        if (e.target.checked && checkedChannels && !checkedChannels.find((item) => item === e.target.id)) {
            setCheckedChannels([...checkedChannels, e.target.id]);
        } else if (!e.target.checked) {
            setCheckedChannels(checkedChannels.filter((item) => item !== e.target.id));
        }
    };
    const allChannelSelectionHandler = (e) => {
        e.stopPropagation();
        if (e.target.checked) {
            setCheckedChannels(filteredChannels?.map((ch) => ch.Name));
        } else if (!e.target.checked) {
            setCheckedChannels([]);
        }
    };

    const hasPreviousDVBTune = (channel) => {
        if (!channel.Tune?.TuneInfo) {
            return false;
        }
        return channel.Tune?.TuneInfo?.[channel?.TuneType];
    };

    const printTuneType = (type) => {
        return type?.replaceAll("-", " ");
    };

    const printChannel = (channel, index) => {
        const isAdded = channel.PredefinedTvChannel || channel.Tune;
        const isChecked = checkedChannels?.find((item) => item === channel.Name);
        let type = "-";
        if (channel.PredefinedTvChannel?.IsRadio || channel.Tune?.IsRadio) {
            type = "Radio";
        } else if (channel.PredefinedTvChannel || channel.Tune) {
            type = "TV";
        }
        const hasPreviousTune = hasPreviousDVBTune(channel);

        const tuneData = JSON.parse(channel.TuneData);
        let tuningInfo = `<div class="font-bold p-2"> ${t("tuning information")} </div>`;
        Object.keys(tuneData).forEach((prop) =>
            tuneData[prop] && prop != "isEnabled" && prop?.[0] != "_"
                ? (tuningInfo += `<div class="p-2 flex w-full"><div class="w-3/5">${prop}</div><div class="w-2/5">${tuneData[prop]}</div></div>`)
                : null
        );

        const actionColor = hasPreviousTune ? "text-orange-100" : isAdded && isChecked ? "text-green-100" : "";
        const actionText = t(
            hasPreviousTune ? "to be overwritten" : isAdded && isChecked ? "to be added" : "not to be added"
        );

        return (
            <div key={`${channel.Name}-${index}`} className="font-medium py-4 flex w-full border-b border-gray-200">
                <div className="pt-2">
                    <Checkable id={channel.Name} checked={isChecked} onclick={channelSelectionHandler} />
                </div>
                <div className="w-2/12 float-left pt-1">{channel.Name}</div>
                <div className="w-2/12 float-left pt-1">
                    <div
                        className="bg-blue-100 p-1 text-white w-fit-content px-4 rounded whitespace-nowrap overflow-ellipsis overflow-hidden max-w-80 cursor-help"
                        data-tip={tuningInfo}
                        data-for="dangerous-html-tooltip"
                    >
                        {printTuneType(channel.TuneType)}
                    </div>
                </div>
                <div className="w-3/12 float-left">
                    <ChannelSelector
                        channelIndex={index}
                        channel={channel}
                        channels={channels}
                        setChannels={setChannels}
                        closeAll={closeAll}
                        setCloseAll={setCloseAll}
                        predefinedChannels={unlinkedPredefinedChannels}
                        tunedChannels={unlinkedTunedChannels}
                        checkedChannels={checkedChannels}
                        setCheckedChannels={setCheckedChannels}
                    />
                </div>
                <div className="w-3/12 float-left pt-2">{type}</div>
                <div className="w-2/12 float-left pt-2">
                    <div className={actionColor}>{actionText}</div>
                    {isChecked && !isAdded ? (
                        <div>
                            <div className="icon icon-warning text-orange-100 text-xl float-left"></div>
                            <div className="text-gray-600 ml-2 text-sm float-left">{t("unlinked")}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    };

    return (
        <div className="p-6 bg-white" style={{ minHeight: "75vh" }}>
            {/* Header */}
            {/* filters */}
            <div className="relative content w-full mb-4 h-7" style={{ height: "3rem" }}>
                <div className="w-8/12 float-left">
                    <DvbTableFilters
                        channels={channels}
                        closeAll={closeAll}
                        setCloseAll={setCloseAll}
                        checkedChannels={checkedChannels}
                        setFilteredChannels={setFilteredChannels}
                        lastSync={lastSync}
                    />
                </div>
                <div className="w-4/12 pt-2">
                    <SynchroniseButton
                        setPrededinedChannels={setPrededinedChannels}
                        setTunedChannels={setTunedChannels}
                        channels={channels}
                        setChannels={setChannels}
                        checkedChannels={checkedChannels}
                        setCheckedChannels={setCheckedChannels}
                        setLastSync={setLastSync}
                    />
                </div>
            </div>
            <div className="font-bold flex  py-4 border-b border-gray-200">
                <Checkable id="all-selected" checked={allChecked} onclick={allChannelSelectionHandler} />

                <div className="w-2/12 float-left relative">
                    {t("name")}{" "}
                    <span
                        id={`search_input-icon`}
                        className={`field-icon ${!sort && "icon-table-sort"} ${sort === "up" && "icon-sort-asc"} ${
                            sort === "down" && "icon-sort-desc"
                        } clickable contents mt-1`}
                        style={{ height: "2rem" }}
                        onClick={() => sortName()}
                    ></span>
                </div>
                <div className="w-2/12 float-left">{t("tune")}</div>
                <div className="w-3/12 float-left">{t("Linked channel")}</div>
                <div className="w-3/12 float-left">{t("type")}</div>
                <div className="w-2/12 float-left">{t("behaviour")}</div>

                <div className="absolute font-medium text-gray-800 mb-1 text-sm" style={{ right: "4.5rem" }}>
                    {`${filteredChannels?.length || 0} ${t("items")}`}
                </div>
            </div>

            {/* Body */}
            <div className="overflow-scroll w-full max-h-60" style={{ minHeight: "40vh" }}>
                {filteredChannels.map((pChannel, index) => printChannel(pChannel, index))}
                {filteredChannels?.length === 0 ? (
                    <div className="w-full text-xl text-center mt-6">{t("no-results-found")}</div>
                ) : null}
            </div>
        </div>
    );
};

export default DvbChannelTable;
