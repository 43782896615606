import React, { useRef, useEffect, useState, cloneElement, forwardRef, useImperativeHandle, useCallback } from "react";
import ReactDOM from "react-dom";

import Button from "components/Button";
import classNames from "classnames";

/**
 * @param id {string} The id of the dropdown.
 * @param disabled {boolean} Whether the dropdown is disabled.
 * @param disabledArrow {boolean} Whether to disable the arrow only
 * @param open {boolean} Open state of the dropdown.
 * @param setOpen {function} The function to set the open state of the dropdown.
 * @param handler {string | object | JSX.Element} The handler of the dropdown.
 * @param body {JSX.Element} The body of the dropdown.
 * @param children {Button[]} The body of the dropdown if not using body.
 * @param designClass {object} The design classes of the dropdown.
 * @param onClick {function} The function to handle the click event.
 * @param showArrow {boolean} Whether to show the arrow.
 * @param arrowPosition {string} The position of the arrow (default: right).
 * @param autoClose {boolean} Whether to close the dropdown when clicking outside.
 * @param tooltip {string} The tooltip of the dropdown.
 * @param tooltipType {string} The type of the tooltip.
 * @param width {string} The width of the dropdown.
 * @param onOpenChange {function} The function to handle the open state change.
 * @returns {JSX.Element} The rendered button component.
 */
const Dropdown = forwardRef(
    (
        {
            id,
            disabled,
            initialOpen,
            open: customOpen,
            setOpen: customSetOpen,
            handler,
            body,
            children,
            designClass: customDesignClass,
            maxWidth,
            maxHeight,
            tooltip,
            tooltipType,
            width,
            onOpenChange,
            autoClose = true,
            float = false,
            showArrow = true,
            disabledArrow = false,
            arrowPosition = "right",
        },
        ref
    ) => {
        const containerRef = useRef(document.createElement("div"));
        const dropdownRef = useRef(null);

        const [opened, setOpen] = useState((initialOpen !== undefined ? initialOpen : customOpen) || false);
        const [handlerElement, setHandlerElement] = useState(null);

        const isOpen = customOpen === undefined ? opened : customOpen;

        const open = () => {
            if (customSetOpen) {
                customSetOpen(true);
            }
            setOpen(true);
        };

        const close = () => {
            if (customSetOpen) {
                customSetOpen(false);
            }
            setOpen(false);
        };

        const toggle = (e) => {
            if (e) {
                e.stopPropagation();
            }
            if (customSetOpen) {
                customSetOpen(!isOpen);
            }
            setOpen(!isOpen);
        };

        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                close();
            }
        };

        useEffect(() => {
            if (autoClose) {
                document.addEventListener("mousedown", handleClickOutside);
            }
            return () => {
                if (autoClose) {
                    document.removeEventListener("mousedown", handleClickOutside);
                }
            };
        }, [autoClose]);

        useEffect(() => {
            if (handler && !React.isValidElement(handler)) {
                if (typeof handler === "object") {
                    setHandlerElement(
                        <div id={handler?.id} onClick={handler?.onClick}>
                            {handler?.label}
                        </div>
                    );
                } else if (typeof handler === "string") {
                    setHandlerElement(<div>{handler}</div>);
                }
            } else {
                setHandlerElement(handler);
            }
        }, [handler]);

        useEffect(() => {
            if (onOpenChange) {
                onOpenChange(isOpen);
            }
        }, [isOpen]);

        useImperativeHandle(ref, () => ({
            open,
            close,
            toggle,
        }));

        const currentBody = body || children;

        useEffect(() => {
            const handleScroll = () => alert("CLOSE!");
            if (float) {
                window.addEventListener("scroll", handleScroll);
                if (containerRef.current && isOpen) {
                    containerRef.current.id = `${id}-dropdown-container`;
                    const body = document.getElementById("root");
                    if (dropdownRef?.current) {
                        const handlerRect = dropdownRef.current.getBoundingClientRect();
                        if (handlerRect) {
                            containerRef.current.style.position = "absolute";
                            containerRef.current.style.zIndex = 990;
                            containerRef.current.style.visibility = "hidden";
                            containerRef.current.style.minWidth = dropdownRef.current.offsetWidth + "px";
                            containerRef.current.style.maxWidth = maxWidth || "100vw";
                            containerRef.current.style.maxHeight = maxHeight || "100vh";

                            setTimeout(() => {
                                const windowWidth = window.innerWidth;
                                const handlerLeft = handlerRect.left;
                                const handlerRight = handlerRect.right;
                                const handlerTop = handlerRect.top;
                                const handlerBottom = handlerRect.bottom;
                                const menuWidth = containerRef.current.offsetWidth;
                                const menuHeight = containerRef.current.offsetHeight;
                                const windowMargin = 10;

                                // Set x position
                                if (handlerLeft + menuWidth + windowMargin > windowWidth) {
                                    if (handlerRight - menuWidth - windowMargin < 0) {
                                        // Match the window's right margin
                                        containerRef.current.style.right = `${windowMargin}px`;
                                        containerRef.current.style.left = undefined;
                                    } else {
                                        // Match the handler's right position
                                        containerRef.current.style.right = `${windowWidth - handlerRight}px`;
                                        containerRef.current.style.left = undefined;
                                    }
                                } else {
                                    if (handlerLeft - windowMargin < 0) {
                                        // Match the window's left margin
                                        containerRef.current.style.left = `${windowMargin}px`;
                                        containerRef.current.style.right = undefined;
                                    } else {
                                        // Match the handler's left position
                                        containerRef.current.style.left = `${handlerLeft}px`;
                                        containerRef.current.style.right = undefined;
                                    }
                                }

                                // Set y position
                                if (handlerBottom + menuHeight + windowMargin > window.innerHeight) {
                                    if (handlerTop - menuHeight - windowMargin < 0) {
                                        // Match the window's top margin
                                        containerRef.current.style.top = `${windowMargin}px`;
                                        containerRef.current.style.bottom = undefined;
                                    } else {
                                        // Match the handler's top position
                                        containerRef.current.style.top = `${handlerTop - menuHeight}px`;
                                        containerRef.current.style.bottom = undefined;
                                    }
                                } else {
                                    if (handlerBottom - windowMargin < 0) {
                                        // Match the window's bottom margin
                                        containerRef.current.style.bottom = `${windowMargin}px`;
                                        containerRef.current.style.top = undefined;
                                    } else {
                                        // Match the handler's bottom position
                                        containerRef.current.style.top = `${handlerBottom}px`;
                                        containerRef.current.style.top = undefined;
                                    }
                                }
                                containerRef.current.style.visibility = "visible";
                            }, 100);
                        }
                    }
                    body.appendChild(containerRef.current);
                }
            }
            return () => {
                if (containerRef?.current?.parentNode) {
                    containerRef.current.parentNode.removeChild(containerRef.current);
                }
                window.removeEventListener("scroll", handleScroll);
            };
        }, [containerRef?.current, isOpen, float]);

        const designClass = {
            dropdown: null,
            handler: null,
            handlerIcon: null,
            ...customDesignClass,
        };

        const arrowElement = (
            <Button
                id={`${id}-dropdown-arrow`}
                disabled={disabled || disabledArrow}
                onClick={toggle}
                className={`${isOpen ? "icon-chevron-up" : "icon-chevron"} ${designClass?.handlerIcon} ${
                    disabledArrow ? "opacity-50" : ""
                }`}
            ></Button>
        );

        const dropdownClass = classNames({
            "flex items-center justify-between": true,
            "cursor-pointer": !disabled,
            "cursor-default": disabled,
            [designClass?.dropdown]: designClass?.dropdown,
            [designClass?.validation]: designClass?.validation,
            [designClass?.selectPages]: designClass?.selectPages,
        });

        return (
            <div
                id={`${id}-dropdown-container`}
                className="relative w-full"
                ref={dropdownRef}
                style={
                    width
                        ? {
                              width: width,
                          }
                        : null
                }
                onClick={(e) => {
                    if (e) {
                        e.stopPropagation();
                    }
                }}
            >
                <div
                    className={dropdownClass}
                    id={`${id}-dropdown`}
                    onClick={!disabled && !disabledArrow ? toggle : null}
                    data-tip={tooltip}
                    data-for={tooltipType || "default-tooltip"}
                >
                    {showArrow && arrowPosition === "left" ? arrowElement : null}
                    {React.isValidElement(handlerElement) ? (
                        cloneElement(handlerElement, {
                            className: designClass?.handler || handlerElement.props.className,
                            disabled,
                        })
                    ) : (
                        <div className={designClass?.handler}>&nbsp;</div>
                    )}
                    {showArrow && arrowPosition === "right" ? arrowElement : null}
                </div>
                {currentBody && isOpen && !float ? currentBody : null}
                {float ? ReactDOM.createPortal(currentBody, containerRef.current) : null}
            </div>
        );
    }
);
Dropdown.displayName = "Dropdown";

export default Dropdown;
