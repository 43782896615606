import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import { Link, useLocation } from "react-router-dom";
import MainMenu from "./MainMenu";
import HotelsDropdown from "./HotelsDropdown";
import Logo from "./logo";
import UserDropdown from "./UserDropdown";
import IconsBar from "./IconsBar";
//Actions
import { openSidebar } from "./../../actions/uiActions";
//Utils
import { Session } from "../../hooks/Utils/Session";
import DisplayMenuSidebar from "../../hooks/Utils/DisplayMenuSidebar";

const Header = () => {
    const menusDisplay = DisplayMenuSidebar();

    //Data
    const isLoggedIn = Session.getSessionProp("token");

    //Store data
    const { showMainMenu } = useSelector((state) => state.ui);

    //States
    const [visible, setVisible] = useState(false);
    const [displaySearch, setDisplaySearch] = useState("hidden");

    //Actions
    const dispatch = useDispatch();
    const showSidebar = () => dispatch(openSidebar());

    const handleDisplaySearch = () => {
        if (displaySearch === "hidden") {
            setDisplaySearch("block");
        } else {
            setDisplaySearch("hidden");
        }
    };

    const location = useLocation();
    //Listeners
    useEffect(() => {
        if (isLoggedIn && location.pathname !== "/select-project") {
            setVisible(true);
        } else {
            setVisible(false);
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);
    if (menusDisplay.hideHeader) {
        return null;
    }
    return (
        <>
            {showMainMenu ? (
                <div>
                    <div className={` w-1/6 left-0 block fixed top-20  bg-white`}>
                        <Logo />
                    </div>
                    <span className={!visible ? "hidden" : "z-200 w-5/6 fixed right-0 bg-gray-100 block"}>
                        <div className="inline-flex w-full h-72px justify-between bg-white shadow-sm border-gray-200 sticky top-0 z-100">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    onClick={showSidebar}
                                    className="inline-block md:hidden px-6 py-4 ml-2 text-gray-700 hover:text-zafiro-400 text-xl lg:text-2xl cursor-pointer"
                                >
                                    Menu
                                </button>

                                <MainMenu />
                            </div>
                            <div className="pl-2 pr-4 lg:pl-4 lg:pr-8 flex items-center">
                                <div className="w-auto hidden">
                                    <input
                                        type="text"
                                        className={`${displaySearch} bg-gray-200 text-gray-700 w-40 py-2 px-4 inline rounded`}
                                        name="general-search"
                                        id="general-search"
                                        placeholder="Buscar..."
                                    ></input>
                                </div>
                                <div
                                    className="px-3 lg:px-5 text-gray-700 hover:text-zafiro-400 text-2xl lg:text-3xl hidden"
                                    onClick={handleDisplaySearch}
                                >
                                    <i className="icon-search cursor-pointer"></i>
                                </div>

                                <Link
                                    className="px-3 lg:px-5 text-gray-700 hover:text-zafiro-400 text-2xl lg:text-3xl relative hidden"
                                    key={"/notif"}
                                    to={"/notif"}
                                >
                                    <i className="icon-notifications"></i>
                                    <span className="rounded-full bg-zafiro-400 w-3 h-3 absolute top-0 right-0 mt-2 mr-5">
                                        &nbsp;
                                    </span>
                                </Link>

                                <Link
                                    className="px-3 lg:px-5 text-gray-700 hover:text-zafiro-300 text-2xl lg:text-3xl hidden"
                                    key={"/config"}
                                    to={"/config"}
                                >
                                    <i className="icon-settings"></i>
                                </Link>

                                <div className="inline-block text-gray-700">
                                    <IconsBar />
                                </div>

                                <div className="inline-block mx-1 px-2 lg:px-5 ">
                                    <HotelsDropdown />
                                </div>

                                <div className="inline-block px-2 mr-4">
                                    <UserDropdown />
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            ) : null}
        </>
    );
};

export default Header;
