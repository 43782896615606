import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Utils
import { openModal, setLoadingModalContent, setModalContent } from "../../actions/uiActions";
import { toast } from "react-toastify";
import { capitalizeFirst, parseBoolean } from "../../hooks/Utils/Utils";
import { Session } from "../../hooks/Utils/Session";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Components
import UseButton from "../useButton";
import UseSearch from "../useSearch";
import { changeActionValues, changeGlobalAction } from "../../actions/globalActions";
import NewAsset from "../../hooks/GraphqlCalls/MediaLibrary/modals/NewAsset";
import ReactTooltip from "components/ReactTooltip";

const UseModalBrowserMediaLibrary = ({ index }) => {
    //Store
    const { dataMediaLibraryBrowser, dataMediaLibrary } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { langStrings, permissions } = useSelector((state) => state.ui);

    //States
    const [folderRef, setFolderRef] = useState(
        dataMediaLibrary && dataMediaLibrary.initial_folder && dataMediaLibrary.initial_folder !== ""
            ? dataMediaLibrary.initial_folder
            : "root"
    );
    const [assets, setAssets] = useState(null);
    const [breadcrumb, setBreadcrumb] = useState(null);
    const [search, setSearch] = useState("");
    const [showToolTip, setShowTooltip] = useState(false);

    const { modalContentId = null } = dataMediaLibrary;

    const lang = dataMediaLibrary.lang ? dataMediaLibrary.lang : null;
    const contentType = dataMediaLibrary?.type;
    const searchInputEnabled =
        (dataMediaLibrary?.search_input && dataMediaLibrary?.search_input === true) || contentType === "asset";

    //Variables.
    let dataMediaLibraryBrowserConfig = dataMediaLibraryBrowser;

    //Actions
    const dispatch = useDispatch();

    const resetSearch = () => {
        setSearch("");
        setTimeout(() => {
            const searchInputElement = document.querySelector("#search-input-modal-browser");
            if (searchInputElement) {
                searchInputElement.value = "";
            }
        }, 500);
    };

    function handleClickCard(e) {
        let ref = e.currentTarget.dataset.id;
        let type = e.currentTarget.dataset.type;
        const typeFile = contentType ? contentType : "asset";
        switch (type) {
            case "folder":
                setFolderRef(ref);
                if (searchInputEnabled) {
                    resetSearch();
                }
                break;
            case "asset":
                if (!typeFile.includes("asset")) {
                    return;
                }
                if (!dataMediaLibrary.select_multiple) {
                    if (document.querySelector(".selected")) {
                        document.querySelector(".selected").classList.remove("selected");
                    }
                    if (document.querySelector(".border-blue-600")) {
                        document.querySelector(".border-blue-600").classList.remove("border-blue-600");
                    }
                }
                let imageContent = document.querySelector("#image-ref-" + ref);
                if (e.currentTarget.classList.contains("selected")) {
                    e.currentTarget.classList.remove("selected");
                    imageContent.classList.remove("border-blue-600");
                } else {
                    e.currentTarget.classList.add("selected");
                    imageContent.classList.add("border-blue-600");
                }
                break;
            case "video":
                if (!typeFile.includes("video")) {
                    return;
                }
                if (!dataMediaLibrary.select_multiple) {
                    if (document.querySelector(".selected")) {
                        document.querySelector(".selected").classList.remove("selected");
                    }
                    if (document.querySelector(".border-blue-600")) {
                        document.querySelector(".border-blue-600").classList.remove("border-blue-600");
                    }
                }
                let videoContent = document.querySelector("#video-ref-" + ref);
                if (e.currentTarget.classList.contains("selected")) {
                    e.currentTarget.classList.remove("selected");
                    videoContent.classList.remove("border-blue-600");
                } else {
                    e.currentTarget.classList.add("selected");
                    videoContent.classList.add("border-blue-600");
                }
                break;
            default:
                break;
        }
        handleChoose(e);
    }

    async function handleClickBreadcrumb(e) {
        const name = e.currentTarget.dataset.name;
        const isRoot = parseBoolean(e.currentTarget.dataset.isroot);
        if (searchInputEnabled) {
            resetSearch();
        }
        if (isRoot) {
            setFolderRef("root");
        } else {
            if (name) {
                if (data && data.libraryTree && data.libraryTree.response) {
                    let elementInTree = await getObject(data.libraryTree.response, "name", name);
                    if (elementInTree) {
                        setFolderRef(elementInTree.ref);
                    }
                }
            }
        }
    }

    function handleAddMedia(e) {
        const { type = "" } = dataMediaLibrary;
        const assetTypeMediaLibrary = modalContentId === "menu-nav-new-item" ? "asset" : type;
        const modal = NewAsset(folderRef, null, null, dataMediaLibraryBrowserConfig, null, {
            ...dataMediaLibrary,
            type: assetTypeMediaLibrary,
        });
        dispatch(
            changeGlobalAction({
                actionName: "add-media-library-media",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    }

    function handleChoose(e) {
        const items = document.querySelectorAll(".selected");
        const refs = [];
        const info = [];
        if (items && items.length > 0) {
            items.forEach(async (item, index) => {
                refs.push(item.dataset.id);
                info.push(item.dataset);
            });
        }
        dispatch(
            changeActionValues({
                "browser-media-library-items-selected": refs,
                "browser-media-library-items-info-selected": info,
            })
        );
    }

    const arrangeSearchQuery = (props) => {
        const { searchInputEnabled, search } = props;
        let response = "";
        if (searchInputEnabled && search) {
            response = `search: "${search}"`;
        }
        return response;
    };

    //API
    const GET_INFO = gql`{
        ${`libraryContents: libraryContents(ref:"${folderRef}" , filter:{type:"folder , ${
            contentType ? contentType : "asset"
        }" ${arrangeSearchQuery({ searchInputEnabled, search })}  }`}  ){
            error,
            ok,
            response{
                results{
                    lastModified,
                    name,
                    ref,
                    size,
                    type
                  }
            }
        },
        libraryTree: libraryTree(ref:"root"){
            error,
            ok,
            response{
                fullPath,
                name,
                ref,
                tree{
                    fullPath,
                    name,
                    ref,
                        tree{
                        fullPath,
                        name,
                        ref,
                            tree{
                            fullPath,
                            name,
                            ref,
                            tree{
                                fullPath,
                                name,
                                ref
                            }
                        }
                    }
                }
            }
        }
    }`;

    const [executeQuery, { data, loading }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError(err) {
            console.log(err);
            dispatch(setLoadingModalContent(false));
            toast.error(capitalizeFirst(langStrings["mutation-error"]));
        },
    });

    //Listeners
    useEffect(() => {
        dispatch(setLoadingModalContent(true));
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data) {
            if (!loading) {
                dispatch(setLoadingModalContent(false));
            }
            const dataItems = arrangeData({ libraryContents: data.libraryContents ? data.libraryContents : [], lang });
            setAssets(dataItems);

            async function fetchBreadcrumb() {
                const dataBreadcrumb = await arrangeBreadcrumb({
                    libraryTree: data.libraryTree ? data.libraryTree : [],
                    langStrings,
                    folderRef,
                });
                setBreadcrumb(dataBreadcrumb);
            }
            fetchBreadcrumb();
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setLoadingModalContent(true));
        executeQuery();
        if (dataMediaLibraryBrowserConfig) {
            if (!dataMediaLibraryBrowserConfig.dataMediaLibrary) {
                dataMediaLibraryBrowserConfig["dataMediaLibrary"] = [];
            }
            dataMediaLibraryBrowserConfig.dataMediaLibrary["initial_folder"] = folderRef;
        }
        // eslint-disable-next-line
    }, [folderRef]);

    //Responses

    return (
        <>
            {dataMediaLibrary.add_asset &&
            dataMediaLibrary.add_asset.active &&
            permissions &&
            permissions.design &&
            permissions.design.library ? (
                <div className="absolute right-5 top-0 mt-10">
                    <div onClick={handleAddMedia}>
                        <UseButton
                            icon="add"
                            buttonName={contentType === "asset" ? "add-image" : "add-media"}
                            buttonColor={"btn-blue"}
                            adjust="w-auto px-4 min-w-24 mt-1"
                        />
                    </div>
                </div>
            ) : null}
            {searchInputEnabled && (
                <div className=" w-1/4 mb-10">
                    <UseSearch
                        value={search}
                        onChange={setSearch}
                        id="search-input-modal-browser"
                        placeholder={"search-for"}
                    />
                </div>
            )}
            <div id="breadcrumb-container" className="mb-2">
                {_renderBreadcrumb({ breadcrumb, handleClickBreadcrumb })}
            </div>
            {showToolTip && (
                <ReactTooltip
                    place="bottom"
                    type="light"
                    offset={{ top: -8, left: -8 }}
                    html={true}
                    border={true}
                    multiline={true}
                    borderColor="#D3DAE1"
                    id="tooltip-browser-media-library"
                />
            )}
            <div className="w-full max-h-40 overflow-scroll rounded ">
                {_renderAssets({ assets, handleClickCard, setShowTooltip, lang })}
            </div>
        </>
    );
};

const _renderBreadcrumb = (props) => {
    const { breadcrumb, handleClickBreadcrumb } = props;
    let response = [];
    let links = [];
    let _key = 0;
    if (breadcrumb && breadcrumb.length > 0) {
        // eslint-disable-next-line
        breadcrumb.map((item, index) => {
            links.push(
                <span
                    key={`breadcrumb-${item.name}`}
                    className={`${index + 1 !== breadcrumb.length ? "t-link cursor-pointer" : "font-black"}`}
                    data-name={item.name}
                    data-isroot={item.isRoot}
                    onClick={handleClickBreadcrumb}
                >
                    {item.name}
                </span>
            );
            if (index + 1 !== breadcrumb.length) {
                links.push(<span key={`breadcrumb-${item.name}-separator`}>{` / `}</span>);
            }
            _key = index;
        });
    }
    response.push(
        <div className="" key={_key}>
            {links}
        </div>
    );
    return response;
};

const _renderAssets = (props) => {
    const { assets, handleClickCard, setShowTooltip, lang } = props;
    let response = [];
    let cards = [];
    if (assets && assets.length > 0) {
        // eslint-disable-next-line
        assets.map((asset, index) => {
            let classes = "";
            if (asset.image && asset.image.url) {
                classes = asset.image.adjust ? asset.image.adjust : "max-h-full max-w-full";
            } else {
                classes = "h-full w-1/2";
            }
            classes = `${classes} m-auto align-middle`;
            cards.push(
                <div
                    key={asset.ref}
                    className="md:flex md:w-1/2 lg:w-1/8 px-2 py-2 cursor-pointer"
                    data-type={asset.type}
                    data-id={asset.ref}
                    data-name={asset.name}
                    onClick={handleClickCard}
                >
                    <div className="md:flex-1 w-full bg-white">
                        <div
                            className={`h-40 w-full flex content-center rounded border mb-2 ${
                                asset.type === "folder" ? "bg-white" : "bg-gray-200"
                            } `}
                            id={idReference(asset.type, asset.ref)}
                        >
                            <img
                                alt=""
                                src={imageSrc({ assetRef: asset, lang })}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require(`../../assets/images/icons/image.svg`);
                                }}
                                className={classes}
                            ></img>
                        </div>
                        <div className="w-full" data-tip={asset.name} data-for="tooltip-browser-media-library">
                            <p
                                className="link text-center truncate"
                                onMouseEnter={(e) => {
                                    setShowTooltip(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth);
                                    ReactTooltip.rebuild();
                                }}
                            >
                                {asset.name}
                            </p>
                        </div>
                    </div>
                </div>
            );
        });
    }
    response.push(
        <div className="md:flex content-center flex-wrap" key="assets_cards">
            {cards}
        </div>
    );
    return response;
};

const idReference = (type, ref) => {
    switch (type) {
        case "asset":
            return "image-ref-" + ref;
        case "video":
            return "video-ref-" + ref;
        default:
            break;
    }
};

const imageSrc = (props) => {
    const { assetRef, lang } = props;
    if (
        assetRef &&
        assetRef.type &&
        assetRef.image &&
        assetRef.image.url &&
        (assetRef.type === "asset" || assetRef.type === "folder")
    ) {
        return assetRef.image.url;
    } else if (assetRef && assetRef.type && assetRef.ref && assetRef.type === "video") {
        const posterSrc = Session.getDasUrl(`thumb/${assetRef.ref}-${lang}?w=138&h=138`);
        return posterSrc;
    } else {
        return require(`../../assets/images/icons/image.svg`);
    }
};

const arrangeData = (props) => {
    const { libraryContents, lang } = props;
    const libraryContentsData = [];
    if (libraryContents?.response?.results) {
        // eslint-disable-next-line
        libraryContents.response.results.forEach((libraryContent) => {
            libraryContentsData.push({
                ref: libraryContent.ref,
                name: libraryContent.name,
                type: libraryContent.type,
                image: {
                    url:
                        libraryContent.type === "folder"
                            ? require("../../assets/images/icons/folder.svg")
                            : Session.getDasUrl(`${libraryContent.ref}?w=138&h=138&${lang}`),
                    adjust: libraryContent.type === "folder" ? "w-1/2 h-full" : null,
                },
            });
        });
    }
    return libraryContentsData;
};
const arrangeBreadcrumb = async (props) => {
    const { libraryTree, langStrings, folderRef } = props;
    const libraryTreeData = [];
    libraryTreeData.push({
        name: langStrings["media-library"],
        isRoot: true,
    });
    if (libraryTree && libraryTree.response) {
        if (folderRef !== "root") {
            let elementInTree = await getObject(libraryTree.response, "ref", folderRef);
            if (elementInTree && elementInTree.fullPath) {
                let splitPath = elementInTree.fullPath.split("/");
                if (splitPath.length > 0) {
                    for (const element of splitPath) {
                        if (element !== "") {
                            libraryTreeData.push({
                                name: element,
                                isRoot: false,
                            });
                        }
                    }
                }
            }
        }
    }
    return libraryTreeData;
};

async function getObject(theObject, keyToFind, valueToFind) {
    let result = null;
    if (theObject instanceof Array) {
        for (const element of theObject) {
            result = await getObject(element, keyToFind, valueToFind);
            if (result) {
                break;
            }
        }
    } else {
        for (let prop in theObject) {
            if (prop === keyToFind) {
                if (theObject[prop] === valueToFind) {
                    return theObject;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = await getObject(theObject[prop], keyToFind, valueToFind);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

export default UseModalBrowserMediaLibrary;
