import React from "react";

export const EditorContext = React.createContext({
    lang: null, // en, fr...
    theme: null, // {...}
    screenType: null, // TV, MOBILE, GENERAL...
    deviceType: null, // TV, Mobile
    editorSize: null, // { width, height, my, mx, cols, rows, isBounded }
    itemSelected: null, // Selected item id
});
export const EditorProvider = EditorContext.Provider;

export const WidgetContext = React.createContext({
    type: null, // TEXT, LOGO, IMAGE...
    id: 0, // widget ID
    container: null, // Widget container {}
    style: null, // Widget style data
    config: {
        style: null,
        data: null,
    },
});
export const WidgetProvider = WidgetContext.Provider;
