import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";

const InputOrientation = ({ type, inline, id, title, toolbarID, deviceSpecific, icon }) => {
    const { deviceType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const uid = (str) => {
        return [widgetID, toolbarID, id, type, str].join("-");
    };

    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);

    // const value = style ? style[id] : null;
    const [value, setValue] = useState(style ? style[id] : null);
    const [currentOrientation, setCurrentOrientation] = useState(value || "center");
    useEffect(() => {
        setValue(style ? style[id] : null);
        setCurrentOrientation(style ? style[id] : null);
        // eslint-disable-next-line
    }, [gridItems]);

    //Actions
    const updateValue = (property, value) => {
        setCurrentOrientation(value);
        dispatch(
            setExecuteQuery(
                Widget.UpdateStyle({ widget, property, value, deviceSpecific: deviceSpecific ? deviceType : null })
            )
        );
    };

    const orientations = (() => {
        return ["horizontal", "vertical"];
    })();

    return (
        <div className={inline ? "inline-block mb-2 mr-2" : "flex w-full mb-4"}>
            {title ? <div className="first-capital flex items-center w-3/12">{capitalizeFirst(title)}</div> : null}
            <div className="w-9/12 flex">
                {orientations.map((orientation) => {
                    const icon =
                        orientation === "horizontal" ? "icon-navigation-horizontal" : "icon-navigation-vertical";
                    return (
                        <div
                            key={uid("input-orientation-" + orientation)}
                            className={`inline-block px-5 rounded py-1 mr-1 text-gray-800 cursor-pointer icon ${icon} ${
                                currentOrientation === orientation ? "bg-gray-200" : ""
                            } `}
                            style={{ fontSize: "4rem" }}
                            onClick={() => updateValue(id, orientation)}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default InputOrientation;
