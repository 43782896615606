export const PasswordReminderModal = () => {
    return {
        title: "recover-password",
        text: "pass-reminder-text",
        executeSectionQuery: true,
        inputs: [
            {
                name: "mail",
                type: "text",
                focus: true,
                ph: "email",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "continue",
                style: "blue",
                action: "send-recovery-email",
            },
        ],
    };
};

export default PasswordReminderModal;
