import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UseSectionMap, getSidebarIDs } from "../../components/Sidebar/utils";
import Sitemap from "../../sitemap";
import { Session } from "./Session";

const Userguiding = () => {
    const { getItems } = UseSectionMap();
    const { permissions } = useSelector((state) => state.ui);

    useEffect(() => {
        if (permissions) {
            if (permissions?.product) {
                // After login
                const products = Object.keys(permissions.product).filter((v) => permissions.product[v] === true);
                const sections = [];
                Object.keys(Sitemap).forEach((sectionID) => {
                    const items = getItems(sectionID, Sitemap[sectionID]);
                    const ids = getSidebarIDs(items);
                    if (ids && ids.length > 0) {
                        sections.push(sectionID);
                        ids.forEach((item) => {
                            sections.push(item);
                        });
                    }
                });
                Session.userGuiding(products, sections);
            } else {
                // Before login
                Session.userGuiding(null, null);
            }
        }
    }, [permissions]);

    return null;
};

export default Userguiding;
