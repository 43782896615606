import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import { useNavigate as useHistory } from "react-router-dom";

//UTILS
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { getProjectLangs } from "../../Utils/SalesUtils";

const UpdateAutomaticMessages = ({ client, subject, content, type }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { projectLangs } = useSelector((state) => state.ui);
    const [messagesExtraData, setMessagesExtraData] = useState({ langDefault: "en" });

    const [mutation, setMutation] = useState(`
    mutation{
        fake{ error id ok}}`);

    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            if (langDefault) {
                setMessagesExtraData({ ...messagesExtraData, langDefault: langDefault });
            }
        }
    }, [projectLangs]);

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(err.message);
        },
    });

    const getTypeMessage = (type) => {
        if (type === "instant-success") {
            return "INSTANT_SUCCESS";
        } else if (type === "scheduled-success") {
            return "SCHEDULED_SUCCESS";
        } else if (type === "accepted") {
            return "ACCEPTED";
        } else if (type === "ready") {
            return "READY";
        } else if (type === "deleted") {
            return "DELETED";
        } else if (type == "delivery-failed") {
            return "DELIVERY_FAILED";
        }
    };

    const convertObjectToArrayStr = (obj) => {
        Object.keys(obj).forEach((key) => {
            if (obj[key].trim() === "") {
                obj[key] = "";
            }
        });

        const arr = Object.keys(obj).map((key) => ({
            lang: key,
            text: obj[key],
        }));

        return arr.map((obj) => `{lang:${JSON.stringify(obj.lang)},text:${JSON.stringify(obj.text)}}`).join(",");
    };

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "discard-message":
                    execute = false;
                    dispatch(setModalContent(false));
                    dispatch(closeModal());
                    dispatch(setExecuteQuery(null));
                    dispatch(cleanAction());
                    history("/services/sales/general-settings");
                    break;
                case "save-msg":
                    const finalSubject =
                        convertObjectToArrayStr(subject).trim() === ""
                            ? `{lang: "${messagesExtraData.langDefault}",text:""}`
                            : convertObjectToArrayStr(subject);
                    const finalContent =
                        convertObjectToArrayStr(content).trim() === ""
                            ? `{lang: "${messagesExtraData.langDefault}",text:""}`
                            : convertObjectToArrayStr(content);
                    setMutation(`
                    mutation {
                        updateShopMessage(
                          type: ${getTypeMessage(type)}
                          subject: [${finalSubject}]
                          content: [${finalContent}]
                        ) {
                          error
                          ok
                        }
                      }
                      
                    `);
                    executeQuery.mutationName = "updateShopMessage";
                    executeQuery.closeModal = true;
                    break;
                case "update-state-message":
                    setMutation(`mutation {
                        updateShopMessage(
                          type: ${executeQuery.params.type}
                          enabled: ${executeQuery.params.value}
                        ) {
                          error
                          ok
                        }
                      }
                      `);
                    //   console.log()
                    executeQuery.mutationName = "updateShopMessage";
                    executeQuery.closeModal = true;
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(t("operation-successful"));
            removeApolloCacheKeys(client.cache, "all");
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateAutomaticMessages);
