import React, { forwardRef, useImperativeHandle, useMemo, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { SalesContext } from "contexts/Sales";

import { Session } from "hooks/Utils/Session";
import currenciesVendure from "hooks/Utils/Integrations/vendure-currencies.json";

import useShopConfig, {
    useConfigUpdate,
    useShippingConfigUpdate,
    useGeolocationTranslationsUpdate,
    usePickupLocationsCreate,
    usePickupLocationsUpdate,
    usePickupLocationsDelete,
} from "hooks/Data/useShopConfig";
import useShopLabels from "hooks/Data/useShopLabels";

export const ShopContext = React.createContext({
    ref: null, // Provider ref

    refresh: () => {}, // Refresh shop data

    called: false, // Shop data has been fetched
    loading: false, // Shop data is being fetched
    error: null, // Shop data fetch error
    ready: null, // Shop data is ready

    id: null, // Shop ID
    name: null, // Shop name
    token: null, // Shop token
    isActive: false, // Shop is active
    coverURL: null, // Shop cover URL or color
    autoAcceptOrders: false, // Shop auto accept orders
    deliverySchedule: null, // Shop delivery schedule
    orderCancellationAllowed: false, // Shop order cancellation allowed
    limitMaxProductPerOrder: false, // Shop max product per order active
    maxProductsPerOrder: null, // Shop max products per order
    delayedDeliveryThreshold: null, // Shop delayed delivery threshold
    allowScheduleDelivery: false, // Shop allow schedule delivery
    geolocationMessage: null, // Shop geolocation message
    pickupLocations: null, // Shop pickup locations
    shippingConfig: null, // Shop shipping configuration
    currency: null, // Shop currency {..., getName: (lang) => string}
    defaultTax: null, // Shop default tax
    taxRates: null, // All tax rates
    defaultLanguageCode: "",

    allCurrencies: null, // All currencies
    allTaxZones: null, // All taxes
    allTaxes: null, // All taxes

    data: null, // All shop data

    updateGeolocationMessage: () => {}, // Update geolocation message
    updateConfig: () => {
        return new Promise((_, reject) => {
            toast.error("Not implemented");
            reject(new Error("Not implemented"));
        });
    }, // Update shop configuration

    updateShippingConfig: () => {}, // Update shipping configuration
    setDeliveryMethodAvailable: () => {}, // Set delivery method available or not available

    createPickupLocations: () => {}, // Create pickup locations
    updatePickupLocations: () => {}, // Update pickup locations
    deletePickupLocations: () => {}, // Delete pickup locations

    // Shop permissions
    permissions: {
        // ...SalesContext.permissions
    },

    // Shop PMS configuration
    pms: {
        load: () => {},
        called: false,
        loading: false,
        error: null,
        ready: null,
        data: {
            id: null,
            description: null,
            enabled: false,
        },
    },

    // Shop labels
    labels: {
        load: () => {},
        called: false,
        loading: false,
        error: null,
        ready: null,
        data: {
            labels: null,
            facetID: null,
        },
    },

    // Shop print configuration
    print: {
        load: () => {},
        called: false,
        loading: false,
        error: null,
        ready: null,
        data: {
            link: null,
            id: null,
            password: null,
        },
    },
});

const ShopProvider = forwardRef(({ children, id, onError }, ref) => {
    const { t } = useTranslation();

    const lang = localStorage?.getItem("lang") || "en";

    const { permissions, credentials } = useContext(SalesContext);

    const [printData, setPrintData] = useState(null);

    const shopConfig = useShopConfig({ id });
    const shopToken = shopConfig.data?.channel?.token;
    const shopLabels = useShopLabels({ token: shopToken });

    const updateConfig = useConfigUpdate();

    const updateShippingConfig = useShippingConfigUpdate();
    const updateGeolocationTranslations = useGeolocationTranslationsUpdate({ token: shopToken });
    const createPickupLocations = usePickupLocationsCreate({ token: shopToken });
    const updatePickupLocations = usePickupLocationsUpdate({ token: shopToken });
    const deletePickupLocations = usePickupLocationsDelete({ token: shopToken });

    const allTaxes = shopConfig.data?.taxRates?.items
        ? shopConfig.data.taxRates.items.filter((tax) => tax.enabled)
        : null;

    const allTaxZones = allTaxes
        ? allTaxes
              .map((tax) => tax.zone)
              .filter((zone, index, self) => self.findIndex((t) => t.id === zone.id) === index)
        : null;

    const storedDeliverySchedule = shopConfig?.data?.channel?.customFields?.delivery_schedule
        ? JSON.parse(shopConfig.data.channel.customFields.delivery_schedule)
        : null;

    const storedConfig = {
        currencyCode: shopConfig.data?.channel?.currencyCode,
        defaultLanguageCode: shopConfig.data?.defaultLanguageCode,
        defaultTaxZoneId: shopConfig.data?.channel?.defaultTaxZone?.id,
        customFields: {
            cover_url: shopConfig?.data?.channel?.customFields?.cover_url,
            is_auto_accept_enabled: shopConfig?.data?.channel?.customFields?.is_auto_accept_enabled,
            delivery_schedule: storedDeliverySchedule,
            is_order_cancellation_allowed: shopConfig?.data?.channel?.customFields?.is_order_cancellation_allowed,
            allow_scheduled_delivery: shopConfig?.data?.channel?.customFields?.allow_scheduled_delivery,
            is_max_product_per_order_active: shopConfig?.data?.channel?.customFields?.is_max_product_per_order_active,
            max_products_per_order: shopConfig?.data?.channel?.customFields?.max_products_per_order,
            delayed_delivery_threshold: shopConfig?.data?.channel?.customFields?.delayed_delivery_threshold,
            default_tax_categoryId: shopConfig?.data?.channel?.customFields?.default_tax_category?.id,
            default_taxId: shopConfig?.data?.channel?.customFields?.default_tax?.id,
        },
    };

    const storedShippingConfig = shopConfig.data?.channel?.customFields?.shipping_config
        ? JSON.parse(shopConfig.data.channel.customFields.shipping_config)
        : null;

    const storedPickupLocations = shopConfig.data?.channel?.pickupLocations
        ? shopConfig.data.channel.pickupLocations.sort((a, b) => a.order - b.order)
        : [];

    const storedGeolocationTranslations = shopConfig.data?.channel?.geolocationTranslations
        ? shopConfig.data.channel.geolocationTranslations
        : null;

    const [config, setConfig] = useState(storedConfig);
    const [shippingConfig, setShippingConfig] = useState(storedShippingConfig);
    const [geolocationMessage, setGeolocationMessage] = useState(storedGeolocationTranslations);
    const [pickupLocationsData, setPickupLocationsData] = useState(storedPickupLocations);
    const [deletedPickupLocations, setDeletedPickupLocations] = useState([]);
    const [addedPickupLocations, setAddedPickupLocations] = useState([]);

    const pickupLocations = pickupLocationsData // Use stored locations
        .concat(addedPickupLocations) // Add recently added locations
        .filter((location, index, self) => self.findIndex((t) => t.id === location.id) === index) // Remove possible duplicates
        .filter((location) => !deletedPickupLocations.includes(location.id)) // Remove recently deleted locations
        .sort((a, b) => a.order - b.order); // Sort locations by order

    const refreshData = () => {
        if (ref?.current) {
            ref.current.refresh();
        }
    };

    const getCurrencyName = (code, lang) => {
        const currency = currenciesVendure?.currencies?.[code];
        if (currency) {
            const fallbackLangs = [lang, "en", "es"];
            for (let fallbackLang of fallbackLangs) {
                if (lang !== fallbackLang && currency[fallbackLang]) {
                    return currency[fallbackLang];
                }
            }
        }
        return code;
    };

    const allCurrencies = currenciesVendure?.currencies
        ? Object.keys(currenciesVendure.currencies).map((code) => ({
              ...currenciesVendure.currencies[code],
              name: getCurrencyName(code, lang),
              id: currenciesVendure.currencies[code].code,
          }))
        : [];
    const contextData = useMemo(
        () => ({
            ref,

            refresh: refreshData,

            called: shopConfig.called,
            loading: shopConfig.loading,
            error: shopConfig.error,
            ready: shopConfig.ready || !id,

            id,
            token: shopToken,
            name: shopConfig.data?.name,
            isActive: shopConfig.data?.channel?.customFields?.is_active,
            coverURL: config?.customFields?.cover_url,
            autoAcceptOrders: config?.customFields?.is_auto_accept_enabled,
            deliverySchedule: config?.customFields?.delivery_schedule,
            orderCancellationAllowed: config?.customFields?.is_order_cancellation_allowed,
            allowScheduleDelivery: config?.customFields?.allow_scheduled_delivery,
            limitMaxProductPerOrder: config?.customFields?.is_max_product_per_order_active,
            maxProductsPerOrder: config?.customFields?.max_products_per_order,
            delayedDeliveryThreshold: config?.customFields?.delayed_delivery_threshold,
            pickupLocations,
            geolocationMessage,
            shippingConfig,
            defaultLanguageCode: config.defaultLanguageCode,
            currency:
                config?.currencyCode && allCurrencies.some((currency) => currency.id === config.currencyCode)
                    ? {
                          ...allCurrencies.find((currency) => currency.id === config.currencyCode),
                          getName: (lang) => getCurrencyName(config.currencyCode, lang),
                      }
                    : null,
            defaultTax:
                allTaxes && config.customFields?.default_taxId
                    ? {
                          ...allTaxes.find((tax) => tax.id === config.customFields.default_taxId),
                          category: {
                              id: config?.customFields?.default_tax_categoryId,
                          },
                          zone: config?.defaultTaxZoneId
                              ? {
                                    ...allTaxZones.find((zone) => zone.id === config.defaultTaxZoneId),
                                }
                              : null,
                      }
                    : null,
            taxRates:
                config?.defaultTaxZoneId && allTaxes
                    ? allTaxes.filter((tax) => tax?.zone?.id === config.defaultTaxZoneId)
                    : null,

            allCurrencies,
            allTaxZones,
            allTaxes,

            data: shopConfig.data,

            updateGeolocationMessage: (message) => {
                return new Promise((resolve, reject) => {
                    setGeolocationMessage(message);
                    updateGeolocationTranslations(message)
                        .then((data) => {
                            setGeolocationMessage(data);
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to update geolocation message", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            updateConfig: (cfg) => {
                return new Promise((resolve, reject) => {
                    const newConfig = {
                        ...(cfg?.currencyCode !== undefined ? { currencyCode: cfg.currencyCode } : {}),
                        ...(cfg?.defaultTaxZoneId !== undefined ? { defaultTaxZoneId: cfg.defaultTaxZoneId } : {}),
                        ...(cfg?.defaultShippingZoneId !== undefined
                            ? { defaultShippingZoneId: cfg.defaultShippingZoneId }
                            : {}),
                        customFields: {
                            ...(cfg?.coverURL !== undefined ? { cover_url: cfg.coverURL } : {}),
                            ...(cfg?.autoAcceptOrders !== undefined
                                ? { is_auto_accept_enabled: cfg.autoAcceptOrders }
                                : {}),
                            ...(cfg?.deliverySchedule !== undefined
                                ? {
                                      delivery_schedule: cfg.deliverySchedule ? cfg.deliverySchedule : null,
                                  }
                                : {}),
                            ...(cfg?.orderCancellationAllowed !== undefined
                                ? { is_order_cancellation_allowed: cfg.orderCancellationAllowed }
                                : {}),
                            ...(cfg?.allowScheduleDelivery !== undefined
                                ? { allow_scheduled_delivery: cfg.allowScheduleDelivery }
                                : {}),
                            ...(cfg?.limitMaxProductPerOrder !== undefined
                                ? { is_max_product_per_order_active: cfg.limitMaxProductPerOrder }
                                : {}),
                            ...(cfg?.maxProductsPerOrder !== undefined
                                ? { max_products_per_order: cfg.maxProductsPerOrder }
                                : {}),
                            ...(cfg?.delayedDeliveryThreshold !== undefined
                                ? { delayed_delivery_threshold: cfg.delayedDeliveryThreshold }
                                : {}),
                            ...(cfg?.defaultTaxId !== undefined ? { default_taxId: cfg.defaultTaxId } : {}),
                            ...(cfg?.defaultTaxCategoryId !== undefined
                                ? { default_tax_categoryId: cfg.defaultTaxCategoryId }
                                : {}),
                        },
                    };
                    if (newConfig.customFields && Object.keys(newConfig.customFields).length === 0) {
                        delete newConfig.customFields;
                    }
                    setConfig({
                        ...config,
                        ...newConfig,
                        customFields: { ...config.customFields, ...newConfig.customFields },
                    });
                    updateConfig(id, newConfig)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to update shop config", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            updateShippingConfig: (config) => {
                return new Promise((resolve, reject) => {
                    setShippingConfig(config);
                    updateShippingConfig(id, config)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to update shipping config", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            setDeliveryMethodAvailable: (key, value) => {
                const newShippingConfig = {
                    ...shippingConfig,
                    [key]: {
                        ...shippingConfig?.[key],
                        enabled: value,
                    },
                };
                setShippingConfig(newShippingConfig);
                return new Promise((resolve, reject) => {
                    updateShippingConfig(id, newShippingConfig)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to update shipping config", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            updatePickupLocations: (locations) => {
                return new Promise((resolve, reject) => {
                    const newLocations = pickupLocations.map((location) => {
                        const updatedLocation = locations.find((item) => item.id === location.id);
                        if (updatedLocation) {
                            return {
                                ...location,
                                ...updatedLocation,
                            };
                        }
                        return location;
                    });
                    setPickupLocationsData(newLocations);
                    updatePickupLocations(newLocations)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to update pickup locations", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            createPickupLocations: (locations) => {
                return new Promise((resolve, reject) => {
                    createPickupLocations(locations)
                        .then((newLocations) => {
                            setAddedPickupLocations([...addedPickupLocations, ...newLocations]);
                            resolve(newLocations);
                        })
                        .catch((error) => {
                            console.error("Failed to create pickup locations", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            deletePickupLocations: (values) => {
                const ids = Array.isArray(values) ? values.filter((id) => id) : [values];
                return new Promise((resolve, reject) => {
                    setDeletedPickupLocations([...deletedPickupLocations, ...ids]);
                    deletePickupLocations(ids)
                        .then((data) => {
                            resolve(data);
                        })
                        .catch((error) => {
                            console.error("Failed to delete pickup locations", error);
                            toast.error(t("mutation-error"));
                            refreshData();
                            reject(error instanceof Error ? error : new Error(error));
                        });
                });
            },

            permissions,

            labels: {
                ...shopLabels,
                load: () => {
                    if (shopLabels && !shopLabels.called) {
                        shopLabels.load();
                    }
                },
            },
            pms: {
                ...shopConfig,
                data: shopConfig.data?.pmsConfig,
                load: () => {
                    if (shopConfig && !shopConfig.called) {
                        shopConfig.load();
                    }
                },
            },
            print: {
                called: credentials?.called,
                loading: credentials?.loading || shopConfig.loading,
                error: shopConfig.error || credentials?.error,
                ready: credentials?.ready,
                data: printData,
                load: () => {
                    if (credentials && !credentials?.called) {
                        credentials.load();
                    }
                },
            },
        }),
        [id, ref, credentials, shopConfig, config, printData]
    );

    useImperativeHandle(ref, () => ({
        refresh: () => {
            if (shopConfig) {
                shopConfig.refetch();
                if (shopLabels?.called) {
                    shopLabels.load();
                }
                if (credentials?.called) {
                    credentials.load();
                }
            }
        },
    }));

    useEffect(() => {
        if (shopConfig) {
            shopConfig.load();
        }
    }, [id]);

    useEffect(() => {
        setConfig(storedConfig);
        setShippingConfig(storedShippingConfig);
        setGeolocationMessage(storedGeolocationTranslations);
        setPickupLocationsData(storedPickupLocations);
        setDeletedPickupLocations([]);
        setAddedPickupLocations([]);
    }, [shopConfig?.data]);

    useEffect(() => {
        if (credentials?.data) {
            setPrintData(getPrintSettings(credentials.data, shopToken));
        }
    }, [credentials?.data, shopToken]);

    useEffect(() => {
        if (typeof onError === "function") {
            onError(shopConfig?.error);
        }
    }, [shopConfig?.error]);

    return <ShopContext.Provider value={contextData}>{children}</ShopContext.Provider>;
});

const getPrintSettings = (projectCredentials, token) => {
    if (projectCredentials) {
        return {
            link: token ? `${Session.getPrinterUrl()}/print/request?shop_token=${token}` : null,
            id: projectCredentials?.user,
            password: projectCredentials?.password,
        };
    }
};

export default ShopProvider;
