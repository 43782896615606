import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { capitalizeFirst } from "hooks/Utils/Utils";
import { ACCEPTEDFORMATS, checkFileType } from "hooks/Utils/FileUtils";

import ColorInput from "components/ColorInput";
import { Radiobutton } from "components/Inputs/Radiobuttons";

const TYPE_IMAGE = "IMAGE";
const TYPE_COLOR = "COLOR";

const ImageColorInput = ({ id, color: defaultColor, image: defaultImage, onChange, fileHandler }) => {
    const { t } = useTranslation();

    if (!id) {
        throw new Error("ImageColorInput: id is required");
    }

    const [color, setColor] = useState(defaultColor);
    const [image, setImage] = useState(defaultImage);

    const [type, setType] = useState(defaultImage ? TYPE_IMAGE : TYPE_COLOR);

    useEffect(() => {
        setImage(defaultImage);
        setType(defaultImage ? TYPE_IMAGE : TYPE_COLOR);
    }, [defaultImage]);

    useEffect(() => {
        setColor(defaultColor);
    }, [defaultColor]);

    const handleChangeImageInput = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            Array.from(files).forEach((file) => {
                const validImage = checkFileType(file, ACCEPTEDFORMATS["image"]);
                if (!validImage) {
                    toast.error(`${file.name} ${t("error-file-not-valid-dataType")}`);
                } else if (!file.size || (validImage && file.size / 1000 / 1000 > 11)) {
                    toast.error(`${file.name} ${t("error-exceeds-allowed-size")}`);
                } else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        setType(TYPE_IMAGE);
                        setImage(e.target.result);
                        if (onChange) {
                            onChange({ image: e.target.result, color: null });
                        }
                    };
                    reader.readAsDataURL(file);
                    if (fileHandler) {
                        fileHandler(file);
                    }
                }
            });
        }
    };

    const imagePreview = (
        <img
            alt="preview"
            className="w-full h-full rounded"
            style={{ objectFit: "cover" }}
            src={image}
            id={`${id}-image`}
        />
    );

    const colorPreview = (
        <div className="w-full h-full rounded" style={{ backgroundColor: color || null }} id={`${id}-color`}></div>
    );

    const noImagePreview = (
        <div
            id={`${id}-not-image`}
            className="flex rounded items-center justify-center text-xs text-gray-700 h-full w-full bg-gray-100"
        >
            {t("not-image-yet")}
        </div>
    );

    const deleteImage = (
        <div className=" absolute flex items-center justify-center w-full h-8 bottom-0 left-0 bg-gray-700">
            <span
                id={`${id}-delete-button`}
                className=" text-white cursor-pointer icon icon-delete text-lg"
                onClick={() => {
                    setImage(null);
                    if (onChange) {
                        onChange({ image: null, color });
                    }
                }}
            ></span>
        </div>
    );

    return (
        <div className="flex w-full items-center space-x-10">
            <div className="relative w-32 h-32 rounded bg-alpha border border-gray-600">
                {type === TYPE_COLOR ? colorPreview : null}
                {type === TYPE_IMAGE && !image ? noImagePreview : null}
                {type === TYPE_IMAGE && image ? (
                    <>
                        {imagePreview}
                        {deleteImage}
                    </>
                ) : null}
            </div>
            <div className="flex flex-col items-start space-y-5">
                <div className="flex items-center space-x-3">
                    <div className="relative w-5 h-5">
                        <Radiobutton
                            id={`${id}-option-color`}
                            checked={type === TYPE_COLOR}
                            value={TYPE_COLOR}
                            onChange={({ checked, value }) => {
                                if (checked) {
                                    setType(value);
                                    if (onChange) {
                                        onChange({ image: null, color });
                                    }
                                }
                            }}
                        />
                    </div>
                    <ColorInput
                        id="shop-color-picker"
                        disabled={type !== TYPE_COLOR}
                        value={color}
                        onChange={(value) => {
                            setColor(value);
                            if (onChange) {
                                onChange({ image: null, color: value });
                            }
                        }}
                    />
                </div>
                <div className="flex items-center space-x-3">
                    <div className="relative w-5 h-5">
                        <Radiobutton
                            id={`${id}-option-image`}
                            checked={type === TYPE_IMAGE}
                            value={TYPE_IMAGE}
                            onChange={({ checked, value }) => {
                                if (checked) {
                                    setType(value);
                                    if (onChange) {
                                        onChange({ image: null });
                                    }
                                }
                            }}
                        />
                    </div>
                    <div>
                        <span
                            className={` inline-flex items-center justify-center h-10 rounded border w-auto px-3 text-base text-white ${
                                type === TYPE_COLOR ? " bg-gray-400 cursor-default " : " bg-zafiro-600 cursor-pointer "
                            }  `}
                            id={`${id}-button-select-image`}
                            onClick={() => {
                                const inputFile = document.getElementById("getImageShop");
                                if (type === TYPE_IMAGE && inputFile) {
                                    inputFile.click();
                                }
                            }}
                        >
                            {capitalizeFirst(t("choose-an-image"))}
                        </span>
                        <input
                            type="file"
                            id="getImageShop"
                            style={{ display: "none" }}
                            accept="image/png, image/jpeg, image/webp, image/avif"
                            multiple={false}
                            onChange={(e) => {
                                handleChangeImageInput(e);
                            }}
                        ></input>
                    </div>
                </div>
            </div>
        </div>
    );
};
ImageColorInput.displayName = "ImageColorInput";

export default ImageColorInput;
