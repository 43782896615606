import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";

import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
const InputAlignment = ({ type, inline, id, title, toolbarID, deviceSpecific, icons }) => {
    const { t } = useTranslation();
    const { deviceType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const sectionName = `input-alignment`;

    const uid = (str) => {
        return [widgetID, toolbarID, id, type, str].join("-");
    };

    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);

    // const value = style ? style[id] : null;
    const [value, setValue] = useState(style ? style[id] : null);
    const [align, setAlign] = useState(value || "center");
    useEffect(() => {
        setValue(style ? style[id] : null);
        setAlign(style ? style[id] : null);
        // eslint-disable-next-line
    }, [gridItems]);

    // console.log(type);
    // console.log(id);
    console.log(id);

    //Actions
    const updateValue = (property, value) => {
        setAlign(value);
        dispatch(
            setExecuteQuery(
                Widget.UpdateStyle({
                    widget,
                    property,
                    value,
                    deviceSpecific: deviceSpecific ? deviceType : null,
                })
            )
        );
    };

    const positions = (() => {
        switch (type) {
            case "vertical":
                return ["top", "center", "bottom"];
            case "around":
                return ["right", "left", "bottom", "top"];
            case "horizontal":
            default:
                return ["left", "center", "right"];
        }
    })();

    return (
        <div className={inline ? "inline-block mb-2 mr-2" : "flex w-full mb-2"}>
            {title ? (
                <div className="first-capital flex items-center mt-2 w-3/12">{capitalizeFirst(t(title))}</div>
            ) : null}
            <div className="w-9/12 flex">
                {positions.map((pos) => {
                    let icon;
                    if (icons === "alt" && type === "vertical") {
                        switch (pos) {
                            case "top":
                                icon = "icon-Top-text";
                                break;
                            case "bottom":
                                icon = "icon-Bottom-text";
                                break;
                            case "center":
                            default:
                                icon = "icon-center-text";
                                break;
                        }
                    } else {
                        icon = `icon-align-${pos === "center" && type === "vertical" ? `${pos}-v` : pos}`;
                    }
                    const star = <i className={`icon icon-star text-orange-100 p-1`} style={{ fontSize: "1rem" }}></i>;
                    return type === "around" ? (
                        <div
                            id={`${sectionName}-${pos}`}
                            key={uid("input-align-" + pos)}
                            className={`inline-block mr-1 rounded p-2 cursor-pointer ${
                                align === pos ? "bg-gray-200" : ""
                            }`}
                            style={{ zoom: 0.9 }}
                            onClick={() => updateValue(id, pos)}
                        >
                            <div
                                className={`flex flex-${
                                    pos === "top" || pos === "bottom" ? "col" : "row"
                                } place-items-center`}
                                style={{ width: "3.5rem", height: "3rem" }}
                            >
                                {pos === "left" || pos === "top" ? star : null}
                                <span>Abc</span>
                                {pos === "right" || pos === "bottom" ? star : null}
                            </div>
                        </div>
                    ) : (
                        <div
                            id={`${sectionName}-${pos}${id ? `-${id}` : ""}`}
                            key={uid("input-align-" + pos)}
                            className={`inline-block p-2 mr-1 cursor-pointer icon ${icon} ${
                                align === pos ? "bg-gray-200" : ""
                            }`}
                            onClick={() => updateValue(id, pos)}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
};

export default InputAlignment;
