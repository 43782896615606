import React from "react";

import Toggle from "components/Inputs/Toggle";

const Checkbox = (props) => {
    return <Toggle {...props} design="checkbox" />;
};
Checkbox.displayName = "Checkbox";

export default Checkbox;
