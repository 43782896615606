import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setCount,
    setTableResults,
    setCustomError,
    setCustomConfig,
} from "../../../actions/tableActions";
//Utils
import GetInfoCTableHeaders from "../../Utils/Table/Headers/GetInfoTableHeaders";
//Model
import ContractedProduct from "./models/ContractedProduct";
import { Session } from "../../Utils/Session";
import { useTranslation } from "react-i18next";

const ListContractedProducts = () => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);

    //States
    //Data
    const sectionName = "list-contracted-products";

    const cookieCHotel = Session.getSessionProp("currentHotel");
    const HOTEL_ID = cookieCHotel ? JSON.parse(cookieCHotel).id : "";

    const projects = JSON.parse(Session.getSessionProp("projects"));
    const projectData = projects.filter((project) => parseInt(project.id) === parseInt(HOTEL_ID))[0];

    //Actions
    const dispatch = useDispatch();

    const GET_CONTRACTED_PRODUCTS = gql`{
        products(projectCode: "${
            projectData ? (projectData.ref === "CORPORATE" ? projectData.ref : projectData.code) : ""
        }"){
         code isPremium name}
      }`;
    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_CONTRACTED_PRODUCTS);

    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && !error && data && data.products) {
            //Get data
            const dataItems = arrangeData(data, langStrings);
            const tableHeaders = GetInfoCTableHeaders(sectionName);

            //Add to store
            dispatch(setLoading(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.products.length));
            dispatch(
                setCustomConfig({
                    pagination: false,
                    tableContainerAdjust: "min-h-0 max-h-70",
                    rowsContainerAdjust: "overflow-scroll max-h-40",
                })
            );

            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));

            dispatch(setCustomError(t("no products available")));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const arrangeData = (data, langStrings) => {
    const produts = [];
    // eslint-disable-next-line
    data.products.map((product) => {
        produts.push(ContractedProduct(product, langStrings));
    });
    return produts;
};

export default ListContractedProducts;
