import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
//Actions
import { clearProjectRef } from "../../../actions/hotelActions";
//Utils
import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";

import { Session } from "../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";

const LoginSuperUser = () => {
    const { t, i18n } = useTranslation();
    //Data
    const isSuperUser = Session.getSessionProp("superUser");
    const currentAccount = Session.getSessionProp("currentAccount")
        ? JSON.parse(Session.getSessionProp("currentAccount"))
        : null;
    const history = useHistory();
    // eslint-disable-next-line

    //API Call

    const MUTATION = gql`
    mutation {
        loginSuperuser(
            chainRef: "${currentAccount ? currentAccount.ref : ""}"
            lang:  "${i18n.language.split("-")[0]}"
      ) { chainId chainName chainRef chainAppURL cloudManagerURL managerLogo error isOk token isSuperuser userID userRef userFullname userManagerRole hasCorporateUser userTOSAccepted projects{ id location name timeZone keyBrand ref managerURL managerUsers blockGuestManagement code supportPhone supportEmail zMobileURL }}}`;

    const [loginSuperuser, { data }] = useMutation(MUTATION);

    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (data && data.loginSuperuser && data.loginSuperuser.isOk) {
            Session.login({
                user: {
                    id: data.loginSuperuser.userID,
                    ref: data.loginSuperuser.userRef,
                    fullname: data.loginSuperuser.userFullname,
                    superUser: data.loginSuperuser.isSuperuser,
                    role: data.loginSuperuser.userManagerRole,
                    corporate: data.loginSuperuser.hasCorporateUser,
                    tos: data.loginSuperuser.userTOSAccepted,
                },
                chain: {
                    id: data.loginSuperuser.chainId,
                    name: data.loginSuperuser.chainName,
                    ref: data.loginSuperuser.chainRef,
                    app: data.loginSuperuser.chainAppURL,
                    manager: data.loginSuperuser.cloudManagerURL,
                    logo: data.loginSuperuser.managerLogo,
                },
                projects: getProjects(data.loginSuperuser.projects, data.loginSuperuser.hasCorporateUser, t),
            });

            if (data.loginSuperuser.token !== "") {
                Session.setToken(data.loginSuperuser.token);
                history("/select-project");
            }
        } else if (data && data.loginSuperuser && data.loginSuperuser.error) {
            toast.error(t("invalid email or password"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(clearProjectRef());
        if (isSuperUser) {
            loginSuperuser();
            //Loguear al usuario con la cadena seleccionada
        }
        // eslint-disable-next-line
    }, []);

    return <></>;
};

const getProjects = (projects, hasCorporateUser, t) => {
    projects.sort((a, b) => String(a.name).localeCompare(String(b.name)));
    projects.map((project) => {
        project.hasMinimalPermissions = true;
    });
    //CORPORATE PROJECT
    if (hasCorporateUser) {
        projects.unshift({
            name: t("global-management"),
            id: 0,
            location: t("global-management-desc"),
            ref: "CORPORATE",
            supportEmail: "zafiro@zafiro.tv",
            supportPhone: "1234",
            timeZone: "UTC",
            global: true,
        });
    }
    return projects;
};

export default LoginSuperUser;
