import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
//Components
import UseSelect from "./useSelect";
import UseSelectSearch from "./useSelectSearch";
import UseCheckboxes from "./useCheckboxes";
import UseButton from "../useButton";
import UseFilterDate from "./useFilterDate";
import UseFilterScheduling from "./useFilterScheduling";
//Actions
import { setActionName, setActionItemAffected, setActionData } from "../../actions/globalActions";
import { openModal, setModalContent } from "../../actions/uiActions";
import { showRowForm } from "../../actions/sectionActions";
import { cleanCheckboxDropdownNames } from "../../actions/uiActions";
import { setCheckboxDropdownFilter } from "../../actions/tableActions";
//Utils
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { parseBoolean } from "../../hooks/Utils/Utils";

const UseFilters = () => {
    const { t } = useTranslation();
    const { availableFilters, dateHeader, headerButtons } = useSelector((state) => state.table);
    const { rowForm } = useSelector((state) => state.sectionContent);

    //History
    const history = useHistory();

    //Data
    const { id } = useParams();

    //Listeners
    useEffect(() => {
        return () => {
            dispatch(cleanCheckboxDropdownNames());
            dispatch(setCheckboxDropdownFilter(null));
        };
        // eslint-disable-next-line
    }, []);

    //Actions
    const dispatch = useDispatch();

    const handleClickNew = (e) => {
        if (!parseBoolean(e.currentTarget.dataset.disabled)) {
            console.log(e.currentTarget.dataset);
            if (rowForm) {
                toast.error(t("save-before-continue"));
            } else if (e.currentTarget.dataset.link) {
                history(e.currentTarget.dataset.link);
            } else {
                dispatch(setActionName(e.currentTarget.dataset.action));
                //Check if the button has a modal assigned
                const cButton = headerButtons.filter((button) => button.value === e.currentTarget.dataset.action);
                if (cButton && cButton.length > 0 && cButton[0].modal && cButton[0].modal.length > 0) {
                    if (cButton[0].modal[0]?.beforeModalOpen) {
                        cButton[0].modal[0].beforeModalOpen();
                    }
                    dispatch(setModalContent(cButton[0].modal[0]));
                    dispatch(openModal());
                    return;
                }
                if (cButton && cButton.length > 0 && cButton[0].redirect) {
                    history(cButton[0].redirect);
                    return;
                }

                if (id) {
                    dispatch(setActionItemAffected([id]));
                }
                if (
                    e.currentTarget.dataset.action === "new-cz-area" ||
                    e.currentTarget.dataset.action === "new-cz-category"
                ) {
                    dispatch(setActionData({ name: "" }));
                }
                dispatch(showRowForm(true));
            }
        }
    };

    return (
        <>
            {availableFilters && availableFilters.length > 0 ? (
                <>
                    <div className="inline-block pr-4 pl-6 first-capital mb-2 float-left mt-2">{t("filter-by")}:</div>
                    {availableFilters.map((filter, index) => {
                        return (
                            <div
                                className={`inline-block w-filter-checkbox float-left ${
                                    filter.adjust ? filter.adjust : ""
                                }`}
                                key={index}
                            >
                                {filter.filterType === "checkboxDropdown" ? <UseCheckboxes index={index} /> : null}
                                {filter.filterType === "selectWithSearch" ? (
                                    <UseSelectSearch index={index} value={filter.value} />
                                ) : null}
                                {filter.filterType === "select" ? <UseSelect index={index} /> : null}
                                {filter.filterType === "date" ? <UseFilterDate index={index} /> : null}
                                {filter.filterType === "scheduling" ? <UseFilterScheduling index={index} /> : null}
                            </div>
                        );
                    })}
                </>
            ) : null}
            {!dateHeader && headerButtons ? (
                <div className="inline-block ml-8 text-base font-normal absolute right-0 top-0">
                    {headerButtons.map((headerButton, index) => (
                        <div
                            className={headerButton.adjust}
                            onClick={
                                headerButton?.customAction
                                    ? () => {
                                          headerButton.customAction();
                                      }
                                    : handleClickNew
                            }
                            id={`${headerButton.value}-button`}
                            data-action={headerButton.value}
                            key={index}
                            data-disabled={headerButton.disabled}
                            data-tip={t("{{capitalize, capitalize}}", { capitalize: t(headerButton.tooltip) })}
                            data-for="default-tooltip"
                            data-link={headerButton.link}
                        >
                            <UseButton
                                buttonName={headerButton.name}
                                icon={headerButton.icon}
                                buttonColor={headerButton.color}
                                disabled={headerButton.disabled}
                                adjust={"ml-4 w-124px px-2"}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default UseFilters;
