import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate as useHistory } from "react-router-dom";

import useSelect from "../../useSelect";
import { useTranslation } from "react-i18next";

//Actions
import { setCurrentDevice, cleanLayout, setCurrentLang } from "../../../actions/designActions";

const DevicesSubmenu = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const Select = useSelect();
    const { t } = useTranslation();

    //Params
    const { id, type: screenType, screenid, lang, landingid } = useParams();

    //Store data
    const { cDevice, cScreenData } = useSelector((state) => state.design);
    const { permissions } = useSelector((state) => state.ui);
    const [saveDeviceType, setSaveDeviceType] = useState(0);

    const products = permissions ? permissions.product : null;
    const tvEnabled = products?.tv;
    const mobileEnabled = products?.mobile;
    const castEnabled = products?.cast;
    const wifiEnabled = products?.wifi;

    useEffect(() => {
        if (screenType === "GENERAL" && devices && cDevice) {
            setDevice(saveDeviceType);
        }
        // eslint-disable-next-line
    }, [cDevice]);

    const isCustType = screenType === "GENERAL" || screenType === "TV" || screenType === "MOBILE";
    const devices = cScreenData?.data?.devices
        ? cScreenData.data.devices.filter((d) => {
              switch (d.type) {
                  case "Mobile":
                      if (isCustType) {
                          return mobileEnabled;
                      } else {
                          return mobileEnabled || castEnabled || wifiEnabled;
                      }
                  case "Desktop":
                      return wifiEnabled;
                  case "TV":
                      if (isCustType) {
                          return tvEnabled;
                      } else {
                          return tvEnabled || castEnabled;
                      }
                  default:
                      return false;
              }
          })
        : null;
    const selectedDevice = cDevice ? cDevice.type : devices?.length > 0 ? devices[0].type : null;

    const getDeviceIcon = (deviceType) => {
        switch (deviceType) {
            case "Desktop":
                return "uniE906";
            case "TV":
                return "interface";
            case "Mobile":
            case "Mobile med":
                return "mobile";
            default:
                return null;
        }
    };
    const setDevice = (idx) => {
        dispatch(cleanLayout());
        dispatch(setCurrentDevice(devices ? devices[idx] : null));
    };

    const changeScreenLang = (newLang) => {
        history(`/design/${id}/screen/${screenType}/${screenid}/${newLang}${landingid ? `/${landingid}` : ""}`);
        dispatch(setCurrentLang(newLang));
        Select.close();
    };

    return (
        <div id="devicesSubmenu" className="border-b z-201  flex border-gray-200 bg-white">
            <div className="border-r border-gray-20  flex w-10/12 bg-white ">
                {devices
                    ? devices.map((device, idx) => {
                          return (
                              <div
                                  key={device.type}
                                  className={`icon  icon-${getDeviceIcon(
                                      device.type
                                  )} mx-2 mt-3 cursor-pointer px-2 text-3xl font-bold ${
                                      selectedDevice === device.type ? "text-zafiro-600 border-b border-zafiro-600" : ""
                                  }
                            `}
                                  onClick={(e) => {
                                      setDevice(idx);
                                      setSaveDeviceType(idx);
                                  }}
                                  id={`button-${device.type}`}
                              ></div>
                          );
                      })
                    : null}
            </div>
            {screenid && cScreenData ? (
                <div className="border-r float-right w-2/12 border-l-0 border-gray-200">
                    <Select.Handler className="relative">
                        <div className=" whitespace-no-wrap table w-full items-center px-4 py-3  leading-tight">
                            <div className="table-cell align-middle">
                                <div className="icon icon-information float-left mr-4 text-3xl"></div>
                                <div className="float-left pt-1"> {lang ? t("language:" + lang) : ""}</div>
                            </div>
                            <div className="table-cell align-middle">
                                <div className="float-right pl-2 text-gray-700">
                                    <i className="icon-chevron"></i>
                                </div>
                            </div>
                        </div>
                    </Select.Handler>

                    <Select.Body className="dropdown-body-flags max-h-80 w-inherit absolute overflow-y-scroll text-center">
                        {cScreenData && cScreenData.langs
                            ? cScreenData.langs.map((option, index) => {
                                  return (
                                      <div
                                          className="dropdown-item flex justify-between pl-12"
                                          key={index}
                                          data-iso={option.languageRef}
                                          onClick={(e) => changeScreenLang(option.languageRef)}
                                      >
                                          {option.languageRef ? t("language:" + option.languageRef) : ""}
                                          {option.languageRef === lang ? (
                                              <i className="icon-tick-right flex items-center justify-items-center"></i>
                                          ) : null}
                                      </div>
                                  );
                              })
                            : null}
                    </Select.Body>
                </div>
            ) : null}
        </div>
    );
};

export default DevicesSubmenu;
