import React from "react";

//Components
import IconInfo from "./IconInfo";
import IconSettings from "./IconSettings";

const IconsBar = () => {
    return (
        <div className="flex">
            <div className="float-left">
                <IconInfo />
            </div>
            <div className="float-left">
                <IconSettings />
            </div>
        </div>
    );
};

export default IconsBar;
