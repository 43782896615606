export const SET_HOTEL_SEARCH = "SET_HOTEL_SEARCH";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_IMPERSONATED = "SET_IMPERSONATED";

export function setHotelSearch(term) {
    //Action to load in auth store searh term in project selection screen
    return (dispatch) => {
        dispatch({
            type: SET_HOTEL_SEARCH,
            payload: term,
        });
    };
}

export function setCurrentProjectsPage(page) {
    //Action to load in auth store current page (project pagination) in project selection screen
    return (dispatch) => {
        dispatch({
            type: SET_CURRENT_PAGE,
            payload: page,
        });
    };
}

export function setImpersonated(val) {
    //loads in store.ui.sidebarSection an string with the side bar section
    return (dispatch) => {
        dispatch({
            type: SET_IMPERSONATED,
            payload: val,
        });
    };
}

export function setToken(token) {
    //Action to load in auth store current page (project pagination) in project selection screen
    return (dispatch) => {
        dispatch({
            type: SET_TOKEN,
            payload: token,
        });
    };
}
