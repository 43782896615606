import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction } from "../../../actions/globalActions";
import { setLoading, cleanCheckedItems } from "../../../actions/tableActions";
import { showGlobalLoading } from "../../../actions/uiActions";

//Utils
import { toast } from "react-toastify";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../../hooks/Utils/Utils";

import { useTranslation } from "react-i18next";
import UseToast from "../../../components/Notifications/useToast";
import { useLibraryParams } from "../../../components/Section/MediaLibrary/MediaLibrary";

const UpdateMediaLibrary = ({ client, viewMode }) => {
    const { t } = useTranslation();
    //Store data
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { itemsAffected } = useSelector((state) => state.action);

    //Params
    const { parentRef } = useLibraryParams();

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            if (
                err.includes("already exists") ||
                err.includes("name not allowed") ||
                err.includes("extension must be") ||
                err.includes("name is empty") ||
                err.includes("before uploading new files") ||
                err.includes("before set the asset as") ||
                err.includes("unknown extension")
            ) {
                toast.error(t(err));
                return;
            }
            toast.error(t("mutation-error"));
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            executeQuery.queryName = "";
            let mutationFragment = "";
            switch (executeQuery.action) {
                case "media-library-add-folder":
                    setMutation(`
                    mutation{
                        createLibraryFolder(
                            parentRef: "${parentRef}",
                            name: "${escapeSpecialChars(actionData.values["media-library-name"])}"
                        ){ error, ok, response{ ref, name } }}
                    `);
                    execute = true;
                    executeQuery.mutationName = "createLibraryFolder";
                    executeQuery.goBack = false;
                    dispatch(cleanCheckedItems());
                    dispatch(showGlobalLoading(true));
                    break;
                case "media-library-edit-folder":
                    setMutation(`
                    mutation{
                        updateLibraryFolder(
                            ref: "${itemsAffected[0]}"
                            parentRef: "${parentRef}",
                            name: "${escapeSpecialChars(actionData.values["media-library-name"])}"
                        ){ error, ok, response{ ref, name } }}
                    `);
                    execute = true;
                    executeQuery.mutationName = "updateLibraryFolder";
                    executeQuery.goBack = false;
                    dispatch(showGlobalLoading(true));
                    break;
                case "media-library-edit-media":
                    setMutation(`
                    mutation{
                        updateLibraryAsset(
                            ref: "${itemsAffected[0]}"
                            name: "${escapeSpecialChars(actionData.values["media-library-name"])}"
                            folderRef: "${parentRef}"
                        ){ error, ok, response{ ref, name } }}
                    `);
                    execute = true;
                    executeQuery.mutationName = "updateLibraryAsset";
                    executeQuery.goBack = false;
                    dispatch(showGlobalLoading(true));
                    break;
                case "media-library-update-media":
                    mutationFragment = `mutation{updateLibraryAsset(ref:"${executeQuery.params.id}"`;
                    if (actionData.values["file"]) {
                        mutationFragment += `,file:"${actionData.values["file"]}",fileType:"${actionData.values["fileType"]}",fileName:"${actionData.values["fileName"]}"`;
                    } else if (executeQuery.params && executeQuery.params.file) {
                        mutationFragment += `,file:"${executeQuery.params.file}",fileType:"${executeQuery.params.fileType}",fileName:"${executeQuery.params.fileName}"`;
                    }
                    if (actionData.values["language"]) {
                        mutationFragment += `,fileLanguage:"${actionData.values["language"]}"`;
                    } else if (executeQuery.params && executeQuery.params.language) {
                        mutationFragment += `,fileLanguage:"${executeQuery.params.language}"`;
                    }
                    if (actionData.values["name"]) {
                        mutationFragment += `,name:"${actionData.values["name"]}"`;
                    } else if (executeQuery.params && executeQuery.params.name) {
                        mutationFragment += `,name:"${executeQuery.params.name}"`;
                    }
                    if (actionData.values["folderRef"]) {
                        mutationFragment += `,folderRef:"${actionData.values["folderRef"]}"`;
                    } else if (executeQuery.params && executeQuery.params.folderRef) {
                        mutationFragment += `,folderRef:"${executeQuery.params.folderRef}"`;
                    }
                    if (executeQuery.params && executeQuery.params.defaultLanguage) {
                        if (executeQuery.params.defaultLanguage !== "notC") {
                            mutationFragment += `,defaultLanguage:"${executeQuery.params.defaultLanguage}"`;
                        }
                    } else if (actionData.values["defaultLanguage"]) {
                        mutationFragment += `,defaultLanguage:"${actionData.values["defaultLanguage"]}"`;
                    } else if (actionData.values["assetDefaultLanguage"]) {
                        mutationFragment += `,defaultLanguage:"${actionData.values["assetDefaultLanguage"]}"`;
                    }
                    mutationFragment += `){ error, ok, response{ ref, name } }}`;
                    setMutation(mutationFragment);
                    execute = true;
                    executeQuery.mutationName = "updateLibraryAsset";
                    executeQuery.goBack = true;
                    dispatch(showGlobalLoading(true));
                    break;
                case "media-library-update-video":
                    mutationFragment = `mutation{updateLibraryVideo(ref:"${executeQuery.params.id}"`;

                    /*if (actionData.values["language"]) {
                        mutationFragment += `,fileLanguage:"${actionData.values["language"]}"`;
                    } else if (executeQuery.params && executeQuery.params.language) {
                        mutationFragment += `,fileLanguage:"${executeQuery.params.language}"`;
                    }*/
                    if (actionData.values["name"]) {
                        mutationFragment += `,name:"${actionData.values["name"]}"`;
                    } else if (executeQuery.params && executeQuery.params.name) {
                        mutationFragment += `,name:"${executeQuery.params.name}"`;
                    }
                    if (actionData.values["folderRef"]) {
                        mutationFragment += `,folderRef:"${actionData.values["folderRef"]}"`;
                    } else if (executeQuery.params && executeQuery.params.folderRef) {
                        mutationFragment += `,folderRef:"${executeQuery.params.folderRef}"`;
                    }
                    if (executeQuery.params && executeQuery.params.defaultLanguage) {
                        if (executeQuery.params.defaultLanguage !== "notC") {
                            mutationFragment += `,defaultLanguage:"${executeQuery.params.defaultLanguage}"`;
                        }
                    } else if (actionData.values["defaultLanguage"]) {
                        mutationFragment += `,defaultLanguage:"${actionData.values["defaultLanguage"]}"`;
                    } else if (actionData.values["assetDefaultLanguage"]) {
                        mutationFragment += `,defaultLanguage:"${actionData.values["assetDefaultLanguage"]}"`;
                    }
                    mutationFragment += `){ error, ok, response{ ref, name } }}`;
                    setMutation(mutationFragment);
                    execute = true;
                    executeQuery.mutationName = "updateLibraryVideo";
                    executeQuery.goBack = true;
                    dispatch(showGlobalLoading(true));
                    break;
                default:
                    execute = false;
                    break;
            }
        }
        if (execute) {
            /*if (!executeQuery.hideLoading) {
                dispatch(setLoading(true));
            }*/
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (checkMutationSuccess(data)) {
            if (viewMode && viewMode === "assetDetail") {
                if (
                    executeQuery.action === "media-library-update-media" &&
                    (actionData.values["file"] || (executeQuery.params && executeQuery.params.file))
                ) {
                    toast.success(
                        <UseToast
                            title={t("success")}
                            msgs={[
                                {
                                    text: t("operation-successful"),
                                    textAdjust: "inline-grid w-10/12 t-link font-bold",
                                    icon: {
                                        name: "save-circle",
                                        values: "ok",
                                    },
                                    iconAdjust: "inline-grid w-1/12",
                                },
                            ]}
                        />,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 50000,
                            hideProgressBar: true,
                            icon: false,
                            className: "use-toast",
                        }
                    );
                } else {
                    toast.success(t("operation-successful"));
                }
                dispatch(showGlobalLoading(false));
                dispatch(setRefreshContentData(true));
            } else {
                let textLabel = "";
                switch (executeQuery.mutationName) {
                    case "createLibraryAsset":
                        textLabel = "file x created";
                        break;
                    case "updateLibraryAsset":
                    case "updateLibraryVideo":
                        textLabel = "file x updated";
                        break;
                    case "createLibraryFolder":
                        textLabel = "folder x created";
                        break;
                    case "updateLibraryFolder":
                        textLabel = "folder x updated";
                        break;
                    default:
                        break;
                }
                if (textLabel) {
                    toast.success(
                        <UseToast
                            title={t("success")}
                            msgs={[
                                {
                                    text: t(textLabel, { name: actionData.values["media-library-name"] }),
                                    textAdjust: "inline-grid w-10/12 t-link font-bold",
                                    iconAdjust: "inline-grid w-1/12",
                                },
                            ]}
                        />,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 50000,
                            hideProgressBar: true,
                            icon: false,
                            className: "use-toast",
                        }
                    );
                }
                dispatch(setRefreshContentData(true));
                removeApolloCacheKeys(client.cache, "libraryContents");
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
        } else {
            let error = checkMutationErrors(data, t);
            if (error !== "") {
                let toastFormat = "traditional";
                let textLabel = "";
                switch (executeQuery.mutationName) {
                    case "createLibraryAsset":
                        toastFormat = "new";
                        textLabel = "file x created error";
                        break;
                    case "updateLibraryAsset":
                    case "updateLibraryVideo":
                        toastFormat = "new";
                        textLabel = "file x updated error";
                        break;
                    case "createLibraryFolder":
                        textLabel = "folder x created error";
                        break;
                    case "updateLibraryFolder":
                        textLabel = "folder x updated error";
                        break;
                    default:
                        break;
                }
                if (error === t("mutation-error")) {
                    if (textLabel !== "") {
                        error = t(textLabel, { name: actionData.values["media-library-name"] });
                    }
                }
                if (toastFormat === "traditional") {
                    toast.error(error);
                } else {
                    toast.error(
                        <UseToast
                            title={t("error")}
                            msgs={[
                                {
                                    text: error,
                                    textAdjust: "inline-grid w-10/12 t-link font-bold",
                                    icon: {
                                        name: "delete-circle",
                                        values: "ok",
                                    },
                                    iconAdjust: "inline-grid w-1/12",
                                },
                            ]}
                        />,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 50000,
                            hideProgressBar: true,
                            icon: false,
                            className: "use-toast",
                        }
                    );
                }
                dispatch(setExecuteQuery(null));
                dispatch(cleanAction());
                dispatch(showGlobalLoading(false));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    const checkMutationErrors = (data, t) => {
        if (data && data[executeQuery.mutationName] && data[executeQuery.mutationName].error !== "") {
            const err = data[executeQuery.mutationName].error;
            console.log(err);
            if (
                err.includes("already exists") ||
                err.includes("name not allowed") ||
                err.includes("extension must be") ||
                err.includes("name is empty") ||
                err.includes("before uploading new files") ||
                err.includes("before set the asset as") ||
                err.includes("unknown extension") ||
                err.includes("content type should be")
            ) {
                return t("general:" + err);
            }
            return t("mutation-error");
        }
        return "";
    };

    const checkMutationSuccess = (data) => {
        //returns true if mutation success
        let res = false;
        if (data && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok) {
            res = true;
        }
        return res;
    };

    return null;
};

export default withApollo(UpdateMediaLibrary);
