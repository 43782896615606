import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import RGL, { WidthProvider } from "react-grid-layout";

//Components
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext, WidgetProvider } from "../../../../contexts/editor";
import WidgetImage from "../Widgets/WidgetImage";
import WidgetLogo from "../Widgets/WidgetLogo";
import GridItemToolbar from "../Widgets/WidgetToolbars/GridItemToolbar";
import WidgetStyle from "zrender/widgetStyle";

//Actions
import {
    setItemSelected,
    setWidgetToolbarVisible,
    setDesignToolbarVisible,
    updateContainerItemPos,
    setContainerInEdition,
    setColorPickerReference,
} from "../../../../actions/designActions";

import { setExecuteQuery } from "../../../../actions/sectionActions";

//Utils
import {
    ensureToolbarPos,
    GetThemeDefault,
    calculateContainerSizes,
    ensureWidgetOptionsPos,
    vw2px,
    showAndHideResizableHandleWidget,
} from "../../../../hooks/Utils/DesignUtils";
import { Session } from "../../../../hooks/Utils/Session";
import { DEVICE, SCREEN } from "../../../../hooks/Utils/Design/EditorUtils";

//API
import { toast } from "react-toastify";
import WidgetMessages from "./WidgetMessages";
import WidgetClock from "./WidgetClock";
import WidgetTVMenuNav from "./WidgetTVMenuNav";
import WidgetAlarm from "./WidgetAlarm";

const ReactGridLayout = WidthProvider(RGL);

const WidgetTVMenu = ({ widgets }) => {
    const { deviceType, editorSize, itemSelected, theme, screenType } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const size = (s) => vw2px(s, editorSize ? editorSize.width : null, deviceType === "Mobile" ? 3 : 1);
    const {
        gridLayout,
        widgetToolbarVisibleTab: tab,
        widgetToolbarVisible,
        gridItems,
        editingMenu,
        resizeWindowMenu,
        editorMode,
        containersLayouts,
        cScreenData,
    } = useSelector((state) => state.design);
    const { modal } = useSelector((state) => state.ui);

    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });

    const screenPos = cScreenData?.data ? cScreenData.data.pos : null;
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const borderWidth = style ? style.borderWidth : 0;
    const bgColor = style?.bgColor ? style.bgColor : defaultBgColor;
    const isGeneralTV = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.TV ? true : false;

    const menuStyle = new WidgetStyle(type, theme);
    menuStyle.fill(style);

    const parentStatusStyles = {
        active: {
            ...menuStyle.export().status.active,
        },
        focus: {
            ...menuStyle.export().status.focus,
        },
    };
    const widgetType = Session.getSessionProp("widgetTypeDrag");

    const margin = data?.margin ? data.margin : 10;
    const itemsMargin = data?.itemsMargin ? data.itemsMargin : 10;

    const [containerSize, setContainerSize] = useState(null);
    const [layoutCopy, setLayoutCopy] = useState(null);
    const [cDroppingItem, setCDroppingItem] = useState({ i: "-1", type: "undefined", w: 0, h: 0 });
    const [statusStyle, setStatusStyle] = useState(null);
    const [onResize, setOnResize] = useState(false);

    const status = statusStyle === "activeColor" ? "active" : statusStyle === "focusColor" ? "focus" : null;

    const gItemRef = useRef(widget);
    gItemRef.current = widget;

    const ensureToolbarPositions = () => {
        if (itemSelected) {
            ensureToolbarPos(itemSelected);
            ensureWidgetOptionsPos(itemSelected, editorSize);
        }
    };

    const hideTools = (container) => {
        dispatch(setDesignToolbarVisible(null));
        dispatch(setContainerInEdition(container));
        dispatch(setColorPickerReference(null));
        if (widgetToolbarVisible !== "text-settings") {
            dispatch(setWidgetToolbarVisible(null));
        }
    };

    useEffect(() => {
        ensureToolbarPositions();
        if (widgetToolbarVisible === null) {
            setStatusStyle(null);
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (editingMenu === false && !modal) {
            dispatch(setItemSelected(null));
        }
        // eslint-disable-next-line
    }, [editingMenu]);

    useEffect(() => {
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [gridLayout, margin, borderWidth, itemsMargin]);

    useEffect(() => {
        const w = layoutCopy ? layoutCopy.filter((item) => item.i === itemSelected)[0] : null;
        dispatch(setWidgetToolbarVisible(w?.data && w.type === "TEXT" ? "text-settings" : null));
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        document.querySelector(`#widget_zone_${widgetID}`).style.backgroundColor = "transparent";
        document.querySelector(`#widget_zone_${widgetID}`).style.borderColor = "";
        document.querySelector(`#widget_zone_${widgetID}`).style.borderWidth = 0;
        document.querySelector(`#widget_zone_${widgetID}`).style.borderRadius = 0;
        document.querySelector(`#widget_zone_${widgetID}`).style.overflow = "visible";
    }, [gridItems, widgetID]);

    useEffect(() => {
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [editorSize]);

    useEffect(() => {
        const toolbarMenu = document.querySelector("#toolbar_" + widgetID);

        if (isGeneralTV && toolbarMenu) {
            toolbarMenu.style.display = "none";
        }

        // eslint-disable-next-line
    }, [isGeneralTV]);

    useEffect(() => {
        //Al cambiar el tamaño del container hay que volver calcular tamaños y refrescar
        reCalcutateSizes();
        // eslint-disable-next-line
    }, [screenPos]);

    useEffect(() => {
        if (containerSize) {
            let factor = 3;
            let h = Math.max(1, parseInt(containerSize.rows / factor));
            let w = Math.max(1, parseInt(containerSize.cols / factor));
            switch (deviceType) {
                case "Desktop":
                case "TV":
                    if (widgetType === "QR") {
                        factor = 4;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 1.75));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 40));
                    } else if (widgetType === "BUTTON") {
                        h = Math.max(1, parseInt(containerSize.rows / factor / 2));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 30));
                    } else if (widgetType === "ICON") {
                        factor = 4;
                        h = Math.max(1, parseInt(containerSize.rows / factor / 2.2));
                        w = Math.max(1, parseInt(containerSize.cols / factor - 45));
                    } else if (widgetType === "LOGO") {
                        h = Math.max(1, parseInt(containerSize.rows / 2));
                        w = Math.max(1, parseInt(containerSize.cols / 10));
                    }
                    break;

                default:
                    break;
            }

            setCDroppingItem({ i: "-1", type: "undefined", w, h });
        }
        // eslint-disable-next-line
    }, [containerSize, widgetType]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            setStatusStyle(getStatusStyle(tab));
        } else {
            setStatusStyle(null);
        }
        // eslint-disable-next-line
    }, [tab]);

    useEffect(() => {
        //Se utiliza esto en lugar de gridLayout directamente porque
        //sino la librería no renderiza bien los items cuando hay cambios en gridlayout
        if (containersLayouts) {
            const layout = JSON.parse(
                JSON.stringify(
                    containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0]
                        ? containersLayouts.filter((layout) => parseInt(layout.i) === parseInt(widgetID))[0].layout
                        : []
                )
            ).filter((copy) => {
                copy.isDraggable = editorMode === "EDITION";
                copy.isDroppable = copy.isDraggable;
                copy.isResizable = copy.isDraggable;
                copy.resizeHandles = copy.isResizable ? ["se"] : null;
                return copy;
            });
            setLayoutCopy(layout);
        }
        // eslint-disable-next-line
    }, [containersLayouts]);

    const getStatusStyle = (tab) => {
        let statusStyle = "";
        switch (tab) {
            case "regular":
            case "defaultColor":
                statusStyle = "defaultColor";
                break;
            case "activeColor":
                statusStyle = "activeColor";
                break;
            case "focusColor":
                statusStyle = "focusColor";
                break;
            default:
                break;
        }
        return statusStyle;
    };

    const reCalcutateSizes = () => {
        if (gItemRef.current?.data) {
            setTimeout(() => {
                calculateContainerSizes(widgetID, gItemRef.current, setContainerSize);
                const evt = document.createEvent("UIEvents");
                evt.initUIEvent("resize", true, false, window, 0);
                window.dispatchEvent(evt);
            }, 10);
        }
    };

    const layoutChange = (layout) => {
        //mientras hago drag de elementos externos no actualizo layout
        if (layout.filter((item) => item.i === "__dropping-elem__").length > 0) {
            return;
        }
    };

    const onDragStart = (e) => {
        if (itemSelected) {
            const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
            const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
            if (toolbar) toolbar.classList.add("invisible");
            if (editorToolbar) editorToolbar.style.visibility = "hidden";
        }
    };

    const onDragStop = (layout, oldDragItem, newDragItem) => {
        setTimeout(() => {
            if (itemSelected) {
                const toolbar = document.querySelector(`#toolbar_${itemSelected}`);
                const editorToolbar = document.querySelector(`#widget_${itemSelected} .rdw-editor-toolbar`);
                if (toolbar) toolbar.classList.remove("invisible");
                if (editorToolbar) editorToolbar.style.visibility = "visible";
                ensureToolbarPositions();
            }
        }, 500);
        updateLayout(layout, oldDragItem, newDragItem);
    };
    const updateLayout = (layout, oldDragItem, newDragItem) => {
        if (
            oldDragItem.x !== newDragItem.x ||
            oldDragItem.y !== newDragItem.y ||
            oldDragItem.w !== newDragItem.w ||
            oldDragItem.h !== newDragItem.h
        ) {
            //check if new posics are inside the edition area
            let editorContainer = document.querySelector(`#container_${widgetID}`);
            let widget = document.querySelector(`#widget_${newDragItem.i}`);
            const editorPos = editorContainer.getClientRects()[0];
            const widgetPos = widget.getClientRects()[0];

            const prevPos = {
                x: oldDragItem.x,
                y: oldDragItem.y,
                w: oldDragItem.w,
                h: oldDragItem.h,
            };
            const newPos = {
                x: newDragItem.x,
                y: newDragItem.y,
                w: newDragItem.w,
                h: newDragItem.h,
            };

            //Si al hacer el resize el widget queda fuera del editor, se cancela
            if (widgetPos.y + widgetPos.height > editorPos.y + editorPos.height + 5 && onResize) {
                dispatch(updateContainerItemPos(widgetID, newDragItem.i, newPos));

                setTimeout(() => {
                    dispatch(updateContainerItemPos(widgetID, newDragItem.i, prevPos));
                    toast.error(t("place-into-container-area"));
                }, 100);
                return;
            }

            ensureToolbarPositions();
            dispatch(
                setExecuteQuery({
                    action: "updateContainerWidgetPos",
                    params: {
                        containerId: widgetID,
                        widgetID: newDragItem.i,
                        widgetPos: newPos,
                        prevWidgetPos: prevPos,
                    },
                })
            );
        }
    };

    const onDrop = (layout, dropLayoutItem, _event) => {
        _event.stopPropagation();
        if (!Session.getSessionProp("widgetTypeDrag")) {
            return;
        }
        if (Session.getSessionProp("widgetTypeDrag") === "CONTAINER") {
            toast.error(t("can not add container"));
            return;
        }
        if (!dropLayoutItem) return;
        if (!dropLayoutItem.x) dropLayoutItem.x = 0;
        if (!dropLayoutItem.y) dropLayoutItem.y = 0;

        const menu = document.querySelector(".widget-tv-menu");
        const newItem = document.querySelector(".react-grid-placeholder");

        const menuPos = menu?.getClientRects()[0];
        const newItemPos = newItem?.getClientRects()[0];

        if (newItemPos.y + newItemPos.height > menuPos.y + menuPos.height) {
            toast.error(t("place-into-edition-area"));
            return;
        }

        dispatch(
            setExecuteQuery({
                action: "createWidget",
                params: {
                    containerID: widgetID,
                    widgetType: Session.getSessionProp("widgetTypeDrag"),
                    widgetPos: {
                        x: dropLayoutItem.x,
                        y: dropLayoutItem.y,
                        w: dropLayoutItem.w < containerSize.cols ? dropLayoutItem.w : containerSize.cols,
                        h: dropLayoutItem.h < containerSize.rows ? dropLayoutItem.h : containerSize.rows,
                    },
                    style: { bgColor: "rgba(255,255,255,0.0)" },
                },
            })
        );
    };

    const showItemOptions = (id) => {
        if (editorMode === "PREVIEW") {
            return;
        }
        dispatch(setItemSelected(id));
    };

    const selectItemResized = () => {
        const items = document.querySelectorAll(":hover");
        if (!items) {
            return;
        }
        Array.from(items).map((item) =>
            item.id.indexOf("widget_") > -1 ? dispatch(setItemSelected(item.id.replace("widget_", ""))) : null
        );
    };

    return (
        <>
            {widget && layoutCopy && containerSize && containerSize.height ? (
                <>
                    <div
                        id={`container_${widgetID}`}
                        className="widget-tv-menu relative bg-cover bg-center bg-no-repeat"
                        style={{
                            height: containerSize.height,
                            width: containerSize.width,
                            opacity: style?.opacity ? style.opacity / 100 : 1,
                            backgroundColor: bgColor,
                            borderRadius: style?.radius ? size(style.radius) + "px" : "",
                        }}
                    ></div>
                    <div
                        className="relative"
                        style={{
                            height: containerSize.height,
                            width: containerSize.width,
                            marginTop: `-${containerSize.height}px`,
                        }}
                        onClick={(e) => {
                            showItemOptions(widgetID);
                            hideTools(null);
                            showAndHideResizableHandleWidget(
                                widgetID,
                                "visible",
                                ".widget-menu-container .react-grid-item"
                            );
                            e.stopPropagation();
                        }}
                    >
                        <ReactGridLayout
                            style={{
                                height: containerSize.height,
                                width: containerSize.width,
                                outline:
                                    itemSelected !== widgetID &&
                                    !resizeWindowMenu &&
                                    (!borderWidth || (borderWidth && style && !style.borderColor))
                                        ? "1px dashed #ccc"
                                        : "",
                            }}
                            layout={layoutCopy}
                            cols={containerSize.cols}
                            rowHeight={containerSize.rowHeight}
                            useCSSTransforms={false}
                            isBounded={true}
                            onResizeStart={(e) => selectItemResized()}
                            preventCollision={true}
                            onLayoutChange={layoutChange}
                            onDrop={onDrop}
                            isDroppable={editorMode === "EDITION" && editingMenu === true}
                            droppingItem={cDroppingItem}
                            draggableHandle={".container-item-label"}
                            onDragStart={onDragStart}
                            onDragStop={onDragStop}
                            onResize={() => {
                                ensureToolbarPositions();
                                setOnResize(true);
                            }}
                            onResizeStop={(layout, oldItem, newItem) => {
                                setOnResize(false);
                                updateLayout(layout, oldItem, newItem);
                            }}
                            margin={[itemsMargin.w, itemsMargin.h]}
                            containerPadding={[margin.w, margin.h]}
                            compactType={null}
                        >
                            {layoutCopy.map((copy) => {
                                const copyID = copy.i;
                                const type = copy.type;
                                const curType = widgets.filter((widget) => widget.type === type);
                                const cWidget = gridItems.find((item) => item.i === copyID);
                                const draggable = copy.isDraggable;

                                const cStyle = Widget.ParseStyle(cWidget ? cWidget.widgetStyle : null, deviceType);
                                const cstyle = new WidgetStyle(type, theme);
                                cstyle.fill(cStyle);

                                return (
                                    <div
                                        id={`widget_${copyID}`}
                                        data-grid={{ x: copy.x, y: copy.y, w: copy.w, h: copy.h }}
                                        key={`${copyID}`}
                                        onClick={(e) => {
                                            if (editingMenu === true) {
                                                hideTools(widgetID);
                                                showItemOptions(copyID);
                                            }
                                            showAndHideResizableHandleWidget(
                                                widgetID,
                                                "hidden",
                                                ".widget-menu-container .react-grid-item"
                                            );
                                            e.stopPropagation();
                                        }}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        className={`react-grid-layout mb-40  ${
                                            itemSelected === copyID ? " widgetSelected" : ""
                                        } ${editorMode === "PREVIEW" || editingMenu === false ? "previewMode" : ""}`}
                                    >
                                        <WidgetProvider
                                            value={{
                                                id: copy ? copy.i : 0,
                                                type: copy ? copy.type : null,
                                                container: widget,
                                                style: cStyle,
                                                config: {
                                                    style: cstyle,
                                                    data: null,
                                                },
                                            }}
                                        >
                                            <Widget.Zone>
                                                {type === "LOGO" ? (
                                                    <WidgetLogo status={status} statusStyles={parentStatusStyles} />
                                                ) : type === "IMAGE" ? (
                                                    <WidgetImage status={status} statusStyles={parentStatusStyles} />
                                                ) : type === "MESSAGES" ? (
                                                    <WidgetMessages insideMenu={true} menuStatusStyle={statusStyle} />
                                                ) : type === "CLOCK" ? (
                                                    <WidgetClock insideMenu={true} menuStatusStyle={statusStyle} />
                                                ) : type === "TVMENUNAV" ? (
                                                    <WidgetTVMenuNav insideMenu={true} menuStatusStyle={statusStyle} />
                                                ) : type === "ALARM" ? (
                                                    <WidgetAlarm insideMenu={true} menuStatusStyle={statusStyle} />
                                                ) : null}
                                            </Widget.Zone>
                                            <GridItemToolbar />
                                            <div
                                                id={`item_label_${copyID}`}
                                                className={`${
                                                    draggable ? "container-item-label cursor-move " : "cursor-default"
                                                }${itemSelected === copyID ? "" : "hidden"}  z-500 absolute top-0`}
                                            >
                                                <div className="flex items-center">
                                                    <div className="rounded bg-zafiro-400 inline-block px-2 text-base text-white">
                                                        <span className="capitalize">
                                                            {curType.length > 0 ? t(curType[0].name) : "-"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </WidgetProvider>
                                    </div>
                                );
                            })}
                        </ReactGridLayout>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default WidgetTVMenu;
