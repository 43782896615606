import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

//Components
import UseSearch from "../components/useSearch";
import UseTitle from "../components/useTitle";
import UseMapItem from "./useMapItem";

//Utils
import { Session } from "../hooks/Utils/Session";

const InstallationMap = ({ res, filters, setFilters }) => {
    //States
    const [activeWing, setActiveWing] = useState(null);
    const [activeFloor, setActiveFloor] = useState(null);

    //Store
    const { langStrings, showGlobalLoading } = useSelector((state) => state.ui);

    //Actions
    const handleClickWing = (e) => {
        setActiveWing(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("map-active-wing", Number(e.currentTarget.dataset.id));

        let firstAvailableFloor = "";
        // eslint-disable-next-line
        res.map((wing) => {
            if (wing.id === activeWing) {
                firstAvailableFloor = wing.floors.length > 0 ? wing.floors[0].id : null;
            }
        });
        setActiveFloor(firstAvailableFloor);
        Session.setSessionProp("map-active-floor", firstAvailableFloor);
    };

    const handleClickFloor = (e) => {
        setActiveFloor(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("map-active-floor", Number(e.currentTarget.dataset.id));
    };

    const handleChangeSearch = (e) => {
        setFilters(e.target.value);
    };

    useEffect(() => {
        const pLocation = Session.getSessionProp("previous-location");

        if (pLocation.indexOf("room/detail") > -1) {
            setActiveFloor(Number(Session.getSessionProp("map-active-floor")));
            setActiveWing(Number(Session.getSessionProp("map-active-wing")));
        }
    }, []);

    useEffect(() => {
        const pLocation = Session.getSessionProp("previous-location");

        if (pLocation.indexOf("room/detail") > -1) {
            setActiveFloor(Number(Session.getSessionProp("map-active-floor")));
            setActiveWing(Number(Session.getSessionProp("map-active-wing")));
        } else {
            setActiveWing(res && res[0] ? res[0].id : null);
            setActiveFloor(res[0] && res[0].floors && res[0].floors[0] ? res[0].floors[0].id : null);

            if (!Session.getSessionProp("map-active-floor") && res[0] && res[0].floors && res[0].floors[0]) {
                Session.setSessionProp("map-active-floor", res[0].floors[0].id);
            }
            if (!Session.getSessionProp("map-active-wing") && res && res[0]) {
                Session.setSessionProp("map-active-wing", res[0].id);
            }
        }
    }, [res]);

    return (
        <div id="main-container" className="main-container">
            <div id="filters-container" className="t-filters-container">
                <div id="filter-row" className="t-filters-row">
                    <div>
                        <UseSearch
                            id={"installation-map-input-search"}
                            handleChangeSearch={handleChangeSearch}
                            placeholder="search-rooms"
                        />
                    </div>
                </div>
                <div className="mt-8">
                    {
                        /* Get Wings... */
                        res && res.length > 0 && !filters ? (
                            <>
                                <div>
                                    <div className="flex">
                                        <div>
                                            <UseTitle text={"wings"} adjust="text-gray-900 font-bold text-lg pb-2" />
                                        </div>
                                        <span id="wings-number" className="ml-2 text-gray-800">
                                            ({res.length})
                                        </span>
                                    </div>
                                </div>
                                {res.map((wing) => (
                                    <div
                                        className="inline-flex"
                                        key={wing.id}
                                        data-id={wing.id}
                                        onClick={handleClickWing}
                                    >
                                        <UseMapItem data={wing} active={activeWing === wing.id ? true : false} />
                                    </div>
                                ))}
                            </>
                        ) : res && !filters && !showGlobalLoading ? (
                            <div className="first-capital"> {langStrings["no-hotel-map"]}</div>
                        ) : null
                    }
                    {
                        /* Get Floors... */
                        res && res.length > 0 && !filters
                            ? res.map((wing, index) =>
                                  wing.id === activeWing ? (
                                      <div key={index}>
                                          <div>
                                              <div className="flex mt-6">
                                                  <div>
                                                      <UseTitle
                                                          text={"floors"}
                                                          adjust="text-gray-900 font-bold text-lg pb-2"
                                                      />
                                                  </div>
                                                  <span id="floor-number" className="ml-2 text-gray-800">
                                                      ({wing.floors.length})
                                                  </span>
                                              </div>
                                          </div>
                                          {wing.floors.map((floor) => (
                                              <div
                                                  className="inline-flex"
                                                  key={floor.id}
                                                  data-id={floor.id}
                                                  onClick={handleClickFloor}
                                              >
                                                  <UseMapItem
                                                      data={floor}
                                                      active={activeFloor === floor.id ? true : false}
                                                  />
                                              </div>
                                          ))}
                                      </div>
                                  ) : null
                              )
                            : null
                    }
                    {
                        /* Get Rooms... */
                        res && res.length > 0 && !filters
                            ? res.map((wing, index) =>
                                  wing.id === activeWing
                                      ? wing.floors.map((floor, index) =>
                                            floor.id === activeFloor ? (
                                                <div key={index}>
                                                    <div className="flex mt-6">
                                                        <div>
                                                            <UseTitle
                                                                text={"rooms"}
                                                                adjust="text-gray-900 font-bold text-lg pb-2"
                                                            />
                                                        </div>
                                                        <span id="rooms-number" className="ml-2 text-gray-800">
                                                            ({floor.rooms.length})
                                                        </span>
                                                    </div>
                                                    {floor.rooms.map((room, index) => (
                                                        <div className="inline-flex" key={index} data-id={room.id}>
                                                            <div className="">
                                                                <UseMapItem data={room} />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null
                                        )
                                      : null
                              )
                            : null
                    }
                    {
                        /* Search Rooms results... */
                        filters ? (
                            res.filter(
                                (wing) =>
                                    wing &&
                                    wing.floors &&
                                    wing.floors.filter((floor) => floor.rooms.length > 0).length > 0
                            ).length === 0 ? (
                                <div className="first-capital"> {langStrings["no-search-results"]}</div>
                            ) : (
                                res.map((wing, index) =>
                                    wing.floors.filter((floor) => floor.rooms.length > 0).length > 0 ? (
                                        <>
                                            <UseTitle text={wing.name} adjust="pt-6 text-gray-900 font-bold text-lg" />
                                            <div key={index}>
                                                {wing.floors.map((floor, index) =>
                                                    floor.rooms.length > 0 ? (
                                                        <>
                                                            <UseTitle
                                                                text={floor.name}
                                                                adjust="pt-4 text-gray-900 font-normal text-lg"
                                                            />
                                                            {floor.rooms.map((room, index) => (
                                                                <div
                                                                    className="inline-flex"
                                                                    key={index}
                                                                    data-id={room.id}
                                                                >
                                                                    <UseMapItem data={room} />
                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : null
                                                )}
                                            </div>
                                        </>
                                    ) : null
                                )
                            )
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default InstallationMap;
