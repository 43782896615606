import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table";
//Utils
import ListMessages from "../../../../hooks/GraphqlCalls/Customers/ListMessages";
import UpdateMessages from "../../../../hooks/GraphqlCalls/Customers/UpdateMessages";

const Messages = () => {
    ListMessages();
    return (
        <>
            <UpdateMessages />
            <UseSectionHeader title={["list", "of", "messages"]} />
            <UseTable />
        </>
    );
};

export default Messages;
