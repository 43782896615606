import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { Session } from "hooks/Utils/Session";
import Icon from "components/Icon";
import Button from "components/Button";
import { useModalEditProperty } from "./modals/useModalEditProperty";
import i18n from "i18n";
import { options } from "sanitize-html";

const useListProperties = () => {
    const currentUserRef = Session.getSessionProp("userRef");

    const [getUserAndData, { loading, data, error, refetch }] = useLazyQuery(QUERY, {
        variables: { ref: currentUserRef },
    });

    const { open: openModalEditProperty } = useModalEditProperty();

    const [properties, setProperties] = useState([]);
    const [propertiesGroups, setPropertiesGroups] = useState([]);

    useEffect(() => {
        getUserAndData();
    }, [getUserAndData]);

    useEffect(() => {
        if (data) {
            const newOptionsPropertiesGroups = [
                {
                    label: i18n.t("manageBrands"),
                    options: data?.brands?.results.map((b) => ({ value: b.id, label: b.name })) || [],
                },
            ];

            const newPropertiesGroups = data?.brands?.results.map((b) => ({ value: b.id, label: b.name })) || [];

            const newProperties =
                data.properties?.results.map((property) =>
                    createProperty(property, openModalEditProperty, newOptionsPropertiesGroups, refetch)
                ) || [];

            setPropertiesGroups(newPropertiesGroups);
            setProperties(newProperties);
        }
    }, [data]);

    return { data, loading, error, properties, propertiesGroups };
};

const createProperty = (propertyData, openModalEditProperty, propertiesGroups, refetch) => {
    const { id, name, brands } = propertyData;

    return {
        // id,
        property: name,
        group: (
            <div className="flex">
                {brands.map((brand) => (
                    <div key={brand.id} className="ml-2 rounded bg-orange-100 px-3 py-1 text-white">
                        {brand.name}
                    </div>
                ))}
            </div>
        ),
        edit: (
            <Button
                id={`property-${id}`}
                onClick={() => {
                    openModalEditProperty({
                        title: `${name} - ${i18n.t("edit-property")}`,
                        options: propertiesGroups,
                        optionsSelected: brands.map((b) => b.id),
                        name: name,
                        id: id,
                        refetch: refetch,
                    });
                }}
            >
                <Icon type="edit" size={1.3} />
            </Button>
        ),
        groups: brands.map((brand) => brand.id),
    };
};

const QUERY = gql`
    query GetUserAndData($ref: String!) {
        properties {
            info {
                count
                __typename
            }
            results {
                id
                name
                brands {
                    id
                    name
                    __typename
                }
                __typename
            }
            __typename
        }
        brands {
            results {
                id
                name
                ref
                __typename
            }
            __typename
        }
        user: users(ref: $ref) {
            results {
                brands {
                    id
                    name
                    ref
                    __typename
                }
                __typename
            }
            __typename
        }
    }
`;

export default useListProperties;
