import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setAvailableFilters,
    setCheckable,
    setBatchActive,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { Session } from "../../Utils/Session";
import { useTranslation } from "react-i18next";
import _ from "lodash";

//Model
import PropertyGroup from "./models/PropertyGroup";
import { useMSQuery } from "../useMSQuery";

const ListPropertyGroups = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, checkedItems, results } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const currentUserRef = Session.getSessionProp("userRef");
    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);
    const [applyFilters, setApplyFilters] = useState("");
    const [brandsIDs, setBrandsIDs] = useState(undefined);

    //Data
    const sectionName = "list-propertyGroups";

    //Variables.
    let associatedProjects = [];
    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            if (project.id && project.id > 0) {
                associatedProjects.push(project);
            }
        });
    }

    //Actions
    const dispatch = useDispatch();

    // }${filterQuery}){
    //API
    const GET_CHAIN_BRANDS = gql`
        {
            users(ref:"${currentUserRef}", page: ${currentPage}, size: ${perPage}${
        sortCriteria ? sortCriteria : sorting
    }){
                info{count}
                results{ brands{ id name ref chainID } }
            }
        }`;
    const [executeBrandsQuery, dataBrands] = useMSQuery(GET_CHAIN_BRANDS);

    const GET_PROPERTIES = gql`
    {
        brands${
            Array.isArray(brandsIDs)
                ? `(IDs:[${brandsIDs.join(",")}], page: ${currentPage}, size: ${perPage},${
                      sortCriteria ? sortCriteria : sorting
                  }${applyFilters})`
                : `(page: ${currentPage}, size: ${perPage},${sortCriteria ? sortCriteria : sorting}${applyFilters})`
        }
        {
            info{count}
            results{ id chainID name ref hasUsers
                projects {
                  id
                  name
                }
             }
        }
    }`;

    const [executePropertiesQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_PROPERTIES);
    //Listeners
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        const tableHeaders = GetTableHeaders(sectionName);
        dispatch(setHeaders(tableHeaders));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setBatchActive(arrangeBatchActions()));
        dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
        dispatch(setCountPosition("table-header"));
        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no property groups available")));
        executeBrandsQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setBatchActive(arrangeBatchActions({checkedItems, results})));
        // eslint-disable-next-line
    }, [checkedItems.length]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            // executeBrandsQuery();
            dataBrands.refetch();
            setApplyFilters(``);
            dispatch(cleanActiveFilters());
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        let currentFilters = arrangeFilters();
        setApplyFilters(`, filter:{${currentFilters}}`);
        // eslint-disable-next-line
    }, [filterQuery]);

    useEffect(() => {
        let currentFilters = arrangeFilters();
        setApplyFilters(`, filter:{${currentFilters}}`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            !dataBrands.loading &&
            !dataBrands.error &&
            dataBrands.data &&
            dataBrands.data.users &&
            dataBrands.data.users.results.length > 0
        ) {
            const { brands } = dataBrands.data.users.results[0];
            let _arrayBrandsIds = [];
            brands.forEach((brand) => {
                _arrayBrandsIds.push(brand.id);
            });
            setBrandsIDs(_arrayBrandsIds.length > 0 ? _arrayBrandsIds : null);
        }
    }, [dataBrands]);

    useEffect(() => {
        if (brandsIDs === null || Array.isArray(brandsIDs)) {
            executePropertiesQuery();
        }
        // eslint-disable-next-line
    }, [brandsIDs]);

    useEffect(() => {
        if (!loading && !error && data && data.brands && networkStatus === 7) {
            dispatch(setLoading(true));
            //Get data
            const availableFilters = GetAvailableFilters(sectionName, extractPropertyList(associatedProjects));
            dispatch(setAvailableFilters(availableFilters));
            const dataItems = arrangeData(data);
            if (brandsIDs === null || brandsIDs.length > 0) {
                dispatch(setTableResults(dataItems));
            }
            dispatch(setCount(data.brands.info.count));
            //Add to store
            dispatch(setActions(dataItems.length > 1 && dataItems[0].actions ? true : false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    const arrangeFilters = () => {
        let currentFilters = "";
        if (!_.isEmpty(activeFilters)) {
            for (const [key, value] of Object.entries(activeFilters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        return currentFilters;
    };
};

const arrangeData = (data) => {
    const propertyGroupsData = [];
    // eslint-disable-next-line
    data.brands.results.map((brand) => {
        propertyGroupsData.push(PropertyGroup(brand));
    });
    return propertyGroupsData;
};

const extractPropertyList = (properties) => {
    let propertiesArray = [];
    properties.map((property) =>
        propertiesArray.push({
            value: property.name,
            id: property.id,
            visible: true,
        })
    );

    return propertiesArray;
};

const arrangeBatchActions = (props = {}) => {
    const {checkedItems, results} = props;
    let actions = [
        {
            name: "delete-group",
            action: "delete-property-group",
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-properties-groups-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
        },
    ];
    if((checkedItems && checkedItems.length>0) && (results && results.length>0)){
        let itemsSelected = [];
        checkedItems.forEach((itemId) => {
            let item = results.filter((result) => result.id === itemId);
            if (item.length > 0){
                itemsSelected.push(item[0]);
            }
        });
        let itemsWithUsers = itemsSelected.filter((item) => item.hasUsers);
        if(itemsWithUsers && itemsWithUsers.length>0){
            actions = actions.filter((action) => action.name !== "delete-group");
        }
    }
    return actions;
}

export default ListPropertyGroups;
