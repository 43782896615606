import i18n from "i18n";
import ShowScheduleModal from "hooks/GraphqlCalls/CommonZones/modals/areasActivities/ShowScheduleModal";
import { Session } from "hooks/Utils/Session";

export const MobileApp = ({ data, modals }) => {
    const dataType = "mobile-app";

    const isMultiproperty = data?.isMultiProperty ? true : false;

    const isMultipropertyStyle = isMultiproperty
        ? "bg-purple-600 rounded inline text-white p-1"
        : "bg-teal-500 rounded inline text-white p-1";

    const chainName = Session.getSessionProp("chainName") || "";
    const projectName = data?.projects[0]?.name || "";
    const creatorName = isMultiproperty ? chainName : projectName;
    const propertiesNumber = data?.projects?.length || 0;

    const convertToStructurePropertiesModal = (projects) => {
        if (!projects) {
            return [];
        }

        return projects.map((project) => {
            const { name, information } = project;
            const { appAvailable } = information;

            return [
                {
                    item: name,
                    width: "w-6/12",
                },
                {
                    item: i18n.t(appAvailable ? "available" : "unavailable"),
                    width: "w-6/12",
                    style: `justify-end ${appAvailable ? "text-green-100" : "text-red-100"}`,
                },
            ];
        });
    };

    return {
        dataType: dataType,
        id: data?.id,
        info: [
            {
                cellContent: "text",
                name: "text",
                adjust: " font-bold text-zafiro-600 cursor-pointer",
                value: data?.name || "",
                specialWidth: "w-2/12",
                link: `/all-properties/mobile-apps/${data.id}`,
            },
            {
                cellContent: "text",
                name: "text",
                adjust: isMultipropertyStyle,
                value: creatorName,
                specialWidth: "w-2/12",
            },
            {
                cellContent: "text",
                name: "text",
                value: data?.url || "",
                specialWidth: "w-5/12",
            },
            {
                cellContent: "text",
                name: "text",
                adjust: propertiesNumber > 0 ? "font-bold text-zafiro-600 pl-2" : "pl-2",
                value: propertiesNumber
                    ? projectName === 1
                        ? propertiesNumber + " " + i18n.t("property")
                        : propertiesNumber + " " + i18n.t("properties")
                    : 0 + " " + i18n.t("properties"),
                specialWidth: "w-2/12",
                onClick: () => {
                    if (typeof modals?.openAssociatedPropertiesModal === "function") {
                        modals.openAssociatedPropertiesModal({
                            data: convertToStructurePropertiesModal(data?.projects),
                            title: `${data?.name} - ${i18n.t("associated-properties")}`,
                        });
                    }
                },
            },
            {
                name: "schedule",
                cellContent: "edit-modal",
                icon: { name: "edit", itemType: dataType, position: "right" },
                fallBackLink: `/#/all-properties/mobile-apps/${data?.id}`,
                specialWidth: "w-0.5/12",
                hidden: isMultiproperty ? false : true,
            },
            {
                name: "capacity-limit",
                cellContent: "edit-modal",
                onClickAction: () => {
                    if (typeof modals?.openDeleteModalApp === "function") {
                        modals.openDeleteModalApp({ name: data?.name, id: data?.id });
                    }
                },
                icon: { name: "delete", itemType: dataType, position: "right" },
                specialWidth: "w-0.5/12",
                hidden: isMultiproperty ? false : true,
            },
        ],
    };
};

export default MobileApp;
