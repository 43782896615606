import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useEffect } from "react";
import { Session } from "../../Utils/Session";
import { useDispatch } from "react-redux";
import { setFonts } from "../../../actions/uiActions";
import { LATO_FONT, ROBOTO_FONT } from "../../Utils/Design/FontUtils";
import { useLocation } from "react-router-dom";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";
// import { useQuery } from "react-apollo/lib";

const LoadFonts = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef, "/"].includes(location.pathname);

    const GET_FONTS_LIST = gql`
        {
            libraryFonts {
                response {
                    results {
                        name
                        ref
                    }
                }
            }
        }
    `;

    const {
        loading,
        data: fontList,
        error,
    } = useQuery(GET_FONTS_LIST, {
        skip: isNonChainRefOrProjectPath,
    });

    useEffect(() => {
        if (
            !loading &&
            !error &&
            fontList &&
            fontList.libraryFonts &&
            fontList.libraryFonts.response &&
            fontList.libraryFonts.response.results
        ) {
            // eslint-disable-next-line
            fontList.libraryFonts.response.results.map((font) => {
                // load font inside header
                let link = document.createElement("link");
                link.rel = "stylesheet";
                link.type = "text/css";
                link.href = Session.getDasUrl(font.ref + "/?format=css");
                if (document?.getElementsByTagName("HEAD")?.[0]?.children) {
                    const headElements = Array.from(document.getElementsByTagName("HEAD")[0].children);
                    const element_found = headElements.find((element) => element.href && element.href === link.href);
                    if (!element_found) {
                        document.getElementsByTagName("HEAD")[0].appendChild(link);
                    }
                }
            });
            let _allFonts = fontList.libraryFonts.response.results;
            // TODO: se deja funte por defecto Roboto que siempre se cargara al inicio
            // *** se cambia fuente por defecto de Roboto a Lato por petición de Marketing
            // saber como manejar las funtes por defecto, meter en drive los archivos de la fuente .tff y los .css. Crear una fuente sin cadena con link a los .css
            // GENERIC FONTS
            if (!_allFonts.find((font) => font.name === LATO_FONT.name)) {
                _allFonts.push({ ...LATO_FONT });
            }
            if (!_allFonts.find((font) => font.name === ROBOTO_FONT.name)) {
                _allFonts.push({ ...ROBOTO_FONT });
            }
            dispatch(
                setFonts({
                    fontNames: _allFonts.map((font) => font.name),
                    fontList: _allFonts,
                })
            );
        }
        // eslint-disable-next-line
    }, [fontList]);

    useEffect(() => {
        if (error) {
            // just load generic fonts Lato & Roboto
            dispatch(
                setFonts({
                    fontNames: ["Lato", "Roboto"],
                    fontList: [{ ...LATO_FONT }, { ...ROBOTO_FONT }],
                })
            );
        }
        // eslint-disable-next-line
    }, [error]);
    return null;
};

export default LoadFonts;
