import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";
import Modal, { useModal } from "components/Modal";
import { AccessTypesContext } from "contexts/AccessTypes";
import { useUpdateAccessProfiles } from "hooks/GraphqlCalls/Hotel/Monitoring/useUpdateAccessType";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const AccessTypesOptions = ({ wifiAccessTypes, accessProfile, refetch, setLoadingRefresh, checkboxStates }) => {
    const [newIDs, setNewIDs] = useState({});

    const { creating, editing } = useContext(AccessTypesContext);
    const { updateWifiAccess } = useUpdateAccessProfiles();
    const { t } = useTranslation();
    const { open } = useModal();

    useEffect(() => {
        if (wifiAccessTypes && accessProfile) {
            setNewIDs({
                [accessProfile?.id]: {
                    oldIDs: accessProfile.accesstypes
                        ? accessProfile.accesstypes.map((accessType) => accessType.id)
                        : [],
                    newIDs: accessProfile.accesstypes
                        ? accessProfile.accesstypes.map((accessType) => accessType.id)
                        : [],
                },
            });
        }
    }, []);

    return (
        <>
            {wifiAccessTypes.map((wifiAccessType, index) => {
                const selected = accessProfile.accesstypes.find(
                    (accessType) => accessType.accesstypes_name === wifiAccessType.value
                );
                return (
                    <Checkbox
                        id={`${accessProfile?.name}-access-type-${wifiAccessType.value}-${index}`}
                        value={wifiAccessType.ids[0]}
                        label={wifiAccessType.name}
                        checked={selected}
                        customChecked={({ checked, setChecked, value }) => {
                            if (!creating && !editing?.editing) {
                                console.log(creating);
                                const getIfAccessTypeIsNotUsed = (value) => checkboxStates.includes(value);
                                const isTypeUsed = getIfAccessTypeIsNotUsed(wifiAccessType.value);

                                if (!checked) {
                                    setChecked(false);
                                    setNewIDs((prev) => {
                                        const updatedNewIDs = {
                                            ...prev,
                                            [accessProfile?.id]: {
                                                ...prev[accessProfile?.id],
                                                newIDs: prev[accessProfile?.id].newIDs.filter((id) => id !== value),
                                            },
                                        };
                                        updateWifiAccess(
                                            accessProfile.id,
                                            updatedNewIDs[accessProfile.id].oldIDs,
                                            updatedNewIDs[accessProfile.id].newIDs
                                        );
                                        setLoadingRefresh(true);
                                        return updatedNewIDs;
                                    });
                                } else if (!isTypeUsed) {
                                    setChecked(true);
                                    setNewIDs((prev) => {
                                        const updatedNewIDs = {
                                            ...prev,
                                            [accessProfile?.id]: {
                                                ...prev[accessProfile?.id],
                                                newIDs: [...prev[accessProfile?.id].newIDs, value],
                                            },
                                        };
                                        updateWifiAccess(
                                            accessProfile.id,
                                            updatedNewIDs[accessProfile.id].oldIDs,
                                            updatedNewIDs[accessProfile.id].newIDs
                                        );
                                        setLoadingRefresh(true);
                                        return updatedNewIDs;
                                    });
                                } else {
                                    open(
                                        <ChangeAccessTypeModal
                                            accessProfile={accessProfile}
                                            setChecked={setChecked}
                                            setNewIDs={setNewIDs}
                                            wifiAccessType={wifiAccessType}
                                            value={value}
                                            refetch={refetch}
                                            setLoadingRefresh={setLoadingRefresh}
                                        />
                                    );
                                }
                            } else {
                                toast.error(t("save-before-continue"));
                            }
                        }}
                    />
                );
            })}
        </>
    );
};

const ChangeAccessTypeModal = ({
    accessProfile,
    setChecked,
    setNewIDs,
    wifiAccessType,
    value,
    refetch,
    setLoadingRefresh,
}) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { updateWifiAccess } = useUpdateAccessProfiles();
    return (
        <Modal
            title={`${accessProfile?.name} - ${t("edit-access-type")}`}
            onClose={close}
            className="w-2/7 p-10"
            footer={
                <footer className="flex w-full gap-4 mt-10 justify-end">
                    <Button id="cancel" className="btn-white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="alert-accept"
                        design="blue"
                        onClick={() => {
                            setChecked(true);
                            close();
                            setNewIDs((prev) => {
                                const updatedNewIDs = {
                                    ...prev,
                                    [accessProfile.id]: {
                                        ...prev[accessProfile.id],
                                        newIDs: [...prev[accessProfile.id].newIDs, value],
                                    },
                                };
                                updateWifiAccess(
                                    accessProfile.id,
                                    updatedNewIDs[accessProfile.id].oldIDs,
                                    updatedNewIDs[accessProfile.id].newIDs
                                );
                                setLoadingRefresh(true);
                                setTimeout(() => {
                                    refetch();
                                    setLoadingRefresh(false);
                                }, 1500);
                                return updatedNewIDs;
                            });
                        }}
                    >
                        {t("accept")}
                    </Button>
                </footer>
            }
        >
            <p
                className="mt-4"
                dangerouslySetInnerHTML={{
                    __html: t("access-type-already-used", {
                        accessTypes: wifiAccessType?.name,
                    }),
                }}
            ></p>
            <p className="my-6">{t("access-type-already-used-confirm")}</p>
            <p
                dangerouslySetInnerHTML={{
                    __html: t("access-type-already-used-description", {
                        accessTypes: wifiAccessType?.name,
                        accessProfile: accessProfile?.name,
                    }),
                }}
            ></p>
        </Modal>
    );
};
