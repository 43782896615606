import React, { useContext, useEffect } from "react";

import Cookies from "js-cookie";
import { SeleniumChecker } from "zdev";

import Header from "./components/Header";
import Section from "./components/Section";
import Sidebar from "./components/Sidebar";
import Impersonated from "./components/Section/Impersonated";
import Warnings from "./components/Section/Warning";
import Notifications from "./components/Section/Notifications";

import UseGlobalLoading from "./components/useGlobalLoading";
import UseModal from "./components/useModal";
import Permissions from "./hooks/Utils/Permissions";
import Userguiding from "./hooks/Utils/Userguiding";
import { Session } from "./hooks/Utils/Session";
import SalesBackgroundUpdate from "./hooks/GraphqlCalls/Sales/SalesBackgroundUpdate";
import LoadFonts from "./hooks/GraphqlCalls/MediaLibrary/LoadFonts";
import { includeWidgetRenderLibrary } from "./hooks/Utils/Utils";

import { GlobalContext } from "contexts/Global";

const AppContent = () => {
    const { environment, features } = useContext(GlobalContext);

    const token = Session.getSessionProp("token");

    useEffect(() => {
        window.addEventListener("visibilitychange", onVisibilityChange);
        includeWidgetRenderLibrary();
        return () => {
            window.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, []);

    //Manage different project in different tabs
    const onVisibilityChange = (isVisible) => {
        if (!document.hidden) {
            Cookies.set("restoreSession", !!token);
            //Copy session data that I want to maintain in new tab
            Session.saveBackup();
        }
    };

    return (
        <>
            <Permissions token={token} />
            <LoadFonts />
            <SalesBackgroundUpdate />
            {features?.userguiding ? <Userguiding /> : null}
            {features?.notifications ? <Notifications /> : null}

            {features?.selenium ? <SeleniumChecker mode={environment} /> : null}
            <Impersonated />
            <Warnings />
            <div>
                <UseGlobalLoading />
                <Header />
                <Sidebar />
                <Section />
                <UseModal />
            </div>
        </>
    );
};

export default AppContent;
