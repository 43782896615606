import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Actions
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import { setExecuteQuery } from "../../../../../actions/sectionActions";

//components
import UseLoading from "../../../../Table/useLoading";

//Utils
import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { cloneObject } from "../../../../../hooks/Utils/Utils";
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Actions from "./CommonOptions/Actions";
import Widget from "../Widget";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import { SCREEN } from "../../../../../hooks/Utils/Design/EditorUtils";

const IconToolbar = () => {
    const { deviceType, itemSelected, theme } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const { type: screenType } = useParams();

    const DEFAULT_ICON_SIZE = 5;
    let show = 0;
    const RESULTS_INCREMENT = 50;

    library.add(fas);
    library.add(fab);
    let fasArray = Object.keys(library.definitions.fas);
    fasArray.splice(fasArray.indexOf("font-awesome-logo-full"), 1);
    let fabArray = Object.keys(library.definitions.fab);
    fabArray.splice(fabArray.indexOf("font-awesome-logo-full"), 1);
    fabArray.splice(fabArray.indexOf("angry"), 1);

    const allIcons = [
        { lib: "fa", icons: fasArray },
        { lib: "fab", icons: fabArray },
    ];
    const allIconsLength = allIcons[0].icons.length + allIcons[1].icons.length;
    const sectionName = `toolbar`;

    const dispatch = useDispatch();

    //States
    const [icons, setIcons] = useState(allIcons);
    const [search, setSearch] = useState(null);
    const [loadingIcons, setLoadingIcons] = useState(false);
    const [iconsShowing, setIconsShowing] = useState(RESULTS_INCREMENT);
    const [iconSelected, setIconSelected] = useState();

    //Store data
    const { gridItems, widgetToolbarVisible } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);

    useEffect(() => {
        if (widgetToolbarVisible === "icon-settings" && itemSelected === widgetID) {
            setFields();
            setLoadingIcons(false);
            setIcons(allIcons);
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (itemSelected === widgetID) {
            setFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    const setFields = () => {
        setIconSelected({
            icon: data && data.icon ? data.icon : null,
            lib: data && data.lib ? data.lib : null,
        });
    };

    const updateIcon = (icon, lib) => {
        setIconSelected({ icon: icon, lib: lib });
        const valueSize = document.querySelector(`#size_${widgetID}`);
        const iconSize = valueSize.value === "" ? DEFAULT_ICON_SIZE : valueSize.value;
        if (!data || !data.size) {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        property: "size",
                        value: parseFloat(iconSize),
                        deviceSpecific: deviceType,
                    })
                )
            );
        }
        setTimeout(() => {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateData({
                        widget,
                        value: {
                            icon: icon,
                            lib: lib,
                            size: parseFloat(iconSize),
                        },
                    })
                )
            );
        }, 100);
    };

    const filterIcons = (e) => {
        if (!e.target.value) {
            setIcons(allIcons);
            setSearch(null);
            return;
        }
        let tmpIcons = cloneObject(search && search.length > e.target.value.length ? allIcons : icons);

        tmpIcons.map(
            (set) => (set.icons = set.icons.filter((icon) => icon.toLowerCase().includes(e.target.value.toLowerCase())))
        );

        setIcons(tmpIcons);
        setSearch(e.target.value);
    };

    const onScrollCallback = (e) => {
        const maxScroll = e.target.scrollHeight - e.target.clientHeight;
        const currenteScroll = e.target.scrollTop;
        const bottom = 25;

        if (currenteScroll + bottom >= maxScroll && iconsShowing < allIconsLength) {
            setIconsShowing(iconsShowing + RESULTS_INCREMENT);
        }
    };

    return (
        <>
            <div
                id={`${sectionName}-settings`}
                className={`icon icon-settings-editor cursor-pointer p-2 ${
                    widgetToolbarVisible === "icon-settings" ? "toolbarOptionSelected" : ""
                }`}
                onClick={(e) =>
                    dispatch(setWidgetToolbarVisible(widgetToolbarVisible === "icon-settings" ? null : "icon-settings"))
                }
            ></div>
            {widgetToolbarVisible === "icon-settings" ? (
                <div
                    id={`widgetOptionContainer_${widgetID}`}
                    className={`widgetOptionContainer invisible`}
                    style={{ width: "26rem" }}
                >
                    <div className="first-capital mb-6 font-bold">{t("icon options")}</div>

                    <Input.Number
                        id="size"
                        title={t("icon size")}
                        deviceSpecific={true}
                        dataType="data"
                        defaultInputValue={theme.paragraphStyle.size}
                    />

                    <div className="relative">
                        <input
                            id={`icon_search_input`}
                            type="text"
                            className="t-filter-search  m-0 w-full"
                            placeholder={`${t("search")}...`}
                            onChange={filterIcons}
                        ></input>
                        <span className="field-icon icon-search mt-4 table" style={{ height: "2rem" }}></span>
                    </div>
                    {loadingIcons ? <UseLoading adjust="contents" /> : null}
                    <div className={`mt-3 h-64 overflow-x-hidden overflow-y-scroll`} onScroll={onScrollCallback}>
                        {icons.map((set) =>
                            set.icons.map((icon) =>
                                search || (!search && show++ && show < iconsShowing) ? (
                                    <div
                                        key={`${set.lib}-${icon} `}
                                        className={`border rounded float-left  mb-2 mr-2 w-12 cursor-pointer p-3 text-center ${
                                            iconSelected && icon === iconSelected.icon
                                                ? "border-zafiro-200"
                                                : "border-gray-200"
                                        }`}
                                        onClick={(e) => updateIcon(icon, set.lib)}
                                    >
                                        <FontAwesomeIcon icon={[set.lib, icon]} size={"lg"} />
                                    </div>
                                ) : null
                            )
                        )}
                    </div>
                </div>
            ) : null}
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id="fgColor" title={t("icon")} />
                        <Input.Color id="bgColor" title={t("background")} />
                    </Settings.Group>
                    <Settings.Border t={t} />
                </Settings>
            </Toolbar>
            {screenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default IconToolbar;
