import React, { useContext, useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";

import { GlobalContext } from "contexts/Global";

/**
 * @description A text input component
 * @param {string} id - The id of the input
 * @param {string} type - The type of the input (default: text)
 * @param {string} value - The initial value of the input
 * @param {string} placeholder - The placeholder of the input
 * @param {function} onChange - The function to call when the input value changes and loses the focus
 * @param {function} onInput - The function to call when the input value changes inmediately
 * @param {function} onBlur - The function to call when the input loses the focus
 * @param {function} onKeyPress - The function to call when a key is pressed
 * @param {boolean} autoFocus - If the input should be focused on render
 * @param {boolean} disabled - If the input should be disabled
 * @param {boolean} required - If the input is required
 * @param {RegExpConstructor} validate - The regular expression to validate the input
 * @param {string} className - The extended class name of the input
 * @returns {JSX.Element} The text input component
 * @example
 * <TextInput id="name" value="John Doe" placeholder="Enter your name" onChange={(value) => console.log(value)} required={true} />
 */
const TextInput = forwardRef(
    (
        {
            id,
            type = "text",
            value,
            placeholder,
            error = false,
            onInput,
            onChange,
            onBlur,
            onKeyPress,
            autoFocus,
            disabled,
            required,
            validate,
            className,
        },
        ref
    ) => {
        const { highlightComponent } = useContext(GlobalContext);

        const [currentValue, setCurrentValue] = useState(value);

        const [validating, setValidating] = useState(false);

        const inputRef = useRef(null);

        const isValid = ((value) => {
            if (required && !value) {
                return false;
            }
            if (validate && !validate.test(value)) {
                return false;
            } else if (type === "email" && value?.length) {
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
            } else if (type === "password") {
                return value?.length >= 3;
            }
            return true;
        })(currentValue);

        useEffect(() => {
            const blurHandler = (e) => {
                const value = e?.target?.value || "";
                setValidating(true);
                if (onBlur) {
                    onBlur(value);
                }
            };
            const keyPressHandler = (e) => {
                if (onKeyPress && !disabled) {
                    onKeyPress(e);
                }
            };
            if (inputRef.current) {
                inputRef.current.addEventListener("blur", blurHandler);
                inputRef.current.addEventListener("keypress", keyPressHandler);
            }
            return () => {
                if (inputRef.current) {
                    inputRef.current.removeEventListener("blur", blurHandler);
                    inputRef.current.removeEventListener("keypress", keyPressHandler);
                }
            };
        }, [inputRef.current, onKeyPress, onBlur, disabled]);

        useEffect(() => {
            const onInputHandler = (e) => {
                const value = e?.target?.value || "";
                setCurrentValue(value);
                if (onInput) {
                    onInput(value);
                }
            };
            const onChangeHandler = (e) => {
                const value = e?.target?.value || "";
                setCurrentValue(value);
                setValidating(true);
                if (onChange) {
                    onChange(value);
                }
            };
            if (inputRef.current) {
                inputRef.current.addEventListener("input", onInputHandler);
                inputRef.current.addEventListener("change", onChangeHandler);
            }

            return () => {
                if (inputRef.current) {
                    inputRef.current.removeEventListener("input", onInputHandler);
                    inputRef.current.removeEventListener("change", onChangeHandler);
                }
            };
        }, [inputRef.current, onInput, onChange]);

        useEffect(() => {
            if (autoFocus) {
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 100);
            }
        }, [inputRef.current, autoFocus]);

        useImperativeHandle(ref, () => ({
            focus: () => {
                setTimeout(() => {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }, 200);
            },
            blur: () => {
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            },
            getValue: () => {
                return currentValue;
            },
            isValid: () => {
                return isValid;
            },
        }));

        // Update the value if initial value changes
        useEffect(() => {
            setCurrentValue(value);
        }, [value]);

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.value = currentValue?.length ? currentValue : "";
            }
        }, [inputRef.current, currentValue]);

        const inputClass = classNames({
            "border border-red-500": error,
            "w-full rounded py-1 leading-7 long bg-gray-200": true,
            "bg-gray-300 text-gray-700": disabled,
            "border border-red-100": !disabled && !isValid && validating,
            "highlight-component": highlightComponent,
            "px-4": !(className || "").match(/^px-/),
            [className]: className,
        });

        return (
            <input
                ref={inputRef}
                id={id}
                name={id}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClass}
            />
        );
    }
);
TextInput.displayName = "TextInput";

export default TextInput;
