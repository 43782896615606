import React, { useEffect, useRef, useState, useMemo } from "react";
import Modal, { useModal } from "components/Modal";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import {
    QUERIES_TRANSLATIONS,
    TYPE_QUERY,
    getAllLangs,
    getDefaultLang,
    // otherlangs,
    getOtherLangs,
    getDefaultTranslation,
    handleTranslationsChange,
    MUTATION_TRANSLATIONS,
} from "../utils/QueriesTranslations";
import { Session } from "hooks/Utils/Session";
import Translations from "components/Translations";
import TextInput from "components/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import Button from "components/Button";
import { setRefreshContentData } from "actions/sectionActions";
import { labelsPrefix } from "hooks/Utils/SalesUtils";
const ModalVendureTranslations = ({
    VARIABLES,
    QUERY,
    setOnLoading,
    onClickSave,
    setOnClickSave,
    close: closeModal,
    NO_FETCH_DATA,
}) => {
    const { projectLangs } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [translations, setTranslations] = useState([]);
    const [internalLoading, setInternalLoading] = useState(NO_FETCH_DATA ? false : true);

    const designTranslations = QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION ? "cols" : "modal";
    const includeDefault = QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION ? true : false;
    const isMultiline = QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION ? true : false;

    const defaultLang = useMemo(() => getDefaultLang(projectLangs), [projectLangs]);
    const otherLangs = useMemo(() => getOtherLangs(projectLangs), [projectLangs]);
    const allLangs = useMemo(() => getAllLangs(projectLangs), [projectLangs]);

    const translationsRef = useRef();

    const { translations: _translations } = useFetchTranslationsVendure({
        query: QUERIES_TRANSLATIONS[QUERY],
        variables: VARIABLES,
        setInternalLoading: setInternalLoading,
        queryType: QUERY,
        noFetchData: NO_FETCH_DATA,
    });

    const { mutate } = useMutationTranslationsVendure(
        MUTATION_TRANSLATIONS[QUERY],
        (data) => handleMutationCompletion(data),
        (error) => handleMutationCompletion(error, true)
    );

    useEffect(() => {
        setOnLoading(internalLoading);
    }, [internalLoading]);

    useEffect(() => {
        if (onClickSave) {
            if (getDefaultTranslation(translations, defaultLang)?.trim() !== "") {
                const input = {
                    ...VARIABLES,
                    translations: translations.filter((translation) => {
                        return translation.id || translation.name.trim() !== "";
                    }),
                };

                if (QUERY === TYPE_QUERY.CREATE_LABEL) {
                    input["code"] = `${labelsPrefix}_${getDefaultTranslation(translations, defaultLang)?.trim()}`;
                }

                if (QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION) {
                    input.translations = input.translations.map((translation) => {
                        const { name, nameStored, ...rest } = translation;

                        const matchingTranslation = _translations?.find((t) => t.id === translation.id);

                        const nameAssigned = matchingTranslation ? matchingTranslation.nameStored : "";

                        return {
                            ...rest,
                            name: nameAssigned,
                            description: name,
                        };
                    });
                }

                setInternalLoading(true);
                mutate({ input });
            } else {
                toast.error(t("input error"));

                const defaultElement = document.querySelector("#sales-modals-translations-default");

                if (isMultiline && defaultElement) {
                    defaultElement.classList.add("border", "border-red-100");
                }
            }

            setOnClickSave(false);
        }
    }, [onClickSave]);

    useEffect(() => {
        if (_translations?.length > 0) {
            setTranslations(_translations);
        }
    }, [_translations]);

    const onTranslationsChange = (newTranslations) => {
        const includesSlug =
            QUERY === TYPE_QUERY.PRODUCT || QUERY === TYPE_QUERY.COLLECTION || QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION;
        const includesDescription =
            QUERY === TYPE_QUERY.PRODUCT || QUERY === TYPE_QUERY.COLLECTION || QUERY === TYPE_QUERY.PRODUCT_DESCRIPTION;

        handleTranslationsChange(
            allLangs,
            newTranslations,
            setTranslations,
            includesSlug,
            includesDescription,
            translations
        );
    };

    const handleMutationCompletion = (result, isError = false) => {
        if (isError) {
            toast.error(result);
        } else {
            toast.success(t("operation-successful"));
        }
        setInternalLoading(false);
        closeModal();
        dispatch(setRefreshContentData(true));
    };

    return internalLoading ? (
        <Loading />
    ) : (
        <>
            {QUERY === TYPE_QUERY.CREATE_LABEL || QUERY === TYPE_QUERY.EDIT_LABEL || QUERY === TYPE_QUERY.SHOP ? (
                <div className=" mb-8">{t("add-label-description")}</div>
            ) : null}
            {!isMultiline ? (
                <>
                    <div className=" mb-4">{t("default-language")}</div>
                    <div className=" flex items-start space-x-5">
                        <div className=" min-w-24 text-base font-bold">{t("language:" + defaultLang)}</div>
                        <div className=" w-full">
                            <TextInput
                                id={`sales-modals-translations-default-lang`}
                                className="w-full"
                                value={getDefaultTranslation(translations, defaultLang)}
                                required={true}
                                placeholder={t("enter-translation-here")}
                                onChange={(name) => {
                                    const newTranslations =
                                        translations.length === 0
                                            ? allLangs.map((lang) => ({
                                                  languageCode: lang,
                                                  name: lang === defaultLang ? name : "",
                                              }))
                                            : translations.map((translation) =>
                                                  translation.languageCode === defaultLang
                                                      ? { ...translation, name }
                                                      : translation
                                              );

                                    onTranslationsChange(newTranslations);
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : null}

            <Translations
                ref={translationsRef}
                id={`sales-modals-translations`}
                languages={otherLangs}
                key={getDefaultTranslation(translations, defaultLang)}
                defaultLang={defaultLang}
                placeholder={getDefaultTranslation(translations, defaultLang)}
                includeDefault={includeDefault}
                design={designTranslations}
                multiline={isMultiline ? 4 : null}
                onChange={onTranslationsChange}
                translations={translations}
                templateContainerClassName="flex justify-end"
            />
        </>
    );
};

const useMutationTranslationsVendure = (query, onSuccess, onError) => {
    const tokenShop = Session.getSessionProp("tokenShop");

    const mutate = async (variables) => {
        try {
            const response = await executeVendureQuery(
                getVendureApiData(),
                { queryBody: query, variables },
                { "vendure-token": tokenShop }
            );

            if (response?.error) {
                if (onError) onError(response.error);
            } else {
                if (onSuccess) onSuccess(response.data);
            }
        } catch (error) {
            if (onError) onError(error.message);
        }
    };

    return { mutate };
};

const useFetchTranslationsVendure = ({ query, variables, setInternalLoading, queryType, noFetchData }) => {
    const tokenShop = Session.getSessionProp("tokenShop");
    const [translations, setTranslations] = useState([]);

    const handleTranslationsResponse = (data) => {
        console.log(data);

        switch (queryType) {
            case TYPE_QUERY.PRODUCT:
                return data?.product?.translations;
            case TYPE_QUERY.PRODUCT_DESCRIPTION:
                return data?.product?.translations.map((item) => ({
                    ...item,
                    name: item.description,
                    nameStored: item.name,
                }));
            case TYPE_QUERY.COLLECTION:
                return data?.collection?.translations;
            case TYPE_QUERY.EDIT_LABEL:
                const facetItem = data?.facets?.items?.[0];
                return facetItem?.values.find((f) => f.id === variables.id)?.translations;
            default:
                return [];
        }
    };

    const fetchData = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: query, variables },
            { "vendure-token": tokenShop }
        );

        if (response?.error) {
            toast.error(response?.data?.error);
            setInternalLoading(false);
        } else {
            const fetchedTranslations = handleTranslationsResponse(response?.data);
            setTranslations(fetchedTranslations || []);
            setInternalLoading(false);
        }
    };

    useEffect(() => {
        if (!noFetchData) {
            fetchData();
        }
    }, [query]);

    return { translations };
};

const ModalContent = (props) => {
    const [onLoading, setOnLoading] = useState(true);
    const [onClickSave, setOnClickSave] = useState(false);

    const { t } = useTranslation();

    const newProps = {
        onLoading,
        onClickSave,
        setOnClickSave,
        setOnLoading,
        ...props,
    };

    return (
        <Modal
            title={t(props?.title)}
            footer={
                <>
                    {onLoading ? null : (
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                                {t("cancel")}
                            </Button>
                            <Button
                                design="blue"
                                id="modal-button-save"
                                onClick={() => {
                                    setOnClickSave(true);
                                }}
                            >
                                {t("save")}
                            </Button>
                        </>
                    )}
                </>
            }
            minWidth="30vw"
        >
            <ModalVendureTranslations {...newProps} />
        </Modal>
    );
};

export const useSalesModalVendureTranslations = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
