import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import UseButton from "../../../useButton";
import UseIcon from "../../../useIcon";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { ensureValue } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseComponentLoading from "../../../useComponentLoading";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { changeGlobalAction, cleanActionValues } from "../../../../actions/globalActions";
import RoomCheckoutModal from "../../../../hooks/GraphqlCalls/Hotel/Rooms/modals/RoomCheckoutModal";
import { setRefreshContentData } from "../../../../actions/sectionActions";
import { Session } from "../../../../hooks/Utils/Session";
import RoomDetailAlarms from "./RoomDetailAlarms";
import uuid from "react-uuid";
import RoomDetailParentalCode from "./RoomDetailParentalCode";

import SalesProvider from "contexts/Sales";
import GuestInfoFeedback from "components/Section/Customers/Guest/GuestInfoFeedback";
import RoomDetailPurchases from "./RoomDetailOrders";

function RoomDetailGuest({ setShowCheckIn }) {
    const dispatch = useDispatch();
    const { room, guest } = useSelector((state) => state.sectionContent);
    const { dashboardCards } = useSelector((state) => state.ui);
    const { langStrings, permissions } = useSelector((state) => state.ui);
    const hasAlarmsPermission = permissions && permissions.guests && permissions.guests.alarms;
    const hasProductSales = permissions && permissions.services && permissions.services.productSALES;
    const hasParentalCode = permissions?.actions?.actParental;
    const hasGuestSatisfaction = permissions && permissions.guests.guestSatisfaction;

    const format = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
    };
    const infoStyle = "text-left mx-8 my-4";

    //Session data
    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};

    const { t } = useTranslation();

    const renderStayInfo = () => {
        let stayInfo = null;
        let response = [];
        if (dashboardCards && dashboardCards.length > 0) {
            // eslint-disable-next-line
            dashboardCards.map((cardData) => {
                if (cardData.type && cardData.type === "stay-card") {
                    stayInfo = cardData;
                }
            });
        }

        if (stayInfo) {
            response.push(
                <Fragment key={uuid()}>
                    <hr className="h-1 mx-6 my-6"></hr>
                    <div className={infoStyle}>
                        <span className="">{capitalizeFirst(t("current-stay"))}</span>
                    </div>
                    {stayInfo.occupied ? (
                        <>
                            <div className={infoStyle}>
                                <UseIcon icon={{ name: "login" }} adjust="inline mr-2"></UseIcon>
                                <span className="">{stayInfo.stayFrom.toLocaleDateString(undefined, format)}</span>
                            </div>
                            <div className={infoStyle}>
                                <UseIcon icon={{ name: "logout" }} adjust="inline mr-2"></UseIcon>
                                <span className="">
                                    {stayInfo.stayTo ? stayInfo.stayTo.toLocaleDateString(undefined, format) : "-"}
                                </span>
                            </div>
                            {/*<div className={infoStyle}>
                               <span className="first-capital text-lg font-bold">
                                   <Link to={`/customers/guests/guest-list/guest/${stayInfo.guestId}`}>
                                       {langStrings["view-stay"]}
                                   </Link>
                               </span>
                           </div>*/}
                        </>
                    ) : (
                        <>
                            <div className={infoStyle}>
                                <UseIcon icon={{ name: "logout" }} adjust="inline mr-2"></UseIcon>
                                <span className="">
                                    {`${langStrings["last-stay"]}: ${stayInfo.lastStay.toLocaleDateString(
                                        undefined,
                                        format
                                    )}`}
                                </span>
                            </div>
                            <div className={infoStyle}>{langStrings["empty"]}</div>
                        </>
                    )}
                </Fragment>
            );
        }
        return response;
    };

    const iconGuest = (on) => {
        let divCompanions = "";
        if (on && guest && guest.companions.length > 0 && room) {
            let tooltipCompanions = `<b>${capitalizeFirst(t("companions"))}</b>`;
            guest.companions.map((companion) => {
                tooltipCompanions += `<ul>${sanitizeHtml(companion.name)} ${sanitizeHtml(companion.surname)} ${
                    room.name !== sanitizeHtml(companion.rooms[0].name)
                        ? `- <small>${sanitizeHtml(companion.rooms[0].name)}</small>`
                        : ""
                }</ul>`;
                return null;
            });

            divCompanions = (
                <div
                    className="inline-block ml-3 text-zafiro-600 cursor-default"
                    data-tip={tooltipCompanions}
                    data-for="dangerous-html-tooltip"
                >{`+${guest.companions.length}`}</div>
            );
        }
        return (
            <div className="flex justify-center w-full">
                <div
                    className={`inline-block w-14 h-14 rounded-full col-span-2 ${on ? "bg-blue-100" : "bg-gray-500"} ${
                        divCompanions !== "" && "ml-5"
                    }`}
                >
                    <UseIcon icon={{ name: "guest" }} adjust="text-center pt-3 text-3xl white"></UseIcon>
                </div>
                {divCompanions}
            </div>
        );
    };
    const noGuest = () => {
        return (
            <>
                {iconGuest(false)}
                {!currentHotel.blockGuestManagement ? (
                    <UseButton
                        id={"room-detail-guest-check-in-button"}
                        buttonName="check-in"
                        buttonColor="btn-blue"
                        customClass="mx-24 mt-8"
                        action={checkinAction}
                    ></UseButton>
                ) : null}
            </>
        );
    };

    const checkoutAction = () => {
        const checkoutModal = RoomCheckoutModal(guest, room);
        dispatch(
            changeGlobalAction({
                actionName: "checkout",
                itemsAffected: guest.guestID,
            })
        );
        dispatch(cleanActionValues());
        dispatch(setModalContent(checkoutModal));
        dispatch(setRefreshContentData(true));
        dispatch(openModal());
    };

    const checkinAction = () => {
        setShowCheckIn(true);
        return;
    };

    const getGuestGroups = () => {
        if (guest.guestGroup.length > 0) {
            let _guestGroups = [];
            // eslint-disable-next-line
            guest.guestGroup.map((group) => {
                _guestGroups.push(group.name);
            });
            return _guestGroups.join(", ");
        } else {
            return "-";
        }
    };

    const guestCard = () => {
        if (guest === "loading") return <UseComponentLoading />;
        return (
            <>
                {iconGuest(true)}
                <h1 className="text-xl font-bold mt-4 text-zafiro-600">
                    {guest.guestLoyaltyRef ? (
                        <span
                            className={`icon-loyal-customers text-base text-zafiro-600 pr-2`}
                            data-tip={capitalizeFirst(t("loyal-customer"))}
                            data-for="default-tooltip"
                        ></span>
                    ) : null}
                    <Link to={`/customers/guests/guest-list/guest/${guest.guestID}`}>
                        {`${guest.guestTitle} ${guest.guestName} ${guest.guestSurname}`}
                    </Link>
                </h1>
                <hr className="h-1 mx-6 my-6"></hr>
                <div className={infoStyle}>
                    <UseIcon icon={{ name: "country" }} adjust="inline mr-2"></UseIcon>
                    {`${capitalizeFirst(t("country"))}: `}
                    <span className="font-bold">{`${ensureValue(
                        guest && guest.guestCountry ? t("country:" + guest.guestCountry.toLowerCase()) : ""
                    )}`}</span>
                </div>
                <div className={infoStyle}>
                    <UseIcon icon={{ name: "language" }} adjust="inline mr-2"></UseIcon>
                    {`${capitalizeFirst(t("lang"))}: `}
                    <span className="font-bold">{`${ensureValue(
                        guest.guestLang ? t("language:" + guest.guestLang) : null
                    )}`}</span>
                </div>
                <div className={infoStyle}>
                    <UseIcon icon={{ name: "star" }} adjust="inline mr-2"></UseIcon>
                    {`${capitalizeFirst(t("guest-group"))}: `}
                    <span className="font-bold ">{getGuestGroups()}</span>
                </div>

                {renderStayInfo()}

                {!currentHotel.blockGuestManagement ? (
                    <UseButton
                        id={"room-detail-guest-check-out-button"}
                        buttonName="check-out"
                        buttonColor="btn-white"
                        customClass="mx-24 mt-8"
                        action={checkoutAction}
                    ></UseButton>
                ) : null}
            </>
        );
    };

    return (
        <div className="h-auto">
            <div
                className={`bg-white text-center ${
                    guest && hasAlarmsPermission ? "h-auto" : "h-full"
                } py-10 px-2 rounded flex flex-col`}
            >
                {guest ? guestCard() : noGuest()}
            </div>
            {guest && hasGuestSatisfaction ? <GuestInfoFeedback roomId={room?.id} classNameContainer=" mt-8" /> : null}
            {guest && hasParentalCode ? <RoomDetailParentalCode /> : null}
            {guest && hasProductSales ? (
                <SalesProvider>
                    <RoomDetailPurchases />
                </SalesProvider>
            ) : null}
            {guest && hasAlarmsPermission ? <RoomDetailAlarms /> : null}
        </div>
    );
}

export default RoomDetailGuest;
