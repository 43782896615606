const UpdateLanguageModal = (language, name) => {
    const { projectId, languageRef } = language;
    return [
        {
            id: languageRef,
            projectId: projectId,
            isDefault: true,
            title: ["make-default"],
            value: name,
            text: ["language-confirm-update"],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "delete",
                },
            ],
        },
    ];
};

export default UpdateLanguageModal;
