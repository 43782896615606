import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
//Actions
import { clearProjectRef } from "../../../actions/hotelActions";
import { setToken } from "../../../actions/authActions";
//Utils
import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";

import { Session } from "../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";

const LoginImpersonate = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    //Data

    //Store data
    const { impersonated } = useSelector((state) => state.auth);

    //API Call

    const MUTATION = gql`
    mutation {
        loginImpersonate(
            userRefToImpersonate: "${impersonated ? impersonated.ref : ""}"
            lang:  "${i18n.language.split("-")[0]}"
      ) { chainId chainName chainRef chainAppURL cloudManagerURL managerLogo error isOk token isSuperuser userID userRef userFullname userManagerRole hasCorporateUser userTOSAccepted warnings { type message } userAdvicedUpdate projects{ id location name timeZone keyBrand ref blockGuestManagement managerURL managerUsers code supportPhone supportEmail zMobileURL}}}`;

    const [loginImpersonate, { data }] = useMutation(MUTATION);

    //Actions
    const dispatch = useDispatch();

    useEffect(() => {
        if (data?.loginImpersonate?.isOk) {
            const isSuperUser = data.loginImpersonate.isSuperuser;
            const isCorporateUser = data.loginImpersonate.hasCorporateUser;
            const userToken = data.loginImpersonate.token;

            Session.login({
                user: {
                    id: data.loginImpersonate.userID,
                    ref: data.loginImpersonate.userRef,
                    fullname: data.loginImpersonate.userFullname,
                    superUser: data.loginImpersonate.isSuperuser,
                    role: data.loginImpersonate.userManagerRole,
                    corporate: data.loginImpersonate.hasCorporateUser,
                    tos: data.loginImpersonate.userTOSAccepted,
                    userAdvicedUpdate: data.loginImpersonate.userAdvicedUpdate,
                    warnings: data.loginImpersonate.warnings,
                },
                chain: {
                    id: data.loginImpersonate.chainId,
                    name: data.loginImpersonate.chainName,
                    ref: data.loginImpersonate.chainRef,
                    app: data.loginImpersonate.chainAppURL,
                    manager: data.loginImpersonate.cloudManagerURL,
                    logo: data.loginImpersonate.managerLogo,
                },
                projects: getProjects(data.loginImpersonate.projects, data.loginImpersonate.hasCorporateUser, t),
            });
            Session.setToken(userToken);
            dispatch(setToken(userToken));
            if (isCorporateUser || data.loginImpersonate.projects.length > 1) {
                history("/select-project");
            } else if (data.loginImpersonate.projects.length > 0) {
                // TODO if there is only one project, select it automatically
                history("/select-project");
            } else {
                toast.error(t("not found projects"));
            }
        } else if (data?.loginImpersonate?.error) {
            toast.error(t("invalid email or password"));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(clearProjectRef());
        if (impersonated) {
            loginImpersonate();
            //Loguear al usuario con la cadena seleccionada
        }
        // eslint-disable-next-line
    }, []);

    return <></>;
};

const getProjects = (projects, hasCorporateUser, t) => {
    projects.sort((a, b) => String(a.name).localeCompare(String(b.name)));
    projects.map((project) => {
        project.hasMinimalPermissions = true;
    });
    //CORPORATE PROJECT
    if (hasCorporateUser) {
        projects.unshift({
            name: t("global-management"),
            id: 0,
            location: t("global-management-desc"),
            ref: "CORPORATE",
            supportEmail: "zafiro@zafiro.tv",
            supportPhone: "1234",
            timeZone: "UTC",
            global: true,
        });
    }
    return projects;
};

export default LoginImpersonate;
