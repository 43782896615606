import React, { useEffect, useState } from "react";
import Icon from "./Icon";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import BrowserMediaLibraryModal from "hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { useDispatch, useSelector } from "react-redux";
import { setModalContent, openModal, closeModal } from "actions/uiActions";
import { cleanActionValues } from "actions/globalActions";
import { Session } from "hooks/Utils/Session";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";

const LibraryImageSelector = ({ onChange, value, id, resolution }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const lang = localStorage.getItem("lang") || "en";
    const { values } = useSelector((state) => state.action);
    const [onClick, setOnClick] = useState();
    const [refImage, setRefImage] = useState(null);
    const [onErrorImg, setOnErrorImg] = useState(false);
    const [nameImg, setNameImg] = useState("");

    const GET_INFO_ASSET = gql`
        query {
            libraryAsset(ref: "${refImage || ""}") {
                response {
                    name
                }
            }
        }
    `;

    const { data } = useQuery(GET_INFO_ASSET, {
        fetchPolicy: "network-only",
        skip: !refImage,
    });

    useEffect(() => {
        if (data?.libraryAsset?.response?.name) {
            setNameImg(data.libraryAsset.response.name);
        }
    }, [data]);

    useEffect(() => {
        if (value) {
            setRefImage(value);
        }
    }, [value]);

    useEffect(() => {
        if (onClick) {
            setOnClick(false);
            if (values?.["browser-media-library-items-selected"]?.[0]) {
                setRefImage(values?.["browser-media-library-items-selected"][0]);
            }
            setOnErrorImg(false);
            dispatch(closeModal());
            dispatch(setModalContent(false));
            dispatch(cleanActionValues());
        }
    }, [values, onClick]);

    useEffect(() => {
        if (onChange) {
            onChange(refImage);
        }
    }, [refImage]);

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            text: "images-of-the-media-library",
            title: "choose-an-image",
            bodyAdjust: "w-10/12",
            buttonSelectedAssetName: "choose-an-image",
            action: "widget-actions-media",
            textAdjust: "",
            executeSectionQuery: true,
            closeAction: null,
            buttonAceptAction: () => {
                setOnClick(true);
            },
            dataMediaLibrary: {
                lang: lang,
                selected_ref: null,
                type: "asset",
                select_multiple: false,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <div>
            <div className=" font-bold  flex flex-col items-center">
                <div className=" flex w-full">
                    <span className=" block mr-1 text-lg">{t("property-image")}</span>
                    <Icon
                        tooltip={t("recommended-resolution", {
                            width: resolution?.width || 0,
                            height: resolution?.height || 0,
                        })}
                        type="info"
                        size={1.8}
                    />
                </div>

                <div className=" flex relative mt-2 items-center  justify-center mb-2 rounded border border-gray-300 w-full h-20 bg-gray-100">
                    {!refImage || onErrorImg ? <Icon type="no-image" className=" text-gray-400" size={1.5} /> : null}
                    {refImage && !onErrorImg ? (
                        <img
                            className=" w-full h-20 object-cover rounded"
                            src={Session.getDasUrl(`${refImage}?lang=${lang}`)}
                            onError={() => {
                                setOnErrorImg(true);
                            }}
                        ></img>
                    ) : null}

                    {refImage ? (
                        <div className=" bg-gray-800 bg-opacity-75  absolute bottom-0 left-0 flex w-full h-8  ">
                            <div className=" w-1/3 flex justify-end opacity-0 ">&</div>

                            <div className=" w-1/3 flex justify-center ">
                                <Button
                                    id={`${id ? `${id}-library-image-selector-delete` : null}`}
                                    onClick={() => {
                                        setRefImage(null);
                                    }}
                                >
                                    <Icon type="delete" size={1.3} className=" text-white" />
                                </Button>
                            </div>
                            <div className=" w-1/3 flex justify-end items-center">
                                <Icon
                                    type="info"
                                    size={1.3}
                                    key={nameImg}
                                    style={{ color: "white" }}
                                    className=" pr-3"
                                    tooltip={`${nameImg}`}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                <Button
                    className="w-full"
                    onClick={() => {
                        openModalLibrary();
                    }}
                    design="blue"
                    id={`${id ? `${id}-library-image-selector-add-image` : null}`}
                >
                    {capitalizeFirst(t("add-image"))}
                </Button>
            </div>
        </div>
    );
};

export default LibraryImageSelector;
