import ListMobileAppsComponent from "components/Section/Customers/allProperties/ListMobileApps/ListMobileAppsComponent";
import Properties from "components/Section/Settings/Properties";
import { newRoute, newRedirect } from "./utils";
import AssociatedProperties from "components/Section/Customers/allProperties/AssociatedProperties.js/AssociatedProperties";
import PropertyGroups from "components/Section/Settings/PropertyGroups";
import MobileSettings from "components/Section/Hotel/MobileSettings";

const sectionID = "all-properties";
const packageID = "allProperties";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);
const attrMobileConfig = "mobileConfig";
const attrManageProps = "manageProperties";
const attrManageBrands = "manageBrands";

export const allPropertiesRoutes = [
    route("/", {
        redirect: redirect("/property-management"),
    }),

    route("/mobile-apps", {
        section: ListMobileAppsComponent,
        attribute: attrMobileConfig,
    }),

    route("/mobile-apps/:id/associated-properties", {
        section: AssociatedProperties,
        attribute: attrMobileConfig,
    }),
    route("/mobile-apps/:id", {
        section: MobileSettings,
        attribute: "mobileConfig",
    }),
    route("/property-management", {
        redirect: redirect("/property-management/properties"),
        attribute: attrManageProps,
    }),
    route("/property-management/properties", {
        section: Properties,
        attribute: attrManageProps,
    }),
    route("/property-management/propertyGroup", {
        attribute: attrManageBrands,
        section: PropertyGroups,
    }),
];
