import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import DayPicker, { DateUtils } from "react-day-picker";
import "../../assets/css/filterRangeDate.css";
import {
    parse,
    format,
    isValid,
    isAfter,
    isBefore,
    endOfMonth,
    isSameMonth,
    isSameDay,
    startOfMonth,
    isToday,
    subMonths,
    addMonths,
} from "date-fns";
import { enUS, es } from "date-fns/locale";
import ReactInputMask from "react-input-mask";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";

import { GlobalContext } from "contexts/Global";

const UseFilterRangeDate = ({ id, action, defaultInputName, initRange, selectFromDate }) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const currentMonth = new Date();
    const fromMonth = startOfMonth(currentMonth);
    const dispatch = useDispatch();
    const userLocale = navigator.language || navigator.userLanguage;
    const currentDate = new Date();
    const startDate = selectFromDate || new Date(currentDate);
    startDate.setDate(startDate.getDate() - 29);

    //Logic Component
    const [visibleOptions, setVisibleOptions] = useState(false);

    const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setVisibleOptions(false);
        }
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    //Logic to DayPicker
    const [inputName, setInputName] = useState(defaultInputName);
    const [month, setMonth] = useState(fromMonth);
    const [range, setRange] = useState(initRange || { from: null, to: null });
    const [hours, setHours] = useState({ from: null, to: null });
    const [filterTime, setFilterTime] = useState(initRange || { from: null, to: null });

    useEffect(() => {
        setFilterTime(arrangeFilter(range, hours));
    }, [range, hours]);

    useEffect(() => {
        if (filterTime) {
            if (filterTime.from && filterTime.to) {
                setInputName("date-selected");
                if (id) {
                    dispatch(changeActionValues({ [id]: formatFilterAsAText(filterTime) }));
                }
            } else {
                if (id) {
                    dispatch(changeActionValues({ [id]: formatFilterAsAText(filterTime) }));
                }
                setInputName(defaultInputName);
            }
        }
    }, [filterTime]);

    useEffect(() => {
        if (action) {
            action(filterTime);
        }
    }, [visibleOptions]);

    const handleDayClick = (day) => {
        if (!range.to && !range.from) {
            const now = new Date();
            if (isToday(day)) {
                const startOfDay = new Date(day);
                startOfDay.setHours(0, 0, 0, 0);

                setRange({ from: startOfDay, to: now });
            } else {
                const startOfDay = new Date(day);
                startOfDay.setHours(0, 0, 0, 0);

                const endOfDay = new Date(day);
                endOfDay.setHours(23, 59, 59, 999);

                setRange({ from: startOfDay, to: endOfDay });
            }
        } else if (range.from && !range.to) {
            setRange({ from: range.from, to: day });
        } else {
            const newRange = DateUtils.addDayToRange(day, range);

            if (newRange.from) {
                newRange.from.setHours(0, 0, 0, 0);
            }

            if (newRange.to) {
                if (isToday(newRange.to)) {
                    newRange.to = new Date();
                } else if (isSameDay(newRange.from, newRange.to)) {
                    newRange.from = new Date(newRange.from);
                    newRange.to = new Date(newRange.to);

                    newRange.from.setHours(0, 0, 0, 0);
                    newRange.to.setHours(23, 59, 59, 999);
                } else {
                    newRange.to.setHours(23, 59, 59, 999);
                }
            }

            setRange(newRange);
        }
    };

    const modifiers = {
        startDay: (day) => DateUtils.isSameDay(day, range.from),
        endDay: (day) => DateUtils.isSameDay(day, range.to),
        outsideAndBeforeCurrent: (day) => {
            const currentMonthEnd = endOfMonth(month);
            return isBefore(month, currentMonthEnd) && isAfter(month, startDate) && !isSameMonth(month, new Date());
        },
        afterCurrent: (day) => (isAfter(day, new Date()) && !isSameDay(day, new Date())) || isBefore(day, startDate),
    };

    const onDateChange = (newDates, type) => {
        setRange((prevRange) => {
            if (type === "end" && newDates.to && newDates.to < prevRange.from) {
                newDates.to.setHours(0, 0, 0, 0);
                if (isToday(prevRange.from)) {
                    const now = new Date();
                    prevRange.from.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                } else {
                    prevRange.from.setHours(23, 59, 59, 999);
                }
                return {
                    from: newDates.to,
                    to: prevRange.from,
                };
            }

            if (type === "start" && newDates.from && prevRange.to && newDates.from > prevRange.to) {
                prevRange.to.setHours(0, 0, 0, 0);
                if (isToday(newDates.from)) {
                    const now = new Date();
                    newDates.from.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
                } else {
                    newDates.from.setHours(23, 59, 59, 999);
                }
                return {
                    from: prevRange.to,
                    to: newDates.from,
                };
            }

            if (newDates.from && !isToday(newDates.from)) {
                newDates.from.setHours(0, 0, 0, 0);
            }
            if (prevRange.from && !isToday(prevRange.from)) {
                prevRange.from.setHours(0, 0, 0, 0);
            }

            if (newDates.to && !isToday(newDates.to)) {
                newDates.to.setHours(23, 59, 59, 999);
            }

            if (prevRange.to && !isToday(prevRange.to)) {
                prevRange.to.setHours(23, 59, 59, 999);
            }

            if (newDates.to && isToday(newDates.to)) {
                const now = new Date();
                newDates.to.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            }

            if (prevRange.to && isToday(prevRange.to)) {
                const now = new Date();
                prevRange.to.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
            }

            return {
                from: newDates.from !== undefined ? newDates.from : prevRange.from,
                to: newDates.to !== undefined ? newDates.to : prevRange.to,
            };
        });
    };

    const onHourChange = (selectedHour, type) => {
        setHours((prevState) => {
            if (type === "start") {
                return { ...prevState, from: selectedHour };
            } else if (type === "end") {
                return { ...prevState, to: selectedHour };
            } else {
                return prevState;
            }
        });
    };

    return (
        <div className="relative" ref={containerRef}>
            <div
                onClick={() => setVisibleOptions((prev) => !prev)}
                className=" w-full t-filter-ph flex items-center justify-between bg-gray-200 rounded relative"
            >
                <div
                    className={`${filterTime?.from && filterTime?.to ? " text-gray-900" : ""} ${
                        visibleOptions ? "text-blue-100 font-bold" : ""
                    }`}
                >
                    {capitalizeFirst(t(inputName))}
                </div>
                <div
                    className={` field-icon text-gray-900 absolute top-0 animated right-0 icon-chevron ${
                        visibleOptions ? "mr-4 -rotate-180" : ""
                    }`}
                ></div>
            </div>
            {visibleOptions ? (
                <div
                    className=" absolute rounded bg-white px-4 pb-4  left-0 t-options-container z-20"
                    style={{ width: "27rem", height: "auto", top: "2.4rem", visibleOptions }}
                >
                    <div className=" mb-4 mt-4">
                        <div className=" flex items-center">
                            <span style={{ minWidth: "3rem" }} className=" block font-bold">
                                {capitalizeFirst(t("start_"))}
                            </span>
                            <DateInputComponent
                                date={range.from}
                                range={range}
                                onDateChange={onDateChange}
                                type={"start"}
                                startDate={startDate}
                                removeButtonAction={() => {
                                    setRange((prevRange) => ({
                                        from: null,
                                        to: prevRange?.to ? prevRange.to : null,
                                    }));
                                }}
                            />
                            <HourInputComponent
                                range={range}
                                onHourChange={onHourChange}
                                hours={hours}
                                type={"start"}
                                hour={hours.from}
                                setHours={setHours}
                            />
                        </div>
                        <div className=" flex items-center mt-2">
                            <span style={{ minWidth: "3rem" }} className=" block font-bold">
                                {capitalizeFirst(t("end_"))}
                            </span>
                            <DateInputComponent
                                date={range.to}
                                range={range}
                                onDateChange={onDateChange}
                                type={"end"}
                                startDate={startDate}
                                removeButtonAction={() => {
                                    setRange((prevRange) => ({
                                        from: prevRange?.from ? prevRange.from : null,
                                        to: null,
                                    }));
                                }}
                            />
                            <HourInputComponent
                                range={range}
                                onHourChange={onHourChange}
                                hours={hours}
                                type={"end"}
                                hour={hours.to}
                                setHours={setHours}
                            />
                        </div>
                    </div>
                    <DayPicker
                        className="filter-range-date w-full  border border-gray-100"
                        firstDayOfWeek={1}
                        month={month}
                        navbarElement={<CustomNavbar locale={userLocale} setMonth={setMonth} startDate={startDate} />}
                        fromMonth={month}
                        toMonth={month}
                        modifiers={modifiers}
                        renderDay={renderDay}
                        locale={userLocale}
                        selectedDays={[range.from, { from: range.from, to: range.to }]}
                        onDayClick={handleDayClick}
                        showOutsideDays
                        onMonthChange={(month) => setMonth(month)}
                        weekdayElement={<Weekday locale={userLocale} />}
                    />
                </div>
            ) : null}
        </div>
    );
};

export default UseFilterRangeDate;

const CustomNavbar = ({ month, setMonth, locale = "es-ES", startDate }) => {
    const { lang } = useContext(GlobalContext);
    const isCurrentMonth = isSameMonth(month, new Date());
    const isStartMonth = isSameMonth(month, startDate);
    const currentLocale = lang === "es" ? "es-ES" : locale;
    const monthName = month.toLocaleString(currentLocale, { month: "long" });
    const year = month.getFullYear();

    const handlePreviousMonth = () => {
        if (!isStartMonth) {
            const prevMonth = subMonths(month, 1);
            setMonth(prevMonth);
        }
    };

    const handleNextMonth = () => {
        if (!isCurrentMonth) {
            const nextMonth = addMonths(month, 1);
            setMonth(nextMonth);
        }
    };

    return (
        <div className="custom-nav-bar py-4 border-b ">
            <button
                className={`icon icon-chevron-left focus:outline-none text-gray-900 text-base ${
                    isStartMonth ? "opacity-50 " : ""
                }`}
                onClick={handlePreviousMonth}
                disabled={isStartMonth}
            ></button>
            <span className="font-bold mx-2">{`${capitalizeFirst(monthName)} ${year}`}</span>
            <button
                className={`icon icon-chevron-right focus:outline-none text-gray-900 text-base ${
                    isCurrentMonth ? "opacity-50 " : ""
                }`}
                onClick={handleNextMonth}
                disabled={isCurrentMonth}
            ></button>
        </div>
    );
};

const DateInputComponent = ({ date, range, onDateChange, type, removeButtonAction, startDate }) => {
    const [inputValue, setInputValue] = useState(date ? formatDateToDisplay(date) : "");

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleInputBlur = () => {
        const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
        const today = new Date();

        if (isValid(parsedDate) && parsedDate <= today && parsedDate >= startDate) {
            if (type === "start") {
                onDateChange({ from: parsedDate }, "start");
            } else if (type === "end") {
                onDateChange({ to: parsedDate }, "end");
            }
            setInputValue(formatDateToDisplay(parsedDate));
        } else {
            if (inputValue !== "__/__/____") {
                toast.error(t("invalid-date"));
            }
            setInputValue(date ? formatDateToDisplay(date) : "");
        }
    };

    const handleRemoveClick = () => {
        if (removeButtonAction) {
            removeButtonAction();
        }
    };

    useEffect(() => {
        setInputValue(date ? formatDateToDisplay(date) : "");
    }, [date]);

    const handleInputClick = () => {
        if (type === "start" && !date) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            onDateChange({ from: currentDate }, "start");
            if (!range.to) {
                const currentEndTime = new Date();
                onDateChange({ to: currentEndTime }, "end");
            }
        }
    };

    return (
        <div
            id={`date-input-container-${type}`}
            className=" date-input-container overflow-hidden ml-6 h-10 relative mr-4 flex rounded bg-gray-100"
        >
            <i style={{ top: "0.6rem", left: "0.5rem" }} className=" absolute icon icon-booking "></i>
            {date ? (
                <button
                    id={`date-input-container-${type}-remove`}
                    onClick={handleRemoveClick}
                    style={{ top: "0.6rem", right: "0.5rem" }}
                    className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                >
                    <i className="icon icon-remove text-white text-xxs "></i>
                </button>
            ) : null}
            <ReactInputMask
                id={`date-input-container-${type}-input`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onClick={handleInputClick}
                className=" pl-10 bg-gray-100 rounded w-full "
                mask={"99/99/9999"}
                value={inputValue}
                placeholder={t("date-abbreviation")}
            />
        </div>
    );
};

const HourInputComponent = ({ range, onHourChange, hours, type, hour, setHours }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [inputValue, setInputValue] = useState(hour ? hour : "");
    const [dropdownClicked, setDropdownClicked] = useState(false);
    const [timeIntervals, setTimeIntervals] = useState([]);
    const { t } = useTranslation();
    const firstRender = useRef(true);

    const handleFocus = () => {
        setShowOptions(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            if (!dropdownClicked) {
                setShowOptions(false);

                const fromDate = new Date(range.from);
                const toDate = new Date(range.to);
                const now = new Date();
                const currentTime = `${String(now.getHours()).padStart(2, "0")}:${String(now.getMinutes()).padStart(
                    2,
                    "0"
                )}`;

                const currentTimeInput = timeInMinutes(inputValue);
                const timeFromInMinutes = timeInMinutes(hours.from);
                const timeToInMinutes = timeInMinutes(hours.to);
                if (
                    type === "end" &&
                    isToday(fromDate) &&
                    isToday(toDate) &&
                    currentTimeInput !== null &&
                    timeFromInMinutes !== null
                ) {
                    const currentTimeInMinutes = timeInMinutes(currentTime);
                    if (currentTimeInput < timeFromInMinutes || currentTimeInput > currentTimeInMinutes) {
                        setInputValue(hours.from);
                        onHourChange(hours.from, type);
                        toast.error(t("invalid-hour"));
                        return;
                    }
                } else if (type === "end" && currentTimeInput !== null && timeFromInMinutes !== null) {
                    if (currentTimeInput < timeFromInMinutes) {
                        setInputValue(hours.from);
                        onHourChange(hours.from, type);
                        toast.error(t("invalid-hour"));
                        return;
                    }
                    if (!isToday(fromDate) && isToday(toDate)) {
                        const currentTimeInMinutes = timeInMinutes(currentTime);
                        if (currentTimeInput > currentTimeInMinutes) {
                            setInputValue(currentTime);
                            onHourChange(currentTime, type);
                            toast.error(t("invalid-hour"));
                            return;
                        }
                    }
                }
                if (
                    type === "start" &&
                    isToday(fromDate) &&
                    isToday(toDate) &&
                    currentTimeInput !== null &&
                    timeToInMinutes !== null
                ) {
                    if (currentTimeInput > timeToInMinutes) {
                        setInputValue(hours.to);
                        onHourChange(hours.to, type);
                        toast.error(t("invalid-hour"));
                        return;
                    }
                } else if (type === "start" && currentTimeInput !== null && timeToInMinutes !== null) {
                    if (currentTimeInput > timeToInMinutes) {
                        setInputValue(hours.to);
                        onHourChange(hours.to, type);
                        toast.error(t("invalid-hour"));
                        return;
                    }
                }

                if (!isValidTime(inputValue) && inputValue !== "__:__") {
                    const adjustedTime = adjustToValidTime(inputValue);
                    toast.error(t("invalid-hour"));
                    if (!adjustedTime) {
                        setInputValue("");
                        onHourChange(null, type);
                        return;
                    }
                    setInputValue(adjustedTime);
                    onHourChange(adjustedTime, type);
                    return;
                }
                // if (inputValue !== "__:__") {
                //     // onHourChange(inputValue, type);
                //     // console.log("Here");
                // }
            }
            setDropdownClicked(false);
        }, 150);
    };

    useEffect(() => {
        let intervals = generateTimeIntervals();
        const currentTime = getCurrentTime24HFormat();
        const fromDate = new Date(range.from);
        const toDate = new Date(range.to);
        if (isToday(fromDate) && isToday(toDate)) {
            intervals = intervals.filter((t) => t.val <= currentTime);
            if (type === "start" && hours.to) {
                intervals = intervals.filter((t) => t.val < hours.to);
            }

            if (type === "end" && hours.from) {
                intervals = intervals.filter((t) => t.val > hours.from);
            }
        } else if (isSameDay(fromDate, toDate)) {
            if (type === "end" && hours.from) {
                intervals = intervals.filter((t) => t.val > hours.from);
            }
            if (type === "start" && hours.to) {
                intervals = intervals.filter((t) => t.val < hours.to);
            }
        } else if (type === "end" && !isSameDay(fromDate, toDate) && isToday(toDate)) {
            intervals = intervals.filter((t) => t.val <= currentTime);
        }
        setTimeIntervals(intervals);
    }, [range, hours, type]);

    // useEffect(() => {}, [range]);

    useEffect(() => {
        if (!firstRender.current) {
            const currentTime = getCurrentTime24HFormat();
            if (type === "start" && hours.from !== null) {
                if (isToday(new Date(range.from))) {
                    setInputValue(currentTime);
                    onHourChange(currentTime, type);
                }
            } else if (type === "end" && hours.to !== null) {
                if (isToday(new Date(range.to))) {
                    setInputValue(currentTime);
                    onHourChange(currentTime, type);
                }
            }

            if (!hours.from || !hours.to) {
                return;
            }

            const startHour = parseInt(hours.from.split(":")[0], 10);
            const startMinute = parseInt(hours.from.split(":")[1], 10);
            const endHour = parseInt(hours.to.split(":")[0], 10);
            const endMinute = parseInt(hours.to.split(":")[1], 10);

            if (
                isSameDay(new Date(range.from), new Date(range.to)) &&
                !isToday(range.from) &&
                !isToday(range.to) &&
                (endHour < startHour || (endHour === startHour && endMinute <= startMinute))
            ) {
                const tempStart = hours.from;
                const tempEnd = hours.to;

                if (type === "start") {
                    setInputValue(tempEnd);
                } else if (type === "end") {
                    setInputValue(tempStart);
                }
            }
        }
        firstRender.current = false;
    }, [range]);

    const handleTimeClick = (timeVal) => {
        setInputValue(timeVal);
        setDropdownClicked(true);
        onHourChange(timeVal, type);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <div id={`hour-input-container-${type}`} className="relative date-input-container rounded">
            <div
                tabIndex={0}
                onFocus={handleFocus}
                onBlur={handleBlur}
                // style={{ border: "1.5px solid #2286EF " }}
                className="overflow-hidden h-10 relative flex rounded bg-gray-100"
            >
                <i style={{ top: "0.6rem", left: "0.5rem" }} className="absolute icon icon-clock text-gray-800"></i>
                <ReactInputMask
                    id={`hour-input-container-${type}-input`}
                    handleBlur={handleBlur}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    className="pl-10 bg-gray-100 rounded w-full"
                    mask={"99:99"}
                    value={inputValue}
                    placeholder="hh:mm"
                />
                {hour ? (
                    <button
                        id={`hour-input-container-${type}-remove`}
                        onClick={() => {
                            setInputValue("");
                            setShowOptions(false);
                            if (type === "start") {
                                setHours((prev) => ({ ...prev, from: null }));
                            }
                            if (type === "end") {
                                setHours((prev) => ({ ...prev, to: null }));
                            }
                        }}
                        style={{ top: "0.6rem", right: "0.6rem" }}
                        className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                    >
                        <i style={{ marginLeft: "0rem" }} className="icon icon-remove text-white text-xxs "></i>
                    </button>
                ) : null}
            </div>
            {showOptions ? (
                <div
                    id={`hour-input-container-${type}-options-intervals`}
                    onClick={() => setDropdownClicked(true)}
                    style={{ top: "2.6rem", maxHeight: "20rem", minHeight: "auto", left: "0", zIndex: "10" }}
                    className="absolute w-full p-4 t-options-container overflow-y-scroll bg-white"
                >
                    {timeIntervals.map((t) => {
                        // console.log(t.val.replace(":", "-"));
                        return (
                            <div
                                id={`hour-input-container-${type}-input-options-interval-${t.val.replace(":", "-")}`}
                                onClick={() => handleTimeClick(t.val)}
                                className="text-center mb-2 w-full hover:text-zafiro-400 cursor-pointer"
                            >
                                {t.val}
                            </div>
                        );
                    })}
                    {timeIntervals.length === 0 ? <div className="text-center">{t("no-intervals-available")}</div> : ""}
                </div>
            ) : null}
        </div>
    );
};

function renderDay(day) {
    return <div>{format(day, "d")}</div>;
}

function Weekday({ weekday, className, locale }) {
    const { lang } = useContext(GlobalContext);
    const spanishAbbreviations = ["D", "L", "M", "X", "J", "V", "S"];
    const weekdayName = format(new Date(1970, 1, weekday + 1), "iiii", {
        locale: locale === "es-MX" ? es : enUS,
    });

    let displayedWeekday;
    if (lang === "es") {
        displayedWeekday = spanishAbbreviations[weekday];
    } else {
        displayedWeekday = weekdayName.slice(0, 1).toUpperCase() + weekdayName.slice(1, 3);
    }

    return <div className={className}>{displayedWeekday}</div>;
}

const generateTimeIntervals = () => {
    let times = [];

    for (let i = 0; i < 24; i++) {
        for (let j = 0; j < 60; j += 15) {
            let hour12 = (i % 12 || 12).toString().padStart(2, "0");
            let hour24 = i.toString().padStart(2, "0");
            let minute = j.toString().padStart(2, "0");
            let period = i < 12 ? "am" : "pm";

            let text = `${hour12}:${minute} ${period}`;
            let val = `${hour24}:${minute}`;

            times.push({ text, val });
        }
    }

    return times;
};

const formatDateToDisplay = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const getCurrentTime24HFormat = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
};

const isValidTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
};

const adjustToValidTime = (time) => {
    let [hours, minutes] = time.split(":").map(Number);
    if (isNaN(hours) || isNaN(minutes)) {
        return "";
    }

    if (hours > 23) {
        hours = 23;
    }

    if (minutes > 59) {
        minutes = 59;
    }
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

const timeInMinutes = (time) => {
    if (!time) return null;

    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
};

const arrangeFilter = (range, hours) => {
    const filter = { from: null, to: null };
    if (!range.from && !range.to) {
        return filter;
    }

    if (range.from) {
        let fromDate = new Date(range.from);

        if (hours.from) {
            const fromHours = parseInt(hours.from.split(":")[0], 10);
            const fromMinutes = parseInt(hours.from.split(":")[1], 10);

            fromDate.setHours(fromHours);
            fromDate.setMinutes(fromMinutes);
            fromDate.setSeconds(0);
            fromDate.setMilliseconds(0);
        }

        filter.from = fromDate.toISOString();
    }

    if (range.to) {
        let toDate = new Date(range.to);

        if (hours.to) {
            const toHours = parseInt(hours.to.split(":")[0], 10);
            const toMinutes = parseInt(hours.to.split(":")[1], 10);

            toDate.setHours(toHours);
            toDate.setMinutes(toMinutes);
            toDate.setSeconds(0);
            toDate.setMilliseconds(0);
        }

        filter.to = toDate.toISOString();
    }

    return filter;
};

const formatFilterAsAText = (obj) => {
    if (!obj.from || !obj.to) {
        return "";
    } else {
        return `{ from: "${obj.from}", to: "${obj.to}" }`;
    }
};
