import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, composeDestination, ROOM_BG_LABEL, TYPENAME_GRAPHQL } from "../../hooks/Utils/Utils";
import { useSelector } from "react-redux";
import UseComponentLoading from "../useComponentLoading";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { mapReasonToDestination } from "../../hooks/Utils/Customers/MessagesUtils";
import sanitizeHtml from "sanitize-html";

const UseModalResultsDestinations = ({ allfilters, typeDestination }) => {
    const { t } = useTranslation();
    const actionData = useSelector((state) => state.action.values);
    const filterOccupied = useSelector((state) => state.action.filterOccupied);
    const [numResults, setNumResults] = useState(0);
    const [roomsResults, setRoomsResults] = useState([]);
    const [roomsResultsToolTip, setRoomsResultsToolTip] = useState("");

    const destinationFilter = useRef({});
    const RESULTS_DESTINATION = gql`{
        filterDestination(destination: ${destinationFilter.current} ${
        filterOccupied ? `onlyRoomsWithGuests:true` : ""
    }) {
            results {
                stayGuestRoomReasons {
                    reason {
                        all
                        countryRefs
                        floorIDs
                        languageRefs
                        roomGroupIDs
                        roomIDs
                        stayGroupIDs
                        stayGuestIDs
                        wingIDs
                    }
                    roomId
                    roomName
                    stayGuestId
                    stayGuestName
                    stayGuestSurname
                }
            }
        }
    }`;

    const [getResultsDestination, { data, loading }] = useLazyQuery(RESULTS_DESTINATION);

    useEffect(() => {
        if (
            Object.keys(actionData).filter((key) => key.includes("filter_")) &&
            actionData[
                Object.keys(actionData)
                    .filter((key) => key.includes("filter_"))
                    .pop()
            ]
        ) {
            destinationFilter.current = composeDestination({ values: actionData });
            getResultsDestination();
        } else {
            setRoomsResults([]);
        }
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        setNumResults(roomsResults ? roomsResults.length : 0);
    }, [roomsResults]);

    useEffect(() => {
        if (!loading && data && data.filterDestination) {
            setRoomsResults(data.filterDestination.results[0].stayGuestRoomReasons);
        }
        // eslint-disable-next-line
    }, [data]);

    const printResultRooms = () => {
        return (
            <span className={`${numResults === 0 ? "text-gray-700" : ""}`}>{`${numResults} ${
                numResults === 1 ? t("room") : t("rooms")
            }`}</span>
        );
    };

    const printRoomLabels = () => {
        return (
            <>
                {roomsResults.map((room, index) => {
                    return (
                        <div
                            id={`${room.roomId ? room.roomId : 0}-room`}
                            key={index}
                            className={`rounded py-1 px-4 m-1 text-white inline-block ${ROOM_BG_LABEL} cursor-default`}
                            data-tip={roomsResultsToolTip}
                            data-for="dangerous-html-tooltip"
                            onMouseOver={() => getRoomResultToolTip(room)}
                        >{`${room.roomName}`}</div>
                    );
                })}
            </>
        );
    };
    const getRoomResultToolTip = (room) => {
        let _tooltip = [];
        const reasonKeys = Object.keys(room.reason);
        reasonKeys.forEach((key) => {
            if (key === "all" && room.reason[key]) {
                _tooltip.push(`<div class="text-center">${t("all property")}</div>`);
            } else if (key !== "all" && room.reason[key].length > 0 && key !== TYPENAME_GRAPHQL) {
                room.reason[key].forEach((reasonElement) => {
                    _tooltip.push(
                        `<div class="text-center">${
                            allfilters[mapReasonToDestination(key)].filter(
                                (destination) => destination.id === reasonElement || destination.ref === reasonElement
                            )[0].name
                        }</div>`
                    );
                });
            }
        });
        if (room.stayGuestName) {
            _tooltip.push(
                `<div class="text-center"><strong>${sanitizeHtml(room.stayGuestName)} ${sanitizeHtml(
                    room.stayGuestSurname
                )}</strong></div>`
            );
        }
        setRoomsResultsToolTip(_tooltip.join(""));
    };

    return (
        <>
            <div className="mb-4">
                {capitalizeFirst(t(typeDestination === "gridChannelsDestinations" ? "results-filtering" : "results"))}:
            </div>
            {loading ? (
                <UseComponentLoading />
            ) : (
                <div className="w-full bg-gray-200 rounded p-2">
                    <div>{printResultRooms()}</div>
                    <div>{printRoomLabels()}</div>
                </div>
            )}
        </>
    );
};

export default UseModalResultsDestinations;
