import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import UseSectionHeader from "../../../useSectionHeader";

//Actions
import { cleanAction } from "../../../../actions/globalActions";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setSectionContent } from "../../../../actions/sectionActions";

//Utils
import { Session } from "../../../../hooks/Utils/Session";
import GuestInfo from "./GuestInfo";
import GuestInfoRequest from "../../../../hooks/GraphqlCalls/Guest/GuestInfo";
import UpdateComment from "../../../../hooks/GraphqlCalls/Guest/Comment";
import UpdateGuestData from "../../../../hooks/GraphqlCalls/Guest/UpdateGuestData";
import UpdateStay from "../../../../hooks/GraphqlCalls/Guest/UpdateStay";

const GuestDetail = () => {
    //Params
    const { id } = useParams();
    //Store data
    const { sectionName } = useSelector((state) => state.sectionContent);

    const pLocation = Session.getSessionProp("previous-location");

    //Actions
    const dispatch = useDispatch();

    //listeners
    useEffect(() => {
        dispatch(showGlobalLoading(true));
        return () => {
            dispatch(cleanAction());
            dispatch(
                setSectionContent({
                    data: null,
                    groupsData: null,
                })
            );
        };
        // eslint-disable-next-line
    }, []);

    GuestInfoRequest({ id: id });

    return (
        <>
            <UpdateComment />
            <UpdateGuestData />
            <UpdateStay />
            <UseSectionHeader
                title={sectionName}
                buttonColor="btn-blue"
                action={"function"}
                value={"demo"}
                navToSection={pLocation.indexOf("room/detail/") > -1 ? null : "/customers/guests/guest-list"}
                navToPreviousPage={pLocation.indexOf("room/detail/") > -1 ? true : null}
                breadcrumbsLimit={2}
            />
            <GuestInfo></GuestInfo>
        </>
    );
};

export default GuestDetail;
