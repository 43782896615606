export const Header = [
    "account",
    "common-zones",
    "customers",
    "design",
    "stats",
    "has-changed",
    "lang",
    "close-session",
    "change-password",
    "select",
    "to",
    "your",
    "hotel",
    "legal-matters",
    "privacy-policy",
    "accept",
    "help",
    "support",
    "FAQ",
    "my-account",
    "contracted-products",
    "information",
    "premium",
    "user-management",
    "language-management",
    "content-languages",
    "general-settings",
    "current-password",
    "new-password",
    "enter",
    "invalid-current-password",
    "password-six-chars",
    "password-upper-and-lower",
    "password-numbers",
    "media-library",
    "property-management",
    "policy-and-conditions-management",
    "sales",
];
