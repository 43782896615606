import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { Session } from "../../../hooks/Utils/Session";

const PasscodesPreview = () => {
    const { id } = useParams();
    const { name } = useSelector((state) => state.hotel);
    const { t } = useTranslation();

    const [nameWifi, setNameWifi] = useState("");
    const [codePasscode, setCodePasscode] = useState("");
    const [nameHotel, setNameHotel] = useState("");

    const GET_DATA = gql`
    query {
        hotelwifi: wifiSettings(kind: "Hotel") {
          results {
            name
          }
        }
        wifiAccessCodes(filter: { id: ${id} }) {
          results {
            code
          }
        }
      }
      
    `;

    const [executeQuery, { data, loading }] = useLazyQuery(GET_DATA, {
        onError(err) {
            console.log(err);
        },
    });

    useEffect(() => {
        executeQuery();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && !loading && data.hotelwifi && data.wifiAccessCodes) {
            if (data.hotelwifi && data.hotelwifi.results && data.hotelwifi.results.name) {
                setNameWifi(data.hotelwifi.results.name);
            }
            if (
                data.wifiAccessCodes &&
                data.wifiAccessCodes.results &&
                data.wifiAccessCodes.results.length >= 1 &&
                data.wifiAccessCodes.results[0].code
            ) {
                setCodePasscode(data.wifiAccessCodes.results[0].code);
            }
        }
        //eslint-disable-next-line
    }, [data, loading]);

    useEffect(() => {
        if (name) {
            setNameHotel(name);
        } else {
            const dataHotel = Session.getSessionProp("currentHotel")
                ? JSON.parse(Session.getSessionProp("currentHotel"))
                : null;

            if (dataHotel && dataHotel.name) {
                setNameHotel(dataHotel.name);
            }
        }
        //eslint-disable-next-line
    }, [name]);

    return (
        <div className=" relative flex items-center justify-center" style={{ height: "100%" }}>
            <div className=" bg-white flex flex-col items-center justify-center">
                <div className=" w-4/5 items-center justify-items-center text-center mx-14 text-xl font-bold pt-10 pb-8 border-b ">
                    {nameHotel}
                </div>
                <div className=" w-full pt-8 flex pb-18 gap-10 items-center px-28 ">
                    <div>
                        <div className=" font-bold text-base pb-10 ">{capitalizeFirst(t("wifi-name"))}</div>
                        <div className=" font-bold text-base pb-10 ">{capitalizeFirst(t("wifipasscode"))}</div>
                    </div>
                    <div>
                        <div className=" text-sm pb-10">{nameWifi}</div>
                        <div className=" text-sm pb-10">{codePasscode}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasscodesPreview;
