import React, { useState } from "react";
import UseSearch from "./useSearch";
import { useTranslation } from "react-i18next";
import UseCheckBox from "./Inputs/useCheckBox";
import { capitalizeFirst } from "../hooks/Utils/Utils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeActionValues } from "../actions/globalActions";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const UseCompactTable = ({
    useSearch = true,
    countName = "items",
    columnName = "items",
    iconRow = "icon-delete",
    bottomText = "delete-selected-items",
    data = null,
    actionButton,
    tableName = "default",
    actionBottomButton,
    emptyList = "no-items",
    heightTable = "13.7rem",
    infoStateCell = false,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [checkedItems, setCheckedItems] = useState([]);
    const [idsItems, setIdsItems] = useState([]);
    const [activeMainCB, setActiveMainCb] = useState(false);
    const [criteria, setCriteria] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [copyData, setCopyData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            const arrIds = [];
            data.forEach((item) => {
                if (item.value) {
                    arrIds.push(item.value);
                }
            });
            setFinalData(filterAndSortData(data, searchValue, criteria));
            setIdsItems(arrIds);
            setCopyData(data);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(
            changeActionValues({
                [`${tableName}-checkedItames`]: checkedItems,
            })
        );
        // eslint-disable-next-line
    }, [checkedItems]);

    const filterAndSortData = (data, searchValue, criteria) => {
        let filteredData = data.filter((item) => item.text.toLowerCase().includes(searchValue.toLowerCase()));
        if (criteria === "asc") {
            filteredData = sortDataDescending(filteredData);
        } else if (criteria === "desc") {
            filteredData = sortDataAscending(filteredData);
        }
        return filteredData;
    };

    const sortDataDescending = (data) => {
        data.sort((a, b) => {
            if (a.text < b.text) {
                return 1;
            }
            if (a.text > b.text) {
                return -1;
            }
            return 0;
        });
        return data;
    };

    const sortDataAscending = (data) => {
        data.sort((a, b) => {
            if (a.text > b.text) {
                return 1;
            }
            if (a.text < b.text) {
                return -1;
            }
            return 0;
        });
        return data;
    };

    const row = (rowData, index) => {
        return (
            <div
                id={`compact-table-${index}-row`}
                className={` flex border-b border-gray-200 py-3 items-center ${index !== 0 ? " border-t" : ""} `}
                style={{ width: "98%" }}
            >
                <span className=" w-0.5/12 " style={{ marginTop: "-1.75rem" }}>
                    <UseCheckBox
                        key={checkedItems}
                        checked={checkedItems.includes(rowData.value)}
                        onClick={() => {
                            if (rowData.value && !checkedItems.includes(rowData.value)) {
                                setCheckedItems((prevCheckedItems) => [...prevCheckedItems, rowData.value]);
                            } else {
                                setCheckedItems((prevCheckedItems) =>
                                    prevCheckedItems.filter((item) => item !== rowData.value)
                                );
                            }
                        }}
                        id={`compact-table-checkbox-${index}-row`}
                    />
                </span>
                <div className={`${infoStateCell ? "w-8/12" : " w-11/12"}`}>
                    <Button
                        id={`compact-table-text-button-${index}`}
                        onClick={() => {
                            if (rowData?.link) {
                                navigate(rowData?.link);
                            }
                        }}
                    >
                        <span
                            className={`pl-1 ${rowData.link ? " text-zafiro-600 cursor-pointer" : " text-gray-800"} `}
                        >
                            {rowData.text}
                        </span>
                    </Button>
                </div>
                {infoStateCell ? (
                    <span className={` w-3/12 ${rowData?.enabled ? " text-green-100" : "text-red-100"} `}>
                        {capitalizeFirst(t(rowData?.enabled ? "available" : "unavailable"))}
                    </span>
                ) : null}
                <span
                    className={`w-0.5/12 cursor-pointer icon ${iconRow} text-gray-800 text-xl`}
                    onClick={() => {
                        if (typeof actionButton === "function") {
                            actionButton(rowData.value);
                        }
                    }}
                    id={`delete-button-${index}-row`}
                ></span>
            </div>
        );
    };

    return (
        <div className=" w-full ">
            <div className=" flex items-center justify-between ">
                <div>
                    {useSearch ? (
                        <UseSearch
                            placeholder={"search-for"}
                            value={searchValue}
                            onChange={(val) => {
                                setSearchValue(val ? val : "");
                                setFinalData(filterAndSortData([...copyData], val, criteria));
                            }}
                        />
                    ) : null}
                </div>{" "}
                <span> {` ${copyData.length} ${t(countName)}`} </span>
            </div>
            <div className=" mt-6 flex items-center">
                <span style={{ marginTop: "-1.75rem" }}>
                    <UseCheckBox
                        key={activeMainCB}
                        checked={activeMainCB}
                        onClick={() => {
                            if (!activeMainCB === true) {
                                setCheckedItems(idsItems);
                            } else {
                                setCheckedItems([]);
                            }
                            setActiveMainCb(!activeMainCB);
                        }}
                        id={"all-products"}
                    />
                </span>
                <span className=" font-bold mr-1 "> {capitalizeFirst(t(columnName))} </span>
                <span
                    className={` cursor-pointer text-lg mt-1 icon ${
                        criteria === null
                            ? "icon-table-sort"
                            : criteria === "desc"
                            ? "icon-sort-desc"
                            : criteria === "asc"
                            ? "icon-sort-asc"
                            : ""
                    }`}
                    onClick={() => {
                        if (criteria === null) {
                            setCriteria("desc");
                            setFinalData(filterAndSortData([...copyData], searchValue, "desc"));
                        } else if (criteria === "desc") {
                            setCriteria("asc");
                            setFinalData(filterAndSortData([...copyData], searchValue, "asc"));
                        } else if (criteria === "asc") {
                            setCriteria(null);
                            setFinalData(filterAndSortData([...copyData], searchValue, null));
                        }
                    }}
                ></span>
            </div>
            <div className=" border border-gray-200 pl-4 pr-1 flex flex-col items-center mt-2 rounded ">
                <div
                    id="compact-table-rows-container"
                    style={{ minHeight: heightTable, maxHeight: heightTable, overflow: "scroll", width: "100%" }}
                >
                    {finalData.length > 0 ? (
                        finalData.map((dataItem, index) => {
                            return row(dataItem, index);
                        })
                    ) : (
                        <div className=" w-full mt-2 items-center justify-center">
                            <p className=" text-center">{t(emptyList)}</p>
                        </div>
                    )}
                </div>
            </div>
            <div
                className=" pl-5 cursor-pointer mt-4 text-zafiro-600 font-bold"
                onClick={() => {
                    if (typeof actionBottomButton === "function" && checkedItems.length > 0) {
                        actionBottomButton(checkedItems, finalData);
                        setCheckedItems([]);
                        setActiveMainCb(false);
                    }
                }}
            >
                {t(bottomText)}{" "}
            </div>
        </div>
    );
};

export default UseCompactTable;
