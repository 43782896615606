import React from "react";
import Button from "components/Button";
import Checkbox from "components/Inputs/Checkbox";
import UseLabel from "components/Inputs/useLabel";
import Select from "components/Select";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import { useModal } from "components/Modal";

export const AddAndEditComponent = ({
    addRoomGroupOrEdit,
    selectData,
    handleSelect,
    handleDelete,
    editGroupName,
    labelGroupSelect,
    labelGroupAdded,
    inputTextPlaceholder,
    inputSelectPlaceholder,
    type,
}) => {
    const { t } = useTranslation();
    const { close } = useModal();

    const addRoomGroupSelectProps = {
        id: "rooms-of-group",
        multiple: true,
        placeholder: inputSelectPlaceholder,
        onChange: (value) => handleSelect(value),
    };

    const selectedOptions = selectData?.options?.filter((room) => room?.selected).map((room) => room?.id);

    const editRoomGroupSelectProps = {
        id: "rooms-of-group",
        multiple: true,
        ...(selectedOptions.length > 0 && { value: selectedOptions }),
        placeholder: !selectedOptions.length > 0 && inputSelectPlaceholder,
        onChange: (value) => handleSelect(value),
    };

    const addTextInputProps = {
        id: "group-name",
        onChange: (value) => editGroupName(value),
        placeholder: inputTextPlaceholder,
    };

    const editTextInputProps = {
        id: "group-name",
        value: selectData?.groupName,
        onChange: (value) => editGroupName(value),
    };

    const selectProps = (type === "add" && addRoomGroupSelectProps) || (type === "edit" && editRoomGroupSelectProps);
    const textInputProps = (type === "add" && addTextInputProps) || (type === "edit" && editTextInputProps);

    return (
        <div>
            <main className="flex gap-40 w-full mt-6">
                <div className="w-1/2">
                    <div className="w-3/4">
                        <UseLabel label={["give-group-name", ":"]} />
                        <TextInput {...textInputProps} />
                    </div>
                    <div className="w-full mt-12">
                        <label>{labelGroupSelect}</label>
                        <Select {...selectProps} options={selectData?.options} />
                    </div>
                </div>
                <div className="w-full">
                    <label>{labelGroupAdded}</label>
                    <div className="bg-gray-200 h-48 w-full">
                        {selectData?.options?.length > 0 ? (
                            <div className="flex flex-wrap gap-4 items-center p-4 w-full">
                                {selectData?.options?.map((room, index) =>
                                    room?.selected ? (
                                        <div
                                            className="bg-zafiro-400 text-white flex gap-6 items-center rounded-md pl-2 p-1"
                                            key={index}
                                        >
                                            <p>{room?.label}</p>
                                            <div onClick={() => handleDelete(room?.id)}>
                                                <Icon type="close" className="cursor-pointer" />
                                            </div>
                                        </div>
                                    ) : null
                                )}
                            </div>
                        ) : null}
                    </div>
                </div>
            </main>
            <footer className="flex w-full gap-4 mt-10 justify-end">
                <Button id="cancel" design="white" onClick={close}>
                    {t("cancel")}
                </Button>
                <Button id="alert-accept" design="blue" onClick={addRoomGroupOrEdit}>
                    {t("accept")}
                </Button>
            </footer>
        </div>
    );
};
