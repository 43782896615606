import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData } from "../../Utils/Integrations/useVendure";
import _ from "lodash";

const UpdateGeneralSettings = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const dispatch = useDispatch();

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "shops-update-price-include-tax":
                    let shops = executeQuery.params ? executeQuery.params.shops : null;
                    mutation = "mutation { ";
                    if (shops && shops.length > 0) {
                        // eslint-disable-next-line
                        shops.map((shop, index) => {
                            mutation += `
                                updateChannel${index}: updateChannel(input:{id:"${
                                shop.id
                            }",customFields:{price_field:"${executeQuery.params.value ? "WithTax" : "WithoutTax"}"}}){
                                    __typename
                                }
                            `;
                        });
                    }
                    mutation += "}";
                    executeQuery.mutationName = "updateChannel";
                    executeQuery.closeModal = false;
                    break;
                case "sales-allergens-translate":
                    let facetId = actionData && actionData.values ? actionData.values["facetId"] : null;
                    let languages = actionData && actionData.values ? actionData.values["translations"] : null;
                    let facetValues = [];
                    mutation = "mutation { ";
                    if (languages && languages.length > 0) {
                        let allergens = languages[0].allergens;
                        if (allergens && allergens.length > 0) {
                            // eslint-disable-next-line
                            allergens.map((allergen) => {
                                let facet = {
                                    id: allergen.facetId,
                                    code: allergen.facetCode,
                                    translations: [],
                                };
                                // eslint-disable-next-line
                                languages.map((language) => {
                                    let allergenInLang = language.allergens
                                        ? language.allergens.filter(
                                              (allergenFiltered) => allergenFiltered.facetCode === allergen.facetCode
                                          )
                                        : null;
                                    if (allergenInLang && allergenInLang.length > 0) {
                                        allergenInLang = allergenInLang[0];
                                        if (allergenInLang.translationValue && allergenInLang.translationValue !== "") {
                                            let translation = {
                                                languageCode: language.ref,
                                                name: allergenInLang.translationValue,
                                            };
                                            if (allergenInLang.translationId)
                                                translation.id = allergenInLang.translationId;
                                            facet.translations.push(translation);
                                        }
                                    }
                                });
                                if (facet.translations && facet.translations.length > 0) {
                                    facetValues.push(facet);
                                }
                            });
                        }
                    }
                    if (facetValues && facetValues.length > 0) {
                        // eslint-disable-next-line
                        facetValues.forEach((facetValue, index) => {
                            if (facetValue.id && facetValue.id > 0) {
                                //code:"${facetValue.code}"
                                mutation += `
                                    updateFacetValues${index}: updateFacetValues(input:{
                                        id:${facetValue.id}
                                        translations:[${facetValue.translations.map(
                                            (translation) =>
                                                `{languageCode:${translation.languageCode}, name:"${(
                                                    translation?.name || ""
                                                ).replace(/"/g, '\\"')}" ${
                                                    translation.id ? `id:${translation.id}` : ``
                                                } } `
                                        )}]
                                    }) {
                                        id
                                    }
                                `;
                            } else {
                                mutation += `
                                    createFacetValues${index}: createFacetValues(input:{
                                        facetId:${facetId}
                                        code:"${(facetValue.code || "").replace(/"/g, '\\"')}"
                                        translations:[${facetValue.translations.map(
                                            (translation) =>
                                                `{languageCode:${translation.languageCode}, name:"${(
                                                    translation?.name || ""
                                                ).replace(/"/g, '\\"')}" ${
                                                    translation.id ? `id:${translation.id}` : ``
                                                } } `
                                        )}]
                                    }){
                                        id
                                    }
                                `;
                            }
                        });
                        mutation += "}";
                        executeQuery.mutationName = "createFacetValues";
                        executeQuery.closeModal = true;
                    } else {
                        execute = false;
                        cancelAction();
                    }
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(mutation, executeAnotherQuery);
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (mutation, executeAnotherQuery) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: mutation });
        if (response && response.data && !response.data.errors) {
            if (executeQuery.mutationName) {
                let mutationObject = response.data[executeQuery.mutationName];
                if (mutationObject && mutationObject.result && mutationObject.result.toUpperCase().includes("NOT")) {
                    errorMutation = true;
                }
            }
        } else {
            errorMutation = true;
        }
        if (!errorMutation) {
            if (executeAnotherQuery) {
                let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    let param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            } else {
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                dispatch(setExecuteQuery(null));
                dispatch(cleanAction());
                dispatch(setLoading(false));
                if (executeQuery.cacheKeyToDelete) {
                    removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
                }
            }
        } else {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            toast.error(t("mutation-error"));
        }
    };

    const cancelAction = async () => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        dispatch(setRefreshContentData(true));
        dispatch(setRefreshData(true));
        dispatch(setExecuteQuery(null));
        dispatch(cleanAction());
        dispatch(setLoading(false));
        if (executeQuery.cacheKeyToDelete) {
            removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
        }
    };

    return null;
};

export default withApollo(UpdateGeneralSettings);
