import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import useModalAssignEmails, { TYPES_MODAL_ASSIGN_EMAILS } from "components/Modal/useModalAssignEmails";
import { cancelEdit } from "../utils";
import { Session } from "hooks/Utils/Session";

const SettingsCardEmail = ({ dataModified, SET_EXECUTE, refetch, setLoading, setCardInEdition }) => {
    const { t } = useTranslation();
    const { open } = useModalAssignEmails();

    const projectData = JSON.parse(Session.getSessionProp("currentHotel")) || {};
    const projectId = projectData?.id || null;

    return (
        <div className={`${!dataModified?.sendEmail ? " text-gray-700" : ""}`}>
            <div className="mt-4">{t("select-the-users-who-will-receive-an-email-with-guest-feedback")}</div>
            <div className=" flex justify-between mt-2">
                <div>{`${t("x emails", {
                    count: dataModified?.feedbackEmails?.length + dataModified?.users?.length,
                })}`}</div>
                <Button
                    onClick={() => {
                        open({
                            title: t("email-to-receive-user-feedback-edit"),
                            action: SET_EXECUTE,
                            type: TYPES_MODAL_ASSIGN_EMAILS.FEEDBACK_SETTINGS,
                            selectedData: {
                                emails: dataModified?.feedbackEmails || [],
                                users: dataModified?.users.map((user) => user.id) || [],
                            },
                            actionPostUpdate: () => {
                                refetch();
                                SET_EXECUTE({
                                    ACTION: "",
                                    PARAMS: {},
                                });
                                cancelEdit(setCardInEdition);
                            },
                            extraVariables: {
                                projectID: projectId,
                            },
                        });
                    }}
                    disabled={!dataModified?.sendEmail}
                    id="settings-card-email-edit-button"
                >
                    <Icon className="text-gray-800" type="edit" size={1.3} />
                </Button>
            </div>
        </div>
    );
};

export default SettingsCardEmail;
