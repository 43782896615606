import Capacity from "../components/Section/CommonZones/Capacity";
import Cleaning from "../components/Section/CommonZones/Cleaning";
import Staff from "../components/Section/CommonZones/Staff";
import Categories from "../components/Section/CommonZones/Categories";
import Areas from "../components/Section/CommonZones/Areas";
import AreaSchedule from "../components/Section/CommonZones/AreaSchedule";
import BookingCategories from "../components/Section/CommonZones/BookingCategories";
import BookingCategory from "../components/Section/CommonZones/BookingCategory";
import AreaDescription from "../components/Section/CommonZones/AreaDescription";
import AreaClose from "../components/Section/CommonZones/AreaClose";
import { newRoute, newRedirect } from "./utils";

const sectionID = "common-zones";
const packageID = "goOutSafe";

const attrAreas = "areas";
const attrBookings = "bookings";
const attrCapacity = "capacity";
const attrCategories = "categories";
const attrCleaning = "cleaning";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const commonZonesRoutes = [
    route("/", {
        redirect: redirect("/booking/categories"),
    }),

    route("/zones-configuration/areas", {
        section: Areas,
        attribute: attrAreas,
    }),
    route("/zones-configuration/area", {
        redirect: redirect("/zones-configuration/areas"),
        attribute: attrBookings,
    }),
    route("/zones-configuration", {
        redirect: redirect("/zones-configuration/areas"),
        attribute: attrBookings,
    }),
    route("/zones-configuration/area/description/:id", {
        section: AreaDescription,
        attribute: attrAreas,
    }),
    route("/zones-configuration/area/close/:id", {
        section: AreaClose,
        attribute: attrAreas,
    }),

    route("/booking", {
        redirect: redirect("/booking/categories"),
        attribute: attrBookings,
    }),
    route("/booking/categories", {
        section: BookingCategories,
        attribute: attrBookings,
    }),
    route("/booking/categories/area/:id", {
        section: BookingCategory,
        attribute: attrAreas,
    }),
    route("/booking/categories/area/:id/:date", {
        section: BookingCategory,
        attribute: attrAreas,
    }),

    route("/capacity", {
        section: Capacity,
        attribute: attrCapacity,
    }),

    route("/zones-configuration/categories", {
        section: Categories,
        attribute: attrCategories,
    }),
    route("/zones-configuration/areas/schedule/:id", {
        section: AreaSchedule,
        attribute: attrCategories,
    }),

    route("/cleaning", {
        section: Cleaning,
        attribute: attrCleaning,
    }),

    // route("/staff", {
    //     section: Staff,
    //     attribute: "staff",
    // }),
];
