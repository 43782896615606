import React, { useContext } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Actions from "./CommonOptions/Actions";
import { EditorContext } from "../../../../../contexts/editor";
import { SCREEN } from "../../../../../hooks/Utils/Design/EditorUtils";

const TextToolbar = ({ deviceSpecific }) => {
    const { deviceType } = useContext(EditorContext);
    const { t } = useTranslation();
    const { type: screenType } = useParams();
    return (
        <>
            <Toolbar type="text-settings"></Toolbar>
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id="bgColor" title={t("background")} />
                    </Settings.Group>
                    <Settings.Border t={t} />
                    <Settings.Group title={t("margins")}>
                        <Input.Number id="padding" deviceSpecific={true} title={t("width")} min="0" max="30" />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            {screenType !== SCREEN.TYPE.PORTAL ? (
                <Toolbar type="actions">
                    <Actions
                        actions={{
                            content: true,
                            landing: true,
                            section: true,
                            url: deviceType !== "TV",
                            library: true,
                            tvchannel: true,
                        }}
                        type="actions"
                    />
                </Toolbar>
            ) : null}
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default TextToolbar;
