import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseInputText from "../../../../../Table/useInputText";
import { useParams } from "react-router-dom";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { getProjectLangs } from "../../../../../../hooks/Utils/SalesUtils";
import { useSalesModalVendureTranslations } from "../../modals/useSalesModalVendureTranslations";
import { TYPE_QUERY } from "../../utils/QueriesTranslations";

const InformationCard = ({ data, nameHeader, setNameHeader }) => {
    const { t } = useTranslation();
    const { idCat } = useParams();
    const { projectLangs } = useSelector((state) => state.ui);
    const [langDefault, setLangDefault] = useState("en");
    const [objectNames, setObjectNames] = useState({});
    const [onChangeName, setOnChangeName] = useState(null);
    const [updateKey, setUpdateKey] = useState(0);
    const dispatch = useDispatch();

    const { open: openModalVendureTranslations } = useSalesModalVendureTranslations();

    const editShopCategories = "edit-shop-categories-information-card";
    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (data && data.translations) {
            const obj = {};
            for (let i = 0; i < data.translations.length; i++) {
                const item = data.translations[i];
                obj[item.languageCode] = {
                    name: item.name,
                    languageCode: item.languageCode,
                    slug: item.slug,
                    id: item.id ? item.id : "",
                };
            }
            setObjectNames(obj);
        }
    }, [data]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (onChangeName) {
                handleTranslate(onChangeName);
            }
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line
    }, [onChangeName]);

    useEffect(() => {
        setUpdateKey((prev) => prev + 1);
    }, [objectNames]);

    // console.log(objectNames);

    const handleTranslate = (val) => {
        const firstLang = Object.keys(objectNames)[0];
        const firstSlug = objectNames[firstLang].slug;
        dispatch(
            setExecuteQuery({
                action: "update-name-category",
                params: {
                    idCategory: idCat,
                    translationId:
                        objectNames[langDefault] && objectNames[langDefault].id ? objectNames[langDefault].id : null,
                    languageCode: langDefault,
                    name: val,
                    slug: firstSlug,
                },
            })
        );
    };

    return (
        <div
            className=" p-6 w-full bg-white rounded shadow-sm  "
            style={{ height: "14.5rem" }}
            id={`${editShopCategories}-container`}
        >
            <div className=" text-xl font-bold mb-5">{capitalizeFirst(t("information"))}</div>
            <div className=" flex">
                <div className=" mr-4 font-bold">{t("name")}</div>
                <div
                    className=" font-bold text-zafiro-600 cursor-pointer"
                    onClick={() => {
                        openModalVendureTranslations({
                            VARIABLES: { id: idCat },
                            QUERY: TYPE_QUERY.COLLECTION,
                            title: `${capitalizeFirst(t("translate"))}`,
                        });
                    }}
                    id={`${editShopCategories}-translate-modal`}
                >
                    {t("translate")}
                </div>
            </div>
            <div className="flex items-center justify-between mt-3 mr-3 w-full">
                <div className="whitespace-nowrap">{`${t("language:" + langDefault)} (${t("default-language")})`}</div>
                <div className="flex-grow ml-3" id={`${editShopCategories}-input-text-container`}>
                    <UseInputText
                        inputData={{
                            name: `${editShopCategories}-input-text`,
                        }}
                        key={`${editShopCategories}-input-text-${updateKey}`}
                        validation={true}
                        currentValue={
                            objectNames && objectNames[langDefault] && objectNames[langDefault].name
                                ? objectNames[langDefault].name
                                : ""
                        }
                        id={`${editShopCategories}-input-text`}
                        onChangeAction={(val) => {
                            setOnChangeName(val);
                            setNameHeader(val);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default InformationCard;
