import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
//Components
import Widget from "../Widget";
import Input from "./CommonOptions/Input";
import Select from "./CommonOptions/Select";
import Toolbar from "./CommonOptions/Toolbar";
import { arrayMoveImmutable } from "array-move";
import Settings from "./CommonOptions/Settings";
import UseItemActions from "../../../../useItemActions";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import UseLoading from "../../../../Table/useLoading";
//Modals
import MenuNavNewItem from "hooks/GraphqlCalls/MediaLibrary/modals/MenuNavNewItem";
//Actions
import { setExecuteQuery } from "../../../../../actions/sectionActions";
import { changeGlobalAction } from "../../../../../actions/globalActions";
import { openModal, setModalContent, showGlobalLoading } from "../../../../../actions/uiActions";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
//Utils
import {
    getFontNameOptionSelected,
    getOptionsFontList,
    getTextSizeSelected,
    getFontSizes,
} from "hooks/Utils/Design/FontUtils";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { SortContents } from "../../../../Sidebar/ScreensSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Session } from "hooks/Utils/Session";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { SCREEN } from "hooks/Utils/Design/EditorUtils";
import { getSectionName, getScreens as GET_SCREENS } from "hooks/Utils/Design/EditorUtils";

const TVMenuNavToolbar = () => {
    const { deviceType, itemSelected, theme, editorSize, screenType } = useContext(EditorContext);
    const { id: widgetID, type } = useContext(WidgetContext);
    const { lang } = useParams();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { gridItems, widgetToolbarVisible, cScreenData, widgetMenuColorsAndAnimations } = useSelector(
        (state) => state.design
    );

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { fonts } = useSelector((state) => state.ui);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const actions = widget && widget.actions ? widget.actions : [];
    const designId = cScreenData && cScreenData.data && cScreenData.data.designID ? cScreenData.data.designID : null;
    const widgetInsideMenu = widget && widget.insideMenu === true ? true : false;
    // const orientation = style && style.orientation ? style.orientation : "vertical";

    const tabs = [
        { id: "defaultColor", name: t("default") },
        { id: "activeColor", name: t("active") },
        { id: "focusColor", name: t("focus") },
    ];

    const [items, setItems] = useState([]);
    const [fitItems, setFitItems] = useState(null);
    const [sameSizeItems, setSameSizeItems] = useState(null);
    const [zoomIn, setZoomIn] = useState(null);
    const [iconsReady, setIconsReady] = useState(false);
    const [currentStyleTab, setCurrentStyleTab] = useState(tabs[0].id);
    const [lastExecuteQuery, setLastExecuteQuery] = useState(null);
    const [getScreens, { data: screensData, loading: loadingScreens }] = useMSQuery(GET_SCREENS(designId));

    // console.log(data);

    useEffect(() => {
        library.add(fas, fab);
        getScreens();
        reloadFields();
        setIconsReady(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (widgetID === itemSelected) {
            dispatch(setWidgetToolbarVisible("style", currentStyleTab));
        } else {
            setCurrentStyleTab(tabs[0].id);
            dispatch(setWidgetToolbarVisible(null));
        }
        // eslint-disable-next-line
    }, [currentStyleTab, itemSelected]);

    useEffect(() => {
        if (
            (widgetToolbarVisible === "config-navigation-items" || widgetToolbarVisible === "style") &&
            widgetID === itemSelected
        ) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible]);

    useEffect(() => {
        if (zoomIn !== null && screenType === SCREEN.TYPE.TV) {
            updateZoomIn();
        }
        // eslint-disable-next-line
    }, [zoomIn]);

    useEffect(() => {
        if (widgetID === itemSelected) {
            reloadFields();
        }
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (data && data.items && screensData && data.items.length !== items.length) {
            setTimeout(() => {
                setItems(getItemsAndActionInfo(screensData, data.items, actions));
            }, 1000);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        const designToolbar = document.querySelector("#designToolbarContainer");
        if (itemSelected === widgetID && designToolbar) {
            designToolbar.classList.remove("z-201");
        } else if (itemSelected !== widgetID && designToolbar) {
            designToolbar.classList.add("z-201");
        }
        // eslint-disable-next-line
    }, [itemSelected]);

    useEffect(() => {
        if (lastExecuteQuery === "edit-item-widget-nav" && data && data.items) {
            setItems(getItemsAndActionInfo(screensData, data.items, actions));
        }
        // eslint-disable-next-line
    }, [lastExecuteQuery]);

    useEffect(() => {
        if (
            executeQuery &&
            executeQuery.action !== null &&
            executeQuery.action !== "updateWidgetData" &&
            executeQuery.action !== "updateWidgetActions"
        ) {
            setLastExecuteQuery(executeQuery.action);
        }
    }, [executeQuery]);

    const reloadFields = () => {
        if (data && data.items && data.items.length && screensData) {
            setItems(getItemsAndActionInfo(screensData, items, actions));
        }

        if (data && data.fitItems && data.fitItems === "true") {
            setFitItems(true);
        } else {
            setFitItems(false);
        }

        setSameSizeItems(data && data.sameSizeItems === "true");

        if ((data && data.zoomInOutAnimation === false) || (data && data.zoomInOutAnimation === true)) {
            setZoomIn(data.zoomInOutAnimation);

            const switchEl = document.querySelector("#zoom-in-switch");
            if (switchEl) {
                switchEl.checked = zoomIn;
            }
        }
    };

    const updateZoomIn = () => {
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "zoomInOutAnimation", value: zoomIn })));
    };

    const updateFitItems = (value) => {
        setFitItems(value);
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "fitItems", value: String(value) })));
    };

    const updateSameSizeItems = (value) => {
        setSameSizeItems(value);
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "sameSizeItems", value: String(value) })));
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const itemsSorted = arrayMoveImmutable(items, oldIndex, newIndex);
        const newActions = arrayMoveImmutable(actions, oldIndex, newIndex);
        const newItems = getItemsAndActionInfo(screensData, itemsSorted, newActions);
        setItems(newItems);
        dispatch(
            setExecuteQuery({
                action: "sort-actions-items-widget-nav",
                params: {
                    actionsSorted: newActions,
                    itemsSorted: newItems,
                },
            })
        );
    };

    const setVisibilityItem = (id) => {
        let itemsClone = [...items];
        const indexItem = itemsClone.findIndex((item) => item.id === id);
        itemsClone[indexItem].visibility = true;
        setItems(itemsClone);
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "items", value: itemsClone })));
        dispatch(showGlobalLoading(true));
        setTimeout(() => {
            dispatch(showGlobalLoading(false));
        }, 1000);
    };

    const getFromDAS = (libraryRef, languageRef) => {
        if (libraryRef) {
            let path = libraryRef;
            if (languageRef) {
                path += "?lang=" + languageRef;
            }
            return Session.getDasUrl(path);
        }
        return null;
    };

    const getSubtitle = (item) => {
        if (item?.actionInfo) {
            const destType = item.actionInfo.destinationType;
            const destValue = item.actionInfo.destinationValue;
            const destName = item.actionInfo.destinationName;
            switch (destType) {
                case "content":
                    return destName ? capitalizeFirst(t("screen")) + " - " + destName : "";
                case "section":
                    if (destValue === "landing") {
                        return t("landing");
                    }
                    return t("predefined section") + " - " + destName;
                case "library":
                    return t("media file");
                case "tvchannel":
                    return t("TV channel");
                case "url":
                    return t("url") + " - " + destName;
                default:
                    return destName;
            }
        }
        return "";
    };

    const openModalAddItem = () => {
        const modalCont = MenuNavNewItem(t, widgetID, { data: null }, lang);
        setTimeout(() => {
            dispatch(
                changeGlobalAction({
                    actionName: "create-item-widget-nav",
                })
            );
        }, 1000);
        dispatch(setModalContent(modalCont));
        dispatch(openModal());
    };

    const getMenuColor = (currentStyleTab) => {
        if (currentStyleTab === tabs[0].id) {
            return [
                widgetMenuColorsAndAnimations.bgColor,
                widgetMenuColorsAndAnimations.fgColor,
                widgetMenuColorsAndAnimations.iconColor,
            ];
        } else if (currentStyleTab === tabs[1].id) {
            return [
                widgetMenuColorsAndAnimations.bgActiveColor,
                widgetMenuColorsAndAnimations.fgActiveColor,
                widgetMenuColorsAndAnimations.iconActiveColor,
            ];
        } else if (currentStyleTab === tabs[2].id) {
            return [
                widgetMenuColorsAndAnimations.bgFocusColor,
                widgetMenuColorsAndAnimations.fgFocusColor,
                widgetMenuColorsAndAnimations.iconFocusColor,
            ];
        } else {
            return [null, null, null];
        }
    };

    const getItemsAndActionInfo = (screensData, items, actions) => {
        if (screensData && screensData.contentTree && screensData.contentTree.screens) {
            const screensResult = screensData.contentTree.screens;
            const screens = SortContents(screensResult.dir.contents);
            const itemsTemp = items;
            actions.forEach((action, index) => {
                if (action && action.type === "content") {
                    screens.forEach((screen) => {
                        if (Number(screen.id) === Number(action.value)) {
                            if (itemsTemp[index]) {
                                itemsTemp[index]["actionInfo"] = {
                                    destinationName: screen.name,
                                    destinationType: action.type,
                                    destinationValue: action.value,
                                };
                            }
                        }
                    });
                    if (screensResult.dir.subDirs && screensResult.dir.subDirs.length > 0) {
                        screensResult.dir.subDirs.forEach((sub) => {
                            if (sub.contents) {
                                const subscreens = SortContents(sub.contents);
                                if (subscreens instanceof Array && subscreens.length > 0) {
                                    subscreens.forEach((subScreen) => {
                                        if (Number(subScreen.id) === Number(action.value)) {
                                            if (itemsTemp[index]) {
                                                itemsTemp[index]["actionInfo"] = {
                                                    destinationName: subScreen.name,
                                                    destinationType: action.type,
                                                    destinationValue: action.value,
                                                };
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                } else if (action && action.type === "section") {
                    if (itemsTemp[index]) {
                        itemsTemp[index]["actionInfo"] = {
                            destinationName: getSectionName(action.value, t),
                            destinationType: action.type,
                            destinationValue: action.value,
                        };
                    }
                } else if (action && action.type === "library") {
                    if (itemsTemp[index]) {
                        itemsTemp[index]["actionInfo"] = {
                            destinationName: t("media file"),
                            destinationType: action.type,
                            destinationValue: action.value,
                        };
                    }
                } else if (action && action.type === "tvchannel") {
                    if (itemsTemp[index]) {
                        itemsTemp[index]["actionInfo"] = {
                            destinationName: t("TV channel"),
                            destinationType: action.type,
                            destinationValue: action.value,
                        };
                    }
                } else if (action && action.type === "url") {
                    if (itemsTemp[index]) {
                        itemsTemp[index]["actionInfo"] = {
                            destinationName: action.value,
                            destinationType: action.type,
                            destinationValue: action.value,
                        };
                    }
                } else if (action === null) {
                    if (itemsTemp[index]) {
                        itemsTemp[index]["actionInfo"] = {
                            destinationName: null,
                            destinationType: null,
                            destinationValue: action?.value,
                        };
                    }
                }
            });
            return itemsTemp;
        }
    };

    const getItemName = (item, lang) => {
        if (item && item.name && item.name[lang]) {
            return item.name[lang];
        } else if (item && item.name && !item.name[lang] && item.name["en"]) {
            return item.name["en"];
        } else if (item && item.name && item.name.text) {
            return item.name.text;
        } else if (item && item.name && !item.name[lang] && !item.name["en"]) {
            return item.name[Object.keys(item.name)[0]];
        } else {
            return "";
        }
    };

    const SortableItem = SortableElement(({ item, i }) => {
        const arrActions = [];
        if (actions) {
            arrActions[0] = actions[i];
        }

        return (
            <li
                key={item.id + item.name}
                className=" relative mt-4 flex h-auto w-full cursor-move list-none items-center bg-white"
            >
                <span
                    style={{ fontSize: "1rem" }}
                    className=" icon icon-drag-and-drop w-1/12 pr-3 text-gray-800 "
                ></span>

                <div
                    className=" border rounded relative mr-2 flex items-center justify-center border-gray-200 "
                    style={{
                        minWidth: "2.5rem",
                        minHeight: "2.5rem",
                        color: `${item.visibility === true ? "" : "#576575"}`,
                    }}
                >
                    {iconsReady && item.icon && item.lib ? (
                        <FontAwesomeIcon icon={[item.lib, item.icon]} size={"lg"} />
                    ) : (
                        <div className=" relative">
                            <img
                                alt=""
                                className=" h-full w-full object-scale-down"
                                style={{ maxHeight: "2.5rem", maxWidth: "2.5rem" }}
                                src={getFromDAS(item.libraryRef, lang)}
                            ></img>
                            {item.visibility === false ? (
                                <div
                                    className=" absolute left-0 top-0 h-full w-full"
                                    style={{ backgroundColor: "rgba(87, 101, 117 , 0.5)" }}
                                ></div>
                            ) : null}
                        </div>
                    )}
                </div>

                <div className=" truncate flex w-full flex-col items-start justify-center ">
                    <span
                        className={` ${item.visibility === true ? "text-gray-900" : " text-gray-500"} truncate w-full`}
                    >
                        {getItemName(item, lang)}
                    </span>
                    <span
                        className={` ${item.visibility === true ? "text-gray-700" : " text-gray-500"} truncate text-sm`}
                    >
                        {getSubtitle(item)}
                    </span>
                </div>
                <div className=" w-1/12" onClick={(e) => e.stopPropagation()}>
                    {item.visibility === true ? (
                        <UseItemActions
                            dataType="widget-nav"
                            containerAdjust="w-48"
                            executeSectionQuery={true}
                            id={item.id}
                            actions={[
                                {
                                    name: t("edit-item"),
                                    action: "edit-item-widget-nav",
                                    modal_title: "edit-menu-item",
                                    modal_btn_name: "save",
                                    modal_twoColumns: true,
                                    modal_bodyAdjust: "w-full",
                                    widgetID,
                                    t,
                                    data: {
                                        itemName: item.name,
                                        resourceValue: item.icon ? { icon: item.icon, lib: item.lib } : item.libraryRef,
                                        itemActions: actions ? arrActions : null,
                                        itemId: item.id ? item.id : null,
                                    },
                                    lang,
                                },
                                {
                                    name: t("duplicate"),
                                    mutation: "duplicate-item-widget-nav",
                                    executeSectionQuery: true,
                                    noModal: true,
                                    mutationParams: {
                                        itemId: item.id ? item.id : null,
                                    },
                                    hideGlobalLoading: true,
                                },
                                {
                                    name: "hide-word",
                                    mutation: "hide-visibility-item-widget-nav",
                                    executeSectionQuery: true,
                                    noModal: true,
                                    mutationParams: {
                                        itemId: item.id ? item.id : null,
                                    },
                                    hideGlobalLoading: true,
                                },
                                {
                                    name: t("delete"),
                                    action: "delete-item-widget-nav",
                                    executeSectionQuery: true,
                                    modal_title: "delete",
                                    modal_value: item.name && item.name[lang] ? item.name[lang] : "",
                                    data: {
                                        itemId: item.id ? item.id : null,
                                    },
                                    hideGlobalLoading: true,
                                },
                            ]}
                        />
                    ) : (
                        <div
                            onClick={() => setVisibilityItem(item.id)}
                            className="icon icon-eye-blocked cursor-pointer text-gray-500 "
                            style={{ fontSize: "1rem" }}
                        ></div>
                    )}
                </div>
            </li>
        );
    });

    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul
                className="grid grid-cols-1 gap-2"
                style={{ paddingBottom: items && items.length >= 1 ? "4.5rem" : "" }}
            >
                {items && items.length > 0
                    ? items.map((item, index) => (
                          <SortableItem key={`${item.id}`} index={index} sortIndex={index} item={item} i={index} />
                      ))
                    : null}
            </ul>
        );
    });

    const renderOptionToolbar = ({ icon = "icon-settings-editor", toolbarVisibleTitle = "" }) => {
        return (
            <div
                className={`icon ${icon} cursor-pointer p-2 ${
                    widgetToolbarVisible === toolbarVisibleTitle ? "bg-gray-200" : ""
                }`}
                onClick={(e) =>
                    dispatch(
                        setWidgetToolbarVisible(
                            widgetToolbarVisible === toolbarVisibleTitle ? null : toolbarVisibleTitle
                        )
                    )
                }
            ></div>
        );
    };

    const renderNavigationItemsConfig = () => {
        return widgetToolbarVisible === "config-navigation-items" ? (
            <div
                id={`widgetOptionContainer_${widgetID}`}
                className={`widgetOptionContainer invisible p-0`}
                style={{ minWidth: "29rem", maxWidth: "29rem" }}
            >
                <Settings>
                    <Settings.Group title={capitalizeFirst(t("manage-navigation-items"))}>
                        {loadingScreens ? (
                            <div className=" flex h-full w-full items-center justify-center">
                                <UseLoading adjust="contents" />
                            </div>
                        ) : (
                            <>
                                <span>{capitalizeFirst(t("order-edit-navigation-items"))}</span>
                                <SortableList items={items} onSortEnd={onSortEnd} axis={"y"} distance={1} />
                            </>
                        )}
                    </Settings.Group>
                </Settings>
                <div className=" border-t sticky bottom-0 flex w-full flex-col border-gray-300 bg-white py-4 ">
                    <div
                        className=" text-zafiro-600 flex cursor-pointer items-center justify-center font-bold"
                        onClick={() => {
                            openModalAddItem();
                        }}
                    >
                        <span className=" icon icon-add pr-2 " style={{ fontSize: "1rem", fontWeight: "bold" }}></span>
                        {capitalizeFirst(t("add-menu-item"))}
                    </div>
                </div>
            </div>
        ) : null;
    };

    return (
        <>
            {renderOptionToolbar({ icon: "icon-widget-navigation", toolbarVisibleTitle: "config-navigation-items" })}
            {renderNavigationItemsConfig()}
            <Toolbar type="layout">
                <Settings>
                    <Settings.Group title={t("item-options")}>
                        <Input.Orientation id="orientation" title={t("orientation")} />
                        <Input.Number id="itemsMargin" title={t("spacing")} />
                        <Input.Alignment title="alignment" id="alignment" deviceSpecific={true} />
                        <div className="first-capital mt-6 flex w-full   cursor-pointer">
                            <div className=" first-capital inline-block  w-6/12 text-gray-900 ">
                                {t("fit-items-to-container")}
                            </div>
                            <div className=" w-6/12 ">
                                <label className="switch cursor-pointer ">
                                    <input
                                        type="checkbox"
                                        checked={fitItems}
                                        onChange={() => updateFitItems(!fitItems)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>

                        <div className="first-capital mt-6 flex w-full   cursor-pointer">
                            <div
                                className={`${
                                    fitItems ? "disabled" : "text-gray-900"
                                } first-capital  inline-block w-6/12`}
                            >
                                {t("same-size-items")}
                            </div>
                            <div className=" w-6/12 ">
                                <label className="switch cursor-pointer ">
                                    <input
                                        type="checkbox"
                                        checked={sameSizeItems}
                                        disabled={fitItems}
                                        onChange={() => updateSameSizeItems(!sameSizeItems)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>

                        {sameSizeItems && !fitItems ? (
                            <div className=" my-4 w-full">
                                <Input.Number
                                    id="itemsSize"
                                    dataType="data"
                                    title={t("size")}
                                    titleWidth="pl-6 w-9/12"
                                />
                            </div>
                        ) : null}
                    </Settings.Group>
                    <Settings.Group title={t("text-options")}>
                        <div className="flex w-full justify-between">
                            {theme && (
                                <div className=" mr-2 w-5/12">
                                    <Select
                                        title={t("styletype")}
                                        id="styletype"
                                        adjust="w-full mr-2"
                                        data={{
                                            selected: style && style.styletype ? style.styletype : "paragraph",
                                            options: [
                                                { value: "header", text: t("heading") },
                                                { value: "paragraph", text: t("paragraph") },
                                            ],
                                        }}
                                    />
                                </div>
                            )}
                            <div className=" mr-2 w-5/12">
                                <Select
                                    title={t("font")}
                                    id="fontName"
                                    adjust="w-full mr-2"
                                    data={{
                                        selected: getFontNameOptionSelected(
                                            theme,
                                            fonts,
                                            deviceType,
                                            widget,
                                            Widget.ImplementNewLibrary(type)
                                        ),
                                        options: getOptionsFontList(fonts.fontList),
                                    }}
                                />
                            </div>
                            <div className=" w-2/12">
                                <Select
                                    title={t("total size")}
                                    id="textSize"
                                    adjust="w-full"
                                    data={{
                                        selected: getTextSizeSelected(theme, deviceType, widget),
                                        options: getFontSizes(editorSize.width, deviceType),
                                    }}
                                />
                            </div>
                        </div>
                        <Input.Check
                            icon="icon-bold"
                            dataType="style"
                            id="bold"
                            dafultValue={style && style.bold ? style.bold : theme.paragraphStyle.bold}
                        />
                        <Input.Check
                            icon="icon-italic"
                            dataType="style"
                            id="italic"
                            dafultValue={style && style.italic ? style.italic : theme.paragraphStyle.italic}
                        />
                    </Settings.Group>
                    <Settings.Group title={t("icon options")}>
                        <div className=" mb-4">
                            <Input.Number id="iconSize" title={t("total size")} />
                        </div>

                        <div className=" mb-4">
                            <Input.Alignment id="iconPosition" type="around" title={t("position")} />
                        </div>

                        <div className=" mb-4">
                            <Input.Number id="iconMargin" title={t("spacing")} />
                        </div>
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                {tabs && tabs.length > 1 ? (
                    <Toolbar.Tabs
                        data={tabs}
                        currentTab={currentStyleTab}
                        setCurrentTab={(tab) => {
                            setCurrentStyleTab(tab);
                        }}
                    />
                ) : null}
                {tabs && tabs.length > 0
                    ? tabs.map((tab) => {
                          return (
                              <Settings key={tab.id + "-tab-content"} visible={tab.id === currentStyleTab}>
                                  <Settings.Group type="colors" title={capitalizeFirst(t("items-colors"))}>
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "bgColor"
                                                  : tab.id === "activeColor"
                                                  ? "bgActiveColor"
                                                  : "focusBgColor"
                                          }
                                          defaultColor={
                                              widgetInsideMenu && widgetMenuColorsAndAnimations
                                                  ? getMenuColor(currentStyleTab)[0]
                                                  : null
                                          }
                                          title={t("item-background")}
                                      />
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "fgColor"
                                                  : tab.id === "activeColor"
                                                  ? "fgActiveColor"
                                                  : "fgFocusColor"
                                          }
                                          defaultColor={
                                              widgetInsideMenu && widgetMenuColorsAndAnimations
                                                  ? getMenuColor(currentStyleTab)[1]
                                                  : null
                                          }
                                          title={t("text")}
                                      />
                                      <Input.Color
                                          id={
                                              tab.id === "defaultColor"
                                                  ? "iconColor"
                                                  : tab.id === "activeColor"
                                                  ? "iconActiveColor"
                                                  : "iconFocusColor"
                                          }
                                          defaultColor={
                                              widgetInsideMenu && widgetMenuColorsAndAnimations
                                                  ? getMenuColor(currentStyleTab)[2]
                                                  : null
                                          }
                                          title={t("icons")}
                                      />
                                  </Settings.Group>
                                  {tab.id === "defaultColor" ? (
                                      <Settings.Border t={t} title="item-corners" onlyRadius={true} />
                                  ) : null}
                                  {tab.id === "focusColor" ? (
                                      <Settings.Group title="animation">
                                          <div className=" flex items-center">
                                              <span>{capitalizeFirst(t("zoom-in-and-out"))}</span>
                                              <label className="switch ml-auto cursor-pointer">
                                                  <input
                                                      type="checkbox"
                                                      id="zoom-in-switch"
                                                      checked={null}
                                                      onChange={(e) => {
                                                          setZoomIn(e.currentTarget.checked);
                                                      }}
                                                  ></input>
                                                  <span className="slider round"></span>
                                              </label>
                                          </div>
                                      </Settings.Group>
                                  ) : null}
                              </Settings>
                          );
                      })
                    : null}
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="delete" />
        </>
    );
};

export default TVMenuNavToolbar;
