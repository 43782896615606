import React, { useEffect, useContext, useState, useLayoutEffect } from "react";
import UseSwitch from "../../../Inputs/useSwitch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate as useHistory, useParams } from "react-router-dom";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import UpdateShop from "../../../../hooks/GraphqlCalls/Sales/UpdateShop";
import { setExecuteQuery, setShopToken } from "../../../../actions/sectionActions";
import ReactTooltip from "components/ReactTooltip";
import { Session } from "../../../../hooks/Utils/Session";
import { useLocation } from "react-router-dom";
import { ShopContext } from "contexts/Sales/Shop";
import { GlobalContext } from "contexts/Global";
import { useSalesModalZafiroTranslations } from "./modals/useSalesModalZafiroTranslations";
import { TYPE_QUERY } from "./utils/QueriesTranslations";

const ShopHeadMenu = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { name, token, isActive } = useContext(ShopContext);
    const { open: openSalesModalTranslations } = useSalesModalZafiroTranslations();

    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();
    const { impersonated } = useSelector((state) => state.auth);
    const { warningsMinimized } = useSelector((state) => state.ui);
    const { searchHistory, warnings: uiWarnings } = useContext(GlobalContext);
    const [warningsHeight, setWarningsHeight] = useState(0);

    const regex = /\/sales\/(shop\/\d+\/product\/\d+|shop\/categories\/\d+\/edit-category\/\d+)/i;
    const showAvailableToggle = !regex.test(location?.pathname);

    const managerLink1 = document.querySelector("#WIFIMANAGER_LINK");
    const managerLink2 = document.querySelector("#ZAFIROTV_LINK");
    const sectionContent = document.querySelector("#sectionContent");

    useEffect(() => {
        // TODO revisar que m* es esto
        if (Session.getSessionProp("idShop") !== "null" && Session.getSessionProp("idShop") !== id) {
            if (id) {
                history("/services/sales/shop/products/" + id);
            } else {
                history("/services/sales/shop");
            }
        }
    }, [id]);

    useLayoutEffect(() => {
        if (managerLink1) {
            managerLink1.style.display = "none";
        }
        if (managerLink2) {
            managerLink2.style.display = "none";
        }
        return () => {
            if (managerLink1) {
                managerLink1.style.display = null;
            }
            if (managerLink2) {
                managerLink2.style.display = null;
            }
        };
        // eslint-disable-next-line
    }, [managerLink1, managerLink2]);

    useLayoutEffect(() => {
        if (sectionContent) {
            sectionContent.style.position = "absolute";
            sectionContent.style.left = "13.7rem";
            sectionContent.style.width = "calc(100% - 13.7rem)";
            sectionContent.style.marginTop = calculateMarginTop({ impersonated, uiWarnings, warningsMinimized });
        }
        return () => {
            if (sectionContent) {
                sectionContent.style.paddingLeft = null;
                sectionContent.style.position = null;
                sectionContent.style.left = null;
                sectionContent.style.width = null;
                sectionContent.style.marginTop = null;
            }
        };
        // eslint-disable-next-line
    }, [sectionContent, warningsMinimized]);

    useEffect(() => {
        const mainSideBarContainer = document.querySelector("#main_sidebar_nav_container");
        const mainSideBar = document.querySelector("#main_sidebar_nav");
        mainSideBarContainer.style.width = "13.7rem";
        mainSideBar.style.marginTop = "-1rem";
        return () => {
            mainSideBarContainer.style.width = null;
            mainSideBar.style.marginTop = null;
            dispatch(setShopToken(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const h = calculateTopStyle({ impersonated, uiWarnings, warningsMinimized });
        setWarningsHeight(h);
    }, [impersonated, uiWarnings, warningsMinimized]);

    return (
        <>
            <div className=" fixed left-0 flex w-full h-16 bg-white " style={{ top: warningsHeight }}>
                <div
                    id="sales-back-to-shop-list"
                    className=" absolute border-r top-0 left-0  flex h-full items-center text-gray-800 hover:text-zafiro-400 mt justify-center border-b  cursor-pointer "
                    style={{ width: "13.7rem" }}
                    onClick={() => {
                        const shopsList = "/services/sales/shop";
                        history(searchHistory({ lastPath: shopsList }) || shopsList);
                        Session.setSessionProp("idShop", null);
                    }}
                >
                    <span className=" icon icon-chevron-left mr-3 mt-1 text-lg"></span>
                    <span className=" text-lg ">{t("back-to-shop-list")}</span>
                </div>
                <div className=" flex w-full pl-64 h-full items-center justify-between px-4 border ">
                    {token ? (
                        <>
                            <div className=" flex items-center text-gray-800 justify-center">
                                <div
                                    id="sales-name-shop"
                                    className=" font-bold mr-4 text-lg truncate"
                                    style={{ maxWidth: "32rem" }}
                                    data-for="sales-title-tooltip"
                                    data-tip
                                >
                                    {name}
                                </div>
                                {name?.length > 60 ? (
                                    <ReactTooltip id="sales-title-tooltip">{name} </ReactTooltip>
                                ) : null}
                                <div
                                    id="edit-shop-name"
                                    className=" icon icon-edit text-3xl cursor-pointer "
                                    onClick={() => {
                                        openSalesModalTranslations({
                                            VARIABLES: { id: id, token: token },
                                            QUERY: TYPE_QUERY.SHOP,
                                            title: "edit-name",
                                        });
                                    }}
                                ></div>
                            </div>
                            {showAvailableToggle && (
                                <div className={`flex items-center mr-2`}>
                                    <div className=" text-lg">{capitalizeFirst(t("available"))}</div>
                                    <UseSwitch
                                        id={id}
                                        checked={isActive}
                                        action={() => {
                                            dispatch(
                                                setExecuteQuery({
                                                    action: "set-state-shop",
                                                    params: {
                                                        active: !isActive,
                                                        id: id,
                                                    },
                                                })
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
            </div>
            <UpdateShop />
        </>
    );
};

function calculateTopStyle({ impersonated, uiWarnings, warningsMinimized }) {
    if (impersonated && !uiWarnings) {
        return "3rem";
    } else if (uiWarnings && !impersonated) {
        return warningsMinimized ? "2rem" : "5rem";
    } else if (uiWarnings && impersonated) {
        return warningsMinimized ? "5rem" : "8rem";
    }
    return "0rem";
}

function calculateMarginTop({ impersonated, uiWarnings, warningsMinimized }) {
    if (impersonated && !uiWarnings) {
        return "2rem";
    } else if (uiWarnings && !impersonated) {
        return warningsMinimized ? "1rem" : "4rem";
    } else if (uiWarnings && impersonated) {
        return warningsMinimized ? "4rem" : "7rem";
    }
    return "-1rem";
}

export default ShopHeadMenu;
