import React from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import UseSectionHeader from "../../useSectionHeader";
import ListHotspot from "../../../hooks/GraphqlCalls/Hotel/ListHotspots";
import UseCard from "../../useCard";
import UseButton from "../../useButton";
import UpdateHotspot from "../../../hooks/GraphqlCalls/Hotel/UpdateHotspot";
import { useTranslation } from "react-i18next";
import { Session } from "../../../hooks/Utils/Session";
import NewHotspot from "../../../hooks/GraphqlCalls/Settings/modals/NewHotspot";
import { openModal, setModalContent } from "../../../actions/uiActions";

const PortalManagerWifi = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { dashboardCards } = useSelector((state) => state.ui);
    const isSuperUser = Session.getSessionProp("superUser");
    ListHotspot();

    const handleAddHotspot = () => {
        const modal = NewHotspot(null, "add-hotspot", null, null, t)[0];
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <>
            <UseSectionHeader title={["manage-wifi-portal"]} navToSection="/hotel/property-settings/wifi" />
            <UpdateHotspot />
            <div className="w-full">
                <UseCard
                    title="access-profile"
                    description="setup-portal-access"
                    adjust="mb-5"
                    buttons={[
                        {
                            name: "manage-access-profile",
                            link: "/hotel/property-settings/wifi/access-profile",
                            buttonColor: "btn-blue",
                            cStyle: "w-auto px-4",
                        },
                    ]}
                />
            </div>
            {dashboardCards ? (
                <>
                    <div className="w-full mb-4 flex items-center">
                        <span className="font-bold text-gray-900 text-2xl">{t("hotspots")}</span>
                        {isSuperUser ? (
                            <div className=" ml-4">
                                <UseButton
                                    buttonName="add-hotspot"
                                    icon="superuser text-3xl pl-1"
                                    adjust="btn-blue-outline text-zafiro-800 font-bold"
                                    action={() => handleAddHotspot()}
                                    disabled={dashboardCards.length >= 1}
                                />
                            </div>
                        ) : null}
                    </div>

                    {dashboardCards.map((dashboardCard, index) => (
                        <div className="w-full">
                            <UseCard type="hotspot-card" adjust="mb-5" data={dashboardCard} />
                        </div>
                    ))}
                </>
            ) : null}
        </>
    );
};
export default PortalManagerWifi;
