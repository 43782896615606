import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";

//ACTIONS
import { cleanDashboardCards, setDashboardCards, showGlobalLoading } from "../../../actions/uiActions";
import {
    setLoading,
    cleanTableStore,
    setSearchable,
    setHeaders,
    setTableType,
    setRefreshData,
    setTableResults,
    setCheckable,
    setHeaderButtons,
    setActions,
    setCustomConfig,
    setCountPosition,
    setCount,
    setPage,
} from "../../../actions/tableActions";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetDesignTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";

//UTILS
import { toast } from "react-toastify";
import { capitalizeFirst } from "../../Utils/Utils";
import { Session } from "../../Utils/Session";

//Models
import Hotspot from "./models/Hotspot";
import { cleanAction } from "../../../actions/globalActions";
import HotspotCard from "./models/HotspotCard";

const ListHotspot = () => {
    const { currentPage, perPage, sortCriteria, refreshData, filterQuery } = useSelector((state) => state.table);
    const { langStrings } = useSelector((state) => state.ui);
    const search_match = filterQuery.match(/search\s?:\s?"([^"]*)/);
    const searchValue = search_match ? search_match[1] : "";
    const isSuperUser = Session.getSessionProp("superUser");
    const searchQuery = searchValue ? `, filter:{search:"${searchValue}"}` : "";
    const [sorting] = useState(`,orderBy:{field:"name" , criteria:"asc"}`);
    const finalSortCriteria = sortCriteria
        ? sortCriteria.includes("macExceptions")
            ? sorting
            : sortCriteria
        : sorting;

    //DATA
    const sectionName = "list-hotspots";

    //ACTIONS
    const dispatch = useDispatch();

    //API CALLS
    const GET_HOTSPOTS = gql`
        {
            hotspots(page: ${currentPage} , size: ${perPage} ${finalSortCriteria} ${searchQuery} ) {
                info {
                    count
                    page
                    size
                }
                results {
                    name
                    link
                }
            }
            macExceptions {
                info {
                  count
                  page
                  size
                }
                results {
                  access
                  description
                  hotspot
                  mac
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_HOTSPOTS, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError(err) {
            console.log(err);
            toast.error(capitalizeFirst(langStrings["mutation-error"]));
        },
        // eslint-disable-next-line
    });

    //LISTENERS
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanAction());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
            dispatch(cleanDashboardCards());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setPage(Number(0)));
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (
            (data && sortCriteria === `,orderBy:{field:"name", criteria:"asc"}`) ||
            (data && sortCriteria === `,orderBy:{field:"link", criteria:"asc"}`) ||
            (data && sortCriteria === "")
        ) {
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, sortCriteria]);

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch(setLoading(false));
            const tableHeaders = GetDesignTableHeaders(sectionName);
            const dataItems = arrangeData(data);
            const dashboardCards = arrangeData(data, true);
            dispatch(setDashboardCards(dashboardCards));
            dispatch(showGlobalLoading(false));
            dispatch(setTableType(sectionName));
            dispatch(setSearchable(true));
            dispatch(setCheckable(false));
            dispatch(setHeaders(tableHeaders));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.hotspots.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(
                setCustomConfig({
                    pagination: true,
                })
            );
            if (isSuperUser) {
                dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            }
            dispatch(setActions());
        }
        // eslint-disable-next-line
    }, [data, sortCriteria]);

    //FUNCTIONS
    const arrangeData = (data, useCard) => {
        const listHotspot = [];
        if (data && data.hotspots && data.macExceptions && data.hotspots.results && data.macExceptions.results) {
            data.hotspots.results.forEach((hotspot) => {
                const macsExceptionsInfo = [];
                data.macExceptions.results.forEach((mac) => {
                    if (mac.hotspot === hotspot.name) {
                        macsExceptionsInfo.push(mac);
                    }
                });
                listHotspot.push(
                    useCard ? HotspotCard(hotspot, macsExceptionsInfo) : Hotspot(hotspot, macsExceptionsInfo)
                );
            });
        }

        return listHotspot;
    };
};

export default ListHotspot;
