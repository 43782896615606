import React, { useEffect, useState, useRef } from "react";
import UseSearch from "../useSearch";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst, checkShowTooltip, getFromDAS, uncapitalizeFirst } from "../../hooks/Utils/Utils";
import { getTagInfo } from "../../hooks/Utils/Services/ChannelsUtils";
import { useTranslation } from "react-i18next";
import UseSelect from "../Inputs/useSelect";
import UseLoading from "../Table/useLoading";
import { useDispatch } from "react-redux";
import { changeActionValues, setActionName } from "../../actions/globalActions";
import NewChannel from "../../hooks/GraphqlCalls/Services/modals/NewChannel";
import { setModalContent } from "../../actions/uiActions";
import { Session } from "../../hooks/Utils/Session";

const UseModalAddChannelToGroup = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dataTunes, setDataTunes] = useState([]);
    const [showTooltipName, setShowTooltipName] = useState(false);
    const [imageLoadError, setImageLoadError] = useState({});
    const [itemsSelected, setItemsSelected] = useState([]);
    const [count, setCount] = useState(0);
    const [activeFilters, setActiveFilters] = useState({ type: "", tune: "", search: "" });
    const renderWithData = useRef(false);
    const [totalTunesNumber, setTotalTunesNumber] = useState(null);

    const prefix = "modal-add-channel-to-group";
    const GET_TUNES = gql`
        query {
            tunes${buildFilterText(activeFilters)} {
                info {
                    count
                }
                results {
                    id
                    name
                    logoRef
                    type
                    tuneInfo
                    isRadio
                }
            }
            tunesInGroup(tuneGroupID: ${Session.getSessionProp("groupChannelId")} ) {
                results {
                    id
                }
            }
        }
    `;

    const [getTunesSummary, { data, loading }] = useLazyQuery(GET_TUNES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getTunesSummary();
    }, []);

    useEffect(() => {
        if (data?.tunes?.results && data?.tunesInGroup?.results) {
            const currentTunesInGroup = new Set(data.tunesInGroup.results.map((item) => item.id));
            const allTunes = data.tunes.results;

            const finalData = allTunes.filter((item) => !currentTunesInGroup.has(item.id));
            if (totalTunesNumber === null) {
                setTotalTunesNumber(finalData.length);
            }
            setDataTunes(finalData);
            setCount(finalData.length);
            renderWithData.current = true;
        }
    }, [data]);

    useEffect(() => {
        dispatch(
            changeActionValues({
                ["channels-to-add-group"]: itemsSelected,
            })
        );
    }, [itemsSelected]);

    const handleSelectAll = () => {
        const allIds = dataTunes.map((c) => c.id);
        setItemsSelected(allIds);
    };

    const handleUnselectAll = () => {
        setItemsSelected([]);
    };

    const handleItemClick = (item) => {
        const isAlreadySelected = itemsSelected.includes(item.id);

        if (isAlreadySelected) {
            setItemsSelected(itemsSelected.filter((id) => id !== item.id));
        } else {
            setItemsSelected([...itemsSelected, item.id]);
        }
    };

    return (
        <div className=" relative" id={`${prefix}-container`}>
            <button
                id={`${prefix}-add-channel-button`}
                onClick={() => {
                    dispatch(setActionName("add-channel"));
                    dispatch(setModalContent(NewChannel("close-and-open-add-channel-to-group")));
                }}
                className=" focus:outline-none w-auto absolute right-0 btn-blue text-white font-bold  "
                style={{ top: "-5.8rem" }}
            >
                {t("create-channel")}
            </button>
            <div className=" w-full flex items-center">
                <div style={{ width: "22%" }} className=" mr-8">
                    <UseSearch
                        id={`${prefix}-search-channel`}
                        value={activeFilters.search}
                        onChange={(val) => {
                            setActiveFilters({ ...activeFilters, search: val ? val : "" });
                        }}
                    />
                </div>
                <div style={{ width: "78%" }} className=" flex items-center">
                    <span className=" mr-4">{`${capitalizeFirst(t("filter-by"))}:`}</span>
                    <div className=" mr-4" style={{ width: "25%" }}>
                        <UseSelect
                            selectData={{
                                id: `${prefix}-filter-type-select`,
                                name: "filter-type-select",
                                placeHolder: t("type"),
                                data: [
                                    { text: t("all"), value: "" },
                                    { text: t("tv"), value: "false" },
                                    { text: t("radio"), value: "true" },
                                ],
                                noTranslateAV: true,
                                containerOptionsAdjust: "overflow-y-auto max-h-20",
                                handleChange: (val) => {
                                    setActiveFilters({ ...activeFilters, type: val ? val : "" });
                                },
                            }}
                        />
                    </div>
                    {/* <div style={{ width: "25%" }}>
                        <UseSelect
                            selectData={{
                                id: `${prefix}-filter-tune-select`,
                                name: "filter-tune-select",
                                placeHolder: t("tune"),
                                data: [
                                    { text: t("all"), value: "" },
                                    { text: t("UDP"), value: "UDP" },
                                    { text: t("HLS"), value: "HLS" },
                                    { text: t("untuned"), value: "Untuned" },
                                ],
                                noTranslateAV: true,
                                containerOptionsAdjust: "overflow-y-auto max-h-20",
                                handleChange: (val) => {
                                    setActiveFilters({ ...activeFilters, tune: val ? val : "" });
                                },
                            }}
                        />
                    </div> */}
                </div>
            </div>
            <div className=" mt-6 mb-4 flex items-center justify-between">
                <button
                    id={`${prefix}-select-all-channels-button`}
                    onClick={() => {
                        if (itemsSelected.length === totalTunesNumber) {
                            handleUnselectAll();
                        } else {
                            handleSelectAll();
                        }
                    }}
                    className=" focus:outline-none text-zafiro-600 font-bold "
                >
                    {itemsSelected.length === totalTunesNumber
                        ? capitalizeFirst(t("unselect-all"))
                        : capitalizeFirst(t("select-all"))}
                </button>
                <span>{`${count} ${uncapitalizeFirst(t("channels_"))}`}</span>
            </div>
            <div
                id={`${prefix}-grid-container`}
                className=" relative p-1"
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(7.5rem, 1fr))",
                    gap: "1.7rem",
                    minHeight: "15rem",
                    maxHeight: "20rem",
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >
                {dataTunes.length <= 0 && !loading ? (
                    <div
                        id={`${prefix}-no-channels-text`}
                        className=" absolute top-0 left-0 w-full flex justify-center"
                    >
                        {t("not-channels-yet")}
                    </div>
                ) : null}
                {dataTunes.map((c, index) => {
                    let untuned = false;
                    if (c?.tuneInfo) {
                        try {
                            const tuneInfo = JSON.parse(c.tuneInfo);
                            untuned = !(
                                (tuneInfo.hls && tuneInfo.hls.isEnabled) ||
                                (tuneInfo.udp && tuneInfo.udp.isEnabled)
                            );
                        } catch (e) {
                            untuned = true;
                        }
                    } else {
                        untuned = true;
                    }

                    let tooltipName = "";

                    if (showTooltipName && c && c.name) {
                        tooltipName = c.name;
                    } else if (untuned) {
                        tooltipName = t("untuned-channel");
                    }
                    return (
                        <div
                            id={`${prefix}-channel-card-${c?.id}`}
                            onClick={() => handleItemClick(c)}
                            className="relative rounded w-full bg-gray-900"
                            style={{
                                height: "7.5rem",
                                outline: itemsSelected.includes(c.id) ? "4px solid #2286EF" : "4px solid transparent",
                            }}
                            key={c?.id}
                        >
                            {untuned ? (
                                <div
                                    data-tip={t("untuned-channel")}
                                    data-for="modal-add-channel-to-group-tooltip"
                                    onMouseOver={() => {
                                        ReactTooltip.rebuild();
                                    }}
                                    className="absolute rounded top-0 left-0 bg-gray-100 h-full w-full z-10"
                                    style={{ opacity: 0.6 }}
                                ></div>
                            ) : null}
                            <div style={{ height: "5.5rem" }} className="rounded flex items-center justify-center">
                                {imageLoadError[c.id] || !c?.logoRef ? (
                                    <span
                                        id={`${prefix}-channel-card-no-image-${c?.id}`}
                                        className="icon icon-channel text-4xl text-gray-800"
                                    ></span>
                                ) : (
                                    <img
                                        id={`${prefix}-channel-card-image-${c?.id}`}
                                        className="h-full w-full object-scale-down"
                                        src={getFromDAS(c.logoRef)}
                                        alt="something"
                                        onError={() => setImageLoadError({ ...imageLoadError, [c.id]: true })}
                                    />
                                )}
                            </div>
                            <div
                                id={`${prefix}-channel-card-tag-${c?.id}`}
                                className={`absolute px-2 rounded text-sm ${getTagInfo(c).color}`}
                                style={{ right: "0.25rem", top: "0.25rem" }}
                            >
                                {capitalizeFirst(t(getTagInfo(c).text))}
                            </div>

                            <div className="py-1 px-2 text-white absolute w-full bottom-0 left-0 bg-gray-800 rounded">
                                <div
                                    id={`${prefix}-channel-card-name-${c?.id}`}
                                    className="w-full absolute top-0 left-0 py-1 px-2 truncate z-20"
                                    onMouseOver={(e) => {
                                        checkShowTooltip(e.currentTarget, setShowTooltipName);
                                        ReactTooltip.rebuild();
                                    }}
                                    data-for="modal-add-channel-to-group-tooltip"
                                    data-tip={tooltipName}
                                >
                                    {c?.name}
                                </div>
                                <span className="opacity-0 truncate">{c?.name}</span>
                            </div>
                        </div>
                    );
                })}

                {loading && !renderWithData.current ? <UseLoading /> : null}
                <ReactTooltip
                    type={"light"}
                    id="modal-add-channel-to-group-tooltip"
                    borderColor={"#D3DAE1"}
                    border={true}
                    multiline={true}
                    place="bottom"
                ></ReactTooltip>
            </div>
        </div>
    );
};

export default UseModalAddChannelToGroup;

const buildFilterText = (activeFilters) => {
    const { type, tune, search } = activeFilters;
    let filterParts = [];
    if (type !== "") {
        filterParts.push(`isRadio: ${type}`);
    }
    if (tune !== "") {
        filterParts.push(`tuneType: "${tune}"`);
    }
    if (search !== "") {
        filterParts.push(`search: "${search}"`);
    }
    if (filterParts.length > 0) {
        return `(,filter:{${filterParts.join(", ")}})`;
    } else {
        return "";
    }
};
