import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";

const Customer = () => {
    return (
        <>
            {/* <UseSectionHeader
                title={["client"]}
                buttonName={["add", "client"]}
                buttonColor="btn-blue"
                action={"function"}
                value={"demo"}
            /> */}
        </>
    );
};

export default Customer;
