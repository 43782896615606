import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import { setRefreshContentData } from "actions/sectionActions";
import { setRefreshData } from "actions/tableActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const UPDATE_CATEGORY_MUTATION = `mutation UpdateCategory($id: ID!, $enabled: Boolean!){
    updateCollection( input:{ id:$id, customFields:{ enabled: $enabled }})
    {
      id
    }
  }`;

const useMutationUpdateProduct = (onSuccess, onError) => {
    const mutate = async (variables) => {
        try {
            const response = await executeVendureQuery(getVendureApiData(), {
                queryBody: UPDATE_CATEGORY_MUTATION,
                variables,
            });

            if (response?.error) {
                if (onError) onError(response.error);
            } else {
                if (onSuccess) onSuccess(response.data);
            }
        } catch (error) {
            if (onError) onError(error.message);
        }
    };

    return { mutate };
};

const ModalModifyCategoryState = ({ name, available, id, close: closeModal }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const { mutate } = useMutationUpdateProduct(
        (data) => handleMutationCompletion(data),
        (error) => handleMutationCompletion(error, true)
    );

    const handleMutationCompletion = (result, isError = false) => {
        const message = isError ? result : t("operation-successful");
        isError ? toast.error(message) : toast.success(message);
        closeModal();
        dispatch(setRefreshContentData(true));
        dispatch(setRefreshData(true));
    };

    return (
        <Modal
            title={`${name} - ${
                available ? capitalizeFirst(t("set-as-unavailable")) : capitalizeFirst(t("set-as-available"))
            }`}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={() => closeModal()}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-save"
                            onClick={() => {
                                mutate({ id, enabled: !available });
                                setLoading(true);
                            }}
                        >
                            {capitalizeFirst(t("save"))}
                        </Button>
                    </>
                ) : null
            }
            className={" w-5/12 p-10"}
        >
            {!loading ? (
                <div className=" py-4">
                    {available ? t("confirm-set-as-unavailable-category") : t("confirm-set-as-available-category")}
                </div>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

const useModalModifyCategoryState = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalModifyCategoryState {...newProps} />);
        },
    };
};

export default useModalModifyCategoryState;
