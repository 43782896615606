import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/";
//Utils
import ListGuests from "../../../../hooks/GraphqlCalls/Customers/ListGuests";
import UpdateBooking from "hooks/GraphqlCalls/Booking/UpdateBooking";
import UseButton from "components/useButton";
import { useDispatch, useSelector } from "react-redux";
import { openModal, setModalContent } from "actions/uiActions";
import { ExportGuestRegistrationForm } from "hooks/GraphqlCalls/Guest/modals/ExportGuestRegistrationForm";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { Session } from "hooks/Utils/Session";
import DropdownButton from "components/DropdownButton";
import Icon from "components/Icon";
import useModalBulkCheckin from "../Precheckin/ReservationDetails/modals/useModalBulkCheckin";
import { useNavigate } from "react-router-dom";

const Guests = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasPreCheckin = permissions?.guests?.preCheckin;
    ListGuests();

    return (
        <>
            <UpdateBooking></UpdateBooking>
            <div className="flex justify-between">
                <UseSectionHeader title={"hotel-guests"} />
                {hasPreCheckin && (
                    <div className="flex items-end pb-5">
                        <UseButton
                            adjust={"p-4"}
                            buttonColor={"btn-blue"}
                            buttonName={"export-guest-registration-form"}
                            action={() => {
                                dispatch(setModalContent(ExportGuestRegistrationForm(t)));
                                dispatch(openModal());
                            }}
                        ></UseButton>
                    </div>
                )}
            </div>
            <div className=" relative">
                <GuestActions />
                <UseTable />
            </div>
        </>
    );
};

export default Guests;

const GuestActions = () => {
    const { folder } = useSelector((state) => state.table);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const sectionName = `list-guests-${folder?.name}`;
    const isSuperUser = Session.getSessionProp("superUser");

    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};

    if (sectionName === "list-guests-currents" && !currentHotel.blockGuestManagement) {
        return (
            <div
                style={{
                    position: "absolute",
                    right: "1.8rem",
                    top: "1.8rem",
                    zIndex: 10,
                    height: isSuperUser ? "4rem" : "auto",
                    width: isSuperUser ? "12rem" : "auto",
                }}
            >
                {!isSuperUser ? (
                    <Button
                        id="new-guest-button"
                        design="blue"
                        onClick={() => navigate(`/customers/guests/guest-list/check-in`)}
                    >
                        {t("Check-in")}
                    </Button>
                ) : (
                    <DropdownButton
                        id="check-in-dropdown"
                        handlerPadding="px-4 py-2"
                        label={{
                            id: "new-guest-button",
                            label: t("Check-in"),
                            onClick: () => navigate(`/customers/guests/guest-list/check-in`),
                        }}
                        options={[<BulkCheckInOption key="bulk-check-in" />]}
                    />
                )}
            </div>
        );
    }

    return null;
};

const BulkCheckInOption = () => {
    const { t } = useTranslation();
    const { open: openModalBulkCheckin } = useModalBulkCheckin();

    return (
        <Button
            id="bulk-check-in-option"
            onClick={() => {
                openModalBulkCheckin();
            }}
            className="px-4 py-2 flex items-center cursor-pointer "
        >
            <Icon className=" mr-2" type="superuser" size={1.4} />
            {t("bulk-check-in")}
        </Button>
    );
};
