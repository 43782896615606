export const NewPasswordModal = (t) => {
    return {
        title: "change-password",
        executeSectionQuery: true,
        inputs: [
            {
                text: t("enter new password"),
                adjust: "first-capital",
                type: "void",
            },
            {
                name: "new-password",
                type: "password",
                securePassword: true,
                focus: true,
                ph: `password`,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "change-password",
                style: "blue",
                action: "recovery-new-password",
            },
        ],
    };
};

export default NewPasswordModal;
