import { useEffect } from "react";
import { useSelector } from "react-redux";
//Utils
import { toast } from "react-toastify";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { Session } from "./Session";
import { useTranslation } from "react-i18next";

export const LoginProject = () => {
    const { i18n } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { ref, global } = useSelector((state) => state.hotel);

    //API
    const MUTATION = gql`
        ${getLoginProjectMutation(global, ref, i18n)}
    `;

    const [hotelLogin, { data }] = useMutation(MUTATION);

    useEffect(() => {
        if (ref) {
            hotelLogin();
        }
        // eslint-disable-next-line
    }, [ref]);

    useEffect(() => {
        let _token = null;
        if (data && data.loginProject && data.loginProject.isOk) {
            _token = data.loginProject.token;
        } else if (data && data.loginCorporate && data.loginCorporate.isOk) {
            _token = data.loginCorporate.token;
        } else if (data && data.error !== "") {
            toast.error(langStrings["cant obtain your projects"]);
        }
        if (_token) {
            Session.setToken(_token);
            window.location.href = "/";
        }
        // eslint-disable-next-line
    }, [data]);
};

const getLoginProjectMutation = (isGlobal, projectRef, i18n) => {
    if (isGlobal)
        return `
        mutation {
            loginCorporate(
                chainRef: "${Session.getSessionProp("chainRef")}",
                userRef: "${Session.getSessionProp("userRef")}",
                chainId: "${Session.getSessionProp("chainId")}",
                lang:  "${i18n.language.split("-")[0]}"
                ) {
            isOk
            token
            }
        }
        `;
    return `
        mutation {
            loginProject(
                chainRef: "${Session.getSessionProp("chainRef")}",
                projectRef: "${projectRef}",
                userRef: "${Session.getSessionProp("userRef")}",
                chainId: "${Session.getSessionProp("chainId")}",
                lang:  "${i18n.language.split("-")[0]}"
                ) {
            isOk
            token
            }
        }
        `;
};
