const ChangePassword = (t) => {
    return {
        title: t("account") + " - " + t("change-password"),
        bodyAdjust: "w-4/12 h-auto",
        useGlobalLoading: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "change-password",
                style: "blue",
                action: "change-password",
            },
        ],
        inputs: [
            {
                name: "current-password",
                text: ["current-password", "*"],
                type: "password",
                focus: true,
                ph: t("enter current password"),
            },
            {
                name: "new-password",
                text: ["new-password", "*"],
                type: "password",
                securePassword: true,
                ph: t("enter new password"),
            },
        ],
    };
};

export default ChangePassword;
