import Countries from "../../lang/es/country.json";
import CountriesEN from "../../lang/en/country.json";

export const GetCountriesList = (data, t) => {
    const countries = [""];
    // eslint-disable-next-line
    data.countries.results.map((country) => {
        if (country && country.ref) countries.push([t("country:" + country.ref.toLowerCase()), country.ref, "str"]);
    });
    return countries;
};

export default GetCountriesList;

export const GetCountriesOptions = () => {
    const lang = localStorage.getItem("lang");

    //Get countries list
    let countries = [];

    for (let i in Countries) {
        if (lang === "es") {
            countries.push({ id: i.toUpperCase(), name: Countries[i] });
        } else if (lang === "en") {
            countries.push({
                id: i.toUpperCase(),
                name: CountriesEN[i.toLowerCase()],
            });
        }
    }

    return countries;
};

export const GetCountryName = (key, lang) => {
    if (!key) {
        return null;
    }
    const currentLang = lang ? lang : localStorage.getItem("lang");

    return currentLang === "es" ? Countries[key.toLowerCase()] : CountriesEN[key.toLowerCase()];
};
