import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = ({ code = 404, title: customTitle, desc: customDesc }) => {
    const { t } = useTranslation();

    const defaulTitle = code ? t(`error-${code}-title`) : null;
    const defaultDesc = code ? t(`error-${code}-desc`) : null;

    const title = customTitle === undefined ? defaulTitle : customTitle;
    const desc = customDesc === undefined ? defaultDesc : customDesc;

    const color = code >= 300 ? "text-red-100" : "text-black";

    return (
        <>
            {title ? <h1 className={`text-xl ${color} font-bold mb-2`}>{title}</h1> : null}
            {desc ? <p>{desc}</p> : null}
        </>
    );
};

export default NotFound;
