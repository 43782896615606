import React, { useState, useEffect } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Components
import UseEditableField from "../../../useEditableField";

//Utils
import Parser from "hooks/Utils/Parser";
import { useTranslation } from "react-i18next";
import { Session } from "../../../../hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";
import { changeActionValues } from "../../../../actions/globalActions";
import { useDispatch, useSelector } from "react-redux";

const RoomFields = ({ fieldsToShow = {}, initialData = {}, index, isEdit = false }) => {
    const { t } = useTranslation();
    const { values } = useSelector((state) => state.action);
    const dispatch = useDispatch();
    const isSuperUser = Session.getSessionProp("superUser");
    const maxNumberTvsPerRoom = 10;
    const sectionName = `${initialData.parentSectionName}-roomField${index ? `-${index}` : ""}`;
    //State
    const [tvLocationsOptions, setTvLocationsOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loadingLocations, setLoadingLocations] = useState(true);
    const [numberTvsPerRoom, setNumberTvsPerRoom] = useState(
        initialData.tvsPerRoom ? initialData.tvsPerRoom : !isEdit ? 1 : null
    );
    const [locationsSelected, setLocationsSelected] = useState(
        initialData.locationsSelected ? initialData.locationsSelected : []
    );

    //API
    const GET_LOCATIONS = gql`
        {
            locations {
                results
            }
            wings: installationMap {
                results {
                    pos
                }
            }
        }
    `;
    const [getLocations, { data, loading }] = useLazyQuery(GET_LOCATIONS, {
        fetchPolicy: "network-only",
    });

    //listeners
    useEffect(() => {
        let locationsSelectedClone = [...locationsSelected];
        let tvLocationsOptionsData = [];
        for (let i = 1; i <= maxNumberTvsPerRoom; i++) {
            tvLocationsOptionsData.push({
                id: `${i}`,
                name: `${i}`,
            });
        }
        if (initialData.roomtvs) {
            initialData.roomtvs.map((roomtv) =>
                locationsSelectedClone.push({
                    name: roomtv.name,
                    cec: roomtv.cec !== undefined ? roomtv.cec : true,
                })
            );
        }
        setLocationsSelected(locationsSelectedClone);
        setTvLocationsOptions(tvLocationsOptionsData);
        if (fieldsToShow.location) {
            getLocations();
        } else {
            setLoadingLocations(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && data) {
            setLoadingLocations(false);
        }
        if (data && data.locations) {
            let locationsOptions = [];
            data.locations.results.map((result) => locationsOptions.push({ id: result, name: result }));
            setLocations(locationsOptions);
        }
        if (initialData?.parentSectionName === "add-wing" && data?.wings) {
            const arrPos = data?.wings?.results.map((w) => {
                return w?.pos;
            });
            dispatch(changeActionValues({ arrPos: arrPos }));
        }

        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        for (let i = maxNumberTvsPerRoom; i > numberTvsPerRoom; i--) {
            const suffix = `${index ? `_${index}` : ""}_${i}`;
            dispatch(changeActionValues({ [`location${suffix}`]: null }));
            if (locationsSelected && locationsSelected.length >= i) {
                delete locationsSelected[i - 1];
            }
        }
        // eslint-disable-next-line
    }, [numberTvsPerRoom]);

    const deleteTvLocation = (arrayPosition) => {
        let currentPosition = arrayPosition + 1;
        let valuesClone = { ...values };
        let locationsSelectedClone = [...locationsSelected];
        locationsSelectedClone.splice(arrayPosition, 1);
        setLocationsSelected(locationsSelectedClone);
        for (let i = currentPosition + 1; i <= numberTvsPerRoom; i++) {
            let j = i - 1;
            const suffix = `${index ? `_${index}` : ""}_${i}`;
            const prevSuffix = `${index ? `_${index}` : ""}_${j}`;
            dispatch(changeActionValues({ [`location${prevSuffix}`]: valuesClone[`location${suffix}`] }));
            dispatch(changeActionValues({ [`location-cec${prevSuffix}`]: valuesClone[`location-cec${suffix}`] }));
        }
        setNumberTvsPerRoom(numberTvsPerRoom - 1);
    };

    const renderLocations = () => {
        const locationItems = [];
        if (numberTvsPerRoom && numberTvsPerRoom > 0 && !loadingLocations) {
            for (let i = 1; i <= numberTvsPerRoom; i++) {
                const suffix = `${index ? `_${index}` : ""}_${i}`;
                let locationData = locationsSelected && locationsSelected.length >= i ? locationsSelected[i - 1] : null;
                let cec = locationData ? locationData.cec : true;
                if (!locationData) {
                    if (values[`location-cec${suffix}`] === false) cec = values[`location-cec${suffix}`];
                }
                locationItems.push(
                    <div key={index + "_" + i} className="w-full flex justify-start items-center my-2">
                        <div className="w-48 pr-4">
                            <UseEditableField
                                data={{
                                    id: `${sectionName}-location-${i}`,
                                    label: `location${suffix}`,
                                    labelOnEdition: `location`,
                                    labelAdjust: "hidden",
                                    required: true,
                                    placeholder: ["select", "location"],
                                    inEditionMode: true,
                                    type: "select-with-search",
                                    markSelected: false,
                                    oneSelected: true,
                                    optionData: locations,
                                    selectWithAddInput: true,
                                    selectedIds: locationData ? [locationData.name] : null,
                                    loading: loadingLocations,
                                    editOptions: true,
                                }}
                            ></UseEditableField>
                        </div>
                        <div className={`w-auto flex items-center ${!isSuperUser ? "hidden" : ""} `}>
                            <span className="inline-block text-gray-800 pr-2 text-sm">
                                {t("send-instructions-chromecast")}
                            </span>
                            <UseEditableField
                                data={{
                                    id: `${sectionName}-location-cec-${i}`,
                                    label: `location-cec${suffix}`,
                                    noLabel: true,
                                    type: "switch",
                                    inEditionMode: true,
                                    value: cec,
                                    markSelected: cec,
                                }}
                            ></UseEditableField>
                        </div>
                        <div className="w-auto ml-3">
                            <span
                                className="icon icon-delete text-xl cursor-pointer"
                                onClick={() => {
                                    deleteTvLocation(i - 1);
                                }}
                            ></span>
                        </div>
                    </div>
                );
            }
        }
        return (
            <div className="w-full block">
                <div className="w-full my-2 ">
                    {isSuperUser ? (
                        <>
                            <div className="w-full block mt-4 mb-2">
                                <span className="font-bold text-sm">{t("tv-locations")}</span>
                            </div>
                            <div className="w-full block mb-4">
                                <span className="text-gray-800 text-sm">
                                    {Parser(String(t("select-tv-locations-and-cec")))}
                                </span>
                                <span
                                    className="icon icon-info text-xl ml-2 lg:text-2xl"
                                    data-tip
                                    data-for={`roomFieldLocationCEC`}
                                ></span>
                                <ReactTooltip id={`roomFieldLocationCEC`}>
                                    {t("{{capitalize, capitalize}}", { capitalize: t("cec-enabled-description") })}
                                </ReactTooltip>
                            </div>
                        </>
                    ) : null}
                    <UseEditableField
                        data={{
                            label: `locations${index ? `_${index}` : ""}`,
                            noLabel: true,
                            inEditionMode: true,
                            value: true,
                            type: "text",
                            inputAdjust: "hidden",
                        }}
                    ></UseEditableField>
                </div>
                <div className="w-full">{locationItems}</div>
            </div>
        );
    };

    return (
        <>
            <div className="float-left w-full flex">
                {fieldsToShow.order ? (
                    <div className="w-1/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-order`,
                                labelOnEdition: `order`,
                                label: `order${index ? `_${index}` : ""}`,
                                inEditionMode: true,
                                type: "number",
                                numberMin: 1,
                                value: initialData.order,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.floorName ? (
                    <div className="w-3/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-floor-name`,
                                labelOnEdition: `floor-name`,
                                label: `floor-name${index ? `_${index}` : ""}`,
                                inEditionMode: true,
                                type: "text",
                                value: initialData.floorName,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.roomName ? (
                    <div className="w-3/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-room-name`,
                                label: "room-name",
                                inEditionMode: true,
                                type: "text",
                                value: initialData.roomName,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.roomNumber ? (
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-room-number`,
                                label: "room-number",
                                inEditionMode: true,
                                type: "number",
                                value: initialData.roomNumber,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.pmsNumber ? (
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-pms-number`,
                                label: "pms-number",
                                inEditionMode: true,
                                type: "text",
                                value: initialData.pmsNumber,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.numberOfRooms ? (
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-number-of-rooms`,
                                labelOnEdition: `number-of-rooms`,
                                label: `number-of-rooms${index ? `_${index}` : ""}`,
                                inEditionMode: true,
                                value: initialData.numberOfRooms ? initialData.numberOfRooms : !isEdit ? 1 : null,
                                type: "number",
                                numberMin: 1,
                                numberMax: 500,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.starterRoom ? (
                    <div className="w-1.5/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-starter-room`,
                                labelOnEdition: `starter-room`,
                                label: `starter-room${index ? `_${index}` : ""}`,
                                inEditionMode: true,
                                type: "number",
                                value: initialData.starterRoom,
                                numberMin: 1,
                            }}
                        ></UseEditableField>
                    </div>
                ) : null}
                {fieldsToShow.tvsPerRoom ? (
                    <div className="w-6/12 pr-4 float-left">
                        <UseEditableField
                            data={{
                                id: `${sectionName}-tvs-per-room`,
                                labelOnEdition: `tvs-per-room`,
                                placeholder: ["select", "tvs-per-room"],
                                styleAdjust: "w-3/12",
                                label: `tvs-per-room${index ? `_${index}` : ""}`,
                                inEditionMode: true,
                                type: "select-with-search",
                                optionData: tvLocationsOptions,
                                markSelected: false,
                                oneSelected: true,
                                selectedIds: numberTvsPerRoom ? [numberTvsPerRoom] : null,
                                hideSearch: true,
                                loading: loadingLocations,
                                onChangeAction: (e) => {
                                    setNumberTvsPerRoom(e);
                                },
                            }}
                        ></UseEditableField>
                        {fieldsToShow.location && renderLocations()}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default RoomFields;
