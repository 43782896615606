import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";

//Utils
import ListLanguages from "../../../hooks/GraphqlCalls/Settings/ListLanguages";

const Languages = () => {
    ListLanguages();
    return (
        <>
            <UseSectionHeader title={"language-list"} introduction={"language-descriptive-text"} />
            <UseTable />
        </>
    );
};

export default Languages;
