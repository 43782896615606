import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getAssetUrl } from "../../../../../../../hooks/Utils/SalesUtils";

function ProductSettingsFileCard({ asset, index, moveCard, handleDeleteAsset, handleMoveAssets }) {
    //Consts&states
    const { id, opacity } = asset;
    const ref = useRef(null);
    const style = { float: "left", cursor: "move" };

    //queries&mutations

    //effects

    //arrangeData

    //handle&functions
    const [{ handlerId }, drop] = useDrop({
        accept: "productFieldCard",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item) {
            handleMoveAssets(item.index);
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });
    // eslint-disable-next-line
    const [{ isDragging }, drag] = useDrag({
        type: "productFieldCard",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                const dragIndex = item.index;
                const hoverIndex = index;
                moveCard(dragIndex, hoverIndex, true);
                item.index = hoverIndex;
            }
        },
    });
    //const opacity = isDragging ? 0 : 1;

    //renders
    drag(drop(ref));

    //response
    return (
        <div className={`w-28 h-28 px-2 py-3 mr-1`} ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
            <div className="w-full h-full flex align-middle items-center relative" key={id} data-id={id}>
                <div
                    className="w-full h-full rounded"
                    style={{
                        backgroundImage: `url('${getAssetUrl(asset.preview)}?preset=thumb')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                    }}
                ></div>
                <div className="absolute bottom-0 w-full h-6 z-10 bg-black bg-opacity-70 rounded-b">
                    <p className="text-center w-full h-full">
                        <span
                            className="icon icon-delete-editor text-3xl text-white cursor-pointer"
                            onClick={() => {
                                handleDeleteAsset();
                            }}
                        ></span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ProductSettingsFileCard;
