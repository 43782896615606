import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseCell from "./useCell";
import UseRowActions from "./useRowActions";
import sanitizeHtml from "sanitize-html";
//Actions
import { setHiddenRows } from "../../actions/sectionActions";
import { addCheckedItem, removeCheckedItem } from "../../actions/tableActions";
import useLangStings from "../../lang/useLangStrings";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";

const UseRow = ({ index, subrowData, depth }) => {
    //Store data
    const { checkable, actions, columns, allChecked, checkedItems, currentPage, serverError } = useSelector(
        (state) => state.table
    );
    const storeDataItem = useSelector((state) => state.table.results[index]);
    const dataItem = subrowData || storeDataItem;
    const { showHiddenRows } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    //States
    const [moreInfo, setMoreInfo] = useState(false);
    const [iconChange, setIconChange] = useState("");
    const [active, setActive] = useState(checkedItems.includes(dataItem.id));
    const [hideRow, setHideRow] = useState(false);
    const tooltipText = useLangStings(dataItem.textTooltip ? dataItem.textTooltip : "");

    const superUser = Session.getSessionProp("superUser");

    //State styles
    const expandHandler = (e) => {
        setMoreInfo(moreInfo ? false : true);
        setIconChange(moreInfo ? "" : "mr-4 -rotate-180");
    };

    //Actions
    const dispatch = useDispatch();
    const clickHandler = (e) => {
        e.stopPropagation();
        if (active) {
            dispatch(removeCheckedItem(dataItem.id));
        } else {
            dispatch(addCheckedItem(dataItem.id));
        }
        setActive(active ? false : true);
    };

    useEffect(() => {
        if (allChecked) {
            if (!dataItem.disableCheckbox) {
                dispatch(addCheckedItem(dataItem.id));
            }
        } else {
            dispatch(removeCheckedItem(dataItem.id));
        }
        if (!dataItem.disableCheckbox) {
            setActive(allChecked ? true : false);
        }
        // eslint-disable-next-line
    }, [allChecked]);

    useEffect(() => {
        if (!checkedItems || checkedItems.length === 0) {
            setActive(false);
        }
    }, [checkedItems]);

    useEffect(() => {
        if (dataItem.extendedInfo) {
            setMoreInfo(false);
            setIconChange("");
        }
        // eslint-disable-next-line
    }, [currentPage, dataItem.uniqueRowValue]);

    //Listeners
    useEffect(() => {
        if (showHiddenRows) {
            setHideRow(false);
            dispatch(setHiddenRows(false));
        }
        // eslint-disable-next-line
    }, [showHiddenRows]);

    useEffect(() => {
        if (
            values &&
            values["checked-item"] &&
            typeof values["checked-item"] === "number" &&
            values["checked-item"] === dataItem.id
        ) {
            dispatch(addCheckedItem(values["checked-item"]));
            setActive(true);
        }
    }, [values]);

    return (
        <div
            className={`border-b border-gray-200 ${hideRow ? "hidden" : ""}
            }`}
            data-infoid={dataItem.id}
        >
            <div
                className={`t-info-container ${dataItem.specialBackground && "bg-gray-300 hover:bg-gray-300"} ${
                    dataItem.extendedInfo && " cursor-pointer"
                }  ${dataItem.adjust ? dataItem.adjust : ""}`}
                id={`row-container-${index}`}
                data-tip={`${tooltipText ? sanitizeHtml(capitalizeFirst(tooltipText)) : ""}`}
                data-for="default-tooltip"
            >
                {dataItem.extendedInfo ? (
                    <span
                        id={`${index}-icon-extended-info`}
                        className={`icon-chevron animated mr-4 ${iconChange} ml-6`}
                        onClick={expandHandler}
                    ></span>
                ) : (
                    <span className="mr-4"></span>
                )}
                {dataItem.rowAddon && (
                    <div
                        className={` absolute ${
                            dataItem?.rowAddon?.widthBottom ? dataItem?.rowAddon?.widthBottom : "w-11"
                        } bg-white `}
                        style={{
                            bottom: "-1px",
                            height: dataItem?.rowAddon?.height ? dataItem?.rowAddon?.height : "1px",
                        }}
                    ></div>
                )}
                {/* checkbox */}
                {checkable && (
                    <div
                        className={`t-checkbox-container ${!dataItem.hideCheckbox ? "opacity-100" : "opacity-0"}`}
                        id={`${index}-row-checkbox-container`}
                    >
                        <input
                            data-id={dataItem.id}
                            type="checkbox"
                            onClick={(e) => clickHandler(e)}
                            className={`t-checkbox-checked opacity-${active ? "100" : "0"}`}
                            checked={active}
                            id={`${index}-row-checkbox`}
                            onChange={(e) => {}}
                            disabled={dataItem.disableCheckbox ? true : false}
                        ></input>
                        <span className="t-checkbox-unchecked" id={`${index}-row-checkbox-span`}></span>
                    </div>
                )}
                {/*cells*/}
                {dataItem.info.map((property, colIndex) => (
                    <UseCell
                        key={colIndex}
                        colIndex={colIndex}
                        rowIndex={index}
                        depth={depth}
                        columns={columns}
                        expandHandler={expandHandler}
                        cellData={property}
                        setHideRow={setHideRow}
                    />
                ))}
                {/* Row actions */}
                {(!serverError || superUser) && actions && (
                    <UseRowActions
                        actions={dataItem.actions}
                        dataItem={dataItem}
                        id={dataItem.id}
                        rowIndex={index}
                        depth={depth}
                        setHideRow={setHideRow}
                        resultsIndex={subrowData ? null : index}
                        componentStyle={dataItem.actionComponentStyle}
                        dataType={subrowData ? subrowData.dataType : null}
                        subrowData={subrowData ? subrowData : null}
                    />
                )}
            </div>
            {/* Expandable info */}
            {dataItem.extendedInfo && moreInfo && (
                <div className="t-extended-container items-baseline">
                    <div className="t-chevron-space"></div>
                    {checkable && <div className="t-checkbox-margin"></div>}
                    {dataItem.extendedInfo.map((property, cellIndex) => (
                        <UseCell
                            rowIndex={index}
                            key={cellIndex}
                            colIndex={cellIndex}
                            columns={columns}
                            cellData={property}
                        />
                    ))}
                    {actions && <div className="t-actions-space" />}
                </div>
            )}
        </div>
    );
};

export default UseRow;
