const PropertyGroup = (propertyGroup) => {
    const { id, name, projects } = propertyGroup;
    const dataType = "propertyGroup";

    let listProperties = [];
    if (projects)
        projects.forEach((project, index) => {
            listProperties.push([index, `${project.name}`, "bg-teal-500"]);
        });

    return {
        ...propertyGroup,
        dataType: dataType,
        adjust: "h-auto",
        actions: [{ type: dataType, value: id }],
        mutationParams: { id: id, name: name },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },

            {
                cellContent: "labelsTruncate",
                value: listProperties,
                specialWidth: "w-9/12 pr-6",
                cellAdjust: "truncate labels",
            },
        ],
    };
};

export default PropertyGroup;
