import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Icon from "components/Icon";
import TaxRatesModal from "./TaxRatesModal";
import Modal, { useModal } from "components/Modal";
import useProjectConfig from "hooks/Data/useProjectConfig";
import { toast } from "react-toastify";
import { executeVendureQuery, getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import { capitalizeFirst } from "hooks/Utils/Utils";

export const GET_TAX_ZONES = `
    {
        zones {
            id
            name
        }
        taxRates {
            items {
                id
                name
                value
                enabled
                category {
                    id
                }
                zone {
                    id
                }
            }
        }
    }
`;

const MoviesTaxes = () => {
    const { t } = useTranslation();
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedTaxRate, setSelectedTaxRate] = useState(null);
    const [priceTax, setPriceTax] = useState(false);
    const [taxZonesData, setTaxZonesData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { open, close } = useModal();

    const { load: loadProjectConfig, data: projectConfigData } = useProjectConfig({
        concept: "movies_taxes",
    });

    const fetchTaxZones = async () => {
        try {
            setLoading(true);
            const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_TAX_ZONES });
            setTaxZonesData(response?.data);
        } catch (err) {
            console.error("Error fetching tax zones:", err);
            setError(err);
            toast.error(capitalizeFirst("mutation-error"));
        } finally {
            setLoading(false);
        }
    };

    const updateSelectionFromConfig = (configData, zonesData) => {
        if (!configData || configData.length === 0 || !zonesData) return;

        const configValue = configData[0]?.value ? JSON.parse(configData[0].value) : null;

        if (!configValue) {
            console.error("Config value is missing or invalid");
            return;
        }

        const selectedZoneId = configValue.taxZone;
        const selectedTaxRateId = configValue.defaultTax;
        const selectedPriceTax = configValue.pricesWithTaxes;

        const matchingZone = zonesData.zones?.find((zone) => zone.id === selectedZoneId);
        const matchingTaxRate = zonesData.taxRates?.items?.find((rate) => rate.id === selectedTaxRateId);

        setSelectedZone(matchingZone || null);
        setSelectedTaxRate(matchingTaxRate || null);
        setPriceTax(selectedPriceTax || null);
    };

    useEffect(() => {
        loadProjectConfig();
    }, [loadProjectConfig]);

    useEffect(() => {
        fetchTaxZones();
    }, []);

    useEffect(() => {
        updateSelectionFromConfig(projectConfigData, taxZonesData);
    }, [projectConfigData, taxZonesData]);

    const handleUpdateSelectedTax = (zone, rate, price) => {
        setSelectedZone(zone);
        setSelectedTaxRate(rate);
        setPriceTax(price);
    };

    const handleSaveChanges = (zone, rate, price) => {
        setSelectedZone(zone);
        setSelectedTaxRate(rate);
        setPriceTax(price);
        loadProjectConfig();
        close();
    };

    const titleModal = <h2 className="text-3xl font-bold px-6 py-2">{`${t("taxes")} - ${t("edit")}`}</h2>;

    return (
        <div className="items-center justify-start flex flex-wrap">
            <div className="p-6 w-full bg-white rounded shadow-sm flex flex-col">
                <div className="flex justify-between items-center mb-5">
                    <div className="flex items-center">
                        <div className="text-3xl font-bold ml-2">{t("taxes")}</div>
                    </div>
                    <div className="flex items-center space-x-5 text-gray-800">
                        <Button
                            id="edit-button-taxes-movies"
                            className="cursor-pointer"
                            onClick={() =>
                                open(
                                    <Modal
                                        title={titleModal}
                                        id="modal-body-taxes-movies"
                                        className="w-4/12 p-6"
                                        style={{
                                            width: "560px",
                                            height: "376px",
                                            position: "absolute",
                                            top: "256px",
                                            left: "440px",
                                        }}
                                    >
                                        <TaxRatesModal
                                            onUpdate={handleUpdateSelectedTax}
                                            onSave={handleSaveChanges}
                                            savedTaxData={{
                                                zone: selectedZone,
                                                taxRate: selectedTaxRate,
                                                pricesWithTaxes: priceTax,
                                            }}
                                            fetchTaxZones={fetchTaxZones}
                                            taxesData={taxZonesData}
                                        />
                                    </Modal>
                                )
                            }
                        >
                            <Icon type="edit" size="xl" />
                        </Button>
                    </div>
                </div>

                <div className="text-base mb-5 font-bold ml-2">{t("tax-zone-selected")}</div>

                {selectedZone && selectedTaxRate ? (
                    <>
                        <div className="py-3 ml-2 space-y-4">
                            <p>
                                {selectedZone.name} - {selectedTaxRate.name}: {selectedTaxRate.value}%
                            </p>
                        </div>
                        <div className="flex items-center space-x-3 py-5 ml-2 font-bold">
                            {priceTax ? t("movie-prices-with-taxes") : t("movie-prices-without-taxes")}
                        </div>
                    </>
                ) : (
                    <div className="flex items-center space-x-3 py-5 ml-2">
                        <Icon className="icon icon-warning text-xl lg:text-2xl text-orange-100" />
                        <p className="mb-0">{t("tax-not-selected-tv-movies")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MoviesTaxes;
