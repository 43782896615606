import React, { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { useDispatch } from "react-redux";
import { showMainMenu } from "../../../../../actions/uiActions";
import { useNavigate as useHistory, useLocation } from "react-router-dom";
import { Session } from "../../../../../hooks/Utils/Session";

const HeaderPredefinedChannels = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const projectRef = Session.getSessionProp("projectRef") ? Session.getSessionProp("projectRef") : "";
    useLayoutEffect(() => {
        dispatch(showMainMenu(false));
        return () => {
            dispatch(showMainMenu(true));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const mainSideBarContainer = document.querySelector("#main_sidebar_nav_container");
        const sectionContent = document.querySelector("#sectionContent");
        mainSideBarContainer.style.display = "none";
        sectionContent.style.width = "100%";
        sectionContent.style.left = "0";
        return () => {
            mainSideBarContainer.style.display = null;
            sectionContent.style.width = null;
            sectionContent.style.left = null;
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div style={{ height: "72px" }} className=" w-full flex h-48 shadow-sm bg-white fixed left-0 top-0">
            <button
                onClick={() => {
                    if (location.pathname === "/services/tv-channels/preset-channels") {
                        if (projectRef === "CORPORATE") {
                            history("/services/tv-channels/corporate-channels");
                        } else {
                            history("/services/tv-channels/channels");
                        }
                    } else {
                        history("/services/tv-channels/preset-channels");
                    }
                }}
                style={{ width: "10%" }}
                className=" focus:outline-none flex items-center justify-center border-r border-gray-200 text-gray-800  "
            >
                <span className=" icon icon-chevron-left mt-1 pr-2 text-xl "></span>
                <span className=" text-lg ">{capitalizeFirst(t("back"))}</span>
            </button>
            <div style={{ width: "90%" }} className=" px-6 h-full font-bold text-lg flex items-center">
                {t("manage-of-preset-channels-database")}
            </div>
        </div>
    );
};

export default HeaderPredefinedChannels;
