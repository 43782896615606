import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Session } from "../../../hooks/Utils/Session";

//Actions
import { openModal, setModalContent, showGlobalLoading } from "../../../actions/uiActions";
import { changeGlobalAction, cleanAction } from "../../../actions/globalActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { setRefreshData, setReload } from "../../../actions/tableActions";
import Transcode from "../../../hooks/GraphqlCalls/MediaLibrary/modals/Transcode";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Utils
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { arrangeToastMessagesUploadingFiles } from "../../../hooks/Utils/DesignUtils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UseTabs from "../../useTabs";
import NewVideo from "../../../hooks/GraphqlCalls/MediaLibrary/modals/NewVideo";
import UpdateMediaLibrary from "../../../hooks/GraphqlCalls/MediaLibrary/UpdateMediaLibrary";
import UseToast from "../../Notifications/useToast";
import VideoLocale from "./VideoLocale";
import { setToastData } from "../../../actions/designActions";

const VideoDetail = ({ fileRef, projectLangs, assetName, setAssetName, parentRef, sideBarRef }) => {
    const { t } = useTranslation();

    //Actions
    const dispatch = useDispatch();

    //Store
    const { values, actionName, itemsAffected } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { toastData } = useSelector((state) => state.design);

    //States
    const [resetInputs, setResetInputs] = useState(false);
    const [languagesOptions, setLanguagesOptions] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [assetLanguages, setAssetLanguages] = useState(null);
    const [lastUpdateInterfaz, setLastUpdateInterfaz] = useState(window.ZDate.now());

    //Variables
    const hasCorporateUser =
        Session.getSessionProp("hasCorporateUser") && Session.getSessionProp("hasCorporateUser") !== "false";
    const isLangEditable = (ref) =>
        hasCorporateUser || ref === "" || (projectLangs && projectLangs.indexOf(ref) !== -1);

    const GET_INFO = gql`
    {
        libraryVideo: libraryVideo(ref:"${fileRef}"){
			error,
			ok,
            response{
                contentType
                defaultLanguage
                folderPath
                folderRef
                items
                languages{
                    contentType
                    duration
                    language
                    lastModified
                    name
                    resolution
                    size
                    streamStatus
                    streamTime
                    videoFormat
                }
                lastModified
                name
                ref
                size
            }
        }
    }
    `;
    const [executeQuery, { data }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(capitalizeFirst(t("mutation-error")));
        },
    });

    const [executeBackQuery, { data: dataBackQuery }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        pollInterval: 30000,
    });

    //Functions
    const replaceAsset = async (lang, name) => {
        const modal = NewVideo(null, fileRef, lang, null, name);
        dispatch(
            changeGlobalAction({
                actionName: "media-library-update-video",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const transcodeAsset = async (lang, name, secondName) => {
        const langs = [];
        if (projectLangs) {
            projectLangs.map((projectLang) =>
                langs.push({
                    id: projectLang,
                    name: t("language:" + projectLang) || projectLang,
                    selected: projectLang === lang,
                })
            );
        }
        const modal = Transcode({
            ref: fileRef,
            name: name,
            projectLangs: langs,
            actionName: "media-library-update-video-local",
            transcode: true,
            hiddeProjectLang: true,
            secondName: secondName,
        });
        dispatch(
            changeGlobalAction({
                actionName: "media-library-update-video-local",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const createAsset = async (lang) => {
        let prefix = `files${lang}`;
        dispatch(showGlobalLoading(true));
        if (values[prefix] && values[prefix].length > 0) {
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            let filesUploading = [];
            let transcodingVideos = false;
            for (let i = 0; i < values[prefix].length; i++) {
                let f = values[prefix][i];
                let file = f.file;
                let typeFile = f.type;
                filesUploading.push({
                    name: file.name,
                    status: 1,
                    errorMessage: "",
                    ref: "",
                    typeFile: typeFile,
                    lang: lang,
                    size: f && f.file && f.file.size ? f.file.size : 0,
                });
            }
            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
            let toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                closeButton: false,
                hideProgressBar: true,
                closeOnClick: false,
                draggable: false,
                icon: false,
                className: "use-toast",
            });
            for (let i = 0; i < values[prefix].length; i++) {
                let f = values[prefix][i];
                let file = f.file;
                let typeFile = f.type;
                if (typeFile === "image") typeFile = "asset";
                const uploadUrl = Session.getUploadUrl(
                    `?type=${typeFile}&token=${Session.getSessionProp("token").replace("Bearer ", "")}&ref=${fileRef}`
                );
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileName", file.name);
                formData.append("fileLanguage", lang);
                try {
                    const uploadResponse = await fetch(uploadUrl, {
                        method: "POST",
                        body: formData,
                    }).then((uploadResponse) => uploadResponse.json());
                    if (uploadResponse && uploadResponse.ref) {
                        dispatch(setRefreshData(true));
                        dispatch(setReload(true));
                        filesUploading[i].status =
                            typeFile === "video" && uploadResponse.streamStatus !== "COMPLETE" ? 1 : 2;
                        filesUploading[i].ref = uploadResponse.ref;
                        [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    } else {
                        filesUploading[i].status = 4;
                        filesUploading[i].errorMessage =
                            uploadResponse && uploadResponse.error && uploadResponse.error.message
                                ? uploadResponse.error.message
                                : "";
                        console.error(filesUploading[i].errorMessage);
                        [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    }
                } catch (err) {
                    console.log(err);
                    filesUploading[i].status = 4;
                    [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                }
                if (filesUploading[i].status === 1) transcodingVideos = true;
                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={true} />,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    icon: false,
                    className: "use-toast",
                });
            }
            setTimeout(function () {
                if (!transcodingVideos) {
                    [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    toast.update(toastId, {
                        render: <UseToast title={title} msgs={msgs} minimize={false} />,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        icon: false,
                        className: "use-toast",
                    });
                }
                dispatch(
                    setToastData({
                        id: toastId,
                        filesUploading: filesUploading,
                        size: values[prefix].length,
                    })
                );
            }, 500);
        } else {
            dispatch(showGlobalLoading(false));
        }
    };

    const downloadAsset = async (lang) => {
        const link = document.createElement("a");
        link.setAttribute("target", "_blank");
        link.href = Session.getDasUrl(fileRef + "?download");
        if (lang !== defaultLanguage) {
            link.href += `&lang=${lang}`;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const deleteAsset = async (lang, name, isMain) => {
        let modal = {
            id: fileRef,
            title: "media-library-delete-permanently",
            value: name,
            text: isMain ? "media-library-delete-all-permanently-text" : "media-library-delete-permanently-text",
            useGlobalLoading: true,
            inputs: [
                {
                    text: "",
                    name: "language",
                    value: lang,
                    type: "text",
                    cStyle: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete-permanently",
                    style: "red",
                    action: "video-library-delete-permanently",
                },
            ],
        };
        dispatch(
            changeGlobalAction({
                actionName: "video-library-delete-permanently",
                itemsAffected: fileRef,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const makeAsDefaultAsset = async (lang, name) => {
        let modal = {
            id: fileRef,
            title: "make-default",
            value: name,
            text: "media-library-make-to-default-text",
            useGlobalLoading: true,
            inputs: [
                {
                    text: "",
                    name: "defaultLanguage",
                    value: lang,
                    type: "text",
                    cStyle: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "media-library-update-video",
                },
            ],
        };
        dispatch(
            changeGlobalAction({
                actionName: "media-library-update-video",
                itemsAffected: fileRef,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        if (values["assetDefaultLanguage"] && values["assetDefaultLanguage"].length > 0) {
            if (!actionName && !itemsAffected) {
                if (defaultLanguage !== null && defaultLanguage !== values["assetDefaultLanguage"][0]) {
                    if (values["assetDefaultLanguage"][0] === "" && assetLanguages && assetLanguages.length > 1) {
                        toast.error(t("unable to set multi-language"));
                        setResetInputs(true);
                        setTimeout(() => setResetInputs(false));
                    } else {
                        dispatch(showGlobalLoading(true));
                        dispatch(
                            setExecuteQuery({
                                action: "media-library-update-video",
                                params: {
                                    id: fileRef,
                                    lang: values["assetDefaultLanguage"][0],
                                },
                            })
                        );
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [values]);

    const arrangeDataLangs = (assetLangs) => {
        let langs = [];
        if (projectLangs) {
            langs = [...projectLangs];
        }
        if (assetLangs) {
            // eslint-disable-next-line
            assetLangs.map((lang) => {
                if (langs.indexOf(lang.language) === -1) {
                    langs.push(lang.language);
                }
            });
        }
        let response = [];
        if (langs.length > 0) {
            // eslint-disable-next-line
            langs.forEach((languageRef) => {
                const name = t("language:" + languageRef);
                if (languageRef) {
                    response.push({
                        id: languageRef,
                        name: name || languageRef,
                        selected: false,
                    });
                }
            });
        }
        return response;
    };

    //listeners
    useEffect(() => {
        executeQuery();
        executeBackQuery();
        dispatch(showGlobalLoading(true));
        dispatch(cleanAction());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            //refetch();
            executeQuery();
            dispatch(setRefreshContentData(false));
            setLastUpdateInterfaz(window.ZDate.now());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data) {
            const assetLangs =
                (data.libraryVideo && data.libraryVideo.response && data.libraryVideo.response.languages
                    ? data.libraryVideo.response.languages
                    : null) || [];
            const assetDefaultLang =
                (data.libraryVideo && data.libraryVideo.response && data.libraryVideo.response.defaultLanguage
                    ? data.libraryVideo.response.defaultLanguage
                    : null) || "";
            const assetName =
                (data.libraryVideo && data.libraryVideo.response && data.libraryVideo.response.name
                    ? data.libraryVideo.response.name
                    : null) || "";

            setDefaultLanguage(assetDefaultLang);
            setAssetName(assetName);
            setAssetLanguages(assetLangs);
            setLanguagesOptions(arrangeDataLangs(assetLangs));
            dispatch(showGlobalLoading(false));

            if (data.libraryVideo && !data.libraryVideo.ok && data.libraryVideo.response == null) {
                window.location = "#/design/library/" + parentRef + "/" + sideBarRef;
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (
            dataBackQuery &&
            dataBackQuery.libraryVideo &&
            dataBackQuery.libraryVideo.response &&
            dataBackQuery.libraryVideo.response.languages &&
            dataBackQuery.libraryVideo.response.languages.length > 0
        ) {
            if (toastData && toastData.id && toastData.filesUploading && toastData.filesUploading.length > 0) {
                let allUploaded = true;
                let filesUploadingClone = [...toastData.filesUploading];
                for (let i = 0; i < filesUploadingClone.length; i++) {
                    let fileUploadingClone = filesUploadingClone[i];
                    if (
                        fileUploadingClone.lang &&
                        fileUploadingClone.typeFile &&
                        fileUploadingClone.typeFile === "video"
                    ) {
                        let libraryVideo = dataBackQuery.libraryVideo.response.languages.filter(
                            (libraryVideo) => libraryVideo.language === fileUploadingClone.lang
                        );
                        if (libraryVideo && libraryVideo.length > 0) {
                            libraryVideo = libraryVideo[0];
                            if (libraryVideo && libraryVideo.streamStatus === "COMPLETE") {
                                fileUploadingClone.status = 2;
                            } else {
                                allUploaded = false;
                            }
                        }
                    }
                    filesUploadingClone[i] = fileUploadingClone;
                }
                const [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploadingClone, toastData.size);
                toast.update(toastData.id, {
                    type: allUploaded ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
                    render: <UseToast title={title} msgs={msgs} minimize={!allUploaded} />,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    closeButton: allUploaded,
                    icon: false,
                    className: "use-toast",
                });
                if (allUploaded) {
                    dispatch(setRefreshData(true));
                    dispatch(setReload(true));
                    dispatch(setToastData(null));
                } else {
                    dispatch(
                        setToastData({
                            id: toastData.id,
                            filesUploading: filesUploadingClone,
                            size: toastData.size,
                        })
                    );
                }
            }
        }
        // eslint-disable-next-line
    }, [dataBackQuery]);

    useEffect(() => {
        if (defaultLanguage !== null) {
            // eslint-disable-next-line
            languagesOptions
                .filter((option) => option.id === defaultLanguage)
                .map((option) => {
                    let obj = option;
                    obj["selected"] = true;
                    return option;
                });
        }
        // eslint-disable-next-line
    }, [defaultLanguage]);

    return (
        <>
            <UpdateMediaLibrary viewMode={"assetDetail"} />
            {(() => {
                if (languagesOptions && languagesOptions.length > 0 && defaultLanguage && defaultLanguage !== "") {
                    let response = [];

                    languagesOptions
                        .filter((lang) => lang.id === defaultLanguage)
                        .map((lang) => {
                            const languageRef = lang.id;
                            let assetLang = null;
                            if (assetLanguages && assetLanguages.length > 0) {
                                assetLanguages
                                    .filter((language) => language.language === languageRef)
                                    // eslint-disable-next-line
                                    .map((lang) => {
                                        assetLang = lang;
                                    });
                            }

                            response.push(
                                <VideoLocale
                                    isDefault={true}
                                    defaultLanguage={defaultLanguage}
                                    resetInputs={resetInputs}
                                    languagesOptions={languagesOptions}
                                    assetLang={assetLang}
                                    lang={lang}
                                    fileRef={fileRef}
                                    assetName={assetName}
                                    lastUpdateInterfaz={lastUpdateInterfaz}
                                    deleteAsset={deleteAsset}
                                    downloadAsset={downloadAsset}
                                    makeAsDefaultAsset={makeAsDefaultAsset}
                                    replaceAsset={replaceAsset}
                                    transcodeAsset={transcodeAsset}
                                    createAsset={createAsset}
                                    isLangEditable={isLangEditable}
                                />
                            );

                            return lang;
                        });

                    let titles = [];
                    let contents = [];
                    languagesOptions
                        .filter((lang) => lang.id !== defaultLanguage && lang.id !== "")
                        .map((lang) => {
                            const languageRef = lang.id;
                            const title = lang.name;
                            const isEditable = isLangEditable(languageRef);
                            let assetLang = null;
                            let videoTranscode = true;
                            if (assetLanguages && assetLanguages.length > 0) {
                                assetLanguages
                                    .filter((language) => language.language === languageRef)
                                    // eslint-disable-next-line
                                    .map((lang) => {
                                        assetLang = lang;
                                    });
                            }
                            if (assetLang) videoTranscode = assetLang.streamStatus === "COMPLETE";
                            titles.push({
                                name: title,
                                disabled: !isEditable,
                                spinner: !videoTranscode,
                                spinnerAdjust: "inline-block pl-2",
                                spinnerStyle: {
                                    transform: "scale(0.2)",
                                    transformOrigin: "top left",
                                    width: "15px",
                                    height: "15px",
                                },
                                image:
                                    assetLang && videoTranscode
                                        ? require(`../../../assets/images/icons/` +
                                              (isEditable ? "save-circle-green" : "save-circle-gray") +
                                              `.svg`)
                                        : null,
                            });

                            contents.push(
                                <VideoLocale
                                    assetLang={assetLang}
                                    lang={lang}
                                    fileRef={fileRef}
                                    assetName={assetName}
                                    lastUpdateInterfaz={lastUpdateInterfaz}
                                    deleteAsset={deleteAsset}
                                    downloadAsset={downloadAsset}
                                    makeAsDefaultAsset={makeAsDefaultAsset}
                                    replaceAsset={replaceAsset}
                                    transcodeAsset={transcodeAsset}
                                    createAsset={createAsset}
                                    isLangEditable={isLangEditable}
                                />
                            );

                            return lang;
                        });

                    response.push(
                        <>
                            <div className="w-full flex pl-5">
                                <div className="w-12/12 mb-5 mt-10">
                                    <h1 className={`section-title first-capital mb-5`}>
                                        {capitalizeFirst(t("other-lang"))}
                                    </h1>
                                </div>
                            </div>
                            <div className="w-full pl-5">
                                <UseTabs color={`blue-600`} titles={titles} contents={contents} />
                            </div>
                        </>
                    );
                    return response;
                }
                return null;
            })()}
        </>
    );
};

export default VideoDetail;
