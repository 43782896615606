import React from "react";
import { NavLink } from "react-router-dom";
import { Session } from "../../hooks/Utils/Session";
const Logo = () => {
    return (
        <NavLink id={"topBar-logo-link"} to={"/"} className="">
            <img
                id={"topBar-logo-img"}
                width="160px"
                className={`mx-auto py-2 h-20 object-contain`}
                src={Session.getDasUrl(Session.getSessionProp("chainLogoRef"))}
                alt="Logo"
            />
        </NavLink>
    );
};

export default Logo;
