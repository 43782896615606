import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setHeaderButtons,
    setAvailableFilters,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
//Model
import Property from "./models/Property";
import { Session } from "../../Utils/Session";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useMSQuery } from "../useMSQuery";

const ListProperties = () => {
    const { t } = useTranslation();
    const { type, currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);
    const [applyFilters, setApplyFilters] = useState("");
    const [associatedBrands, setAssociatedBrands] = useState(null);
    //Data
    const sectionName = "list-properties";
    const currentUserRef = Session.getSessionProp("userRef");

    //Variables.
    let associatedProjects = [];
    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            if (project.id && project.id > 0) {
                associatedProjects.push(project);
            }
        });
    }

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_PROPERTIES = gql`
        {
            properties(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}${applyFilters}){
                info{count}
                results{id name brands{id name} }
            }
            brands{results{id name ref}}
            user:users(ref:"${currentUserRef}"){
                results{ brands{ id name ref } }
            }
        }`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_PROPERTIES);

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        localStorage.removeItem("brands");
        setAssociatedBrands(null);
        const tableHeaders = GetTableHeaders(sectionName);
        dispatch(setLoading(true));
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
        dispatch(setCountPosition("table-header"));
        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no properties available")));
        executeQuery();
        // executeFiltersQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (type === sectionName) {
            let currentFilters = arrangeFilters();
            setApplyFilters(`, filter:{${currentFilters}}`);
        } else {
            setApplyFilters("");
        }
        // eslint-disable-next-line
    }, [filterQuery]);

    useEffect(() => {
        if (associatedBrands !== null) {
            const dataItems = arrangeData(data);
            dispatch(setTableResults(dataItems));
        }
        // eslint-disable-next-line
    }, [associatedBrands]);

    useEffect(() => {
        if (!loading && !error && data && data.properties && networkStatus === 7) {
            dispatch(setLoading(true));
            //Get filters data
            if (data.user && data.user.results && data.user.results.length > 0) {
                Session.setSessionProp("brands", JSON.stringify(data.user.results[0].brands));
                if (associatedBrands === null) {
                    setAssociatedBrands(data.user.results[0].brands);
                }
            }
            if (data.brands && data.brands.results) {
                const availableFilters = GetAvailableFilters(
                    sectionName,
                    extractBrandsList(data.brands.results ? data.brands.results : [])
                );
                dispatch(setAvailableFilters(availableFilters));
            }

            //Get data
            dispatch(setTableType(sectionName));
            const dataItems = arrangeData(data);
            dispatch(setTableResults(dataItems));
            if (data.properties.info) dispatch(setCount(data.properties.info.count));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    const arrangeFilters = () => {
        let projectsData = "";
        let othersFilters = activeFilters;
        let currentFilters = "";
        if (!_.isEmpty(associatedProjects)) {
            projectsData = `[${associatedProjects.map(function (a) {
                return `"${a.ref}"`;
            })}]`;
        }
        if (_.get(othersFilters, "property")) {
            projectsData = _.get(othersFilters, "property");
            delete othersFilters["property"];
        }
        if (!_.isEmpty(othersFilters)) {
            for (const [key, value] of Object.entries(othersFilters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        currentFilters += `refs:${projectsData ? projectsData : `"fakeProject"`}`;
        return currentFilters;
    };
};
const arrangeData = (data) => {
    const propertiesData = [];
    // eslint-disable-next-line
    if (data && data.properties && data.properties.results) {
        // eslint-disable-next-line
        data.properties.results.map((property) => {
            propertiesData.push(
                Property(property, brandsForEdit(property.brands, data.brands.results, data.user.results[0].brands))
            );
        });
    }
    return propertiesData;
};

const brandsForEdit = (propertyBrands, allBrands, userBrands) => {
    const isSuperUser = Session.getSessionProp("superUser");
    const optionsData = [];
    // eslint-disable-next-line
    allBrands.map((_brand) => {
        let _selected = false;
        let _disabled = true;
        // eslint-disable-next-line
        propertyBrands.map((_propertyBrand) => {
            if (_propertyBrand.id === _brand.id) {
                _selected = true;
            }
            // eslint-disable-next-line
        });
        // eslint-disable-next-line
        userBrands.map((_userBrand) => {
            if (_userBrand.id === _brand.id) {
                _disabled = false;
            }
        });
        optionsData.push({
            id: _brand.id,
            name: _brand.name,
            selected: _selected,
            category: "property-groups",
            selectedLabelColor: "bg-orange-100 text-white",
            disabled: isSuperUser ? false : _disabled,
        });
    });

    return optionsData;
};

const extractBrandsList = (brands) => {
    let brandsArray = [];
    if (brands) {
        brands.map((brand) =>
            brandsArray.push({
                value: brand.name,
                id: brand.id,
                visible: true,
            })
        );
    }
    return brandsArray;
};

export default ListProperties;
