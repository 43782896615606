import Keys from "../components/Section/Devices/Keys";
import RemoteControllers from "../components/Section/Devices/RemoteControllers";
import { newRoute, newRedirect } from "./utils";

const sectionID = "devices";
const packageID = "hotel";

const attrDevices = "devices";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const devicesRoutes = [
    route("/", {
        redirect: redirect("/keys"),
        attribute: attrDevices,
    }),
    route("/keys", {
        section: Keys,
        attribute: attrDevices,
    }),
    route("/remote-controllers", {
        section: RemoteControllers,
        attribute: attrDevices,
    }),
];
