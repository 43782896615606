import React, { useEffect, useState } from "react";
import UseSectionHeader from "components/useSectionHeader";
import { useTranslation } from "react-i18next";
import UseSwitch from "components/Inputs/useSwitch";
import UseSelectWithSearch from "components/useSelectWithSearch";
import {
    ACTIVATE_AUTOMATION_GUEST_REPORT,
    AUTHORITIES,
    DEACTIVATE_AUTOMATION_GUEST_REPORT,
    UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT,
    UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS,
} from "../Precheckin/ReservationDetails/utils";
import { POLLINTERVAL_15S, capitalizeFirst } from "hooks/Utils/Utils";
import UseButton from "../../../useButton";
import { gql } from "apollo-boost";
import {
    FAKE_MUTATION,
    MUTATION_UPDATE_AUTOMATION_GUEST_REPORT,
    MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT,
    MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS,
    QUERY_PRE_CHECK_IN_SETTINGS_TRAVELERS_REPORT,
} from "../Precheckin/ReservationDetails/queries";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal, setModalContent, showGlobalLoading } from "actions/uiActions";
import AutomationOfGuestReport from "hooks/GraphqlCalls/Customers/modals/AutomationOfGuestReport";
import { cleanActionValues } from "actions/globalActions";
import { setExecuteQuery, setRefreshContentData, setSectionContent } from "actions/sectionActions";
import UseComponentLoading from "components/useComponentLoading";
import { createBrowserHistory } from "history";
import { GuestDataReport } from "hooks/GraphqlCalls/Settings/modals/GuestDataReport";

const AutomationGuestReport = () => {
    const { t } = useTranslation();
    const PersonalInformationOptions = [
        { name: "name", id: "name" },
        { name: "surname", id: "surname" },
        { name: "gender", id: "gender" },
        { name: "nationality", id: "nationality" },
        { name: "date-of-birth", id: "birthdate" },
        { name: "email-address", id: "email" },
        { name: "phone-number", id: "phone" },
    ];
    const IdentificationOptions = [
        { name: "document", id: "documentType" },
        { name: "document-number", id: "documentNumber", disabled: true },
        { name: "date-of-issue", id: "documentExpeditionDate", disabled: true },
    ];
    const [isEditedGuestReportData, setIsEditedGuestReportData] = useState(true);
    const [hasFieldsSelected, setHasFieldsSelected] = useState(false);
    const [generalGuestReportList, setGeneralGuestReportList] = useState([
        ...PersonalInformationOptions.map((val) => {
            val.category = t("personal-information");
            val.name = t(val.name);
            return val;
        }),
        ...IdentificationOptions.map((val) => {
            val.category = t("identification-document");
            val.name = t(val.name);
            return val;
        }),
    ]);

    const [generalChildReportList, setGeneralChildReportList] = useState([
        ...PersonalInformationOptions.map((val) => {
            val.category = t("personal-information");
            val.name = t(val.name);
            return val;
        }),
        ...IdentificationOptions.map((val) => {
            val.category = t("identification-document");
            val.name = t(val.name);
            return val;
        }),
    ]);

    const prefixId = "automation-guest-report";
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const { lang } = useSelector((state) => state.ui);
    const history = createBrowserHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [dataGuestReport, setDataGuestReport] = useState({
        authorities: null,
        user: null,
        password: null,
        automaticSendingOfTheTravelersReport: null,
    });
    const [copyDataGuestReport, setCopyDataGuestReport] = useState({
        authorities: null,
        user: null,
        password: null,
        automaticSendingOfTheTravelersReport: null,
    });

    const QUERY = gql(QUERY_PRE_CHECK_IN_SETTINGS_TRAVELERS_REPORT());

    const [executeQuery, { data, refetch, loading }] = useLazyQuery(QUERY, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    const [guestSelectedIds, setGuestSelectedIds] = useState([]);

    const [guestChildrenSelectedIds, setGuestChildrenSelectedIds] = useState([]);

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        const initialData = { ...dataGuestReport };
        const { preCheckInSettings } = data || {};
        const { preCheckInSettingsTravelersReport } = data || {};
        const { automaticSendingOfTheTravelersReport } = preCheckInSettings || {};
        const { authorities, password, user } = preCheckInSettingsTravelersReport || {};
        initialData.automaticSendingOfTheTravelersReport = automaticSendingOfTheTravelersReport;
        initialData.authorities = authorities;
        initialData.password = password;
        initialData.user = user;
        const customInputs = data?.preCheckInSettingsCustomFields?.fields?.reduce((acc, curr) => {
            const title =
                curr.names.find((val) => val.lang == lang).name || curr.names.find((val) => val.name != "").name;
            acc.push({ id: curr.ref, name: title, category: t("additional-information") });
            return acc;
        }, []);
        const customInputsChildren = data?.preCheckInSettingsCustomFieldsChildren?.fields?.reduce((acc, curr) => {
            const title =
                curr.names.find((val) => val.lang == lang).name || curr.names.find((val) => val.name != "").name;
            acc.push({ id: curr.ref, name: title, category: t("additional-information") });
            return acc;
        }, []);

        if (
            Object.values(data?.preCheckInSettingsTravelersReport?.guestData?.fields ?? {}).some((item) => {
                if (typeof item == "boolean") {
                    return item;
                } else {
                    return false;
                }
            }) ||
            data?.preCheckInSettingsTravelersReport?.guestData?.customFields?.length > 0 ||
            Object.values(data?.preCheckInSettingsTravelersReport?.guestDataChildren?.fields ?? {}).some((item) => {
                if (typeof item == "boolean") {
                    return item;
                } else {
                    return false;
                }
            }) ||
            data?.preCheckInSettingsTravelersReport?.guestDataChildren?.customFields?.length > 0
        ) {
            setHasFieldsSelected(true);
        } else {
            setHasFieldsSelected(false);
        }

        if (
            customInputs &&
            generalGuestReportList.length == PersonalInformationOptions.length + IdentificationOptions.length
        ) {
            setGeneralGuestReportList((curr) => [...curr, ...customInputs]);
        }
        if (
            customInputsChildren &&
            generalChildReportList.length == PersonalInformationOptions.length + IdentificationOptions.length
        ) {
            setGeneralChildReportList((curr) => [...curr, ...customInputsChildren]);
        }
        setCopyDataGuestReport(initialData);
        setDataGuestReport(initialData);
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        setGuestSelectedIds(
            generalGuestReportList.reduce((acc, curr) => {
                if (
                    data?.preCheckInSettingsTravelersReport?.guestData?.fields[curr.id] ||
                    data?.preCheckInSettingsTravelersReport?.guestData?.customFields?.includes(curr.id)
                ) {
                    acc.push(curr.id);
                }
                return acc;
            }, [])
        );
    }, [generalGuestReportList, data]);

    useEffect(() => {
        setGuestChildrenSelectedIds(
            generalChildReportList.reduce((acc, curr) => {
                if (
                    data?.preCheckInSettingsTravelersReport?.guestDataChildren?.fields[curr.id] ||
                    data?.preCheckInSettingsTravelersReport?.guestDataChildren?.customFields?.includes(curr.id)
                ) {
                    acc.push(curr.id);
                }
                return acc;
            }, [])
        );
    }, [generalChildReportList, data]);

    useEffect(() => {
        if (values["guest-report-select"]?.includes("documentType")) {
            setGeneralGuestReportList((curr) => {
                return curr.map((val) => {
                    let selectedValue = { ...val };
                    if (val.id == "documentNumber" || val.id == "documentExpeditionDate") {
                        selectedValue = { ...selectedValue, disabled: false };
                    }
                    if (values["guest-report-select"]?.includes(val.id)) {
                        selectedValue = { ...selectedValue, selected: true };
                    } else {
                        selectedValue = { ...selectedValue, selected: false };
                    }
                    return selectedValue;
                });
            });
        } else {
            setGeneralGuestReportList((curr) => {
                return [
                    ...curr.map((val) => {
                        let selectedValue = { ...val };
                        if (val.id == "documentNumber" || val.id == "documentExpeditionDate") {
                            selectedValue = { ...selectedValue, disabled: true, selected: false };
                        } else if (val.id == "documentType") {
                            selectedValue = { ...selectedValue, selected: false };
                        } else if (values["guest-report-select"]?.includes(val.id)) {
                            selectedValue = { ...selectedValue, selected: true };
                        } else {
                            selectedValue = { ...selectedValue, selected: false };
                        }
                        return selectedValue;
                    }),
                ];
            });
        }
        if (values["children-guest-report-select"]?.includes("documentType")) {
            setGeneralChildReportList((curr) => {
                return [
                    ...curr.map((val) => {
                        let selectedValue = { ...val };
                        if (val.id == "documentNumber" || val.id == "documentExpeditionDate") {
                            selectedValue = { ...selectedValue, disabled: false };
                        }
                        if (values["children-guest-report-select"].includes(val.id)) {
                            selectedValue = { ...selectedValue, selected: true };
                        } else {
                            selectedValue = { ...selectedValue, selected: false };
                        }
                        return selectedValue;
                    }),
                ];
            });
        } else {
            setGeneralChildReportList((curr) => {
                return [
                    ...curr.map((val) => {
                        let selectedValue = { ...val };
                        if (val.id == "documentNumber" || val.id == "documentExpeditionDate") {
                            selectedValue = { ...selectedValue, disabled: true, selected: false };
                        } else if (val.id == "documentType") {
                            selectedValue = { ...selectedValue, selected: false };
                        } else if (values["children-guest-report-select"]?.includes(val.id)) {
                            selectedValue = { ...selectedValue, selected: true };
                        } else {
                            selectedValue = { ...selectedValue, selected: false };
                        }
                        return selectedValue;
                    }),
                ];
            });
        }
    }, [values]);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setRefreshContentData(false));
            refetch();
            setIsEditedGuestReportData(false);
        }
    }, [refreshData]);

    return (
        <div className=" relative">
            <UseSectionHeader title={t("guest-report")} navToPreviousPage={true} />
            <div className="w-full grid grid-cols-2 gap-8">
                <div className=" col-span-2 bg-white rounded p-6" style={{ height: "fit-content" }}>
                    <div className="flex justify-between">
                        <span className=" font-bold text-3xl">{t("guest-report-data")}</span>
                        {!isEditedGuestReportData && (
                            <button
                                id="edit-guest-report-button"
                                className="text-gray-800 text-3xl focus:outline-none icon icon-edit"
                                onClick={() => {
                                    setIsEditedGuestReportData(true);
                                }}
                            ></button>
                        )}
                    </div>

                    <p className=" mt-4">{t("select-fields-guest-report")}</p>
                    {isEditedGuestReportData ? (
                        <div className=" mt-2">
                            <p className=" mt-6 text-sm text-gray-800">{t("general-guest-report-data")}</p>
                            <div className=" max-w-md">
                                <UseSelectWithSearch
                                    data={{
                                        optionData: generalGuestReportList,
                                        id: `guest-report-select`,
                                        name: `guest-report-select`,
                                        selectPlaceHolder: t("select-data"),
                                        oneSelected: false,
                                        noSelect: true,
                                        selectedIds: guestSelectedIds,
                                        noShowSelectedOptionsInDropDownSection: false,
                                        nonInitialOptionData: true,
                                        categoryCheck: true,
                                    }}
                                ></UseSelectWithSearch>
                            </div>

                            <p className=" mt-6">{t("select-guest-report-children-description")}</p>
                            {!data?.preCheckInSettings?.requestDataFromChildren && (
                                <div className=" mt-6 flex gap-2">
                                    <i className="icon icon-warning warning text-xl"></i>
                                    <p>{t("no-data-setup-for-children")}</p>
                                </div>
                            )}

                            <p className=" mt-6 text-sm text-gray-800">{t("childrens-guest-report-data")}</p>
                            <div className=" max-w-md">
                                <UseSelectWithSearch
                                    data={{
                                        optionData: generalChildReportList,
                                        id: `children-guest-report-select`,
                                        name: `children-guest-report-select`,
                                        selectPlaceHolder: t("select-data"),
                                        selectedIds: data?.preCheckInSettings?.requestDataFromChildren
                                            ? guestChildrenSelectedIds
                                            : [],
                                        oneSelected: false,
                                        noSelect: true,
                                        noShowSelectedOptionsInDropDownSection: false,
                                        nonInitialOptionData: true,
                                        categoryCheck: true,
                                        disabled: !data?.preCheckInSettings?.requestDataFromChildren,
                                    }}
                                ></UseSelectWithSearch>
                            </div>
                            <div className="flex justify-end mt-5">
                                <UseButton
                                    id={`edit-guest-report-cancel-button`}
                                    action={() => {
                                        setIsEditedGuestReportData(false);
                                    }}
                                    adjust={"mr-4"}
                                    buttonName={t("cancel")}
                                    buttonColor={"btn-white"}
                                />
                                <UseButton
                                    id={`edit-guest-report-save-button`}
                                    buttonName={t("save")}
                                    buttonColor={"btn-blue"}
                                    action={() => {
                                        const hasChildrenCustomFields =
                                            data?.preCheckInSettings?.requestDataFromChildren;
                                        const showTwoColumns =
                                            values["guest-report-select"]?.length ==
                                            values["children-guest-report-select"]?.length
                                                ? values["guest-report-select"].reduce((acc, curr) => {
                                                      if (!values["children-guest-report-select"].includes(curr)) {
                                                          acc = false;
                                                      }
                                                  }, true)
                                                : true;
                                        dispatch(setSectionContent({ hasChildrenCustomFields, showTwoColumns }));
                                        dispatch(
                                            setModalContent(
                                                GuestDataReport(
                                                    showTwoColumns,
                                                    hasChildrenCustomFields,
                                                    data?.preCheckInSettingsCustomFields?.fields || [],
                                                    data?.preCheckInSettingsCustomFieldsChildren?.fields || []
                                                )
                                            )
                                        );
                                        dispatch(openModal());
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            {hasFieldsSelected ? (
                                <p className=" text-green-500 mt-4">{t("fields-selected")}</p>
                            ) : (
                                <p className=" text-gray-500 mt-4">{t("no-fields-selected-yet")}</p>
                            )}
                        </>
                    )}
                </div>
                {/* <div className=" flex-1 bg-white p-6 rounded">
                    <div className=" flex items-center mb-4">
                        <span className=" block pr-2 font-bold">{t("allow-automation-of-guest-report-sending")}</span>

                        <div className=" w-1/12 ">
                            <label id={`${prefixId}-label-switch`} className="switch cursor-pointer ">
                                <input
                                    type="checkbox"
                                    id={`${prefixId}-input-switch`}
                                    checked={dataGuestReport.automaticSendingOfTheTravelersReport}
                                    onChange={(e) => {
                                        const newVal = !dataGuestReport.automaticSendingOfTheTravelersReport;

                                        if (newVal) {
                                            dispatch(setModalContent(AutomationOfGuestReport()));
                                            dispatch(openModal());
                                            setDataGuestReport({
                                                ...dataGuestReport,
                                                automaticSendingOfTheTravelersReport: false,
                                            });
                                        } else {
                                            dispatch(setExecuteQuery({ action: DEACTIVATE_AUTOMATION_GUEST_REPORT }));
                                            setDataGuestReport({
                                                ...dataGuestReport,
                                                automaticSendingOfTheTravelersReport: newVal,
                                            });
                                        }
                                    }}
                                ></input>
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    <div className=" flex flex-col mb-4">
                        <span className=" block mb-2">{t("select-autorities-and-the-access-credential")}</span>
                        <span className=" block">{t("keep-in-mind-that-this-option-for-properties-from-spain")}</span>
                    </div>

                    {dataGuestReport.automaticSendingOfTheTravelersReport ? (
                        <>
                            <div className=" w-3/5 flex">
                                <div className="  w-1/3 mr-6 ">
                                    <span className=" block font-bold text-gray-800 mb-2 ">
                                        {capitalizeFirst(t("authorities"))}
                                    </span>
                                    <UseSelectWithSearch
                                        data={{
                                            id: `${prefixId}-country-authorities-select`,
                                            name: `${prefixId}-country-authorities-select`,
                                            optionData: AUTHORITIES,
                                            selectPlaceHolder: t("select-authority"),
                                            oneSelected: true,
                                            noSelect: true,
                                            adjust: "h-11",
                                            // hideSearch: true,
                                            selectedTextAdjust: "hidden",
                                            optionsAdjust: "mt-11",
                                            hideSearch: true,
                                            selectedIds: dataGuestReport?.authorities
                                                ? [dataGuestReport?.authorities]
                                                : null,
                                            // nonInitialOptionData: true,
                                            onChange: (val) => {
                                                setDataGuestReport({ ...dataGuestReport, authorities: val });
                                            },
                                        }}
                                    />
                                </div>
                                <div className="  w-1/3 mr-6 ">
                                    <span className=" block font-bold text-gray-800 mb-2 ">
                                        {capitalizeFirst(t("user"))}
                                    </span>
                                    <input
                                        id={`${prefixId}-user-input`}
                                        type="text"
                                        className={`h-11 px-2 w-full rounded bg-gray-200 ${
                                            // validations["surname"] === false ? "border border-red-100" : "border border-transparent"
                                            ""
                                        }`}
                                        value={dataGuestReport.user}
                                        placeholder={`${t("ex")} ${"User1234"}`}
                                        onChange={(e) => {
                                            setDataGuestReport({ ...dataGuestReport, user: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="  w-1/3 mr-6 ">
                                    <span className=" block font-bold text-gray-800 mb-2 ">
                                        {capitalizeFirst(t("password"))}
                                    </span>
                                    <input
                                        type="text"
                                        id={`${prefixId}-password-input`}
                                        className={`h-11 px-2 w-full rounded bg-gray-200 ${
                                            // validations["surname"] === false ? "border border-red-100" : "border border-transparent"
                                            ""
                                        }`}
                                        value={dataGuestReport.password}
                                        placeholder={`****`}
                                        onChange={(e) => {
                                            setDataGuestReport({ ...dataGuestReport, password: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className=" flex justify-end mt-5">
                                <UseButton
                                    id={`${prefixId}-cancel-button`}
                                    action={() => {
                                        setDataGuestReport({ ...copyDataGuestReport });
                                        history.push("/#/customers/guests/general-settings");
                                        history.go(0);
                                    }}
                                    adjust={"mr-4"}
                                    buttonName={t("cancel")}
                                    buttonColor={"btn-white"}
                                />
                                <UseButton
                                    id={`${prefixId}-save-button`}
                                    action={() => {
                                        dispatch(
                                            setExecuteQuery({
                                                action: UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT,
                                                params: { data: dataGuestReport },
                                            })
                                        );
                                    }}
                                    buttonName={t("save")}
                                    buttonColor={"btn-blue"}
                                />
                            </div>
                        </>
                    ) : null}
                </div>*/}
            </div>
            {isLoading ? (
                <div className=" rounded absolute w-full h-full top-0 left-0 bg-white">
                    <UseComponentLoading />
                </div>
            ) : null}
            <UpdateAutomationGuestReport dataGuestReport={dataGuestReport} setDataGuestReport={setDataGuestReport} />
        </div>
    );
};

const UpdateAutomationGuestReport = ({ dataGuestReport, setDataGuestReport }) => {
    const { executeQuery, hasChildrenCustomFields } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = createBrowserHistory();

    const [executeMutation] = useMutation(gql(`${FAKE_MUTATION()}`), {
        onError(err) {
            toast.error(err.message);
        },
        onCompleted() {
            toast.success(t("operation-successful"));
            dispatch(closeModal());
            dispatch(cleanActionValues());
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(showGlobalLoading(false));
            if (executeQuery?.action === "UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT") {
                history.push("/#/customers/guests/general-settings");
                history.go(0);
            }
        },
    });

    useEffect(() => {
        let execute = false;
        let mutation = "";
        if (executeQuery) {
            switch (executeQuery.action) {
                case ACTIVATE_AUTOMATION_GUEST_REPORT:
                    mutation = MUTATION_UPDATE_AUTOMATION_GUEST_REPORT();
                    setDataGuestReport({ ...dataGuestReport, automaticSendingOfTheTravelersReport: true });
                    execute = true;
                    break;
                case DEACTIVATE_AUTOMATION_GUEST_REPORT:
                    mutation = MUTATION_UPDATE_AUTOMATION_GUEST_REPORT(true);
                    execute = true;
                    break;
                case UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT:
                    mutation = MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT(executeQuery.params.data);
                    execute = true;
                    break;
                case UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS:
                    mutation = MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT_FIELDS(
                        hasChildrenCustomFields,
                        values["guest-report-select"] ?? [],
                        values["children-guest-report-select"] ?? []
                    );
                    execute = true;
                    break;
                default:
                    break;
            }
            if (execute) {
                dispatch(showGlobalLoading(true));
                executeMutation({ mutation: gql(`${mutation}`) });
            }
        }
    }, [executeQuery]);

    return null;
};

export default AutomationGuestReport;
