import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";

//Components
import { Session } from "../../../hooks/Utils/Session";
import UserDropdown from "../../Header/UserDropdown";
import UseButton from "../../useButton";
import UseSelectWithSearch from "../../useSelectWithSearch";

//utils
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { toast } from "react-toastify";
import { useLazyQuery } from "react-apollo";
import { setImpersonated, setToken } from "../../../actions/authActions";

//Actions

const SelectProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    if (!Session.getSessionProp("superUser") || Session.getSessionProp("superUser") === "false") {
        history("/login");
    }

    const GET_CHAINS = gql`
        {
            chains {
                results {
                    name
                    id
                    ref
                }
            }
        }
    `;
    const [requestChains, chainsData] = useLazyQuery(GET_CHAINS, {});

    //State
    const [profile, setProfile] = useState(null);
    const [chains, setChains] = useState(null);
    const [accountError, setAccountError] = useState(false);
    const [profileFocused, setProfileFocused] = useState(null);

    //Store data
    const actionData = useSelector((state) => state.action.values);

    //Data

    useEffect(() => {
        if (chainsData?.data?.chains) {
            setChains(chainsData.data.chains.results);
        }
    }, [chainsData]);

    useEffect(() => {
        dispatch(setImpersonated(null));
        requestChains();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (actionData?.["profile_account"]?.[0]) {
            setAccountError(false);
        }
    }, [actionData]);

    const profileContinue = () => {
        if (profile === "staff") {
            if (actionData?.["profile_account"]?.[0]) {
                const chainSelected = chains.filter(
                    (chain) => parseInt(chain.id) === parseInt(actionData["profile_account"][0])
                )[0];
                Session.setSessionProp("currentAccount", JSON.stringify(chainSelected));
                Session.endImpersonation();
                history("/login-superuser");
            } else {
                toast.error(t("select a value to continue"));
                setAccountError(true);
            }
        } else if (profile === "impersonate") {
            const superUserData = JSON.parse(Session.getSessionProp("superUserData"));
            dispatch(setToken(superUserData?.token || Session.getSessionProp("token")));
            dispatch(setImpersonated(null));
            Session.endImpersonation();
            history("/impersonate-list");
        }
    };

    return (
        <>
            <div className="z-500 absolute right-0 mr-8 top" style={{ top: "2rem" }}>
                <UserDropdown />
            </div>
            <div className="absolute top-0 left-0 w-screen h-screen flex justify-center bg-white z-201 overflow-y-scroll">
                <div className={`p-8 rounded bg-white w-full block`}>
                    <span className="mx-auto">
                        <div
                            className="mx-auto"
                            style={{
                                background: `url('${Session.getDriveUrl("logo%20Zafiro%20by%20ES.png")}')`,
                                width: "235px",
                                height: "166px",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                            }}
                            alt="Logo"
                        />
                    </span>
                    <div className="text-gray-900 mt-12 mb-4 text-2xl text-center font-bold fist-capital">
                        <h2 className="first-capital">
                            {t("welcome name", { name: Session.getSessionProp("userFullname") })}
                        </h2>
                    </div>
                    <div className="text-gray-900 mt-8 mb-6 text-xl text-center fist-capital">
                        <h3 className="first-capital">{t("choose a profile to continue")}</h3>
                    </div>

                    <div className="w-full flex justify-center mt-4 text-gray-600">
                        <div
                            className={`mr-32 clickable hover:font-bold ${
                                profile === "staff" ? "text-blue-300 font-bold" : ""
                            }`}
                            onClick={(e) => setProfile("staff")}
                            onMouseEnter={(e) => setProfileFocused("staff")}
                            onMouseLeave={(e) => setProfileFocused(null)}
                            id="staff-profile-button"
                        >
                            <div
                                className={`${
                                    profileFocused === "staff" || profile === "staff"
                                        ? " border-blue-300"
                                        : "border-gray-600"
                                }  rounded p-16  clickable border-2`}
                            >
                                <div
                                    className={`icon icon-zafiro-${profile === "staff" ? "solid" : "regular"} `}
                                    style={{ fontSize: "4rem" }}
                                ></div>
                            </div>
                            <div className="first-capital text-center pt-4"> {t("ES staff")}</div>
                        </div>
                        <div
                            className={`clickable hover:font-bold ${
                                profile === "impersonate" ? "text-blue-300 font-bold" : ""
                            }`}
                            onClick={(e) => setProfile("impersonate")}
                            onMouseEnter={(e) => setProfileFocused("impersonate")}
                            onMouseLeave={(e) => setProfileFocused(null)}
                            id="impersonate-profile-button"
                        >
                            <div
                                className={`${
                                    profileFocused === "impersonate" ? "border-blue-300" : " border-gray-600"
                                }   rounded p-16 clickable border-2 `}
                            >
                                <div
                                    className={`icon icon-user${profile === "impersonate" ? "" : "-regular"} `}
                                    style={{ fontSize: "4rem" }}
                                ></div>
                            </div>
                            <div className="first-capital text-center pt-4"> {t("impersonate user")}</div>
                        </div>
                    </div>
                    {profile === "staff" ? (
                        <div className="w-full grid justify-center mt-8 ">
                            <div className="text-gray-900 first-capital mb-6 text-center"> {t("select account")}</div>
                            <div className={`${accountError ? "borderError" : ""}`} style={{ width: "30rem" }}>
                                <UseSelectWithSearch
                                    data={{
                                        oneSelected: true,
                                        noSelect: true,
                                        selectPlaceHolder: capitalizeFirst(t("select an account")),
                                        name: `profile_account`,
                                        optionData: chains,
                                        optionsAdjust: "mt-11",
                                        useOptionID: false,
                                    }}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="flex w-full justify-center mt-16 px">
                        <UseButton
                            buttonName={"continue"}
                            buttonColor={"btn-blue"}
                            adjust="w-auto px-28 mb-12"
                            disabled={
                                !profile ||
                                (profile === "staff" &&
                                    (!actionData["profile_account"] ||
                                        (actionData["profile_account"] && !actionData["profile_account"][0])))
                            }
                            action={profileContinue}
                            id={"continue-profile-button"}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SelectProfile;
