import React from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";

export const LogOutUsers = () => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { executeMutation } = useLogOutUsers();

    return (
        <Modal
            title={t("reset-wifi-licenses")}
            onClose={close}
            footer={
                <div className="flex justify-end gap-4">
                    <Button id="cancel" className="btn-white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="log-out"
                        design="red"
                        onClick={() => {
                            executeMutation().then(() => {
                                toast.success(t("operation-successful"));
                                close();
                            });
                        }}
                    >
                        {t("log-out")}
                    </Button>
                </div>
            }
        >
            <p className="mt-4" dangerouslySetInnerHTML={{ __html: t("reset-wifi-licenses-modal-text") }}></p>
        </Modal>
    );
};

const useLogOutUsers = () => {
    const LOG_OUT_USERS = gql`
        mutation {
            deleteWifiUsers {
                id
                ok
                error
            }
        }
    `;
    const [executeMutation] = useMutation(LOG_OUT_USERS);

    return { executeMutation };
};
