import Login from "../components/Section/Auth/Login";
import LoginSuperUser from "../components/Section/Auth/LoginSuperUser";
import LoginImpersonate from "../components/Section/Auth/LoginImpersonate";
import ResetPassword from "../components/Section/Auth/ResetPassword";
import SelectProject from "../components/Section/Auth/SelectProject";
import SelectProfile from "../components/Section/Auth/SelectProfile";
import ImpersonateList from "../components/Section/Auth/ImpersonateList";
import Policy from "../components/Section/Auth/Policy";

const mainSection = [
    {
        path: "/login",
        section: Login,
        hideSidebar: true,
        hideHeader: true,
    },

    {
        path: "/login-superuser",
        section: LoginSuperUser,
        hideSidebar: true,
        hideHeader: true,
    },

    {
        path: "/login-impersonate",
        section: LoginImpersonate,
        hideSidebar: true,
        hideHeader: true,
    },
];

export const authRoutes = [
    {
        path: "/reset-password",
        section: ResetPassword,
    },
    {
        path: "/select-project",
        section: SelectProject,
        hideSidebar: true,
        hideHeader: true,
    },
    {
        path: "/select-profile",
        section: SelectProfile,
        hideSidebar: true,
        hideHeader: true,
    },
    {
        path: "/impersonate-list",
        section: ImpersonateList,
        hideSidebar: true,
        hideHeader: true,
    },
    {
        path: "/privacy-policy",
        section: Policy,
        hideSidebar: true,
        hideHeader: true,
    },
    {
        path: "/policy",
        section: Policy,
        hideSidebar: true,
        hideHeader: true,
    },
    ...mainSection,
];
