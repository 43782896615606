import ContractedProducts from "../components/Section/Info/ContractedProducts";
import { Session } from "../hooks/Utils/Session";
import { newRoute, newRedirect } from "./utils";

const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";

const sectionID = "information";

const route = newRoute(sectionID, null);
const redirect = newRedirect(sectionID);

export const infoRoutes = [
    route("/", {
        redirect: redirect("/my-account/contracted-products"),
    }),

    !isCorporate &&
        route("/my-account", {
            redirect: redirect("/my-account/contracted-products"),
        }),
    !isCorporate &&
        route("/my-account/contracted-products", {
            section: ContractedProducts,
        }),

    route("/legal-matters", {
        redirect: redirect("/legal-matters/privacy-policy"),
    }),
];
