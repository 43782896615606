import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setColumns,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setActions,
    setHeaderButtons,
    setFormNewContent,
    setCustomError,
    cleanActiveFilters,
    setRefreshData,
} from "../../../actions/tableActions";
//Model
import Staff from "./models/Staff";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import { getProjectLangs } from "hooks/Utils/SalesUtils";

const ListStaff = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { langStrings, projectLangs, permissions } = useSelector((state) => state.ui);

    //Data
    const itemType = "list-staff";

    //States
    //reset sorting

    const [sorting] = useState(`,orderBy:{field: "ID", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();

    const { langDefault } = getProjectLangs(projectLangs, t);

    //API
    const GET_STAFF_INFO = gql`{
    staffUsers: staffUsers(page: ${currentPage}, size: ${perPage}${
        sortCriteria ? sortCriteria : sorting
    }${filterQuery}) {
      info { count }
      results { ref description email fullName id roles{ id }
      staffShops {
        id
        name
      }
       staffAreas{id name ref}}}
    commonzones: commonzones(page: 1, size: 10000) {
        results {
         id,
         name,
         parentCategoryName
        }
      }
    }`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_STAFF_INFO, {
        pollInterval: 300000,
        errorPolicy: "all",
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setHeaderButtons(GetTableHeaderButton(itemType)));
        if (!loading && data && data.staffUsers) {
            //Get data
            const dataItems = arrangeData(data, langStrings, langDefault);
            const tableHeaders = GetTableHeaders(itemType);
            const availableFilters = GetAvailableFilters(itemType);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.staffUsers.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setFormNewContent(GetTableForm(itemType)));
            dispatch(setCustomError(t("no staff members available")));

            if (dataItems.length > 0) {
                //Column adjust if there are cell with only icon on it
                if (dataItems[0].withAction) {
                    dispatch(setColumns(dataItems[0].info.length - 1));
                } else {
                    dispatch(setColumns(dataItems[0].info.length));
                }
                if (dataItems[0].actions) {
                    dispatch(setActions());
                }
            }
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        // dispatch(setError(error));
        dispatch(setLoading(false));
        // eslint-disable-next-line
    }, [error]);
};

const arrangeData = (data, langStrings, langDefault) => {
    const tempArray = [];
    // eslint-disable-next-line
    data.staffUsers.results.map((user) => {
        tempArray.push(
            Staff(
                user,
                langStrings,
                data?.commonzones?.results ? data?.commonzones?.results : [],
                data?.shops?.results ? data?.shops?.results : [],
                langDefault
            )
        );
    });
    return tempArray;
};

export default ListStaff;
