import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import { useParams } from "react-router-dom";

import { setDroppingItem, setDesignToolbarVisible } from "../../../actions/designActions";
import { cleanGridPlaceholder } from "../../../hooks/Utils/DesignUtils";
import { Session } from "../../../hooks/Utils/Session";
import { DefaultWidgetSizes, WidgetAvailability } from "../../../hooks/Utils/Widgets";
import { SCREEN } from "../../../hooks/Utils/Design/EditorUtils";
import { normalizeToSearch } from "../../../hooks/Utils/Utils";
import UseSearch from "../../useSearch";

const WidgetToolbar = ({ setVisible }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { type: screenType } = useParams();

    const {
        widgets,
        designToolbarVisible,
        cDevice,
        cScreenData,
        editorMode,
        editingMenu,
        gridLayout,
        containersLayouts,
    } = useSelector((state) => state.design);

    const { permissions } = useSelector((state) => state.ui);

    const [search, setSearch] = useState(null);
    const [widgetsToRender, setWidgetsToRender] = useState([]);

    //Listeners
    useEffect(() => {
        if (designToolbarVisible === "widgets") {
            const widgetToolbar = document.querySelector("#widgetToolbar");
            const widgetToolbarPosis = widgetToolbar.getClientRects()[0];
            widgetToolbar.style.maxHeight = `${window.innerHeight - widgetToolbarPosis.y}px`;
        }
        // eslint-disable-next-line
    }, [designToolbarVisible]);

    useEffect(() => {
        setWidgetsToRender(widgetsToRenderByPermissions(widgets, permissions.widgets));
        // eslint-disable-next-line
    }, [screenType]);

    useEffect(() => {
        if (search) {
            const widgetsFiltersBySearch = widgetsToRender.filter((widget) =>
                normalizeToSearch(t(widget.name).toLowerCase()).includes(normalizeToSearch(search))
            );
            setWidgetsToRender(widgetsToRenderByPermissions(widgetsFiltersBySearch, permissions.widgets));
        } else if (search === "") {
            setWidgetsToRender(widgetsToRenderByPermissions(widgets, permissions.widgets));
        }
        // eslint-disable-next-line
    }, [search]);

    const widgetsToRenderByPermissions = (widgets, permissionsWidgets) => {
        widgets.forEach((widget) => {
            if (!permissionsWidgets.includes(widget.type)) {
                widget.hidden = true;
            }
        });
        return widgets;
    };

    const existVideoOnScreen = () => {
        const videoOnScreen = gridLayout?.find((gLayout) => gLayout.type === "VIDEO");
        let videoOnContainer = false;

        containersLayouts &&
            containersLayouts.forEach((containerLayout) => {
                containerLayout.layout.forEach((item) => {
                    if (item.type === "VIDEO") {
                        videoOnContainer = true;
                    }
                });
            });

        return videoOnScreen || videoOnContainer;
    };

    return (
        <div className={`z-200 left-0 contents h-full bg-white`}>
            <div className="border-b mb-2 flex items-center justify-between border-gray-200 px-8 py-4 align-middle text-base">
                <div className="first-capital font-bold  ">{t("widgets")}</div>
                <div
                    className="icon icon-remove sidebar-submenu-link cursor-pointer text-3xl text-gray-800"
                    onClick={(e) => setVisible(null)}
                />
            </div>
            <div className=" px-6 py-2">
                <UseSearch
                    value={search ? search : ""}
                    onChange={setSearch}
                    id="search-input-widgets"
                    placeholder={"search-for"}
                />
            </div>
            <div
                id="widgetToolbar"
                className={`grid gap-4 overflow-x-hidden overflow-y-scroll bg-white p-8 pb-12  pt-6 
                ${screenType === SCREEN.TYPE.PORTAL ? " grid-cols-3" : "grid-cols-2"} `}
            >
                {widgetsToRender.map((widget, index) => {
                    if (!widget.type || widget.hidden) {
                        return false;
                    }
                    const deviceType = cDevice ? cDevice.type : null;
                    let { disabled, reason, hidden } = WidgetAvailability({
                        widgetType: widget.type,
                        deviceType,
                        screenType,
                        i18n: t,
                        isEditingTVMenu: editingMenu,
                    });
                    if (widget.type === "VIDEO" && existVideoOnScreen()) {
                        disabled = true;
                        reason = t("only-one-video");
                    }
                    return !hidden ? (
                        <div
                            className="droppable-element sidebar-submenu-link flex cursor-move flex-col items-center p-4 "
                            key={`widget-toolbar-${index}`}
                            draggable={!disabled}
                            unselectable="on"
                            onDragEnd={() => {
                                cleanGridPlaceholder();
                                if (
                                    designToolbarVisible === "widgets" &&
                                    (deviceType === "TV" || deviceType === "Desktop") &&
                                    editorMode === "EDITION"
                                ) {
                                    dispatch(setDesignToolbarVisible(null));
                                }
                            }}
                            onDragStart={() => {
                                const { w, h } = DefaultWidgetSizes({
                                    widgetType: widget.type,
                                    deviceType,
                                    screenDevices: cScreenData ? cScreenData.data.devices : null,
                                });
                                dispatch(setDroppingItem({ i: "-1", type: widget.type, w, h }));
                                Session.setSessionProp("widgetTypeDrag", widget.type);
                                if (
                                    designToolbarVisible === "widgets" &&
                                    (deviceType === "TV" || deviceType === "Desktop") &&
                                    editorMode === "EDITION"
                                ) {
                                    const widgetToolbar = document.querySelector("#widgets_toolbar");
                                    widgetToolbar.style.width = "0px";
                                }
                            }}
                            data-tip
                            data-for={`${index}_widgets_tooltip`}
                            style={
                                disabled
                                    ? {
                                          color: "#BDC8D3",
                                      }
                                    : null
                            }
                        >
                            <div className={`icon icon-${widget.icon} pb-3 text-3xl`}></div>
                            <div className={`first-capital ${screenType === SCREEN.TYPE.PORTAL ? " text-sm" : ""}`}>
                                {t(widget.name)}
                            </div>
                            {disabled ? (
                                <ReactTooltip
                                    id={`${index}_widgets_tooltip`}
                                    delayShow={300}
                                    type={"light"}
                                    borderColor={"#D3DAE1"}
                                    border={true}
                                    multiline={true}
                                >
                                    {reason}
                                </ReactTooltip>
                            ) : null}
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default WidgetToolbar;
