import { routes } from "../../routes";
import { useLocation, matchPath } from "react-router-dom";

const DisplayMenuSidebar = () => {
    const location = useLocation();
    let menuDisplay = {
        hideHeader: false,
        hideSidebar: false,
        style: { x: "px-14 w-5/6", y: "py-10 section-top" },
    };
    routes
        .filter((route) => typeof route.section !== "undefined")
        .forEach((route) => {
            const match = route.path ? matchPath({ path: route.path, end: true }, location.pathname) : false;
            if (match && route.hideSidebar) {
                menuDisplay = {
                    ...menuDisplay,
                    hideSidebar: true,
                    style: { ...menuDisplay.style, x: `px-0 w-full ${route.hideHeader ? "h-full" : ""}` },
                };
            }
            if (match && route.hideHeader) {
                menuDisplay = { ...menuDisplay, hideHeader: true, style: { ...menuDisplay.style, y: "py-0 top-0" } };
            }
        });
    return menuDisplay;
};

export default DisplayMenuSidebar;
