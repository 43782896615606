import React, { useState, useContext, useEffect, useRef } from "react";
import { GlobalContext } from "contexts/Global";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import ReactTooltip from "components/ReactTooltip";
import useComponentVisible from "components/useComponentVisible";
import InputMask from "react-input-mask";

export const DateInput = ({
    tooltip,
    placeholder,
    validation,
    disabled,
    onChange,
    value,
    customDisabledDays = [],
    autoClose = true,
    type,
}) => {
    const { lang } = useContext(GlobalContext);
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const dayPickerRef = useRef(null);

    function formatDate(date) {
        if (date) {
            const day = String(date?.getDate())?.padStart(2, "0");
            const month = String(date?.getMonth() + 1).padStart(2, "0");
            const year = date?.getFullYear();
            if (type === "hyphen") {
                return `${day ?? "__"}-${month ?? "__"}-${year ?? "_____"}`;
            }
            return `${day ?? "__"}/${month ?? "__"}/${year ?? "_____"}`;
        } else {
            return `__/__/____`;
        }
    }

    const [preventTextDate, setPreventTextDate] = useState(formatDate(value));
    const [date, setDate] = useState(null);

    const handleDayClick = (day, modifiers) => {
        if (!modifiers?.disabled) {
            setPreventTextDate(formatDate(day));
            setDate(day);
            onChange(day, formatDate(day));
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (dayPickerRef.current && !dayPickerRef.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        if (autoClose) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            if (autoClose) {
                document.removeEventListener("mousedown", handleClickOutside);
            }
        };
    }, []);

    useEffect(() => {
        if (isComponentVisible && tooltip) {
            const getDisabledElements = document.getElementsByClassName("DayPicker-Day--disabled");
            for (let i = 0; i < getDisabledElements.length; i++) {
                getDisabledElements[i].setAttribute("data-for", "disabled-days-tooltip");
                getDisabledElements[i].setAttribute("data-tip", "");
            }
            ReactTooltip.rebuild();
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (value) {
            setDate(value);
            setPreventTextDate(formatDate(value));
        }
    }, [value]);

    return (
        <div className="flex flex-col relative">
            {tooltip && (
                <ReactTooltip
                    id={`disabled-days-tooltip`}
                    type="light"
                    border={true}
                    borderColor="text-gray-200"
                    arrowColor="text-gray-200"
                    className="font-normal"
                    place="bottom"
                >
                    <p>{tooltip}</p>
                </ReactTooltip>
            )}
            <div className="relative z-500">
                <InputMask
                    mask={"99/99/9999"}
                    className={`t-filter-input flex items-center py-3 pl-10 pr-4 mt-2 mr-5 w-full ${
                        !value ? "text-gray-600" : ""
                    } ${validation ? "border border-red-100" : ""}  ${disabled ? "bg-gray-300" : ""}`}
                    value={preventTextDate}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setPreventTextDate(e.target.value);
                        if (!e.target.value.includes("_")) {
                            const values = e.target.value.split("/");
                            setDate(new Date(`${values[1]}-${values[0]}-${values[2]}`));
                            onChange(new Date(`${values[1]}-${values[0]}-${values[2]}`), e.target.value);
                        } else {
                            onChange(value, e.target.value);
                        }
                    }}
                    onFocus={() => {
                        setIsComponentVisible(true);
                    }}
                    tabIndex={0}
                />
                <i className=" absolute top-6 left-4 icon icon-booking mr-2 font-black text-gray-700"></i>
                {isComponentVisible && (
                    <div style={{ position: "fixed" }} ref={dayPickerRef}>
                        <DayPicker
                            firstDayOfWeek={1}
                            fixedWeeks
                            className="Selectable"
                            onDayClick={handleDayClick}
                            numberOfMonths={1}
                            localeUtils={MomentLocaleUtils}
                            selectedDays={[date]}
                            locale={lang}
                            disabledDays={customDisabledDays}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
