import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setAvailableFilters,
    setCheckable,
    setCustomError,
    setBatchActive,
    setCustomConfig,
    setFolder,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetServicesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";
import { POLLINTERVAL_15S, parseBoolean } from "../../Utils/Utils";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { CORPORATE_CHANNEL, CUSTOMISED_CHANNEL, TUNE_TYPES } from "../../Utils/Services/ChannelsUtils";
import Channel from "./models/Channel";
import _ from "lodash";

import { GlobalContext } from "contexts/Global";

//helpers
import { Session } from "../../Utils/Session";

const ListChannels = () => {
    //Consts&states
    const { t } = useTranslation();
    const { lang } = useContext(GlobalContext);
    const dispatch = useDispatch();
    const [applyFilters, setApplyFilters] = useState("");
    const { currentPage, perPage, sortCriteria, filterQuery, refreshData, folder } = useSelector(
        (state) => state.table
    );
    const { permissions } = useSelector((state) => state.ui);
    const hasCorporateUser = parseBoolean(Session.getSessionProp("hasCorporateUser"));
    const hasCorporateChannels = permissions.services.corporateChannels ? true : false;
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const sectionName = `list-channels-${folder ? folder.name : ""}`;
    const folders =
        hasCorporateUser || (!hasCorporateUser && hasCorporateChannels)
            ? [
                  { ...CUSTOMISED_CHANNEL, adjust: "w-44" },
                  { ...CORPORATE_CHANNEL, adjust: "w-44" },
              ]
            : [{ ...CUSTOMISED_CHANNEL, adjust: "w-44" }];
    const hasChannelPremium = permissions?.services?.multipleGrids;
    const corporateChannelsWithoutGlobalManagement = permissions?.services?.corporateChannelsWithoutGlobalManagement;

    //queries&mutations
    const GET_DATA = gql`
        {
            channels: ${
                folder && folder.value === CORPORATE_CHANNEL.value
                    ? "corporateTunesSummary"
                    : "customAndPresetTunesSummary"
            } (page: ${currentPage}, size: ${perPage} ${
        sortCriteria ? sortCriteria : `orderBy: { field: "name", criteria: "asc" }`
    } ${applyFilters}){
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    name
                    logoRef
                    type
                    tuneInfo
                    groups {
                        groupID
                        name
                    }
                    grids {
                        gridID
                        name
                    }
                    ${folder && folder.value !== CORPORATE_CHANNEL.value ? "isRadio" : ""}
                }
            }
        }
    `;
    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DATA, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    //effects
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        dispatch(setSearchable(true));
        dispatch(setCountPosition("table-header"));
        dispatch(setCheckable(true));
        dispatch(setActions(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setFolder(folders[0]));
        dispatch(setTableType("list-channels"));
        dispatch(setCustomConfig({ folders: folders }));
        dispatch(
            setCustomError(
                folder && folder.name === CORPORATE_CHANNEL.name
                    ? t("not-corporate-channels-yet")
                    : t("not-channels-yet")
            )
        );
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        let currentFilters = arrangeFilters(activeFilters);
        setApplyFilters(`, filter:{${currentFilters}}`);
        // eslint-disable-next-line
    }, [filterQuery]);

    useEffect(() => {
        if (!loading && !error && data && data.channels && networkStatus === 7) {
            dispatch(setLoading(true));
            const dataItems = arrangeData(data, folder.value, t);
            const tableHeaders = GetTableHeaders(sectionName, { folder: folder.value });
            const availableFilters = GetAvailableFilters(sectionName, { folder: folder.value });
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(
                setHeaderButtons(
                    GetTableHeaderButton(sectionName, {
                        folder: folder.value,
                        dataItemsCount: dataItems.length,
                        hasChannelPremium: hasChannelPremium,
                        corporateChannelsWithoutGlobalManagement: corporateChannelsWithoutGlobalManagement,
                    })
                )
            );
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.channels.info.count));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(cleanActiveFilters());
        dispatch(setLoading(true));
        dispatch(setTableResults([]));
    }, [folder]);

    //arrangeData
    const arrangeData = (data, folder, t) => {
        const items = [];
        data.channels.results.forEach((channel) => {
            items.push(Channel(channel, folder, t, lang));
        });
        return items;
    };

    const arrangeFilters = (othersFilters) => {
        let currentFilters = "";
        if (_.get(othersFilters, "isRadio")) {
            let isRadio = _.get(othersFilters, "isRadio").toLowerCase() === "radio";
            currentFilters += `isRadio:${isRadio},`;
            delete othersFilters["isRadio"];
        }
        if (_.get(othersFilters, "tuneType")) {
            let tuneTypeValue = "";
            Object.keys(TUNE_TYPES).map((objectKey) => {
                if (TUNE_TYPES[objectKey].label === _.get(othersFilters, "tuneType")) {
                    tuneTypeValue = TUNE_TYPES[objectKey].value;
                }
            });
            if (tuneTypeValue) {
                currentFilters += `tuneType:"${tuneTypeValue}",`;
            }
            delete othersFilters["tuneType"];
        }
        if (_.get(othersFilters, "channels")) {
            delete othersFilters["channels"];
        }
        if (!_.isEmpty(othersFilters)) {
            for (const [key, value] of Object.entries(othersFilters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        return currentFilters;
    };

    //handleFunctions
};

export default ListChannels;

const BatchActions = () => {
    const actions = [
        {
            name: "delete",
            action: "batch-delete-channels",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-channels-confirm",
                },
                {
                    type: "deleteBatchChannelsDetail",
                    style: "",
                    elementsType: "group",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "batch-delete-channels" },
            ],
        },
    ];
    return actions;
};
