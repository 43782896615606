import Nationalities from "../../lang/es/nationality.json";
import NationalitiesEN from "../../lang/en/nationality.json";

export const GetNationalitiesOptions = () => {
    const lang = localStorage.getItem("lang");

    let countries = [];

    for (let i in Nationalities) {
        if (lang === "es") {
            countries.push({ id: i.toUpperCase(), name: Nationalities[i] });
        } else if (lang === "en") {
            countries.push({
                id: i.toUpperCase(),
                name: NationalitiesEN[i.toLowerCase()],
            });
        }
    }

    return countries;
};

export const GetNationalityName = (key, lang) => {
    if (!key) {
        return null;
    }
    const currentLang = lang ? lang : localStorage.getItem("lang");

    return currentLang === "es" ? Nationalities[key.toLowerCase()] : NationalitiesEN[key.toLowerCase()];
};
