import React from "react";

import SalesProvider from "contexts/Sales";
import { SalesCategoriesProvider } from "./SalesContext";

import EditShopCategories from "./EditShopCategories";

const EditShopCategoriesParent = () => {
    return (
        <SalesProvider>
            <SalesCategoriesProvider>
                <EditShopCategories />
            </SalesCategoriesProvider>
        </SalesProvider>
    );
};

export default EditShopCategoriesParent;
