import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { changeActionValues } from "../actions/globalActions";
import { showGlobalLoading } from "../actions/uiActions";

//Components

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import { useTranslation } from "react-i18next";

const UseTranslationTextArea = ({ textId, parentType }) => {
    const { t } = useTranslation();

    const { projectLangs } = useSelector((state) => state.ui);

    //Data
    const charLimit = 250;

    //API
    const GET_LANGS = gql`
        {
            projectLangs {
                results {
                    isDefault
                    languageRef
                }
            }
            ${
                textId
                    ? `
            translations(id: ${textId} parentType:${parentType}){
                lang
                text
            }
            `
                    : ""
            }
        }
    `;

    const [getLangsQuery, { data }] = useLazyQuery(GET_LANGS, {
        fetchPolicy: "network-only",
    });

    //States
    const [langs, setLangs] = useState(null);
    const [defaultLang, setDefaultLang] = useState(null);
    const [texts, setTexts] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        if (e.target.value.length > charLimit) {
            return;
        }
        dispatch(
            changeActionValues({
                [e.target.id]: e.target.value.replace(/(\r\n|\n|\r)/gm, ""),
            })
        );

        let textCopy = JSON.parse(JSON.stringify(texts));
        let updated = false;
        textCopy.map((text) =>
            text.lang === e.target.id
                ? (((text.text = e.target.value.replace(/(\r\n|\n|\r)/gm, "")), (text.length = e.target.value.length)),
                  (updated = true))
                : null
        );
        if (!updated) {
            textCopy.push({
                lang: e.target.id,
                text: e.target.value.replace(/(\r\n|\n|\r)/gm, ""),
                length: e.target.value.replace(/(\r\n|\n|\r)/gm, "").length,
            });
        }
        setTexts(textCopy);
    };

    //Listeners
    useEffect(() => {
        getLangsQuery();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.projectLangs && data.projectLangs.results) {
            setLangs(projectLangs);
            setDefaultLang(projectLangs.filter((lang) => lang.isDefault)[0].languageRef);

            data.translations.map((trans) => (trans.length = trans.text.length));

            setTexts(data.translations ? data.translations : []);
            dispatch(showGlobalLoading(false));
        }

        // eslint-disable-next-line
    }, data);

    return langs ? (
        <div className="grid grid-cols-2 col-gap-4">
            <div className="border-r border-gray-200">
                <div>
                    <div className="first-capital pb-4 text-lg font-bold">{t("default-lang")}</div>
                </div>
                <div className="w-full mb-4 flex pt-2 pr-4">
                    <div className="w-3/12 p-2 float-left font-bold my-auto">
                        {t("language:" + langs.filter((lang) => lang.isDefault)[0].languageRef)}
                    </div>
                    <textarea
                        id={`${defaultLang}`}
                        min="0"
                        max="250"
                        required
                        placeholder={t("write-description")}
                        value={
                            texts.filter((text) => text.lang === defaultLang).length > 0
                                ? texts.filter((text) => text.lang === defaultLang)[0].text
                                : ""
                        }
                        className={`w-10/12 float-left bg-gray-200 rounded p-2 hover:text-zafiro-400 h-32`}
                        onChange={(e) => handleChange(e)}
                    ></textarea>
                </div>
                <p className="text-sm text-gray-800 -mt-3 text-right pr-4 ">
                    {`${
                        texts.filter((text) => text.lang === defaultLang)[0]
                            ? texts.filter((text) => text.lang === defaultLang)[0].length
                            : 0
                    }/${charLimit} ${t("chars")}`}
                </p>
            </div>
            <div>
                <div>
                    <div className="first-capital pb-4 text-lg font-bold">{t("translations")}</div>
                </div>
                <div className=" max-h-40 overflow-scroll p-4 border-gray-300 border rounded">
                    {langs.map((lang) =>
                        lang.isDefault ? null : (
                            <>
                                <div className="w-full mb-4 flex">
                                    <div className="w-3/12 p-2 float-left font-bold my-auto">
                                        {t("language:" + lang.languageRef)}
                                    </div>
                                    <textarea
                                        id={`${lang.languageRef}`}
                                        rows="4"
                                        min="0"
                                        max="250"
                                        required
                                        placeholder={getTranslationsText({texts, lang, defaultLang, t})}
                                        value={
                                            texts.filter((text) => text.lang === lang.languageRef).length > 0
                                                ? texts.filter((text) => text.lang === lang.languageRef)[0].text
                                                : null
                                        }
                                        className={`w-10/12 float-left bg-gray-200 rounded p-2 hover:text-zafiro-400 h-32 phNoTransform `}
                                        onChange={(e) => handleChange(e)}
                                    ></textarea>
                                </div>
                                <p className="text-sm text-gray-800 -mt-3 mb-4 text-right ">
                                    {`${
                                        texts.filter((text) => text.lang === lang.languageRef)[0]
                                            ? texts.filter((text) => text.lang === lang.languageRef)[0].length
                                            : 0
                                    }/${charLimit} ${t("chars")}`}
                                </p>
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    ) : (
        <div className="text-center">{""}</div>
    );
};

export default UseTranslationTextArea;

const getTranslationsText = (props = {}) => {
    const {texts, lang, defaultLang, t} = props;
    let response = t("enter-translation-here");
    if( texts.filter((text) => text.lang === lang.languageRef).length === 0){
        if(texts.filter((text) => text.lang === defaultLang).length > 0){
            if(texts.filter((text) => text.lang === defaultLang)[0].text.length>0){
                response = texts.filter((text) => text.lang === defaultLang)[0].text;
            }
        }
    }
    return response;
}