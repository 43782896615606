import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
import { multipleTranslate } from "../../hooks/Utils/Utils";
import { gql } from "apollo-boost";
import { useMSQuery } from "../../hooks/GraphqlCalls/useMSQuery";
import { setLoadingModalContent } from "../../actions/uiActions";

//Utils
import Parser from "hooks/Utils/Parser";

const UseModalCheckbox = ({ index }) => {
    //Store data
    const {
        id,
        checked,
        name,
        instructions,
        textActive,
        textInactive,
        textActiveAdjust,
        textInactiveAdjust,
        textAdjust,
        adjust,
        cStyle,
        noDisable,
        validationShow,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);

    const { langStrings } = useSelector((state) => state.ui);

    //Actions
    const dispatch = useDispatch();

    //State
    const [isActive, setIsActive] = useState(checked);
    const [text, setText] = useState(name);
    const [textStyle, setTextStyle] = useState(textAdjust);
    const [checkboxActive, setCheckboxActive] = useState({
        checked: false,
        visible: "hidden",
        background: "block",
        text: "",
    });
    const [disaplay, setDisplay] = useState(true);

    //API
    const GET_SHOW_QUERY =
        validationShow && validationShow.query
            ? gql`
                  ${validationShow.query}
              `
            : null;
    const [getShowQuery, { data, networkStatus }] = useMSQuery(GET_SHOW_QUERY, {
        fetchPolicy: "network-only",
    });

    const clickHandler = (e) => {
        const switchValue = isActive ? false : true;
        dispatch(changeActionValues({ [id]: switchValue }));
        setIsActive(switchValue);
    };

    //Listeners

    useEffect(() => {
        let textConfig = null;
        if (checked) {
            dispatch(changeActionValues({ [id]: true }));
            textConfig = arrangeText({ text: textActive, textStyle: textActiveAdjust });
        } else {
            textConfig = arrangeText({ text: textInactive, textStyle: textInactiveAdjust });
        }
        if (textConfig?.text) {
            setText(textConfig.text);
        }
        if (textConfig?.textStyle) {
            setTextStyle(textConfig.textStyle);
        }
        //get options from query
        if (validationShow && validationShow.query) {
            setDisplay(false);
            dispatch(setLoadingModalContent(true));
            getShowQuery();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (networkStatus === 7 && data && data.data) {
            dispatch(setLoadingModalContent(false));
            if (!data.data.results || data.data.results.length < 1) {
                dispatch(changeActionValues({ [id]: false }));
                setIsActive(false);
            } else {
                setDisplay(true);
            }
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        let textConfig = null;
        if (isActive) {
            textConfig = arrangeText({ text: textActive, textStyle: textActiveAdjust });
            setCheckboxActive({
                checked: true,
                visible: "block",
                background: "hidden",
                text: "text-blue-300",
            });
        } else {
            if (!noDisable) {
                setCheckboxActive({
                    checked: false,
                    visible: "hidden",
                    background: "block",
                    text: "",
                });
                textConfig = arrangeText({ text: textInactive, textStyle: textInactiveAdjust });
            }
        }
        if (textConfig?.text) {
            setText(textConfig.text);
        }
        if (textConfig?.textStyle) {
            setTextStyle(textConfig.textStyle);
        }
        // eslint-disable-next-line
    }, [isActive]);

    return (
        disaplay && (
            <div className={`w-full h-10 ${cStyle}`}>
                {instructions ? (
                    <span className="block pb-2 first-capital">
                        {Parser(multipleTranslate(instructions, langStrings))}
                    </span>
                ) : null}
                <div className="block relative mb-4 cursor-pointer">
                    <div className={`ml-8  first-capital text-left ${textStyle}`} onClick={clickHandler}>
                        {Parser(langStrings[text] ? langStrings[text] : text)}
                    </div>
                    <div className={`${adjust}`}>
                        <input
                            id="checkbox"
                            type="checkbox"
                            className={`absolute top-0 left-0 w-5 h-5 checkbox cursor-pointer z-50 ${checkboxActive.visible}`}
                            checked={checkboxActive.checked}
                            onChange={clickHandler}
                        ></input>
                        <span
                            id="checkbox-background"
                            className={`absolute top-0 left-0 w-5 h-5 bg-gray-200 hover:bg-gray-300 ${checkboxActive.background}`}
                            onClick={clickHandler}
                        ></span>
                    </div>
                </div>
            </div>
        )
    );
};

const arrangeText = (props) => {
    return {
        text: props?.text,
        textStyle: props?.textStyle,
    };
};

export default UseModalCheckbox;
