import Analytics from "../components/Section/Statistics/Analytics";
import StatsSidebar from "../components/Sidebar/StatsSidebar";
import { newRoute } from "./utils";

const sectionID = "stats";
const packageID = "stats";

const attrCast = "cast";
const attrWifi = "wifi";
const attrInternal = "internal";
const attrMobile = "mobile";
const attrSales = "sales";

const route = newRoute(sectionID, packageID);

export const statsRoutes = [
    route("/", {
        section: Analytics,
        sidebar: StatsSidebar,
    }),

    route("/sales", {
        section: Analytics,
        sidebar: StatsSidebar,
        attribute: attrSales,
    }),

    route("/cast", {
        section: Analytics,
        sidebar: StatsSidebar,
        attribute: attrCast,
    }),

    route("/wifi", {
        section: Analytics,
        sidebar: StatsSidebar,
        attribute: attrWifi,
    }),

    route("/mobile", {
        section: Analytics,
        sidebar: StatsSidebar,
        attribute: attrMobile,
    }),

    route("/internal", {
        section: Analytics,
        sidebar: StatsSidebar,
        attribute: attrInternal,
    }),

    route("/:type/:id", {
        section: Analytics,
        sidebar: StatsSidebar,
    }),
    route("/:type/:id/:sub", {
        section: Analytics,
        sidebar: StatsSidebar,
    }),
];
