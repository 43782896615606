import DeleteLanguageModal from "../modals/DeleteLanguageModal";
import UpdateLanguageModal from "../modals/UpdateLanguageModal";

const Language = (language, name, isCorporate) => {
    const { dataType, isDefault, languageRef } = language;
    let isDefaultCell = {
        name: "isDefault",
        cellContent: "labels",
        value: [[1, "default", "#47A1FF"]],
        isAction: false,
        specialWidth: isCorporate ? "w-6/12" : "w-5/12",
    };
    if (!isDefault) {
        isDefaultCell = {};
        if (!isCorporate) {
            isDefaultCell = {
                name: "update-language",
                cellContent: "edit-modal",
                contentAdjust: "cursor-pointer text-zafiro-600",
                value: "make-default",
                translate: true,
                type: dataType,
                evaluate: true,
                isAction: true,
                modal: UpdateLanguageModal(language, name),
                specialWidth: "w-5/12",
            };
        }
    }
    let row = [];
    row.push({
        name: "lang",
        cellContent: "text",
        value: name,
        specialWidth: "w-6/12",
    });
    row.push(isDefaultCell);
    if (!isCorporate) {
        row.push({
            name: "delete-language",
            cellContent: "icon",
            value: languageRef,
            evaluate: true,
            isAction: !isDefault ? true : false,
            specialWidth: "w-1/12",
            icon: {
                name: "delete",
                values: languageRef,
                itemType: dataType,
                adjust: !isDefault ? "text-black" : "text-gray-300",
            },
            popover: false,
            translatePopover: true,
            modal: !isDefault ? DeleteLanguageModal(language, name) : false,
        });
    }
    return {
        dataType: dataType,
        withAction: true,
        info: row,
    };
};
export default Language;
