import React from "react";
import { EditAlarm } from "components/Section/Customers/EditAlarm";
import {
    createRoomNumbersLabels,
    getDate,
    getEditedBy,
    getExpandedRow,
    getHour,
    getLastUpdate,
} from "../utils/alarmsUtils";
import { DeleteAlarm } from "components/Section/Customers/DeleteAlarm";

export const AlarmModel = (alarm, t, open, deleteAlarm, refetch, setLoadingRefresh) => {
    const {
        stayguestName,
        stayguestSurname,
        rooms,
        startTime,
        state,
        isFromGuest,
        lastUpdate,
        channelName,
        type,
        groupingID,
        tvChannelId,
    } = alarm;

    const AlarmModel = {
        id: groupingID,
        guest: `${stayguestName} ${stayguestSurname}`,
        rooms: createRoomNumbersLabels(rooms)[0],
        hour: getHour(startTime),
        date: <p alt={new Date(startTime).getMonth()}>{getDate(startTime)}</p>,
        "last-update": getLastUpdate(lastUpdate),
        edited: getEditedBy(isFromGuest, t),
        rowConfig: {
            expanded: getExpandedRow(rooms, type, t, channelName),
            actions:
                state !== "PENDING"
                    ? []
                    : [
                          {
                              id: "edit",
                              label: "Edit",
                              onClick: () =>
                                  open(
                                      <EditAlarm
                                          stayguestName={stayguestName}
                                          stayguestSurname={stayguestSurname}
                                          rooms={rooms}
                                          type={type}
                                          tvChannelId={tvChannelId}
                                          startTime={startTime}
                                          groupingID={groupingID}
                                          refetch={refetch}
                                          setLoadingRefresh={setLoadingRefresh}
                                      />
                                  ),
                          },
                          {
                              id: "delete",
                              label: "Delete",
                              onClick: () =>
                                  open(
                                      <DeleteAlarm
                                          deleteAlarm={deleteAlarm}
                                          setLoadingRefresh={setLoadingRefresh}
                                          refetch={refetch}
                                          stayguestName={stayguestName}
                                          stayguestSurname={stayguestSurname}
                                          startTime={startTime}
                                          groupingID={groupingID}
                                      />
                                  ),
                          },
                      ],
        },
    };

    return AlarmModel;
};
