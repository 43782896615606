import React from "react";
import { useTranslation } from "react-i18next";

const UseProjectDisplayer = ({ projectsToDisplay, clicHandler }) => {
    const { t } = useTranslation();

    const globalStyle = "border border-blue-400 text-blue-400 bg-white hover:bg-gray-200";
    const proyectStyle = "bg-blue-400 text-white hover:bg-blue-200";
    return (
        <>
            {projectsToDisplay && projectsToDisplay.length > 0 ? (
                projectsToDisplay.map((project, index) => (
                    <div
                        id={
                            project.global
                                ? "project-global"
                                : projectsToDisplay.some((project) => project.global)
                                ? `project-${index - 1}`
                                : `project-${index}`
                        }
                        className={`${
                            project.global ? globalStyle : proyectStyle
                        }  font-base h-24 rounded  cursor-pointer flex items-center p-8`}
                        onClick={clicHandler}
                        data-id={project.id}
                        data-name={project.name}
                        data-location={project.location}
                        data-ref={project.ref}
                        data-keybrand={project.keyBrand}
                        data-timezone={project.timeZone}
                        data-oldmanagerurl={project.managerURL}
                        data-oldmanagerusers={project.managerUsers}
                        data-global={project.global ? true : false}
                        data-zmobileurl={project.zMobileURL}
                        data-code={project.code}
                        key={index}
                    >
                        <div className="w-full">
                            <div className="w-full block text-2xl text-left">
                                <span className="font-bold">{project.name}</span>
                            </div>
                            <div className="w-full block font-2xl">
                                <span className="inline-block">
                                    {!project.global && <span className="icon-location text-sm mr-2"></span>}
                                    {project.location}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="text-center">
                    <h1 className="mt-5 pt-5" id="no-results-message">
                        {t("no-results")}
                    </h1>
                </div>
            )}
        </>
    );
};

export default UseProjectDisplayer;
