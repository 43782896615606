import { useSelector } from "react-redux";
import { Session } from "./Utils/Session";
import Sitemap from "../sitemap";
import { UseSectionMap } from "../components/Sidebar/utils";

export const UseMainMenu = () => {
    const { checkPath } = UsePermissions();
    const { getItems } = UseSectionMap();
    const menu = {};
    Object.keys(Sitemap).forEach((sectionID) => {
        const excludeSections = ["devices", "information", "sales", "general-settings"];
        if (!excludeSections.includes(sectionID)) {
            let available = checkPath("/" + sectionID);
            if (available) {
                const menuItems = getItems(sectionID, Sitemap[sectionID]);
                available = menuItems && menuItems.length > 0;
            }
            menu[sectionID] = available;
        }
    });
    return evalItems(menu);
};

export const UseSettingsMenu = () => {
    const { checkPath } = UsePermissions();
    const settings = Sitemap["general-settings"];
    const menu = {};
    Object.keys(settings).forEach((subsectionID) => {
        if (settings[subsectionID].childs) {
            const childs = settings[subsectionID].childs;
            if (childs) {
                let found = false;
                Object.keys(childs).forEach((itemKey) => {
                    if (!found && checkPath(childs[itemKey].to)) {
                        menu[itemKey] = true;
                        found = true;
                    }
                });
            }
        } else {
            menu[subsectionID] = checkPath(settings[subsectionID].to);
        }
    });
    return evalItems(menu);
};

export const evalItems = (data) => {
    let items = [];
    if (data) {
        for (const [key, value] of Object.entries(data)) {
            if (value) {
                items.push(key);
            }
        }
    }
    return items;
};

export const UsePermissions = () => {
    const allEnabled = Session.allPermissions();
    const { permissions, paths } = useSelector((state) => state.ui);
    const check = (pkg, attr) => {
        if (allEnabled || !attr || !pkg) {
            return true;
        }
        return permissions && permissions[pkg] && permissions[pkg][attr] ? true : false;
    };
    const checkPath = (path) => {
        if (paths) {
            return paths.filter((v) => v === path || v === path + "/" || v === path + "/*").length > 0;
        }
        return false;
    };
    return {
        check,
        checkPath,
    };
};
