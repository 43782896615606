import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { toast } from "react-toastify";
//Actions
import { setSectionContent, showRowForm } from "../../../actions/sectionActions";
import {
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setSearchable,
    setCheckable,
    setTableResults,
    setItemsPerpage,
    cleanActiveFilters,
    setCustomError,
    setActions,
    setLoading,
    setTableType,
    setRowGroups,
    setColumns,
    setCustomConfig,
    cleanTableStore,
    setRefreshData,
    setPage,
    setFolder,
    getFilterQuery,
} from "../../../actions/tableActions";

//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetDesignTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";

//Model
import MediaLibrary from "./models/MediaLibrary";
import { useTranslation } from "react-i18next";
import { showGlobalLoading } from "../../../actions/uiActions";
import useWindowDimensions from "../../Utils/Utils";
import UseToast from "../../../components/Notifications/useToast";
import { arrangeToastMessagesUploadingFiles } from "../../Utils/DesignUtils";
import { setToastData } from "../../../actions/designActions";
import { useLibraryParams } from "../../../components/Section/MediaLibrary/MediaLibrary";

const ListMediaLibrary = (viewMode) => {
    const { t } = useTranslation();
    const [searching, setSearching] = useState(false);
    const { toastData } = useSelector((state) => state.design);
    const { width: pageWidth } = useWindowDimensions();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, folder, searchString } = useSelector(
        (state) => state.table
    );
    const { parentRef, sideBarRef } = useLibraryParams();

    const ALL_FILES = { name: "all", value: "", key: "type" };
    const ASSET_FILES = { name: "images", value: "asset", key: "type" };
    const VIDEO_FILES = { name: "videos", value: "video", key: "type" };

    const folders = [ALL_FILES, ASSET_FILES, VIDEO_FILES];

    //States
    const [sorting] = useState(`,orderBy:{field: "lower(name)", criteria: "asc"}`);
    const [lastUpdateInterfaz, setLastUpdateInterfaz] = useState(window.ZDate.now());
    const [cardsPerPage, setCardsPerPage] = useState(0);

    //Data
    const sectionName = "list-media-library";

    //Actions
    const dispatch = useDispatch();

    const search_match = filterQuery.match(/search\s?:\s?"([^"]*)/);
    const search = search_match ? search_match[1] : "";
    const newFilterQuery = `, filter:{ ${
        folder && folder.value !== "" ? `type: "folder, ${folder.value}"` : ""
    } , search: "${search}" }`;
    const queryPart =
        search === ""
            ? `page: ${currentPage}, size: ${perPage}, ref: "${parentRef}" ${newFilterQuery}`
            : newFilterQuery;

    //API
    const GET_INFO = gql`{
        libraryContents: libraryContents(${queryPart}${sortCriteria ? sortCriteria : sorting}){
            info{
                count
            }
            response{
                results{
                    lastModified,
                    name,
                    path,
                    parentRef,
                    ref,
                    size,
                    type,
                    ready
                  }
            }
        },
        libraryTree: libraryTree(ref:"root"){
            response{
                fullPath,
                name,
                ref,
                tree{
                    fullPath,
                    name,
                    ref,
                        tree{
                        fullPath,
                        name,
                        ref,
                            tree{
                            fullPath,
                            name,
                            ref,
                            tree{
                                fullPath,
                                name,
                                ref
                            }
                        }
                    }
                }
            }
        }
    }`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        pollInterval: 60000,
    });

    //Listeners
    useEffect(() => {
        dispatch(setPage(Number(1)));
        dispatch(cleanActiveFilters());
        dispatch(getFilterQuery(""));
        // eslint-disable-next-line
    }, [parentRef]);

    useEffect(() => {
        setSearching(search === "" ? false : true);
        // console.log(search)
        // dispatch(setFolder(folders[1]));
        // eslint-disable-next-line
    }, [filterQuery]);

    useEffect(() => {
        dispatch(setLoading(false));
        dispatch(showGlobalLoading(true));
        dispatch(cleanTableStore());
        if (!folder) {
            dispatch(setFolder(folders[0]));
        }
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!folder) {
            dispatch(setFolder(folders[0]));
        }
    }, [folder]);

    useEffect(() => {
        dispatch(setSectionContent({ viewMode: viewMode }));
        dispatch(showRowForm(false));
        dispatch(setRefreshData(true));
        // eslint-disable-next-line
        /*if (data && data.libraryContents) {
            const dataItems = arrangeData(data, viewMode);
            dispatch(setTableResults(dataItems));
        }*/
        // eslint-disable-next-line
    }, [viewMode]);

    useEffect(() => {
        dispatch(setItemsPerpage(cardsPerPage));
        // eslint-disable-next-line
    }, [cardsPerPage]);

    useEffect(() => {
        if (pageWidth > 397 && viewMode === "map") {
            let itemsToShow = parseInt((pageWidth - 397) / 198, 10);
            itemsToShow = parseInt(itemsToShow * 3, 10);
            setCardsPerPage(itemsToShow);
        } else if (cardsPerPage !== 6) {
            setCardsPerPage(6);
        }
        // eslint-disable-next-line
    }, [pageWidth, viewMode]);

    useEffect(() => {
        if (refreshData) {
            dispatch(showGlobalLoading(true));
            executeQuery();
            dispatch(setRefreshData(false));
            setLastUpdateInterfaz(window.ZDate.now());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.libraryContents) {
            //Get data
            const dataItems = arrangeData(data, viewMode);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName);
            if ((dataItems && dataItems.length > 0) || currentPage <= 1) {
                //Add to store
                dispatch(setTableType(sectionName));
                dispatch(setSearchable(true));
                dispatch(setCheckable(true));
                dispatch(setHeaders(tableHeaders));
                dispatch(setAvailableFilters(availableFilters));
                dispatch(setTableResults(dataItems));
                dispatch(setCount(data.libraryContents.info.count));
                dispatch(setCountPosition("table-header"));
                dispatch(
                    setCustomConfig({
                        searchStyle: "col-span-4 pr-36 mb-2",
                        batchStyle: "w-full -ml-28",
                        tableBodyStyle: "-mt-8",
                        pagination: search === "",
                        folders: folders,
                    })
                );

                dispatch(
                    setCustomError(
                        searchString !== null
                            ? parentRef !== "root"
                                ? t("empty-folder")
                                : t("no-results-found")
                            : t("no data available")
                    )
                );
                if (sortCriteria === "") {
                    dispatch(setRowGroups(true));
                }
                if (dataItems.length > 0) {
                    dispatch(setColumns(dataItems[0].info.length));
                }
                dispatch(setExtendedInfo(false));
                dispatch(showRowForm(false));
                if (filterQuery === "") {
                    dispatch(cleanActiveFilters());
                }
                if (dataItems && dataItems[0] && dataItems[0].actions) {
                    dispatch(setActions(true));
                }
                updateToastState(data);
            } else if (currentPage > 1) {
                dispatch(setPage(1));
            }
        }
        dispatch(setLoading(false));
        dispatch(showGlobalLoading(false));
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    const updateToastState = (data) => {
        //update state toast
        if (data && data.libraryContents && data.libraryContents.response && data.libraryContents.response.results) {
            if (toastData && toastData.id && toastData.filesUploading && toastData.filesUploading.length > 0) {
                let allUploaded = true;
                let filesUploadingClone = [...toastData.filesUploading];
                for (let i = 0; i < filesUploadingClone.length; i++) {
                    let fileUploadingClone = filesUploadingClone[i];
                    if (fileUploadingClone.typeFile && fileUploadingClone.typeFile === "video") {
                        let libraryContent = data.libraryContents.response.results.filter(
                            (libraryContent) => libraryContent.ref === fileUploadingClone.ref
                        );
                        if (libraryContent && libraryContent.length > 0) {
                            libraryContent = libraryContent[0];
                            if (libraryContent && libraryContent.ready) {
                                fileUploadingClone.status = 2;
                                fileUploadingClone.parentRef = parentRef;
                                fileUploadingClone.sideBarRef = sideBarRef;
                            } else {
                                allUploaded = false;
                            }
                        }
                    }
                    filesUploadingClone[i] = fileUploadingClone;
                }
                const [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploadingClone, toastData.size);
                toast.update(toastData.id, {
                    type: allUploaded ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
                    render: <UseToast title={title} msgs={msgs} minimize={!allUploaded} />,
                    position: toast.POSITION.BOTTOM_RIGHT,
                    closeButton: allUploaded,
                    icon: false,
                    className: "use-toast",
                });
                if (allUploaded) {
                    dispatch(setToastData(null));
                } else {
                    dispatch(
                        setToastData({
                            id: toastData.id,
                            filesUploading: filesUploadingClone,
                            size: toastData.size,
                        })
                    );
                }
            }
        }
    };

    const arrangeData = (data, viewMode) => {
        const libraryContentsData = [];
        if (data && data.libraryContents && data.libraryContents.response && data.libraryContents.response.results) {
            let libraryTreeOptions = [];
            libraryTreeOptions.push({
                id: "root",
                name: t("media-library"),
                adjust: `ml-8`,
                image: `icons/folder.svg`,
                image_adjust: ``,
            });
            if (data.libraryTree && data.libraryTree.response) {
                libraryTreeOptions = [...libraryTreeOptions, ...getLibraryTreeOptions(data.libraryTree.response)];
            }
            // eslint-disable-next-line
            data.libraryContents.response.results.map((libraryContentResponse) => {
                let libraryContent = { ...libraryContentResponse };
                libraryContent.parentRef = parentRef;
                libraryContentsData.push(
                    MediaLibrary(
                        libraryContent,
                        viewMode,
                        libraryTreeOptions,
                        sideBarRef,
                        lastUpdateInterfaz,
                        searching,
                        !libraryContent.ready
                    )
                );
            });
        }
        return libraryContentsData;
    };
};

const getLibraryTreeOptions = (libraryTreeItems) => {
    let response = [];
    if (libraryTreeItems != null && libraryTreeItems.length > 0) {
        let folders = [];
        let level_nested = 1;
        // eslint-disable-next-line
        libraryTreeItems.map((item, index) => {
            let nested_folders = [];
            if (item.tree != null && item.tree.length > 0) {
                nested_folders = getLibraryTreeOptionsNested(nested_folders, item.tree, level_nested + 1);
            }
            folders.push(
                {
                    id: item.ref,
                    name: item.name,
                    adjust: `ml-${level_nested * 4 + 8}`,
                    image: `icons/folder.svg`,
                    image_adjust: `ml-${level_nested * 4}`,
                },
                ...nested_folders
            );
        });
        response = folders;
    }
    return response;
};

function getLibraryTreeOptionsNested(sections, nesteds, level_nested) {
    sections = sections != null && sections.length > 0 ? sections : [];
    if (nesteds != null && nesteds.length > 0) {
        // eslint-disable-next-line
        nesteds.map((item, index) => {
            let nested_folders = [];
            if (item.tree != null && item.tree.length > 0) {
                nested_folders = getLibraryTreeOptionsNested(nested_folders, item.tree, level_nested + 1);
            }
            sections.push(
                {
                    id: item.ref,
                    name: item.name,
                    adjust: `ml-${level_nested * 4 + 8}`,
                    image: `icons/folder.svg`,
                    image_adjust: `ml-${level_nested * 4}`,
                },
                ...nested_folders
            );
        });
    }
    return sections;
}

export default ListMediaLibrary;
