import React, { useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";

import { EditorContext, WidgetContext } from "../../../../../contexts/editor";
import Select from "./CommonOptions/Select";
import { getFontSizes, getOptionsFontList } from "../../../../../hooks/Utils/Design/FontUtils";
import { GetThemeDefault } from "../../../../../hooks/Utils/DesignUtils";

const LangSelectionToolbar = () => {
    const { deviceType, editorSize, theme } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);
    const { fonts } = useSelector((state) => state.ui);

    const { t } = useTranslation();

    const { gridItems } = useSelector((state) => state.design);

    // const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const [widget, setWidget] = useState(gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null);

    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });

    useEffect(() => {
        const _widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
        setWidget(_widget);
        // eslint-disable-next-line
    }, [gridItems]);

    // const data = Widget.ParseData(widget ? widget.data : null, deviceType);

    return (
        <>
            <Toolbar type="settings">
                <Settings>
                    <Settings.Group title={t("title options")}>
                        <Input.Text id="title" t={t} tagsAvailable={true} />
                        <div className="flex justify-between">
                            {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="styletype"
                                    // deviceSpecific={true}
                                    adjust="w-full mr-2"
                                    data={{
                                        selected:
                                            widget.widgetStyle && widget.widgetStyle.styletype
                                                ? widget.widgetStyle.styletype
                                                : "header",
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )}
                            <Select
                                title={t("font")}
                                id="fontName"
                                // deviceSpecific={true}
                                adjust="w-full mr-2"
                                data={{
                                    selected:
                                        widget.widgetStyle && widget.widgetStyle.fontName
                                            ? widget.widgetStyle.fontName
                                            : theme.headerStyle.font,
                                    options: getOptionsFontList(fonts.fontList),
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="textSize"
                                deviceSpecific={true}
                                adjust="w-full"
                                data={{
                                    selected:
                                        widget.widgetStyle && widget.widgetStyle.textSize
                                            ? widget.widgetStyle.textSize
                                            : theme.headerStyle.size,
                                    options: getFontSizes(editorSize.width, deviceType),
                                }}
                            />
                            {/* <Input.Number deviceSpecific={true} id="textSize" /> */}
                        </div>
                        <Input.Check
                            icon="icon-bold"
                            dataType="style"
                            id="bold"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.bold
                                    ? widget.widgetStyle.bold
                                    : theme.headerStyle.bold
                            }
                        />
                        <Input.Check
                            icon="icon-italic"
                            dataType="style"
                            id="italic"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.italic
                                    ? widget.widgetStyle.italic
                                    : theme.headerStyle.italic
                            }
                        />
                        <Input.Check
                            icon="icon-underline"
                            dataType="style"
                            id="underline"
                            dafultValue={
                                widget.widgetStyle && widget.widgetStyle.underline
                                    ? widget.widgetStyle.underline
                                    : theme.headerStyle.underline
                            }
                        />
                    </Settings.Group>
                    <Settings.Group title={t("languages options")}>
                        <div className="flex justify-between">
                            {/* {theme && (
                                <Select
                                    title={t("styletype")}
                                    id="langsstyletype"
                                    // deviceSpecific={true}
                                    adjust="w-full mr-2"
                                    data={{
                                        selected:
                                            widget.widgetStyle && widget.widgetStyle.langsstyletype
                                                ? widget.widgetStyle.langsstyletype
                                                : "paragraph",
                                        options: [
                                            { value: "header", text: t("heading") },
                                            { value: "paragraph", text: t("paragraph") },
                                        ],
                                    }}
                                />
                            )} */}
                            <Select
                                title={t("font")}
                                id="langsFontName"
                                // deviceSpecific={true}
                                adjust="w-full mr-2"
                                data={{
                                    selected:
                                        widget.widgetStyle && widget.widgetStyle.langsFontName
                                            ? widget.widgetStyle.langsFontName
                                            : theme.paragraphStyle.font,
                                    options: getOptionsFontList(fonts.fontList),
                                }}
                            />
                            <Select
                                title={t("total size")}
                                id="langsTextSize"
                                deviceSpecific={true}
                                adjust="w-full"
                                data={{
                                    selected:
                                        widget.widgetStyle && widget.widgetStyle.langsTextSize
                                            ? widget.widgetStyle.langsTextSize
                                            : theme.paragraphStyle.size,
                                    options: getFontSizes(editorSize.width, deviceType),
                                }}
                            />
                            {/* <Input.Number deviceSpecific={true} id="textSize" /> */}
                        </div>
                        <Input.Check icon="icon-bold" dataType="style" id="langsBold" />
                        <Input.Check icon="icon-italic" dataType="style" id="langsItalic" />
                        <Input.Check icon="icon-underline" dataType="style" id="langsUnderline" />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                <Settings>
                    <Settings.Group type="colors">
                        <Input.Color id={"bgColor"} title={t("background")} />
                        <Input.Color id={"fgColor"} title={t("title")} />
                        <Input.Color id={"optionsColor"} title={t("options")} defaultColor={defaultFgColor} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
        </>
    );
};

export default LangSelectionToolbar;
