import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setCheckable,
    setBatchActive,
    setTableType,
} from "../../../actions/tableActions";
//Utils
import { Session } from "../../Utils/Session";
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { useTranslation } from "react-i18next";
import _ from "lodash";
//Model
import User from "./models/User";
import { useMSQuery } from "../useMSQuery";
//Variables
let currentUserInList = false;

const ListUsers = () => {
    const { t } = useTranslation();
    const { type, currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //Data
    const sectionName = "list-users";
    const currentUserRef = Session.getSessionProp("userRef");
    const isSuperUser = Session.getSessionProp("superUser");

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);
    const [applyFilters, setApplyFilters] = useState("");

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_USERS_INFO = gql`
        {
            users(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}${applyFilters}){
               info { count }
              results{roles{id type name accesses{ name} } id ref email fullName properties { id name ref } assignedAll hasCorporate brands{ id name ref} managerRole}
            }

            ${
                !Session.getSessionProp("chainName")
                    ? `
            chain{
                  name
              }
              `
                    : ``
            }
        }`;
    const GET_FILTERS_DATA = gql`
        {
            roles : users(page: 1, size: 10000){
                info { count }
               results{roles{id name }}
             }
            properties: properties{
                info { count }
               results{id name ref}
            },
            user:users(ref:"${currentUserRef}"){
                results{ assignedAll hasCorporate properties{id ref} brands{ id name ref } }
            }
            brands:brands{
                results{id name ref}
            }
            
        }`;
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_USERS_INFO);
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA);

    //Listeners
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(showGlobalLoading(true));
        dispatch(cleanTableStore());
        localStorage.removeItem("brands");
        executeQuery();
        executeFiltersQuery();
        //table set up
        const tableHeaders = GetTableHeaders(sectionName);
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        dispatch(setCheckable(true));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setCountPosition("table-header"));
        dispatch(showRowForm(false));
        dispatch(setCustomError(t("no users available")));
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(showGlobalLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (type === sectionName) {
            let currentFilters = arrangeFilters();
            setApplyFilters(`, filter:{${currentFilters}}`);
        } else {
            setApplyFilters("");
        }
        // eslint-disable-next-line
    }, [filterQuery]);

    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(showGlobalLoading(false));
        if (!loading && !error && data && data.users && networkStatus === 7) {
            if (data && data.chain && data.chain.name) {
                Session.setSessionProp("chainName", data.chain.name);
            }
            dispatch(setTableType(sectionName));
            const dataItems = arrangeData(data, t);
            dispatch(setTableResults(dataItems));
            dispatch(setCount(currentUserInList ? data.users.info.count - 1 : data.users.info.count));

            dispatch(setActions());
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (!filtersData.error && !filtersData.loading && filtersData.data) {
            // SuperUser access to all brands and properties
            const _cUser = isSuperUser
                ? { assignedAll: true, brands: filtersData.data.brands.results, hasCorporate: true, properties: [] }
                : filtersData.data.user && filtersData.data.user.results
                ? filtersData.data.user.results[0]
                : null;
            Session.setSessionProp("cUser", _cUser);
            if (filtersData.data.roles && filtersData.data.properties) {
                const availableFilters = GetAvailableFilters(
                    sectionName,
                    extractRolesList(filtersData.data.roles),
                    extractPropertiesList(filtersData.data.properties)
                );
                dispatch(setAvailableFilters(availableFilters));
            }
            dispatch(setHeaderButtons(GetTableHeaderButton("list-manager-users", undefined, undefined, t)));
        }
        //eslint-disable-next-line
    }, [filtersData]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        if (filtersData.error) {
            dispatch(setError(filtersData.error));
        }
        // eslint-disable-next-line
    }, [error, filtersData]);

    const arrangeFilters = () => {
        let projectsData = "";
        let othersFilters = activeFilters;
        let currentFilters = "";

        if (_.get(othersFilters, "property")) {
            projectsData = _.get(othersFilters, "property");
            delete othersFilters["property"];
        }

        if (!_.isEmpty(othersFilters)) {
            for (const [key, value] of Object.entries(othersFilters)) {
                if (value) {
                    if (value.charAt(0) === "[" || value === "true" || value === "false") {
                        currentFilters += `${key}:${value},`;
                    } else {
                        currentFilters += `${key}:"${value}",`;
                    }
                }
            }
        }
        currentFilters += `property:{${projectsData ? `,properties:${projectsData}` : ``}}`;
        return currentFilters;
    };
};

const arrangeData = (data, t) => {
    const userData = [];
    currentUserInList = false;
    // eslint-disable-next-line
    data.users.results.map((user) => {
        userData.push(User(user, t));
    });
    return userData;
};

const extractRolesList = (data) => {
    let rolesArray = [""];
    data.results.map((user) =>
        user.roles.map((role) =>
            rolesArray.filter((tRole) => tRole.id === role.id).length === 0
                ? rolesArray.push({
                      value: role.name,
                      id: role.id,
                      visible: true,
                  })
                : false
        )
    );

    rolesArray.sort(function (a, b) {
        return a[0] < b[0];
    });

    return rolesArray;
};

const extractPropertiesList = (data) => {
    let propertiesArray = [];
    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            if (project.id && project.id > 0) {
                propertiesArray.push({
                    value: project.name,
                    id: project.id,
                    visible: true,
                });
            }
        });
    }
    return propertiesArray;
};

const BatchActions = () => {
    const actions = [
        {
            name: "generate-password",
            action: "batch-generate-password",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "confirm-pass-reset-multiple",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
        },
        {
            name: "delete-users",
            action: "delete-manager-users",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-users-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
        },
    ];
    return actions;
};

export default ListUsers;
