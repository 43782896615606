import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setExecuteQuery } from "../../actions/sectionActions";

const UseResendCode = ({ index }) => {
    const { langStrings } = useSelector((state) => state.ui);
    //Store data
    const { ramdon, timeout } = useSelector((state) => state.ui.modalContent.inputs[index]);

    //state
    const [countDown, setCountDown] = useState();

    //se usa useRef porque al leer el valor del state en el interval
    //coge siempre el valor que tenía cuando se lanzó el interval
    const countDownRef = useRef(countDown);
    countDownRef.current = countDown;

    //Listeners
    useEffect(() => {
        startCountDown();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        startCountDown();
        // eslint-disable-next-line
    }, [ramdon]);

    //Actions
    const dispatch = useDispatch();

    const updateCountDown = () => {
        if (countDownRef.current > 0) {
            setCountDown(countDownRef.current - 1);
            setTimeout(function () {
                updateCountDown();
            }, 1000);
        }
    };

    const startCountDown = () => {
        setCountDown(timeout);
        setTimeout(function () {
            updateCountDown();
        }, 1000);
    };
    const sendCode = (e) => {
        dispatch(
            setExecuteQuery({
                action: "send-recovery-email",
            })
        );
    };

    return (
        <div className="text-right inline-flex absolute mr-8">
            <div
                onClick={countDown === 0 ? sendCode : null}
                className={`first-capital ${
                    countDown > 0 ? "text-gray-600" : "font-bold text-zafiro-600 cursor-pointer"
                }`}
            >
                {langStrings["resend-code"]}
            </div>
            {countDown > 0 ? <span className="pl-2 ">{countDown}s</span> : null}
        </div>
    );
};

export default UseResendCode;
