import { useState, useEffect } from "react";
import { GET_DHCP } from "../services/getMonitorInfo";
import { useLazyQuery } from "react-apollo";
import { Session } from "hooks/Utils/Session";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";
import { useLocation } from "react-router-dom";

export const useIsDhcp = () => {
    const location = useLocation();
    const isCorporate = Session?.getSessionProp("projectRef") === "CORPORATE";
    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef].includes(location.pathname);
    const isLoggedIn = Session?.getSessionProp("token") && !isNonChainRefOrProjectPath && !isCorporate;

    const [executeGetDhcpQuery, { data, called, loading }] = useLazyQuery(GET_DHCP, {
        pollInterval: 200000,
    });

    useEffect(() => {
        if (isLoggedIn) {
            executeGetDhcpQuery();
        }
    }, [isLoggedIn]);

    return { isDhcp: data?.getMonitorAvailability, ready: called && !loading, called, loading, executeGetDhcpQuery };
};
