import { Session } from "../../../Utils/Session";
import ApplyDesign from "../modals/ApplyDesign";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";

const Design = (design, designsLocalAssigned, langStrings) => {
    const {
        id,
        name,
        lastUpdatedTime,
        type,
        lastUpdatedUserInfo,
        assignedAllProject,
        isAssigned,
        assignedAll,
        visibleAll,
        visibleAllProject,
        isAssignedFromCorporate,
        creatorProject,
        themeID,
    } = design;

    const lastUpdatedTimeDate = lastUpdatedTime ? new window.ZDate(lastUpdatedTime) : null;
    const currentHotel =
        Session.getSessionProp("currentHotel") && Session.getSessionProp("currentHotel") !== ""
            ? JSON.parse(Session.getSessionProp("currentHotel"))
            : null;
    const isCorporateAccess = currentHotel && currentHotel.ref === "CORPORATE" ? true : false;
    const dataType = "design";
    let listProperties = [];
    const listCreator = [];
    const dynamicCells = [];
    const chainName =
        Session.getSessionProp("currentAccount") && JSON.parse(Session.getSessionProp("currentAccount")).name
            ? JSON.parse(Session.getSessionProp("currentAccount")).name
            : "";
    if (type) {
        switch (type) {
            case "LOCAL":
                listCreator.push([
                    0,
                    creatorProject ? creatorProject.name : currentHotel ? currentHotel.name : "",
                    "#26BCAD",
                ]);
                break;
            case "CORPORATE":
                listCreator.push([0, chainName, "#6554C0"]);
                break;
            default:
                break;
        }
    }
    if (visibleAllProject && visibleAllProject.ref) {
        listProperties.push([
            0,
            `${visibleAllProject.name}`,
            visibleAllProject.type && visibleAllProject.type === "BRAND" ? "#FF991F" : "#26BCAD",
        ]);
    }
    if (visibleAll) {
        listProperties = [[1, chainName, "rgb(101, 84, 192)"]];
    }
    if (isCorporateAccess) {
        if (type === "LOCAL") {
            dynamicCells.push({
                name: "properties",
                cellContent: "labels",
                value: [[1, creatorProject.name, "#26BCAD"]],
                specialWidth: "w-3/12 truncate",
            });
        } else {
            dynamicCells.push({
                name: "properties",
                cellContent: "labels",
                value: listProperties,
                specialWidth: "w-3/12 truncate",
            });
        }
    } else {
        if (type) {
            switch (type) {
                case "CORPORATE":
                    if (isAssignedFromCorporate) {
                        if (designsLocalAssigned) {
                            dynamicCells.push({
                                name: "applied",
                                cellContent: "text",
                                value: `${capitalizeFirst(langStrings["default-design"])}`,
                                specialWidth: "w-3/12",
                                adjust: "text-gray-600 inline",
                                //textTooltip:langStrings["design-global-default-design-tooltip-long"],
                                textTooltip: langStrings["design-global-default-design-tooltip-long"].replace(
                                    "%0",
                                    chainName
                                ),
                                wrap: "truncate",
                            });
                        } else {
                            dynamicCells.push({
                                name: "applied",
                                value: [
                                    [
                                        0,
                                        langStrings["applied"],
                                        "#2286EF",
                                        `${langStrings["design-global-default-design-tooltip"]} ${chainName}`,
                                    ],
                                ],
                                cellContent: "labels",
                                specialWidth: "w-3/12",
                            });
                        }
                    } else {
                        dynamicCells.push({
                            name: "applied",
                            cellContent: "text",
                            value: "",
                            specialWidth: "w-3/12",
                        });
                    }
                    break;
                case "LOCAL":
                    if (isAssigned && isAssigned === true) {
                        dynamicCells.push({
                            name: "applied",
                            value: [[0, langStrings["applied"], "#2286EF"]],
                            cellContent: "labels",
                            specialWidth: "w-3/12",
                        });
                    } else {
                        dynamicCells.push({
                            name: "apply-design",
                            cellContent: "edit-modal",
                            value: langStrings["apply"],
                            isAction: true,
                            modal: ApplyDesign(id, currentHotel ? currentHotel.id : null, name),
                            specialWidth: "w-3/12 pl-4 link clickable",
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        isCorporateAccess: isCorporateAccess,
        isAssigned: isAssigned,
        type: type,
        actions: [{ type: dataType, value: id }],
        mutationParams: {
            id: id,
            name: name,
            type: type,
            assignedAll: assignedAll,
            visibleAll: visibleAll,
            assignedAllProject: assignedAllProject,
            visibleAllProject: visibleAllProject,
            isAssigned: isAssigned,
            themeID: themeID,
        },
        info: [
            {
                name: "name",
                cellContent: "link",
                value: name,
                specialWidth: "w-3/12",
                link: `/design/${id}`,
                adjust: "truncate",
                wrap: "truncate",
            },
            {
                name: "author",
                cellContent: "text",
                value: lastUpdatedUserInfo ? lastUpdatedUserInfo.name : "",
                specialWidth: "w-2/12",
                adjust: "truncate",
            },
            {
                name: "lastUptade",
                cellContent: "text",
                value: lastUpdatedTimeDate !== null ? lastUpdatedTimeDate.toLocaleString() : "",
                specialWidth: "w-2/12",
                adjust: "truncate",
            },
            {
                name: "creator",
                cellContent: "labels",
                value: listCreator,
                specialWidth: "w-2/12 truncate",
            },
            ...dynamicCells,
        ],
    };
};

export default Design;
