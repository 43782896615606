import React, { useState, useEffect, useLayoutEffect } from "react";
import HeaderPredefinedChannels from "./HeaderPredefinedChannels";
import { useTranslation } from "react-i18next";
import PredefinedChannelsListed from "./PredefinedChannelsListed";
import UpdatePredefinedChannels from "../../../../../hooks/GraphqlCalls/Services/UpdatePredefinedChannels";
import { useLocation } from "react-router-dom";
import PredefinedBulkChannels from "./PredefinedBulkChannels";
import { useDispatch } from "react-redux";
import { cleanTableStore } from "../../../../../actions/tableActions";
import { cleanAction } from "../../../../../actions/globalActions";
import { showRowForm, setHiddenRows } from "../../../../../actions/sectionActions";
import HTMLReactParser from "html-react-parser";
import Parser from "hooks/Utils/Parser";

const PresetChannels = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const path = location.pathname ? location.pathname : null;
    const [bulkChannels, setBulkChannels] = useState(false);
    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (path) {
            if (path === "/services/tv-channels/preset-channels/bulk-channels") {
                setBulkChannels(true);
            } else {
                setBulkChannels(false);
            }
        }
    }, [path]);

    useEffect(() => {
        return () => {
            dispatch(cleanTableStore());
            dispatch(cleanAction());
            dispatch(showRowForm(false));
            dispatch(setHiddenRows(true));
        };
    }, []);

    return (
        <>
            <HeaderPredefinedChannels />
            <div className=" mb-6 flex items-center">
                {/* <i className=" pr-2 mt-1 block text-1xl icon icon-warning text-orange-100 "></i> */}
                <span className=" text-lg">
                    {Parser(t("please-note-that-this-database-is-common-for-all-customers"))}
                </span>
            </div>
            {!bulkChannels ? <PredefinedChannelsListed /> : <PredefinedBulkChannels />}
            <UpdatePredefinedChannels />
        </>
    );
};

export default PresetChannels;
