import React, { useState, useEffect, useContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Models
import ScreenName from "./Modals/ScreenName";

//Components
import UseButton from "../../useButton";
import UseItemActions from "../../../components/useItemActions";
import { setExecuteQuery } from "../../../actions/sectionActions";
import UseSwitch from "../../../components/Inputs/useSwitch";

//Modals
import PublishContent from "./Modals/PublishContent";

//Actions
import { setCurrentLang } from "../../../actions/designActions";
import { setModalContent, openModal, setLoadingModalContent } from "../../../actions/uiActions";
import { setActionName, changeGlobalAction } from "../../../actions/globalActions";

//Utils
import { Session } from "../../../hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";
import { capitalizeFirst, parseBoolean } from "../../../hooks/Utils/Utils";
import { SCREEN, TRANSLATE_SCREEN_TYPE_TITLE } from "../../../hooks/Utils/Design/EditorUtils";

import { EditorContext } from "../../../contexts/editor";

const DesignSubmenu = () => {
    //API
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { theme } = useContext(EditorContext);

    //states

    const [isDefault, setIsDefault] = useState(false);
    const [numFilters, setNumFilters] = useState(0);
    const [filtersTooltip, setFiltersTooltip] = useState("");
    const [designList, setDesignList] = useState(null);
    const [screenName, setScreenName] = useState(null);

    //Params
    const { id, type, screenid, landingid } = useParams();
    const { langStrings, permissions } = useSelector((state) => state.ui);
    const { cScreenData, editorMode } = useSelector((state) => state.design);
    const projectRef = useSelector((state) => state.hotel.ref);
    const salesIsPublish = useSelector((state) => state.sectionContent.isPublished);

    const isLanding = type && type !== "GENERAL";
    const isWelcome = type && type === SCREEN.TYPE.WELCOME;
    const isSales = type && (type === SCREEN.TYPE.SALES || type === SCREEN.TYPE.SALESMOBILE);
    const scrData = cScreenData ? cScreenData.data : null;
    let isPublish = scrData ? scrData.isEnabled : false;
    if (cScreenData?.landing) {
        isPublish = cScreenData.landing.isEnabled;
    } else if (isSales) {
        isPublish = salesIsPublish;
    }
    const canUnpublish = scrData && (!scrData.linkedFrom || scrData.linkedFrom.length === 0);

    //Listeners
    useEffect(() => {
        setDesignList(JSON.parse(Session.getSessionProp("designList")));
        dispatch(setCurrentLang(Session.getSessionProp("managerDefLang")));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cScreenData?.data) {
            setScreenName(cScreenData.landing ? cScreenData.landing.name : cScreenData.data.name);
            setIsDefault(isPublish && cScreenData.ptarget && cScreenData.ptarget.all);
            if (cScreenData.ptarget && !cScreenData.ptarget.all) {
                let totalFilters = 0;

                const destination = cScreenData.ptarget.direct.destination;

                Object.keys(destination).forEach((key) => {
                    if (destination[key] && typeof destination[key] === "object" && destination[key].length) {
                        totalFilters = totalFilters + destination[key].length;
                    }
                });
                if (destination.all) {
                    totalFilters++;
                }
                setNumFilters(totalFilters);

                let filtersApplied = [];

                destination.rooms.map((roomFilter) => filtersApplied.push(roomFilter.name));
                destination.floors.map((floorFilter) => filtersApplied.push(floorFilter.name));
                destination.wings.map((wingFilter) => filtersApplied.push(wingFilter.name));
                destination.stayGuests.map((stayGuest) => filtersApplied.push(stayGuest.name));
                destination.stayGroups.map((stayGroup) => filtersApplied.push(stayGroup.name));
                destination.roomGroups.map((roomGroup) => filtersApplied.push(roomGroup.name));
                if (destination.countries) {
                    destination.countries.map((country) => filtersApplied.push(country.name));
                }
                destination.languages.map((language) => filtersApplied.push(language.name));
                if (destination.all) {
                    filtersApplied.push(t("all property"));
                }
                setFiltersTooltip(filtersApplied.join(" | "));
            } else {
                setNumFilters(0);
                setFiltersTooltip("");
            }
        }
        // eslint-disable-next-line
    }, [cScreenData]);

    //Funcions
    const openChangeNameModal = () => {
        if (type === SCREEN.TYPE.WELCOME) {
            dispatch(setActionName("welcome-edit-name"));
            dispatch(
                setModalContent(
                    ScreenName(langStrings, screenName, {
                        welcomeId: landingid,
                        contentType: type,
                        designId: id,
                    })
                )
            );
        } else {
            dispatch(setActionName("content-edit-name"));
            dispatch(
                setModalContent(
                    ScreenName(langStrings, screenName, {
                        contentID: screenid,
                        contentType: type,
                        designID: id,
                        landingID: landingid,
                    })
                )
            );
        }
        //Launch modal
        dispatch(openModal());
    };

    const setAvailable = (set) => {
        dispatch(setExecuteQuery({ action: set ? "publish-content" : "unpublish-content" }));
    };

    const publish = () => {
        dispatch(setActionName("publish-content"));
        dispatch(setModalContent(PublishContent(t, type, screenName, false, numFilters <= 0)));
        //Launch modal
        dispatch(openModal());
    };

    const editFilters = () => {
        dispatch(
            changeGlobalAction({
                actionName: "edit-screen-filters",
                itemsAffected: id,
                values: {
                    numPreviousFilters: numFilters,
                    landingID: landingid,
                    screentype: type,
                    screenprojectRef: projectRef,
                    screenname: screenName,
                    screendesignID: id,
                },
            })
        );
        //Modal content
        const modalContent = {
            id: id,
            useGlobalLoading: true,
            executeSectionQuery: false,
            bodyAdjust: "w-8/12",
            // twoColumns: true,
            noCapitalizeTitle: true,
            title: ["add", "filter"],
            value: `${screenName}`,
            inputs: [
                {
                    type: "void",
                    text: `${capitalizeFirst(langStrings["define-apply-screen"])}:`,
                },
                /* {
                    type: "void",
                    text: `${capitalizeFirst(langStrings["filtering-result"])}:`,
                },*/
                {
                    type: "destinations",
                    isFilterDestinations: true,
                },
                /* {
                    type: "filters-results",
                },*/
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "edit-screen-filters",
                },
            ],
        };
        dispatch(setLoadingModalContent(true));
        dispatch(setModalContent(modalContent));
        //Launch modal
        dispatch(openModal());
    };

    const printFilters = () => {
        return projectRef !== "CORPORATE" && editorMode === "EDITION" && permissions.design.multipleInterface ? (
            <>
                <div className="flex items-center justify-between ">
                    <div className="first-capital font-bold ">{langStrings["apply-to"]}:</div>
                    <div className="first-capital px-4 font-bold">
                        <div className=" min-w-40 rounded flex h-10 items-center bg-gray-200">
                            <div className="first-capital flex h-10 items-center px-4 text-gray-600">
                                {numFilters ? (
                                    <>
                                        <div
                                            data-tip
                                            data-for={`filters_tooltip`}
                                            className="bg-zafiro-200 rounded px-2 py-1 text-white"
                                        >
                                            {t("x filters applied", { count: numFilters })}
                                        </div>
                                        <ReactTooltip
                                            id={`filters_tooltip`}
                                            delayShow={250}
                                            type="light"
                                            border={true}
                                            borderColor="text-gray-200"
                                            arrowColor="text-gray-200"
                                            className="w-auto max-w-lg font-normal"
                                        >
                                            {" "}
                                            <div className="first-capital">{filtersTooltip}</div>
                                        </ReactTooltip>
                                    </>
                                ) : (
                                    <div>{langStrings["all-customers"]}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="first-capital sidebar-submenu-link text-zafiro-200 cursor-pointer font-bold">
                        <div className="float-left" onClick={(e) => editFilters()}>
                            <div className={`icon icon-${numFilters ? "edit" : "add"} mt-0.5 float-left mr-2`}></div>
                            <div className="first-capital float-left">
                                {langStrings[numFilters ? "edit-filter" : "add-filter"]}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : null;
    };

    const printPublishButton = () => {
        if (isPublish || isDefault) {
            return (
                <div className="flex ">
                    <div className="icon icon-tick-right mr-2 mt-1"></div>
                    <div className="first-capital">{t("published")}</div>
                </div>
            );
        }
        if (editorMode === "EDITION" || (isSales && !isPublish)) {
            return (
                <UseButton
                    id={`designs-${isPublish ? "unpublish" : "publish"}-button`}
                    buttonName={isPublish ? "unpublish" : "publish"}
                    buttonColor={isPublish ? "btn-red" : "btn-blue"}
                    adjust="inline-flex float-right"
                    action={(e) => publish()}
                />
            );
        }

        return null;
    };

    return (
        <div id="designSubmenu" className=" z-202 mt-0.5 border-b relative flex  border-gray-200 bg-white ">
            <NavLink
                className=" first-capital border-r flex items-center border-l-0 border-gray-200 px-4 py-5 text-gray-800 hover:text-blue-200 font-bold"
                key={"/design"}
                to={"/design"}
            >
                <div className="relative flex w-full items-center justify-between">
                    <div className="flex items-center ">
                        <i className={`icon-chevron-left inline-block w-auto  `}></i>
                        <span className="first-capital ml-2 inline-block">{langStrings["back-to-list"]}</span>
                    </div>
                </div>
            </NavLink>
            {screenid && cScreenData ? (
                <>
                    <div
                        className=" border-r flex h-auto justify-between border-l-0 border-gray-200 px-4 py-2 align-middle"
                        // style={{ width: "18rem" }}
                    >
                        <div className="flex w-11/12 align-middle">
                            <div className="table h-full w-2/12">
                                <div
                                    data-tip
                                    data-for={`screen_info_tooltip`}
                                    className="icon icon-info cursor-help table-cell align-middle text-3xl text-gray-700 "
                                ></div>
                            </div>

                            <div className="whitespace-no-wrap table  h-full w-10/12 pr-2 text-base font-bold">
                                <div className="table-cell  align-middle ">{cScreenData?.data ? screenName : ""}</div>
                            </div>
                        </div>
                        {cScreenData?.data?.name && editorMode === "EDITION" ? (
                            <div className="flex align-middle">
                                <div
                                    className="icon icon-edit sidebar-submenu-link flex cursor-pointer text-3xl text-gray-800"
                                    onClick={(e) => openChangeNameModal()}
                                ></div>
                            </div>
                        ) : null}
                    </div>
                    <div className="absolute right-0 mr-6 flex h-auto items-center">
                        {isLanding && type !== SCREEN.TYPE.PORTAL && !isSales ? (
                            <div
                                className={` border-l-0 ${
                                    isDefault ||
                                    (!isDefault &&
                                        projectRef !== "CORPORATE" &&
                                        editorMode === "EDITION" &&
                                        permissions.design.multipleInterface)
                                        ? " px-4"
                                        : ""
                                } flex items-center justify-between border-gray-200 ${isDefault ? "py-5" : "py-2"}`}
                            >
                                {isDefault ? (
                                    <div className="first-capital px-4">{langStrings["default-screen"]}</div>
                                ) : (
                                    printFilters()
                                )}
                            </div>
                        ) : null}

                        {cScreenData?.data ? (
                            <div
                                className={`flex px-4 align-middle ${
                                    isLanding && !(isPublish || isDefault) ? "py-2" : "py-5"
                                } items-center`}
                            >
                                {!isLanding ? (
                                    <div className="flex ">
                                        <div
                                            className={`first-capital ${
                                                isPublish && !canUnpublish ? "text-gray-500" : "text-black"
                                            } `}
                                        >
                                            {t("available")}
                                        </div>
                                        <UseSwitch
                                            disabled={isPublish && !canUnpublish}
                                            tooltip={
                                                isPublish && !canUnpublish ? t("screen cant be set unavailable") : null
                                            }
                                            tooltipDelay={250}
                                            showLabel={false}
                                            checked={isPublish}
                                            action={() => {
                                                setAvailable(!isPublish);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    printPublishButton()
                                )}
                                {editorMode === "EDITION" && !isSales ? (
                                    <div className="-mr-6 flex items-center">
                                        <UseItemActions
                                            dataType="content"
                                            containerAdjust="w-48"
                                            executeSectionQuery={true}
                                            id={id}
                                            actions={[
                                                isPublish && ((isLanding && !isDefault) || isWelcome)
                                                    ? {
                                                          name: "unpublish",
                                                          action: "unpublish-content",
                                                          modal_btn_name: "unpublish",
                                                          modal_title: "unpublish",
                                                          modal_value: screenName,
                                                          mutationParams: {
                                                              landingID: landingid,
                                                          },
                                                      }
                                                    : [],
                                                {
                                                    name: `${t("duplicate in")} ...`,
                                                    action: "duplicate-in",
                                                    modal_btn_name: "duplicate",
                                                    modal_title: `${t("duplicate in")} ...`,
                                                    modal_value: screenName,
                                                },
                                                parseBoolean(Session.getSessionProp("ESChain")) &&
                                                Session.getSessionProp("superUser")
                                                    ? {
                                                          name: t("save as template"),
                                                          action: "save-template",
                                                          modal_btn_name: "save",
                                                          modal_title: "save as template",
                                                          modal_value: screenName,
                                                          mutationParams: {
                                                              designID: id,
                                                              contentID: screenid,
                                                              contentType: type,
                                                              landingID: landingid,
                                                          },
                                                      }
                                                    : [],
                                                !isDefault
                                                    ? {
                                                          name: t("delete screen"),
                                                          action: "content-delete",
                                                          modal_confirm: isDefault,
                                                          modal_title: "delete",
                                                          modal_value: screenName,
                                                          disabled: !canUnpublish,
                                                          disabledTooltip: t("{{text, capitalize}}", {
                                                              text: t("linked-designs"),
                                                          }),
                                                          mutationParams: {
                                                              designID: id,
                                                              contentID: screenid,
                                                              contentType: type,
                                                              landingID: landingid,
                                                          },
                                                      }
                                                    : [],
                                            ]}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>

                    {/*TOOLTIPS */}

                    <ReactTooltip
                        id={`screen_info_tooltip`}
                        delayShow={250}
                        type="light"
                        border={true}
                        borderColor="text-gray-200"
                        arrowColor="text-gray-200"
                        className="font-normal"
                    >
                        <div className="">
                            <div className="first-capital font-bold"> {t("design")}</div>
                            <div className="mb-4">
                                {" "}
                                {designList?.filter((design) => parseInt(design.id) === parseInt(id))[0]
                                    ? designList.filter((design) => parseInt(design.id) === parseInt(id))[0].name
                                    : ""}
                            </div>
                            <div className="first-capital font-bold"> {t("screen-type")}</div>
                            <div className="mb-4"> {t(TRANSLATE_SCREEN_TYPE_TITLE[type])}</div>
                            {theme ? (
                                <>
                                    <div className="first-capital font-bold"> {t("appearance")}</div>
                                    <div className="mb-4"> {theme.name}</div>
                                </>
                            ) : null}
                        </div>
                    </ReactTooltip>
                </>
            ) : null}
        </div>
    );
};

export default DesignSubmenu;
