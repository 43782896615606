import React, { Fragment, useEffect, useState, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import UseDropDownOption from "./useDropDownOption";
import { setSectionContent } from "../../../../../actions/sectionActions";
import { capitalizeT } from "../../../../../hooks/Utils/Utils";
import { ORDERED_PRINT_LIST } from "../../../../../hooks/Utils/Design/SalesUtils";
import { EditorContext } from "../../../../../contexts/editor";
import { DEVICE } from "../../../../../hooks/Utils/Design/EditorUtils";

const UseListDropDown = ({ list }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { screenType, deviceType, theme } = useContext(EditorContext);

    const isMobile = deviceType === DEVICE.TYPE.MOBILE;

    const { currentScreen, styleInputData } = useSelector((state) => state.sectionContent);
    const [listTitles] = useState(
        screenType && ORDERED_PRINT_LIST[screenType] ? ORDERED_PRINT_LIST[screenType](theme) : null
    );
    const [openOption, setOpenOption] = useState(listTitles ? listTitles[0] : null);
    const [overOrange, setOverOrange] = useState("");

    useEffect(() => {
        changeOption(listTitles[0]);
        // eslint-disable-next-line
    }, []);

    const changeOption = (option) => {
        dispatch(
            setSectionContent({
                styleInputData: {
                    ...styleInputData,
                    key: { listOption: option },
                    value: null,
                },
            })
        );
        setOpenOption(option);
    };

    return (
        <div>
            {listTitles.map((itemKey, index) => {
                const nameLabel = isMobile && itemKey === "items" ? "navigation-items" : itemKey;
                return (
                    <Fragment key={itemKey}>
                        <div
                            onClick={() => changeOption(openOption === itemKey ? "" : itemKey)}
                            className={`border-b inline-flex w-full cursor-pointer border-gray-300 bg-gray-100 p-4`}
                        >
                            <span
                                id={`chevron-${index}`}
                                className={`icon-chevron transform duration-100 ease-out ${
                                    openOption === itemKey ? "mr-1 -rotate-180" : ""
                                }  left-0 mt-1 w-4`}
                            ></span>
                            <div className="ml-4 w-10/12 ">{capitalizeT(nameLabel, t)}</div>
                            {list[itemKey] && list[itemKey].targets ? (
                                <div
                                    className={`icon-target right-0 my-auto w-auto text-3xl ${
                                        currentScreen && currentScreen.targets.find((key) => key === itemKey)
                                            ? "text-gray-700"
                                            : "text-gray-400"
                                    } ${overOrange === itemKey ? "text-orange-100" : ""} `}
                                    style={{ cursor: "grabbing" }}
                                    onMouseOver={() => {
                                        if (currentScreen && currentScreen.targets.find((key) => key === itemKey)) {
                                            setOverOrange(itemKey);
                                            dispatch(setSectionContent({ highlightedSection: itemKey }));
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        setOverOrange("");
                                        dispatch(setSectionContent({ highlightedSection: null }));
                                    }}
                                ></div>
                            ) : null}
                        </div>
                        <AnimatePresence>
                            {openOption === itemKey && list[itemKey] ? (
                                <motion.div
                                    initial="hidden"
                                    animate="visible"
                                    exit="close"
                                    variants={{
                                        visible: {
                                            opacity: 1,
                                            height: "auto",
                                            display: "block",
                                            transition: {
                                                when: "beforeChildren",
                                                staggerChildren: 0.5,
                                            },
                                        },
                                        hidden: {
                                            opacity: 0,
                                            height: 0,
                                            display: "hidden",
                                        },
                                        close: {
                                            opacity: 0,
                                            height: 0,
                                            display: "hidden",
                                            transition: {
                                                when: "afterChildren",
                                                delay: 0,
                                            },
                                        },
                                    }}
                                >
                                    <UseDropDownOption option={list[itemKey]} style={itemKey} />
                                </motion.div>
                            ) : null}
                        </AnimatePresence>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default UseListDropDown;
