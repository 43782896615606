import React, { useContext, useEffect, useState } from "react";
import { EditorContext } from "../../../contexts/editor";
import { DEVICE, SCREEN, WELCOME_SEQUENCE, EDITOR_MODE } from "../../../hooks/Utils/Design/EditorUtils";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import UseItemActions from "../../useItemActions";
import { setActionName, setActionItemAffected } from "../../../actions/globalActions";
import { openModal, setModalContent } from "../../../actions/uiActions";
import { setExecuteQuery } from "../../../actions/sectionActions";
import AddWelcomeScreen from "./Modals/AddWelcomeScreen";
import { useParams } from "react-router-dom";

const WelcomeSequenceSubMenu = () => {
    const { lang } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { editorSize, deviceType } = useContext(EditorContext);
    const { cScreenData, editorMode } = useSelector((state) => state.design);
    const inEdition = editorMode == EDITOR_MODE.EDITION;

    const [wSequence, setwSequence] = useState([]);

    useEffect(() => {
        if (
            cScreenData &&
            cScreenData.welcome &&
            cScreenData.welcome.sequence &&
            cScreenData.welcome.sequence.length > 0
        ) {
            setwSequence(cScreenData.welcome.sequence);
        }
    }, [cScreenData]);

    const isMobile = deviceType === DEVICE.TYPE.MOBILE;

    const addScreen = () => {
        dispatch(setActionName("add-welcome-screen"));
        dispatch(setActionItemAffected(cScreenData.data.designId));
        dispatch(
            setExecuteQuery({
                action: "add-welcome-screen",
                params: {
                    type: SCREEN.TYPE.WELCOME,
                    cScreenData: cScreenData,
                },
            })
        );
        dispatch(
            setModalContent(
                AddWelcomeScreen(
                    cScreenData.data,
                    t,
                    cScreenData.welcome.sequence.find((item) => item.contentType === WELCOME_SEQUENCE.LANGSELECTION)
                        ? true
                        : false
                )
            )
        );
        //Launch modal
        dispatch(openModal());
    };

    const selectSequence = (item) => {
        history(`/design/${item.designId}/screen/WELCOME/${item.contentId}/${lang}/${item.welcomeId}`);
    };

    const SortableList = SortableContainer(({ items }) => {
        if (!cScreenData) {
            return null;
        }
        return (
            <div className="h-5/6 overflow-y-auto">
                <ul className="grid grid-rows-4 gap-2 px-4">
                    {items.map((item, index) => (
                        <SortableItem
                            key={`item-${item.contentId}`}
                            index={index}
                            sortIndex={index}
                            item={item}
                            inEdition={inEdition}
                            selectSequence={selectSequence}
                        />
                    ))}
                </ul>
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (!inEdition) {
            return;
        }
        setwSequence(arrayMoveImmutable(wSequence, oldIndex, newIndex));
        dispatch(
            setExecuteQuery({
                action: "move-welcomescreen-content",
                params: {
                    newPos: newIndex,
                    designId: cScreenData.data.designId,
                    contentId: wSequence[oldIndex].contentId,
                    welcomeId: cScreenData.data.welcomeId,
                    newArrayPos: arrayMoveImmutable(wSequence, oldIndex, newIndex),
                },
            })
        );
    };
    return (
        <div
            id="welcome_submenu"
            className={`w-104 bg-gray-200`}
            style={{
                height: editorSize.welcomeHeight * (isMobile ? 5 : 1),
            }}
        >
            <div className="ml-10 h-full bg-white">
                <div className="border-b flex w-full border-gray-200 p-4 font-bold leading-6">
                    {t("{{capitalize, capitalize}}", { capitalize: t("screens-sequence") })}
                    {inEdition ? (
                        <span
                            className="icon icon-add right-0 my-auto ml-auto cursor-pointer text-xl text-gray-800"
                            onClick={(e) => addScreen()}
                        ></span>
                    ) : null}
                </div>
                <div className="w-full p-4 leading-6">{t("add-order-screens-sequence")}</div>
                <SortableList items={wSequence} axis={"y"} onSortEnd={onSortEnd} distance={1} />
            </div>
        </div>
    );
};

export default WelcomeSequenceSubMenu;

const SortableItem = SortableElement(({ item, selectSequence, inEdition }) => {
    const { t } = useTranslation();
    const [actionsVisible, setActionsVisible] = useState(false);
    const { cScreenData } = useSelector((state) => state.design);
    return (
        <li
            className="relative w-auto cursor-move"
            onMouseEnter={(e) => setActionsVisible(inEdition)}
            onMouseLeave={(e) => setActionsVisible(false)}
        >
            <div
                className={`sidebar-submenu-link flex ${cScreenData.data.contentId === item.contentId ? "active" : ""}`}
            >
                {inEdition ? <span className="icon-drag-and-drop px-1 text-gray-800"></span> : null}
                <span
                    className={`${SCREEN.SEQUENCES.find((sequence) => sequence.type === item.contentType).icon} px-1`}
                ></span>
                <span className="pl-1 " onClick={() => selectSequence(item)}>
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t(
                            SCREEN.SEQUENCES.find((sequence) => sequence.type === item.contentType).translate
                        ),
                    })}
                </span>
                <span className="ml-auto text-gray-800">
                    {actionsVisible ? (
                        <UseItemActions
                            dataType="content"
                            containerAdjust="w-48"
                            executeSectionQuery={true}
                            id={item.contentId}
                            isWelcomeSequence={true}
                            actions={[
                                item.contentType !== WELCOME_SEQUENCE.LANGSELECTION && {
                                    name: `${t("duplicate")}`,
                                    mutation: "duplicate-welcome-screen",
                                    executeSectionQuery: true,
                                    mutationParams: {
                                        contentId: item.contentId,
                                        designId: cScreenData.data.designId,
                                        welcomeId: cScreenData.data.welcomeId,
                                    },
                                },
                                {
                                    name: "delete",
                                    action: "delete-welcome-content",
                                    executeSectionQuery: true,
                                    modal_btn_name: "delete",
                                    modal_title: "delete",
                                    modal_value: t("{{capitalize, capitalize}}", {
                                        capitalize: t(
                                            SCREEN.SEQUENCES.find((sequence) => sequence.type === item.contentType)
                                                .translate
                                        ),
                                    }),
                                    disabled: cScreenData.welcome.sequence.length === 1 ? true : false,
                                    mutationParams: {
                                        contentId: item.contentId,
                                        designId: cScreenData.data.designId,
                                        welcomeId: cScreenData.data.welcomeId,
                                    },
                                },
                            ]}
                        />
                    ) : null}
                </span>
            </div>
        </li>
    );
});
