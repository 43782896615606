import { capitalizeFirst } from "../../../Utils/Utils";
const Shop = (shop, t, hasOrderPermission) => {
    const {
        id,
        name,
        available,
        langCode,
        taxRate,
        taxZone,
        currency,
        users,
        token,
        openModalEditName,
        openModalModifyState,
        openModalDeleteShop,
    } = shop;

    return {
        dataType: "shop",
        id: id,
        adjust: "h-auto",
        actions: [
            {
                type: "shop",
                value: id,
            },
        ],
        name: name,
        available: available,
        langCode: langCode,
        executeSectionQuery: true,
        taxZone: taxZone,
        currency: currency,
        taxRate: taxRate,
        token: token,
        t: t,
        openModalEditName,
        openModalModifyState,
        openModalDeleteShop,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                value: name,
                link: `/services/sales/shop/products/${id}`,
                cellContent: "link",
                specialWidth: "w-4/12 ",
            },
            hasOrderPermission
                ? {
                      cellContent: "text",
                      value: `${users} ${t("users")}`,
                      specialWidth: "w-4/12 pl-1",
                      wrap: "truncate",
                  }
                : {},
            {
                cellContent: "text",
                value: capitalizeFirst(available ? t("available") : t("unavailable")),
                specialWidth: `w-4/12  pl-1 text-left ${available ? "text-green-100" : "text-red-100"} `,
                wrap: "truncate",
            },
            {
                value: hasOrderPermission ? capitalizeFirst(t("orders")) : "",
                link: hasOrderPermission ? `/services/sales/order-monitor/${id}` : null,
                cellContent: "link",
                adjust: "font-normal text-right pr-10 ",
                specialWidth: "w-4/12",
            },
        ],
    };
};

export default Shop;
