import { deprecationWarning } from "dev";

export const SET_SIDEBAR_STATUS = "SET_SIDEBAR_STATUS";
export const SET_MODAL_STATUS = "SET_MODAL_STATUS";
export const SET_DASHBOARD_CARDS = "SET_DASHBOARD_CARDS";
export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
export const SET_LOADING_CONTENT = "SET_LOADING_CONTENT";
export const GET_CZLIST_INFO = "GET_CZLIST_INFO";
export const SET_ROOMTV_LIST = "SET_ROOMTV_LIST";
export const SHOW_LOGIN = "SHOW_LOGIN";
export const SET_CHECKDD_NAMES = "SET_CHECKDD_NAMES";
export const CLEAN_CHECKDD_NAMES = "CLEAN_CHECKDD_NAMES";
export const SET_LANG = "SET_LANG";
export const SET_LANG_STRINGS = "SET_LANG_STRINGS";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_PATHS = "SET_PATHS";
export const SET_AGREGATED_PERMISSIONS = "SET_AGREGATED_PERMISSIONS";
export const SHOW_GLOBAL_LOADING = "SHOW_GLOBAL_LOADING";
export const CLEAN_DASHBOARD_CARDS = "CLEAN_DASHBOARD_CARDS";
export const RELOAD_BREADCRUMBS = "RELOAD_BREADCRUMBS";
export const SET_SECTION_STYLE = "SET_SECTION_STYLE";
export const SHOW_MAIN_MENU = "SHOW_MAIN_MENU";
export const SET_DROPDOWN_MENU = "SET_DROPDOWN_MENU";
export const SET_WARNINGS_MINIMIZED = "SET_WARNINGS_MINIMIZED";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SET_FONTS = "SET_FONTS";
export const SET_PROJECT_LANGS = "SET_PROJECT_LANGS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_SELECTING_HOTEL_DROPDOWN = "SET_SELECTING_HOTEL_DROPDOWN";

export function openSidebar() {
    //Sets store.sidebar to show sidebar component
    return (dispatch) => {
        dispatch({
            type: SET_SIDEBAR_STATUS,
            payload: true,
        });
    };
}

export function setSelectingHotelDropdown(value) {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTING_HOTEL_DROPDOWN,
            payload: value,
        });
    };
}

export function closeSidebar() {
    //Sets store.sidebar to hide sidebar component
    return (dispatch) => {
        dispatch({
            type: SET_SIDEBAR_STATUS,
            payload: false,
        });
    };
}

export function openModal() {
    deprecationWarning({ name: "openModal", alt: "useModal" });
    //Sets store.modal to show sidebar component
    return (dispatch) => {
        dispatch({
            type: SET_MODAL_STATUS,
            payload: true,
        });
    };
}

export function closeModal() {
    //Sets store.sidebar to hide sidebar component
    return (dispatch) => {
        dispatch({
            type: SET_MODAL_STATUS,
            payload: false,
        });
    };
}

export function setDashboardCards(data) {
    //Sets store.dashboardCards an obj with all data and config for dashboardCards component
    return (dispatch) => {
        dispatch({
            type: SET_DASHBOARD_CARDS,
            payload: data,
        });
    };
}

export function cleanDashboardCards() {
    //reset store.dashboardCards
    return (dispatch) => {
        dispatch({
            type: CLEAN_DASHBOARD_CARDS,
        });
    };
}
export function reloadBreadCrumbs(data) {
    //reset store.dashboardCards
    return (dispatch) => {
        dispatch({
            type: RELOAD_BREADCRUMBS,
            payload: data,
        });
    };
}

/**
 *
 * @param data {{
 *      useGlobalLoading: boolean,
 *      buttons: [
 *          {name: string, action: string, style: string},
 *          {name: string, action: string, style: string}
 *      ],
 *      executeSectionQuery: boolean,
 *      inputs: [],
 *      noTranslateTitle: boolean,
 *      text,
 *      id,
 *      title: string,
 *      noTranslateText: boolean
 *  }}
 * @returns {(function(*): void)|*}
 */
export function setModalContent(data) {
    //Sets store.modalContent an obj with all data and config for modal component
    return (dispatch) => {
        dispatch({
            type: SET_MODAL_CONTENT,
            payload: data,
        });
    };
}
export function setLoadingModalContent(data) {
    //Sets store.modalContent an obj with all data and config for modal component
    return (dispatch) => {
        dispatch({
            type: SET_LOADING_CONTENT,
            payload: data,
        });
    };
}

export function setCZCategories(data) {
    //Sets store.CZList an array of available categories of areas/activities
    return (dispatch) => {
        dispatch({
            type: GET_CZLIST_INFO,
            payload: data,
        });
    };
}

export function setRoomTv(data) {
    //Sets store.RoomTvList an array of available RoomTvs free to assing device
    return (dispatch) => {
        dispatch({
            type: SET_ROOMTV_LIST,
            payload: data,
        });
    };
}
export function showLogin(config) {
    //Sets store.login (bool) to load specific login layout (without sidebar and header)
    return (dispatch) => {
        dispatch({
            type: SHOW_LOGIN,
            payload: config,
        });
    };
}

export function setCheckboxDropdownNames(filterNames) {
    //Loads in store.checkboxDropdownNames the text user needs to see in dropdown with checkboxes
    return (dispatch) => {
        dispatch({
            type: SET_CHECKDD_NAMES,
            payload: filterNames,
        });
    };
}
export function cleanCheckboxDropdownNames() {
    //Loads in store.checkboxDropdownNames the text user needs to see in dropdown with checkboxes
    return (dispatch) => {
        dispatch({
            type: CLEAN_CHECKDD_NAMES,
        });
    };
}

export function setLanguage(newLanguage) {
    //Sets in store.lang current lang ("es","en"...)
    return (dispatch) => {
        dispatch({
            type: SET_LANG,
            payload: newLanguage,
        });
    };
}

export function setLangStrings(langStrings) {
    //loads in store.langStrings an obj with section translations as [term]: "translated string"
    return (dispatch) => {
        dispatch({
            type: SET_LANG_STRINGS,
            payload: langStrings,
        });
    };
}

export function showGlobalLoading(show) {
    deprecationWarning({ name: "showGlobalLoading", alt: "useLoading" });
    //loads in store.langStrings an obj with section translations as [term]: "translated string"
    return (dispatch) => {
        dispatch({
            type: SHOW_GLOBAL_LOADING,
            payload: show,
        });
    };
}

export function setAvailablePaths(paths) {
    return (dispatch) => {
        dispatch({
            type: SET_PATHS,
            payload: paths,
        });
    };
}
export function setPermissions(permissions) {
    //loads in store.langStrings an obj with section translations as [term]: "translated string"
    return (dispatch) => {
        dispatch({
            type: SET_PERMISSIONS,
            payload: permissions,
        });
    };
}
export function setAgregatedPermissions(permissions) {
    //loads in store.langStrings an obj with section translations as [term]: "translated string"
    return (dispatch) => {
        dispatch({
            type: SET_AGREGATED_PERMISSIONS,
            payload: permissions,
        });
    };
}

export function setSectionStyle(section) {
    //loads in store.ui.sidebarSection an string with the side bar section
    return (dispatch) => {
        dispatch({
            type: SET_SECTION_STYLE,
            payload: section,
        });
    };
}

export function showMainMenu(show) {
    //loads in store.ui.sidebarSection an string with the side bar section
    return (dispatch) => {
        dispatch({
            type: SHOW_MAIN_MENU,
            payload: show,
        });
    };
}

export function setDropdownMenu(dropdown) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: SET_DROPDOWN_MENU,
            payload: dropdown,
        });
    };
}

export function setWarningsMinimized(minimized) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: SET_WARNINGS_MINIMIZED,
            payload: minimized,
        });
    };
}
export function addNotification(notification) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: ADD_NOTIFICATION,
            payload: notification,
        });
    };
}
export function removeNotification(notification) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: REMOVE_NOTIFICATION,
            payload: notification,
        });
    };
}
export function setFonts(fonts) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: SET_FONTS,
            payload: fonts,
        });
    };
}
export function setProjectLangs(langs) {
    //sets dropdown menu opened from header
    return (dispatch) => {
        dispatch({
            type: SET_PROJECT_LANGS,
            payload: langs,
        });
    };
}

export function setCountries(countries) {
    return (dispatch) => {
        dispatch({
            type: SET_COUNTRIES,
            payload: countries,
        });
    };
}

export function setLanguages(languages) {
    return (dispatch) => {
        dispatch({
            type: SET_LANGUAGES,
            payload: languages,
        });
    };
}
