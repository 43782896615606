import React from "react";

import { ListComponents } from "sections/playground";

import LocationMap from "components/LocationMap";
import { useAlert } from "components/Modal";
import SettingsCard from "components/SettingsCard";
import { DropdownSection } from "components/DropdownSection";

const Others = () => {
    const { open: alert } = useAlert();

    return (
        <ListComponents cols={2}>
            <SettingsCard
                title="The title"
                description="This is a description of the SettingsCard component"
                info="This is information"
                warning="Warning example"
                edit={{ onClick: () => alert("Edit") }}
                toggle={{ checked: true }}
                right={<div className="icon icon-room text-4xl text-purple-500"></div>}
            >
                <p className="py-5">Example of a SettingsCard component</p>
            </SettingsCard>

            <DropdownSection dropdownSectionHeader="Dropdown Section" id="dropdownsection-playground">
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <img
                        src="https://plus.unsplash.com/premium_photo-1669879825881-6d4e4bde67d5?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHJhbmRvbSUyMHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D"
                        alt="placeholder"
                        width={"150px"}
                        height={"150px"}
                    />
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "Title example",
                        className: "text-4xl",
                    },
                    titleIcon: {
                        type: "room",
                        size: "xl",
                        className: "text-purple-500",
                        tooltip: "You can add a tooltip",
                    },
                }}
                id="dropdownsection-playground"
                canDisplay={false}
            >
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <p className="font bold text-2xl">Always displayed</p>
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "Opened",
                        className: "text-xl font-bold ",
                    },
                    finalIcon: {
                        type: "info",
                        size: "xl",
                    },
                }}
                id="dropdownsection-playground"
                open={true}
            >
                <div className="w-full bg-white flex items-center justify-center py-8">
                    <p className="p-4">Opened by default</p>
                </div>
            </DropdownSection>
            <DropdownSection
                dropdownSectionHeader={{
                    title: {
                        content: "With icons",
                        className: "text-xl font-bold text-red-100",
                    },
                    titleIcon: {
                        type: "info",
                        size: "xl",
                    },
                    finalIcon: {
                        type: "delete",
                        size: "xl",
                        className: "text-green-500",
                    },
                }}
                id="dropdownsection-playground"
            >
                <div className="w-full bg-white p-4">
                    <LocationMap latitude={41.6488} longitude={-0.8891} />
                </div>
            </DropdownSection>
            <DropdownSection dropdownSectionHeader={"Inside another one"} id="dropdownsection-playground">
                <DropdownSection
                    dropdownSectionHeader={{
                        title: {
                            content: "Opened",
                            className: "text-xl font-bold ",
                        },
                        finalIcon: {
                            type: "info",
                            size: "xl",
                        },
                    }}
                    id="dropdownsection-playground"
                    open={true}
                >
                    <div className="w-full bg-red-800 flex items-center justify-center py-8">
                        <p className="p-4 font-bold text-white">Opened by default</p>
                    </div>
                </DropdownSection>
            </DropdownSection>

            <LocationMap latitude={41.6488} longitude={-0.8891} />
        </ListComponents>
    );
};

export default Others;
