import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

//Components
import UseSearch from "../../../useSearch";
import UseTitle from "../../../useTitle";
import TopologyItem from "./TopologyItem";
import UseButton from "../../../useButton";

//Utils
import { Session } from "../../../../hooks/Utils/Session";
import { WingCard } from "./WingCard";
import { FloorCard } from "./FloorCard";
import update from "immutability-helper";
import { changeActionValues } from "actions/globalActions";

const TopologyMap = ({ res, filters, setFilters }) => {
    const dispatch = useDispatch();
    //States
    const [activeWing, setActiveWing] = useState(null);
    const [activeFloor, setActiveFloor] = useState(null);
    const [roomOver, setRoomOver] = useState();

    //Store
    const sectionName = "topology-map";
    const { langStrings, showGlobalLoading } = useSelector((state) => state.ui);

    //Actions
    const handleClickWing = (e) => {
        const wingId = Number(e.currentTarget.dataset.id);
        setActiveWing(wingId);
        Session.setSessionProp("topology-active-wing", wingId);

        //mark first available floor
        let firstAvailableFloor = "";
        // eslint-disable-next-line
        res.map((wing) => {
            if (wing.id === wingId) {
                firstAvailableFloor = wing.floors && wing.floors[0] ? wing.floors[0].id : null;
            }
        });
        setActiveFloor(firstAvailableFloor);
        Session.setSessionProp("topology-active-floor", firstAvailableFloor);
    };

    const handleClickFloor = (e) => {
        setActiveFloor(Number(e.currentTarget.dataset.id));
        Session.setSessionProp("topology-active-floor", Number(e.currentTarget.dataset.id));
    };

    const handleChangeSearch = (e) => {
        setFilters(e.target.value);
    };

    //Listeners

    useEffect(() => {
        const pLocation = Session.getSessionProp("previous-location");
        if (
            pLocation.indexOf("add-wing") > -1 ||
            pLocation.indexOf("add-floor") > -1 ||
            pLocation.indexOf("add-room") > -1 ||
            pLocation.indexOf("edit-room") > -1
        ) {
            setActiveFloor(Number(Session.getSessionProp("topology-active-floor")));
            setActiveWing(Number(Session.getSessionProp("topology-active-wing")));
        } else {
            setActiveWing(res && res[0] ? res[0].id : null);
            setActiveFloor(res[0] && res[0].floors && res[0].floors[0] ? res[0].floors[0].id : null);

            if (!Session.getSessionProp("topology-active-floor") && res[0] && res[0].floors && res[0].floors[0]) {
                Session.setSessionProp("topology-active-floor", res[0].floors[0].id);
            }
            if (!Session.getSessionProp("topology-active-wing") && res && res[0]) {
                Session.setSessionProp("topology-active-wing", res[0].id);
            }
        }

        if (res?.length > 0) {
            Session.setSessionProp(
                "arrNamesWings",
                res.map((r) => {
                    return r.name;
                })
            );
        }
    }, [res]);

    const [wingCards, setWingCards] = useState(res);
    const moveWingCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = wingCards[dragIndex];
            setWingCards(
                update(wingCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [wingCards]
    );

    let floors = null;
    res.map((wing, index) => (wing.id === activeWing ? (floors = wing.floors) : null));

    const [floorCards, setFloorCards] = useState(floors);
    const moveFloorCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = floorCards[dragIndex];
            setFloorCards(
                update(floorCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [floorCards]
    );

    useEffect(() => {
        let floors = null;
        res.map((wing, index) => (wing.id === activeWing ? (floors = wing.floors) : null));
        setFloorCards(floors);
        // eslint-disable-next-line
    }, [activeWing]);

    useEffect(() => {
        setWingCards(res);
        let floors = null;
        res.map((wing, index) => (wing.id === activeWing ? (floors = wing.floors) : null));
        setFloorCards(floors);
        // eslint-disable-next-line
    }, [res]);

    return (
        <div id="main-container" className="main-container">
            <div id="filters-container" className="t-filters-container">
                <div id="filter-row" className="t-filters-row">
                    <div>
                        <UseSearch
                            id={`${sectionName}-search`}
                            handleChangeSearch={handleChangeSearch}
                            placeholder="search-rooms"
                        />
                    </div>
                </div>
                <div className="mt-8">
                    {
                        /* Get Wings... */
                        res && res.length > 0 && !filters ? (
                            <>
                                <div>
                                    <div className="flex">
                                        <div>
                                            <UseTitle text={"wings"} adjust="text-gray-900 font-bold text-lg pb-2" />
                                        </div>
                                        <span className="ml-2 p-0.5 text-gray-800">({res.length})</span>
                                    </div>
                                </div>
                                {wingCards.map((wing, index) => (
                                    <WingCard
                                        parentSectionName={sectionName}
                                        key={wing.id}
                                        id={wing.id}
                                        wing={wing}
                                        index={index}
                                        moveCard={moveWingCard}
                                        handleClickWing={handleClickWing}
                                        activeWing={activeWing}
                                        res={res}
                                    />
                                ))}
                                <Link
                                    to={`/hotel/property-settings/topology/add-wing`}
                                    className="z-10 relative "
                                    style={{
                                        top: ".65rem",
                                    }}
                                    id={`${sectionName}-wing-link-add`}
                                >
                                    <div className="inline-flex">
                                        <UseButton
                                            id={`${sectionName}-wing-add`}
                                            icon="add"
                                            buttonName={"add-wing"}
                                            buttonColor={"btn-white"}
                                            adjust="w-auto px-4 min-w-40"
                                        />
                                    </div>
                                </Link>
                            </>
                        ) : res && !filters && !showGlobalLoading ? (
                            <>
                                <div className="first-capital"> {langStrings["no-hotel-map"]}</div>
                                <Link
                                    to={`/hotel/property-settings/topology/add-wing`}
                                    className="z-10 relative "
                                    style={{
                                        top: ".65rem",
                                    }}
                                    id={`${sectionName}-wing-link-add`}
                                >
                                    <div className="inline-flex">
                                        <UseButton
                                            id={`${sectionName}-wing-add`}
                                            icon="add"
                                            buttonName={"add-wing"}
                                            buttonColor={"btn-white"}
                                            adjust="w-auto px-4 min-w-40"
                                        />
                                    </div>
                                </Link>
                            </>
                        ) : null
                    }
                    <div className="inline-block w-full">
                        {
                            /* Get Floors... */
                            res && res.length > 0 && !filters
                                ? res.map((wing, index) =>
                                      wing.id === activeWing ? (
                                          <div key={index}>
                                              <div>
                                                  <div className="flex mt-12">
                                                      <div>
                                                          <UseTitle
                                                              text={"floors"}
                                                              adjust="text-gray-900 font-bold text-lg pb-2"
                                                          />
                                                      </div>
                                                      <span className="ml-2 p-0.5 text-gray-800">
                                                          ({wing.floors ? wing.floors.length : 0})
                                                      </span>
                                                  </div>
                                              </div>
                                              {floorCards
                                                  ? floorCards.map((floor, index) => (
                                                        <FloorCard
                                                            parentSectionName={sectionName}
                                                            key={floor.id}
                                                            id={floor.id}
                                                            floor={floor}
                                                            index={index}
                                                            moveCard={moveFloorCard}
                                                            handleClickFloor={handleClickFloor}
                                                            activeFloor={activeFloor}
                                                        />
                                                    ))
                                                  : null}
                                              <Link
                                                  to={`/hotel/property-settings/topology/add-floor/${wing.id}`}
                                                  className="z-10 relative"
                                                  style={{
                                                      top: ".65rem",
                                                  }}
                                                  id={`${sectionName}-floor-link-add`}
                                              >
                                                  <div className="inline-flex">
                                                      <UseButton
                                                          id={`${sectionName}-floor-add`}
                                                          icon="add"
                                                          buttonName={"add-floor"}
                                                          buttonColor={"btn-white"}
                                                          adjust="w-auto px-4 min-w-40"
                                                      />
                                                  </div>
                                              </Link>
                                          </div>
                                      ) : null
                                  )
                                : null
                        }
                    </div>
                    <div className="inline-block w-full">
                        {
                            /* Get Rooms... */
                            res && res.length > 0 && !filters
                                ? res.map((wing, index) =>
                                      wing.id === activeWing
                                          ? wing.floors.map((floor, index) =>
                                                floor.id === activeFloor ? (
                                                    <div key={index}>
                                                        <div className="flex mt-12">
                                                            <div>
                                                                <UseTitle
                                                                    text={"rooms"}
                                                                    adjust="text-gray-900 font-bold text-lg pb-2"
                                                                />
                                                            </div>
                                                            <span className="ml-2 p-0.5 text-gray-800">
                                                                ({floor.rooms ? floor.rooms.length : 0})
                                                            </span>
                                                        </div>
                                                        {floor.rooms.map((room, index) => (
                                                            <div
                                                                id={`${sectionName}-room-${room.name}-container`}
                                                                onMouseEnter={(e) => setRoomOver(room.id)}
                                                                onMouseLeave={(e) => setRoomOver(null)}
                                                                className="inline-flex"
                                                                key={index}
                                                                data-id={room.id}
                                                            >
                                                                <div className="">
                                                                    <TopologyItem
                                                                        data={{
                                                                            ...room,
                                                                            parentSectionName: `${sectionName}-room`,
                                                                        }}
                                                                        showOptions={roomOver === room.id}
                                                                        res={res}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <Link
                                                            to={`/hotel/property-settings/topology/add-room/${floor.id}`}
                                                            className="z-10 relative"
                                                            style={{
                                                                top: ".65rem",
                                                            }}
                                                            id={`${sectionName}-room-link-add`}
                                                        >
                                                            <div className="inline-flex">
                                                                <UseButton
                                                                    id={`${sectionName}-room-add`}
                                                                    icon="add"
                                                                    buttonName={"add-room"}
                                                                    buttonColor={"btn-white"}
                                                                    adjust="w-auto px-4 min-w-40"
                                                                />
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ) : null
                                            )
                                          : null
                                  )
                                : null
                        }
                    </div>
                    <div className="inline-block w-full">
                        {
                            /* Search Rooms results... */
                            filters ? (
                                res.filter(
                                    (wing) =>
                                        wing &&
                                        wing.floors &&
                                        wing.floors.filter((floor) => floor.rooms.length > 0).length > 0
                                ).length === 0 ? (
                                    <div className="first-capital"> {langStrings["no-search-results"]}</div>
                                ) : (
                                    res.map((wing, index) =>
                                        wing.floors.filter((floor) => floor.rooms.length > 0).length > 0 ? (
                                            <>
                                                <UseTitle
                                                    text={wing.name}
                                                    adjust="pt-6 text-gray-900 font-bold text-lg"
                                                />
                                                <div key={index}>
                                                    {wing.floors.map((floor, index) =>
                                                        floor.rooms.length > 0 ? (
                                                            <>
                                                                <UseTitle
                                                                    text={floor.name}
                                                                    adjust="pt-4 text-gray-900 font-normal text-lg"
                                                                />
                                                                {floor.rooms.map((room, index) => (
                                                                    <div
                                                                        onMouseEnter={(e) => setRoomOver(room.id)}
                                                                        onMouseLeave={(e) => setRoomOver(null)}
                                                                        className="inline-flex"
                                                                        key={index}
                                                                        data-id={room.id}
                                                                    >
                                                                        <TopologyItem
                                                                            data={room}
                                                                            showOptions={roomOver === room.id}
                                                                            res={res}
                                                                        />
                                                                    </div>
                                                                ))}
                                                                <Link
                                                                    to={`/hotel/property-settings/topology/add-room/${floor.id}`}
                                                                    className="z-10 relative"
                                                                    style={{
                                                                        top: ".65rem",
                                                                    }}
                                                                    id={`${sectionName}-room-link-add`}
                                                                >
                                                                    <div className="inline-flex">
                                                                        <UseButton
                                                                            id={`${sectionName}-room-add`}
                                                                            icon="add"
                                                                            buttonName={"add-room"}
                                                                            buttonColor={"btn-white"}
                                                                            adjust="w-auto px-4 min-w-40"
                                                                        />
                                                                    </div>
                                                                </Link>
                                                            </>
                                                        ) : null
                                                    )}
                                                </div>
                                            </>
                                        ) : null
                                    )
                                )
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopologyMap;
