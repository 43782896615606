import React, { useState } from "react";
import UseCheckBox from "components/Inputs/useCheckBox";
import UseSwitch from "components/Inputs/useSwitch";
import { checkShowTooltip } from "hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";
import UseItemActions from "components/useItemActions";
import { useDispatch } from "react-redux";
import { openModal, setModalContent } from "actions/uiActions";
import AddCustomItem from "../Precheckin/ReservationDetails/modals/AddCustomItem";
import DeleteCustomItem from "../Precheckin/ReservationDetails/modals/DeleteCustomItem";
import { useTranslation } from "react-i18next";

const CustomFieldItem = ({ data, setActionToTrigger, fields, setFields, itemIndex }) => {
    const lang = localStorage?.getItem("lang") || "en";

    const { t } = useTranslation();

    const [showTooltip, setShowTooltip] = useState(false);
    const [timerId, setTimerId] = useState(null);
    const dispatch = useDispatch();

    //Names
    const namesArr = data?.names;
    const currentNameLang = namesArr?.find((n) => n.lang === lang)?.name;
    const defaultName = namesArr?.find((n) => n.name !== "")?.name;
    const nameToDisplay = currentNameLang || defaultName;
    const ref = data?.temporaryRef || data?.ref;

    const enabled = data?.enabled ? true : false;
    const blocked = data?.blocked ? true : false;
    const required = data?.required ? true : false;

    const handleCheckboxChange = () => {
        const updatedFields = fields.map((field) => {
            if (field.temporaryRef === ref || field.ref === ref) {
                return { ...field, enabled: !field.enabled ? true : false };
            }
            return field;
        });
        setFields(updatedFields);
    };

    const handleSwitchChange = () => {
        const updatedFields = fields.map((field) => {
            if (field.temporaryRef === ref || field.ref === ref) {
                return { ...field, required: !field.required ? true : false };
            }
            return field;
        });

        setFields(updatedFields);
    };

    return (
        <div className=" flex items-center w-full " style={{ userSelect: "none" }}>
            <i className=" icon icon-drag-and-drop text-2xl mr-2 -mt-2 "></i>
            <div
                className=" flex"
                onMouseOver={(e) => {
                    // checkShowTooltip(e.currentTarget, setShowTooltip);
                    ReactTooltip.rebuild();
                }}
                data-for={"default-tooltip"}
                data-tip={blocked ? t("this-item-content-is-being-included-in-the-guest-report") : ""}
                style={{ marginTop: "0.3rem", height: "2.5rem" }}
            >
                <UseCheckBox
                    id={`pre-check-in-information-custom-field-${itemIndex}-checkbox`}
                    checked={enabled}
                    enabled={!blocked}
                    onClick={handleCheckboxChange}
                    // forceUpdateChecked
                    // adjust=" mt-2"
                />
            </div>
            <div
                onMouseOver={(e) => {
                    checkShowTooltip(e.currentTarget, setShowTooltip);
                    ReactTooltip.rebuild();
                }}
                onMouseLeave={() => {
                    setShowTooltip(false);
                }}
                data-tip={showTooltip ? nameToDisplay : ""}
                data-for={"default-tooltip"}
                style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                className=" w-full  -mt-2  overflow-hidden whitespace-nowrap "
            >
                {nameToDisplay}
            </div>
            <div
                className="  items-end"
                onMouseOver={(e) => {
                    // checkShowTooltip(e.currentTarget, setShowTooltip);
                    ReactTooltip.rebuild();
                }}
                data-for={"default-tooltip"}
                data-tip={blocked ? t("this-item-content-is-being-included-in-the-guest-report") : ""}
            >
                <UseSwitch
                    id={`switch-required-custom-field-${itemIndex}`}
                    // adjust={"mt-2"}
                    checked={required}
                    disabled={enabled === false || blocked}
                    action={handleSwitchChange}
                />
            </div>
            <div>
                <UseItemActions
                    // scrollParentId={"custom-fields-items-container"}
                    dataType="custom-field"
                    id={`custom-field-${itemIndex}`}
                    executeSectionQuery={true}
                    onClickActions={(e) => {
                        if (timerId) {
                            clearTimeout(timerId);
                        }

                        const newTimerId = setTimeout(() => {
                            const mainContainer = document.querySelector("#custom-fields-items-container");
                            const actionsContainer = document.querySelector("#actions-container");

                            function calculateScrollPercentage(container) {
                                const { scrollTop, scrollHeight, clientHeight } = container;
                                const scrolledContentHeight = scrollHeight - clientHeight;
                                const scrollPercentage = (scrollTop / scrolledContentHeight) * 100;
                                return scrollPercentage;
                            }

                            if (
                                mainContainer &&
                                actionsContainer &&
                                mainContainer.scrollHeight > mainContainer.clientHeight &&
                                itemIndex >= 5 &&
                                (calculateScrollPercentage(mainContainer) <= 15 ||
                                    calculateScrollPercentage(mainContainer) >= 60)
                            ) {
                                mainContainer.scrollBy({
                                    top: actionsContainer.offsetHeight,
                                    behavior: "smooth",
                                });
                            }
                        }, 100);

                        setTimerId(newTimerId);
                    }}
                    actions={[
                        {
                            // alarms: getRoomAlarm(alarms, room.RoomID),
                            // filterRoom: room.name,
                            // modal_value: room.name,
                            name: "edit-item",
                            actionCallback: () => {
                                dispatch(
                                    setModalContent(
                                        AddCustomItem({
                                            action: () => {
                                                setActionToTrigger({ action: "edit-custom-item", data: data });
                                            },
                                            editData: { name: nameToDisplay, names: namesArr, blocked: blocked },
                                        })
                                    )
                                );
                                dispatch(openModal());
                            },
                            noModal: true,
                            // disabled: blocked,
                        },
                        {
                            // alarms: alarms.map((alarm) => Alarm(alarm, t)),
                            // modal_value: room.name,
                            name: "delete-item",
                            actionCallback: () => {
                                dispatch(
                                    setModalContent(
                                        DeleteCustomItem({
                                            action: () => {
                                                setActionToTrigger({ action: "delete-custom-item", data: data });
                                            },
                                            name: nameToDisplay,
                                        })
                                    )
                                );
                                dispatch(openModal());
                            },
                            noModal: true,
                            disabled: blocked,
                            tooltip: blocked ? t("this-item-content-is-being-included-in-the-guest-report") : null,
                        },
                    ]}
                    // scrollParentId="guestAlarms"
                />
            </div>
        </div>
    );
};

export default CustomFieldItem;
