import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MobileSettingsInformationCard from "./MobileSettingsInformationCard";
import ReactTooltip from "components/ReactTooltip";
import MobileSettingsChatFeature from "./MobileSettingsChatFeature";
import MobileSettingsLinksAndSocialNetworks from "./MobileSettingsLinksAndSocialNetworks";
import HTMLReactParser from "html-react-parser";
import UpdateMobileInformationCard from "./UpdateMobileInformationCard";
import { ACTIONS_MOBILE_SETTINGS } from "./MobileSettingsUtils";
import { MobileAssociatedProperties } from "./MobileAssociatedProperties";
import { Session } from "hooks/Utils/Session";
import useDeleteModalApp from "components/Section/Customers/allProperties/modals/useDeleteModalApp";

const MobileSettingsCards = ({ data, cardInEdition, setCardInEdition }) => {
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const corporateAccess = Session.getSessionProp("projectRef") === "CORPORATE";
    const nativeApps = permissions?.hotel?.nativeApps || permissions?.allProperties?.nativeApps;
    const { open: openModalDeleteMobileApp } = useDeleteModalApp();

    return (
        <div className=" w-full ">
            <div className=" mb-4">
                {!corporateAccess && <span className=" text-xl font-bold">{`${data?.name}`}</span>}
                <div className=" flex justify-between mt-4">
                    <div className=" flex gap-2">
                        {(nativeApps ||
                            (corporateAccess && !data?.isMultiProperty) ||
                            (!corporateAccess && data?.isMultiProperty)) && (
                            <i className="icon-warning text-orange-100 text-1xl"></i>
                        )}
                        {((corporateAccess && nativeApps && data?.isMultiProperty) ||
                            (!corporateAccess && nativeApps && !data?.isMultiProperty)) && (
                            <div>{HTMLReactParser(t("to-generate-or-update-mobile-apps"))}</div>
                        )}
                        {corporateAccess && !data?.isMultiProperty && <p>{t("app-created-from-property")}</p>}
                        {!corporateAccess && data?.isMultiProperty && <p>{t("app-created-from-global")}</p>}
                    </div>

                    {((!corporateAccess && !data?.isMultiProperty) || (corporateAccess && data.isMultiProperty)) && (
                        <button
                            className=" focus:outline-none text-zafiro-600 flex items-center"
                            id="mobile-settings-delete-app-button"
                            onClick={() => {
                                openModalDeleteMobileApp({
                                    name: data?.name,
                                    action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_DELETE_MOBILE_APP,
                                    id: data?.id,
                                });
                            }}
                        >
                            <i className=" icon icon-delete mr-2 text-xl "></i>
                            <span className=" font-bold">{`${t("delete-mobile-app")}`}</span>
                        </button>
                    )}
                </div>
            </div>

            <div className=" grid grid-cols-2 gap-6 w-full">
                <div>
                    <MobileSettingsInformationCard
                        data={data}
                        setCardInEdition={setCardInEdition}
                        cardInEdition={cardInEdition}
                    />
                </div>
                <div className="flex flex-col">
                    {!data?.isMultiProperty && (
                        <MobileSettingsChatFeature
                            data={data.projects[0]}
                            setCardInEdition={setCardInEdition}
                            cardInEdition={cardInEdition}
                        />
                    )}

                    <MobileSettingsLinksAndSocialNetworks
                        data={data}
                        setCardInEdition={setCardInEdition}
                        cardInEdition={cardInEdition}
                    />
                </div>
                {data.isMultiProperty && (
                    <div className=" col-span-2">
                        <MobileAssociatedProperties data={data}></MobileAssociatedProperties>
                    </div>
                )}
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                // delay={0}
                borderColor="#D3DAE1"
            />
            <UpdateMobileInformationCard setCardInEdition={setCardInEdition} />
        </div>
    );
};

export default MobileSettingsCards;
