import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";

import { getRGBA, ensureRGBA, rgbaToHex, setColorAlpha } from "hooks/Utils/DesignUtils";

import TextInput from "components/TextInput";
import CurrencyInput from "components/Inputs/CurrencyInput.jsx";

/**
 * @description A color input component
 * @param {string} id - The id of the input
 * @param {string} value - The initial value of the input
 * @param {string} placeholder - The placeholder of the input
 * @param {function} onChange - The function to call when the input value changes
 * @param {boolean} autoFocus - If the input should be focused on render
 * @param {boolean} disabled - If the input should be disabled
 * @param {boolean} required - If the input is required
 * @param {string} className - The extended class name of the input
 * @returns {JSX.Element} The color input component
 * @example
 * <ColorInput id="name" value="#ff0" placeholder="Your color" onChange={(value) => console.log(value)} required={true} />
 */
const ColorInput = forwardRef(({ id, value, placeholder, onChange, autoFocus, disabled, required, className }, ref) => {
    if (!id) {
        throw new Error("ColorInput: Missing id");
    }

    const [selectorTimeout, setSelectorTimeout] = useState(null);

    const inputColorRef = useRef(null);
    const inputTextColorRef = useRef(null);
    const inputAlphaRef = useRef(null);

    const [hexValue, setHexValue] = useState(null);
    const [opacity, setOpacity] = useState(100);

    const rgbaValue = hexValue ? getRGBA(setColorAlpha(hexValue, opacity))?.string : null;

    const setAlpha = (value) => {
        if (onChange && hexValue) {
            onChange(setColorAlpha(hexValue, value));
        }
        setOpacity(value);
    };

    const setColor = (value, timeout) => {
        if (selectorTimeout) {
            clearTimeout(selectorTimeout);
        }
        const rgbaColor = ensureRGBA(value);
        const hexColor = rgbaColor ? rgbaToHex(rgbaColor, false) : null;
        if (timeout) {
            setSelectorTimeout(
                setTimeout(() => {
                    if (onChange) {
                        onChange(hexColor ? setColorAlpha(hexColor, opacity) : null);
                    }
                }, timeout)
            );
        } else {
            if (onChange) {
                onChange(hexColor ? setColorAlpha(hexColor, opacity) : null);
            }
        }
        setHexValue(hexColor);
    };

    useEffect(() => {
        const rgbaColor = value ? ensureRGBA(value) : null;
        const hexColor = rgbaColor ? rgbaToHex(rgbaColor, false) : null;
        const color = getRGBA(rgbaColor);
        setHexValue(hexColor);
        setOpacity(hexColor ? color?.a * 100 : 100);
    }, [value]);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputTextColorRef.current) {
                inputTextColorRef.current.focus();
            }
        },
        blur: () => {
            if (inputTextColorRef.current) {
                inputTextColorRef.current.blur();
            }
        },
        getValue: () => rgbaValue,
        isValid: () => {
            return !!rgbaValue || !required;
        },
    }));

    const globalClass = classNames({
        "flex items-center space-x-2": true,
        [className]: className,
    });

    const squareClass = classNames({
        "w-10 h-10 rounded border border-gray-600 bg-alpha overflow-hidden": true,
    });
    const inputColorClass = classNames({
        "w-full h-full": true,
        "cursor-pointer": !disabled,
    });

    return (
        <div className={globalClass}>
            <div className={squareClass}>
                <input
                    type="color"
                    ref={inputColorRef}
                    id={id}
                    name={id}
                    className={inputColorClass}
                    onChange={(e) => {
                        const color = e?.target?.value;
                        setColor(color, 750);
                    }}
                    style={{
                        opacity: hexValue ? opacity / 100 : 0,
                        filter: disabled ? "grayscale(100%)" : "none",
                    }}
                    value={hexValue || ""}
                    disabled={disabled}
                />
            </div>
            <div className="w-20">
                <TextInput
                    ref={inputTextColorRef}
                    id={`${id}-color-code`}
                    name={`${id}-color-code`}
                    placeholder={placeholder}
                    value={hexValue}
                    onChange={(value) => setColor(value)}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    required={required}
                    className="px-2"
                />
            </div>
            <div className="w-24">
                <CurrencyInput
                    ref={inputAlphaRef}
                    id={`${id}-color-percent`}
                    name={`${id}-color-percent`}
                    minValue={0}
                    maxValue={100}
                    allowDecimals={false}
                    value={opacity}
                    disabled={disabled}
                    onChange={setAlpha}
                    customSymbol={"%"}
                />
            </div>
        </div>
    );
});
ColorInput.displayName = "ColorInput";

export default ColorInput;
