import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import Checkbox from "./Inputs/Checkbox";
import { SearchInput } from "./ZTable/SearchInput";
import { useTranslation } from "react-i18next";

/**
 * CategorizedSelect - Component that allows selecting options with support for categories and search functionality.
 *
 * @param {Array} optionsData - Initial list of options.
 * @param {Function} onChange - Callback function that is executed when the selection changes.
 * @param {String} [placeholder="select-options"] - Placeholder text when no options are selected.
 * @param {Object} [counterText={ singular: "option-selected", plural: "options-selected" }] - Texts displayed next to the selected options counter.
 * @param {String} [id] - Optional ID for the component.
 * @returns {JSX.Element} - The select component.
 */

const CategorizedSelect = ({
    optionsData,
    onChange,
    placeholder = "select-options",
    counterText = { singular: "option-selected", plural: "options-selected" },
    id: componentId,
}) => {
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        if (optionsData?.length > 0) {
            setOptions(optionsData);

            const initialCheckedOptions = [];
            optionsData.forEach((opt) => {
                if (opt?.checked) {
                    initialCheckedOptions.push({ id: opt.id, label: opt.option });
                }
            });
            setCheckedOptions(initialCheckedOptions);
        }
    }, [optionsData]);

    useEffect(() => {
        if (onChange && typeof onChange === "function") {
            onChange(checkedOptions.map((opt) => opt.id));
        }
    }, [checkedOptions, onChange]);

    const handleCheckboxChange = (id, label) => {
        setCheckedOptions((prevCheckedOptions) =>
            prevCheckedOptions.some((option) => option.id === id)
                ? prevCheckedOptions.filter((option) => option.id !== id)
                : [...prevCheckedOptions, { id, label }]
        );
    };

    const handleCategoryChange = (category) => {
        const categoryOptions = options.filter((option) => option.category === category && !option.disabled);
        const allEnabledChecked = categoryOptions.every((option) =>
            checkedOptions.some((checkedOption) => checkedOption.id === option.id)
        );

        if (allEnabledChecked) {
            setCheckedOptions((prevCheckedOptions) =>
                prevCheckedOptions.filter(
                    (checkedOption) => !categoryOptions.some((option) => option.id === checkedOption.id)
                )
            );
        } else {
            const newCheckedOptions = categoryOptions.map((option) => ({
                id: option.id,
                label: option.option,
            }));
            setCheckedOptions((prevCheckedOptions) => {
                const existingIds = new Set(prevCheckedOptions.map((opt) => opt.id));
                return [...prevCheckedOptions, ...newCheckedOptions.filter((opt) => !existingIds.has(opt.id))];
            });
        }
    };

    const handleRemoveSelectedOption = (id) => {
        setCheckedOptions((prevCheckedOptions) => prevCheckedOptions.filter((option) => option.id !== id));
    };

    const filteredOptions = options.filter((option) => option.option.toLowerCase().includes(searchText.toLowerCase()));

    const categories = Array.from(new Set(filteredOptions.map((option) => option.category))).filter(
        (category) => category !== undefined && category !== null
    );

    const uncategorizedOptions = filteredOptions.filter((option) => !option.category);

    return (
        <Dropdown
            designClass={{
                handler: `h-8 flex items-center ${
                    checkedOptions.length === 0 ? "text-gray-600 font-medium" : "text-black"
                }`,
                handlerIcon: "",
                dropdown: "bg-gray-200 px-6 py-1 rounded",
            }}
            id={componentId ? componentId + "-categorized-select" : null}
            handler={
                <div className={`p-5 w-full } `}>
                    {placeholder && checkedOptions.length === 0 ? t(placeholder) : ""}
                    {checkedOptions.length > 0
                        ? checkedOptions.length +
                          " " +
                          t(checkedOptions.length === 1 ? counterText.singular : counterText.plural)
                        : ""}
                </div>
            }
        >
            <div className="p-5 absolute  w-full shadow-2xl bg-white rounded">
                <div className="">
                    <SearchInput setSearchValue={setSearchText} />
                </div>

                {checkedOptions.length > 0 && (
                    <div className="mb-4">
                        <span className="font-bold">{`${checkedOptions.length} ${
                            checkedOptions.length === 1 ? t("item-selected") : t("items-selected")
                        } `}</span>
                        <ul className="mt-2 flex flex-wrap">
                            {checkedOptions.map((option, index) => (
                                <li
                                    key={option.id}
                                    className=" bg-zafiro-400 text-white px-2 py-1 rounded mr-2 mb-2 flex items-center"
                                >
                                    {option.label}
                                    <button
                                        id={
                                            componentId
                                                ? componentId +
                                                  "-" +
                                                  index +
                                                  "-remove-selected-option-categorized-select"
                                                : null
                                        }
                                        onClick={() => handleRemoveSelectedOption(option.id)}
                                        className="ml-2 text-white"
                                    >
                                        &times;
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div style={{ maxHeight: "10rem", overflowY: "scroll" }}>
                    {categories.map((category) => {
                        const categoryOptions = filteredOptions.filter((option) => option.category === category);
                        const enabledCategoryOptions = categoryOptions.filter((option) => !option.disabled); // Solo opciones habilitadas
                        const categoryChecked =
                            enabledCategoryOptions.length > 0 &&
                            enabledCategoryOptions.every(({ id }) =>
                                checkedOptions.some((checkedOption) => checkedOption.id === id)
                            );

                        return (
                            <div key={category}>
                                <div className=" mb-2">
                                    <Checkbox
                                        label={`${t(category)}`}
                                        id={componentId ? componentId + "-" + category + "-option-categorized" : null}
                                        checked={categoryChecked}
                                        onChange={() => handleCategoryChange(category)}
                                        className="font-bold"
                                    />
                                </div>
                                {categoryOptions.map(({ id, option, disabled, tooltip }, index) => (
                                    <div className=" mb-2 ml-8 rounded" key={id}>
                                        <Checkbox
                                            label={option}
                                            id={
                                                componentId
                                                    ? componentId + "-" + index + "-suboption-categorized"
                                                    : null
                                            }
                                            disabled={disabled}
                                            value={id}
                                            tooltip={tooltip}
                                            checked={checkedOptions.some((checkedOption) => checkedOption.id === id)}
                                            onChange={() => handleCheckboxChange(id, option)}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                    {uncategorizedOptions.map(({ id, option, disabled, tooltip }, index) => (
                        <Checkbox
                            key={id}
                            label={option}
                            id={componentId ? componentId + "-" + index + "-option-uncategorized" : null}
                            value={id}
                            tooltip={tooltip}
                            disabled={disabled}
                            checked={checkedOptions.some((checkedOption) => checkedOption.id === id)}
                            onChange={() => handleCheckboxChange(id, option)}
                        />
                    ))}
                </div>
            </div>
        </Dropdown>
    );
};

export default CategorizedSelect;
