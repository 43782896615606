import React, { useContext } from "react";

import { useSelector } from "react-redux";

//Componentes
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";

const WidgetIframe = () => {
    const { deviceType } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);

    const { gridItems } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    return (
        <>
            {data && data.url ? (
                <iframe title="widgetFrame" className="w-full h-full" src={data.url}></iframe>
            ) : (
                <EmptyWidget type={type}></EmptyWidget>
            )}
            <div className="absolute left-0 top-0 w-full h-full bg-transparent"></div>
        </>
    );
};

export default WidgetIframe;
