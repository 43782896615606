import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { gql } from "apollo-boost";
import { useDispatch } from "react-redux";
import Select from "components/Select";
import Loading from "components/Loading";

const UPDATE_PROPERTY = gql`
    mutation UpdateProperty($brands: [Int64!]!, $id: Int64!, $name: String!, $replace: Boolean!) {
        updateProperty(brands: $brands, id: $id, name: $name, replace: $replace) {
            error
            id
            ok
            __typename
        }
    }
`;

const ModalEditProperty = (props) => {
    const { t } = useTranslation();

    const [isValid, setIsValid] = useState(true);
    const [variables, setVariables] = useState({
        brands: props.optionsSelected || [],
        replace: true,
        name: props?.name,
        id: props?.id,
    });

    const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY, {
        onCompleted() {
            toast.success(t("operation-successful"));
            props.close();
            props.refetch();
        },
        onError() {
            toast.error(t("mutation-error"));
            props.close();
            props.refetch();
        },
    });

    const handleSaveClick = () => {
        if (variables?.brands?.length === 0 || !variables?.brands) {
            toast.error(t("error-add-at-least-one-option"));
            setIsValid(false);
        } else {
            updateProperty({
                variables: variables,
            });
        }
    };
    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    return (
        <Modal
            title={`${props?.title}`}
            footer={
                !loading ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-delete" onClick={handleSaveClick}>
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            className={"w-5/12 p-10"}
        >
            {!loading ? (
                <div>
                    <div className=" mb-2">{capitalizeFirst(t("select-properties-groups"))}</div>
                    <div className={isValid ? " border border-transparent" : "border border-red-100 rounded"}>
                        <Select
                            id="modals-assign-emails-select"
                            placeholder={t("manageBrands")}
                            multiple={true}
                            options={props?.options}
                            showSelectedLabelsInline={true}
                            selectedLabelColor={"bg-orange-100"}
                            value={props.optionsSelected}
                            onChange={(values) => {
                                setVariables({ ...variables, brands: values });
                            }}
                        />
                    </div>
                </div>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

export const useModalEditProperty = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalEditProperty {...newProps} />);
        },
    };
};
