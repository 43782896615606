import { Session } from "../../../Utils/Session";
import { THEME_TYPE_DESIGN } from "hooks/Utils/AppearancesUtils";
function NewDesign(editData) {
    const currentHotel =
        Session.getSessionProp("currentHotel") && Session.getSessionProp("currentHotel") !== ""
            ? JSON.parse(Session.getSessionProp("currentHotel"))
            : [];
    const isCorporateAccess = currentHotel && currentHotel.ref === "CORPORATE" ? true : false;
    const id = editData && editData.id ? editData.id : null;
    const name = editData && editData.name ? editData.name : null;
    const type = editData && editData.type ? editData.type : isCorporateAccess ? "CORPORATE" : "LOCAL";
    const visibleAll = editData && editData.visibleAll ? editData.visibleAll : false;
    const themeID = editData && editData.themeID ? editData.themeID : 0;

    const visibleAllProject = editData && editData.visibleAllProject ? editData.visibleAllProject : null;
    const projectsData = [];
    const dynamicInputs = [];

    const themesQuery = `{
        data: themes ${
            type === "CORPORATE"
                ? `(filter:{type:${type} , themeType:${THEME_TYPE_DESIGN}})`
                : `(filter:{themeType:${THEME_TYPE_DESIGN}})`
        } {
            info {
                count
            }
            results {
                id
                name
                category:type
            }
        }
        ${
            themeID
                ? `selected: themes(filter:{id:${themeID}}){
                    results {
                        id
                    }
                }`
                : ""
        } 
    }`;

    if (Session.getSessionProp("chainName")) {
        projectsData.push({
            id: `all`,
            name: Session.getSessionProp("chainName"),
            selected: visibleAll,
            category: "chain",
            selectedLabelColor: "bg-purple-600 text-white",
        });
    }

    if (isCorporateAccess) {
        if (Session.getSessionProp("brands") !== null && Session.getSessionProp("brands") !== undefined) {
            // eslint-disable-next-line
            JSON.parse(Session.getSessionProp("brands")).map((brand) => {
                let itemPreSelected = null;
                if (brand.ref) {
                    if (
                        visibleAllProject &&
                        visibleAllProject.ref &&
                        visibleAllProject.type === "BRAND" &&
                        visibleAllProject.ref === brand.ref
                    ) {
                        itemPreSelected = visibleAllProject;
                    }
                    projectsData.push({
                        id: `${brand.ref}`,
                        name: brand.name,
                        selected: !visibleAll && itemPreSelected !== null ? true : false,
                        category: "property-groups",
                        selectedLabelColor: "bg-orange-100 text-white",
                    });
                }
            });
        }
    }
    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            let itemPreSelected = null;
            if (project.ref) {
                if (
                    visibleAllProject &&
                    visibleAllProject.ref &&
                    visibleAllProject.type === "PROJECT" &&
                    visibleAllProject.ref === project.ref
                ) {
                    itemPreSelected = visibleAllProject;
                }
                if (project.id !== 0 && (isCorporateAccess || project.ref === currentHotel.ref)) {
                    projectsData.push({
                        id: `${project.ref}`,
                        name: project.name,
                        selected: !visibleAll && itemPreSelected !== null ? true : false,
                        category: "properties",
                        selectedLabelColor: "bg-teal-500 text-white",
                    });
                }
            }
        });
    }
    const themeInput = {
        text: ["default appearance", "*"],
        name: "design-themeId",
        instructions: "default appearance instructions",
        type: "selectMultipleWithSearch",
        optionsQuery: themesQuery,
        //selectedIdsPath: "data.selected.themes.results[0]",
        selectPlaceHolder: "select appearance",
        oneSelected: true,
        noSelect: true,
        cStyle: "w-6/12",
        textStyle: "font-bold first-capital",
        translateOptions: true,
        forceSelection: true,
        groupsNames: [
            { name: "LOCAL", text: "property-appearances" },
            { name: "CORPORATE", text: "general-appearances" },
        ],
        orderOptions: true,
    };
    if (isCorporateAccess) {
        dynamicInputs.push(
            {
                text: ["properties"],
                name: "properties",
                instructions: "design-properties-instructions",
                type: "selectMultipleWithSearch",
                optionData: projectsData,
                selectPlaceHolder: "select-properties",
                labelsColors: "bg-teal-500 text-white",
                oneSelected: true,
                translateOptions: true,
                cStyle: "w-full",
                textStyle: "font-bold first-capital",
            },
            themeInput,
            {
                id: "design-assignedAllProject",
                name: "design-assignedAllProject",
                type: "checkbox",
                checked: false,
                instructions: "design-global-apply-instructions",
                textActive: "design-global-apply-active",
                textInactive: "design-global-apply-inactive",
                textActiveAdjust: "text-gray-400 -ml-1 pl-1",
                textInactiveAdjust: "text-blue-200 -ml-1 pl-1",
                adjust: "hidden mb-10",
                cStyle: "mb-14",
                noDisable: true,
            }
        );
    } else {
        dynamicInputs.push(
            {
                text: "",
                name: "properties",
                value: "",
                type: "text",
                style: "hidden",
            },
            themeInput
        );
    }

    return [
        {
            title: name ? [name, "-", "edit-design"] : "add-design",
            bodyAdjust: isCorporateAccess ? "w-10/12" : "w-4/12",
            twoColumns: isCorporateAccess,
            id: id,
            executeSectionQuery: true,
            isCorporateAccess: isCorporateAccess,
            inputs: [
                {
                    text: ["name", "*"],
                    name: "design-name",
                    instructions: "design-name-instructions",
                    value: name,
                    type: "text",
                    focus: true,
                    cStyle: isCorporateAccess ? "w-6/12" : "",
                    //style: "row-span-2",
                    textStyle: "font-bold first-capital",
                },
                {
                    text: "",
                    name: "design-type",
                    value: type,
                    type: "text",
                    style: "hidden",
                },
                ...dynamicInputs,
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-design",
                },
            ],
        },
    ];
}

export default NewDesign;
