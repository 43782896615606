import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UseInputText from "components/Table/useInputText";
import UseSelectWithSearch from "components/useSelectWithSearch";
import UseInputDate from "components/useInputDate";
import UseButton from "components/useButton";
import ReactInputMask from "react-input-mask";
import {
    ARRANGE_GUEST_INFORMATION_DATA,
    CONVERT_DATE_YMD_TO_DMY,
    DOCUMENTS,
    GENDERS,
    GROUP_INPUTS_ARR_GUEST_SECTION,
    GUEST_INFORMATION_INITIAL_DATA,
    HANDLER_UPDATE_FIELD_GUEST,
    UPDATE_STAY_GUEST_TO_STAY_BOOKING,
    VALIDATE_GUEST_FIELDS,
} from "./utils";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import { PreCheckInContext } from "./ReservationDetailsContext";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import { GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import Section from "components/SettingsCard/Section";
import ReactTooltip from "components/ReactTooltip";
import Icon from "components/Icon";

const Guest = () => {
    const { t } = useTranslation();
    const prefixId = "reservation-details-guest";
    const dispatch = useDispatch();
    const [originalData, setOriginalData] = useState([]);
    const [data, setData] = useState([]);
    const { data: dataContext, setInEdition: setInEditionContext } = useContext(PreCheckInContext);
    const [validations, setValidations] = useState({});
    const [roomOptions, setRoomOptions] = useState([]);
    const [inEditionIndex, setInEditionIndex] = useState(null);
    const [indexesCollapsing, setIndexCollapsing] = useState([]);
    const [precheckinFields, setPrecheckinFields] = useState(null);
    const [customFields, setCustomFields] = useState([[], [], []]);
    const [customFieldsChildren, setCustmoFieldsChildren] = useState([[], [], []]);
    const isFirstRenderWithData = useRef(false);

    const numberCardGuest = dataContext?.stayBookings?.numberOfGuests || 0;
    const numberRooms = dataContext?.stayBookings?.numberOfRooms;
    const numerGuestArr = new Array(numberCardGuest).fill(0);
    const maximumAgeOfChildren = dataContext?.preCheckInSettings?.maximumAgeOfChildren || 0;
    const requestDataFromChildren = dataContext?.preCheckInSettings?.requestDataFromChildren ? true : false;

    useEffect(() => {
        if (inEditionIndex !== null) {
            setInEditionContext(true);
        } else {
            setInEditionContext(false);
        }
    }, [inEditionIndex]);

    useEffect(() => {
        if (dataContext?.stayBookings) {
            let initialData = ARRANGE_GUEST_INFORMATION_DATA(dataContext?.stayBookings) || [];
            const initialIndexCollapsing = [];
            if (numberCardGuest > initialData.length) {
                const additionalData = Array.from({ length: numberCardGuest - initialData.length }, (_, index) => ({
                    ...GUEST_INFORMATION_INITIAL_DATA,
                    index: initialData.length + index,
                }));
                initialData = initialData.concat(additionalData);
            }
            setOriginalData(initialData);
            setData(initialData);
            const roomsOptions = Array.from({ length: numberRooms }, (_, index) => ({
                id: String(index + 1),
                name: `${capitalizeFirst(t("room"))} ${index + 1}`,
            }));
            setRoomOptions(roomsOptions);

            if (!isFirstRenderWithData.current) {
                initialData.forEach((item) => {
                    initialIndexCollapsing.push(item.index);
                });
                setIndexCollapsing(initialIndexCollapsing);
                isFirstRenderWithData.current = true;
            }
        }

        if (dataContext?.preCheckInSettingsFields) {
            setPrecheckinFields(dataContext?.preCheckInSettingsFields);
        }
        if (dataContext?.preCheckInSettingsCustomFields?.fields?.length > 0) {
            setCustomFields(distributeRecords(dataContext?.preCheckInSettingsCustomFields?.fields));
        }

        if (dataContext?.preCheckInSettingsCustomFieldsChildren?.fields?.length > 0) {
            setCustmoFieldsChildren(distributeRecords(dataContext?.preCheckInSettingsCustomFieldsChildren?.fields));
        }
    }, [numberCardGuest, numberRooms, dataContext?.stayBookings]);

    const RenderInput = ({
        updateField,
        index,
        inEditionIndex,
        placeholder,
        keyInput,
        type,
        optionData,
        name,
        hideSearch,
        isRequired,
    }) => {
        return (
            <div className=" mb-4">
                <div className=" mb-2 text-sm text-gray-800 font-bold flex items-center">
                    {capitalizeFirst(t(name))}

                    {isRequired ? (
                        <Icon
                            type="mandatory-for-guest"
                            size={1.5}
                            className=" ml-2"
                            tooltip={t("mandatory-information-requested-on-the-guest-form")}
                        />
                    ) : null}
                </div>
                <div>
                    <GuestCustomInput
                        key={optionData}
                        inputData={{
                            validations,
                            updateField,
                            placeholder,
                            index,
                            data,
                            keyInput,
                            type,
                            inEditionIndex,
                            optionData,
                            hideSearch,
                        }}
                    />
                </div>
            </div>
        );
    };

    const GroupRendersInput = ({ updateField, index, inEditionIndex, groupsIndex }) => {
        const groupsInputs = GROUP_INPUTS_ARR_GUEST_SECTION(t, roomOptions);
        return groupsInputs[groupsIndex].map((group) => {
            return RenderInput({
                updateField,
                index,
                inEditionIndex,
                placeholder: group?.placeholder,
                keyInput: group.keyInput,
                type: group?.type,
                name: group?.name,
                optionData: group?.optionData,
                hideSearch: group?.hideSearch,
                isRequired: precheckinFields?.[group.keyRequiredField]?.required ? true : false,
            });
        });
    };

    const cardGuest = (index) => {
        const updateField = ({ field, val }) => {
            HANDLER_UPDATE_FIELD_GUEST({
                index: index,
                field: field,
                value: val,
                data: JSON.parse(JSON.stringify(data)),
                setData: setData,
            });
        };

        const indexIsCollapsing = indexesCollapsing.includes(index) ? true : false;

        const childrenCustomFields = maximumAgeOfChildren > 0 && requestDataFromChildren;
        const ageGuest = data[index]?.birthDate ? calculateAge(data[index]?.birthDate) : null;
        const showChildrenCustomFields = childrenCustomFields && ageGuest !== null && ageGuest <= maximumAgeOfChildren;

        const customFieldsToRender = (index) => {
            return showChildrenCustomFields ? customFieldsChildren[index] : customFields[index];
        };

        return (
            <div className={` mt-6 h-auto  ${indexIsCollapsing ? "border-b border-gray-300" : ""} `}>
                <Section
                    title={`${capitalizeFirst(t("guest"))} ${index + 1}`}
                    subtitle={
                        inEditionIndex !== index
                            ? `${data?.[index]?.["name"] || ""} ${data?.[index]?.["surname"] || ""}`
                            : null
                    }
                    className="mb-4"
                    titleClassName="h-10"
                    id={`guest-${index}`}
                    collapseButton={
                        inEditionIndex !== index
                            ? {
                                  action: () => {
                                      const copyIndexesCollapsing = [...indexesCollapsing];
                                      if (copyIndexesCollapsing.includes(index)) {
                                          const indexPos = copyIndexesCollapsing.indexOf(index);
                                          if (indexPos > -1) {
                                              copyIndexesCollapsing.splice(indexPos, 1);
                                          }
                                      } else {
                                          copyIndexesCollapsing.push(index);
                                      }
                                      setIndexCollapsing(copyIndexesCollapsing);
                                  },
                                  open: indexIsCollapsing,
                              }
                            : null
                    }
                    edit={
                        inEditionIndex !== index
                            ? {
                                  onClick: () => {
                                      setInEditionIndex(index);
                                      setValidations({});
                                      setData(originalData);
                                      const copyIndexesCollapsing = [...indexesCollapsing];
                                      copyIndexesCollapsing.push(index);
                                      setIndexCollapsing(copyIndexesCollapsing);
                                  },
                              }
                            : false
                    }
                />
                {indexIsCollapsing ? (
                    <>
                        <div className=" flex  w-full mt-10 ">
                            <div className=" flex flex-col mr-16 relative " style={{ width: "25%" }}>
                                <div style={{ top: "-2rem" }} className=" font-bold absolute left-0">
                                    {t("main-information")}
                                </div>
                                {GroupRendersInput({ updateField, index, inEditionIndex, groupsIndex: 0 })}
                            </div>
                            <div className=" flex flex-col mr-16" style={{ width: "25%" }}>
                                {GroupRendersInput({ updateField, index, inEditionIndex, groupsIndex: 1 })}
                            </div>
                            <div className=" flex flex-col mr-16" style={{ width: "25%" }}>
                                {GroupRendersInput({ updateField, index, inEditionIndex, groupsIndex: 2 })}
                            </div>
                            <div className=" flex flex-col relative " style={{ width: "25%" }}>
                                <div
                                    style={{ top: "-1rem" }}
                                    className=" flex absolute left-0 items-center font-bold -mt-4 "
                                >
                                    {t("allocation")}
                                    <i
                                        data-tip={t("distribution-of-the-guest-in-the-rooms-of-the-booking")}
                                        data-for="guest-tooltip"
                                        onMouseOver={() => {
                                            ReactTooltip.rebuild();
                                        }}
                                        className=" icon icon-info text-gray-800 text-3xl  ml-2"
                                    ></i>
                                </div>
                                {GroupRendersInput({ updateField, index, inEditionIndex, groupsIndex: 3 })}
                            </div>
                        </div>
                        <div>
                            <div className=" mb-4 font-bold">{t("additional-information")}</div>
                            <div className=" flex ">
                                <div className=" flex flex-col mr-16 relative  " style={{ width: "25%" }}>
                                    {customFieldsToRender(0).map((f) => {
                                        return RenderInput({
                                            updateField,
                                            index,
                                            inEditionIndex,
                                            keyInput: "custom-field-" + f.ref,
                                            type: "text",
                                            placeholder: getNameCustomField(f.names),
                                            name: getNameCustomField(f.names),
                                            isRequired: f?.required ? true : false,
                                        });
                                    })}
                                </div>
                                <div className=" flex flex-col mr-16 relative  " style={{ width: "25%" }}>
                                    {customFieldsToRender(1).map((f) => {
                                        return RenderInput({
                                            updateField,
                                            index,
                                            inEditionIndex,
                                            keyInput: "custom-field-" + f.ref,
                                            type: "text",
                                            name: getNameCustomField(f.names),
                                            placeholder: getNameCustomField(f.names),
                                            isRequired: f?.required ? true : false,
                                        });
                                    })}
                                </div>
                                <div className=" flex flex-col mr-16 relative  " style={{ width: "25%" }}>
                                    {customFieldsToRender(2).map((f) => {
                                        return RenderInput({
                                            updateField,
                                            index,
                                            inEditionIndex,
                                            keyInput: "custom-field-" + f.ref,
                                            type: "text",
                                            name: getNameCustomField(f.names),
                                            placeholder: getNameCustomField(f.names),
                                            isRequired: f?.required ? true : false,
                                        });
                                    })}
                                </div>
                                <div className=" flex flex-col relative " style={{ width: "25%" }}></div>
                            </div>
                        </div>
                        {inEditionIndex === index ? (
                            <div className=" w-full flex mt-6 mb-6 justify-end space-x-4">
                                <UseButton
                                    action={() => {
                                        setValidations({});
                                        setData(originalData);
                                        setInEditionIndex(null);
                                    }}
                                    buttonName={t("cancel")}
                                    buttonColor={"btn-white"}
                                    id={`${prefixId}-cancel-button`}
                                />
                                <UseButton
                                    action={() => {
                                        const { validations, reasons } = VALIDATE_GUEST_FIELDS(data);

                                        if (validations[index]) {
                                            reasons.forEach((reason) => {
                                                toast.error(t(reason));
                                            });
                                            setValidations(validations);
                                        }

                                        const guestsArr = data;

                                        const currentIndexGuestArr = guestsArr?.[index] ? [guestsArr[index]] : null;

                                        let nullOrEmptyGuest = false;

                                        if (currentIndexGuestArr?.length > 0) {
                                            nullOrEmptyGuest = checkIfNullorEmpty(currentIndexGuestArr[0]);
                                        } else {
                                            nullOrEmptyGuest = false;
                                        }

                                        if (nullOrEmptyGuest === false && !validations[index] && currentIndexGuestArr) {
                                            dispatch(
                                                setExecuteQuery({
                                                    action: UPDATE_STAY_GUEST_TO_STAY_BOOKING,
                                                    params: {
                                                        guests: currentIndexGuestArr,
                                                        index,
                                                        inEditionIndex,
                                                    },
                                                })
                                            );
                                            setInEditionIndex(null);
                                        } else if (!validations[inEditionIndex] && nullOrEmptyGuest === true) {
                                            setInEditionIndex(null);
                                        }
                                    }}
                                    id={`${prefixId}-save-button`}
                                    buttonName={t("save")}
                                    buttonColor={"btn-blue"}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    };

    return (
        <div>
            <div className=" px-2" style={{ height: "40rem", overflowY: "scroll" }}>
                {numerGuestArr.map((_, index) => {
                    return cardGuest(index);
                })}
                <ReactTooltip
                    id={`guest-tooltip`}
                    delayShow={0}
                    type={"light"}
                    borderColor={"#D3DAE1"}
                    border={true}
                    multiline={true}
                ></ReactTooltip>
            </div>
        </div>
    );
};

const GuestCustomInput = ({ inputData }) => {
    const prefixId = "reservation-details-guest";
    const { t } = useTranslation();
    const {
        validations,
        updateField,
        index,
        keyInput,
        data,
        optionData,
        type,
        placeholder,
        inEditionIndex,
        hideSearch,
    } = inputData;
    const inEdition = inEditionIndex === index;

    const textValue = () => {
        if (!inEdition) {
            const value = data?.[index]?.[keyInput];
            if (!value) return "-";

            const keyValueMap = {
                birthDate: () => CONVERT_DATE_YMD_TO_DMY(value),
                dateOfIssue: () => CONVERT_DATE_YMD_TO_DMY(value),
                stayBookingRoomIndex: () => `${capitalizeFirst(t("room"))} ${value}`,
                documentType: () => DOCUMENTS.find((item) => item.id === value)?.name || "",
                gender: () => GENDERS.find((item) => item.id === value)?.name || "",
                countryRef: () => GetNationalitiesOptions().find((item) => item.id === value)?.name || "",
            };

            return keyValueMap[keyInput] ? keyValueMap[keyInput]() : value;
        }

        return "-";
    };

    return (
        <>
            {type === "text" && inEdition ? (
                <input
                    type="text"
                    placeholder={placeholder}
                    id={`${prefixId}-input-text-${keyInput}-${index}`}
                    value={data?.[index]?.[keyInput]}
                    className={` w-full ${
                        validations?.[index]?.[keyInput] ? "border border-red-100" : "border border-transparent"
                    } h-11 bg-gray-200 rounded  p-2`}
                    onChange={(e) => {
                        updateField({ field: keyInput, val: e.target.value });
                    }}
                ></input>
            ) : null}

            {type === "select" && inEdition ? (
                <UseSelectWithSearch
                    data={{
                        id: `${prefixId}-${keyInput}-${index}`,
                        name: `${prefixId}-${keyInput}-${index}`,
                        optionData: optionData,
                        selectPlaceHolder: t("select-an-option"),
                        oneSelected: true,
                        noSelect: true,
                        adjust: "h-11",
                        hideSearch: hideSearch === false ? false : true,
                        selectedTextAdjust: "hidden",
                        optionsAdjust: "mt-11",
                        selectedIds: data?.[index]?.[keyInput] ? [data?.[index]?.[keyInput]] : null,
                        onChange: (val) => {
                            updateField({ field: keyInput, val: val });
                        },
                    }}
                />
            ) : null}
            {type === "phone" && inEdition ? (
                <UseCustomPhoneInput
                    value={data?.[index]?.[keyInput] ? data?.[index]?.[keyInput] : null}
                    onChange={(val) => {
                        updateField({ field: "phone", val: val });
                    }}
                    id={`${prefixId}-input-${keyInput}-${index}`}
                    height="2.75rem"
                />
            ) : null}
            {type === "date" && inEdition ? (
                <UseInputDate
                    data={{
                        name: `${prefixId}-input-${keyInput}-${index}`,
                        fullwidth: true,
                        nonInitialDate: true,
                        date: data?.[index]?.[keyInput],
                        placeholder: "dd/mm/yyyy",
                        noCapitalizePlaceholder: true,
                        styleAdjust: `h-11 ${!data?.[index]?.[keyInput] ? " text-gray-600" : ""}`,
                        id: `${prefixId}-input-${keyInput}-${index}`,
                        onChange: (date) => {
                            updateField({ field: keyInput, val: date });
                        },
                    }}
                />
            ) : null}
            {!inEdition ? <div className=" mb-4">{textValue()}</div> : ""}
        </>
    );
};

export default Guest;

function checkIfNullorEmpty(data) {
    if (data.id !== null) {
        return false;
    }

    for (let key in data) {
        if (key !== "index") {
            if (data[key] !== null && data[key] !== "") {
                if (typeof data[key] === "string" && data[key].trim() !== "") {
                    return false;
                }
            }
        }
    }

    return true;
}

function distributeRecords(records) {
    const result = [[], [], []];
    const numArrays = result.length;

    for (let i = 0; i < records.length; i++) {
        const arrayIndex = Math.floor(i / numArrays);
        result[i % numArrays].push(records[i]);
    }

    return result;
}

function getNameCustomField(names) {
    const lang = localStorage.getItem("lang") || "en";

    const langName = names.find((n) => n.lang === lang)?.name || "";
    const defaultName = names.find((n) => n?.name?.trim() !== "")?.name || "";

    return langName || defaultName;
}

function calculateAge(birthDate) {
    if (!birthDate) {
        return null;
    }

    const birthday = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthday.getFullYear();
    const m = today.getMonth() - birthday.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
        age--;
    }

    return age;
}
