import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useRef, useContext } from "react";
import {
    setHeaderButtons,
    setLoading,
    setBatchActive,
    setSearchable,
    setCustomError,
    setCountPosition,
    setCheckable,
    setHeaders,
    setActions,
    setTableResults,
    setCount,
    setItemsPerpage,
    setRefreshData,
    setCustomConfig,
    resetIdSubRow,
    cleanTableStore,
} from "../../../actions/tableActions";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { executeVendureQuery, getVendureApiData } from "../../Utils/Integrations/useVendure";
import { Session } from "../../Utils/Session";
import Category from "./models/Category";
import { parseVendureTranslation } from "../../Utils/SalesUtils";
import { SalesContextDeprecated } from "contexts/Sales";
import { useModalAddCategory } from "./modals/UseModalAddCategory";
import useModalModifyCategoryState from "./modals/UseModalModifyCategoryState";
import useModalDeleteCategory from "./modals/UseModalDeleteCategory";
import { useModalSalesBatchActions } from "components/Section/Services/Sales/modals/ModalBatchActions/useModalSalesBatchAction";
import { TYPE_BATCH_ACTION } from "components/Section/Services/Sales/modals/ModalBatchActions/utils";

const ListCategories = ({ levelCategory = 0, idCategory, availableCategory = true }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { open: openModalAddCategory } = useModalAddCategory();
    const { id, idCat } = useParams();
    const navigate = useNavigate();
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const [categories, setCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [copyCategoriesData, setCopyCategoriesData] = useState([]);
    const [currentLevelCategory, setCurrentLevelCategory] = useState(0);
    const initialCategoriesRender = useRef(true);
    const { currentPage, perPage, refreshData, checkedItems, results, activeFilters, sortCriteria, idsSubrows } =
        useSelector((state) => state.table);
    const { projectLangs } = useSelector((state) => state.ui);
    const SECTION_NAME = "list-categories";
    const search = activeFilters && activeFilters.search ? activeFilters.search : "";
    const { open: openModalModifyCategoryState } = useModalModifyCategoryState();
    const { open: openModalDeleteCategory } = useModalDeleteCategory();

    const { open: openModalBatchActions } = useModalSalesBatchActions();

    useEffect(() => {
        if (idCat && dataContext?.collections) {
            setCurrentLevelCategory(dataContext?.collections?.items?.[0]?.breadcrumbs?.length - 1);
        } else if (!idCat) {
            setCurrentLevelCategory(0);
        }
    }, [dataContext]);

    useEffect(() => {
        setTimeout(() => {
            if (dataContext?.collections?.items) {
                let langDefault = "en";
                if (projectLangs) {
                    projectLangs.forEach((pl) => {
                        if (pl && pl.isDefault) {
                            langDefault = pl.languageRef;
                        }
                    });
                }
                const data = arrangeData(
                    dataContext.collections.items,
                    dataContext.products.items,
                    langDefault,
                    levelCategory
                );
                // if (isRefresh) {
                if (sortCriteria && !search) {
                    handlerSortCriteria(sortCriteria, data);
                    setCopyCategoriesData(data);
                } else if (sortCriteria && search) {
                    handlerSortCriteria(sortCriteria, filterSearchCategories(data, search));
                    setCopyCategoriesData(data);
                } else if (!sortCriteria && search) {
                    setCategoriesData(filterSearchCategories(data, search));
                    setCopyCategoriesData(data);
                } else {
                    setCategoriesData(data);
                    setCopyCategoriesData(data);
                }

                initialCategoriesRender.current = false;
                Session.setSessionProp("vendure-categories", JSON.stringify(data));
                dispatch(setLoading(false));
            }
        }, 1000);
    }, [dataContext]);

    const arrangeData = (data, dataProducts, langDefault, levelCategory) => {
        const arrData = [];
        const dataToArrange = levelCategory <= 0 || levelCategory === 4 ? data : data[0].children;
        dataToArrange.forEach((item) => {
            item.name = parseVendureTranslation(item.translations, langDefault);
            item.childrenCount = item.children ? item.children.length : 0;
            item.mainParentEnabled = true;
            item.parentEnabled = true;
            item.idShop = id;

            updateChildrenInfo(
                item.customFields.enabled,
                dataProducts,
                item,
                item.children,
                langDefault,
                item.name,
                ""
            );
            if ((levelCategory === 0 && item?.breadcrumbs?.length === 2) || levelCategory !== 0) {
                item.totalItems = countProductsByFacetValueId(item, dataProducts);
                arrData.push(item);
            }
        });
        dispatch(setLoading(false));
        initialCategoriesRender.current = false;
        return arrData;
    };

    const updateChildrenInfo = (
        mainParentEnabled,
        dataProducts,
        parent,
        children,
        langDefault,
        parentName,
        grandParentName
    ) => {
        if (!children || children.length === 0) {
            return;
        }

        children.forEach((child) => {
            child.name = parseVendureTranslation(child.translations, langDefault);
            child.childrenCount = child.children ? child.children.length : 0;
            child.mainParentEnabled = mainParentEnabled;
            child.parentEnabled = parent.customFields.enabled;
            child.idShop = id;
            child.parentName = parentName;
            child.grandParentName = grandParentName;
            child.totalItems = countProductsByFacetValueId(child, dataProducts);
            updateChildrenInfo(
                parent.customFields.enabled,
                dataProducts,
                child,
                child.children,
                langDefault,
                child.name,
                parentName
            );
        });
    };

    const getCategoriesByPage = (categories, currentPage, itemsPerPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return categories.slice(startIndex, endIndex);
    };

    const sortCategoriesByNumber = (objects, ascending = true, property) => {
        const sortedObjects = [...objects]; // make a copy of the original array
        sortedObjects.sort((a, b) => {
            const propertyA = a[property];
            const PropertyB = b[property];
            if (ascending) {
                return propertyA - PropertyB;
            } else {
                return PropertyB - propertyA;
            }
        });
        return sortedObjects;
    };

    const sortCategoriesAlphabetically = (objects, ascending = true, property) => {
        const sortedObjects = [...objects]; // make a copy of the original array
        sortedObjects.sort((a, b) => {
            const propertyA = a[property].toLowerCase();
            const propertyB = b[property].toLowerCase();
            if (ascending) {
                if (propertyA < propertyB) return -1;
                if (propertyA > propertyB) return 1;
                return 0;
            } else {
                if (propertyA > propertyB) return -1;
                if (propertyA < propertyB) return 1;
                return 0;
            }
        });
        return sortedObjects;
    };

    const filterSearchCategories = (categoriesData, searchQuery) => {
        const copyArrCategories = [...categoriesData];

        if (searchQuery) {
            const filterCategories = copyArrCategories.filter((categories) =>
                categories.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            return filterCategories;
        }
    };

    useEffect(() => {
        dispatch(setCustomError(t("no-categories-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setItemsPerpage(9));
        dispatch(setActions(true));
        // dispatch(setLoading(true));
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(
            setHeaderButtons(
                GetTableHeaderButton(
                    SECTION_NAME,
                    { id, level: currentLevelCategory, idCategory, idCat, openModalAddCategory, navigate },
                    null,
                    t
                )
            )
        );
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME, { levelCategory: currentLevelCategory })));
        dispatch(setCustomError(currentLevelCategory <= 0 ? t("no-categories-yet") : t("no-subcategories-yet")));
        // eslint-disable-next-line
    }, [currentLevelCategory]);

    useEffect(() => {
        dispatch(setCheckable(true));
        dispatch(setSearchable(true));

        initialCategoriesRender.current = true;

        if (idCategory) {
            dispatch(
                setCustomConfig({
                    filtersStyle: "col-span-10 -mt-18",
                })
            );
        }

        // eslint-disable-next-line
    }, [idCat]);

    useEffect(() => {
        setCategories(getCategoriesByPage(categoriesData, currentPage, perPage));
        dispatch(setCount(categoriesData.length));
        // eslint-disable-next-line
    }, [categoriesData]);

    const handlerSortCriteria = (sortCriteria, categories) => {
        const field = sortCriteria.match(/field:"([^"]+)"/) ? sortCriteria.match(/field:"([^"]+)"/)[1] : null;
        const criteria = sortCriteria.match(/criteria:"([^"]+)"/) ? sortCriteria.match(/criteria:"([^"]+)"/)[1] : null;

        if (field === "numberProducts") {
            if (criteria === "asc") {
                setCategoriesData(sortCategoriesByNumber(categories, false, "totalItems"));
            } else if (criteria === "desc") {
                setCategoriesData(sortCategoriesByNumber(categories, true, "totalItems"));
            }
        } else if (field === "nameCategories") {
            if (criteria === "asc") {
                setCategoriesData(sortCategoriesAlphabetically(categories, false, "name"));
            } else if (criteria === "desc") {
                setCategoriesData(sortCategoriesAlphabetically(categories, true, "name"));
            }
        }
    };

    useEffect(() => {
        if (categoriesData.length > 0 && !initialCategoriesRender.current) {
            setCategories(getCategoriesByPage(categoriesData, currentPage, perPage));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if (sortCriteria && !initialCategoriesRender.current) {
            dispatch(setLoading(false));
            handlerSortCriteria(sortCriteria, categoriesData);
        } else if (!sortCriteria && !initialCategoriesRender.current) {
            dispatch(setLoading(false));
            setCategories(copyCategoriesData);
        }
        dispatch(resetIdSubRow());
        // eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        const data = [];
        categories.forEach((item) => {
            data.push(
                Category({
                    mainLvlCategory: levelCategory,
                    data: item,
                    t,
                    availableCategory,
                    idsSubrows,
                    openModalModifyCategoryState,
                    openModalDeleteCategory,
                })
            );
        });
        dispatch(setTableResults(data));
        // eslint-disable-next-line
    }, [categories, availableCategory, JSON.stringify(idsSubrows)]);

    useEffect(() => {
        if (search && !initialCategoriesRender.current) {
            setCategoriesData(filterSearchCategories(copyCategoriesData, search));
        } else if (!search && !sortCriteria && !initialCategoriesRender.current) {
            setCategoriesData(copyCategoriesData);
        } else if (!search && sortCriteria && !initialCategoriesRender.current) {
            handlerSortCriteria(sortCriteria, copyCategoriesData);
        }
        dispatch(resetIdSubRow());
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(
            setBatchActive(
                BatchActions({
                    openModalBatchActions,
                    checkedItems,
                    categories: results
                        ? results
                              .filter((collection) => checkedItems.includes(collection.id))
                              .map((item) => {
                                  return {
                                      id: item.id,
                                      name: item.name,
                                      extraParams: {
                                          facetValuesIds: item?.facetValueIds || [],
                                      },
                                  };
                              })
                        : [],
                })
            )
        );
    }, [checkedItems]);

    return null;
};

export default ListCategories;

const BatchActions = ({ openModalBatchActions, checkedItems, categories }) => {
    const actions = [
        {
            name: "delete",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.DELETE_CATEGORY,
                    subtitle: "delete-categories-confirm",
                    labels: categories,
                    checkedItems,
                    title: "delete",
                    textsSuccess: "next-categories-have-been-deleted",
                    textsError: "next-categories-have-not-been-deleted",
                    apiPointer: "VENDURE",
                });
            },
        },
        {
            name: "set-as-available",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.AVAILABLE_CATEGORY,
                    subtitle: "set-as-available-categories-confirm",
                    labels: categories,
                    checkedItems,
                    title: "set-as-available",
                    textsSuccess: "next-categories-have-been-modified-successfully",
                    textsError: "next-categories-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
        {
            name: "set-as-unavailable",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.UNAVAILABLE_CATEGORY,
                    subtitle: "set-as-unavailable-categories-confirm",
                    labels: categories,
                    checkedItems,
                    title: "set-as-unavailable",
                    textsSuccess: "next-categories-have-been-modified-successfully",
                    textsError: "next-categories-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
    ];
    return actions;
};
const countProductsByFacetValueId = (categoryItem, facetValuesProducts) => {
    let count = 0;

    let allFacetsProductsValues = [];
    for (const facetValue of facetValuesProducts) {
        allFacetsProductsValues = [...allFacetsProductsValues, ...facetValue.facetValues];
    }

    if (categoryItem) {
        count = iterateOverCategory(categoryItem, allFacetsProductsValues, count);
    }

    return count;
};

const iterateOverCategory = (obj, allFacetsProductsValues, count) => {
    if (obj.filters && obj.customFields.can_have_children !== true) {
        for (const filter of obj.filters) {
            const args = filter.args;
            for (const arg of args) {
                if (arg.name === "facetValueIds") {
                    const values = JSON.parse(arg.value);
                    const number = Number(values[0]);

                    const matchingFacetValues = allFacetsProductsValues.filter(
                        (facetValue) => facetValue.id === number.toString()
                    );

                    count += matchingFacetValues.length;
                }
            }
        }
    }

    if (obj.children) {
        for (const child of obj.children) {
            count = iterateOverCategory(child, allFacetsProductsValues, count);
        }
    }

    return count;
};
