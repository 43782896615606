import { useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

export const useAdvicedUpdate = () => {
    const query = gql`
        mutation updateUser($ref: String!, $advicedUpdate: Boolean) {
            updateUser(ref: $ref, advicedUpdate: $advicedUpdate) {
                error
                ok
            }
        }
    `;
    const [load, { data, loading, error, called }] = useMutation(query, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
    });

    return {
        called,
        update: ({ ref, value }) => {
            if (load) {
                load({ variables: { ref, advicedUpdate: value } });
            }
        },
        loading,
        error: error || data?.updateUser?.error,
        ready: called && !loading && !error,
        success: data?.updateUser?.ok || false,
    };
};
