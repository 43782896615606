import React, { useContext } from "react";

import { EditorContext } from "../../../../../contexts/editor";
import Widget from "../../../../Widget";
import { coverElement, highlightElement } from "./utils";

const TopBar = ({ title, config, device: forceDeviceType, width: forceWidth, templating }) => {
    const { deviceType, editorSize } = useContext(EditorContext);

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const css = templating?.css;

    const highlightActive = css?.highlight;
    const highlightCurrent = highlightActive === "section1";

    const topBar = css?.items?.section1;

    const topBarProps = {
        ...deviceProps,
        config,
        type: "TOPBAR",
        style: {
            font: templating?.titleFont,
            fgColor: topBar?.fgColor,
            bgColor: topBar?.bgColor,
            status: {
                active: {
                    fgColor: topBar?.iconColor,
                },
            },
        },
        texts: { title },
    };

    const styles = {};
    if (highlightActive && highlightCurrent) {
        Object.assign(styles, highlightElement());
    }

    return (
        <div style={styles}>
            <div style={highlightActive && !highlightCurrent ? coverElement() : null}>
                <Widget {...topBarProps} />
            </div>
        </div>
    );
};

export default TopBar;
