import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setColumns,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setRowGroups,
    setCleaningStaff,
    setCustomError,
    cleanActiveFilters,
    setRefreshData,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
//Model
import Cleaning from "./models/Cleaning";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";

const ListCleaning = () => {
    const { t } = useTranslation();
    const {
        currentPage,
        perPage,
        filterQuery,
        sortCriteria,
        refreshData,
        loading: loadingTable,
    } = useSelector((state) => state.table);
    const [lastFilterQuery, setLastFilterQuery] = useState(null);

    //Data
    const sectionName = "list-cleaning";
    let categoriesList = [""];
    const cleanersList = [""];

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_CLEANING_INFO = gql`{
    cz_cleaning: cz_cleaning(page: ${currentPage}, size: ${perPage}${sortCriteria}${filterQuery}) {
      info { count }
      results {
        area { id lastCleaning name responsibleID responsibleName }
        categoryID
        categoryName}}
    cz_categories: cz_categories(page: 1, size: 10000){
      info { count }
      results { id name }}
    cleaning_staff: staffUsers(page: 1, size: 1000, filter: {roles:"1"}){
      info{ count }
      results{ fullName id}}
      }`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_CLEANING_INFO, {
        pollInterval: 300000,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        dispatch(setLoading(true));
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (lastFilterQuery && filterQuery === lastFilterQuery) {
            dispatch(setLoading(false));
        } else {
            setLastFilterQuery(filterQuery);
        }
        // eslint-disable-next-line
    }, [loadingTable, filterQuery]);

    useEffect(() => {
        dispatch(setLoading(true));
        if (!loading && !error && data && data.cz_cleaning && networkStatus === 7) {
            //Get data

            const dataItems = arrangeData(data, sortCriteria, categoriesList, cleanersList);
            const availableFilters = GetAvailableFilters(sectionName, categoriesList, cleanersList);
            const tableHeaders = GetTableHeaders(sectionName);
            //Add to store
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.cz_cleaning.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setCustomError(t("no areas available")));
            //Show row groups if no sort selected
            if (sortCriteria === "") {
                dispatch(setRowGroups(true));
            }

            if (dataItems.length > 0) {
                //Column adjust if there are cell with only icon on it
                if (dataItems[0].withAction) {
                    dispatch(setColumns(dataItems[0].info.length - 1));
                } else {
                    dispatch(setColumns(dataItems[0].info.length));
                }
            }
            dispatch(setExtendedInfo(false));
            dispatch(setCleaningStaff(cleanersList));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        console.log(data);
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(setLoading(loading));
        // eslint-disable-next-line
    }, [loading]);
};

const arrangeData = (data, sortCriteria, categoriesList, cleanersList) => {
    const tempArray = [];
    // eslint-disable-next-line
    data.cz_cleaning.results.map((category) => {
        const { area } = category;
        // eslint-disable-next-line
        area.map((areaCleaning, index) => {
            tempArray.push(Cleaning(category, areaCleaning, index, sortCriteria));
        });
    });
    //Get categories for filter
    // eslint-disable-next-line
    data.cz_categories.results.map((category) => {
        categoriesList.push([category.name, category.id, "int"]);
    });

    // eslint-disable-next-line
    data.cleaning_staff.results.map((cleaner) => {
        cleanersList.push([cleaner.fullName, cleaner.id, "int"]);
    });
    return tempArray;
};

export default ListCleaning;
