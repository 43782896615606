import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import useSelect from "../../components/useSelect";
//Actions
import { setActiveHotel, setProjectRef } from "./../../actions/hotelActions";
//Utils
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";
import { Session } from "../../hooks/Utils/Session";
import { capitalizeFirst, normalizeToSearch, parseBoolean } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { setDropdownMenu, setSelectingHotelDropdown } from "../../actions/uiActions";

const HotelsDropdown = () => {
    const { i18n } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const projectRef = useSelector((state) => state.hotel.ref);

    //Data
    const projectsData = Session.getSessionProp("projects");
    let availableHotels = {};
    if (projectsData) {
        availableHotels = JSON.parse(Session.getSessionProp("projects"));
    }
    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};
    const Select = useSelect({ value: currentHotel });

    //States
    const [selectedProject, setSelectedProject] = useState("");
    const [isGlobal, setIsGlobal] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsFiltered, setOptionsFiltered] = useState(options);
    const [valSearchHotel, setValSearchHotel] = useState("");

    //Actions
    const dispatch = useDispatch();

    const clickHandler = (e) => {
        const currentHotel = {
            id: e.currentTarget.dataset.id,
            name: e.currentTarget.dataset.name,
            location: e.currentTarget.dataset.location,
            ref: e.currentTarget.dataset.ref,
            keyBrand: e.currentTarget.dataset.keybrand ? e.currentTarget.dataset.keybrand : null,
            timeZone: e.currentTarget.dataset.timezone ? e.currentTarget.dataset.timezone : null,
            oldmanagerurl: e.currentTarget.dataset.oldmanagerurl,
            oldmanagerusers: parseBoolean(e.currentTarget.dataset.oldmanagerusers),
            zMobileURL: e.currentTarget.dataset.zmobileurl,
        };
        dispatch(setProjectRef(e.currentTarget.dataset.ref, parseBoolean(e.currentTarget.dataset.global)));
        Select.close();
        setIsGlobal(parseBoolean(e.currentTarget.dataset.global));
        setSelectedProject(currentHotel);
        dispatch(setSelectingHotelDropdown(true));
    };

    //API
    const MUTATION = gql`
        ${getLoginProjectMutation(isGlobal, selectedProject.ref, i18n)}
    `;
    const [hotelLogin, { data }] = useMutation(MUTATION);

    //Listeners
    useEffect(() => {
        let _token = null;
        if (data && data.loginProject && data.loginProject.isOk) {
            _token = data.loginProject.token;
        } else if (data && data.loginCorporate && data.loginCorporate.isOk) {
            _token = data.loginCorporate.token;
        } else if (data && data.error !== "") {
            toast.error(langStrings["cant obtain your projects"]);
        }
        if (_token) {
            Session.setToken(_token);
            Session.setProject(selectedProject);
            window.location.href = "/";
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setActiveHotel(Select.value));
    }, [Select.value, dispatch]);

    useEffect(() => {
        if (selectedProject !== "") {
            hotelLogin();
        }
        // eslint-disable-next-line
    }, [selectedProject]);

    useEffect(() => {
        if (langStrings) {
            setOptions(getHotelOptions(availableHotels, langStrings));
        }
        // eslint-disable-next-line
    }, [langStrings]);

    useEffect(() => {
        if (options && options.length > 0) {
            setOptionsFiltered(options);
            if (options.length === 1) {
                const currentHotel = {
                    id: options[0].id,
                    name: options[0].name,
                    location: options[0].location,
                    ref: options[0].ref,
                    keyBrand: options[0].keyBrand,
                    timeZone: options[0].timeZone,
                    oldmanagerurl: options[0].oldmanagerurl,
                    oldmanagerusers: options[0].oldmanagerusers,
                    zMobileURL: options[0].zMobileURL,
                };
                Session.setProject(currentHotel);
            }
        }
    }, [options]);

    const handleChangeSearch = (e) => {
        const { value } = e.target;
        setValSearchHotel(value);
        let optionsClone = options;
        if (value && value !== "") {
            optionsClone = optionsClone.filter((option) =>
                normalizeToSearch(option.name).includes(normalizeToSearch(value))
            );
        }
        setOptionsFiltered(optionsClone);
    };

    useEffect(() => {
        const inputSearch = document.querySelector(`#SearchHotel_input`);
        if (inputSearch) {
            inputSearch.focus();
            document.getElementById("SearchHotel_input").focus();
        }
    }, [optionsFiltered]);

    useEffect(() => {
        if (Select.isOpen) {
            dispatch(setDropdownMenu("hotels"));
        } else {
            dispatch(setDropdownMenu(null));
        }
        // eslint-disable-next-line
    }, [Select.isOpen]);

    return (
        <div className="relative">
            <Select.Handler id={"hotel-dropdown-selected"}>
                <div className="px-2 flex justify-between items-center leading-tight whitespace-no-wrap">
                    <div id="hotel-dropdown-select-container">
                        <div>
                            <strong id="hotel-dropdown-selected-name-hotel">
                                {langStrings && projectRef === "CORPORATE"
                                    ? `${capitalizeFirst(langStrings["global-management"])}`
                                    : currentHotel.name}
                            </strong>
                        </div>
                        <div className="text-gray-700" id="hotel-dropdown-selected-location">
                            {langStrings && projectRef === "CORPORATE"
                                ? `${capitalizeFirst(langStrings["global-management-desc"])}`
                                : currentHotel.location}
                        </div>
                    </div>
                    {options && options.length > 1 && (
                        <i
                            id="hotel-dropdown-chevron-icon"
                            key={"chevron-hotel-icon"}
                            className={`icon-chevron pl-2 text-gray-700 px-2 animated ${
                                Select.isOpen && "-rotate-180"
                            }`}
                        ></i>
                    )}
                </div>
            </Select.Handler>
            <AnimatePresence key={"hotel-dropdown"}>
                <Select.Body
                    className={`dropdown-body absolute max-h-80 ${
                        options && options.length > 4 && "overflow-y-scroll"
                    }`}
                >
                    {Session.getSessionProp("currentAccount") &&
                    JSON.parse(Session.getSessionProp("currentAccount")).name ? (
                        <div className="text-center p-4 text-gray-800 border-b border-gray-300">
                            {JSON.parse(Session.getSessionProp("currentAccount")).name}
                        </div>
                    ) : null}
                    {options && options.length > 6 ? (
                        <div className="py-2 px-4">
                            <div className="w-full mr-8 relative">
                                <input
                                    placeholder={
                                        langStrings
                                            ? `${capitalizeFirst(langStrings["search"])} ${langStrings["hotel"]}`
                                            : ""
                                    }
                                    autoFocus
                                    type="text"
                                    className={`w-full pr-10 bg-gray-200 rounded hover:text-zafiro-400  text-gray-900  p-2 pl-4 phNoTransform`}
                                    name="SearchHotel"
                                    id="SearchHotel_input"
                                    onChange={handleChangeSearch}
                                    value={valSearchHotel}
                                ></input>
                                <span className="field-icon icon-search"></span>
                            </div>
                        </div>
                    ) : null}
                    {optionsFiltered.map((option, index) => {
                        return (
                            <motion.ul
                                id={`${option.name}-submenu`}
                                key={`${option.name}-submenu`}
                                initial="hidden"
                                animate="visible"
                                exit="close"
                                variants={{
                                    visible: {
                                        opacity: 1,
                                        height: "auto",
                                        display: "block",
                                        transition: {
                                            when: "beforeChildren",
                                            staggerChildren: 0.03,
                                        },
                                    },
                                    hidden: {
                                        opacity: 0,
                                        height: 0,
                                        display: "hidden",
                                    },
                                    close: {
                                        opacity: 0,
                                        height: 0,
                                        display: "hidden",
                                        transition: {
                                            when: "afterChildren",
                                            delay: 0.3,
                                        },
                                    },
                                }}
                                className={`block ${Select.isOpen ? "" : "hidden"}`}
                            >
                                <div
                                    className="dropdown-item"
                                    key={option.id}
                                    data-id={option.id}
                                    data-name={option.name}
                                    data-location={option.location}
                                    data-ref={option.ref}
                                    data-keybrand={option.keyBrand}
                                    data-timezone={option.timeZone}
                                    data-oldmanagerurl={option.oldmanagerurl}
                                    data-oldmanagerusers={option.oldmanagerusers}
                                    data-global={option.ref === "CORPORATE" ? true : false}
                                    data-zmobileurl={option.zMobileURL}
                                    onClick={clickHandler}
                                >
                                    <span
                                        className={`w-full block font-bold ${
                                            currentHotel.id === option.id ? "text-blue-300" : "text-gray-900"
                                        }`}
                                    >
                                        {option.name}
                                    </span>
                                    <span
                                        className={`w-full block ${
                                            currentHotel.id === option.id ? "text-blue-200" : "text-gray-400"
                                        }`}
                                    >
                                        {option.location}
                                    </span>
                                </div>
                            </motion.ul>
                        );
                    })}
                </Select.Body>
            </AnimatePresence>
        </div>
    );
};

const getHotelOptions = (availableHotels, langString) => {
    //Returns projects info
    let res = [];
    if (availableHotels && availableHotels.length > 0) {
        // eslint-disable-next-line
        availableHotels.map((hotel) => {
            res.push({
                id: hotel.id,
                name: hotel.id === 0 ? capitalizeFirst(langString["global-management"]) : hotel.name,
                location: hotel.id === 0 ? capitalizeFirst(langString["global-management-desc"]) : hotel.location,
                ref: hotel.ref,
                keyBrand: hotel.keyBrand,
                timeZone: hotel.timeZone,
                oldmanagerurl: hotel.managerURL,
                oldmanagerusers: parseBoolean(hotel.managerUsers),
                zMobileURL: hotel.zMobileURL,
            });
        });
    }
    return res;
};

const getLoginProjectMutation = (isGlobal, projectRef, i18n) => {
    if (isGlobal) {
        return `
        mutation {
            loginCorporate(
                chainRef: "${Session.getSessionProp("chainRef")}",
                userRef: "${Session.getSessionProp("userRef")}",
                chainId: "${Session.getSessionProp("chainId")}",
                lang:  "${i18n.language.split("-")[0]}"
                ) {
            isOk
            token
            }
        }
        `;
    }
    return `
    mutation {
        loginProject(
            chainRef: "${Session.getSessionProp("chainRef")}",
            projectRef: "${projectRef}",
            userRef: "${Session.getSessionProp("userRef")}",
            chainId: "${Session.getSessionProp("chainId")}",
            lang:  "${i18n.language.split("-")[0]}"
            ) {
        isOk
        token
        }
    }
    `;
};

export default HotelsDropdown;
