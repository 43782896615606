import React, { useState, useEffect } from "react";
import {
    MOBILE_LINKS_AND_SOCIAL_NETWORKS,
    validateMobileSocialMediaCard,
    ACTIONS_MOBILE_SETTINGS,
} from "./MobileSettingsUtils";
import { useTranslation } from "react-i18next";
import UseSocialMediaSelector from "components/Inputs/UseSocialMediaSelector";
import UseButton from "components/useButton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import { Session } from "hooks/Utils/Session";

const MobileSettingsLinksAndSocialNetworks = ({ cardInEdition, setCardInEdition, data }) => {
    const { t } = useTranslation();
    const [socialMediaSelected, setSocialMediaSelected] = useState([]);
    const [validations, setValidations] = useState({});
    const [reset, setReset] = useState(false);
    const dispatch = useDispatch();
    const [dataSocialNetworks, setDataSocialNetworks] = useState([]);
    const [dataSocialNetworksSelector, setDataSocialNetworksSelector] = useState([]);
    const corporateAccess = Session.getSessionProp("projectRef") === "CORPORATE";

    useEffect(() => {
        if (cardInEdition === null || cardInEdition !== MOBILE_LINKS_AND_SOCIAL_NETWORKS) {
            setSocialMediaSelected([]);
        }
    }, [cardInEdition]);

    const fetchedDataSocialNetworks = data?.config?.socialNetworks;
    useEffect(() => {
        if (reset) {
            setReset(false);
        }
    }, [reset]);

    useEffect(() => {
        if (fetchedDataSocialNetworks) {
            setDataSocialNetworks(fetchedDataSocialNetworks);
        }
    }, [fetchedDataSocialNetworks]);

    return (
        <div className="flex-1 rounded p-5 bg-white ">
            <div className=" flex justify-between p-1 text-xl font-bold border-b border-gray-200">
                <span>{t("links-and-social-networks")}</span>
                {((corporateAccess && data?.isMultiProperty) || (!corporateAccess && !data.isMultiProperty)) &&
                cardInEdition !== MOBILE_LINKS_AND_SOCIAL_NETWORKS ? (
                    <button
                        id="mobile-settings-links-and-social-networks-edit-button"
                        onClick={() => setCardInEdition(MOBILE_LINKS_AND_SOCIAL_NETWORKS)}
                        className=" text-gray-800 focus:outline-none icon icon-edit"
                    ></button>
                ) : null}
            </div>
            <div>
                <div className=" block mt-2 mb-6">
                    {t(
                        (!corporateAccess && data.isMultiProperty) || (corporateAccess && !data.isMultiProperty)
                            ? "links-and-social-network-that-will-appear"
                            : "add-links-and-social-networks-will-appear-in-the-profile-app"
                    )}
                </div>
                <>
                    <div>
                        <UseSocialMediaSelector
                            inEdition={cardInEdition === MOBILE_LINKS_AND_SOCIAL_NETWORKS}
                            data={dataSocialNetworks}
                            socialMediaSelected={socialMediaSelected}
                            setSocialMediaSelected={setSocialMediaSelected}
                            validations={validations}
                            setValidations={setValidations}
                            setDataSelected={setDataSocialNetworksSelector}
                            reset={reset}
                            cardInEdition={cardInEdition}
                        />
                        {dataSocialNetworks.length === 0 && cardInEdition !== MOBILE_LINKS_AND_SOCIAL_NETWORKS ? (
                            <p className=" text-gray-800">{t("no-link-social-network-configured-yet")}</p>
                        ) : null}
                    </div>
                    {cardInEdition === MOBILE_LINKS_AND_SOCIAL_NETWORKS ? (
                        <div className=" flex justify-end mt-8">
                            <UseButton
                                action={() => {
                                    setCardInEdition(null);
                                    setReset(true);
                                    setValidations({});
                                }}
                                adjust="mr-2"
                                buttonColor="btn-white"
                                buttonName={t("cancel")}
                                id={"mobile-settings-social-networks-cancel-button"}
                            />
                            <UseButton
                                action={() => {
                                    const { validations, error } =
                                        validateMobileSocialMediaCard(dataSocialNetworksSelector);
                                    setValidations(validations);

                                    if (error) {
                                        toast.error(t("input error"));
                                    } else {
                                        dispatch(
                                            setExecuteQuery({
                                                action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_SOCIAL_MEDIA,
                                                params: { dataSocialNetworksSelector, id: data?.id },
                                            })
                                        );
                                    }
                                }}
                                id={"mobile-settings-social-networks-save-button"}
                                adjust="mr-2"
                                buttonColor="btn-blue"
                                buttonName={t("save")}
                            />
                        </div>
                    ) : null}
                </>
            </div>
        </div>
    );
};

export default MobileSettingsLinksAndSocialNetworks;
