import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { setRefreshData } from "actions/tableActions";
import { setExecuteQuery, setRefreshContentShopData } from "actions/sectionActions";

import { useCategoryUpdate } from "hooks/Data/useShopConfig";
import { getAssetUrl } from "hooks/Utils/SalesUtils";

import SettingsCard from "components/SettingsCard";
import ImageColorInput from "components/ImageColorInput";
import Loading from "components/Loading";

import { ShopContext } from "contexts/Sales/Shop";
import { SalesContextDeprecated as CategoryContext } from "contexts/Sales";

const ShopSettingsImage = ({ isCategory = false }) => {
    const { t } = useTranslation();

    const updateCategoryImage = useCategoryUpdate();

    const { idCat } = useParams();

    const { id, token, coverURL, updateConfig, refresh } = useContext(ShopContext);
    const { data } = useContext(CategoryContext);

    const category = data?.collection;

    const storedData = isCategory ? category?.customFields?.image_url : coverURL;
    const storedDataIsColor = storedData?.includes("rgba(");
    const defaultColor = storedDataIsColor ? storedData : null;
    const defaultImage = !storedDataIsColor ? storedData : null;

    const dispatch = useDispatch();

    const refreshData = useSelector((state) => state?.sectionContent?.refreshData);

    const [uploadingFile, setUploadingFile] = useState(false);

    useEffect(() => {
        if (refreshData) {
            if (!isCategory) {
                refresh();
            }
            dispatch(setRefreshData(false));
            setUploadingFile(false);
        }
    }, [refreshData]);

    useEffect(() => {
        if (uploadingFile) {
            dispatch(
                setExecuteQuery({
                    action: isCategory ? "create-asset-image-category" : "create-asset-image",
                    params: {
                        file: uploadingFile,
                        idShop: id,
                        idCategory: idCat,
                        token,
                    },
                })
            );
            dispatch(setRefreshContentShopData({ action: "uploading" }));
        }
    }, [uploadingFile]);

    const saveColor = (color) => {
        if (isCategory) {
            updateCategoryImage({
                id: idCat,
                imageURL: color,
            }).catch((error) => {
                console.error("Failed to update category color", error);
                toast.error(t("mutation-error"));
            });
        } else {
            updateConfig({ coverURL: color });
        }
    };

    return (
        <SettingsCard title={t("image")} info={t("recommend-resolution-minimum-77")} className="relative">
            <div className="pt-8">
                {uploadingFile ? (
                    <Loading />
                ) : (
                    <ImageColorInput
                        id={"sales-select-image-component"}
                        color={defaultColor}
                        image={defaultImage ? getAssetUrl(defaultImage) : null}
                        fileHandler={(file) => {
                            setUploadingFile(file);
                        }}
                        onChange={({ image, color }) => {
                            if (!image) {
                                saveColor(color);
                            }
                        }}
                    />
                )}
            </div>
        </SettingsCard>
    );
};

export default ShopSettingsImage;
