import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Actions
import { setModalContent, openModal } from "../../../../actions/uiActions";
import { setActionName, setActionItemAffected } from "../../../../actions/globalActions";

//Utils
import {
    PROPERTY_APPEARANCE_TYPE,
    CORPORATE_APPEARANCE_TYPE,
    CORPORATE_REF,
} from "../../../../hooks/Utils/AppearancesUtils";
import AppearanceChangeName from "../../../../hooks/GraphqlCalls/Appearances/Modals/AppearanceChangeName";
import ReactTooltip from "components/ReactTooltip";
import TreeDesigns from "../../../../hooks/GraphqlCalls/Appearances/Modals/TreeDesigns";
import { useLocation } from "react-router-dom";

const AppearanceHeadMenu = () => {
    //API
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const [viewWithMenu, setViewWithMenu] = useState(location.pathname === "/design/appearances/view" ? true : false);

    // t("{{capitalize, capitalize}}", { capitalize: t(item.name) })

    //States
    const projectRef = useSelector((state) => state.hotel.ref);
    const { appearanceSelected } = useSelector((state) => state.sectionContent);

    useEffect(() => {
        const container = document.getElementById("appearancesHeadMenu").parentElement;
        setTimeout(() => {
            if (container.classList.contains("px-14")) {
                container.classList.remove("px-14");
                container.classList.remove("py-10");
            }
        }, 1);

        return () => {
            if (viewWithMenu) {
                const container = document.getElementById("appearancesHeadMenu").parentElement;
                setTimeout(() => {
                    container.classList.add("px-14");
                    container.classList.add("py-10");
                }, 1);
            }
        };
        // eslint-disable-next-line
    }, [viewWithMenu]);

    useEffect(() => {
        setViewWithMenu(location.pathname === "/design/appearances/view" ? true : false);
        // eslint-disable-next-line
    }, []);

    const showTreeDesign = () => {
        if (appearanceSelected && appearanceSelected.appliedIn > 0) {
            dispatch(setModalContent(TreeDesigns(appearanceSelected.designsTree, appearanceSelected.name, t)));
            //Launch modal
            dispatch(openModal());
        }
    };

    const nameAppaerenceDiv = () => {
        return (
            <>
                <div className=" max-w-sm px-4 flex gap-2 justify-center items-center first-capital border-l-0 border-r border-gray-200">
                    <i
                        className={` w-1/6 icon icon-info cursor-help text-gray-700 text-3xl table-cell align-middle`}
                        data-tip={
                            appearanceSelected.type === CORPORATE_APPEARANCE_TYPE
                                ? t("general-appearances")
                                : t("property-appearances")
                        }
                    ></i>
                    <div className="w-4/6">
                        <div className="text-gray-800">
                            {t("{{capitalize, capitalize}}", { capitalize: t("appearance") })}
                        </div>
                        <div className="font-black w-full truncate">{appearanceSelected.name}</div>
                    </div>
                    {editNameicon()}
                </div>
                {!viewWithMenu && appearanceSelected && appearanceSelected.appliedIn !== undefined ? (
                    <div className="py-5 px-4 flex items-center first-capital border-l-0 border-gray-200">
                        {t("applied-in")}:{" "}
                        {appearanceSelected && appearanceSelected.appliedIn > 0 ? (
                            <span
                                className={` font-black mx-2 text-blue-300 cursor-pointer`}
                                onClick={() => {
                                    showTreeDesign();
                                }}
                            >
                                {appearanceSelected.appliedIn} {t("designs")}
                            </span>
                        ) : (
                            "-"
                        )}
                    </div>
                ) : null}
                <ReactTooltip place="bottom" type="light" html={true} border={true} borderColor="#D3DAE1" />
            </>
        );
    };
    const editNameicon = () => {
        if (
            !viewWithMenu &&
            ((projectRef !== CORPORATE_REF && appearanceSelected.type === PROPERTY_APPEARANCE_TYPE) ||
                (projectRef === CORPORATE_REF && appearanceSelected.type === CORPORATE_APPEARANCE_TYPE))
        ) {
            return (
                <i
                    id="appearances-menu-edit-name"
                    className={` w-1/6 icon icon-edit sidebar-menu-link cursor-pointer text-gray-700 text-3xl table-cell align-middle`}
                    onClick={() => {
                        openChangeNameModal();
                    }}
                ></i>
            );
        } else {
            return null;
        }
    };

    const openChangeNameModal = () => {
        dispatch(setActionName("appearance-edit-name"));
        dispatch(setActionItemAffected([appearanceSelected.id]));
        dispatch(
            setModalContent(
                AppearanceChangeName(appearanceSelected.name, t("edit-name"), {
                    appearanceID: appearanceSelected.id,
                    appearanceType: appearanceSelected.type,
                })
            )
        );
        //Launch modal
        dispatch(openModal());
    };

    return (
        <div
            id="appearancesHeadMenu"
            className={`${!viewWithMenu ? "fixed z-202 w-full" : null} flex mt-0.5 bg-white  border-b border-gray-200 `}
        >
            {!viewWithMenu ? (
                <NavLink
                    className="sidebar-menu-link py-5 px-4 flex items-center first-capital border-l-0 border-r border-gray-200"
                    key={"/design/appearances/view"}
                    to={"/design/appearances/view"}
                >
                    <div className="flex items-center w-full justify-between relative">
                        <div className="flex items-center ">
                            <i className={`w-auto icon-chevron-left inline-block hover:text-blue-200 `}></i>
                            <span className="inline-block ml-2 first-capital">{t("back-to-menu")}</span>
                        </div>
                    </div>
                </NavLink>
            ) : null}
            {appearanceSelected ? nameAppaerenceDiv() : null}
        </div>
    );
};

export default AppearanceHeadMenu;
