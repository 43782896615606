import React, { useRef, useEffect, useState } from "react";

/**
 * @description A input number component
 * @param {string} id - The id of the input
 * @param {string} value - The initial value of the input
 * @param {string} placeholder - The placeholder of the input
 * @param {function} onChange - The function to call when the input value changes
 * @param {boolean} autoFocus - If the input should be focused on render
 * @param {boolean} disabled - If the input should be disabled
 * @param {boolean} required - If the input is required
 * @param {string} className - The extended class name of the input
 * @returns {JSX.Element} The input number component
 * @example
 */
const InputNumber = ({ id, value, placeholder, onChange, autoFocus, disabled, required, className }) => {
    const [currentValue, setCurrentValue] = useState(null);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && value !== undefined) {
            inputRef.current.value = value;
            setCurrentValue(value);
        }
        if (autoFocus) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }
    }, [inputRef.current]);

    const defaultClass = " rounded py-1 px-4 leading-7 long bg-gray-200";
    const disabledClass = disabled ? "bg-gray-300 text-gray-700" : "";
    const requiredClass = !disabled && required && !currentValue ? "border border-red-100" : "";

    return (
        <input
            ref={inputRef}
            id={id}
            type="number"
            placeholder={placeholder}
            value={currentValue}
            onChange={(e) => {
                const value = parseInt(e?.target?.value) > 0 ? parseInt(e?.target?.value) : 0;
                setCurrentValue(value);
                if (onChange) {
                    onChange(value);
                }
            }}
            disabled={disabled}
            className={`${defaultClass} ${className} ${disabledClass} ${requiredClass} `}
        />
    );
};
InputNumber.displayName = "InputNumber";

export default InputNumber;
