import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import ListAssignedUsers from "../../../../hooks/GraphqlCalls/Sales/ListAssignedUsers";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import UseSelectMultipleWithSearchOptionsQuery from "../../../useSelectMultipleWithSearchOptionsQuery";
import UseTable from "../../../Table";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import UpdateAssignedUsers from "../../../../hooks/GraphqlCalls/Sales/UpdateAssignedUsers";
import { useDispatch, useSelector } from "react-redux";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { cleanTableStore, setRefreshData } from "../../../../actions/tableActions";
import UseLoading from "../../../Table/useLoading";
import classNames from "classnames";
import { Session } from "../../../../hooks/Utils/Session";
import { SalesAssignedUsersProvider } from "./SalesContext";
import ShopHeadMenu from "./ShopHeadMenu";

const ShopAssignedUsers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { values } = useSelector((state) => state.action);

    const [addButtonDisabled, setAddButtonDisabled] = useState(false);
    const [usersToAssign, setUsersToAssign] = useState([]);
    const [usersAssigned, setUsersAssigned] = useState([]);
    const [parentData, setParentData] = useState(null);
    const [loadingParent, setLoadingParent] = useState(true);

    useEffect(() => {
        return () => dispatch(cleanTableStore());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (values && values["assigned-users-shop"] && values["assigned-users-shop"].length > 0) {
            setAddButtonDisabled(false);
        } else {
            setAddButtonDisabled(true);
        }
    }, [values]);

    useEffect(() => {
        if (
            parentData?.users?.results &&
            parentData?.shops?.results?.[0]?.users &&
            parentData?.shops?.results?.[0]?.usersInfo
        ) {
            const usersAssignedIds = parentData.shops.results[0].users;
            const usersAssigned = parentData.shops.results[0].usersInfo;
            const usersList = parentData.users.results;
            const userListExcludeManagerRoleAndSuperUsers = usersList.filter((user) => {
                return !user.managerRole && !user.ref.startsWith("superuser");
            });
            const usersNoAssigned = userListExcludeManagerRoleAndSuperUsers
                .filter((obj1) => !usersAssigned.some((obj2) => obj1.id === obj2.id))
                .map((obj) => ({
                    id: obj.id,
                    name: obj.email,
                    visible: true,
                    selected: false,
                }));

            setUsersAssigned(usersAssignedIds);
            setUsersToAssign(usersNoAssigned);
        }
    }, [parentData]);

    return (
        <SalesAssignedUsersProvider setParentData={setParentData} setLoadingParent={setLoadingParent}>
            <ShopHeadMenu />
            <div>
                <UseSectionHeader hideBreadcrumbs={true} title={t("assigned-users")} />
                <div className=" p-6 -mt-3 mb-8 rounded bg-white relative ">
                    <p>{t("add-the-users-that-you-want-to-access-the-shop")} </p>
                    <p>{t("users-with-the-administrator-role-would-have-access-to-all-shop-orders")}</p>
                    <span className=" block font-bold text-xs text-gray-800 my-3 ">{capitalizeFirst(t("users"))}</span>
                    <div className=" w-full flex">
                        <div className=" w-4/12 mr-10">
                            <UseSelectMultipleWithSearchOptionsQuery
                                dataSelect={{
                                    textStyle:
                                        "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                                    name: "assigned-users-shop",
                                    optionData: usersToAssign,
                                    selectPlaceHolder: "select-users",
                                    cStyle: "w-full",
                                    sort: true,
                                    type: "selectMultipleWithSearch",
                                    noSelect: true,
                                    id: "assigned-users-select",
                                }}
                                key={usersToAssign}
                            />
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    dispatch(
                                        setExecuteQuery({
                                            action: "add-assign-user",
                                            params: {},
                                        })
                                    );
                                }}
                                className={classNames(
                                    " rounded py-2 px-10 text-white focus:outline-none  ",
                                    addButtonDisabled ? " bg-gray-300 cursor-default " : "bg-zafiro-600 cursor-pointer"
                                )}
                                id={"assigned-users-add-user-button"}
                                disabled={addButtonDisabled}
                            >
                                {capitalizeFirst(t("add-user"))}
                            </button>
                        </div>
                    </div>
                    {loadingParent ? (
                        <div className=" flex items-center justify-center absolute h-full w-full bg-white bg-opacity-75 top-0 left-0">
                            <UseLoading adjust={"contents"} />
                        </div>
                    ) : null}
                </div>
                <UseTable />
                <UpdateAssignedUsers usersAssigned={usersAssigned} />
                <ListAssignedUsers usersAssigned={usersAssigned} />
            </div>
        </SalesAssignedUsersProvider>
    );
};

export default ShopAssignedUsers;
