import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";

import { setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction, changeGlobalAction } from "../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import EnterRecoveryCodeModal from "../../../components/Section/Auth/Modals/EnterRecoveryCodeModal";
import NewPasswordModal from "../../../components/Section/Auth/Modals/NewPasswordModal";
import { useTranslation } from "react-i18next";

const UpdatePassword = ({ client }) => {
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language.split("-")[0];

    const dispatch = useDispatch();

    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    const [lastEmail, setLastEmail] = useState(null);
    const [lastCode, setLastCode] = useState(null);
    const [hash, setHash] = useState(null);
    const [codeRefreshCount, setCodeRefreshCount] = useState(0);

    const onErr = (err) => {
        dispatch(setExecuteQuery(null));
        dispatch(showGlobalLoading(false));
        console.error(err);
        toast.error(t("mutation-error"));
    };

    const [sendResetCode, { data: codeData, refetch: sendResetCodeRefetch }] = useLazyQuery(QUERY_SEND_RESET_CODE, {
        fetchPolicy: "no-cache",
        onError: onErr,
    });

    const sendResetCodeAgain = () => {
        setCodeRefreshCount(codeRefreshCount + 1); // Reset the data to trigger the useEffect when refetch return the same data (when has is always null)
        sendResetCodeRefetch();
    };

    const [verifyResetCode] = useLazyQuery(QUERY_VERIFY_RESET_CODE, {
        fetchPolicy: "no-cache",
        onError: onErr,
        onCompleted: (data) => {
            if (data.verifyResetCode) {
                dispatch(changeGlobalAction({ actionName: "recovery-new-password" }));
                dispatch(setModalContent(NewPasswordModal(t)));
            }
        },
    });

    const [resetForgottenPassword] = useMutation(QUERY_RESET_FORGOTTEN_PASSWORD, {
        onError: onErr,
        onCompleted: (data) => {
            if (data?.resetForgottenPassword?.okUpdate) {
                toast.success(t(executeQuery?.toastMsg || "operation-successful"));
                setLastEmail(null);
                setLastCode(null);
                setHash(null);
                dispatch(setModalContent(false));
                dispatch(closeModal());
                dispatch(cleanAction());
            } else {
                toast.error(data?.resetForgottenPassword?.error || t("mutation-error"));
            }
        },
    });

    useEffect(() => {
        if (codeData) {
            setHash(codeData.sendResetCode?.hash);
            dispatch(changeGlobalAction({ actionName: "check-recovery-code" }));
            dispatch(setModalContent(EnterRecoveryCodeModal(langStrings, lastEmail)));
        }
    }, [codeData, codeRefreshCount]);

    useEffect(() => {
        if (executeQuery) {
            switch (executeQuery.action) {
                case "send-recovery-email":
                    const email = actionData?.values?.mail || lastEmail;
                    if (email === lastEmail) {
                        sendResetCodeAgain();
                    } else {
                        setLastEmail(email);
                        sendResetCode({ variables: { email: email, lang: currentLang } });
                    }
                    dispatch(setExecuteQuery(null));
                    break;
                case "check-recovery-code":
                    const code = actionData?.values?.recoveryCode;
                    setLastCode(code);
                    verifyResetCode({ variables: { email: lastEmail, code: code, hash: hash } });
                    dispatch(setExecuteQuery(null));
                    break;
                case "recovery-new-password":
                    const pass = actionData?.values?.["new-password"];
                    resetForgottenPassword({
                        variables: {
                            email: lastEmail,
                            lang: currentLang,
                            code: lastCode,
                            hash: hash,
                            newPassword: pass,
                        },
                    });
                    dispatch(setExecuteQuery(null));
                    break;
                default:
            }
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    return null;
};

const QUERY_SEND_RESET_CODE = gql`
    query sendResetCode($email: String!, $lang: LanguageCode!) {
        sendResetCode(email: $email, lang: $lang) {
            hash
        }
    }
`;

const QUERY_VERIFY_RESET_CODE = gql`
    query verifyResetCode($email: String!, $code: String!, $hash: String!) {
        verifyResetCode(email: $email, code: $code, hash: $hash)
    }
`;

const QUERY_RESET_FORGOTTEN_PASSWORD = gql`
    mutation resetForgottenPassword(
        $lang: String!
        $email: String!
        $newPassword: String!
        $code: String!
        $hash: String!
    ) {
        resetForgottenPassword(lang: $lang, email: $email, newPassword: $newPassword, code: $code, hash: $hash) {
            error
            okUpdate
        }
    }
`;

export default withApollo(UpdatePassword);
