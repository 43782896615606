import React, { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

//Componentes
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { Session } from "hooks/Utils/Session";

const WidgetQR = () => {
    const { deviceType } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);

    //Store data
    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const data = Widget.ParseData(widget ? widget.data : null, deviceType);

    //State
    const [qrSrc, setQrSrc] = useState(null);

    useEffect(() => {
        if (data && (data.url || data.predefined)) {
            setQrSrc(Session.getDasUrl(`qr?w=150&data=${data.url || data.predefined}`));
        } else if (document.querySelector(`#qr_${widgetID}`)) {
            setQrSrc(null);
        }
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        if (document.querySelector(`#qr_${widgetID}`)) {
            document.querySelector(`#qr_${widgetID}`).parentNode.style.backgroundColor = qrSrc
                ? "transparent"
                : "inherit";
        }
        // eslint-disable-next-line
    }, [qrSrc]);

    return (
        <>
            {qrSrc ? (
                <img alt="" id={`qr_${widgetID}`} className="w-full h-full object-contain" src={qrSrc}></img>
            ) : (
                <EmptyWidget type={type}></EmptyWidget>
            )}
            <div className="absolute left-0 top-0 w-full h-full bg-transparent"></div>
        </>
    );
};

export default WidgetQR;
