import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { Session } from "../../../../hooks/Utils/Session";

import { vw2px, GetThemeDefault, isEmpty } from "../../../../hooks/Utils/DesignUtils";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";

const WidgetMessages = ({ insideMenu, menuStatusStyle }) => {
    const { deviceType, editorSize, itemSelected, theme } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);

    const [iconsReady, setIconsReady] = useState(false);
    const [fgColor, setFgColor] = useState(null);
    const [bgColor, setBgColor] = useState(null);
    const [iconColor, setIconColor] = useState(null);
    const [zoomInOutAnimation, setZoomInOutAnimation] = useState(false);

    const { lang } = useParams();
    const {
        gridItems,
        widgetToolbarVisible: toolbar,
        widgetToolbarVisibleTab: tab,
        widgetMenuColorsAndAnimations,
    } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (widget && widgetID) {
        widget.insideMenu = insideMenu === true ? true : false;
    }
    const widgetInsideMenu = widget && widget.insideMenu === true ? true : false;

    const size = (s) => vw2px(s, editorSize ? editorSize.width : null, deviceType === "Mobile" ? 3 : 1);

    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });
    // const defaultFgActiveColor = GetThemeDefault({ theme, id: "fgActiveColor" });
    const defaultIconColor = GetThemeDefault({ theme, id: "iconColor" });
    const defaultBorderColor = GetThemeDefault({ theme, id: "borderColor" });
    const defaultDisabledBgColor = GetThemeDefault({ theme, id: "disabledBgColor" });
    const defaultDisabledFgColor = GetThemeDefault({ theme, id: "disabledFgColor" });
    const defaultDisabledBorderColor = GetThemeDefault({ theme, id: "disabledBorderColor" });

    const disabled = itemSelected === widgetID && toolbar === "style" && tab === "disabled";
    const menuColorsReady = widgetInsideMenu && widgetMenuColorsAndAnimations ? true : false;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const radius = style && (style.radius || style.radius === 0) ? style.radius : 0;
    const fontName = style && style.fontName ? style.fontName : theme.paragraphStyle.font;
    const textSize = style && style.textSize ? style.textSize : theme.paragraphStyle.size;
    const iconSize = style && style.iconSize ? style.iconSize : theme.paragraphStyle.size;
    const iconPosition = style && style.iconPosition ? style.iconPosition : "right";
    const iconMargin = style && !isEmpty(style.iconMargin) ? style.iconMargin : 0.5;
    const alignment = style && style.alignment ? style.alignment : "center";
    const valignment = style && style.vAlignment ? style.vAlignment : "center";
    const borderColor = style && style.borderColor ? style.borderColor : defaultBorderColor;
    const borderWidth = style && style.borderWidth !== null ? style.borderWidth : 0;
    const bold = style && style.bold ? (style.bold !== false ? true : false) : theme.paragraphStyle.bold;
    const italic = style && style.italic ? (style.italic !== false ? true : false) : theme.paragraphStyle.italic;
    const underline =
        style && style.underline ? (style.underline !== false ? true : false) : theme.paragraphStyle.underline;
    const disabledBgColor = style && style.disabledBgColor ? style.disabledBgColor : defaultDisabledBgColor;
    const disabledFgColor = style && style.disabledFgColor ? style.disabledFgColor : defaultDisabledFgColor;
    const disabledBorderColor =
        style && style.disabledBorderColor ? style.disabledBorderColor : defaultDisabledBorderColor;
    const disabledBorderWidth = style && style.disabledBorderWidth !== null ? style.disabledBorderWidth : 0;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const title = data
        ? data.title && data.title[lang]
            ? data.title[lang]
            : data.title && data.title["en"]
            ? data.title["en"]
            : data.title && data.title.text
            ? data.title.text
            : ""
        : "";
    const showIcon = data ? data.showIcon && iconsReady : true;
    const icon =
        data && data.icon
            ? {
                  icon: data.icon,
                  lib: data.lib || "fa",
              }
            : null;

    const src = data ? (data.libraryRef ? getFromDAS(data.libraryRef, lang) : data.externalUrl) : null;
    const bgSize = data
        ? data.adjust === "cover-adjust"
            ? "100% 100%"
            : data.adjust
            ? data.adjust
            : "cover"
        : "cover";

    useEffect(() => {
        document.querySelector(`#widget_zone_${widgetID}`).style.backgroundColor = "transparent";
        document.querySelector(`#widget_zone_${widgetID}`).style.borderColor = "";
        document.querySelector(`#widget_zone_${widgetID}`).style.borderWidth = 0;
        document.querySelector(`#widget_zone_${widgetID}`).style.borderRadius = 0;
        setFgColor(getColor("fgColor", "fgFocusColor", "fgFocusColor", defaultFgColor));
        setBgColor(getColor("bgColor", "bgFocusColor", "bgFocusColor", defaultBgColor));
        setIconColor(getColor("iconColor", "iconFocusColor", "iconFocusColor", defaultIconColor));
        // eslint-disable-next-line
    }, [gridItems, widgetID]);

    useEffect(() => {
        library.add(fas, fab);
        setIconsReady(true);
    }, []);

    useEffect(() => {
        setFgColor(getColor("fgColor", "fgFocusColor", "fgFocusColor", defaultFgColor));
        setBgColor(getColor("bgColor", "bgFocusColor", "bgFocusColor", defaultBgColor));
        setIconColor(getColor("iconColor", "iconFocusColor", "iconFocusColor", defaultIconColor));
        setZoomInOutAnimation(
            menuStatusStyle &&
                widgetInsideMenu &&
                widgetMenuColorsAndAnimations &&
                widgetMenuColorsAndAnimations.zoomInOutAnimation &&
                menuStatusStyle &&
                menuStatusStyle === "focusColor"
        );
        // eslint-disable-next-line
    }, [menuColorsReady, menuStatusStyle, widgetMenuColorsAndAnimations]);

    const getColor = (styleColor, styleFocusColor, menuColor, defaultColor) => {
        return menuColorsReady
            ? menuStatusStyle && menuStatusStyle === "defaultColor"
                ? style && style[styleColor]
                    ? style[styleColor]
                    : widgetMenuColorsAndAnimations[styleColor]
                : menuStatusStyle && menuStatusStyle === "focusColor"
                ? style && style[styleFocusColor]
                    ? style[styleFocusColor]
                    : widgetMenuColorsAndAnimations[menuColor]
                : style && style[styleColor]
                ? style[styleColor]
                : widgetMenuColorsAndAnimations[styleColor]
            : style && style[styleColor]
            ? style[styleColor]
            : defaultColor;
    };

    let iconStyle = {
        color: !icon || disabled ? disabledFgColor : iconColor,
        fontSize: size(iconSize) + "px",
        display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "inline",
    };
    switch (iconPosition) {
        case "top":
            iconStyle.paddingBottom = size(iconMargin) + "px";
            break;
        case "bottom":
            iconStyle.paddingTop = size(iconMargin) + "px";
            break;
        case "left":
            iconStyle.paddingRight = size(iconMargin) + "px";
            break;
        case "right":
        default:
            iconStyle.paddingLeft = size(iconMargin) + "px";
    }

    const iconElement = <div className="icon icon-messaging-widget" style={iconStyle}></div>;

    return (
        <>
            {icon || title || src ? (
                <div
                    className={`grid place-items-${
                        alignment === "left" ? "start" : alignment === "right" ? "end" : "center"
                    } items-${
                        valignment === "top" ? "start" : valignment === "bottom" ? "end" : "center"
                    } shadow h-full p-2`}
                    style={{
                        color: disabled ? disabledFgColor : fgColor,
                        fontSize: size(textSize) + "px",
                        textAlign: alignment,
                        background: "transparent no-repeat center center",
                        backgroundColor: disabled ? disabledBgColor : bgColor,
                        backgroundImage: src ? `url('${src}')` : "",
                        backgroundSize: bgSize,
                        borderColor: disabled ? disabledBorderColor : borderColor,
                        borderWidth: (disabled ? disabledBorderWidth : borderWidth) + "px",
                        borderRadius: radius + "rem",
                    }}
                >
                    <div className={`text-center table ${zoomInOutAnimation ? "zoomInAnimation" : "zoomOutAnimation"}`}>
                        {showIcon && (iconPosition === "left" || iconPosition === "top") ? iconElement : null}
                        <div
                            style={{
                                fontFamily: `'${fontName}'`,
                                fontWeight: bold ? "bold" : 400,
                                fontStyle: italic ? "italic" : "normal",
                                textAlign: "center",
                                textDecoration: underline ? "underline" : "none",
                                display: iconPosition === "top" || iconPosition === "bottom" ? "block" : "table-cell",
                                verticalAlign: `middle`,
                            }}
                        >
                            {title}
                        </div>
                        {showIcon && (iconPosition === "right" || iconPosition === "bottom") ? iconElement : null}
                    </div>
                </div>
            ) : (
                <EmptyWidget radius={style ? style.radius : 0} type={type}></EmptyWidget>
            )}
        </>
    );
};

const getFromDAS = (libraryRef, languageRef) => {
    if (libraryRef) {
        return Session.getDasUrl(`${libraryRef}?lang=${languageRef}`);
    }
    return null;
};

export default WidgetMessages;

/*


import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

//Utils
import { WidgetContext } from "../../../../contexts/editor";

const WidgetMessages = ({ insideMenu, menuStatusStyle }) => {
    //State
    const [render, setRender] = useState(false);
    //Store data
    const { id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    let widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    if (widget) {
        widget.type = "MESSAGES";
        widget.insideMenu = insideMenu === true ? true : false;
    }
    useEffect(() => {
        //Timeout to wait widget_zone exists
        setTimeout(function () {
            setRender(true);
        }, 200);
    }, []);

    return (
        <>
            {render ? (
                <div
                    dangerouslySetInnerHTML={{
                        width: "100%",
                        height: "100%",
                        __html: widget
                            ? window.Render.widget(widget, null, {
                                  insideMenu: insideMenu === true ? true : false,
                                  statusStyle: menuStatusStyle ? menuStatusStyle : null,
                              }).outerHTML
                            : null,
                    }}
                ></div>
            ) : null}
        </>
    );
};

export default WidgetMessages;
*/
