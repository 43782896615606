import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    setAvailableFilters,
    setHeaderButtons,
    setItemsPerpage,
    setTableResults,
    setCountPosition,
    setRefreshData,
    setBatchActive,
    setSearchable,
    setCheckable,
    setHeaders,
    setLoading,
    setActions,
    setCount,
    setCustomError,
} from "../../../actions/tableActions";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import Product from "./models/Product";
import { useParams } from "react-router-dom";
// import { setAvailableFilters } from "../../../actions/tableActions";
import { executeVendureQuery } from "../../Utils/Integrations/useVendure";
import { getVendureApiData } from "../../Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import { parseBoolean } from "../../Utils/Utils";
import { Session } from "../../Utils/Session";
import { getAssetUrl, getProjectLangs, getSymbolCurrency, parseVendureTranslation } from "../../Utils/SalesUtils";
import vendureCurrencies from "../../Utils/Integrations/vendure-currencies.json";
import _ from "lodash";
import { SalesContextDeprecated } from "contexts/Sales";
import { useContext } from "react";
import { UseAddProductModal } from "components/Section/Services/Sales/modals/useAddProductModal";
import useModifyStateProduct from "components/Section/Services/Sales/modals/useModifyStateProduct";
import useDeleteProductModal from "components/Section/Services/Sales/modals/useDeleteProductModal";
import { useModalSalesBatchActions } from "components/Section/Services/Sales/modals/ModalBatchActions/useModalSalesBatchAction";
import { TYPE_BATCH_ACTION } from "components/Section/Services/Sales/modals/ModalBatchActions/utils";

const ListProducts = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data, loadingContext } = useContext(SalesContextDeprecated);

    const [products, setProducts] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [copyProductsData, setCopyProductsData] = useState([]);
    const [tokenShop, setTokenShop] = useState(null);
    const [shopData, setShopData] = useState(null);
    const [langDefault, setLangDefault] = useState("en");
    const [currencyShop, setCurrencyShop] = useState(null);
    const { id } = useParams();
    const { currentPage, perPage, sortCriteria, refreshData, activeFilters, checkedItems } = useSelector(
        (state) => state.table
    );
    const { projectLangs } = useSelector((state) => state.ui);
    const initialProductsRender = useRef(true);
    const search = activeFilters && activeFilters.search ? activeFilters.search : "";
    const state = activeFilters && activeFilters.state ? activeFilters.state : undefined;
    const SECTION_NAME = "list-products";

    const { open: openModalBatchActions } = useModalSalesBatchActions();
    const { open: openModalAddProduct } = UseAddProductModal();
    const { open: openModalModifyStateProduct } = useModifyStateProduct();
    const { open: openModalDeleteProduct } = useDeleteProductModal();

    const objTranlations = (arrayTranslations) =>
        arrayTranslations.reduce(
            (obj, item) =>
                Object.assign(obj, {
                    [item.languageCode]: { name: item.name, lang: item.languageCode },
                }),
            {}
        );

    const getFieldsFromData = (data) => {
        let arr = [];

        data.forEach((product) => {
            const objProduct = {
                id: "",
                name: "",
                tax: 0,
                price: 0,
                imgSrc: null,
                enabled: false,
            };
            if (product.id) {
                objProduct.id = product.id;
            }
            if (product.enabled) {
                objProduct.enabled = true;
            }
            if (product.translations && product.translations.length > 0) {
                const objNames = objTranlations(product.translations);
                let res = "";

                if (langDefault && objNames) {
                    if (objNames[langDefault] && objNames[langDefault].name) {
                        res = objNames[langDefault].name;
                    } else if (objNames["en"] && objNames["en"].name) {
                        res = objNames["en"].name;
                    } else {
                        for (const property in objNames) {
                            if (objNames[property] && objNames[property].name && !res) {
                                res = objNames[property].name;
                            }
                        }
                    }
                }
                objProduct.name = res;
            }
            if (
                product.variants &&
                product.variants.length > 0 &&
                product.variants[0] &&
                product.variants[0].taxRateApplied &&
                product.variants[0].taxRateApplied.value &&
                product.variants[0].taxRateApplied.value
            ) {
                objProduct.tax = product.variants[0].taxRateApplied.value;
            }
            if (
                product.variants &&
                product.variants.length > 0 &&
                product.variants[0] &&
                product.variants[0].price &&
                product.variants[0].price
            ) {
                objProduct.price = product.variants[0].price / 100;
            }

            if (product.featuredAsset && product.featuredAsset.preview) {
                objProduct.imgSrc = getAssetUrl(product.featuredAsset.preview) + "?preset=thumb";
            }
            if (product.enabled) {
                objProduct.enabled = true;
            }
            arr.push(objProduct);
        });

        if (arr.length > 0) {
            arr = _.orderBy(arr, ["name"], ["asc"]);
        }
        return arr;
    };

    const arrangeData = (data, shopId, tokenShop, openModalModifyStateProduct, openModalDeleteProduct) => {
        const products = [];

        if (data) {
            data.forEach((dataItem) => {
                products.push(
                    Product(
                        {
                            ...dataItem,
                            currency: currencyShop,
                            shopId,
                            tokenShop,
                            openModalModifyStateProduct,
                            openModalDeleteProduct,
                        },
                        t
                    )
                );
            });
        }

        return products;
    };

    useEffect(() => {
        const { channel } = data || {};
        if (channel) {
            setShopData({ ...data });
            const { currencyCode, token } = channel;
            if (currencyCode) {
                setCurrencyShop(vendureCurrencies?.currencies?.[currencyCode]);
            }
            if (token) {
                setTokenShop(token);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (loadingContext) {
            dispatch(setLoading(true));
        } else {
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [loadingContext]);

    useEffect(() => {
        if (data?.products?.items) {
            const fieldsData = getFieldsFromData(data.products.items);
            Session.setSessionProp("vendure-products", JSON.stringify(fieldsData));

            if (sortCriteria && !state && !search) {
                handlerSortCriteria(sortCriteria, fieldsData);
                setCopyProductsData(fieldsData);
            } else if (sortCriteria && (state || search)) {
                handlerSortCriteria(
                    sortCriteria,
                    filterSearchProducts(fieldsData, search, state === undefined ? undefined : parseBoolean(state))
                );
                setCopyProductsData(fieldsData);
            } else if (!sortCriteria && (state || search)) {
                setProductsData(
                    filterSearchProducts(fieldsData, search, state === undefined ? undefined : parseBoolean(state))
                );
                setCopyProductsData(fieldsData);
            } else {
                setProductsData(fieldsData);
                setCopyProductsData(fieldsData);
            }
        }
        // eslint-disable-next-line
    }, [data?.products]);

    const getProductsForPage = (products, currentPage, itemsPerPage) => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return products.slice(startIndex, endIndex);
    };

    // Functions to Sort

    const sortProductsByNumber = (objects, ascending = true, property) => {
        const sortedObjects = [...objects]; // make a copy of the original array
        sortedObjects.sort((a, b) => {
            const propertyA = a[property];
            const PropertyB = b[property];
            if (ascending) {
                return propertyA - PropertyB;
            } else {
                return PropertyB - propertyA;
            }
        });
        return sortedObjects;
    };

    const sortProductsAlphabetically = (objects, ascending = true, property) => {
        const sortedObjects = [...objects]; // make a copy of the original array
        sortedObjects.sort((a, b) => {
            const propertyA = a[property].toLowerCase();
            const propertyB = b[property].toLowerCase();
            if (ascending) {
                if (propertyA < propertyB) return -1;
                if (propertyA > propertyB) return 1;
                return 0;
            } else {
                if (propertyA > propertyB) return -1;
                if (propertyA < propertyB) return 1;
                return 0;
            }
        });
        return sortedObjects;
    };

    const filterSearchProducts = (productsData, searchQuery, state) => {
        let copyArrProducts = [...productsData];

        if (searchQuery) {
            const filterProducts = copyArrProducts.filter(
                (product) =>
                    (product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        product.tax.toString().includes(searchQuery) ||
                        product.price.toString().includes(searchQuery)) &&
                    (state === undefined || product.enabled === state)
            );
            return filterProducts;
        } else {
            const filterProducts = copyArrProducts.filter((product) => product.enabled === state);
            return filterProducts;
        }
    };

    const handlerSortCriteria = (sortCriteria, products) => {
        const field = sortCriteria.match(/field:"([^"]+)"/) ? sortCriteria.match(/field:"([^"]+)"/)[1] : null;
        const criteria = sortCriteria.match(/criteria:"([^"]+)"/) ? sortCriteria.match(/criteria:"([^"]+)"/)[1] : null;
        if (field === "taxes") {
            if (criteria === "asc") {
                setProductsData(sortProductsByNumber(products, false, "tax"));
            } else if (criteria === "desc") {
                setProductsData(sortProductsByNumber(products, true, "tax"));
            }
        } else if (field === "price") {
            if (criteria === "asc") {
                setProductsData(sortProductsByNumber(products, false, "price"));
            } else if (criteria === "desc") {
                setProductsData(sortProductsByNumber(products, true, "price"));
            }
        } else if (field === "name") {
            if (criteria === "asc") {
                setProductsData(sortProductsAlphabetically(products, false, "name"));
            } else if (criteria === "desc") {
                setProductsData(sortProductsAlphabetically(products, true, "name"));
            }
        } else if (field === "available") {
            if (criteria === "asc") {
                setProductsData(sortProductsByNumber(products, false, "enabled"));
            } else if (criteria === "desc") {
                setProductsData(sortProductsByNumber(products, true, "enabled"));
            }
        }
    };

    useEffect(() => {
        dispatch(setItemsPerpage(6));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        setProducts(getProductsForPage(productsData, currentPage, perPage));
        dispatch(setCount(productsData.length));

        // eslint-disable-next-line
    }, [productsData]);

    useEffect(() => {
        if (productsData.length > 0) {
            setProducts(getProductsForPage(productsData, currentPage, perPage));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        if (state && !initialProductsRender.current) {
            setProductsData(filterSearchProducts(copyProductsData, search, parseBoolean(state)));
        } else if (!state && !initialProductsRender.current) {
            if (search) {
                setProductsData(filterSearchProducts(copyProductsData, search, undefined));
            } else {
                setProductsData(copyProductsData);
            }
        }
        // eslint-disable-next-line
    }, [state]);

    useEffect(() => {
        if (search && !initialProductsRender.current) {
            setProductsData(
                filterSearchProducts(copyProductsData, search, state === undefined ? undefined : parseBoolean(state))
            );
        } else if (!search && !sortCriteria && !initialProductsRender.current) {
            if (state) {
                setProductsData(
                    filterSearchProducts(
                        copyProductsData,
                        search,
                        state === undefined ? undefined : parseBoolean(state)
                    )
                );
            } else {
                setProductsData(copyProductsData);
            }
        } else if (!search && sortCriteria && !initialProductsRender.current) {
            if (state) {
                handlerSortCriteria(
                    sortCriteria,
                    filterSearchProducts(
                        copyProductsData,
                        search,
                        state === undefined ? undefined : parseBoolean(state)
                    )
                );
            } else {
                handlerSortCriteria(sortCriteria, copyProductsData);
            }
        }
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (sortCriteria && !initialProductsRender.current) {
            dispatch(setLoading(false));
            handlerSortCriteria(sortCriteria, productsData);
        } else if (
            !sortCriteria &&
            ((search && state) || (!search && state) || (search && !state)) &&
            !initialProductsRender.current
        ) {
            dispatch(setLoading(false));
            setProductsData(
                filterSearchProducts(productsData, search, state === undefined ? undefined : parseBoolean(state))
            );
        } else if (!sortCriteria && !initialProductsRender.current) {
            dispatch(setLoading(false));
            setProductsData(copyProductsData);
        }
        // eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        dispatch(setAvailableFilters(GetAvailableFilters(SECTION_NAME)));
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, { shopData, openModalAddProduct })));
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME)));
        dispatch(
            setTableResults(arrangeData(products, id, tokenShop, openModalModifyStateProduct, openModalDeleteProduct))
        );
        dispatch(setCustomError(t("no-products-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setActions(true));
        // dispatch(setCount(productsData.length));
        initialProductsRender.current = false;
        // eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        dispatch(
            setBatchActive(
                BatchActions({
                    openModalBatchActions,
                    checkedItems,
                    products: data?.products?.items
                        ? data.products.items
                              .filter((product) => checkedItems.includes(product.id))
                              .map((p) => {
                                  return {
                                      id: p.id,
                                      name: parseVendureTranslation(p.translations, langDefault),
                                  };
                              })
                        : [],
                })
            )
        );
    }, [checkedItems]);

    return null;
};

const BatchActions = ({ openModalBatchActions, checkedItems, products }) => {
    const actions = [
        {
            name: "delete",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.DELETE_PRODUCT,
                    subtitle: "delete-products-confirm",
                    labels: products,
                    checkedItems,
                    title: "delete",
                    textsSuccess: "next-products-have-been-deleted",
                    textsError: "next-products-have-not-been-deleted",
                    apiPointer: "VENDURE",
                });
            },
        },
        {
            name: "set-as-available",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.AVAILABLE_PRODUCT,
                    subtitle: "set-as-available-products-confirm",
                    labels: products,
                    checkedItems,
                    title: "set-as-available",
                    textsSuccess: "next-products-have-been-modified-successfully",
                    textsError: "next-products-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
        {
            name: "set-as-unavailable",
            // name: "set-as-available",
            customModal: () => {
                openModalBatchActions({
                    type: TYPE_BATCH_ACTION.UNAVAILABLE_PRODUCT,
                    subtitle: "set-as-unavailable-products-confirm",
                    labels: products,
                    checkedItems,
                    title: "set-as-unavailable",
                    textsSuccess: "next-products-have-been-modified-successfully",
                    textsError: "next-products-have-not-been-modified-successfully",
                    apiPointer: "VENDURE",
                });
            },
        },
    ];
    return actions;
};

export default ListProducts;
