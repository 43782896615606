import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

import { Session } from "hooks/Utils/Session";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";
import { openModal, setDropdownMenu, setModalContent, showGlobalLoading } from "actions/uiActions";
import useComponentVisible from "components/useComponentVisible";
import UseNotificationIcon from "components/Notifications/useNotificationIcon";

import { GlobalContext } from "contexts/Global";

export const useShowPrivacyPolicy = () => {
    const dispatch = useDispatch();
    return {
        show: () => {
            dispatch(
                setModalContent({
                    title: "privacy-policy-title",
                    bodyAdjust: "w-3/5 text-justify max-h-80 overflow-y-scroll",
                    inputs: [
                        {
                            text: "privacy-policy-text",
                            type: "htmltext",
                        },
                    ],
                    buttons: [
                        {
                            name: "accept",
                            style: "blue",
                            action: "close",
                        },
                    ],
                })
            );
            dispatch(openModal());
        },
    };
};

export const useShowReleaseNotes = () => {
    const { setVersionUpdated, saveUserAdvicedUpdate } = useContext(GlobalContext);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    return {
        showReleaseNotes: (title) => {
            saveUserAdvicedUpdate(true);
            dispatch(
                setModalContent({
                    bodyAdjust: `w-3/${window.innerWidth > 1700 ? "6" : "5"} text-justify max-h-80 overflow-y-hidden`,
                    text: t("updates-and-releases"),
                    textAdjust: "font-bold text-3xl pb-8",
                    inputs: [
                        {
                            type: "releaseNotes",
                        },
                    ],
                    buttons: [
                        {
                            name: "accept",
                            style: "blue",
                            action: "close",
                        },
                    ],
                })
            );
            dispatch(openModal());
            setVersionUpdated(false);
        },
    };
};

const IconInfo = () => {
    const { lang, isVersionUpdated } = useContext(GlobalContext);

    const { t } = useTranslation();
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { dropdownOpened, selectingHotelDropdown, permissions } = useSelector((state) => state.ui);
    const { impersonated } = useSelector((state) => state.auth);
    const { show } = useShowPrivacyPolicy();
    const { showReleaseNotes } = useShowReleaseNotes();
    const dispatch = useDispatch();
    const projectRef = useSelector((state) => state.hotel.ref);
    const location = useLocation();

    const isSuperUser = Session.getSessionProp("superUser");

    const GET_SUPPORT_URL = gql`
    {
        loginTicketing(lang :"${lang}") 
    }
    `;

    const GET_PMS_STATUS = gql`
        {
            pmsStatus {
                lastActivityTime
            }
        }
    `;

    const [executeSupportQuery, supportQueryResponse] = useLazyQuery(GET_SUPPORT_URL, {
        fetchPolicy: "network-only",
    });

    const [getPMSStatus, { loading: loadingPMSStatus, data: pms, error }] = useLazyQuery(GET_PMS_STATUS, {
        fetchPolicy: "cache-and-network",
    });

    const pmsLastActivity = pms?.pmsStatus?.lastActivityTime ? new Date(pms?.pmsStatus?.lastActivityTime) : null;
    // Warning if last activity is more than 12 hours ago
    const pmsWarning = pmsLastActivity ? new Date() - pmsLastActivity > 43200000 : false;
    const pmsLastActivityInfo = (
        <span className={`${pmsWarning ? "text-orange-100" : ""} ${pmsLastActivity ? "" : " text-gray-400"}`}>
            {pmsLastActivity
                ? pmsLastActivity.toLocaleString(lang, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : t("unknown")}
        </span>
    );

    //States
    const [notifNumber, setNotifNumber] = useState(0);

    const clickEvent = (visible) => {
        setIsComponentVisible(visible);
        if (visible) {
            dispatch(setDropdownMenu("info"));
        } else {
            dispatch(setDropdownMenu(null));
        }
    };

    const handleSupportClick = (e) => {
        if (isSuperUser || impersonated) {
            return;
        }

        dispatch(showGlobalLoading(true));
        e.preventDefault();
        executeSupportQuery();
    };

    useEffect(() => {
        if (supportQueryResponse.data && supportQueryResponse.data.loginTicketing) {
            dispatch(showGlobalLoading(false));
            Session.setLSProp("supportLink", supportQueryResponse.data.loginTicketing);
            window.open(supportQueryResponse.data.loginTicketing, "_blank").focus();
        } else {
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [supportQueryResponse.data]);

    useEffect(() => {
        if (dropdownOpened !== "info") {
            setIsComponentVisible(false);
        }
        // eslint-disable-next-line
    }, [dropdownOpened]);

    useEffect(() => {
        setNotifNumber(isVersionUpdated ? 1 : 0);
    }, [isVersionUpdated]);

    useEffect(() => {
        if (
            !selectingHotelDropdown &&
            projectRef !== "CORPORATE" &&
            ![...pathsWithoutChainOrProjectRef].includes(location.pathname)
        ) {
            getPMSStatus();
        }
        //Revisar aqui para desencadenar un estado de redux!
        // eslint-disable-next-line
    }, [projectRef]);

    return (
        <div className="relative" ref={ref}>
            <div
                onClick={() => clickEvent(!isComponentVisible ? true : false)}
                id={`icon-info-button`}
                className={`icon icon-info text-blue-1100 menu-icon pb-6 lg:px-4 cursor-pointer  ${
                    isComponentVisible ? "text-blue-300 font-black" : ""
                }`}
            ></div>
            {notifNumber ? (
                <div className="infoIconPos">
                    <UseNotificationIcon number={notifNumber} />
                </div>
            ) : null}
            {isComponentVisible ? (
                <div
                    onClick={() => clickEvent(false)}
                    className="absolute mt-1 p-3 w-64 bg-white shadow-xl rounded-md"
                    style={{ left: "-12rem" }}
                >
                    <div className="text-sm first-capital">{t("help")}</div>
                    <a
                        href={() => false}
                        className={`flex ${isSuperUser || impersonated ? "disabled" : "text-gray-900 clickable"}`}
                        target={`${isSuperUser || impersonated ? "" : "_blank"}`}
                        rel="noopener noreferrer"
                        id={`icon-info-support-link`}
                        onClick={(e) => {
                            if (!isSuperUser || !impersonated) {
                                handleSupportClick(e);
                            }
                        }}
                    >
                        <div className="cursor-pointer  pb-4 first-capital ">{t("support")}</div>
                        <div className="icon-external-link mx-2 font-bold text-xl"></div>
                    </a>
                    <div className="text-sm first-capital">{t("system-updates")}</div>
                    <div
                        className="text-gray-900 cursor-pointer py-2 first-capital pb-4 clickable flex justify-between"
                        onClick={() => showReleaseNotes()}
                        id={`icon-info-show-release-notes-button`}
                    >
                        {t("{{capitalize, capitalize}}", {
                            capitalize: t("updates-and-releases"),
                        })}

                        {isVersionUpdated ? (
                            <div style={{ width: "1.3rem", height: "1.3rem" }}>
                                <UseNotificationIcon number={1} />
                            </div>
                        ) : null}
                    </div>

                    {projectRef !== "CORPORATE" ? (
                        <>
                            <div className="text-sm first-capital">{t("my-account")}</div>
                            <Link
                                id={`icon-info-show-contracted-products-link`}
                                to={"/information/my-account/contracted-products"}
                            >
                                <div className="text-gray-900 cursor-pointer py-2 first-capital pb-4 clickable">
                                    {t("contracted-products")}
                                </div>
                            </Link>
                        </>
                    ) : null}

                    <div className="text-sm first-capital">{t("legal-matters")}</div>
                    <div
                        onClick={() => show()}
                        id={`icon-info-show-privacy-policy`}
                        className="text-gray-900 cursor-pointer py-2 pb-4 first-capitalv clickable first-capital"
                    >
                        {t("privacy-policy")}
                    </div>

                    {projectRef !== "CORPORATE" && permissions?.product?.guest && (
                        <>
                            <div id={`icon-info-show-pms-last-activity`} className="text-sm first-capital">
                                {t("pms last activity")}
                            </div>
                            <div id={`icon-info-pms-status`} className={`text-gray-900 py-2 text-sm `}>
                                {loadingPMSStatus ? "..." : pmsLastActivityInfo}
                            </div>
                        </>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default IconInfo;
