import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { DEVICE_STATUS } from "../../Utils/Hotel/Monitor/ListDevicesUtils";

const UpdateListDevices = ({ client }) => {
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { params, values } = useSelector((state) => state.action);
    const actionData = useSelector((state) => state.action);
    const { permissions } = useSelector((state) => state.ui);
    const hasCast = permissions?.product?.cast;
    const { roomtvId = [] } = values;

    const [mutation, setMutation] = useState(`
    mutation{
        fake{ error id ok}}`);

    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            if (err.message.includes("no-devices-to-authorize")) {
                toast.error(langStrings["no-devices-to-authorize"]);
            } else {
                toast.error(err.message);
            }
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "reset-wifi-licenses":
                    setMutation(`mutation {
                        deleteWifiUsers {
                          id
                          ok
                          error
                        }
                      }
                      `);
                    executeQuery.mutationName = "deleteWifiUsers";
                    break;
                case "device-deauthorise":
                    setMutation(`mutation {
                        unauthorizeDevice(ref: ["${params.ref}"] ){
                          ok
                          error
                        }
                      }`);
                    executeQuery.mutationName = "unauthorizeDevice";
                    executeQuery.closeModal = true;
                    break;
                case "device-unassign":
                    setMutation(`mutation {
                        unassignDevice(ref: "${params.ref}" ) {
                          ok
                          error
                        }
                    }`);
                    executeQuery.mutationName = "unassignDevice";
                    executeQuery.closeModal = true;
                    break;
                case "device-hide-status":
                    setMutation(`
                        mutation {
                            updateDeviceShowWarnings(ref: ["${params.ref}"], showWarnings:false ) {
                                ok
                                error
                            }
                        }`);
                    executeQuery.mutationName = "updateDeviceShowWarnings";
                    executeQuery.closeModal = true;
                    break;
                case "device-reload-interface":
                    setMutation(`
                        mutation {
                            reloadDeviceInterface(ref: "${params.ref}", kind: ${params.kind} ) {
                                ok
                                error
                            }
                        }`);
                    executeQuery.mutationName = "reloadDeviceInterface";
                    executeQuery.closeModal = true;
                    break;
                case "device-restart":
                    setMutation(`
                        mutation {
                            chromecastReboot(ref: ["${params.ref}"]) {
                                ok
                                error
                            }
                        }`);
                    executeQuery.mutationName = "chromecastReboot";
                    executeQuery.closeModal = true;
                    break;
                case "device-launch-settings-screen":
                    setMutation(`mutation {
                            chromecastOpenSettings(ref: ["${params.ref}"]) {
                              ok
                              error
                            }
                          }`);
                    executeQuery.mutationName = "chromecastOpenSettings";
                    executeQuery.closeModal = true;
                    break;
                case "device-launch-system-settings-screen":
                    setMutation(`mutation {
                            chromecastOpenSystemSettings(ref: ["${params.ref}"]) {
                                ok
                                error
                            }
                        }`);
                    executeQuery.mutationName = "chromecastOpenSystemSettings";
                    executeQuery.closeModal = true;
                    break;
                case "device-pairing-remote-control":
                    setMutation(`mutation {
                        chromecastOpenBluetooth(ref: ["${params.ref}"]) {
                            ok
                            error
                            }
                          }`);
                    executeQuery.mutationName = "chromecastOpenBluetooth";
                    executeQuery.closeModal = true;
                    break;
                case "device-show-status":
                    setMutation(`mutation{
                        updateDeviceShowWarnings(ref: ["${params.ref}"] , showWarnings: true){
                          ok
                          error
                        }
                      }`);
                    executeQuery.mutationName = "updateDeviceShowWarnings";
                    break;
                case "device-delete-device":
                case "device-detach-from-property":
                    if (params?.warning.info === DEVICE_STATUS.unauthorized.value) {
                        setMutation(`
                        mutation{
                            deleteUnauthorizedDevice(ref:"${params.ref}"){
                              ok
                              error
                            }
                          }
                        `);
                        executeQuery.mutationName = "deleteUnauthorizedDevice";
                    } else {
                        setMutation(`mutation{
                            detachDevice(ref: "${params.ref}"){
                              error
                              ok
                            }
                          }`);
                        executeQuery.mutationName = "detachDevice";
                    }
                    executeQuery.closeModal = true;
                    break;
                case "device-uninstall":
                    if (hasCast) {
                        setMutation(`mutation {
                            unauthorizeDevice(ref: ["${params.ref}"] ){
                                ok
                                error
                            }
                          }`);
                        executeQuery.mutationName = "unauthorizeDevice";
                        executeQuery.closeModal = true;
                    } else {
                        setMutation(`mutation {
                            uninstallTV (ref:["${params.ref}"]) {
                                error
                                id
                                ok
                            }
                          }`);
                        executeQuery.mutationName = "uninstallTV";
                        executeQuery.closeModal = true;
                    }
                    break;
                case "device-move-room":
                    setMutation(`mutation{
                        updateDeviceRoomTV(
                            ref: "${params.ref}",
                            roomtvId: ${actionData.values["roomtvId"][0]}
                            ${params.roomNumber ? ` oldroomId: ${params.roomNumber} ` : ``}
                        ){
                          ok
                          error
                        }
                      }`);
                    executeQuery.mutationName = "updateDeviceRoomTV";
                    executeQuery.closeModal = true;
                    break;
                case "device-authorise":
                    setMutation(`mutation{
                        authorizeDevice(
                            all: false
                            device: {
                                ref: "${params.ref}"
                                mac: "${params.mac}"
                                type: "${params.type}"
                                model: "${params.model}"
                                name: "${params.name}"
                            }
                        ){
                            ok
                            error
                        }
                        }`);
                    executeQuery.mutationName = "authorizeDevice";
                    executeQuery.closeModal = true;
                    break;
                case "device-edit-name":
                    const { device_edit_name: deviceEditName = "" } = values;
                    setMutation(`mutation{
                        updateDeviceName(
                            ref: "${params.ref}"
                            name: "${deviceEditName}"
                        ){
                            ok
                            error
                        }
                        }`);
                    executeQuery.mutationName = "updateDeviceName";
                    executeQuery.closeModal = true;
                    break;
                case "device-assign":
                    setMutation(`mutation{
                        assignDevice(
                            ref: "${params.ref}" 
                            roomtvId: ${values["assign-device-id"]?.[0]}
                            function: ${values["function_device"]}
                        ){
                            ok
                            error
                        }
                        }`);
                    executeQuery.mutationName = "assignDevice";
                    executeQuery.closeModal = true;
                    break;
                case "device-authorise-and-assign":
                    setMutation(`mutation{
                        authorizeDevice(
                            all: false
                            device: {
                                ref: "${params.ref}"
                                mac: "${params.mac}"
                                type: "${params.type}"
                                model: "${params.model}"
                                name: "${params.name}"
                                function: ${values["function_device"]}
                                roomtvId: ${values["assign-device-id"]?.[0]}
                                assignCC: true
                            }
                        ){
                            ok
                            error
                        }
                        }`);
                    executeQuery.mutationName = "authorizeDevice";
                    executeQuery.closeModal = true;
                    break;
                case "device-change-device-function":
                    setMutation(`mutation{
                        updateDeviceFunction(
                            ref: "${params.ref}" , 
                            function: ${values["function_device"]}
                        ){
                          error
                          id
                          ok
                        }
                      }`);
                    executeQuery.mutationName = "updateDeviceFunction";
                    executeQuery.closeModal = true;
                    break;
                case "chromecast-authorize-all":
                    setMutation(`mutation{
                        authorizeDevice(
                            all: true
                        ){
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "authorizeDevice";
                    executeQuery.closeModal = true;
                    break;
                case "sync-chromecasts":
                    setMutation(`mutation{
                        syncChromecast{
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "syncChromecast";
                    executeQuery.closeModal = true;
                    break;
                case "device-power-off":
                    setMutation(`mutation{
                        sendActionToTV(
                            ref:"${params.ref}",
                            action:POWEROFF
                        ){
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "sendActionToTV";
                    executeQuery.closeModal = true;
                    break;
                case "device-power-on":
                    setMutation(`mutation{
                        sendActionToTV(
                            ref:"${params.ref}",
                            action:POWERON
                        ){
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "sendActionToTV";
                    executeQuery.closeModal = true;
                    break;
                case "device-edit-tv-inputs":
                    setMutation(`mutation{
                        assignTVInput(
                            roomTvIDs: [${values["roomTVID"]}]
                            tvInputID: ${values["input-group"]}
                            replace: false  
                        ){
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "assignTVInput";
                    executeQuery.closeModal = true;
                    break;
                case "device-factory-reset":
                    setMutation(`mutation{
                        chromecastFactoryReset(
                            ref: ["${params.ref}"]
                        ){
                            ok
                            error
                        }
                    }`);
                    executeQuery.mutationName = "chromecastFactoryReset";
                    executeQuery.closeModal = true;
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(langStrings["operation-successful"]);

            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateListDevices);
