import { Session } from "../../../Utils/Session";
import { parseBoolean } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";

function NewUser(editData, t) {
    const userManagerRole = parseBoolean(Session.getSessionProp("userManagerRole"));
    const hasCorporateUser =
        Session.getSessionProp("hasCorporateUser") && Session.getSessionProp("hasCorporateUser") !== "false";
    const managerUserData = Session.getSessionProp("cUser") ? JSON.parse(Session.getSessionProp("cUser")) : null;
    const brands = managerUserData && managerUserData.brands ? managerUserData.brands : [];
    const name = editData && editData.name ? editData.name : null;
    const email = editData && editData.name ? editData.email : null;
    const userId = editData && editData.id ? editData.id : null;
    const selectedAccesses = editData && editData.roles ? editData.roles : null;
    const selectedProperties = editData && editData.properties ? editData.properties : null;
    const selectedBrands = editData && editData.brands ? editData.brands : null;
    const assignedAll = editData && editData.assignedAll ? editData.assignedAll : false;
    const corporateUser = editData && editData.corporateUser ? editData.corporateUser : false;
    const projectsData = [];
    const corporateUserSwitch = [];

    if (Session.getSessionProp("chainName")) {
        if (userManagerRole || assignedAll || (managerUserData && managerUserData.assignedAll)) {
            let disabled = true;
            if (userManagerRole) {
                disabled = false;
            } else if (managerUserData && managerUserData.assignedAll) {
                disabled = false;
            }
            projectsData.push({
                id: `all`,
                name: Session.getSessionProp("chainName"),
                selected: assignedAll,
                disabled: disabled,
                category: "chain",
                selectUnique: true,
                selectedLabelColor: "bg-purple-600 text-white",
            });
        }
    }

    if (brands) {
        // eslint-disable-next-line
        brands.map((brand) => {
            let itemPreSelected = null;
            if (brand.ref && brand.ref !== "undefined") {
                if (selectedBrands !== null && selectedBrands.length > 0) {
                    itemPreSelected = selectedBrands.filter((b) => b.ref === brand.ref);
                }
                projectsData.push({
                    id: `${brand.ref}`,
                    name: brand.name,
                    selected: !assignedAll && itemPreSelected !== null && itemPreSelected.length > 0 ? true : false,
                    category: "property-groups",
                    disabled: assignedAll,
                    selectedLabelColor: "bg-orange-100 text-white",
                });
            }
            return brand;
        });
    }

    if (selectedBrands != null && selectedBrands.length > 0) {
        // eslint-disable-next-line
        selectedBrands.map((selectedBrand) => {
            let addProperty = true;
            if (projectsData !== null && projectsData.length > 0) {
                let itemPreSelected = projectsData.filter((p) => p.id === `${selectedBrand.ref}`);
                if (itemPreSelected !== null && itemPreSelected.length > 0) {
                    addProperty = false;
                }
            }
            if (addProperty) {
                projectsData.push({
                    id: `${selectedBrand.ref}`,
                    name: selectedBrand.name,
                    selected: !assignedAll,
                    category: "property-groups",
                    selectedLabelColor: "bg-orange-100 text-white",
                    disabled: assignedAll,
                });
            }
        });
    }

    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            let itemPreSelected = null;
            if (project.ref && project.ref !== "undefined" && project.ref !== "CORPORATE") {
                if (selectedProperties !== null && selectedProperties.length > 0) {
                    itemPreSelected = selectedProperties.filter((p) => p.ref === project.ref);
                }
                projectsData.push({
                    id: `${project.ref}`,
                    name: project.name,
                    selected: !assignedAll && itemPreSelected !== null && itemPreSelected.length > 0 ? true : false,
                    category: "properties",
                    disabled: assignedAll,
                    selectedLabelColor: "bg-teal-500 text-white",
                });
            }
        });
    }
    if (!assignedAll && selectedProperties != null && selectedProperties.length > 0) {
        // eslint-disable-next-line
        selectedProperties.map((selectedProperty) => {
            let addProperty = true;
            if (projectsData !== null && projectsData.length > 0) {
                let itemPreSelected = projectsData.filter((p) => p.id === `${selectedProperty.ref}`);
                if (itemPreSelected !== null && itemPreSelected.length > 0) {
                    addProperty = false;
                }
            }
            if (addProperty) {
                projectsData.push({
                    id: `${selectedProperty.ref}`,
                    name: selectedProperty.name,
                    selected: !assignedAll,
                    category: "properties",
                    selectedLabelColor: "bg-teal-500 text-white",
                    disabled: true,
                });
            }
        });
    }

    if (hasCorporateUser) {
        corporateUserSwitch.push({
            type: "switch",
            checked: corporateUser,
            text: [t("global-management-user")],
            name: "corporateUser",
            info: [t("global-management-user-info")],
        });
    }

    const rolesQuery = `{
        data: roles (orderBy:{field:"name",criteria:"asc"}){
            info { count }
            results { id name type  }
          }          
        }
        `;

    return [
        {
            title: name ? [name, "-", "edit-user"] : "add-user",
            bodyAdjust: "w-4/12",
            id: userId,
            executeSectionQuery: true,
            inputs: [
                {
                    text: ["fullname", "*"],
                    name: "user-name",
                    value: name,
                    type: "text",
                    focus: true,
                },
                {
                    text: ["email", "*"],
                    name: "user-email",
                    value: email,
                    type: "text",
                    toLowerCase: true,
                    validationsQuery: {
                        query: `{
                            validationInfo: users(email:"%0"){
                              info{
                                count
                              }
                              results{
                                fullName,
                                email
                              }
                            }
                          }`,
                        validateOnKeyUp: false,
                        validateOnBlur: true,
                        validationType: "exist_data",
                        validationMessage: "user-email-exist-footenote",
                        validationAttribute: "results[0].email",
                        validationElementsEdit: [
                            { attr: "data-action", value: "add-manager-user", class: "hidden" },
                            { attr: "data-action", value: "edit-user", class: "hidden" },
                        ],
                        validationIgnoreValue: email,
                    },
                },
                {
                    text: ["properties", "*"],
                    name: "properties",
                    type: "selectMultipleWithSearch",
                    optionData: projectsData,
                    selectedIdsForOQ: selectedProperties,
                    translateOptions: true,
                    selectPlaceHolder: "select-properties",
                    labelsColors: "bg-teal-500 text-white",
                },
                ...corporateUserSwitch,
                {
                    text: ["roles", "*"],
                    name: "roles",
                    type: "selectMultipleWithSearch",
                    optionsQuery: rolesQuery,
                    selectedIdsForOQ: selectedAccesses,
                    selectPlaceHolder: "select-roles",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "add-manager-user",
                },
            ],
        },
    ];
}

export default NewUser;
