import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UseButton from "../../../useButton";
import UseIcon from "../../../useIcon";
import { getStylesToApply } from "./Sidebar/TypographySidebar";
import { THEME_TYPE_DESIGN, getLogoAssetURL } from "../../../../hooks/Utils/AppearancesUtils";
import { getPXDefaultSizeForPreview } from "../../../../hooks/Utils/Design/FontUtils";
import { TV_RATIO } from "../../../../hooks/Utils/Widgets";
import { calculateByRatio } from "../../../../hooks/Utils/DesignUtils";
import { useLocation } from "react-router-dom";
import useSetAppearanceLoaded from "./PreviewMobileAppearanceUtils/useSetAppearanceLoaded";

const PreviewAppearance = ({
    visibilityAL = false,
    themeType = THEME_TYPE_DESIGN,
    loadingListAppearances = false,
    loadingAppearance = false,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const viewWithMenu = location.pathname === "/design/appearances/view" ? true : false;
    const viewAppearanceList = location.pathname === "/design/appearances/list" ? true : false;

    // console.log();
    // console.log(useLocation());

    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const [previewSize, setPreviewSize] = useState(calculateByRatio("TV", TV_RATIO));
    const [appearanceLoaded, setAppearanceLoaded] = useState();
    const [light, setLight] = useState(true);
    const [marginLeft, setMarginLeft] = useState("0px");
    const [headerStyle, setHeaderStyle] = useState(
        appearanceSelected && appearanceSelected.headerStyle
            ? getStylesToApply(appearanceSelected.headerStyle, true, false)
            : {
                  fontSize: `${getPXDefaultSizeForPreview(4)}px`,
                  fontFamily: "Lato",
                  fontWeight: "bold",
              }
    );
    const [headerAlign, setHeaderAlign] = useState("left");
    const [paragraphStyle, setParagraphStyle] = useState(
        appearanceSelected && appearanceSelected.paragraphStyle
            ? getStylesToApply(appearanceSelected.paragraphStyle, true, false)
            : {
                  fontSize: `${getPXDefaultSizeForPreview(2)}px`,
                  fontFamily: "Roboto",
              }
    );
    const [paragraphAlign, setParagraphAlign] = useState("left");
    const [defaultColor, setDefaultColor] = useState({ bg: "rgba(255,255,255,1)", fg: "rgba(29,112,204,1)" });
    const [activeColor, setActiveColor] = useState({ bg: "rgba(71,161,255,0.8)", fg: "rgba(255,255,255,1)" });
    const [focusColor, setFocusColor] = useState({ bg: "rgba(71,161,255,0.8)", fg: "rgba(255,255,255,1)" });
    const [logoURL, setLogoURL] = useState(false);

    useEffect(() => {
        if (appearanceSelected && appearanceSelected.headerStyle) {
            setHeaderStyle(getStylesToApply(appearanceSelected.headerStyle, true, false));
            setHeaderAlign(appearanceSelected.headerStyle.textAlign);
        }
        if (appearanceSelected && appearanceSelected.paragraphStyle) {
            setParagraphStyle(getStylesToApply(appearanceSelected.paragraphStyle, true, false));
            setParagraphAlign(appearanceSelected.paragraphStyle.textAlign);
        }
        if (appearanceSelected && appearanceSelected.default) {
            setDefaultColor({ bg: appearanceSelected.default.rgbaBG, fg: appearanceSelected.default.rgbaFG });
        }
        if (appearanceSelected && appearanceSelected.active) {
            setActiveColor({ bg: appearanceSelected.active.rgbaBG, fg: appearanceSelected.active.rgbaFG });
        }
        if (appearanceSelected && appearanceSelected.focus) {
            setFocusColor({ bg: appearanceSelected.focus.rgbaBG, fg: appearanceSelected.focus.rgbaFG });
        }
        if (appearanceSelected && appearanceSelected.logoSelected) {
            setLogoURL(getLogoAssetURL(appearanceSelected.logoSelected));
        } else {
            setLogoURL(false);
        }
    }, [appearanceSelected, visibilityAL, previewSize]);

    useEffect(() => {
        if (viewWithMenu) {
            let previewDiv = document.getElementById("preview-appearance");
            const _w = (previewDiv?.clientWidth / 100) * 64;
            const _h = _w / 1.777777777777777;

            setPreviewSize({
                width: _w,
                height: _h,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useSetAppearanceLoaded({
        appearanceSelected,
        loadingAppearance,
        loadingListAppearances,
        setAppearanceLoaded,
    });

    useEffect(() => {
        if (viewAppearanceList) {
            //Selectors elements
            const previewDiv = document.getElementById("preview-appearance");
            const appearanceList = document.querySelector("#containerAl");
            const appearancesToolbarIcons = document.querySelector("#appearancesToolbarContainer");
            const toolbar = document.querySelector("[data-id='toolbarAppearance']");

            //Width element selected
            const widthAppearanceList = getElementWidth(appearanceList);
            const widthappearancesToolbarIcons = getElementWidth(appearancesToolbarIcons);
            const widthToolbar = getElementWidth(toolbar);

            setMarginLeft(`${Math.floor(widthAppearanceList + widthappearancesToolbarIcons + widthToolbar) - 50}px`);

            const _w = (previewDiv.clientWidth / 100) * 80;
            const _h = _w / 1.777777777777777;

            setPreviewSize({
                width: _w,
                height: _h,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibilityAL, marginLeft, themeType, appearanceSelected]);

    const getElementWidth = (element) => {
        if (element) {
            return element.getBoundingClientRect().width;
        } else {
            return 0;
        }
    };

    const buttonsPreview = () => {
        const buttons = [
            { bg: "button1.jpg", icon: "utensils", color: focusColor.fg },
            { bg: "button2.jpg", icon: "user", color: defaultColor.fg },
            { bg: "button3.jpg", icon: "bag", color: defaultColor.fg },
        ];
        return buttons.map((button, index) => (
            <div
                key={index}
                className={`w- mb-3 flex items-center  rounded-md align-middle`}
                style={{
                    color: button.color,
                    height: previewSize.height / 6 + "px",
                }}
            >
                <div
                    className="flex items-center justify-center rounded-md"
                    style={{
                        height: previewSize.height / 6 + "px",
                        width: (previewSize.height / 6) * 1.777777777777777 + "px",
                        color: button.color,
                        backgroundImage: `url('/assets/images/appearances/${button.bg}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right",
                        backgroundSize: "130%",
                        backgroundColor: "rgba(255,255,255,0.4)",
                        backgroundBlendMode: "lighten",
                        borderWidth: `${
                            index === 0 ? `${appearanceSelected && appearanceSelected.focusBorderSize}px` : ""
                        }`,
                        borderStyle: "solid",
                        borderColor: focusColor.bg,
                    }}
                >
                    <div>
                        <UseIcon icon={{ name: button.icon }} adjust={" text-6xl m-auto "} />
                    </div>
                </div>
            </div>
        ));
    };
    if (!appearanceSelected) {
        return null;
    }
    const paddingPreview = viewWithMenu ? { height: "100%" } : { marginLeft: `${marginLeft}`, height: "90%" };

    const getDigitalTime = () => {
        let currentDate = new Date();
        let currentHours = currentDate.getHours(); // 0 - 23
        let currentMinutes = currentDate.getMinutes(); // 0 - 59
        currentHours = currentHours < 10 ? "0" + currentHours : currentHours;
        currentMinutes = currentMinutes < 10 ? "0" + currentMinutes : currentMinutes;
        return currentHours + ":" + currentMinutes;
    };

    return (
        <div
            id="preview-appearance"
            className={`flex w-full bg-gray-100 ${viewWithMenu ? "" : "mt-18"}`}
            style={paddingPreview}
        >
            <div
                id="locate-preview-appearance"
                className={`mx-auto ${
                    appearanceLoaded && appearanceSelected?.id && appearanceSelected.themeType === THEME_TYPE_DESIGN
                        ? " opacity-100"
                        : "opacity-0"
                } `}
                style={{ width: `${previewSize.width}px`, height: `${previewSize.height}px` }}
            >
                {/* BUTTONS OPTIONS */}
                <div className="h-14 min-w flex w-full items-center justify-end">
                    <div className="mr-4 inline border-r-2 border-gray-500 pr-4">
                        {t("{{capitalize, capitalize}}", { capitalize: t("background-preview") })}
                    </div>
                    <UseButton
                        id={"light-appearance-button"}
                        buttonName={t("{{capitalize, capitalize}}", { capitalize: t("light") })}
                        buttonColor={light ? "btn-blue" : "btn-white"}
                        adjust={light ? "" : "border-0"}
                        action={() => setLight(true)}
                    />
                    <UseButton
                        id={"dark-appearance-button"}
                        buttonName={t("{{capitalize, capitalize}}", { capitalize: t("dark") })}
                        buttonColor={light ? "btn-white" : "btn-blue"}
                        adjust={light ? "border-0" : ""}
                        action={() => setLight(false)}
                    />
                </div>
                {/* PREVIEW - SCREEN */}
                <div className="h-8 items-center bg-blue-100 text-center leading-8 text-white">
                    {t("{{capitalize, capitalize}}", { capitalize: t("appearance-preview") })}
                </div>
                <div
                    id="appearancePreviewDiv"
                    className="border rounded-b h-full border-blue-100"
                    style={light ? { backgroundColor: "white" } : { backgroundColor: "#2E3843" }}
                >
                    <div
                        className="shadow col-span-3 flex items-center justify-between p-1"
                        style={{ backgroundColor: defaultColor.bg }}
                    >
                        <div className="flex">
                            {!logoURL ? (
                                <span
                                    className="icon-logo rounded border m-2 flex-initial px-12 py-4 text-xl opacity-50"
                                    style={{
                                        borderColor: defaultColor.fg,
                                        color: defaultColor.fg,
                                    }}
                                ></span>
                            ) : (
                                <img alt="Logo" className="max-h-16 m-2 max-w-xs object-contain" src={logoURL} />
                            )}
                            {["default", "active", "focus"].map((v, i) => {
                                let color;
                                let bg = true;
                                switch (i) {
                                    case 1: // Active
                                        color = activeColor;
                                        break;
                                    case 2: // Focus
                                        color = focusColor;
                                        break;
                                    default:
                                        color = defaultColor;
                                        bg = false;
                                }
                                return (
                                    <div
                                        key={v + i}
                                        className="mx-4 flex-col px-3 pb-2 pt-4 text-center align-middle"
                                        style={{ backgroundColor: bg ? color.bg : null }}
                                    >
                                        <span
                                            className="icon-utensils block text-3xl"
                                            style={{ color: color.fg }}
                                        ></span>
                                        <span className="first-capital block text-lg" style={{ color: color.fg }}>
                                            {t(v)}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex items-center self-end text-right">
                            <div
                                className="mx-2 flex-col px-1 py-2 text-center align-middle text-2xl"
                                style={{ color: defaultColor.fg }}
                            >
                                {getDigitalTime()}
                            </div>
                            <div
                                className="mx-2 flex-col px-3 py-2 text-center text-5xl"
                                style={{ color: defaultColor.fg }}
                            >
                                <span className="icon-messaging-widget"></span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2 flex w-full p-6">
                        {/* icons */}
                        <div className="grid w-1/4 grid-flow-row grid-cols-1 px-8">{buttonsPreview()}</div>
                        {/* text */}
                        <div className="w-3/4 p-3" style={{ color: defaultColor.fg, backgroundColor: defaultColor.bg }}>
                            <div
                                style={{
                                    ...headerStyle,
                                    textAlign: headerAlign,
                                }}
                            >
                                {t("{{capitalize, capitalize}}", { capitalize: t("header-example") })}
                            </div>
                            <div className="mt-5" style={{ ...paragraphStyle }}>
                                <p style={{ textAlign: paragraphAlign }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus purus ipsum vitae
                                    viverra dapibus nullam mollis. Sed sem id eget diam aliquet turpis. Molestie non
                                    cras sit vestibulum pretium netus quis. Pellentesque cras consequat ut turpis. Amet
                                    montes, augue venenatis sit phasellus nulla. Adipiscing purus habitant enim, euismod
                                    faucibus elit donec lectus. Amet amet, tincidunt convallis pharetra, elit nulla.
                                    Gravida sed tristique tempus egestas. Blandit tortor et faucibus at tellus. Ornare
                                    interdum vivamus purus sociis mattis.
                                </p>

                                <p className="mt-2" style={{ textAlign: paragraphAlign }}>
                                    Nec purus integer pellentesque et, ultrices. Placerat molestie enim a felis ornare
                                    egestas et faucibus neque. Et, egestas fermentum at elit. Cras diam lobortis cursus
                                    tincidunt facilisi platea eu duis massa. Mi viverra id placerat habitasse lacus
                                    tellus proin amet. Tellus.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewAppearance;
