import { UsePermissions } from "../../hooks/map";

export const UseSectionMap = () => {
    const { checkPath } = UsePermissions();
    const getItems = (sectionID, childs) => {
        if (childs) {
            let menu = [];
            Object.keys(childs).forEach((key) => {
                let subdata = childs[key];
                if (subdata && subdata.enabled === undefined && !subdata.childs && subdata.to) {
                    // If enabled is not specified, check if the path is available
                    subdata.enabled = checkPath(subdata.to);
                }
                if (subdata && subdata.enabled !== false) {
                    const item = newItem(sectionID, childs, key, getItems);
                    if (item) {
                        menu.push(item);
                    }
                }
            });
            if (menu.length > 0) {
                return menu;
            }
        }
        return null;
    };

    return {
        getItems,
    };
};

export const getSidebarIDs = (items) => {
    if (items) {
        const ids = [];
        items.forEach((item) => {
            if (item?.id) {
                ids.push(item.id);
            }
            if (item?.menu) {
                item.menu.forEach((child) => {
                    if (child?.id) {
                        ids.push(child.id);
                    }
                });
            }
        });
        return ids;
    }
    return null;
};

const newItem = (section, map, key, getItems) => {
    const obj = map[key];
    const item = {
        id: section + "_" + key,
        name: key,
        section: section,
    };

    if (map && map[key]) {
        const validProps = ["redirectOptions", "name", "icon", "rightIcon", "to", "external", "action", "division"];
        Object.keys(map[key]).forEach((prop) => {
            if (validProps.includes(prop)) {
                item[prop] = map[key][prop];
            }
        });
    }

    if (!item.to && !item.action && !item.external) {
        item.to = "/" + section + "/" + key;
    }

    if (obj.childs) {
        item.menu = getItems(section, obj.childs);
        if (!item.menu) {
            return null;
        }
    }
    return item;
};
