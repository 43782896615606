function parseOptionsSelect(item, parseInfo) {
    let option = {
        id: "undefined",
        name: "undefined",
        selected: parseInfo.idsToSelect
            ? parseInfo.idsToSelect.find((aItem) => aItem.id === item.id)
                ? true
                : false
            : false,
        visible: true,
    };
    const t = (text) => {
        if (parseInfo.t) {
            return parseInfo.t(text);
        }
        return parseInfo.langStrings[text];
    };
    //  # ID
    option.id = parseInfo.idLabel ? item[parseInfo.idLabel] : item.id;
    if (parseInfo.actionName.includes("chromecast-") || parseInfo.actionName.includes("tv-move")) {
        option.id = `{"roomTVID":${item.roomTVID}, "roomID":${item.roomID}, "roomNumber":${item.roomNumber}}`;
    }
    //  # NAME
    option.name = parseInfo.nameLabel ? item[parseInfo.nameLabel] : item.name;
    if (parseInfo.translateOptions) {
        option.name = t(option.name);
    }
    if (item.surname) {
        option.name += ` ${item.surname}`;
    }
    if (item.rooms && item.rooms.length > 0) {
        option.name += `&nbsp;&nbsp; <span class="text-gray-800 text-sm">${t("room")}: ${item.rooms[0].number}</span>`;
    }
    if (item.roomNumber) {
        // option.name += `&nbsp;&nbsp; <span class="text-gray-800 text-sm">${t("room")} ${item.roomNumber}</span>`;
        // from issue 1255 - It should be: "<room name> + <location>"
        const _roomName =
            item.roomName && item.roomName.includes(item.roomNumber)
                ? `${parseInfo.t("{{capitalize, capitalize}}", { capitalize: parseInfo.t("room") })} ${item.roomNumber}`
                : item.roomName;
        option.name = `${_roomName}&nbsp;&nbsp; <span class="text-gray-800 text-sm">${item.roomTVName}</span>`;
    }
    if (item.category) {
        option.category = item.category;
        if (parseInfo.translateOptions) {
            option.category = t(option.category);
        }
    }
    if (parseInfo.actionName === "stayGuestRoom") {
        // options for alarms select "room"
        option.id = item.stayGuestsRoom[0].id;
        option.name = item.name;
        option.tooltip = `${item.stayGuestsRoom[0].stayGuest.name} ${item.stayGuestsRoom[0].stayGuest.surname}`;
    } else if (parseInfo.actionName === "guest" && item.alarmRooms && item.alarmRooms.length > 0) {
        const _guestRoomsNames = item.alarmRooms.map((room) => room.name);

        option.tooltip = _guestRoomsNames.join("<br>");
    }
    return option;
}

export default parseOptionsSelect;
