import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useComponentVisible from "../../../../../useComponentVisible";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";
import { WidgetContext, EditorContext } from "../../../../../../contexts/editor";
import { styleMatches, getFontSizes } from "../../../../../../hooks/Utils/Design/FontUtils";
import { useTranslation } from "react-i18next";
import { getElementMaxHeight } from "../../../../../../hooks/Utils/DesignUtils";

const Select = ({ title, id, adjust, data, deviceSpecific, optionsAdjust }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [selectActive, setSelectActive] = useState({});
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const { deviceType, theme, editorSize } = useContext(EditorContext);
    const { config, type, id: widgetID } = useContext(WidgetContext);
    const { gridItems } = useSelector((state) => state.design);
    const { fonts } = useSelector((state) => state.ui);
    const [noSelected, setNoSelected] = useState("");
    const [optionsStyleTop, setOptionsStyleTop] = useState({ top: "0px" });
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const sizes = getFontSizes(editorSize.width, deviceType);
    const isFontSelector = id === "fontName" || id.endsWith("font");
    const isStyleSelector = id === "styletype" || id.endsWith("fontStyle");
    const isTextSizeSelector =
        id && (id.match(/(^textSize|^langsTextSize|^textSizeAlarmSet|\.?size)$/) || id === "size");

    const implementNewStyles = Widget.ImplementNewLibrary(type);

    useLayoutEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: " -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
                disabled: "text-gray-400",
            });
            // modificar top del compomente que aparece
            const parent = document.getElementById(`select-${widgetID}-${id}`);
            if (parent) {
                setOptionsStyleTop({
                    maxHeight: `${getElementMaxHeight("select-" + widgetID + "-" + id)}rem`,
                    overflowY: "auto",
                    position: "fixed",
                    width: parent.offsetWidth + "px",
                    top: parent.getBoundingClientRect().top,
                });
            }
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    const getTextToShow = () => {
        if (isTextSizeSelector) {
            if (implementNewStyles) {
                return getSizeName({ size: config.style.exportById(id) || data.selected, sizes: sizes });
            } else {
                return getSizeName({
                    size:
                        style && style.devices && style.devices[deviceType] && style.devices[deviceType][id]
                            ? style.devices[deviceType][id]
                            : style.textSize
                            ? style.textSize
                            : data.selected,
                    sizes: sizes,
                });
            }
        } else {
            let currentValue = style[id];
            if (implementNewStyles) {
                currentValue = config.style.exportById(id);
            } else if (style && style.devices && style.devices[deviceType] && style.devices[deviceType][id]) {
                currentValue = style.devices[deviceType][id];
            }
            if (currentValue && typeof currentValue === "object") {
                currentValue = currentValue.name;
            }
            const found = data.options.find((option) => option.value === currentValue);
            return found ? found.text : currentValue || data.selected;
        }
    };

    const [textShown, setTextShown] = useState(getTextToShow());

    useEffect(() => {
        setTextShown(getTextToShow());
        if (isStyleSelector && !styleMatches(theme, style, data.selected)) {
            //stylestype changes from defined in appareances => shows "*" in text
            const selected =
                style && style[id]
                    ? data.options.find((option) => option.value === style[id])
                    : data.options.find((option) => option.value === data.selected);
            if (selected) {
                const newT = t(selected?.text) + "*";
                setTextShown(newT);
                setNoSelected("text-gray-800");
            } else {
                setNoSelected("");
            }
        } else {
            setNoSelected("");
        }
        // eslint-disable-next-line
    }, [style]);

    const handleChangeSelect = (option) => {
        setNoSelected("");
        setTextShown(isTextSizeSelector ? option.value : option.text);
        setIsComponentVisible(false);
        if (isStyleSelector) {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        value: changeInIsStyleSelector({
                            style: style,
                            theme: theme,
                            option: option,
                            fonts: fonts,
                            implementNewStyles: implementNewStyles,
                            id: id,
                            deviceType: deviceType,
                        }),
                    })
                )
            );
        } else if (isFontSelector) {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        value: changeInIsFontSelector({
                            style: style,
                            fonts: fonts,
                            option: option,
                            implementNewStyles: implementNewStyles,
                            id: id,
                        }),
                    })
                )
            );
        } else if (id === "langsFontName") {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        value: changeInLangsFontName({
                            style: style,
                            fonts: fonts,
                            option: option,
                        }),
                    })
                )
            );
        } else {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        property: id,
                        value: option.value,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        }
    };

    return (
        <>
            <div
                id={`select-${widgetID}-${id}`}
                ref={ref}
                className={`rounded mb-2 flex bg-gray-200 px-2 py-2 ${adjust}`}
            >
                <div
                    className={`first-capital truncate w-5/6 cursor-pointer ${noSelected}`}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsComponentVisible(!isComponentVisible);
                    }}
                >
                    {textShown}
                </div>
                <span
                    className={`icon-chevron animated cursor-pointer ${selectActive.icon}`}
                    onClick={(e) => {
                        e.preventDefault();
                        setIsComponentVisible(!isComponentVisible);
                    }}
                ></span>

                {isComponentVisible ? (
                    <div
                        id={`select-${widgetID}-${title}-options`}
                        className={`t-options-container w-auto ${optionsAdjust} -ml-2 mt-8 p-2 `}
                        style={optionsStyleTop}
                    >
                        {data.options.map((option, index) => (
                            <div
                                className="h-10 hover:bg-gray-100"
                                onClick={() => handleChangeSelect(option)}
                                id={option.value}
                                key={index}
                            >
                                <>
                                    <div className={`t-select-option cursor-pointer p-2`}>
                                        <div className="first-capital">{option.text}</div>
                                    </div>
                                </>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
        </>
    );
};

const getSizeName = (props) => {
    const { size, sizes } = props;
    let found = false;
    let textToRender = "";
    if (sizes) {
        sizes.forEach((s) => {
            if (s.vw === size) {
                textToRender = s.text;
                found = true;
            }
        });
    }

    if (!found) {
        // check for closer size in px, with maths operations sometimes chages 1 or 2 px
        let indexfound = -1;
        for (let index = 0; index < sizes.length; index++) {
            const prevElement = index > 0 ? sizes[index - 1] : sizes[0];
            const nextElement = index === sizes.length - 1 ? sizes[index] : sizes[index + 1];

            if (
                (prevElement.vw < size && size < nextElement.vw) ||
                (index === sizes.length - 1 && size > nextElement.vw)
            ) {
                indexfound = index;
                found = true;
            }
        }
        if (indexfound > -1 && found) {
            textToRender = sizes[indexfound].text;
        }
    }
    // eslint-disable-next-line
    return found ? textToRender : size + "vw";
};

const changeInIsStyleSelector = (props) => {
    const { style, theme, option, fonts, implementNewStyles, id, deviceType } = props;
    // On Font Style change...
    const fontStyleData = theme[`${option.value}Style`];
    const fontUsed = fonts.fontList.find((font) => fontStyleData.font === font.name);
    const size = fontStyleData.size;
    const fontName = fontStyleData.font;
    const bold = fontStyleData.bold;
    const italic = fontStyleData.italic;
    const underline = fontStyleData.underline;
    let setValue = { ...style };
    if (implementNewStyles) {
        // get item prefix (eh: items.button.fontStyle)
        let idPrefix = null;
        const res = id.match(/^(.*)fontStyle$/);
        if (res && res[1]) idPrefix = res[1];

        const setVal = (prop, value, deviceSpecific) => {
            setValue = Widget.SetPropValue(setValue, idPrefix ? idPrefix + prop : prop, value, deviceSpecific);
        };

        setVal("fontStyle", option.value);
        if (fontUsed.ref === "EXTERNAL") {
            setVal("font", {
                name: fontName,
                externalUrl: fontUsed.externalUrl,
                libraryRef: null,
            });
        } else {
            setVal("font", {
                name: fontName,
                libraryRef: fontUsed.ref,
                externalUrl: null,
            });
        }
        setVal("size", size, deviceType);
        // Remove specific devices sizes
        if (deviceType && setValue.devices) {
            Object.keys(setValue.devices).forEach((k) => {
                setVal("size", size, k);
            });
        }
    } else {
        setValue.fontName = fontName;
        setValue.bold = bold;
        setValue.italic = italic;
        setValue.underline = underline;
        setValue.styletype = option.value;
        if (fontUsed.ref === "EXTERNAL") {
            delete setValue.libraryRef;
            setValue.externalUrl = fontUsed.externalUrl;
            setValue.font = {
                name: fontName,
                externalUrl: fontUsed.externalUrl,
            };
        } else {
            delete setValue.externalUrl;
            setValue.libraryRef = fontUsed.ref;
            setValue.font = {
                name: fontName,
                libraryRef: fontUsed.ref,
            };
        }
        setValue.textSize = size;
        // Remove specific devices sizes
        if (deviceType && setValue.devices) {
            Object.keys(setValue.devices).forEach((k) => {
                if (setValue.devices[k]) delete setValue.devices[k].textSize;
            });
        }
    }
    return setValue;
};

const changeInIsFontSelector = (props) => {
    // on font changed...
    const { style, fonts, option, implementNewStyles, id } = props;
    const fontUsed = fonts.fontList.find((font) => option.value === font.name);
    const fontName = option.value;
    let setValue = { ...style };
    if (implementNewStyles) {
        // get item prefix (eh: items.button.fontStyle)
        let idPrefix = null;
        const res = id.match(/^(.*)fontStyle$/);
        if (res && res[1]) idPrefix = res[1];

        const setVal = (prop, value) => {
            setValue = Widget.SetPropValue(setValue, idPrefix ? idPrefix + prop : prop, value);
        };

        setVal("fontStyle", null);
        if (fontUsed.ref === "EXTERNAL") {
            setVal("font", {
                name: fontName,
                externalUrl: fontUsed.externalUrl,
                libraryRef: null,
            });
        } else {
            setVal("font", {
                name: fontName,
                libraryRef: fontUsed.ref,
                externalUrl: null,
            });
        }
    } else {
        setValue.fontName = fontName;
        setValue.styletype = null;
        if (fontUsed.ref === "EXTERNAL") {
            delete setValue.libraryRef;
            setValue.externalUrl = fontUsed.externalUrl;
            setValue.font = {
                name: fontName,
                externalUrl: fontUsed.externalUrl,
            };
        } else {
            delete setValue.externalUrl;
            setValue.libraryRef = fontUsed.ref;
            setValue.font = {
                name: fontName,
                libraryRef: fontUsed.ref,
            };
        }
    }
    return setValue;
};

const changeInLangsFontName = (props) => {
    const { style, fonts, option } = props;
    const fontUsed = fonts.fontList.find((font) => option.value === font.name);
    let widgetNewStyle = {
        ...style,
        langsFontName: option.value,
        langsFont: { name: option.value },
    };
    if (fontUsed.ref === "EXTERNAL") {
        widgetNewStyle.langsExternalUrl = fontUsed.externalUrl;
        widgetNewStyle.langsFont.externalUrl = fontUsed.externalUrl;
        delete widgetNewStyle.langsLibraryRef;
        delete widgetNewStyle.font.libraryRef;
    } else {
        widgetNewStyle.langsLibraryRef = fontUsed.ref;
        widgetNewStyle.langsFont.libraryRef = fontUsed.ref;
        delete widgetNewStyle.langsExternalUrl;
        delete widgetNewStyle.font.externalUrl;
    }
    return widgetNewStyle;
};

export default Select;
