import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { changeActionValues } from "../../actions/globalActions";
//Components
import UseLoading from "../Table/useLoading";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Utils
import { useTranslation } from "react-i18next";

const UseTranslationInputs = ({ index }) => {
    const { t } = useTranslation();

    //Store data
    const { textId, showDefault, customLangs, parentType, actionDefPlaceHolder } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const { validations } = useSelector((state) => state.action);
    const actionData = useSelector((state) => state.action.values);
    const { projectLangs } = useSelector((state) => state.ui);

    //API

    const GET_LANGS = gql`
    {        
        projectLangs {
            results {
                isDefault
                languageRef
            }
        }
                
        ${
            textId
                ? `translations(id: ${textId} parentType:${parentType}){
                            lang
                            text
                        }`
                : ""
        }
    }
    `;

    const [getLangsQuery, { data }] = useLazyQuery(GET_LANGS, {
        fetchPolicy: "network-only",
    });

    //States
    const [langs, setLangs] = useState(null);
    const [defaultLang, setDefaultLang] = useState(null);
    const [texts, setTexts] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(
            changeActionValues({
                [e.target.id]: e.target.value.replace(/(\r\n|\n|\r)/gm, ""),
            })
        );

        let textCopy = JSON.parse(JSON.stringify(texts));
        let updated = false;
        textCopy.map((text) =>
            text.lang === e.target.id
                ? ((text.text = e.target.value.replace(/(\r\n|\n|\r)/gm, "")), (updated = true))
                : null
        );
        if (!updated) {
            textCopy.push({
                lang: e.target.id,
                text: e.target.value.replace(/(\r\n|\n|\r)/gm, ""),
            });
        }

        setTexts(textCopy);
    };

    //Listeners
    useEffect(() => {
        if (customLangs) {
            setLangs(customLangs);
            if (textId) {
                getLangsQuery();
            } else if (customLangs && customLangs[0] && customLangs[0].translation) {
                customLangs.map((cLang) =>
                    dispatch(
                        changeActionValues({
                            [cLang.languageRef]: cLang.translation,
                        })
                    )
                );
            }
        } else {
            getLangsQuery();
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data && data.projectLangs && data.projectLangs.results) {
            if (!customLangs) {
                setLangs(projectLangs);
                const defLang = projectLangs.filter((lang) => lang.isDefault)[0].languageRef;
                setDefaultLang(defLang);
                dispatch(
                    changeActionValues({
                        defaultLang: defLang,
                    })
                );
            }
            //initialize action values
            if (data.translations) {
                setTexts(data.translations);
                data.translations.map((translation) =>
                    dispatch(
                        changeActionValues({
                            [translation.lang]: translation.text,
                        })
                    )
                );
            }
        }

        // eslint-disable-next-line
    }, data);

    return langs ? (
        <>
            {!showDefault ? (
                <>
                    <div>
                        <div className="first-capital pb-4">{t("default-lang")}:</div>
                    </div>

                    <div className="w-full mb-4 flex pt-2 pr-4">
                        <div className="w-3/12 p-2 float-left font-bold">
                            {t("language:" + langs.filter((lang) => lang.isDefault)[0].languageRef)}
                        </div>
                        <input
                            id={`${defaultLang}`}
                            type="text"
                            min="0"
                            required
                            placeholder={""}
                            value={
                                texts.filter((text) => text.lang === defaultLang).length > 0
                                    ? texts.filter((text) => text.lang === defaultLang)[0].text
                                    : ""
                            }
                            className={`w-9/12 float-left h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400`}
                            onChange={(e) => handleChange(e)}
                        ></input>
                    </div>
                </>
            ) : null}

            {!showDefault ? (
                <div>
                    <div className="first-capital pb-4">{t("translations")}:</div>
                </div>
            ) : null}
            <div className=" max-h-40 overflow-scroll pt-2 pr-0.5">
                {langs.map((lang, idx) =>
                    !showDefault && lang.isDefault ? null : (
                        <div className={`w-full ${idx + 1 === langs.length ? "mb-1" : "mb-4"} flex`}>
                            <div className="w-3/12 p-2 float-left font-bold">{`${t("language:" + lang.languageRef)} ${
                                lang.isDefault ? "*" : ""
                            }`}</div>
                            <input
                                id={`${lang.languageRef}`}
                                type="text"
                                min="0"
                                required
                                placeholder={
                                    texts && texts.filter((text) => text.lang === lang.languageRef).length === 0
                                        ? texts.filter((text) => text.lang === defaultLang).length > 0
                                            ? texts.filter((text) => text.lang === defaultLang)[0].text
                                            : actionDefPlaceHolder
                                            ? actionData[actionDefPlaceHolder]
                                            : null
                                        : actionDefPlaceHolder
                                        ? actionData[actionDefPlaceHolder]
                                        : null
                                }
                                value={
                                    texts && texts.filter((text) => text.lang === lang.languageRef).length > 0
                                        ? texts.filter((text) => text.lang === lang.languageRef)[0].text
                                        : customLangs &&
                                          customLangs.filter((cLang) => cLang.languageRef === lang.languageRef) &&
                                          customLangs.filter((cLang) => cLang.languageRef === lang.languageRef)[0]
                                        ? customLangs &&
                                          customLangs.filter((cLang) => cLang.languageRef === lang.languageRef)[0]
                                              .translation
                                        : null
                                }
                                className={`w-9/12 float-left h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400 phNoTransform  ${
                                    typeof validations[lang.languageRef] !== "undefined" &&
                                    !validations[lang.languageRef]
                                        ? "borderError"
                                        : ""
                                }`}
                                onChange={(e) => handleChange(e)}
                            ></input>
                        </div>
                    )
                )}
            </div>
        </>
    ) : (
        <div className="text-center">
            <UseLoading adjust="contents" />
        </div>
    );
};

export default UseTranslationInputs;
