/**
 * Item properties:
 * - name: string (translation key)
 * - icon: string (optional)
 * - rightIcon: string (optional)
 * - to: string (internal navigation)
 * - external: string (external url)
 * - action: string (action to be dispatched)
 * - redirectOptions: []string (when "to", this array contains the possible redirections)
 * - childs: []Item (submenu)
 */
const Sitemap = {
    hotel: {
        installation: {
            icon: "icon-installation",
            childs: {
                map: {
                    to: "/hotel/installation/map",
                },
                monitoring: {
                    to: "/hotel/installation/monitor",
                    redirectOptions: ["/hotel/installation/devices"],
                },
                "network-warning": {
                    name: "network-warnings",
                    to: "/hotel/installation/network-warning",
                },
            },
        },
        rooms: {
            icon: "icon-rooms",
            childs: {
                "room-list": {
                    to: "/hotel/rooms/list",
                },
                "room-groups": {
                    to: "/hotel/rooms/roomgroups",
                },
            },
        },
        "service-config": {
            to: "/hotel/services",
            icon: "icon-information",
        },
        "property-settings": {
            icon: "icon-settings",
            childs: {
                cast: {
                    to: "/hotel/property-settings/cast",
                },
                wifiConfig: {
                    name: "wifi-word",
                    to: "/hotel/property-settings/wifi",
                },
                TVSettings: {
                    name: "SCREEN-TYPE-TV",
                    to: "/hotel/property-settings/tv-settings",
                },
                mobile: {
                    name: "mobile",
                    to: "/hotel/property-settings/mobile-settings",
                },
                topology: {
                    to: "/hotel/property-settings/topology",
                },
            },
        },
    },
    "all-properties": {
        "property-management": {
            icon: "icon-installation",
            childs: {
                "manager-properties": {
                    name: "manager-properties",
                    to: "/all-properties/property-management/properties",
                },
                "manager-propertyGroups": {
                    name: "manager-propertyGroups",
                    to: "/all-properties/property-management/propertyGroup",
                },
            },
        },
        "mobile-apps": {
            to: "/all-properties/mobile-apps",
            icon: "icon-mobile",
        },
    },
    customers: {
        guests: {
            icon: "icon-guest",
            childs: {
                "guest-list": {
                    to: "/customers/guests/guest-list",
                },
                "guest-groups": {
                    to: "/customers/guests/guest-groups",
                },
                "general-settings": {
                    to: "/customers/guests/general-settings",
                },
            },
        },
        "loyal-customers": {
            to: "/customers/loyal-customers",
            icon: "icon-loyal-customers",
            name: "loyaltyCustomers",
        },
        "wifi-passcodes": {
            to: "/customers/passcode-configuration",
            icon: "icon-wifi-passcode",
        },
        messages: {
            to: "/customers/messages/list",
            icon: "icon-messaging-widget",
        },
        alarms: {
            to: "/customers/alarms/list",
            icon: "icon-alarm",
        },
        "feedback-settings": {
            to: "/customers/feedback-settings",
            icon: "icon-Feedback",
        },
    },
    services: {
        sales: {
            icon: "icon-sales-bag",
            childs: {
                shops_: {
                    to: "/services/sales/shop",
                },
                orders: {
                    to: "/services/sales/order-summary",
                },
                "general-settings": {
                    to: "/services/sales/general-settings",
                },
            },
        },
        "tv-channels": {
            to: "/services/tv-channels",
            icon: "icon-channel",
            childs: {
                grid: {
                    to: "/services/tv-channels/grids",
                    name: "grids_",
                },
                channels: {
                    to: "/services/tv-channels/channels",
                    name: "channels_",
                },
                groups: {
                    to: "/services/tv-channels/groups",
                },
                "general-settings": {
                    to: "/services/tv-channels/general-settings",
                },
                "corporate-channels": {
                    to: "/services/tv-channels/corporate-channels",
                    name: "corporate-channels",
                },
                "preset-channels": {
                    to: "/services/tv-channels/preset-channels",
                    name: "preset-channels",
                    icon: "icon-superuser",
                },
            },
        },
        movies: {
            to: "/services/movies",
            icon: "icon-Movies",
            childs: {
                moviesList: {
                    to: "/services/movies/watched-list",
                    name: "watched-movie-list",
                },
                generalSettings: {
                    to: "/services/movies/general-settings",
                    name: "general-settings",
                },
            },
        },
    },
    sales: {
        categories: {
            to: `/services/sales/shop/categories/:id`,
            icon: "icon-category",
        },
        products: {
            to: `/services/sales/shop/products/:id`,
            icon: "icon-product",
            redirectOptions: [
                `/services/sales/shop/:id/product/:productId`,
                `/services/sales/shop/:id/product/:productId/extras`,
            ],
        },
        labels: {
            to: `/services/sales/shop/labels/:id`,
            icon: "icon-labels",
        },
        "shop-settings": {
            to: `/services/sales/shop/settings/:id`,
            icon: "icon-shop-settings",
            division: true,
        },
        "shop-integrations": {
            to: `/services/sales/shop/integrations/:id`,
            icon: "icon-settings",
        },
        "assigned-users": {
            to: `/services/sales/shop/assigned-users/:id`,
            icon: "icon-add-guest",
        },
    },
    design: {
        interface: {
            to: "/design/interface",
            icon: "icon-interface",
            childs: {
                designs: {
                    to: "/design/interface/list",
                },
                templates: {
                    to: "/design/interface/templates",
                },
                "general-mobile-app": {
                    to: "/design/interface/mobile",
                },
            },
        },
        library: {
            to: "/design/library",
            icon: "icon-media",
            rightIcon: "icon-chevron-right",
        },
        appearances: {
            to: "/design/appearances/redirect",
            redirectOptions: ["/design/appearances/list", "/design/appearances/view"],
            icon: "icon-appearances",
            rightIcon: "icon-chevron-right",
        },
        tags: {
            to: "/design/tags",
            icon: "icon-tags",
        },
        /*
        "corporate-identity":{
            to: "/design/corporate-identity",
            icon: "icon-identity",
        },
        */
    },
    stats: {
        "cast-statistics": {
            to: "/stats/cast",
            icon: "icon-stats-cast",
        },
        "wifi-statistics": {
            to: "/stats/wifi",
            icon: "icon-stats-wifi",
        },
        "mobile-statistics": {
            to: "/stats/mobile",
            icon: "icon-stats-mobile",
        },
        "internal-statistics": {
            to: "/stats/internal",
            icon: "icon-zafiro-solid",
        },
        "sales-statistics": {
            to: "/stats/sales",
            icon: "icon-statistics-sales",
        },
    },
    "common-zones": {
        bookings: {
            to: "/common-zones/booking",
            icon: "icon-booking",
        },
        "capacity-monitor": {
            to: "/common-zones/capacity",
            icon: "icon-capacity",
        },
        cleaning: {
            to: "/common-zones/cleaning",
            icon: "icon-cleaning",
        },
        // staff: {
        //     to: "/common-zones/staff",
        //     icon: "icon-staff",
        // },
        "zones-configuration": {
            to: "/common-zones/zones-configuration",
            icon: "icon-areas-activities",
            childs: {
                "areas-activities": {
                    to: "/common-zones/zones-configuration/areas",
                },
                categories: {
                    to: "/common-zones/zones-configuration/categories",
                },
            },
        },
    },
    information: {
        help: {
            to: "/information/help",
            childs: {
                support: {
                    external: "https://support.zafiro.tv",
                },
            },
        },
        "system-updates": {
            to: "/information/system-updates",
            childs: {
                "updates-and-releases": {
                    action: "show-release-notes-modal",
                },
            },
        },
        "my-account": {
            to: "/information/my-account",
            childs: {
                "contracted-products": {
                    to: "/information/my-account/contracted-products",
                },
            },
        },
        "legal-matters": {
            to: "/information/legal-matters",
            childs: {
                "privacy-policy": {
                    action: "show-privacy-modal",
                },
            },
        },
    },
    "general-settings": {
        "user-management": {
            to: "/general-settings/user-management",
            childs: {
                "manager-users": {
                    to: "/general-settings/user-management/users",
                },
                "manager-roles": {
                    to: "/general-settings/user-management/roles",
                },
                "predefined-roles": {
                    to: "/general-settings/user-management/predefined-roles",
                    icon: "icon-superuser",
                },
                "app-staff-users": {
                    to: "/general-settings/user-management/app-staff-users",
                },
            },
        },
        "language-management": {
            to: "/general-settings/language-management/languages",
            childs: {
                "content-languages": {
                    to: "/general-settings/language-management/languages",
                },
            },
        },
        "policy-and-conditions-management": {
            to: "/general-settings/policy-and-conditions-management/policy-and-conditions",
        },
        finance: {
            to: "/general-settings/finance/tax-zones",
            childs: {
                "tax-zones": {
                    to: "/general-settings/finance/tax-zones",
                },
            },
        },
    },
    devices: {
        keys: {
            to: "/devices/keys",
            icon: "icon-installation",
        },
        "remote-controllers": {
            to: "/devices/remote-controllers",
            icon: "icon-analytics",
        },
    },
};

export default Sitemap;
