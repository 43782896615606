import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { UseMainMenu } from "../../hooks/map";
import { NavLink, useLocation } from "react-router-dom";

const prefix = `main-topbar`;

const MainMenu = () => {
    const { t } = useTranslation();
    const sections = UseMainMenu();

    return (
        <ul className="inline-block whitespace-no-wrap">
            {sections
                ? sections.map((value) => {
                      const id = `${prefix}-${value}`;
                      const name = t(value);
                      const to = "/" + value;
                      return (
                          <li
                              key={id}
                              id={id}
                              className="inline-block w-36 text-gray-800 text-sm text-center font-bold"
                          >
                              <NavLink
                                  className={({ isActive }) =>
                                      `menu-link menu-link${value ? `-${value}` : ""} text-base h-72px first-capital ${
                                          isActive ? "active" : ""
                                      }`
                                  }
                                  to={to}
                                  id={`${prefix}-${value}-link`}
                              >
                                  {name}
                              </NavLink>
                          </li>
                      );
                  })
                : null}
        </ul>
    );
};

export default MainMenu;
