const DeleteLanguageModal = (language, name) => {
    const { projectId, languageRef } = language;
    return [
        {
            id: languageRef,
            projectId: projectId,
            title: ["delete"],
            value: name,
            text: ["language-confirm-deletion"],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "erase",
                    style: "red",
                    action: "delete",
                },
            ],
        },
    ];
};

export default DeleteLanguageModal;
