import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

/**
 * @param index {number}
 * @returns {JSX.Element}
 */
const UseModalUsersAssigned = ({ index }) => {
    const { data: users } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState("");
    const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        setFilteredUsers(users.filter((x) => x.fullName.startsWith(searchInput) || x.email.startsWith(searchInput)));
    }, [searchInput]);

    const handleSearchInput = (ev) => {
        setSearchInput(ev.target.value);
    };

    return (
        <div>
            <div className="flex mb-4">
                <div className="w-1/2 flex">
                    <div className="w-full mr-8 relative">
                        <input
                            id={"search-user"}
                            type="text"
                            onInput={handleSearchInput}
                            className="w-full bg-gray-200 rounded hover:text-zafiro-400  text-gray-900 p-2 pl-4"
                            placeholder={t("search-for")}
                        ></input>
                        <span className="field-icon icon-search"></span>
                    </div>
                </div>
                <div className="w-1/2 mt-auto ml-auto block text-right">
                    {filteredUsers.length} {t("users")}
                </div>
            </div>

            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className=" border rounded border-gray-200"
            >
                {filteredUsers.map((user) => {
                    return (
                        <div key={user.fullName} className=" flex justify-between border-b mx-4 border-gray-200 py-4 ">
                            <span className="block">{user.email}</span>
                            {isCorporate ? (
                                <span
                                    className="block text-blue-600 font-bold"
                                    data-for={"default-tooltip"}
                                    data-tip={user.properties.map((x) => x.name).join("\n")}
                                >
                                    {user.properties.length} {t("properties")}
                                </span>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UseModalUsersAssigned;
