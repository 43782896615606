import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TypographyPane from "./TypographyPane";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent, setExecuteQuery } from "../../../../../actions/sectionActions";
import { getFontSizes, getPXDefaultSizeForPreview, textAlignment } from "../../../../../hooks/Utils/Design/FontUtils";

export default function TypographySidebar() {
    const HEADING = "heading";
    const PARAGRAPH = "paragraph";

    const { appearanceSelected } = useSelector((state) => state.sectionContent);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const textSizes = getFontSizes(1280, "TV");

    //states
    const [types, setTypes] = useState([
        {
            name: HEADING,
            showEdit: false,
            style: {
                fontName: "Lato",
                size: 4,
                textAlign: textAlignment.LEFT,
                bold: true,
                italic: false,
                underline: false,
            },
        },
        {
            name: PARAGRAPH,
            showEdit: false,
            style: {
                fontName: "Lato",
                size: 2,
                textAlign: textAlignment.LEFT,
                bold: false,
                italic: false,
                underline: false,
            },
        },
    ]);

    useEffect(() => {
        if (appearanceSelected && appearanceSelected.headerStyle && appearanceSelected.paragraphStyle) {
            // load
            setTypes([
                {
                    name: HEADING,
                    showEdit: false,
                    style: { ...appearanceSelected.headerStyle },
                },
                {
                    name: PARAGRAPH,
                    showEdit: false,
                    style: { ...appearanceSelected.paragraphStyle },
                },
            ]);
        }
    }, [appearanceSelected]);

    const handleShow = (type) => {
        let _arrayTyp = types.map((_type) => {
            if (_type.name === type) {
                return { ..._type, showEdit: true };
            } else {
                return { ..._type, showEdit: false };
            }
        });
        setTypes(_arrayTyp);
    };

    const cancelEdit = () => {
        let _arrayTyp = types.map((_type) => ({ ..._type, showEdit: false }));
        setTypes(_arrayTyp);
    };

    const saveEdit = (_type) => {
        let _arrayTyp = types.map((_tp) =>
            _tp.name === _type.name ? { ..._tp, style: { ..._type.style }, showEdit: false } : _tp
        );
        setTypes(_arrayTyp);
        dispatch(
            setSectionContent({
                appearanceSelected: {
                    ...appearanceSelected,
                    headerStyle: { ..._arrayTyp[0].style },
                    paragraphStyle: { ..._arrayTyp[1].style },
                },
            })
        );
        dispatch(
            setExecuteQuery({
                action: "update-appearance-font",
                heading: { ..._arrayTyp[0].style },
                paragraph: { ..._arrayTyp[1].style },
                appearanceId: appearanceSelected.id,
            })
        );
    };

    const renderTypes = (type, index) => {
        return (
            <Fragment key={index}>
                <div className="grid grid-cols-4 ">
                    <div className={`col-span-3 flex`} style={getStylesToApply(type.style, true, true)}>
                        {t("{{capitalize, capitalize}}", { capitalize: t(type.name) })}
                    </div>
                    {!type.showEdit &&
                        appearanceSelected &&
                        appearanceSelected.locks &&
                        appearanceSelected.locks.TYPOGRAPHY_TOOLBAR === false && (
                            <div
                                className="icon icon-edit row-span-2 m-auto flex cursor-pointer text-3xl hover:text-blue-200 "
                                onClick={() => handleShow(type.name)}
                                id={`typography-sidebar-${type.name}`}
                            ></div>
                        )}
                    <div className="col-span-3 flex font-sans text-base">{`${type.style.fontName}, ${
                        textSizes.find((size) => size.value === type.style.size)
                            ? textSizes.find((size) => size.value === type.style.size).text
                            : `${getPXDefaultSizeForPreview(type.style.size)} px`
                    }`}</div>
                </div>
                {type.showEdit && (
                    <TypographyPane
                        type={type}
                        cancelHandle={() => {
                            cancelEdit();
                        }}
                        saveHandle={saveEdit}
                    />
                )}
                <hr className="my-5" />
            </Fragment>
        );
    };

    return (
        <>
            <ul
                id="typography_List"
                className="max-h-80 collapseAnimation min-w-full flex-row  overflow-x-hidden overflow-y-scroll p-4"
            >
                {types.map((type, index) => renderTypes(type, index))}
            </ul>
        </>
    );
}

const getFontsPxSize = (vwSize) => {
    const fontSizes = getFontSizes(1280, "TV");
    const _size = fontSizes.find((element) => element.vw === parseFloat(vwSize));

    if (_size) {
        return _size.px;
    } else {
        return getPXDefaultSizeForPreview(vwSize);
    }
};

export const getStylesToApply = (style, returnObject = false, limit = false) => {
    let _size = style.size;

    let styles = `font-family:${style.fontName}; font-size:${getFontsPxSize(_size)}px; `;
    let stylesObject = { fontFamily: style.fontName, fontSize: getFontsPxSize(_size) + "px" };
    if (style.bold) {
        styles += "font-weight: bold; ";
        stylesObject.fontWeight = "bold";
    }
    if (style.italic) {
        styles += "font-style: italic; ";
        stylesObject.fontStyle = "italic";
    }
    if (style.underline) {
        styles += "text-decoration: underline; ";
        stylesObject.textDecoration = "underline";
    }

    if (returnObject) {
        styles = stylesObject;
    }

    return styles;
};
