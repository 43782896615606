function NewTag(editData, t) {
    const id = editData && editData.id ? editData.id : null;
    let names = null;
    let title = null;

    //este tryCatch es porque el name viene como string o json si accedo al detalle de un tag
    if (editData) {
        try {
            const namesList = JSON.parse(editData.name);
            if (typeof namesList === "object") {
                names = namesList;
                const lang = localStorage.getItem("lang") || "en";
                title = namesList && namesList[lang] ? namesList[lang] : "unnamed";
            }
        } catch (error) {}
    }

    const tagTypes = [
        {
            id: "TEXT",
            name: t("text").toUpperCase(),
            selected: editData && editData.target === "TEXT" ? true : false,
            selectedLabelColor: "bg-zafiro-400 text-white",
        },
        {
            id: "URL",
            name: `URL`,
            selected: editData && editData.target === "URL" ? true : false,
            selectedLabelColor: "bg-zafiro-800 text-white",
        },
    ];

    return [
        {
            title: title ? [title, "-", "edit-tag"] : "new-tag",
            text: ["new-tag-subtitle", "."],
            bodyAdjust: "w-4/12",
            id: id,
            executeSectionQuery: true,
            inputs: [
                {
                    text: ["name"],
                    type: "void",
                    adjust: "first-capital",
                },
                {
                    type: "translations",
                    actionDefPlaceHolder: "en",
                    customLangs: [
                        {
                            languageRef: "en",
                            translation: names && names["en"] ? names["en"] : null,
                            isDefault: true,
                        },
                        {
                            languageRef: "es",
                            translation: names ? names["es"] || names["en"] : null,
                        },
                    ],
                    //textId: rowContent.mutationParams.nameTID,
                    showDefault: true,
                },
                editData
                    ? {}
                    : {
                          text: ["tag-id"],
                          name: "tag-id",
                          type: "text",
                          value: editData ? editData.tag : null,
                          disabled: editData ? true : false,
                          composeTagId: editData ? null : "en",
                          textStyle: "first-capital",
                      },
                editData
                    ? {}
                    : {
                          text: ["type", "*"],
                          type: editData ? "text" : "selectMultipleWithSearch",
                          value: editData ? editData.target : null,
                          name: "tag-type",
                          disabled: editData ? true : false,
                          optionData: tagTypes,
                          oneSelected: true,
                          //  selectPlaceHolder: "select-zones",
                          // cStyle: "w-8/12",
                      },

                {
                    name: "tag-description",
                    type: "text-area",
                    text: "description",
                    selected: editData ? editData.description : null,
                    charLimit: 250,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: `${editData ? "update" : "create"}`,
                    style: "blue",
                    action: `${editData ? "edit-tag" : "new-tag"}`,
                },
            ],
        },
    ];
}

export default NewTag;
