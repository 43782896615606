import { useEffect, useState } from "react";
import { useNavigate as useHistory, useLocation } from "react-router-dom";

import { UseMainMenu } from "hooks/map";

import { routes } from "routes";

// This component is used to redirect to the first section of the main menu
const Home = () => {
    const sections = UseMainMenu();
    const history = useHistory();

    const [redirect, setRedirect] = useState(null);

    const firstSection = sections?.[0];

    useEffect(() => {
        if (firstSection) {
            setRedirect(`/${firstSection}`);
        }
    }, [firstSection]);

    useEffect(() => {
        if (redirect) {
            history(redirect);
        }
    }, [redirect]);

    return null;
};

// This component is used to redirect to the first section of the active location
export const MenuHome = () => {
    const history = useHistory();
    const location = useLocation();

    const [redirect, setRedirect] = useState(null);

    const firstPath = routes?.find((r) => !r?.redirect && (r?.path || "").startsWith(`${location?.pathname}/`))?.path;

    useEffect(() => {
        if (firstPath && firstPath !== redirect) {
            setRedirect(firstPath);
        }
    }, [firstPath]);

    useEffect(() => {
        if (redirect) {
            history(redirect);
        }
    }, [redirect]);

    return null;
};

export default Home;
