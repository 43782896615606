import React, { useContext, useState, useEffect, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "../actions/globalActions";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InputMask from "react-input-mask";
import es from "date-fns/locale/es";
import { isSameDay } from "date-fns";

import { GlobalContext } from "contexts/Global";

const UseInputDateHour = ({
    data,
    adjustWidthHour,
    setCustomDateValue,
    setCustomHourValue,
    customHourValidation,
    customDateValidation,
    justHour = false,
}) => {
    const { t } = useTranslation();

    const { lang } = useContext(GlobalContext);

    const { name, date, hour, empty, disabled, minDate, noFilterPassedTime, id } = data;
    const { validations } = useSelector((state) => state.action);

    const [valueDate, setValueDate] = useState(empty ? null : date);
    const [valueHour, setValueHour] = useState(empty ? null : hour);
    registerLocale("es", es);

    const dispatch = useDispatch();

    const padFix = (n) => {
        return ("00" + n).match(/\d{2}$/);
    };

    const formatDate = (date, format) => {
        switch (format) {
            case "date":
                return date.getFullYear() + "-" + padFix(date.getMonth() + 1) + "-" + padFix(date.getDate());
            case "time":
                return padFix(date.getHours()) + ":" + padFix(date.getMinutes());
            default:
                return date;
        }
    };

    const onHourPickerSet = (date) => {
        if (date) {
            const hour_value = formatDate(date, "time");
            setValueHour(hour_value);
            dispatch(changeActionValues({ [`${name}-hour`]: hour_value }));
            if (setCustomHourValue) {
                setCustomHourValue(hour_value);
            }
        } else {
            setValueHour(null);
            dispatch(changeActionValues({ [`${name}-hour`]: null }));
            if (setCustomHourValue) {
                setCustomHourValue(null);
            }
        }
    };

    const onDatePickerSet = (date) => {
        if (date) {
            const date_value = formatDate(date, "date");
            setValueDate(date_value);
            dispatch(changeActionValues({ [`${name}-date`]: date_value }));
            if (setCustomDateValue) {
                setCustomDateValue(date_value);
            }
        } else {
            setValueDate(null);
            dispatch(changeActionValues({ [`${name}-date`]: null }));
            if (setCustomDateValue) {
                setCustomDateValue(null);
            }
        }
        onHourPickerSet(null);
    };

    //Listeners
    useEffect(() => {
        if (!empty) {
            dispatch(changeActionValues({ [`${name}-date`]: date }));
            dispatch(changeActionValues({ [`${name}-hour`]: hour }));
            if (setCustomDateValue) {
                setValueDate(date);
                setCustomDateValue(date);
            }
            if (setCustomHourValue) {
                setValueHour(hour);
                setCustomHourValue(hour);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (setCustomHourValue) {
            setValueHour(hour);
            setCustomHourValue(hour);
        }
    }, [hour]);

    const DateInput = forwardRef(({ value, onClick, onChange }, ref) => (
        <div className="relative">
            <InputMask
                id={id ? `${id}-date` : null}
                mask={"99/99/9999"}
                className={`t-filter-input flex items-center py-3 pl-10 pr-4 mt-2 mr-5 w-full ${
                    !value ? "text-gray-600" : ""
                } ${
                    (typeof validations[`${name}-date`] !== "undefined" && !validations[`${name}-date`]) ||
                    customDateValidation
                        ? "border border-red-100"
                        : ""
                }  ${disabled ? "bg-gray-300" : ""}`}
                onClick={onClick}
                value={value}
                onChange={onChange}
                placeholder={t("select-date")}
                ref={ref}
                tabIndex={0}
            />
            <i className=" absolute top-4 left-4 icon icon-booking mr-2 font-black text-gray-700"></i>
        </div>
    ));

    const HourInput = forwardRef(({ value, onClick, onChange, onFocus }, ref) => (
        <div id="input_custom_time" className={`relative ml-4 ${adjustWidthHour ? adjustWidthHour : "w-32"}`}>
            <InputMask
                id={id ? `${id}-hour` : null}
                mask={"99:99"}
                required
                className={`t-filter-input flex items-center py-3 pl-10 pr-4 mt-2 mr-5
                ${adjustWidthHour ? adjustWidthHour : "w-full"}
                ${
                    (typeof validations[`${name}-hour`] !== "undefined" && !validations[`${name}-hour`]) ||
                    customHourValidation
                        ? "border border-red-100"
                        : ""
                }  ${disabled ? "bg-gray-300" : ""}`}
                onClick={onClick}
                onFocus={onFocus}
                onChange={onChange}
                value={value}
                ref={ref}
                tabIndex={0}
                placeholder={"--:--"}
            />
            <i className="absolute left-4 text-gray-700 icon icon-clock mr-2" style={{ top: "1.1rem" }}></i>
        </div>
    ));

    const parseDate = (dateString) => {
        if (dateString.includes("/")) {
            const parts = dateString.split("/");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            const parts = dateString.split("-");
            return new Date(parts[0], parts[1] - 1, parts[2]);
        }
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();

        const selectedDay = valueDate ? parseDate(valueDate) : new Date();

        if (selectedDay < currentDate && !isSameDay(currentDate, selectedDay)) {
            return true;
        }

        if (isSameDay(currentDate, selectedDay)) {
            const timeHours = time.getHours();
            const timeMinutes = time.getMinutes();

            const currentDateHours = currentDate.getHours();
            const currentDateMinutes = currentDate.getMinutes();

            const isTimeNotGreaterThanCurrentDate =
                timeHours < currentDateHours || (timeHours === currentDateHours && timeMinutes <= currentDateMinutes);

            return !isTimeNotGreaterThanCurrentDate;
        }

        return true;
    };

    const isToday = () => {
        if (!valueDate) {
            return false;
        }
        if (
            new window.ZDate().toLocaleDateString([], {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
            }) ===
            new window.ZDate(valueDate).toLocaleDateString([], {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
            })
        ) {
            return true;
        }
        return false;
    };

    return (
        <div className="datepicker">
            {!justHour && (
                <DatePicker
                    selected={valueDate ? new Date(new window.ZDate(valueDate).toDate()) : null}
                    onChange={onDatePickerSet}
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    disabled={disabled}
                    name="date"
                    isClearable={!disabled}
                    tabIndex={0}
                    customInput={<DateInput />}
                    minDate={minDate || null}
                    locale={lang}
                    shouldCloseOnSelect={true}
                    clearButtonClassName={id ? `clear-button-date-${id}` : null}
                />
            )}
            <DatePicker
                selected={valueHour ? new Date(new window.ZDate("2000-01-01T" + valueHour).toDate()) : null}
                onChange={onHourPickerSet}
                dateFormat="HH:mm"
                timeCaption={t("{{capitalize, capitalize}}", { capitalize: t("hour") })}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={10}
                disabled={disabled}
                name="hour"
                isClearable={!disabled}
                filterTime={isToday() && !noFilterPassedTime ? filterPassedTime : null}
                customInput={<HourInput />}
                locale={lang}
                shouldCloseOnSelect={true}
                clearButtonClassName={id ? `clear-button-hour-${id}` : null}
            />
        </div>
    );
};

export default UseInputDateHour;
