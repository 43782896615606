import React from "react";
import { useNavigate as useHistory } from "react-router-dom";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";
import UserDropdown from "../../Header/UserDropdown";

//Utils
import ListAccountsUsers from "../../../hooks/GraphqlCalls/Auth/ListAccountsUsers";
import { Session } from "../../../hooks/Utils/Session";

const ImpersonateList = () => {
    const history = useHistory();

    ListAccountsUsers();

    if (!Session.getSessionProp("superUser") || Session.getSessionProp("superUser") === "false") {
        history("/login");
    }

    return (
        <>
            <div className="z-500 absolute right-0 mr-8 top" style={{ top: "2rem" }}>
                <UserDropdown />
            </div>
            <div className={` bg-white w-full block h-24`}>
                <span className="mx-auto">
                    <img
                        style={{ height: "6rem" }}
                        className="mx-auto"
                        src={Session.getDriveUrl("logo%20Zafiro%20by%20ES.png")}
                        alt="Logo"
                    />
                </span>
            </div>
            <div className="p-8">
                <UseSectionHeader title={"impersonate user"} navToSection={"/select-profile"} />
                <UseTable />
            </div>
        </>
    );
};

export default ImpersonateList;
