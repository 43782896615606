import { combineReducers } from "redux";
import reducerUI from "./reducerUI";
import reducerHotel from "./reducerHotel";
import reducerActions from "./reducerActions";
import reducerTable from "./reducerTable";
import reducerSectionContent from "./reducerSectionContent";
import reducerAuth from "./reducerAuth";
import reducerSchedules from "./reducerSchedules";
import reducerDesign from "./reducerDesign";

export default combineReducers({
    hotel: reducerHotel,
    ui: reducerUI,
    action: reducerActions,
    table: reducerTable,
    sectionContent: reducerSectionContent,
    auth: reducerAuth,
    schedules: reducerSchedules,
    design: reducerDesign,
});
