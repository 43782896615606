import { useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useMSQuery } from "../useMSQuery";
import { useDispatch, useSelector } from "react-redux";
import { setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";
import {
    CORPORATE_APPEARANCE,
    PROPERTY_APPEARANCE,
    CORPORATE_APPEARANCE_TYPE,
    PROPERTY_APPEARANCE_TYPE,
    CORPORATE_REF,
} from "../../Utils/AppearancesUtils";

const ListAppearances = ({ themeType, setLoadingListAppearances }) => {
    const dispatch = useDispatch();
    const projectRef = useSelector((state) => state.hotel.ref);

    const { refreshData, appearanceSelected, appearanceId, searchAppearance } = useSelector(
        (state) => state.sectionContent
    );
    const [keepSelected, setkeepSelected] = useState(appearanceId ? true : false);

    //API Call
    const GET_NAME_THEMES = gql`
        {
            themes: themes(filter: { themeType: ${themeType}, search: "${searchAppearance ? searchAppearance : ""}" }) {
                info {
                    count
                }
                results {
                    id
                    name
                    type
                    projectId
                    hasDesigns
                    isCopyableToProperty
                }
            }
            properties: properties{
                results{
                  id
                  name
                }
              }
        }
    `;

    const [executeNameThemesQuery, { loading, error, data, refetch }] = useMSQuery(GET_NAME_THEMES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (typeof setLoadingListAppearances === "function") {
            setLoadingListAppearances(loading);
        }
    }, [loading]);

    useEffect(() => {
        executeNameThemesQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (appearanceId) {
            setkeepSelected(true);
        }
        // eslint-disable-next-line
    }, [appearanceId]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshContentData(false));
            refetch();
            setkeepSelected(appearanceId ? true : false);
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const sortThemes = (prpThem, projects) => {
        // eslint-disable-next-line
        prpThem.map((theme) => {
            let pFind = projects.find((prj) => prj.id === theme.projectId);
            theme.projectName = pFind ? pFind.name : null;
        });

        prpThem.sort(function (a, b) {
            return a.projectId - b.projectId;
        });
        return prpThem;
    };

    useEffect(() => {
        if (!loading && !error && data && data.themes.results && data.themes.results.length > 0) {
            let _corporateThemes = [];
            let _propertyThemes = [];
            let _firstId = null;
            let _firstName = null;
            let _firstType = null;
            let _keepName = appearanceSelected ? appearanceSelected.name : null;

            data.themes.results.forEach((theme) => {
                if (theme.type === "CORPORATE") {
                    _corporateThemes.push(theme);
                } else {
                    _propertyThemes.push(theme);
                }
                if (keepSelected && theme.id === appearanceSelected.id) {
                    _keepName = theme.name;
                }
            });
            let _arrayMenuItems = [];
            //show order appearances
            if (projectRef === CORPORATE_REF) {
                _arrayMenuItems.push({
                    name: CORPORATE_APPEARANCE,
                    editionMode: "EDITION",
                    appearances: _corporateThemes,
                });
                //ORDER propertyThemes by chains
                _arrayMenuItems.push({
                    name: PROPERTY_APPEARANCE,
                    editionMode: "VIEW",
                    appearances: sortThemes(_propertyThemes, data.properties.results),
                });

                _firstId = _corporateThemes.length > 0 ? _corporateThemes[0].id : _propertyThemes[0].id;
                _firstName = _corporateThemes.length > 0 ? _corporateThemes[0].name : _propertyThemes[0].name;
                _firstType = _corporateThemes.length > 0 ? CORPORATE_APPEARANCE_TYPE : PROPERTY_APPEARANCE_TYPE;
            } else {
                _arrayMenuItems.push({
                    name: PROPERTY_APPEARANCE,
                    editionMode: "EDITION",
                    appearances: _propertyThemes,
                });
                _arrayMenuItems.push({
                    name: CORPORATE_APPEARANCE,
                    editionMode: "VIEW",
                    appearances: _corporateThemes,
                });
                _firstId = _propertyThemes.length > 0 ? _propertyThemes[0].id : _corporateThemes[0].id;
                _firstName = _propertyThemes.length > 0 ? _propertyThemes[0].name : _corporateThemes[0].name;
                _firstType = _propertyThemes.length > 0 ? PROPERTY_APPEARANCE_TYPE : CORPORATE_APPEARANCE_TYPE;
            }

            dispatch(
                setSectionContent({
                    listAppearances: _arrayMenuItems,
                    appearanceSelected:
                        keepSelected && appearanceSelected
                            ? { ...appearanceSelected, name: _keepName }
                            : {
                                  id: _firstId,
                                  name: _firstName,
                                  type: _firstType,
                                  locks: { LOGOS_TOOLBAR: false, TYPOGRAPHY_TOOLBAR: false, COLOUR_TOOLBAR: false },
                              },
                    appearanceId: keepSelected && appearanceSelected ? appearanceSelected.id : _firstId,
                })
            );
        } else if (!loading && !error && data && data.themes.results && data.themes.results.length === 0) {
            dispatch(
                setSectionContent({
                    listAppearances: [
                        {
                            name: projectRef === CORPORATE_REF ? CORPORATE_APPEARANCE : PROPERTY_APPEARANCE,
                            editionMode: "EDITION",
                            appearances: [],
                        },
                        {
                            name: projectRef === CORPORATE_REF ? PROPERTY_APPEARANCE : CORPORATE_APPEARANCE,
                            editionMode: "VIEW",
                            appearances: [],
                        },
                    ],
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);
};

export default ListAppearances;
