import Cookies from "js-cookie";
import { SessionKibana } from "./SessionKibana";
import { parseBoolean } from "./Utils";

// Gestion de cookies de session
export const Session = {
    init: () => {
        //Version control, remove cache if version change
        const versionKey = "ZAFIRO Manager - version";
        if (
            localStorage.getItem(versionKey) &&
            process.env.REACT_APP.VERSION &&
            localStorage.getItem(versionKey) !== process.env.REACT_APP.VERSION
        ) {
            if ("caches" in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });
            }
            localStorage.setItem(versionKey, process.env.REACT_APP.VERSION);
            window.location.reload(true);
        } else if (!localStorage.getItem(versionKey) && process.env.REACT_APP.VERSION) {
            localStorage.setItem(versionKey, process.env.REACT_APP.VERSION);
        }

        // allways restore session
        Session.restoreSession();
        if (!sessionStorage.sessionId && document.location.pathname && document.location.pathname !== "null") {
            sessionStorage.sessionId = Math.random().toString(36);

            if (!Cookies.get("restoreSession") || Cookies.get("restoreSession") === "false") {
                localStorage.removeItem("SSLastTab");
            }
        }
    },
    // Manager user login
    login: (props) => {
        Session.update({
            user: props.user,
            chain: props.chain,
            projects: props.projects,
        });
    },
    // Login project
    setToken: (token) => {
        Session.update({ token: token });
    },
    // Selected project
    setProject: (props) => {
        Session.update({ project: props });
    },
    // projects
    setProjects: (props) => {
        Session.update({ projects: props });
    },
    // Answer TOS
    setTOSAnswer: (accepted) => {
        Session.update({ tos: accepted });
    },
    setCookie: (property, val) => {
        const prefix = sessionStorage.sessionId;
        Cookies.set(`${prefix}_${property}`, val);
    },
    getCookie: (property) => {
        const prefix = sessionStorage.sessionId;
        return Cookies.get(`${prefix}_${property}`);
    },
    removeCookie: (property) => {
        const prefix = sessionStorage.sessionId;
        Cookies.remove(`${prefix}_${property}`);
    },
    setSessionProp: (property, val) => {
        if (typeof val === "object") {
            val = JSON.stringify(val);
        }
        const storage = getStorage(property);
        storage.setItem(`${property}`, val);
        const event = new CustomEvent("storageChange", { detail: { key: `${property}`, value: val } });
        if (event && window) {
            window.dispatchEvent(event);
        }
    },
    getSessionProp: (property) => {
        const storage = getStorage(property);
        return storage.getItem(`${property}`);
    },
    removeSessionProp: (property) => {
        sessionStorage.removeItem(`${property}`);
    },
    setLSProp: (property, val) => {
        const prefix = sessionStorage.sessionId;
        localStorage.setItem(`${prefix}_${property}`, val);
    },
    getLSProp: (property) => {
        const prefix = sessionStorage.sessionId;
        return localStorage.getItem(`${prefix}_${property}`);
    },
    removeLSProp: (property) => {
        const prefix = sessionStorage.sessionId;
        localStorage.removeItem(`${prefix}_${property}`);
    },

    saveBackup: () => {
        let propsArrays = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            propsArrays.push({ key: sessionStorage.key(i), value: sessionStorage.getItem(sessionStorage.key(i)) });
        }
        localStorage.setItem("SSLastTab", JSON.stringify(propsArrays));
    },
    restoreSession: () => {
        const restore = localStorage.getItem("SSLastTab");
        if (!restore) {
            return;
        }
        const propsArray = JSON.parse(restore);

        propsArray.map((prop) => sessionStorage.setItem(prop.key, prop.value));
    },
    usePreviousSession: () => {
        console.log(Cookies.get("restoreSession"));
        console.log(document.location.pathname);
        return (
            Cookies.get("restoreSession") &&
            Cookies.get("restoreSession") !== "false" &&
            document.location.pathname !== "/#/login" &&
            document.location.pathname !== "/#/"
        );
    },

    // Update session info
    update: (props) => {
        if (props.token) {
            Cookies.set("restoreSession", true);
            Session.setSessionProp("token", props.token);
        }
        if (props.tos === true || props.tos === false) {
            Session.setSessionProp("setPolicyToAccepted", props.tos);
        }

        if (props.chain) {
            Session.setSessionProp("chainId", props.chain.id);
            Session.setSessionProp(
                "ESChain",
                parseInt(props.chain.id || -1) === parseInt(process.env.REACT_APP.ZAFIRO_CHAIN_ID || -2)
            );
            Session.setSessionProp("chainRef", props.chain.ref);
            Session.setSessionProp("chainAppURL", props.chain.app);
            Session.setSessionProp("cloudManagerURL", props.chain.manager);
            Session.setSessionProp("chainLogoRef", props.chain.logo);
            Session.setSessionProp("currentAccount", JSON.stringify(props.chain));
            Session.setSessionProp("chainName", props.chain.name);
        }

        if (props.projects) {
            Session.setSessionProp("projects", JSON.stringify(props.projects));
        }

        if (props.user) {
            Session.setSessionProp("userID", props.user.id);
            Session.setSessionProp("userRef", props.user.ref);
            if (props.user.pass) {
                Session.setSessionProp("recovery", props.user.pass);
            }
            Session.setSessionProp("userFullname", props.user.fullname);
            if (props.user.superUser) {
                Session.setSessionProp("superUser", props.user.superUser);
            }
            if (props.user.email) {
                Session.setSessionProp("userEmail", props.user.email);
            }
            Session.setSessionProp("userManagerRole", props.user.role);
            Session.setSessionProp("hasCorporateUser", props.user.corporate);
            Session.setSessionProp("userTOSAccepted", props.user.tos);
            if (props.user.userAdvicedUpdate !== undefined) {
                // User advised update means that the user has already seen the update completed message
                Session.setSessionProp("userAdvicedUpdate", props.user.userAdvicedUpdate);
            }
            if (props.user.warnings !== undefined) {
                Session.setSessionProp("userWarnings", props.user.warnings);
            }
        }

        if (props.project) {
            //Complete project info
            const projectsData = Session.getSessionProp("projects");
            let availableHotels = {};
            if (projectsData) {
                availableHotels = JSON.parse(Session.getSessionProp("projects"));
                const hotel = availableHotels.find((p) => p.ref === props.project.ref);
                if (hotel) {
                    props.project.blockGuestManagement = hotel.blockGuestManagement || false;
                }
            }

            Session.setSessionProp("currentHotel", JSON.stringify(props.project));
            Session.setSessionProp("projectRef", props.project.ref);
        }

        Session.saveBackup();
    },

    endImpersonation: () => {
        const superUserData = JSON.parse(Session.getSessionProp("superUserData"));
        Session.removeSessionProp("projectRef");
        Session.removeSessionProp("chainRef");
        Session.removeSessionProp("currentHotel");
        if (Session.getSessionProp("impersonated")) {
            localStorage.removeItem("SSLastTab");
        }
        Session.removeSessionProp("impersonated");

        if (superUserData) {
            Session.setSessionProp("userFullname", superUserData.userFullname);
            Session.setSessionProp("userEmail", superUserData.email);
            Session.setSessionProp("token", superUserData.token);
            Session.setSessionProp("recovery", superUserData.recovery);
            Session.setSessionProp("superUser", true);
        }
        Session.saveBackup();
    },

    // Remove TOS answer
    removeTOSAnswer: () => {
        Session.removeSessionProp("setPolicyToAccepted");
    },

    userGuiding: (products, sections) => {
        const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
        const hasCorporate = parseBoolean(Session.getSessionProp("hasCorporateUser"));
        const userRef = Session.getSessionProp("userRef");
        const userEmail = Session.getSessionProp("userEmail");
        const userFullname = Session.getSessionProp("userFullname");

        const userguidingLimit = (prop, values, limit = 256) => {
            if (!values) {
                return null;
            }
            let result = {};
            let currentProp = prop;
            let propIndex = 1;
            if (values) {
                values.forEach((value) => {
                    if (value) {
                        const currentVal = result[currentProp];
                        const newValue = !currentVal ? value : `${currentVal}, ${value}`;
                        if (newValue.length <= limit) {
                            result[currentProp] = newValue;
                        } else {
                            currentProp = `${prop}${++propIndex}`;
                            result[currentProp] = value;
                        }
                    }
                });
            }
            return result;
        };

        const userID = userRef || "anonymous";
        const data = {
            email: userEmail || "unknown email",
            name: userFullname || "Unknown name",
            globalMode: isCorporate,
            hasGlobalOption: hasCorporate,
            lang: localStorage.getItem("lang") || "en",
            ...userguidingLimit("products", products),
            ...userguidingLimit("sections", sections),
        };
        if (window && window.userGuiding) {
            window.userGuiding.identify(userID, data);
        }
    },

    // URL getters
    getApiUrl: (path) => {
        return getManagerPath("api", path);
    },
    getUploadUrl: (path) => {
        return getManagerPath("upload", path);
    },
    getDasUrl: (path) => {
        return getManagerPath("das", path);
    },
    getPrinterUrl: (path) => {
        return getManagerPath("printer", path, true);
    },
    getDriveUrl: (path) => {
        return getManagerPath("drive", path);
    },
    getVendureUrl: (path) => {
        return getManagerPath("vendure", path);
    },
    getStaffAppUrl: (path) => {
        return getManagerSubdomain("staff", path);
    },
    getCastUrl: (path) => {
        return getManagerSubdomain("cast", path);
    },

    allPermissions: () => {
        return Boolean(process.env.REACT_APP.ALL_PERMISSIONS == "true");
    },

    develMode: () => {
        return Boolean(process.env.REACT_APP.DEVELOPMENT == "true");
    },

    // Close manager session
    close: () => {
        sessionStorage.clear();

        localStorage.removeItem("SSLastTab");

        Cookies.remove(`restoreSession`);

        // Remove kibana session (if opened)
        SessionKibana.close();
    },
};

const getStorage = (id) => {
    switch (id) {
        case "warnings-closed":
        case "warnings-collapsed":
            return localStorage;

        default:
            return sessionStorage;
    }
};

const getManagerPath = (type, path, useLocationHost = false) => {
    const id = type?.toUpperCase();
    const force = process.env.REACT_APP[`FORCE_${id}`];
    let url = force || process.env.REACT_APP[`DEFAULT_${id}_PATH`] || `/${id}`;
    if (path) {
        url += "/" + path;
    }
    if (useLocationHost && !force) {
        const { host, protocol } = window.location;
        url = `${protocol}//${host}${url}`;
    }
    return cleanURL(url);
};

const getManagerSubdomain = (type, path) => {
    const id = type?.toUpperCase();
    const force = process.env.REACT_APP[`FORCE_${id}`];
    let url = force || subdomain(process.env.REACT_APP[`DEFAULT_${id}_SUBDOMAIN`] || id);
    if (!Session.develMode()) {
        url = url.replace(".link", ".mobi");
    }
    if (path) {
        url += "/" + path;
    }
    return cleanURL(url);
};

const cleanURL = (url) => {
    return url.replace(/([^:]\/)\/+/g, "$1");
};

const subdomain = (type, url = null) => {
    if (!url) {
        url = window.location.href;
    }
    const urlObj = new URL(url);
    const parts = urlObj.hostname.split(".");
    if (parts[0] === "manager") {
        parts.shift();
    }
    parts.unshift(type);
    urlObj.hostname = parts.join(".");
    urlObj.pathname = "";
    urlObj.hash = "";
    return urlObj.toString();
};
