import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import UseSectionHeader from "../../../useSectionHeader";

//Actions
import { cleanAction } from "../../../../actions/globalActions";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { setSectionContent } from "../../../../actions/sectionActions";

//Utils
import { Session } from "../../../../hooks/Utils/Session";
import TagInfo from "./TagInfo";
import UpdateTag from "../../../../hooks/GraphqlCalls/Design/UpdateTag";
import TagInfoRequest from "../../../../hooks/GraphqlCalls/Design/TagInfo";

const TagDetail = () => {
    //Params
    const { id } = useParams();
    //Store data
    const { sectionName } = useSelector((state) => state.sectionContent);

    const pLocation = Session.getSessionProp("previous-location");

    //Actions
    const dispatch = useDispatch();

    //listeners
    useEffect(() => {
        dispatch(showGlobalLoading(true));
        return () => {
            dispatch(cleanAction());
            dispatch(
                setSectionContent({
                    data: null,
                    tagData: null,
                    propertiesValues: null,
                })
            );
        };
        // eslint-disable-next-line
    }, []);

    TagInfoRequest({ id: id });

    return (
        <>
            <UpdateTag />
            <UseSectionHeader
                title={sectionName}
                buttonColor="btn-blue"
                action={"function"}
                noTranslate={true}
                noCapitalize={true}
                value={"demo"}
                navToSection={pLocation.indexOf("tags/detail/") > -1 ? null : "/design/tags"}
                navToPreviousPage={pLocation.indexOf("tags/detail/") > -1 ? true : null}
                breadcrumbsLimit={3}
            />
            <TagInfo></TagInfo>
        </>
    );
};

export default TagDetail;
