export const PMS_STATUS_DISABLED = "NO_PMS";
export const PMS_STATUS_PENDING = "PMS_PENDING";
export const PMS_STATUS_CHARGED = "PMS_CHARGED";
export const PMS_STATUS_REJECTED = "PMS_REJECTED";
export const PMS_STATUS_ERROR = "ERROR";
// Custom status
export const PMS_STATUS_UNKNOWN = "UNKNOWN";
export const PMS_STATUS_CANCELLED = "CANCELLED";

/**
 * Get PMS status display text
 * @param {string} status - PMS status
 * @returns {string} - Translation key
 */
export const getStatusText = (status) => {
    if (!status || status === PMS_STATUS_UNKNOWN) {
        return "-";
    }
    switch (status) {
        case PMS_STATUS_CHARGED:
            return "pms-charged";
        case PMS_STATUS_PENDING:
            return "pms-pending";
        case PMS_STATUS_REJECTED:
            return "pms-denied";
        case PMS_STATUS_CANCELLED:
            return "cancelled";
        case PMS_STATUS_DISABLED:
            return "pms-disabled";
        case PMS_STATUS_ERROR:
            return "error";
        default:
            return `${status}??`;
    }
};

/**
 * Get PMS status information for tooltip
 * @param {string} status - PMS status
 * @returns {string} - Translation key
 */
export const getStatusInfo = (status) => {
    if (!status || status === PMS_STATUS_UNKNOWN) {
        return null;
    }
    switch (status) {
        case PMS_STATUS_CHARGED:
            return "pms-charged-info";
        case PMS_STATUS_PENDING:
            return "pms-pending-info";
        case PMS_STATUS_REJECTED:
            return "pms-denied-info";
        case PMS_STATUS_DISABLED:
            return "pms-disabled-info";
        case PMS_STATUS_CANCELLED:
            return null;
        case PMS_STATUS_ERROR:
            return null;
        default:
            return `${status}??`;
    }
};
