import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, closeModal, setModalContent } from "../../../actions/uiActions";

import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction } from "../../../actions/globalActions";
import {
    setLoading,
    cleanCheckedItems,
    setFolder,
    setAllChecked,
    setRefreshData,
    setPage,
} from "../../../actions/tableActions";
import { capitalizeFirst, escapeSpecialChars, removeApolloCacheKeys } from "../../../hooks/Utils/Utils";
import { dateString } from "../../../hooks/Utils/Utils";

//Utils
import { toast } from "react-toastify";
import { useNavigate as useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SENT_MSG, TEMPLATES_MSG, DRAFTS_MSG, SCHEDULED_MSG } from "../../Utils/Customers/MessagesUtils";

const UpdateMessages = ({ client }) => {
    const history = useHistory();
    // const { id } = useParams();
    const { t } = useTranslation();

    //Store data
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const messageData = useSelector((state) => state.sectionContent.data);
    const { checkedItems } = useSelector((state) => state.table);
    const { projectLangs } = useSelector((state) => state.ui);
    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{fake{ error id ok}}");

    //Actions
    const dispatch = useDispatch();
    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            const errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    const prepareTitleAndBody = () => {
        const titles = [];
        const contents = [];
        // eslint-disable-next-line
        projectLangs.map((lang) => {
            if (actionData.values[`subject-${lang.languageRef}`]) {
                titles.push(
                    `{lang:"${lang.languageRef}" text:"""${escapeSpecialChars(
                        actionData.values[`subject-${lang.languageRef}`]
                    )}"""}`
                );
            }

            if (actionData.values[`content-${lang.languageRef}`]) {
                contents.push(
                    `{lang:"${lang.languageRef}" text:"""${escapeSpecialChars(
                        actionData.values[`content-${lang.languageRef}`]
                    )}"""}`
                );
            }
        });
        return [titles, contents];
    };

    useEffect(() => {
        let titles, contents;
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "send-message":
                    [titles, contents] = prepareTitleAndBody();
                    setMutation(`
                        mutation{
                            updateMessage(
                                sendMessage: true
                                id: ${messageData.message.idMessage}
                                title:[${titles.join(",")}]
                                body:[${contents.join(",")}]
                                priority: "${actionData.values.type[0].toUpperCase()}"
                                ${
                                    actionData.values["expiration-date"]
                                        ? `expirationTime: "${dateString(
                                              `${actionData.values["expiration-date"]} ${
                                                  actionData.values["expiration-hour"]
                                                      ? actionData.values["expiration-hour"]
                                                      : "00:00:00"
                                              }`
                                          )}"`
                                        : ""
                                }
                            ){ error id ok }}
                    `);
                    executeQuery.mutationName = "updateMessage";
                    executeQuery.goTo = "list-messages-sent";
                    removeApolloCacheKeys(client.cache, "messages");
                    dispatch(showGlobalLoading(true));
                    break;

                case "delete-message":
                case "discard-message":
                    const idsToDelete = actionData.itemsAffected[0]
                        ? actionData.itemsAffected[0]
                        : messageData && messageData.idMessage
                        ? messageData.idMessage
                        : checkedItems;
                    //Discard message not saved as draft
                    if (!idsToDelete || idsToDelete.length === 0) {
                        dispatch(setExecuteQuery(null));
                        dispatch(closeModal());

                        history(`/customers/messages/list`);
                        // setTimeout to save cache restore data for table
                        setTimeout(() => {
                            dispatch(setFolder(SENT_MSG));
                        }, 100);
                        setTimeout(() => {
                            dispatch(setPage(1));
                        }, 200);
                        return;
                    }
                    setMutation(`
                        mutation{
                            deleteMessages(
                                ids: [${idsToDelete}]
                                ${executeQuery.action === "discard-message" ? "discard : true" : ""}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteMessages";
                    executeQuery.cleanCheckedItems = actionData.itemsAffected[0] ? false : true;
                    if (executeQuery.action === "discard-message") {
                        executeQuery.goTo = "list-messages-drafts";
                    }
                    removeApolloCacheKeys(client.cache, "messages");
                    dispatch(setLoading(true));
                    break;
                case "recover-as-draft":
                    setMutation(`
                        mutation{
                            updateMessage(
                                id: ${executeQuery.messageId}
                                recover: true
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateMessage";
                    executeQuery.goTo = "list-messages-drafts";
                    removeApolloCacheKeys(client.cache, "messages");
                    break;
                case "delete-permanently":
                    setMutation(`
                        mutation{
                            deleteTemplateMessages(
                                ids: [${actionData.itemsAffected[0]}]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteTemplateMessages";
                    removeApolloCacheKeys(client.cache, "messages");
                    dispatch(setLoading(true));
                    break;
                case "save-as-template":
                    // send id to save as template
                    setMutation(`
                        mutation{
                            createTemplateFromMessageID(
                                messageId: ${actionData.itemsAffected[0]}
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "createTemplateFromMessageID";
                    executeQuery.customToast = "msg-saved-as-template";
                    executeQuery.closeModal = true;
                    executeQuery.goTo = "list-messages-templates";
                    executeQuery.hideLoading = true;
                    removeApolloCacheKeys(client.cache, "messages");
                    break;
                case "save-msg-template":
                    [titles, contents] = prepareTitleAndBody();
                    setMutation(`
                        mutation{
                            createTemplateMessage(
                                title: [${titles.join(",")}]
                                body: [${contents.join(",")}]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "createTemplateMessage";
                    executeQuery.customToast = "msg-saved-as-template";
                    executeQuery.hideLoading = true;
                    executeQuery.goTo = "list-messages-templates";
                    removeApolloCacheKeys(client.cache, "messages");
                    dispatch(setLoading(true));

                    break;
                case "delete-template":
                    setMutation(`
                        mutation{
                            deleteTemplateMessages(
                                ids: [${checkedItems}]
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "deleteTemplateMessages";
                    executeQuery.cleanCheckedItems = true;
                    removeApolloCacheKeys(client.cache, "messages");
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (executeQuery.cleanCheckedItems || checkedItems.length > 0) {
                dispatch(cleanCheckedItems());
                dispatch(setAllChecked(false));
            }

            if (executeQuery.goTo === "list-messages-sent") {
                history(`/customers/messages/list`);
                // setTimeout to save cache restore data for table
                setTimeout(() => {
                    dispatch(setFolder(SENT_MSG));
                }, 100);
                setTimeout(() => {
                    dispatch(setPage(1));
                }, 200);
            } else if (executeQuery.goTo === "list-messages-templates") {
                history(`/customers/messages/list`);
                // setTimeout to save cache restore data for table
                setTimeout(() => {
                    dispatch(setFolder(TEMPLATES_MSG));
                }, 100);
            } else if (executeQuery.goTo === "list-messages-drafts") {
                history(`/customers/messages/list`);
                // setTimeout to save cache restore data for table
                setTimeout(() => {
                    dispatch(setFolder(DRAFTS_MSG));
                }, 100);
            } else if (executeQuery.goTo === "list-messages-scheduled") {
                history(`/customers/messages/list`);
                // setTimeout to save cache restore data for table
                setTimeout(() => {
                    dispatch(setFolder(SCHEDULED_MSG));
                }, 100);
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateMessages);
