import { formatValue } from "react-currency-input-field";
import { Session } from "./Session";

export const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
export const allergensDefaultLang = "en";
export const allergensPrefix = () => {
    const chainRef = Session ? Session.getSessionProp("chainRef") : "";
    return `zafiro_allergens_${chainRef}`;
};
export const labelsPrefix = "zafiro_labels";
export const categoriesPrefix = "zafiro_categories";

export const getAllergens = () => {
    const response = [
        {
            code: "gluten",
            name: "gluten",
            label: "gluten",
            icon: "icon-gluten",
            icon_color: "#D2AC6A",
        },
        {
            code: "crustaceans",
            name: "crustaceans",
            label: "crustacean",
            icon: "icon-crustaceans",
            icon_color: "#925245",
        },
        {
            code: "eggs",
            name: "eggs",
            label: "eggs",
            icon: "icon-eggs",
            icon_color: "#F9AC56",
        },
        {
            code: "fish",
            name: "fish",
            label: "fish",
            icon: "icon-fish",
            icon_color: "#4171B5",
        },
        {
            code: "peanuts",
            name: "peanuts",
            label: "peanuts",
            icon: "icon-peanuts",
            icon_color: "#BC8E6A",
        },
        {
            code: "soy",
            name: "soy",
            label: "soy",
            icon: "icon-soy",
            icon_color: "#36B37E",
        },
        {
            code: "milk",
            name: "milk",
            label: "milk",
            icon: "icon-milk",
            icon_color: "#643980",
        },
        {
            code: "nuts",
            name: "nuts",
            label: "nuts",
            icon: "icon-nuts",
            icon_color: "#C16F63",
        },
        {
            code: "celery",
            name: "celery",
            label: "celery",
            icon: "icon-celery",
            icon_color: "#90C84B",
        },
        {
            code: "mustard",
            name: "mustard",
            label: "mustard",
            icon: "icon-mustard",
            icon_color: "#C1A661",
        },
        {
            code: "sesame_seeds",
            name: "sesame_seeds",
            label: "sesame-seeds",
            icon: "icon-sesame",
            icon_color: "#ABA18D",
        },
        {
            code: "sulphurdioxide_sulphites",
            name: "sulphurdioxide_sulphites",
            label: "sulphur-dioxide-sulphites",
            icon: "icon-sulfur-dioxide--sulphites",
            icon_color: "#85315B",
        },
        {
            code: "lupins",
            name: "lupins",
            label: "lupins",
            icon: "icon-lupins",
            icon_color: "#F1DA6A",
        },
        {
            code: "molluscs",
            name: "molluscs",
            label: "molluscs",
            icon: "icon-molluscs",
            icon_color: "#67C8DB",
        },
    ];
    return response;
};
export const getTranslationName = (translations, lang) => {
    let response = null;
    if (lang && translations && translations.length > 0) {
        let translation = translations.filter(
            (translationData) => translationData.languageCode && translationData.languageCode === lang
        );
        response = translation && translation.length > 0 ? translation[0].name : null;
    }
    return response;
};

export const getProjectLangs = (projectLangs, t) => {
    const response = { langDefault: "", langs: [], allLangs: [] };
    if (projectLangs) {
        const langsArrTemp = [];
        const allLangsTemp = [];
        projectLangs.forEach((pl) => {
            if (pl && pl.languageRef && !pl.isDefault) {
                pl["name"] = t("language:" + pl.languageRef);
                langsArrTemp.push(pl);
            }
            if (pl && pl.languageRef) {
                pl["name"] = t("language:" + pl.languageRef);
                allLangsTemp.push(pl);
            }
            if (pl && pl.isDefault) {
                response.langDefault = pl.languageRef;
            }
        });
        response.allLangs = allLangsTemp;
        response.langs = langsArrTemp;
    }
    return response;
};

export const parseTranslation = (translations, lang, opts) => {
    const defaultLang = opts?.defaultLang || "en";
    if (translations?.length > 0) {
        const langKey = opts?.langKey || "lang";
        const textKey = opts?.textKey || "text";
        translations.sort((a, b) => (a[langKey] > b[langKey] ? 1 : -1));
        const tr = translations.find ? translations.find((t) => t[langKey] === lang || lang === "any") : null;
        if (tr) {
            return tr[textKey];
        }
    }
    if (opts?.exact === true) {
        return null;
    }
    if (lang != "any") {
        if (lang != defaultLang) {
            return parseTranslation(translations, defaultLang, opts);
        }
        return parseTranslation(translations, "any", opts);
    }
    return null;
};

export const parseVendureTranslation = (translations, lang, opts) => {
    return parseTranslation(translations, lang, { ...opts, langKey: "languageCode", textKey: "name" });
};

export const getSymbolCurrency = ({ currency_code } = {}) => {
    if (!currency_code) {
        //return null;
        currency_code = "USD";
    }
    const parts = new Intl.NumberFormat(navigator?.language || "en-US", {
        style: "currency",
        currency: currency_code,
    }).formatToParts(1);
    return parts.find((x) => x.type === "currency")?.value || null;
};

export const objTranslations = (arrayTranslations) => {
    return arrayTranslations.reduce(
        (obj, item) =>
            Object.assign(obj, {
                [item.languageCode]: { name: item.name, lang: item.languageCode },
            }),
        {}
    );
};

export const formatPriceValueVendure = (value, currency) => {
    const currencySymbol = getSymbolCurrency({ currency_code: currency?.code });
    const formattedValue = formatValue({
        value: String(value?.toFixed(2)),
        decimalSeparator: ".",
        groupSeparator: ",",
    });

    if (currency?.code && currenciesSymbolPlaceBefore.includes(currency.code)) {
        return currencySymbol + " " + formattedValue;
    } else {
        return formattedValue + " " + currencySymbol;
    }
};

export const getExtraTypeAndSelection = (type) => {
    let response = {
        typeOfExtra: null,
        typeOfSelection: null,
    };
    if (type) {
        switch (type.toUpperCase()) {
            case "NORMAL":
                response.typeOfExtra = "single-item";
                response.typeOfSelection = "single-unit";
                break;
            case "NUMERIC":
                response.typeOfExtra = "single-item";
                response.typeOfSelection = "several-units-per-item";
                break;
            case "COMBOBOX":
                response.typeOfExtra = "group-items";
                response.typeOfSelection = "single-unit";
                break;
            case "MULTICOMBOBOX":
                response.typeOfExtra = "group-items";
                response.typeOfSelection = "several-units-per-item";
                break;
            default:
                break;
        }
    }
    return response;
};

export const updatePrice = (rate, setPriceAfterTax, setPriceBeforeTax) => {
    if (rate) {
        const price = calculatePrice(rate);

        if (setPriceAfterTax) {
            setPriceAfterTax(price);
        }
        if (setPriceBeforeTax) {
            setPriceBeforeTax(price);
        }
    }
};

export const calculatePrice = (rate) => {
    const price = (Math.round(rate * 100) / 100 / 100).toFixed(2);

    return formatValue({
        value: String(price),
        decimalSeparator: decimalSeparator,
        groupSeparator: "",
    });
};

export const calculateAfterTax = (valueBeforeTax, taxRate, decimalSeparator) => {
    const newTax = Number(valueBeforeTax) * (Number(taxRate) / 100);
    let newValueAfterTax = Number(valueBeforeTax) + Number(newTax);
    newValueAfterTax = Math.round(newValueAfterTax * 100) / 100;

    return formatValue({
        value: String(newValueAfterTax.toFixed(2)),
        decimalSeparator: decimalSeparator,
        groupSeparator: "",
    });
};

export const calculateBeforeTax = (valueAfterTax, taxRate, decimalSeparator) => {
    const newTax = Number(valueAfterTax) - Number(valueAfterTax) / (1 + Number(taxRate) / 100);
    let newValueBeforeTax = Number(valueAfterTax) - newTax;
    newValueBeforeTax = Math.round(newValueBeforeTax * 100) / 100;

    return formatValue({
        value: String(newValueBeforeTax.toFixed(2)),
        decimalSeparator: decimalSeparator,
        groupSeparator: "",
    });
};

export const handleTaxRateChange = (taxRate, priceBeforeTax, decimalSeparator, setPriceAfterTax) => {
    const newTaxRate = Number(taxRate);
    const newValueBeforeTax = parseFloat(String(priceBeforeTax).replace(decimalSeparator, ".")) || 0;
    setPriceAfterTax(calculateAfterTax(newValueBeforeTax, newTaxRate, decimalSeparator));
};

export const handleValueBeforeTaxChange = (values, taxRate, decimalSeparator, setPriceBeforeTax, setPriceAfterTax) => {
    const newValueBeforeTax = values;
    const newTaxRate = taxRate || 0;
    setPriceBeforeTax(newValueBeforeTax.formatted);
    setPriceAfterTax(calculateAfterTax(newValueBeforeTax.float, newTaxRate, decimalSeparator));
};

export const handleValueAfterTaxChange = (values, taxRate, decimalSeparator, setPriceBeforeTax, setPriceAfterTax) => {
    const newValueAfterTax = values;
    const newTaxRate = taxRate || 0;
    setPriceBeforeTax(calculateBeforeTax(newValueAfterTax.float, newTaxRate, decimalSeparator));
    setPriceAfterTax(newValueAfterTax.formatted);
};

export const getAssetUrl = (path, props) => {
    return Session.getVendureUrl(path);
};

export const initialParamsMutation = {
    execute: false,
    mutation: null,
    mutationFile: false,
    executeAnotherQuery: null,
    mutationName: null,
    avoidToast: false,
    aditionalHeader: null,
    refreshShopData: false,
    useLoading: true,
    avoidRefresh: false,
    avoidCloseModal: false,
    avoidCleanAction: false,
    multipleMutations: false,
    multipleMutationsPC: false,
    avoidLoading: false,
    extraParams: {},
};

export const nonDecimalCurrencies = ["BIF", "CLP", "DJF", "GNF", "ISK", "KRW", "PYG", "RWF", "UGX", "XOF", "XPF"];

export const currenciesSymbolPlaceBefore = ["GBP"];
