import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";
import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import UseTagSelector from "../../../../../useTagSelector";

const InputText = ({ id, title, placeholder, required, toolbarID, defaultText, tagsAvailable }) => {
    const { deviceType, itemSelected, screenType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const dispatch = useDispatch();
    const { lang } = useParams();

    const { widgetToolbarVisible, gridItems } = useSelector((state) => state.design);

    const [inputError, setInputError] = useState("");

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const [cValue, setCValue] = useState(null);

    const getText = (text) => {
        return text && text[lang] ? text[lang] : text && text.text ? text.text : defaultText ? defaultText : "";
    };

    const isOpen = itemSelected === widgetID && (!toolbarID || widgetToolbarVisible === toolbarID);

    useEffect(() => {
        if (isOpen) {
            if (data && data[id]) {
                const selector = "#" + id + "_" + widgetID;
                const elm = document.querySelector(selector);
                const v = getText(data[id]);
                if (elm && v) {
                    elm.value = v;
                }
            }
        }
        // eslint-disable-next-line
    }, [widgetToolbarVisible, gridItems]);

    useEffect(() => {
        if (cValue) {
            const _text = getText(data[id]);
            updateText(id, _text + " " + cValue);
            setCValue(null);
        }
        // eslint-disable-next-line
    }, [cValue]);
    //Actions
    const updateText = (property, value) => {
        if (required && value === "") {
            setInputError(property);
            return;
        }
        setInputError(false);
        let setData = data ? data : {};
        setData[property] = { [lang]: value };
        dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: setData })));
    };

    placeholder = placeholder || "";

    return (
        <div className="flex w-full mb-2">
            {title ? <div className="first-capital mt-2 w-3/12">{title}</div> : null}
            <div className={`${title ? "w-9/12" : "w-full"} relative`}>
                <input
                    type="text"
                    id={`${id}_${widgetID}`}
                    className={`bg-gray-200 rounded ${tagsAvailable ? "w-10/12" : "w-full"} px-4 py-2 ${
                        inputError === id ? " border border-red-100" : ""
                    } `}
                    placeholder={placeholder}
                    onBlur={(e) => updateText(id, e.target.value)}
                    onChange={(e) => updateText(id, e.target.value)}
                    onKeyUp={(e) => (e.keyCode === 13 ? updateText(id, e.target.value) : null)}
                />

                {tagsAvailable ? (
                    <UseTagSelector
                        itemLabel={"tags"}
                        cValue={cValue}
                        setCValue={setCValue}
                        customTop="0.1rem"
                        customRight="0px"
                        customPadding="py-0"
                        deviceType={deviceType}
                        screenType={screenType}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default InputText;
