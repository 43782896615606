import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseTagFilterCheckbox from "./useTagFilterCheckbox";
import UseCurrentFilterTag from "./useCurrentFilterTag";
//Actions
import { setCheckboxDropdownNames } from "../../actions/uiActions";
import { setCheckboxDropdownFilter, setActiveFilters, getFilterQuery, setPage } from "../../actions/tableActions";
//Utils
import useComponentVisible from "../../components/useComponentVisible";
import { removeDiacritics } from "./../../hooks/Utils/Utils";
import Parser from "hooks/Utils/Parser";
import { Fragment } from "react";
import UseCheckBox from "../Inputs/useCheckBox";
import _ from "lodash";

const UseCheckboxes = ({ index }) => {
    //Store data
    const { t } = useTranslation();

    let lastOptGroup;
    const {
        label,
        name,
        data,
        showSelected,
        search,
        adjust,
        translatable,
        stringType,
        noSelect,
        selectedById,
        filterType,
    } = useSelector((state) => state.table.availableFilters[index]);
    const { checkboxDropdownNames } = useSelector((state) => state.ui);
    const { checkboxDropdownFilter } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const isProperties = label === "properties" && name === "property";

    //States
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });
    const [optGroups, setOptGroups] = useState([]);

    const [dataOptions, setDataOptions] = useState(data ? JSON.parse(JSON.stringify(data)) : []);

    const [currentSearch, setCurrentSearch] = useState("");

    useEffect(() => {
        if (!currentSearch) {
            setDataOptions(JSON.parse(JSON.stringify(data)));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        setOptGroups(arrangeGroups());
    }, [dataOptions]);

    //Actions
    const dispatch = useDispatch();
    const getTranslations = (text) => {
        let _translation = "";
        if (Array.isArray(text)) {
            text.forEach((_word) => (_translation += t(_word) ? t(_word) : _word));
        } else _translation = t(text) ? t(text) : text;
        return _translation;
    };

    const clickOptionHandler = (e) => {
        //set UI state
        let newState;
        if (!checkboxDropdownNames || (checkboxDropdownNames && !checkboxDropdownNames[name])) {
            newState = [selectedById ? e.currentTarget.dataset.id : e.currentTarget.dataset.value];
        } else if (
            checkboxDropdownNames[name].includes(
                selectedById ? e.currentTarget.dataset.id : e.currentTarget.dataset.value
            )
        ) {
            newState = checkboxDropdownNames[name].filter(
                (as) => as !== (selectedById ? String(e.currentTarget.dataset.id) : e.currentTarget.dataset.value)
            );
        } else {
            newState = checkboxDropdownNames[name];
            newState.push(selectedById ? e.currentTarget.dataset.id : e.currentTarget.dataset.value);
        }
        dispatch(setCheckboxDropdownNames({ [name]: newState }));
        //set Data state
        let newDataFilter;
        if (!checkboxDropdownFilter || (checkboxDropdownFilter && !checkboxDropdownFilter[name])) {
            newDataFilter = [e.currentTarget.dataset.id];
        } else if (checkboxDropdownFilter[name].includes(e.currentTarget.dataset.id)) {
            newDataFilter = checkboxDropdownFilter[name].filter((as) => as !== e.currentTarget.dataset.id);
        } else {
            newDataFilter = checkboxDropdownFilter[name];
            newDataFilter.push(e.currentTarget.dataset.id);
        }
        dispatch(setCheckboxDropdownFilter({ [name]: newDataFilter }));
    };

    const clickTagHandler = (e) => {
        //remove from UI state
        const newState = checkboxDropdownNames[name].filter((as) =>
            as !== selectedById ? String(e.currentTarget.dataset.id) : e.currentTarget.dataset.value
        );
        dispatch(setCheckboxDropdownNames({ [name]: newState }));
        //remove from data state
        const newDataFilter = checkboxDropdownFilter[name].filter((as) => as !== e.currentTarget.dataset.id);
        dispatch(setCheckboxDropdownFilter({ [name]: newDataFilter }));
        setIsComponentVisible(false);
    };

    const changeQueryFilter = () => {
        if (checkboxDropdownFilter && checkboxDropdownFilter[name]) {
            let filterString = "";
            if (checkboxDropdownFilter[name].length === 1 && name === "staff-roles") {
                filterString = parseInt(checkboxDropdownFilter[name][0]);
            } else {
                filterString += name === "staff-roles" ? "" : "[";
                // eslint-disable-next-line
                checkboxDropdownFilter[name].map((value) => {
                    if (filterString !== "[" && filterString.length > 0) {
                        filterString += ",";
                    }
                    filterString += stringType ? `"${value}"` : value;
                });
                filterString += name === "staff-roles" ? "" : "]";
            }

            const filterName = name === "staff-roles" ? "roles" : name;

            dispatch(
                setActiveFilters(
                    filterName,
                    checkboxDropdownFilter[name] && checkboxDropdownFilter[name].length > 0 ? filterString : null
                )
            );
            dispatch(setPage(Number(1)));
            //dispatch(getFilterQuery(activeFilters));
            dispatch(
                getFilterQuery({
                    ...activeFilters,
                    [filterName]:
                        checkboxDropdownFilter[name] && checkboxDropdownFilter[name].length > 0 ? filterString : null,
                })
            );
        }
    };

    const changeVisibility = () => {
        if (!isComponentVisible) {
            setTimeout(function () {
                const inputSearch = document.querySelector(`#${name}_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 150);
        }
        setIsComponentVisible(isComponentVisible ? false : true);
    };

    const filterOptions = (e) => {
        e.stopPropagation();
        setCurrentSearch(e.currentTarget.value);
        const temCopy = JSON.parse(JSON.stringify(dataOptions));
        if (e.currentTarget.value && e.currentTarget.value.length > 0) {
            temCopy.map((item) => {
                if (typeof item === "object") {
                    item.visible =
                        removeDiacritics(String(item.value).toLowerCase()).indexOf(
                            removeDiacritics(e.currentTarget.value.toLowerCase())
                        ) > -1 ||
                        (item.optGroup &&
                            removeDiacritics(String(item.optGroup).toLowerCase()).indexOf(
                                removeDiacritics(e.currentTarget.value.toLowerCase())
                            ) > -1);
                }
                return item;
            });
        } else {
            temCopy.map((item) => (typeof item === "object" ? (item.visible = true) : null));
        }
        setDataOptions(temCopy);
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
            if (search && currentSearch !== "") {
                //Desactivar filtrado de busqueda
                const temCopy = JSON.parse(JSON.stringify(dataOptions));
                temCopy.map((item) => (item ? (item.visible = true) : null));
                setDataOptions(temCopy);
                setCurrentSearch("");
            }
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
        // eslint-disable-next-line
    }, [isComponentVisible]);

    useEffect(() => {
        //Clean filters
        if (activeFilters && !activeFilters[name]) {
            dispatch(setCheckboxDropdownFilter({ [name]: null }));
        }
        // eslint-disable-next-line
    }, [activeFilters]);

    useEffect(() => {
        changeQueryFilter();
        setOptGroups(arrangeGroups());
        // eslint-disable-next-line
    }, [checkboxDropdownNames]);

    const arrangeGroups = () => {
        let response = [];
        if (dataOptions && dataOptions.length > 0) {
            dataOptions.forEach((option) => {
                if (option.optGroup) {
                    let optGroup = {
                        name: option.optGroup,
                        checked: false,
                        options: [],
                    };
                    let prevIndex = _.findIndex(response, (res) => res.name === option.optGroup);
                    if (prevIndex === -1) {
                        optGroup.options.push(`${option[selectedById ? "id" : "value"]}`);
                        response.push(optGroup);
                    } else {
                        optGroup = response[prevIndex];
                        optGroup.options.push(`${option[selectedById ? "id" : "value"]}`);
                        response[prevIndex] = optGroup;
                    }
                }
            });
            if (checkboxDropdownNames && checkboxDropdownNames[name] && checkboxDropdownNames[name].length > 0) {
                response.map((item) => {
                    item.checked = item.options.every((val) => checkboxDropdownNames[name].includes(val));
                });
            }
        }

        return response;
    };

    const addOptGroup = (optGroup) => {
        let response = [];
        if (optGroup) {
            if (optGroup !== lastOptGroup) {
                let optGroupData = _.find(optGroups, { name: optGroup });
                response.push(
                    <div className="w-full mb-4">
                        <UseCheckBox
                            forceUpdateChecked={true}
                            checked={optGroupData.checked}
                            name={optGroup}
                            enabled={true}
                            onClick={(switchValue) => {
                                handleClickOptGroup(optGroup, switchValue);
                            }}
                            nameStyle="font-bold text-gray-900 first-capital"
                        />
                    </div>
                );
            }
            lastOptGroup = optGroup;
        }
        return response;
    };

    const handleClickOptGroup = (optGroup, switchValue) => {
        let options = [];
        let optGroupsClone = [...optGroups];
        let optGroupsIndex = _.findIndex(optGroupsClone, (res) => res.name === optGroup);
        let items = document.querySelectorAll(`[data-group="${optGroup}"]`);
        if (items && items.length > 0) {
            items.forEach((item) => {
                options.push({
                    id: item.dataset.id,
                    value: item.dataset.value,
                });
            });
        }
        if (options && options.length > 0) {
            let newState;
            if (!checkboxDropdownNames || (checkboxDropdownNames && !checkboxDropdownNames[name])) {
                newState = _.map(options, selectedById ? "id" : "value");
            } else {
                newState = checkboxDropdownNames[name].filter(
                    (as) => !_.map(options, selectedById ? "id" : "value").includes(as)
                );
                if (switchValue) {
                    let newData = _.map(options, selectedById ? "id" : "value");
                    newState = [...newState, ...newData];
                }
            }
            dispatch(setCheckboxDropdownNames({ [name]: newState }));

            let newDataFilter;
            if (!checkboxDropdownFilter || (checkboxDropdownFilter && !checkboxDropdownFilter[name])) {
                newDataFilter = _.map(options, "id");
            } else {
                newDataFilter = checkboxDropdownFilter[name].filter((as) => !_.map(options, "id").includes(as));
                if (switchValue) {
                    let newData = _.map(options, "id");
                    newDataFilter = [...newDataFilter, ...newData];
                }
            }
            dispatch(setCheckboxDropdownFilter({ [name]: newDataFilter }));
            optGroupsClone[optGroupsIndex].checked = switchValue;
            setOptGroups(optGroupsClone);
        }
    };

    const showSelectedOptionsInDropDownSection = () => {
        let response = [];
        if (
            checkboxDropdownFilter != null &&
            checkboxDropdownFilter[name] != null &&
            checkboxDropdownFilter[name].length > 0
        ) {
            // eslint-disable-next-line
            checkboxDropdownFilter[name].map((item, index) => {
                if (response !== null && response.length < 1)
                    response.push(
                        <p key={index} className="font-bold text-gray-900 first-capital mt-1 mb-1">
                            {t("selected")}:
                        </p>
                    );
                if (checkboxDropdownNames[name][index]) {
                    response.push(
                        <div
                            key={checkboxDropdownNames[name][index]}
                            id={name && item ? `${name.toLowerCase()}-${item.toLowerCase()}-selected-filter` : null}
                            className="float-left z-20  bg-blue-200 text-white pl-2 mr-4 p-1 mb-2 rounded whitespace-no-wrap"
                        >
                            {Parser(
                                selectedById
                                    ? data.find((obj) => obj.id === parseInt(checkboxDropdownNames[name][index])).value
                                    : checkboxDropdownNames[name][index]
                            )}
                            {
                                <div
                                    className="icon-remove inline-block ml-8 cursor-pointer"
                                    data-id={item}
                                    id={
                                        name && item
                                            ? `${name.toLowerCase()}-${item.toLowerCase()}-delete-button`
                                            : null
                                    }
                                    data-value={
                                        translatable
                                            ? getTranslations(checkboxDropdownNames[name][index])
                                            : checkboxDropdownNames[name][index]
                                    }
                                    key={index}
                                    onClick={clickOptionHandler}
                                ></div>
                            }
                        </div>
                    );
                }
            });
        }
        return response;
    };

    return (
        <div className={`inline-block w-filter-checkbox w-full ${adjust}`} key={ref} ref={ref}>
            <div
                className={`w-full h-10 bg-gray-200 rounded relative flex items-center cursor-pointer pr-12 ${selectActive.container}`}
            >
                {showSelected &&
                checkboxDropdownNames &&
                checkboxDropdownNames[name] &&
                checkboxDropdownNames[name].length > 0 ? (
                    dataOptions.map((option, index) => (
                        <div className="contents" key={index}>
                            {checkboxDropdownNames[name].includes(
                                translatable ? getTranslations(option.value) : option.value
                            ) ? (
                                <div
                                    className="my-2 ml-1 inline"
                                    data-id={option.id}
                                    data-value={translatable ? getTranslations(option.value) : option.value}
                                    onClick={clickTagHandler}
                                    key={index}
                                >
                                    <UseCurrentFilterTag
                                        name={name}
                                        color={option.color}
                                        value={selectedById ? option.id : option.value}
                                        valueText={option.value}
                                        translatable={translatable}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ))
                ) : (
                    <div
                        className={`bg-transparent w-full pl-4 first-capital overflow-hidden text-gray-900 ${selectActive.text}`}
                        onClick={() => changeVisibility()}
                        id={filterType && name ? `${filterType}-${name}` : null}
                    >
                        {checkboxDropdownFilter &&
                        checkboxDropdownFilter[name] &&
                        checkboxDropdownNames &&
                        checkboxDropdownNames[name].length > 0 ? (
                            noSelect ? (
                                `${checkboxDropdownNames[name].length} ${t("selected")}`
                            ) : (
                                checkboxDropdownNames[name].map(
                                    (item, index) =>
                                        `${item}${index === checkboxDropdownNames[name].length - 1 ? "" : ", "}`
                                )
                            )
                        ) : (
                            <span className="text-gray-600">{t(label)}</span>
                        )}
                    </div>
                )}
                <span
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => changeVisibility()}
                    id={filterType && name ? `${filterType}-${name}-icon` : null}
                ></span>
                {isComponentVisible ? (
                    <div
                        className={`absolute left-0 w-auto bg-white shadow-lg rounded border border-gray-200 px-1 pt-2 z-100 t-checkboxes-container `}
                    >
                        <div>
                            {search ? (
                                <div>
                                    <input
                                        id={`${name}_input`}
                                        type="text"
                                        className="t-filter-search"
                                        style={{ width: "calc(100% - 1rem)" }}
                                        placeholder={`${t("search")}...`}
                                        onChange={filterOptions}
                                    ></input>
                                    <span
                                        className="field-icon icon-search table mt-6"
                                        style={{
                                            height: "2rem",
                                            paddingTop: "0.3rem",
                                        }}
                                    ></span>
                                </div>
                            ) : null}
                        </div>
                        {noSelect ? (
                            <div className="bg-transparent w-full overflow-hidden pl-3">
                                {showSelectedOptionsInDropDownSection()}
                            </div>
                        ) : null}
                        <div className="max-h-60 px-4 pt-4 overflow-scroll">
                            {dataOptions.map((option, index) =>
                                !search || (search && option.visible) ? (
                                    <Fragment key={isProperties && option.id ? option.id : index}>
                                        {addOptGroup(option.optGroup)}
                                        <div
                                            //onClick={addData}
                                            data-id={option.id}
                                            id={filterType && name ? `${filterType}-${name}-${index}-opt` : null}
                                            data-type={option.type}
                                            data-value={translatable ? getTranslations(option.value) : option.value}
                                            data-group={option.optGroup}
                                            key={isProperties && option.id ? option.id : index}
                                            onClick={clickOptionHandler}
                                            className={`${option.optGroup ? "ml-6" : ""}`}
                                        >
                                            <UseTagFilterCheckbox
                                                name={name}
                                                filter={selectedById ? option.id : option.value}
                                                filterText={option.value}
                                                translatable={translatable}
                                            />
                                        </div>
                                    </Fragment>
                                ) : null
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default UseCheckboxes;
