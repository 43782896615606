import { capitalizeFirst } from "hooks/Utils/Utils";
import { EN_CUSTOM_FIELD, ES_CUSTOM_FIELD } from "../utils";
import i18n from "i18n";

const AddCustomItem = ({ action, editData = null }) => {
    console.log(editData);

    // const names = editData.names;

    const EN_NAME_VALUE = editData?.names?.find((n) => n.lang === "en")?.name?.trim() || null;

    const ES_NAME_VALUE = editData?.names?.find((n) => n.lang === "es")?.name?.trim() || null;

    return {
        title: editData ? editData.name + " - " + capitalizeFirst(i18n.t("edit-item")) : i18n.t("add-custom-item"),
        bodyAdjust: "w-3/12",
        text: "write-the-name-of-the-item",
        executeSectionQuery: true,
        inputs: [
            {
                type: "void",
                text: "please-note-this-item-is-being-included-in-guest-report",
                style: editData?.blocked ? "" : "hidden",
            },
            {
                id: EN_CUSTOM_FIELD,
                name: EN_CUSTOM_FIELD,
                text: "language:" + "en",
                type: "edit-custom-field",
                cStyle: "flex items-center space-x-3 ",
                textStyle: "w-3/12 font-bold",
                ph: "custom-item",
                isEdit: editData ? true : false,
                inputStyle: "w-9/12",
                values: { en: EN_NAME_VALUE, es: ES_NAME_VALUE },
            },

            // {
            //     id: EN_CUSTOM_FIELD,
            //     name: EN_CUSTOM_FIELD,
            //     text: "language:" + "en",
            //     type: "text",
            //     cStyle: "flex items-center space-x-3 ",
            //     textStyle: "w-3/12 font-bold",
            //     ph: "custom-item",
            //     inputStyle: "w-9/12",
            //     value: EN_NAME_VALUE,
            //     // noResize: true,
            //     // formatText: false,
            // },
            // {
            //     id: ES_CUSTOM_FIELD,
            //     name: ES_CUSTOM_FIELD,
            //     text: "language:" + "es",
            //     textStyle: "w-3/12 font-bold",
            //     ph: "custom-item",
            //     inputStyle: "w-9/12",
            //     type: "text",
            //     cStyle: "flex items-center space-x-3 ",
            //     value: ES_NAME_VALUE,
            //     // noResize: true,
            //     // formatText: false,
            // },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: editData ? "save" : "add",
                style: "blue",
                // action: action ? action : "edit-custom-field",
                actionCallback: () => {
                    if (typeof action === "function") {
                        action();
                    }
                },
            },
        ],
    };
};

export default AddCustomItem;
