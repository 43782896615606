import { Session } from "../Session";

/**
 * @typedef ApiData {{url: string, token: string}}
 */

/**
 * @returns {ApiData}
 */
export const getVendureApiData = () => {
    const url = Session.getVendureUrl("/admin-api");
    const token = Session.getSessionProp("token");
    return {
        url: url,
        token: token,
    };
};

/**
 * @returns {ApiData}
 */
export const getVendureShopApiData = () => {
    const url = Session.getVendureUrl("/shop-api");
    const token = Session.getSessionProp("token");
    return {
        url: url,
        token: token,
    };
};
/**
 * @param apiData {ApiData}
 * @param params {{queryBody: string}}
 * @param aditionalHeader {Record<string, string>}
 * @return {Promise<{data: any} | {error: any}>}
 */
export const executeVendureQuery = async (apiData, params, aditionalHeader = {}) => {
    const { url, token } = apiData;
    const { queryBody, variables } = params;
    let body = null;
    let dataResponse = null;
    let errorResponse = null;

    if (queryBody && variables) {
        body = JSON.stringify({
            query: queryBody,
            variables: variables,
        });
    } else if (queryBody) {
        body = JSON.stringify({ query: queryBody });
    }

    const vendureTokenRequired = aditionalHeader
        ? Object.keys(aditionalHeader).find((key) => key.toLowerCase().includes("vendure-token"))
        : false;
    if (vendureTokenRequired && aditionalHeader[vendureTokenRequired]?.length === 0) {
        return {
            data: null,
            error: "Vendure token is required and not provided",
        };
    }

    await fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token,
            ...aditionalHeader,
        },
        body: body,
    })
        .then((res) => {
            if (res.ok || !res.statusText) {
                return res.json();
            } else {
                throw new Error(res.statusText);
            }
        })
        .then(async (data) => {
            if (data?.errors?.length > 0) {
                throw new Error(data.errors[0].message);
            }
            dataResponse = data.data ? data.data : data;
        })
        .catch(async (err) => {
            errorResponse = err.message || "unknown error";
        });
    return {
        data: dataResponse,
        error: errorResponse,
    };
};

export const uploadVendureMutation = async (apiData, params, aditionalHeader = {}) => {
    const { url, token } = apiData;
    let dataResponse = null;
    let errorResponse = null;
    const data = new FormData();

    const { queryBody, file } = params;
    const headers = {
        Authorization: token,
        ...aditionalHeader,
    };
    data.append(
        "operations",
        JSON.stringify({
            query: queryBody,
            variables: {
                file: file,
            },
        })
    );
    data.append("map", `{"0": ["variables.file"]}`);
    data.append("0", file);

    await fetch(url, {
        method: "post",
        headers: headers,
        body: data,
    })
        .then((res) => {
            if (res.ok) {
                return res.json();
            } else {
                console.log(res);
                throw new Error(res.statusText);
            }
        })
        .then(async (data) => {
            dataResponse = data.data ? data.data : data;
        })
        .catch(async (err) => {
            errorResponse = err.message;
        });
    return {
        data: dataResponse,
        error: errorResponse,
    };
};
