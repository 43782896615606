import React from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

import ListDesigns from "../../../hooks/GraphqlCalls/Design/ListDesigns";
import UpdateDesign from "../../../hooks/GraphqlCalls/Design/UpdateDesign";

const Designs = () => {
    //Actions

    ListDesigns();
    return (
        <>
            <UpdateDesign />
            <UseSectionHeader title={["list-of", "designs"]} value={"demo"} />
            <UseTable />
        </>
    );
};

export default Designs;
