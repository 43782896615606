import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import WidgetToolbar from "./WidgetToolbar";
import ScreensToolbar from "./ScreensToolbar";

import { setDesignToolbarVisible, setItemSelected } from "../../../actions/designActions";
import ReactTooltip from "components/ReactTooltip";
import { useTranslation } from "react-i18next";
import { SCREEN } from "../../../hooks/Utils/Design/EditorUtils";

const DesignToolbar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store data
    const { designToolbarVisible, editorMode, widgetToolbarEnabled } = useSelector((state) => state.design);
    const { permissions } = useSelector((state) => state.ui);

    const { type } = useParams();

    const landings =
        !type ||
        type === "TV" ||
        type === "WELCOME" ||
        type === "MOBILE" ||
        type === "CAST" ||
        type === "SALES" ||
        type === "SALESMOBILE" ||
        type === "CCINSTRUCTION";

    const products = permissions ? permissions.product : null;
    const tvEnabled = products && products.tv;
    const mobileEnabled = products && products.mobile;
    const custScreensEnabled = tvEnabled || mobileEnabled;

    const setVisible = (item, forceShow) => {
        dispatch(setDesignToolbarVisible(!forceShow && designToolbarVisible === item ? null : item));
        dispatch(setItemSelected(null));
    };

    useEffect(() => {
        if (!designToolbarVisible) {
            setVisible(landings ? "landings" : "screens", true);
        }
        return () => {
            dispatch(setDesignToolbarVisible());
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div id="designToolbarContainer" className="z-201 border-r fixed h-full border-gray-200 bg-white">
            <div id="designToolbar" className="float-left grid grid-cols-1 ">
                <div
                    id={"landing-screens-menu-option"}
                    className={`border-b sidebar-submenu-link inline-block border-gray-200 py-0 ${
                        designToolbarVisible === "landings" ? "text-zafiro-600  bg-gray-100" : ""
                    }`}
                    onClick={(e) => setVisible("landings")}
                    data-tip
                    data-for={`landing screens`}
                >
                    <div className="icon icon-predefined-screen cursor-pointer p-6   text-3xl"></div>
                </div>
                <ReactTooltip id={`landing screens`}>
                    {t("{{capitalize, capitalize}}", { capitalize: t("landing screens") })}
                </ReactTooltip>

                {custScreensEnabled ? (
                    <>
                        <div
                            id={"my-screens-menu-option"}
                            className={`border-b sidebar-submenu-link inline-block border-gray-200 py-0 ${
                                designToolbarVisible === "screens" ? "text-zafiro-600  bg-gray-100" : ""
                            }`}
                            onClick={(e) => setVisible("screens")}
                            data-tip
                            data-for={`screens`}
                        >
                            <div className="icon icon-content cursor-pointer p-6   text-3xl"></div>
                        </div>
                        <ReactTooltip id={`screens`}>
                            {t("{{capitalize, capitalize}}", { capitalize: t("my screens") })}
                        </ReactTooltip>
                    </>
                ) : null}

                {editorMode === "EDITION" ? (
                    <>
                        <div
                            id={"widgets-menu-option"}
                            className={`border-b sidebar-submenu-link inline-block border-gray-200 py-0 ${
                                designToolbarVisible === "widgets" ? "text-zafiro-600 bg-gray-100" : ""
                            } ${widgetToolbarEnabled ? "" : "text-gray-500"}`}
                            onClick={(e) => {
                                if (widgetToolbarEnabled) {
                                    setVisible("widgets");
                                }
                            }}
                            data-tip
                            data-for={`widget toolbar`}
                        >
                            <div className="icon icon-areas-activities cursor-pointer p-6  text-3xl"></div>
                        </div>
                        <ReactTooltip id={`widget toolbar`}>
                            {t("{{capitalize, capitalize}}", { capitalize: t("widgets") })}
                        </ReactTooltip>
                    </>
                ) : null}
                {/*
                <div className="border border-gray-200 inline-blockv sidebar-submenu-link py-0">
                    <div className="icon icon-eye-blocked p-6 text-3xl border-b border-t border-gray-200  cursor-pointer"></div>
                </div>
                */}
            </div>

            <div
                id="widgets_toolbar"
                className=" border collapseAnimation absolute  float-left  h-full overflow-hidden border-gray-200 bg-white "
                style={{
                    width: designToolbarVisible === "widgets" ? (type === SCREEN.TYPE.PORTAL ? "24rem" : "20rem") : "0",
                    top: "3.15rem",
                    left: "4.7rem",
                }}
            >
                <WidgetToolbar prefixId={"widgets_toolbar"} setVisible={setVisible}></WidgetToolbar>
            </div>

            <div
                id="landings_toolbar"
                className=" border collapseAnimation absolute float-left h-full overflow-hidden border-gray-200 bg-white"
                style={{
                    width: designToolbarVisible === "landings" ? "24rem" : "0",
                    top: "3.15rem",
                    left: "4.7rem",
                }}
            >
                <ScreensToolbar prefixId={"landings_toolbar"} landings={true} setVisible={setVisible}></ScreensToolbar>
            </div>

            {custScreensEnabled ? (
                <div
                    id="screens_toolbar"
                    className=" border collapseAnimation absolute float-left h-full overflow-hidden border-gray-200 bg-white"
                    style={{
                        width: designToolbarVisible === "screens" ? "24rem" : "0",
                        top: "3.15rem",
                        left: "4.7rem",
                    }}
                >
                    <ScreensToolbar
                        prefixId={"screens_toolbar"}
                        landings={false}
                        setVisible={setVisible}
                    ></ScreensToolbar>
                </div>
            ) : null}
        </div>
    );
};

export default DesignToolbar;
