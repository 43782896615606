import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ShopContext } from "contexts/Sales/Shop";

import Loading from "components/Loading";
import { EditPrice, FooterButtons } from "./Delivery.jsx";

import { DELIVERY_METHOD_ROOM } from "constants/sales";

const EditRoomService = ({ onClose }) => {
    const { t } = useTranslation();
    const { shippingConfig, taxRates, updateShippingConfig } = useContext(ShopContext);

    const [updating, setUpdating] = useState(false);

    const config = shippingConfig?.[DELIVERY_METHOD_ROOM];

    const storedPrice = config?.price?.base ?? null;
    const storedRate = config?.price?.taxRate ?? null; // This is a tax rate value (%), not an ID
    const storedTaxRateID =
        storedRate && taxRates ? taxRates.find((rate) => Number(rate.value) === Number(storedRate))?.id : null;

    const [price, setPrice] = useState(storedPrice);
    const [taxRateID, setTaxRateID] = useState(storedTaxRateID ?? null);
    const [taxRate, setTaxRate] = useState(storedRate ?? null);

    const onChangePrice = ({ price, taxRate }) => {
        setPrice(price);
        setTaxRateID(taxRate?.id);
        setTaxRate(taxRate?.value);
    };

    const cancelChanges = () => {
        setPrice(storedPrice);
        setTaxRateID(storedTaxRateID);
        setTaxRate(storedRate);
        if (onClose) {
            onClose(false);
        }
    };

    const saveChanges = () => {
        const newShippingConfig = {
            ...shippingConfig,
            [DELIVERY_METHOD_ROOM]: {
                ...config,
                price: {
                    ...config?.price,
                    base: price,
                    taxRate,
                },
            },
        };
        setUpdating(true);
        updateShippingConfig(newShippingConfig)
            .then(() => {
                toast.success(t("operation-successful"));
            })
            .finally(() => {
                setUpdating(false);
                if (onClose) {
                    onClose(true);
                }
            });
    };

    return (
        <>
            <div className="border-t-2 py-6 px-10">
                {updating ? (
                    <Loading />
                ) : (
                    <EditPrice
                        id={`delivery-${DELIVERY_METHOD_ROOM}-price`}
                        price={price}
                        taxRate={taxRateID}
                        onChangePrice={onChangePrice}
                    />
                )}
            </div>
            <FooterButtons
                id={`button-${DELIVERY_METHOD_ROOM}`}
                disabled={updating}
                onCancel={() => {
                    cancelChanges();
                }}
                onSave={() => {
                    saveChanges();
                }}
            />
        </>
    );
};

export default EditRoomService;
