import React, { useEffect, useState } from "react";
import { executeVendureQuery } from "hooks/Utils/Integrations/useVendure";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Session } from "hooks/Utils/Session";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { getVendureApiData } from "hooks/Utils/Integrations/useVendure";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { parseTranslation } from "hooks/Utils/SalesUtils";
import { setRefreshData } from "actions/tableActions";
import { allergensPrefix, labelsPrefix } from "hooks/Utils/SalesUtils";
import { toast } from "react-toastify";
import {
    QUERY_GET_PRODUCT_DATA,
    QUERY_SALES_CATEGORIES_DATA,
    QUERY_SALES_GET_LABELS_DATA,
    QUERY_SALES_PRODUCT_SETTINGS_VENDURE_TOKEN,
    QUERY_SALES_PRODUCT_SETTINGS_WITHOUT_VENDURE_TOKEN,
    QUERY_SALES_SHOP_ASSIGNED_USERS_DATA,
} from "./SalesQueries";
import { SalesContextDeprecated } from "contexts/Sales";

export const SalesProductsProvider = ({ children }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);

    const [data, setData] = useState(null);
    const [tokenShop, setTokenShop] = useState("");
    const [loadingContext, setLoadingContext] = useState(true);

    const GET_PRODUCT_DATA = QUERY_GET_PRODUCT_DATA(id);

    const refetch = async ({ token, id }) => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_PRODUCT_DATA },
            { "vendure-token": token }
        );

        const keysToCheck = ["channel", "products"];

        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (response?.data?.[key]) {
                acc[key] = response.data[key];
            } else {
                console.error(`Missing key: ${key}`);
            }
            return acc;
        }, {});

        setLoadingContext(false);
        setData({ ...dataFetched });
    };

    useEffect(() => {
        if (data?.channel?.token) {
            Session.setSessionProp("tokenShop", data.channel.token);
            Session.setSessionProp("idShop", data.channel.id);
            setTokenShop(data.channel.token);
            refetch({ token: data.channel.token, id: data.channel.id });
        }
    }, [data?.channel?.token]);

    useEffect(() => {
        if (refreshData && data?.channel?.token) {
            refetch({ token: data.channel.token, id: data.channel.id });
            dispatch(setRefreshData(false));
            setLoadingContext(true);
        }
    }, [refreshData]);

    return (
        <SalesContextDeprecated.Provider
            value={{
                data,
                name: data?.channel?.name,
                token: data?.channel?.token,
                isActive: data?.channel?.customFields?.is_active,
                loadingContext,
            }}
        >
            <GetShopTokenByIdShop setDataShopVendure={setData} />
            <GetNameShop tokenShop={tokenShop} data={data} setData={setData} />
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const SalesProductSettingsProvider = ({ children, setParentData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id: shopId, productId } = useParams();
    const { refreshData } = useSelector((state) => state.sectionContent);

    const [data, setData] = useState(null);
    const tokenShop = Session.getSessionProp("tokenShop") || null;
    const [loadingContext, setLoadingContext] = useState(true);
    const [fetchingDataNoToken, setFetchingNoDataToken] = useState(true);
    const [dataWithVendureToken, setDataWithVendureToken] = useState(null);
    const [dataWithoutVendureToken, setDataWithoutVendureToken] = useState(null);

    useEffect(() => {
        if (data) {
            setData({ ...data, ...dataWithVendureToken, ...dataWithoutVendureToken });
        } else {
            setData({ ...dataWithVendureToken, ...dataWithoutVendureToken });
        }
    }, [dataWithVendureToken, dataWithoutVendureToken]);

    useEffect(() => {
        if (setParentData) {
            setParentData(data);
        }
    }, [data]);

    const GET_DATA_VENDURE_TOKEN = QUERY_SALES_PRODUCT_SETTINGS_VENDURE_TOKEN({ shopId, productId });

    const GET_DATA_WITHOUT_VENDURE_TOKEN = QUERY_SALES_PRODUCT_SETTINGS_WITHOUT_VENDURE_TOKEN({
        productId,
        allergensPrefix: allergensPrefix(),
        labelsPrefix,
    });

    const refetchWithVendureToken = async () => {
        setFetchingNoDataToken(true);
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_DATA_VENDURE_TOKEN },
            { "vendure-token": Session.getSessionProp("tokenShop") }
        );

        if (!response.data && response.error) {
            toast.error(t("mutation-error"));
            return;
        }

        const keysToCheck = ["extras", "channel", "product", "productVariants", "taxRates"];
        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (response?.data?.[key]) {
                acc[key] = response.data[key];
            } else {
                console.error(`Missing key: ${key}`);
            }
            return acc;
        }, {});
        refetchWithoutVendureToken();
        setLoadingContext(false);
        setDataWithVendureToken({ ...dataFetched });
    };

    const refetchWithoutVendureToken = async () => {
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_DATA_WITHOUT_VENDURE_TOKEN });
        if (!response.data && response.error) {
            toast.error(t("mutation-error"));
            setFetchingNoDataToken(false);

            return;
        }
        const keysToCheck = ["allergens", "labels"];
        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (response?.data?.[key]) {
                acc[key] = response.data[key];
            } else {
                console.error(`Missing key: ${key}`);
            }
            return acc;
        }, {});
        setFetchingNoDataToken(false);
        setLoadingContext(false);
        setDataWithoutVendureToken({ ...dataFetched });
    };

    useEffect(() => {
        refetchWithVendureToken();
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetchWithVendureToken();
            dispatch(setRefreshData(false));
            setLoadingContext(true);
        }
    }, [refreshData]);

    return (
        <SalesContextDeprecated.Provider
            value={{
                data,
                loadingContext,
                fetchingDataNoToken,
            }}
        >
            <GetNameShop tokenShop={tokenShop} data={data} setData={setData} />
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const SalesAssignedUsersProvider = ({ children, setParentData, setLoadingParent }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const tokenShop = Session.getSessionProp("tokenShop") || null;
    const [loadingContext, setLoadingContext] = useState(true);
    const [data, setData] = useState(null);

    const GET_ZAFIRO_SHOP_DATA = gql(QUERY_SALES_SHOP_ASSIGNED_USERS_DATA(tokenShop));

    const [executeQuery, { data: zafiroShopData, refetch: refetchZafiroShopData }] = useLazyQuery(
        GET_ZAFIRO_SHOP_DATA,
        {
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (zafiroShopData?.shops?.results?.[0] && setParentData) {
            const name = parseTranslation(
                zafiroShopData.shops.results[0].nameTranslations,
                getProjectLangs(projectLangs, t).langDefault
            );
            if (name) {
                setData({ ...zafiroShopData, name: name });
            }
            setParentData(zafiroShopData);
            setLoadingContext(false);
        }
    }, [zafiroShopData]);

    useEffect(() => {
        if (refreshData) {
            if (refetchZafiroShopData) {
                refetchZafiroShopData();
            }
            dispatch(setRefreshData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        if (setLoadingParent) {
            setLoadingParent(loadingContext);
        }
    }, [loadingContext]);

    return (
        <SalesContextDeprecated.Provider
            value={{
                data,
                loadingContext,
            }}
        >
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const SalesLabelsProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [loadingContext, setLoadingContext] = useState(true);

    const { refreshData } = useSelector((state) => state.sectionContent);
    const tokenShop = Session.getSessionProp("tokenShop") || null;
    const { t } = useTranslation();

    const { id: shopId } = useParams();
    const dispatch = useDispatch();

    const GET_LABELS_DATA = QUERY_SALES_GET_LABELS_DATA({ shopId, labelsPrefix });

    const refetch = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_LABELS_DATA },
            { "vendure-token": Session.getSessionProp("tokenShop") }
        );

        if (!response.data && response.error) {
            toast.error(t("mutation-error"));
            return;
        }

        const keysToCheck = ["channel", "facets"];
        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (response?.data?.[key]) {
                acc[key] = response.data[key];
            } else {
                console.error(`Missing key: ${key}`);
            }
            return acc;
        }, {});
        setLoadingContext(false);

        setData({ ...dataFetched });
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshData(false));
            setLoadingContext(true);
        }
    }, [refreshData]);

    return (
        <SalesContextDeprecated.Provider
            value={{
                data,
                loadingContext,
            }}
        >
            <GetNameShop tokenShop={tokenShop} data={data} setData={setData} />
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const SalesCategoriesProvider = ({ children }) => {
    const { t } = useTranslation();
    const { idCat: idCategory } = useParams();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const tokenShop = Session.getSessionProp("tokenShop");
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const GET_SHOP_CATEGORIES = QUERY_SALES_CATEGORIES_DATA(idCategory);

    const refetch = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_SHOP_CATEGORIES },
            { "vendure-token": tokenShop }
        );

        if (!response.data && response.error) {
            toast.error(t("mutation-error"));
            console.log(response.error);
            return;
        }

        const keysToCheck = [idCategory ? "collection" : null, "collections", "products"];
        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (key) {
                if (response?.data?.[key]) {
                    acc[key] = response.data[key];
                } else {
                    console.error(`Missing key: ${key}`);
                }
            }
            return acc;
        }, {});

        setData({ ...dataFetched });
    };

    useEffect(() => {
        refetch();
    }, [idCategory]);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshData(false));
        }
    }, [refreshData]);

    return (
        <SalesContextDeprecated.Provider value={{ data }}>
            <GetNameShop tokenShop={tokenShop} data={data} setData={setData} />
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const SalesExtrasProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const tokenShop = Session.getSessionProp("tokenShop");

    return (
        <SalesContextDeprecated.Provider value={{ data }}>
            <GetNameShop tokenShop={tokenShop} data={data} setData={setData} />
            {children}
        </SalesContextDeprecated.Provider>
    );
};

export const GetNameShop = ({ tokenShop, data, setData }) => {
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { t } = useTranslation();

    const GET_ZAFIRO_SHOP = gql(`
        query {
            shops( token: "${tokenShop}" ) {
                results {
                    name: nameTranslations {
                        lang
                        text
                    }        
                }
            }
        } 
    `);

    const [executeQuery, { data: zafiroShopData, refetch: refetchZafiroShopData }] = useLazyQuery(GET_ZAFIRO_SHOP, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (tokenShop) {
            executeQuery();
        }
    }, [tokenShop]);

    useEffect(() => {
        if (refreshData && refetchZafiroShopData) {
            if (refetchZafiroShopData) {
                refetchZafiroShopData();
            }
            dispatch(setRefreshData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        if (zafiroShopData?.shops?.results?.length > 0) {
            const name = parseTranslation(
                zafiroShopData.shops.results[0].name,
                getProjectLangs(projectLangs, t).langDefault
            );
            if (name) {
                setData({ ...data, name: name });
            }
        }
    }, [zafiroShopData]);

    return null;
};

export const GetShopTokenByIdShop = ({ setDataShopVendure }) => {
    const { id: shopId } = useParams();
    const { t } = useTranslation();

    const GET_SHOP_TOKEN = `
    query{
        channel(id: ${shopId})
        {
            token
            id
            customFields{
                is_active
            }
        }
    }
    `;

    const refetch = async () => {
        // setFetchingNoDataToken(true);
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_SHOP_TOKEN }
            // { "vendure-token": token }
        );

        if (!response.data && response.error) {
            toast.error(t("mutation-error"));
            return;
        }

        const keysToCheck = ["channel"];
        const dataFetched = keysToCheck.reduce((acc, key) => {
            if (response?.data?.[key]) {
                acc[key] = response.data[key];
            } else {
                console.error(`Missing key: ${key}`);
            }
            return acc;
        }, {});

        if (dataFetched.channel) {
            setDataShopVendure(dataFetched);
        }
    };

    useEffect(() => {
        if (refetch) {
            refetch();
        }
    }, []);

    return null;
};
