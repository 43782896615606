import { capitalizeFirst } from "../../../Utils/Utils";

const TaxInUse = (taxZone, t, data) => {
    return {
        title: capitalizeFirst(t("use")),
        value: taxZone.name,
        text: t("the-tax-zone-not-be-ablet-to-remove-it", { name: taxZone.name }),
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        // id: taxZone.id,
        inputs: [{ type: "tax-zone-in-use", data: data ? data : null }],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            { name: "save", style: "blue", action: "accept" },
        ],
    };
};

export default TaxInUse;
