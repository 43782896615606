import React, { forwardRef, Children } from "react";
import { useTranslation } from "react-i18next";
import { checkFileType } from "../../hooks/Utils/FileUtils";

// TODO checkFileType should be moved to here

export const ContentType = {
    IMAGE: "image",
    VIDEO: "video",
    FONT: "font",
};

export const DefaultContentTypeAccept = {
    [ContentType.IMAGE]: [".jpg", ".jpeg", ".png", ".gif", ".eps", ".svg", ".webp", ".avif"],
    [ContentType.VIDEO]: ["video/*"],
    [ContentType.FONT]: ["font/*", ".otf", ".ttf", ".woff", ".woff2"],
};

/**
 * @typedef {Object} HandlerOptions
 * @prop {ContentTypeOptions[]} contentTypes - Array of objects with the type of file and the max size
 * @prop {Boolean} multiple - Boolean to allow multiple files
 * @prop {Boolean} dragAndDrop - Boolean to allow drag and drop files
 */

/**
 * @typedef {Object} ContentTypeOptions
 * @prop {("image" | "video" | "font")} type - Type of file
 * @prop {number} maxSize - Max size of file in MB
 * @prop {string[]} accept - Array of accepted formats
 */

/**
 * This component renders a file input and a drag and drop container to handle files
 *
 * @component
 * @param {string} id - used for a custom identifier of the component
 * @param {JSX.Element | JSX.Element[]} children - this will be the UI characteristic inside of fileHandler
 * @param {Function} onSuccess - this is a function that will have able the files previusly handled
 * @param {Function} onError - this is a fuction that will have an errors
 * @param {HandlerOptions} options - this object is used for especify options
 * @returns {JSX.Element} - this returns the component with the file input and the drag and drop container
 * @example <FileHandler
 *     ref={ref}
 *     id="select-file"
 *     options={{
 *         multiple: true,
 *         contentTypes: ["image"],
 *     }}
 *     onSuccess={parseFiles}
 *     onError={parseError}
 * >
 *     <button onClick={ref.current.click}>Upload</button>
 * </FileHandler>
 */
const FileHandler = forwardRef(({ id, children, onSuccess, onError, options = null }, ref) => {
    const { t } = useTranslation();

    const config = {
        contentTypes: [],
        multiple: false,
        dragAndDrop: false,
        ...options,
    };

    const handleChange = async (e) => {
        let returnFiles = [];
        const files = e?.target?.files || e?.dataTransfer?.files;
        let errorHappen = null;
        if (files != null && files.length > 0) {
            Array.from(files).forEach((file) => {
                let foundValidType = false;
                if (!errorHappen) {
                    if (config?.contentTypes?.length > 0) {
                        config.contentTypes.forEach((t) => {
                            if (typeof t !== "object") {
                                t = { type: t };
                            }
                            if (!errorHappen && !foundValidType) {
                                // Check type & size
                                const accept = t.accept || DefaultContentTypeAccept[t.type];
                                foundValidType = checkFileType(file, accept) ? t.type : null;
                                if (
                                    foundValidType &&
                                    t.maxSize &&
                                    (!file.size || file.size / 1000 / 1000 > t.maxSize)
                                ) {
                                    errorHappen = `${file.name} ${t("error-exceeds-allowed-size")}`;
                                }
                            }
                            return t;
                        });
                        if (!foundValidType) {
                            errorHappen = `${file.name} ${t("error-file-not-valid-dataType")}`;
                        }
                    }
                }
                if (foundValidType && !errorHappen) {
                    returnFiles.push({
                        type: foundValidType,
                        file: file,
                    });
                }
                return file;
            });
        }
        if (!errorHappen && onSuccess) {
            onSuccess(returnFiles);
        } else if (errorHappen && onError) {
            onError(errorHappen || "Unknown error");
        }
        e.stopPropagation();
        e.preventDefault();
    };

    const dragAndDropProps = config.dragAndDrop
        ? {
              onDrop: (e) => {
                  e.stopPropagation();
                  handleChange(e);
              },
              onDragOver: (e) => {
                  e.preventDefault();
                  e.currentTarget.style.opacity = 0.5;
              },
              onDragLeave: (e) => {
                  e.preventDefault();
                  e.currentTarget.style.opacity = 1;
              },
          }
        : null;

    const accept = config?.contentTypes
        ? config.contentTypes.reduce((a, t) => {
              if (typeof t !== "object") {
                  t = { type: t };
              }
              const accept = t.accept || DefaultContentTypeAccept[t.type];
              return [...a, ...accept];
          }, [])
        : null;

    return (
        <>
            <input
                type="file"
                ref={ref}
                className={`hidden`}
                onChange={handleChange}
                id={id ? `${id}-input` : ""}
                accept={accept ? accept.join(",") : "*"}
                multiple={config.multiple}
            ></input>
            <div id={id} {...dragAndDropProps}>
                {Children.toArray(children)}
            </div>
        </>
    );
});

export default FileHandler;
