import React, { createContext, useMemo, useState } from "react";

const FilterCCsGuestUsersContext = createContext({});

export const FilterCCsGuestUsersProvider = ({ children }) => {
    const [model, setModel] = useState([]);
    const [ownership, setOwnership] = useState([]);
    const [floorFilter, setFloorFilter] = useState([]);

    const contextValue = useMemo(
        () => ({
            model,
            setModel,
            ownership,
            setOwnership,
            floorFilter,
            setFloorFilter,
        }),
        [model, ownership, floorFilter]
    );

    return <FilterCCsGuestUsersContext.Provider value={contextValue}>{children}</FilterCCsGuestUsersContext.Provider>;
};

export default FilterCCsGuestUsersContext;
