import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Session } from "../../../../hooks/Utils/Session";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";
import { SCREEN } from "../../../../hooks/Utils/Design/EditorUtils";
import WidgetElement from "../../../Widget";

const WidgetType = "TOPBAR";

const WidgetTopBar = () => {
    const { deviceType, editorSize, screenType } = useContext(EditorContext);
    const { config: cfg, id: widgetID } = useContext(WidgetContext);
    const { lang } = useParams();

    const _hotel = Session.getSessionProp("currentHotel");
    const hotel = _hotel ? JSON.parse(_hotel) : null;

    const isLanding = screenType === SCREEN.TYPE.MOBILE;

    const { gridItems } = useSelector((state) => state.design);
    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const globalData = widget?.data;

    const hotelName = hotel?.name || "Hotel";

    const deviceConfig = Widget.ParseData(globalData, deviceType);

    const config = {
        events: {
            back: () => {
                alert("BACK!");
            },
        },
    };

    const styles = cfg.style.export();

    const texts = {
        title: isLanding ? hotelName : deviceConfig?.title?.text || deviceConfig?.title?.[lang],
    };

    return (
        <WidgetElement
            id={"widget_topbar_" + widgetID}
            type={WidgetType}
            device={deviceType}
            deviceWidth={editorSize?.width}
            screenType={screenType}
            config={config}
            style={styles}
            texts={texts}
        />
    );
};

export default WidgetTopBar;
