import i18n from "i18n";

const ShowZonesAndShops = ({ title, values, fullName }) => {
    const infoArr = [];

    if (title === "show-zones") {
        infoArr.push({
            header: "zones-for-cleaning-and-capacity",
            values: values.arrCommonzonesAssigned,
            width: "w-full",
            noResults: "no-zones-assigned",
        });
    } else if (title === "show-zones-and-shops") {
        infoArr.push(
            {
                header: "zones-for-cleaning-and-capacity",
                values: values.arrCommonzonesAssigned,
                width: "w-1/2",
                noResults: "no-zones-assigned",
            },
            {
                header: "shops-for-orders",
                values: values.arrShopsAssigned,
                width: "w-1/2",
                noResults: "no-shops-assigned",
            }
        );
    } else if (title === "show-shops") {
        infoArr.push({
            header: "shops-for-orders",
            values: values.arrShopsAssigned,
            width: "w-full",
            noResults: "no-shops-assigned",
        });
    }

    console.log(values);

    return [
        {
            title: `${fullName} - ${i18n.t(title)}`,
            bodyAdjust: infoArr.length === 2 ? "w-11/12" : "w-1/12",
            executeSectionQuery: true,
            inputs: [
                {
                    type: "modal-informative-list",
                    data: {
                        info: infoArr,
                    },
                },
            ],
            buttons: [{ name: "close", style: "blue", action: "close" }],
        },
    ];
};

export default ShowZonesAndShops;
