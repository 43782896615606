import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/Global/ModalContent";
//Actions
import { changeActionValues } from "../../actions/globalActions";
import { multipleTranslate } from "../../hooks/Utils/Utils";

const UseModalPasword = ({ index }) => {
    //Store data
    const { name, text, focus, value, cStyle, ph, securePassword } = useSelector(
        (state) => state.ui.modalContent.inputs[index]
    );
    const { validations } = useSelector((state) => state.action);

    const { langStrings } = useSelector((state) => state.ui);

    //state
    const [val, setVal] = useState(value);
    const [error, setError] = useState(["6-chars", "upper-lower", "numbers"]);

    //Listeners
    useEffect(() => {
        if (value) {
            dispatch(changeActionValues({ [name]: value }));
        }
        // eslint-disable-next-line
    }, []);

    //Actions
    const dispatch = useDispatch();

    const handleChange = (e) => {
        let dataValue = null;
        if (securePassword) {
            let errors = [];
            if (e.target.value.length < 6) {
                errors.push("6-chars");
            }

            if (!/[a-z]/.test(e.target.value) || !/[A-Z]/.test(e.target.value)) {
                errors.push("upper-lower");
            }
            if (!/\d/.test(e.target.value)) {
                errors.push("numbers");
            }
            setError(errors);
            if (errors.length === 0) {
                dataValue = e.target.value;
            }
        } else {
            dataValue = e.target.value;
        }

        dispatch(changeActionValues({ [name]: dataValue }));
        setVal(e.target.value);
    };

    const changeVisibility = (e, inputId) => {
        const inputItem = document.querySelector(`#${inputId}`);
        inputItem.type = inputItem.type === "text" ? "password" : "text";
        if (e.target.classList.contains("icon-eye-blocked")) {
            e.target.classList.remove("icon-eye-blocked");
            e.target.classList.add("icon-eye");
        } else {
            e.target.classList.add("icon-eye-blocked");
            e.target.classList.remove("icon-eye");
        }
    };

    return (
        <div className={`${cStyle}`}>
            {text ? <span className="block pb-2 first-capital">{multipleTranslate(text, langStrings)}</span> : null}
            <div className="relative">
                <input
                    id={focus ? "modal_focus" : name}
                    type="password"
                    autoComplete="new-password"
                    min="0"
                    value={val}
                    required
                    placeholder={ph ? (langStrings[ph] ? langStrings[ph] : translate(ph)) : ""}
                    className={`w-full h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400  ${
                        typeof validations[name] !== "undefined" && !validations[name] ? "border border-red-100" : ""
                    }`}
                    onChange={(e) => handleChange(e)}
                ></input>

                <div
                    className={`field-icon icon-eye-blocked text-2xl absolute cursor-pointer`}
                    onClick={(e) => changeVisibility(e, focus ? "modal_focus" : name)}
                ></div>
            </div>

            {securePassword ? (
                <div className={"text-right text-alertRed text-xxxs whitespace-pre-wrap pt-2"}>
                    <div
                        className={`${error.includes("6-chars") && !validations[name] ? "text-red-100" : ""} ${
                            !error.includes("6-chars") ? "line-through" : ""
                        }`}
                    >
                        {langStrings["password-six-chars"]}
                    </div>
                    <div
                        className={`${error.includes("upper-lower") && !validations[name] ? "text-red-100" : ""} ${
                            !error.includes("upper-lower") ? "line-through" : ""
                        }`}
                    >
                        {langStrings["password-upper-and-lower"]}
                    </div>
                    <div
                        className={`${error.includes("numbers") && !validations[name] ? "text-red-100" : ""} ${
                            !error.includes("numbers") ? "line-through" : ""
                        }`}
                    >
                        {langStrings["password-numbers"]}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default UseModalPasword;
