//REACT
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Utils
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//ACTIONS
import { setSectionContent } from "../../../../actions/sectionActions";
import { setRefreshData } from "../../../../actions/tableActions";
import { QUERY_ALARMS_PART } from "../../../Utils/Customers/AlarmUtils";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { POLLINTERVAL_15S, POLLINTERVAL_30S } from "../../../Utils/Utils";

const RoomDetailGuestInfo = ({ roomId }) => {
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);
    const [guestData, setGuestData] = useState(null);

    const hasGuestSatisfaction = permissions && permissions.guests.guestSatisfaction;

    const GET_ROOM_INFO = gql`{
        guest: stays(roomID:${roomId}){
    		info{count}
    		results{
                guestID guestLang guestCountry guestName guestSurname guestTitle guestLoyaltyRef guestGroup{name} companions{name surname rooms{name}} rooms{RoomID number name stayGuestRoomID parentalcode} ${QUERY_ALARMS_PART}
            }
        }
            ${
                hasGuestSatisfaction
                    ? `stayFeedbacks(filter: {roomID: ${roomId}}) {
            results {
                id
                originRoom {
                    id
                    name
                }
                stayGuestRoom {
                    id
                    checkedout
                    room {
                        id
                        name
                    }
                    stayGuest {
                        id
                        name
                        surame
                    }
                }
                satisfactionLevel
                createdAt
            }
        }`
                    : ""
            }

    }`;

    const GET_MOVIES_INFO = gql`
        query MovieCharges ($guestId: ID){
            charges( filter: {type: MOVIE ,clientId: $guestId , roomId: ${roomId} }, orderBy: {field: "createdAt", criteria: "DESC"}) {
                            info {
                                count
                                page
                                size
                            }
                            results {
                                id
                                extId
                                stayGuestRoomId
                                createdAt
                                detailInfo
                                canceledAt
                                totalPriceAfterTaxes
                                pmsStatus
                                canceledReason
                                roomId
                                roomNumber
                                guestCheckedOut
                            }
                        }
            }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_ROOM_INFO, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    const [executeMoviesQuery, { data: moviesData, refetch: refetchMovies }] = useLazyQuery(GET_MOVIES_INFO, {
        pollInterval: POLLINTERVAL_30S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!loading && !error && data && data.guest && data.guest.results) {
            dispatch(showGlobalLoading(false));
            if (data.guest.info.count > 0) {
                setGuestData(data.guest.results[0]);
                dispatch(setSectionContent({ guest: data.guest.results[0] }));
                executeMoviesQuery({
                    variables: {
                        guestId: data.guest.results[0]?.guestID,
                    },
                });
            } else {
                setGuestData(null);
                dispatch(setSectionContent({ guest: null }));
            }
        } else if (!loading && !error && data && !data.guest) {
            setGuestData(null);
            dispatch(setSectionContent({ guest: null }));
        }

        if (!loading && data && data?.stayFeedbacks?.results) {
            dispatch(setSectionContent({ stayFeedbacks: data?.stayFeedbacks?.results }));
        }

        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setSectionContent({ movies: moviesData?.charges?.results }));
    }, [moviesData]);

    useEffect(() => {
        if (refreshData) {
            if (refetch) {
                refetch();
            }
            if (refetchMovies) {
                refetchMovies();
            }
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (loading) setGuestData("loading");
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    return guestData;
};

export default RoomDetailGuestInfo;
