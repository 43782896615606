import React, { useRef, useContext, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { SortableElement, SortableContainer, SortableHandle } from "react-sortable-hoc";
import { useSelector } from "react-redux";

import { ShopContext } from "contexts/Sales/Shop";

import Loading from "components/Loading";
import UseSwitch from "components/Inputs/useSwitch";
import Button from "components/Button";
import Icon from "components/Icon";
import Translations from "components/Translations";
import { EditPrice, FooterButtons, TextInput } from "./Delivery.jsx";

import { parseVendureTranslation } from "hooks/Utils/SalesUtils";
import { DELIVERY_METHOD_PICKUP, DELIVERY_METHODS } from "constants/sales";

const EditPickup = forwardRef(({ defaultLang, onClose }, ref) => {
    const locationsContainerRef = useRef();

    const { t } = useTranslation();
    const {
        shippingConfig,
        pickupLocations,
        createPickupLocations,
        updatePickupLocations,
        deletePickupLocations,
        defaultTax,
        setDeliveryMethodAvailable,
    } = useContext(ShopContext);

    const pickupEnabled = shippingConfig?.[DELIVERY_METHOD_PICKUP]?.enabled;

    const [saving, setSaving] = useState(false);

    const [locationsOrder, setLocationsOrder] = useState(null);
    const [locationsToUpdate, setLocationsToUpdate] = useState(null);
    const [locationsToDelete, setLocationsToDelete] = useState(null);
    const [newLocations, setNewLocations] = useState([]);
    const [visibleLocations, setVisibleLocations] = useState([]);

    const enabledDeliveryMethodsCount = DELIVERY_METHODS.filter((key) => shippingConfig?.[key]?.enabled).length;
    const isPickupEnabled = shippingConfig?.[DELIVERY_METHOD_PICKUP]?.enabled;
    const canDisablePickup = enabledDeliveryMethodsCount > (isPickupEnabled ? 1 : 0);

    const hasAvailableLocations = visibleLocations?.filter((location) => location.available)?.length > 0;

    useImperativeHandle(ref, () => ({
        addLocation: () => {
            const newID = `new-${newLocations?.length || 0}`;
            setNewLocations([
                ...(newLocations || []),
                {
                    id: newID,
                    available: false,
                    order: visibleLocations?.length || 0,
                    taxRate: {
                        id: defaultTax?.id,
                        name: defaultTax?.name,
                        value: defaultTax?.value,
                    },
                    translations: [
                        {
                            languageCode: defaultLang,
                            name: "",
                        },
                    ],
                },
            ]);
            setLocationsOrder([...(locationsOrder || []), newID]);
            // scroll to bottom of container to show new Location and focus on name input
            setTimeout(() => {
                if (locationsContainerRef?.current) {
                    locationsContainerRef.current.scrollTop = locationsContainerRef.current.scrollHeight;
                }
                const inputName = document.getElementById(`input-location-${newID}-name-default`);
                if (inputName) {
                    inputName.focus();
                }
            }, 100);
        },
    }));

    useEffect(() => {
        resetChanges();
    }, [pickupLocations]);

    const parseVisible = (toNew, toUpdate, locationsToDelete) => {
        return [
            ...(toUpdate ? toUpdate.filter((l) => !locationsToDelete?.includes(l?.id)) : []),
            ...(toNew ? toNew.filter((l) => !locationsToDelete?.includes(l?.id)) : []),
        ].sort((a, b) => a.order - b.order);
    };

    useEffect(() => {
        if (locationsOrder) {
            if (locationsToUpdate) {
                const toUpdate = locationsToUpdate
                    ? locationsToUpdate.map((location) => ({
                          ...location,
                          order: locationsOrder?.indexOf(location.id),
                      }))
                    : locationsToUpdate;
                setLocationsToUpdate(toUpdate);
            }
            if (newLocations) {
                const toNew = newLocations
                    ? newLocations.map((location) => ({
                          ...location,
                          order: locationsOrder?.indexOf(location.id),
                      }))
                    : newLocations;
                setNewLocations(toNew);
            }
        }
    }, [locationsOrder]);

    useEffect(() => {
        setVisibleLocations(parseVisible(newLocations, locationsToUpdate, locationsToDelete));
    }, [newLocations, locationsToUpdate, locationsToDelete]);

    const resetChanges = () => {
        const storedLocations = pickupLocations ? pickupLocations.map((location) => ({ ...location })) : null;
        const storedOrder = storedLocations
            ? storedLocations.sort((a, b) => a.order - b.order).map((location) => location.id)
            : null;
        setLocationsToUpdate(storedLocations);
        setLocationsOrder(storedOrder);
        setLocationsToDelete([]);
        setNewLocations([]);
    };

    const cancelChanges = () => {
        resetChanges();
        if (onClose) {
            onClose(false);
        }
    };

    const saveLocationsDeleted = () => {
        return new Promise((resolve, reject) => {
            const deleteLocations = locationsToDelete?.filter((id) => !id.startsWith("new-"));
            if (deleteLocations?.length > 0) {
                deletePickupLocations(deleteLocations)
                    .then(() => {
                        toast.success(t("operation-successful"));
                    })
                    .catch((error) => {
                        reject(error instanceof Error ? error : new Error(error));
                    })
                    .finally(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    const saveLocationsChanges = () => {
        return new Promise((resolve) => {
            if (locationsToUpdate?.length > 0) {
                updatePickupLocations(locationsToUpdate.filter((l) => !locationsToDelete?.includes(l.id)))
                    .then(() => {
                        toast.success(t("operation-successful"));
                    })
                    .finally(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    const addNewLocations = () => {
        return new Promise((resolve) => {
            const toAdd = newLocations?.filter((l) => !locationsToDelete?.includes(l.id));
            if (toAdd?.length > 0) {
                createPickupLocations(toAdd)
                    .then(() => {
                        toast.success(t("operation-successful"));
                    })
                    .finally(() => {
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    };

    const checkAvailability = () => {
        return new Promise((resolve) => {
            if (pickupEnabled && !hasAvailableLocations) {
                // if no location is available, disable the delivery method
                setDeliveryMethodAvailable(DELIVERY_METHOD_PICKUP, false).finally(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    };

    const validateLocations = () => {
        if (visibleLocations?.length) {
            return visibleLocations.every((location) => {
                return !!location?.translations?.filter((translation) => translation.languageCode === defaultLang)?.[0]
                    ?.name;
            });
        }
        return true;
    };

    const saveChanges = () => {
        setSaving(true);
        if (!canDisablePickup && !hasAvailableLocations) {
            setSaving(false);
            toast.error(t("At least one available pickup location is required"));
            return;
        }

        if (!validateLocations()) {
            setSaving(false);
            toast.error(t("check form"));
            return;
        }

        saveLocationsChanges().finally(() => {
            saveLocationsDeleted().finally(() => {
                addNewLocations().finally(() => {
                    checkAvailability().finally(() => {
                        setSaving(false);
                        resetChanges();
                        if (onClose) {
                            onClose(true);
                        }
                    });
                });
            });
        });
    };

    return (
        <>
            <div
                ref={locationsContainerRef}
                className="border-t-2 border-b-2 pl-3 overflow-y-auto overflow-x-hidden space-y-5"
                style={{ height: "30em" }}
            >
                {saving ? (
                    <Loading className="my-40" />
                ) : (
                    <>
                        <PickupLocations
                            locations={visibleLocations}
                            lang={defaultLang}
                            useDragHandle={true}
                            onSortEnd={(data) => {
                                const oldIndex = data.oldIndex;
                                const newIndex = data.newIndex;
                                const newOrder = locationsOrder
                                    ? [...locationsOrder]
                                    : [...visibleLocations.map((location) => location.id)];
                                newOrder.splice(newIndex, 0, newOrder.splice(oldIndex, 1)[0]);
                                setLocationsOrder(newOrder);
                            }}
                            onDelete={(ids) => {
                                setLocationsToDelete(ids || []);
                            }}
                            onChangeAvailabilities={(id, value) => {
                                const data = { available: value };
                                setLocationsToUpdate(updateLocationValue(locationsToUpdate, id, data));
                                setNewLocations(updateLocationValue(newLocations, id, data));
                            }}
                            onChangePrices={(id, value) => {
                                const data = {
                                    price: {
                                        withoutTax: value?.price,
                                    },
                                    taxRate: value?.taxRate,
                                };
                                setLocationsToUpdate(updateLocationValue(locationsToUpdate, id, data));
                                setNewLocations(updateLocationValue(newLocations, id, data));
                            }}
                            onChangeTranslations={(id, value) => {
                                const data = { translations: value };
                                setLocationsToUpdate(updateLocationValue(locationsToUpdate, id, data));
                                setNewLocations(updateLocationValue(newLocations, id, data));
                            }}
                            axis="y"
                        />
                    </>
                )}
            </div>
            <FooterButtons
                id={`button-${DELIVERY_METHOD_PICKUP}`}
                disabled={saving}
                onCancel={() => {
                    cancelChanges();
                }}
                onSave={() => {
                    saveChanges();
                }}
            />
        </>
    );
});

const PickupLocations = SortableContainer(
    ({ locations, lang, onDelete, onChangeAvailabilities, onChangePrices, onChangeTranslations }) => {
        const { t } = useTranslation();
        const [toDelete, setToDelete] = useState([]);

        useEffect(() => {
            if (toDelete !== null) {
                onDelete(toDelete);
            }
        }, [toDelete]);

        if (!locations?.length) {
            return <div className="text-center p-6 text-gray-800">{t("No location yet")}</div>;
        }

        return (
            <div>
                {locations.map((location, index) => {
                    if (!location) {
                        throw new Error("<PickupLocations> Location is missing");
                    }
                    return (
                        <PickupLocation
                            {...location}
                            className="bg-white border-b-2"
                            key={location.id}
                            index={index}
                            lang={lang}
                            onChangeAvailable={(value) => {
                                onChangeAvailabilities(location.id, value);
                            }}
                            onChangePrice={({ price, taxRate }) => {
                                onChangePrices(location.id, { price, taxRate });
                            }}
                            onChangeTranslations={(values) => {
                                onChangeTranslations(location.id, values);
                            }}
                            onDelete={() => {
                                setToDelete([...toDelete, location.id]);
                            }}
                        />
                    );
                })}
            </div>
        );
    }
);

/**
 * PickupLocation is a component used to display and edit a pickup location
 * @param {string} id - The id of the location
 * @param {object} translations - The translations of the location
 * @param {boolean} available - The availability of the location
 * @param {object} price - The price of the location
 * @param {object} taxRate - The tax rate of the location
 * @param {string} lang - The default language of the project
 * @param {function} onDelete - The function to call when the location is deleted
 * @param {function} onChangeAvailable - The function to call when the availability of the location is changed
 * @param {function} onChangeTranslations - The function to call when the translations of the location are changed
 * @param {function} onChangePrice - The function to call when the price of the location is changed
 * @param {string} className - Extra classes to apply to the container
 * @returns {JSX.Element} The JSX element
 */
const PickupLocation = SortableElement(
    ({
        id,
        translations,
        available,
        price,
        taxRate,
        lang: defaultLang,
        onDelete,
        onChangeAvailable,
        onChangeTranslations,
        onChangePrice,
        className,
    }) => {
        const { t } = useTranslation();

        const translationsRef = useRef();

        const projectLangs = useSelector((state) => state?.ui?.projectLangs);
        const otherLanguages = projectLangs?.filter((lang) => lang.isDefault === false).map((lang) => lang.languageRef);

        // get best translation by language code
        const getName = (lang) => parseVendureTranslation(translations, lang, { defaultLang });
        // get stored translation by language code
        const getNameTranslation = (lang) => parseVendureTranslation(translations, lang, { exact: true, defaultLang });

        const containerID = `location-${id}`;
        const buttonID = `button-location-${id}`;
        const inputID = `input-location-${id}`;

        const deleteButtonID = `${buttonID}-delete`;
        const inputNameID = `${inputID}-name`;
        const inputPriceID = `${inputID}-price`;

        return (
            <div id={containerID} className={className || ""}>
                <div className="flex items-start py-5">
                    <div className="flex-none items-center">
                        <DragHandle />
                    </div>
                    <div className="flex-grow">
                        <div className="flex flex-start w-full pb-5">
                            <div className="flex-1 px-5">
                                <div className="flex items-start space-x-5">
                                    <div className="min-w-24 pt-1 whitespace-no-wrap">{t("location")} *</div>
                                    <TextInput
                                        id={`${inputNameID}-default`}
                                        className="w-full"
                                        value={getNameTranslation(defaultLang)}
                                        placeholder={getName(defaultLang)}
                                        required={true}
                                        maxLength={50}
                                        displayCount={true}
                                        onChange={(name) => {
                                            if (translationsRef.current) {
                                                translationsRef.current.changeTranslation(name, defaultLang);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex-1 px-5 ">
                                <div className="flex items-center justify-end space-x-5">
                                    <UseSwitch
                                        adjust="flex items-center"
                                        checked={available}
                                        label={t("available")}
                                        action={(checked) => {
                                            onChangeAvailable(checked);
                                        }}
                                    />
                                    <Button
                                        id={deleteButtonID}
                                        design="link"
                                        className="hover:text-red-500"
                                        onClick={onDelete}
                                    >
                                        <Icon type="delete" size="xl" />
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="flex w-full flex-start">
                            {otherLanguages?.length > 0 ? (
                                <Translations
                                    id={inputNameID}
                                    ref={translationsRef}
                                    className="flex-1 px-5 border-r-2"
                                    languages={otherLanguages}
                                    translations={translations}
                                    defaultLang={defaultLang}
                                    includeDefault={false}
                                    maxLength={50}
                                    onChange={onChangeTranslations}
                                    maxHeight="10rem"
                                />
                            ) : null}
                            <div className="flex-1 px-5">
                                <EditPrice
                                    id={inputPriceID}
                                    price={price?.withoutTax}
                                    taxRate={taxRate?.id}
                                    onChangePrice={onChangePrice}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

/**
 *  DragHandle is used to allow the user to drag and drop the location
 */
const DragHandle = SortableHandle(() => (
    <span className="icon icon-drag-and-drop text-gray-800 text-3xl cursor-move"></span>
));

/**
 * This function is used to update the location data in the state when the user changes the value of a field
 * @param {array} locations - The list of locations to update
 * @param {string} id - The id of the location to update
 * @param {object} newData - The new data to apply to the location
 * @returns {array} The updated list of locations
 */
const updateLocationValue = (locations, id, newData) => {
    if (!id) {
        return locations;
    }
    if (locations) {
        return locations.map((location) => {
            if (location?.id === id) {
                return {
                    ...location,
                    ...newData,
                };
            }
            return location;
        });
    }
    return null;
};

export default EditPickup;
