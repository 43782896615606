import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";

//Components
import UseProjectSearch from "./useProjectSearch";
import UseProjectPagination from "./useProjectPagination";
import UseProjectDisplayer from "./useProjectDisplayer";
import { Session } from "../../../hooks/Utils/Session";
import UserDropdown from "../../Header/UserDropdown";
//Actions
import { setCurrentProjectsPage, setHotelSearch } from "../../../actions/authActions";
import { setProjectRef } from "../../../actions/hotelActions";
//Hooks
import { LoginProject } from "../../../hooks/Utils/SelectProjectUtils";
import { normalizeToSearch, parseBoolean } from "../../../hooks/Utils/Utils";

const SelectProject = () => {
    const { t } = useTranslation();
    const history = useHistory();

    //Store data

    const { hotelSearch, currentPage, impersonated } = useSelector((state) => state.auth);
    const perPage = 12;

    //Data
    const projects = JSON.parse(Session.getSessionProp("projects"));
    const [projectsToDisplay, setProjectsToDisplay] = useState(projects);
    const desiredProjects = getDesiredProjects(projects, hotelSearch);

    if (!projectsToDisplay) {
        Session.close();
        history("/login");
    }

    const gridCols = getGridCols(projectsToDisplay);

    //Actions
    const dispatch = useDispatch();

    //States
    const [displayPage, setDisplayPage] = useState(1);
    // const [showContent, setShowContent] = useState(false);

    //API
    LoginProject();

    //Actions
    const clicHandler = (e) => {
        // setSelectedProject(e.currentTarget.dataset.ref);
        dispatch(setProjectRef(e.currentTarget.dataset.ref, parseBoolean(e.currentTarget.dataset.global)));
        const currentHotel = {
            id: e.currentTarget.dataset.id,
            name: e.currentTarget.dataset.name,
            location: e.currentTarget.dataset.location,
            ref: e.currentTarget.dataset.ref,
            keyBrand: e.currentTarget.dataset.keybrand ? e.currentTarget.dataset.keybrand : null,
            timeZone: e.currentTarget.dataset.timezone ? e.currentTarget.dataset.timezone : null,
            oldmanagerurl: e.currentTarget.dataset.oldmanagerurl,
            oldmanagerusers: parseBoolean(e.currentTarget.dataset.oldmanagerusers),
            zMobileURL: e.currentTarget.dataset.zmobileurl,
            code: e.currentTarget.dataset.code,
        };
        Session.setProject(currentHotel);
    };

    //Listeners
    useEffect(() => {
        return () => {
            dispatch(setHotelSearch(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setCurrentProjectsPage(1));
        setProjectsToDisplay(getProjectToDisplay(desiredProjects, perPage, displayPage));
        // eslint-disable-next-line
    }, [hotelSearch]);

    useEffect(() => {
        if (currentPage) {
            setDisplayPage(currentPage);
            setProjectsToDisplay(getProjectToDisplay(desiredProjects, perPage, currentPage));
        }
        // eslint-disable-next-line
    }, [currentPage]);

    if (!projectsToDisplay && !Session.getSessionProp("token")) {
        history("/login");
        return <></>;
    }
    return (
        <>
            <div className={` ${impersonated ? "absolute" : ""}`}>
                {Session.getSessionProp("currentAccount") && Session.getSessionProp("superUser") ? (
                    <div
                        onClick={(e) => history("/select-profile")}
                        className="flex absolute top-6 mt-2 z-500 clickable"
                        style={{ left: "2rem" }}
                    >
                        <div className="icon-chevron-left float-left mt-2 mr-2 cursor-pointer text-gray-600 text-xl" />
                        <div className="first-capital text-xl font-bold mt-0.5">{t("back")}</div>
                    </div>
                ) : null}
                <div className="z-500 absolute right-0 mr-8 top" style={{ top: "2rem" }}>
                    <UserDropdown />
                </div>
                <div className="absolute top-0 left-0 w-screen h-screen flex justify-center bg-white z-201 overflow-y-scroll">
                    <div className={`p-8 rounded bg-white w-full block`}>
                        <span className="mx-auto">
                            <div
                                className="mx-auto mb-4"
                                style={{
                                    background: `url('${Session.getDasUrl(Session.getSessionProp("chainLogoRef"))}')`,
                                    width: "235px",
                                    height: "72px",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                }}
                                alt="Logo"
                            />
                        </span>
                        <div className="text-gray-900 mt-8 mb-4 text-2xl text-center font-bold fist-capital">
                            <h2 className="first-capital">{t("select-hotel")}</h2>
                        </div>
                        {projects && projects.length > 12 ? <UseProjectSearch /> : null}
                        <div className={`grid gap-4 grid-cols-${gridCols}`}>
                            <UseProjectDisplayer projectsToDisplay={projectsToDisplay} clicHandler={clicHandler} />
                        </div>
                        <UseProjectPagination
                            desiredProjects={desiredProjects}
                            perPage={perPage}
                            displayPage={displayPage}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const getGridCols = (projectsToDisplay) => {
    //Returns style for column layout
    let res;
    if (projectsToDisplay && projectsToDisplay.length > 3) {
        res = "3 w-full";
    } else {
        res = "1 w-2/6 mx-auto";
    }
    return res;
};

export const getDesiredProjects = (projects, hotelSearch) => {
    //Returns array of projects depending of search
    let res = [];
    //Get all searched or all results
    if (hotelSearch) {
        // eslint-disable-next-line
        projects.map((project) => {
            const { location, name } = project;
            const fullData = `${location}${name}`;
            if (normalizeToSearch(fullData).includes(normalizeToSearch(hotelSearch))) {
                res.push(project);
            }
        });
    } else {
        res = projects;
    }
    return res;
};

export const getProjectToDisplay = (desiredProjects, perPage, displayPage) => {
    if (!desiredProjects) {
        return null;
    }
    //Returns array of desired projects depending of pagination
    let res = [];
    // eslint-disable-next-line
    desiredProjects.map((desiredProject, index) => {
        if (isCurrentPageProject(perPage, displayPage, index)) {
            res.push(desiredProject);
        }
    });
    return res;
};

export const isCurrentPageProject = (perPage, displayPage, index) => {
    //Returns true if project is on current page
    let res;
    if (index + 1 <= displayPage * perPage && index + 1 > (displayPage - 1) * perPage) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default SelectProject;
