import { SET_HOTEL_SEARCH, SET_CURRENT_PAGE, SET_TOKEN, SET_IMPERSONATED } from "../actions/authActions";
import { Session } from "../hooks/Utils/Session";

//Added to ensure session restore before set initial state
Session.init();

const initialState = {
    token: Session.getSessionProp("token") || null,
    impersonated: sessionStorage.getItem("impersonated") ? JSON.parse(sessionStorage.getItem("impersonated")) : null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_HOTEL_SEARCH:
            return {
                ...state,
                hotelSearch: action.payload,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case SET_TOKEN:
            return {
                ...state,
                token: action.payload,
            };
        case SET_IMPERSONATED:
            return {
                ...state,
                impersonated: action.payload,
            };
        default:
            return state;
    }
}
