import React, { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../../../contexts/editor";
import { vw2px } from "../../../../../hooks/Utils/DesignUtils";
import Color from "zrender/color";
import TopBar from "./topbar";
import { coverElement, coverColor, highlightElement } from "./utils";
import { parseColorsToStyles } from "../../../../../hooks/Utils/Design/SalesUtils";
import { SCREEN } from "../../../../../hooks/Utils/Design/EditorUtils";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";

const ShopProducts = ({ device: forceDeviceType, width: forceWidth, templating }) => {
    const { t: tr } = useTranslation();
    const { lang, theme, deviceType, editorSize } = useContext(EditorContext);

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const size = (s) => vw2px(s, deviceProps.deviceWidth, 3) + "px";

    const config = templating || {
        css: parseColorsToStyles(SCREEN.TYPE.SALESMOBILE, theme, null, null),
        titleFont: { name: "Lato" },
        textFont: { name: "Lato" },
    };
    const css = config?.css;

    const highlightActive = css?.highlight;
    const hlBackground = highlightActive === "general";
    const hlItems = highlightActive === "items";
    const hlDetails = highlightActive === "details";
    const hlFooter = highlightActive === "section2";

    const cssActive = css?.status?.active;
    const tab1 = css?.items?.tabs1;
    const tab1Active = cssActive?.items?.tabs1;
    const tab2 = css?.items?.tabs2;
    const tab2Active = cssActive?.items?.tabs2;
    const details = css?.items?.details;
    const footer = css?.items?.general;
    const footerButton = css?.items?.button;

    const gradientColor = highlightActive && !hlItems ? coverColor(tab2Active?.bgColor) : tab2Active?.bgColor;
    const gradientStart = Color(gradientColor).alpha(0.3).string();
    const gradientEnd = Color(gradientColor).alpha(0.6).string();

    const styles = {
        global: {
            fontFamily: config?.textFont?.name,
            color: css?.fgColor,
            backgroundColor: highlightActive && !hlBackground ? coverColor(css?.bgColor) : css?.bgColor,
            textAlign: "left",
            width: "100%",
            minHeight: "100%",
            cursor: "default",
        },

        footer: {
            backgroundColor: footer?.bgColor,
            color: footer?.fgColor,
            padding: size(1.5),
            textAlign: "center",
        },
        orderButton: {
            display: "inline-block",
            fontSize: size(1.5),
            fontWeight: "bold",
            padding: size(0.75),
            minWidth: "80%",
            color: footerButton?.fgColor,
            background: footerButton?.bgColor,
            borderWidth: footerButton?.borderWidth + "px",
            borderRadius: footerButton?.radius + "rem",
            borderColor: footerButton?.borderColor,
        },

        categories: {
            borderBottomWidth: "1px",
            borderColor: Color(tab1?.fgColor).alpha(0.2).string(),
            boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
            fontSize: size(1.4),
            color: tab1?.fgColor,
            backgroundColor: tab1?.bgColor,
            padding: size(0.75),
            paddingRight: 0,
            paddingBottom: 0,
        },
        category: (selected) => {
            return {
                padding: size(0.75),
                paddingBottom: size(0.25),
                color: selected ? tab1Active?.fgColor : "inherit",
                borderColor: selected ? tab1Active?.fgColor : "transparent",
            };
        },

        subcategories: {
            fontSize: size(1.4),
            paddingTop: size(1),
            paddingLeft: size(1),
            paddingRight: 0,
            paddingBottom: size(0.25),
            backgroundColor: tab2?.bgColor,
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
        subcategory: (selected) => {
            return {
                display: "inline-block",
                paddingLeft: size(1),
                paddingRight: size(1),
                paddingTop: size(0.5),
                paddingBottom: size(0.5),
                margin: size(0.5),
                color: selected ? tab2Active?.fgColor : tab2?.fgColor,
                backgroundColor: selected ? tab2Active?.bgColor : tab2?.bgColor,
                borderColor: selected ? tab2Active?.borderColor : tab2?.borderColor,
                borderWidth: (selected ? tab2Active?.borderWidth : tab2?.borderWidth) + "px",
                borderRadius: tab2?.radius + "rem",
            };
        },

        titleCategory: {
            padding: size(1),
            fontSize: size(1.8),
            textAlign: "center",
            color: tab2Active?.fgColor,
            backgroundColor: tab2Active?.bgColor,
            background: `linear-gradient(180deg, ${gradientStart} 0%, ${gradientEnd} 100%)`,
        },
        titleSubcategory: {
            padding: size(1.5),
            paddingBottom: size(0.75),
            fontSize: size(1.8),
        },

        product: {
            fontSize: size(1.4),
            padding: size(1.5),
            color: "#323232",
            backgroundColor: highlightActive && hlDetails ? coverColor("#FBFBFB") : "#FBFBFB",
        },
        icon: {
            width: size(5),
            height: size(5),
            color: "#FFFFFF",
            background: "#91A3B5",
            lineHeight: size(5),
            fontSize: size(1.6),
        },
        description: {
            fontSize: size(1.25),
            color: "rgb(118,136,155)",
        },
        label: {
            fontSize: size(1),
            paddingLeft: size(1),
            paddingRight: size(1),
            paddingTop: size(0.5),
            paddingBottom: size(0.5),
            margin: size(0.5),
            marginLeft: 0,
            color: details?.fgColor,
            backgroundColor: details?.bgColor,
            display: "inline-block",
            borderRadius: "0.5rem",
        },
    };

    const label = (name) => (
        <div
            style={{
                ...styles.label,
                ...(highlightActive && hlDetails ? highlightElement() : null),
            }}
        >
            {name}
        </div>
    );

    const product = () => (
        <div
            target="details"
            style={{
                marginBottom: size(0.25),
                ...(highlightActive && hlBackground ? highlightElement("vertical") : null),
            }}
        >
            <div
                style={{
                    ...styles.product,
                    ...(highlightActive && !hlDetails ? coverElement() : null),
                }}
            >
                {capitalizeFirst(t("product"))}
                <div
                    className="flex justify-between"
                    style={{ paddingTop: size(0.5), ...(highlightActive && hlDetails ? coverElement() : null) }}
                >
                    <div className="flex">
                        <div className="rounded text-center icon icon-images" style={styles.icon}></div>
                        <div
                            className="flex justify-between flex-col"
                            style={{
                                padding: size(1),
                                paddingTop: 0,
                            }}
                        >
                            <div style={styles.description}>{capitalizeFirst(t("product description"))}</div>
                            <div className="font-bold">00,00 €</div>
                        </div>
                    </div>
                    <div
                        style={{
                            fontSize: size(2.2),
                            padding: size(1),
                            paddingLeft: size(0.5),
                            paddingRight: size(0.5),
                            textAlign: "center",
                            borderWidth: "1px",
                            borderRadius: "0.5rem",
                            borderColor: "#D3DAE1",
                        }}
                    >
                        +
                    </div>
                </div>
                {label(capitalizeFirst(t("label extra large")))}
                {label(capitalizeFirst(t("label")))}
            </div>
        </div>
    );

    useEffect(() => {
        if (highlightActive) {
            const element = document.querySelector(`[target=${highlightActive}]`);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [highlightActive]);

    return (
        <div style={styles.global}>
            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                }}
            >
                <TopBar
                    title={t("shop-name")}
                    config={{ search: true }}
                    device={deviceType}
                    width={forceWidth}
                    templating={config}
                />
            </div>

            <div
                target="general"
                style={{
                    ...styles.categories,
                    ...(highlightActive && !hlItems ? coverElement() : null),
                    ...(highlightActive && hlItems ? highlightElement("nobottom") : null),
                }}
            >
                <ul target="items" className="overflow-hidden whitespace-nowrap">
                    {[1, 2, 3, 4, 5].map((n) => (
                        <li
                            key={"cat_" + n}
                            className={"inline-block " + (n === 1 ? "border-b-2" : "")}
                            style={styles.category(n === 1)}
                        >
                            {capitalizeFirst(t("category")) + " " + n}
                        </li>
                    ))}
                </ul>
            </div>
            <div
                style={{
                    ...(highlightActive && hlBackground ? highlightElement("bottom") : null),
                }}
            >
                <ul
                    style={{
                        ...styles.subcategories,
                        ...(highlightActive && !hlItems ? coverElement() : null),
                        ...(highlightActive && hlItems ? highlightElement("notop") : null),
                    }}
                >
                    {[1, 2].map((n) => (
                        <li key={"subcat_" + n} style={styles.subcategory(n === 1)}>
                            {capitalizeFirst(t("subcategory")) + " " + n}
                        </li>
                    ))}
                </ul>
            </div>

            {[1, 2].map((n) => (
                <>
                    <div
                        style={{
                            marginTop: size(1.5),
                            ...(highlightActive && hlBackground ? highlightElement("top") : null),
                        }}
                    >
                        <div
                            style={{
                                ...styles.titleCategory,
                                ...(highlightActive && !hlItems ? coverElement() : null),
                                ...(highlightActive && hlItems ? highlightElement() : null),
                            }}
                        >
                            {capitalizeFirst(t("subcategory")) + " " + n}
                        </div>
                    </div>

                    <div
                        style={{
                            ...(highlightActive && hlBackground ? highlightElement("top") : null),
                        }}
                    >
                        <div
                            style={{
                                ...styles.titleSubcategory,
                                ...(highlightActive && !hlBackground ? coverElement() : null),
                            }}
                        >
                            {capitalizeFirst(t("subcategory")) + " " + n}
                        </div>
                    </div>
                    {new Array(2).fill(product())}
                </>
            ))}

            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    ...(highlightActive && (hlBackground || hlFooter)
                        ? highlightElement(hlBackground ? "top" : null)
                        : null),
                }}
            >
                <div style={{ ...styles.footer, ...(highlightActive && !hlFooter ? coverElement() : null) }}>
                    <div style={styles.orderButton}>{capitalizeFirst(t("order now"))}</div>
                </div>
            </div>
        </div>
    );
};

export default ShopProducts;
