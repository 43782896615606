import { Session } from "./Session";
import { capitalizeFirst } from "./Utils";

export const CORPORATE_REF = "CORPORATE";

export const PROPERTY_APPEARANCE = "property";
export const CORPORATE_APPEARANCE = "general";
export const PROPERTY_LOGOS = "property";
export const CORPORATE_LOGOS = "general";
export const PROPERTY_LOGOS_TYPE = "PROPERTY";
export const CORPORATE_LOGOS_TYPE = "GENERIC";

export const PROPERTY_APPEARANCE_TYPE = "LOCAL";
export const CORPORATE_APPEARANCE_TYPE = "CORPORATE";

export const THEME_TYPE_DESIGN = "DESIGN";
export const THEME_TYPE_MOBILE = "MOBILE";

export const THEME_TYPES_ARR = [
    { TYPE: THEME_TYPE_DESIGN, TEXT: "design" },
    { TYPE: THEME_TYPE_MOBILE, TEXT: "mobile-app" },
];

export const TYPOGRAPHY_HEADING = "heading";
export const TYPOGRAPHY_PARAGRAPH = "paragraph";

// ==== Appearances screens ====
export const APPEARANCES_TOOLBAR = "appearances";
export const LOGOS_TOOLBAR = "logos";
export const TYPOGRAPHY_TOOLBAR = "typography";
export const COLOUR_TOOLBAR = "colors";

// ==== Logos types ====
export const LOGOS_TYPES = {
    COLOR_LIGHT_HORIZONTAL: "color-light-horizontal",
    COLOR_LIGHT_VERTICAL: "color-light-vertical",
    COLOR_DARK_HORIZONTAL: "color-dark-horizontal",
    COLOR_DARK_VERTICAL: "color-dark-vertical",
    COLOR_LIGHT_ICON: "color-light-icon",
    COLOR_DARK_ICON: "color-dark-icon",
    BW_LIGHT_HORIZONTAL: "bw-light-horizontal",
    BW_LIGHT_VERTICAL: "bw-lgiht-vertical",
    BW_DARK_HORIZONTAL: "bw-dark-horizontal",
    BW_DARK_VERTICAL: "bw-dark-vertical",
    BW_LIGHT_ICON: "bw-light-icon",
    BW_DARK_ICON: "bw-dark-icon",
    OTHERS_LIGHT_HORIZONTAL: "others-light-horizontal",
    OTHERS_LIGHT_VERTICAL: "others-light-vertical",
    OTHERS_DARK_HORIZONTAL: "others-dark-horizontal",
    OTHERS_DARK_VERTICAL: "others-dark-vertical",
    OTHERS_LIGHT_ICON: "others-light-icon",
    OTHERS_DARK_ICON: "others-dark-icon",
};

export const LOGOS_TYPES_MOBILE = {
    OTHERS_LIGHT_ICON: "light-background",
    OTHERS_DARK_ICON: "dark-background",
};

export const LOGOS_ORIGINS = {
    PROPERTY: "property-logos",
    GENERIC: "generic-logos",
};
// ==== Appearance DEFAULT ====
export const APPEARANCE_DEFAULT = {
    colors: {
        defaultBackground: "rgba(255,255,255,0.2)",
        defaultForeground: "rgba(61,95,135,1)",
        activeBackground: "rgba(255,255,255,1)",
        activeForeground: "rgba(34,134,239,1)",
        focusBackground: "rgba(34,134,239,0.8)",
        focusForeground: "rgba(255,255,255,1)",
        focusBorderSize: 5,
        accent: "rgba(34,134,239,1)",
        accentContrast: "rgba(255,255,255,1)",
        login: "rgba(240,240,240,1)",
    },
    fontStyles: {
        heading: {
            name: "Lato",
            size: 2.93,
            bold: true,
            italic: false,
            underline: false,
            alignment: "left",
            font: {
                externalUrl:
                    "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap",
            },
        },
        paragraph: {
            name: "Lato",
            size: 1.9,
            bold: false,
            italic: false,
            underline: false,
            alignment: "left",
            font: {
                externalUrl:
                    "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap",
            },
        },
    },
};
export const getLogoTypeName = (type, t) => t(LOGOS_TYPES[type]);
export const getLogoOriginName = (type, t) => t(LOGOS_ORIGINS[type]);
export const getLogoTypeNameMobile = (type, t) => {
    if (LOGOS_TYPES_MOBILE[type]) {
        return t(LOGOS_TYPES_MOBILE[type]);
    } else {
        return "";
    }
};

export const getLogosTypes = (t, versionLogosSelected = null, themeType) => {
    let _logosType = [];

    if (themeType === THEME_TYPE_MOBILE) {
        Object.keys(LOGOS_TYPES_MOBILE).map((type, index) => {
            _logosType.push({
                id: index,
                disabled:
                    versionLogosSelected && versionLogosSelected.find((_version) => _version === type) ? true : false,
                optGroup: "",
                display: getLogoTypeNameMobile(type, t),
                value: type,
            });
            return type;
        });
    } else {
        Object.keys(LOGOS_TYPES).map((type, index) => {
            let _optGroup = type.split("_")[0];
            _logosType.push({
                id: index,
                disabled:
                    versionLogosSelected && versionLogosSelected.find((_version) => _version === type) ? true : false,
                optGroup: _optGroup.toLowerCase(),
                display: getLogoTypeName(type, t),
                value: type,
            });
            return type;
        });
    }

    return _logosType;
};

export const getVersionsLogosUsed = (logos, type) => {
    let _versionLogoUsed = [];
    const _logoType = type === CORPORATE_LOGOS ? CORPORATE_LOGOS_TYPE : PROPERTY_LOGOS_TYPE;
    if (logos && logos.length > 0) {
        logos.forEach((_logo) => {
            if (_logo.type === _logoType) {
                _versionLogoUsed.push(_logo.version);
            }
        });
    }
    return _versionLogoUsed;
};

export const getLogoAssetURL = (asset, size = null) => {
    if (asset.includes("http")) {
        return asset;
    } else {
        return Session.getDasUrl(asset + (size ? `?w=${size.width}&h=${size.height}` : ""));
    }
};

export const NO_APPEARANCES = "NO APPEARANCES";

export const modalsOff = { add: false, edit: false, replace: false, upload: false, openLast: false };

export const SECTIONCONTENT_NULL_LOGOS = {
    modalAddLogo: false,
    replaceLogo: false,
    addLogo: null,
    allowNullLogo: false,
    modals: modalsOff,
};

export const permissionNullLogo = (logoGroup, appearanceType, projectRef) => {
    if (logoGroup === PROPERTY_LOGOS && appearanceType === CORPORATE_APPEARANCE_TYPE && projectRef === CORPORATE_REF) {
        return true;
    }
    return false;
};

export const findFirstAppearance = (data) => {
    for (let item of data) {
        if (item.name === "property" || item.name === "general") {
            if (item.appearances && item.appearances.length > 0) {
                return item.appearances[0];
            }
        }
    }
    return null;
};

export const findLastAppearance = (data, typeAppearance) => {
    let itemNameFilter;
    if (typeAppearance === "LOCAL") {
        itemNameFilter = "property";
    } else if (typeAppearance === "CORPORATE") {
        itemNameFilter = "general";
    }

    for (let item of data) {
        if (item.name === itemNameFilter) {
            if (item.appearances && item.appearances.length > 0) {
                const lastAppearanceCreated = item.appearances?.reduce(
                    (max, appearance) => (appearance.id > max.id ? appearance : max),
                    item.appearances[0]
                );
                return lastAppearanceCreated;
            }
        }
    }
    return null;
};

export const nullApperanceObj = {
    id: null,
    name: "",
    type: "",
    projectId: null,
    hasDesigns: false,
    isCopyableToProperty: false,
    __typename: "resultsTheme",
};

export const validateNumberLogosMobileTheme = ({ appearanceSelected, themeType }) => {
    if (themeType === THEME_TYPE_DESIGN) {
        return true;
    }

    const numberLogosProperty = appearanceSelected?.logos?.filter((l) => l?.type === PROPERTY_LOGOS_TYPE).length || 0;

    return !(numberLogosProperty === 2 && themeType === THEME_TYPE_MOBILE);
};

export const isLight = (rgb) => {
    if (rgb) {
        const values = rgb.match(/\d+/g).map(Number);
        const luminosity = 0.299 * values[0] + 0.587 * values[1] + 0.114 * values[2];
        const range = 128;
        return luminosity > range;
    }
    return false;
};

export const rgbaToRgb = (rgba) => {
    const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)$/);
    return match ? `rgb(${match[1]}, ${match[2]}, ${match[3]})` : null;
};
