import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Session } from "../../../../../hooks/Utils/Session";
import Toolbar from "./CommonOptions/Toolbar";
import Settings from "./CommonOptions/Settings";
import Input from "./CommonOptions/Input";
import Widget from "../../Widgets/Widget";
import { setWidgetToolbarVisible } from "../../../../../actions/designActions";
import PropertyConfig from "../../../../../hooks/GraphqlCalls/Hotel/PropertyConfig";
import { EditorContext, WidgetContext } from "../../../../../contexts/editor";

const ChatButtonToolbar = () => {
    const { deviceType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { gridItems } = useSelector((state) => state.design);

    const { config, loading, error } = PropertyConfig();

    const tabs = [
        { id: "regular", name: t("regular") },
        { id: "disabled", name: t("disabled") },
    ];

    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};

    const [currentTab, setCurrentTab] = useState(tabs[0].id);

    const chatURL = config ? config.chatURL : null;
    const chatPhone = currentHotel ? extractPhone(chatURL) : "";

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const showIcon = data ? data.showIcon : false;

    const selectTab = (type, tab) => {
        setCurrentTab(tab);
        dispatch(setWidgetToolbarVisible(type, tab));
    };

    useEffect(() => {
        if (error) console.error(error);
    }, [error]);

    return (
        <>
            <Toolbar type="layout">
                <Settings>
                    <Settings.Group title={t("Text options")}>
                        <Input.Text id="title" title={t("title")} />
                        {
                            //TODO implement font selector
                            // <Input.Font id="font" />
                        }
                        <Input.Number deviceSpecific={true} id="textSize" title={t("total size")} />
                        <Input.Check icon="icon-bold" dataType="style" id="bold" />
                        <Input.Check icon="icon-italic" dataType="style" id="italic" />
                        <Input.Check icon="icon-underline" dataType="style" id="underline" />
                    </Settings.Group>
                    <Settings.Group title={t("icon options")}>
                        <Input.Check id="showIcon" deviceSpecific={true} title={t("icon")} />
                        {showIcon ? <Input.Number id="iconSize" deviceSpecific={true} title={t("total size")} /> : null}
                        {showIcon ? (
                            <Input.Alignment
                                id="iconPosition"
                                type="around"
                                deviceSpecific={true}
                                title={t("position")}
                            />
                        ) : null}
                        {showIcon ? <Input.Number deviceSpecific={true} id="iconMargin" title={t("spacing")} /> : null}
                    </Settings.Group>
                    <Settings.Group title={t("alignment")}>
                        <Input.Alignment inline={true} id="alignment" deviceSpecific={true} />
                        <div className="inline-block border-l border-gray-400 py-1 mb-2">&nbsp;</div>
                        <Input.Alignment inline={true} id="vAlignment" type="vertical" deviceSpecific={true} />
                    </Settings.Group>
                </Settings>
            </Toolbar>
            <Toolbar type="settings" warning={!chatPhone}>
                <Settings>
                    {!loading ? (
                        <Settings.Group title={t("Chat settings")}>
                            {chatPhone ? (
                                <div className="flex flex-row">
                                    <i className="icon icon-info text-zafiro-400 mr-2"></i>
                                    <span className="mr-2">{t("Linked phone number") + ":"}</span>
                                    <span className="text-gray-700">{chatPhone}</span>
                                </div>
                            ) : !error ? (
                                <div className="flex flex-row">
                                    <i className="icon icon-warning text-orange-100 mr-2"></i>
                                    <span>{t("No linked phone number")}</span>
                                </div>
                            ) : (
                                <div className="flex flex-row">
                                    <i className="icon icon-warning text-red-100 mr-2"></i>
                                    <span>{error}</span>
                                </div>
                            )}
                        </Settings.Group>
                    ) : null}
                </Settings>
            </Toolbar>
            <Toolbar type="style">
                <Toolbar.Tabs
                    data={tabs}
                    currentTab={currentTab}
                    setCurrentTab={(tab) => {
                        selectTab("style", tab);
                    }}
                />
                {tabs.map((tab) => {
                    const isRegular = tab.id === "regular";
                    const fgColor = isRegular ? "fgColor" : tab.id + "FgColor";
                    const bgColor = isRegular ? "bgColor" : tab.id + "BgColor";
                    const iconColor = isRegular ? "iconColor" : null;
                    return (
                        <Settings key={tab.id + "-tab-content"} visible={tab.id === currentTab}>
                            <Settings.Group type="colors">
                                {fgColor ? <Input.Color id={fgColor} title={t("foreground")} /> : null}
                                {bgColor ? <Input.Color id={bgColor} title={t("background")} /> : null}
                                {iconColor ? <Input.Color id={iconColor} title={t("icon")} /> : null}
                            </Settings.Group>
                            <Settings.Border tab={tab.id} t={t} />
                        </Settings>
                    );
                })}
            </Toolbar>
            {/* <Toolbar type="clone" /> */}
            <Toolbar type="info" description={t("widget-chat-info")} />
            <Toolbar type="delete" />
        </>
    );
};

const extractPhone = (url) => {
    const regex = /phone=(.*)/gm;
    const m = regex.exec(url);
    const phone = m && m.length > 1 ? decodeURIComponent(m[1]) : null;
    return phone || url;
};

export default ChatButtonToolbar;
