import React from "react";
import { useDispatch } from "react-redux";
//Actions
import { setHotelSearch } from "../../../actions/authActions";
import { useTranslation } from "react-i18next";

const UseProjectSearch = () => {
    const { t } = useTranslation();

    //Actions
    const dispatch = useDispatch();
    const handleChangeFilters = (e) => {
        dispatch(setHotelSearch(e.target.value));
    };
    return (
        <div className="grid gap-4 grid-cols-3 mb-8 mt-4">
            <div></div>
            <div className="relative">
                <input
                    autoFocus={true}
                    type="text"
                    className="w-full bg-gray-200 rounded hover:text-zafiro-400 p-2 pl-4 first-capital"
                    placeholder={`${t("search")} ${t("hotel")}...`}
                    onChange={handleChangeFilters}
                ></input>
                <span className="field-icon icon-search"></span>
            </div>
        </div>
    );
};

export default UseProjectSearch;
