import React from "react";
import { Component, ListComponents } from "sections/playground";

import LibraryImageSelector from "components/LibraryImageSelector";
import { DateInput } from "components/Inputs/DateInput";

const Pending = () => {
    return (
        <ListComponents>
            <Component title="LibraryImageSelector" validated={false}>
                <LibraryImageSelector />
                <LibraryImageSelector
                    id="test-library-image-selector"
                    onChange={(newPicture) => {
                        console.log("LibraryImageSelector onChange!");
                        console.log("New picture", newPicture);
                    }}
                    value={
                        "asset-3cc39219-8607-4a3e-b867-d4b91b8bc2fd-636861696e2d303030302d303030302d303030302d30303134"
                    }
                    resolution={{ width: 1280, height: 720 }}
                />
            </Component>
            <Component title={"DateInput"} validated={false}>
                <DateInput
                    onChange={() => {}}
                    value={new Date()}
                    customDisabledDays={[{ after: new Date(2024, 7, 5), before: new Date() }]}
                    tooltip={"tooltip for disabled days"}
                ></DateInput>
                <DateInput
                    onChange={() => {}}
                    value={new Date()}
                    validation={true}
                    tooltip={"tooltip for disabled days"}
                ></DateInput>
            </Component>
        </ListComponents>
    );
};

export default Pending;
