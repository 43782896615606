import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";
import { capitalizeFirst, removeApolloCacheKeys } from "../../../../hooks/Utils/Utils";

//UTils
import { CSVLink } from "react-csv";
import { Session } from "../../../Utils/Session";

const ExportMap = ({ startMapExport, setStartMapExport, client }) => {
    //Constantes
    const cookieCHotel = Session.getSessionProp("currentHotel");
    const HOTEL_NAME = cookieCHotel ? JSON.parse(cookieCHotel).name : "";

    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [sorting] = useState(`,orderBy:{field: "number", criteria: "asc"}`);
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const ALL_ROOMS_INFO = gql`{
    rooms: rooms(page:1, size: 10000${sorting}){
      info { count }
      results { floorID floorName id name number pmsNumber roomTVs { id name} wingID wingName}
  }}`;

    const [executeAllRoomsQuery, allRoomsData] = useLazyQuery(ALL_ROOMS_INFO);

    useEffect(() => {
        if (startMapExport) {
            dispatch(showGlobalLoading(true));

            executeAllRoomsQuery();
        }
        // eslint-disable-next-line
    }, [startMapExport]);

    useEffect(() => {
        if (allRoomsData && allRoomsData.data && allRoomsData.data.rooms && allRoomsData.data.rooms.results) {
            const rooms = allRoomsData.data.rooms.results;

            setExportHeaders([
                { label: capitalizeFirst(langStrings["wing"]), key: "wing" },
                { label: capitalizeFirst(langStrings["floor"]), key: "floor" },
                {
                    label: capitalizeFirst(langStrings["room-number"]),
                    key: "roomnumber",
                },
                {
                    label: capitalizeFirst(langStrings["pms-number"]),
                    key: "pmsnumber",
                },
                {
                    label: capitalizeFirst(langStrings["name"]),
                    key: "roomname",
                },
                { label: capitalizeFirst(langStrings["tvs"]), key: "tvs" },
                {
                    label: capitalizeFirst(langStrings["location"]),
                    key: "location",
                },
            ]);

            let rows = [];
            rooms.map((room) =>
                rows.push({
                    wing: room.wingName ? room.wingName : "",
                    floor: room.floorName ? room.floorName : "",
                    roomnumber: room.number ? room.number : "",
                    pmsnumber: room.pmsNumber ? room.pmsNumber : "",
                    roomname: room.name ? room.name : "",
                    tvs: room.roomTVs.length,
                    location: room.roomTVs.map((rtv) => rtv.name).join(","),
                })
            );

            setExportData(rows);

            dispatch(showGlobalLoading(false));
            setTimeout(function () {
                document.querySelector("#exportLink").click();
                //remove apollo cache for next downloads
                removeApolloCacheKeys(client.cache, "rooms");
                setStartMapExport(false);
            }, 200);
        }
        // eslint-disable-next-line
    }, [allRoomsData.data]);

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={`${HOTEL_NAME ? HOTEL_NAME : "hotel"}-topology.csv`}
        ></CSVLink>
    );
};

export default withApollo(ExportMap);
