import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "react-apollo";
import Dropdown from "components/Dropdown";
import UseEditableField from "components/useEditableField";
import Select from "components/Select";
import TextInput from "components/TextInput";
import UseInputDate from "components/useInputDate";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import UseSelectMultipleWithSearchOptionsQuery from "components/useSelectMultipleWithSearchOptionsQuery";
import { GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import { GetLanguageOptions } from "hooks/Utils/LanguagesUtils";
import { DOCUMENTS, GENDERS, getRequiredIcon } from "../../Precheckin/ReservationDetails/utils";
import { changeActionValues, setActionValidations } from "actions/globalActions";
import { showGlobalLoading } from "actions/uiActions";
import { validateField } from "hooks/Utils/Validations";
import Icon from "components/Icon";

const InputBlock = ({ id, title, placeholder, value, setValue, required, validations, onBlur, icon }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div className=" flex items-center space-x-2">
                <p className="fieldLabel">
                    {t(title)}
                    {required ? " *" : ""}
                </p>
                {icon ? (
                    <Icon
                        className={icon?.className || null}
                        type={icon?.type || null}
                        tooltip={icon?.tooltip || null}
                        size={icon?.size || null}
                    />
                ) : null}
            </div>
            <TextInput
                id={id}
                className={"h-11"}
                placeholder={t(placeholder)}
                value={value}
                onChange={setValue}
                onBlur={onBlur}
                error={validations}
            />
        </div>
    );
};

const NewGuestData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { values, validations } = useSelector((state) => state.action);
    const { lang } = useSelector((state) => state.ui);
    const [phone, setPhone] = useState("");
    const [customFields, setCustomFields] = useState([]);
    const [precheckinFields, setPrecheckinFields] = useState(null);
    const [openSection1, setOpenSection1] = useState(false);
    const [openSection2, setOpenSection2] = useState(false);
    const [openSection3, setOpenSection3] = useState(false);

    const groupsQuery = `{
        data: guestGroups{
          info { count }
          results { id name  }
        }       
    }`;

    const customFieldsQuery = gql`
        {
            preCheckInSettingsFields {
                name {
                    enabled
                    required
                    blocked
                }
                surname {
                    enabled
                    required
                    blocked
                }
                gender {
                    enabled
                    required
                    blocked
                }
                nationality {
                    enabled
                    required
                    blocked
                }
                birthdate {
                    enabled
                    required
                    blocked
                }
                email {
                    enabled
                    required
                    blocked
                }
                phone {
                    enabled
                    required
                    blocked
                }
                documentType {
                    enabled
                    required
                    blocked
                    values
                }
                documentNumber {
                    enabled
                    required
                    blocked
                }
                documentExpeditionDate {
                    enabled
                    required
                    blocked
                }
            }
            preCheckInSettingsCustomFields {
                fields {
                    blocked
                    enabled
                    names {
                        lang
                        name
                    }
                    order
                    ref
                    required
                }
            }
        }
    `;

    const [executeQuery, { data, loading }] = useLazyQuery(customFieldsQuery, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            dispatch(showGlobalLoading(false));
        },
    });

    useEffect(() => {
        executeQuery();
        dispatch(showGlobalLoading(true));
    }, []);

    useEffect(() => {
        if (!openSection1) {
            setPhone(values["mobile-phone"]);
        }
        if (phone && !values["mobile-phone"] && openSection1) {
            dispatch(
                changeActionValues({
                    ["mobile-phone"]: `${phone}`,
                })
            );
        }
    }, [phone, openSection1]);

    useEffect(() => {
        if (openSection1 || openSection2 || openSection3) {
            setTimeout(() => {
                const container = document.getElementById("sectionContent");
                container.scroll(0, 1000);
            }, 100);
        }
    }, [openSection1, openSection2, openSection3]);

    useEffect(() => {
        if (data && !loading) {
            setCustomFields(
                data?.preCheckInSettingsCustomFields?.fields
                    ?.sort((a, b) => a.order - b.order)
                    ?.map((val) => {
                        const title =
                            val?.names?.find((name) => name.lang == lang)?.name ||
                            val?.names?.find((name) => name.name != "")?.name;
                        return { title, ref: val?.ref };
                    })
            );

            setPrecheckinFields(data?.preCheckInSettingsFields || null);
        }
    }, [data]);

    const prefix = "new-guest-form-pms";
    return (
        <div className="p-8 flex flex-col gap-6 ">
            <div>
                <div className="w-full border-b border-gray-200 text-lg pb-2 flex justify-between">
                    <div className="first-capital font-bold">{t("guest-information")}</div>
                </div>
                <div className={`grid grid-cols-2 gap-x-4 gap-y-4 pt-4`}>
                    <InputBlock
                        title={"pms-id"}
                        value={values["pms-id"]}
                        setValue={(e) => {
                            dispatch(changeActionValues({ ["pms-id"]: e }));
                        }}
                        id={`${prefix}-id`}
                    ></InputBlock>
                    <InputBlock
                        title={"title"}
                        value={values["title"]}
                        setValue={(e) => {
                            dispatch(changeActionValues({ ["title"]: e }));
                        }}
                        id={`${prefix}-title`}
                    ></InputBlock>
                    <InputBlock
                        title={"name"}
                        value={values["name"]}
                        setValue={(e) => {
                            dispatch(changeActionValues({ ["name"]: e }));
                        }}
                        required={true}
                        validations={typeof validations["name"] == "boolean" ? !validations["name"] : false}
                        id={`${prefix}-name`}
                    ></InputBlock>
                    <InputBlock
                        title={"surname"}
                        value={values["surname"]}
                        setValue={(e) => {
                            dispatch(changeActionValues({ ["surname"]: e }));
                        }}
                        required={true}
                        validations={typeof validations["surname"] == "boolean" ? !validations["surname"] : false}
                        id={`${prefix}-surname`}
                    ></InputBlock>
                    <div className="col-span-2">
                        <UseSelectMultipleWithSearchOptionsQuery
                            dataSelect={{
                                text: ["guest-group"],
                                textStyle:
                                    "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                                name: "guest-groups",
                                optionsQuery: groupsQuery,
                                selectPlaceHolder: "select-group",
                                cStyle: "w-full",
                                sort: true,
                                type: "selectMultipleWithSearch",
                                noSelect: true,
                                id: "new-guest-form-select-guest-group",
                            }}
                        ></UseSelectMultipleWithSearchOptionsQuery>
                    </div>
                </div>
            </div>
            <Dropdown
                autoClose={false}
                handler={
                    <div className="w-full border-b border-gray-200 text-lg pb-2 flex justify-between">
                        <div className="first-capital font-bold">{t("main-information")}</div>
                    </div>
                }
                id={"main-information-section"}
                open={openSection1}
                setOpen={(open) => {
                    if (open) {
                        setOpenSection1(true);
                        setOpenSection2(false);
                        setOpenSection3(false);
                    } else {
                        setOpenSection1(false);
                    }
                }}
            >
                <div className={`grid grid-cols-2 gap-x-4 gap-y-4 pt-4`}>
                    <div>
                        <div className=" flex items-center">
                            <p className="fieldLabel">{t("nationality")}</p>
                            {precheckinFields?.nationality?.required ? (
                                <Icon {...getRequiredIcon(precheckinFields?.nationality?.required)} />
                            ) : null}
                        </div>
                        <Select
                            placeholder={t("nationality")}
                            options={GetNationalitiesOptions().map((val) => ({
                                value: val.id,
                                label: val.name,
                                id: val.id,
                            }))}
                            allowUnselect={false}
                            width="full-w"
                            value={values["nationality"]}
                            id={`${prefix}-nationality`}
                            onChange={(e) => {
                                console.log(e);

                                dispatch(changeActionValues({ ["nationality"]: e }));
                            }}
                        ></Select>
                    </div>

                    <div>
                        <div className=" flex items-center">
                            <p className="fieldLabel">{t("lang")}</p>
                            {precheckinFields?.lang?.required ? (
                                <Icon {...getRequiredIcon(precheckinFields?.lang?.required)} />
                            ) : null}
                        </div>
                        <Select
                            placeholder={t("lang")}
                            options={GetLanguageOptions().map((val) => ({
                                value: val.id,
                                label: val.name,
                                id: val.id,
                            }))}
                            allowUnselect={false}
                            width="full-w"
                            value={values["lang"]}
                            id={`${prefix}-lang`}
                            onChange={(e) => {
                                dispatch(changeActionValues({ ["lang"]: e }));
                            }}
                        ></Select>
                    </div>

                    <div>
                        <div className=" flex items-center">
                            <p className="fieldLabel">{t("gender")}</p>
                            {precheckinFields?.gender?.required ? (
                                <Icon {...getRequiredIcon(precheckinFields?.gender?.required)} />
                            ) : null}
                        </div>
                        <Select
                            placeholder={t("gender")}
                            options={GENDERS.map((val) => ({ value: val.id, label: val.name, id: val.id }))}
                            width="full-w"
                            value={values["gender"]}
                            id={`${prefix}-gender`}
                            onChange={(e) => {
                                dispatch(changeActionValues({ ["gender"]: e }));
                            }}
                        ></Select>
                    </div>
                    <UseEditableField
                        data={{
                            label: "birthdate",
                            labelOnEdition: "birthdate",
                            value: values["birthdate"] ? values["birthdate"] : "",
                            inEditionMode: true,
                            dateAdjust: "text-gray-600",
                            type: "date",
                            icon: getRequiredIcon(precheckinFields?.birthdate?.required),
                        }}
                    ></UseEditableField>

                    <div className="col-span-2">
                        <InputBlock
                            title={"email"}
                            id={"new-guest-form-email"}
                            value={values["email"]}
                            placeholder={"email"}
                            setValue={(e) => {
                                dispatch(changeActionValues({ ["email"]: e }));
                            }}
                            onBlur={(e) => {
                                dispatch(
                                    setActionValidations({
                                        ...validations,
                                        ["email"]: validateField("email", e, false),
                                    })
                                );
                            }}
                            icon={getRequiredIcon(precheckinFields?.email?.required)}
                        ></InputBlock>
                    </div>
                    <div className="col-span-2">
                        <p className="fieldLabel">{t("mobile-phone")}</p>
                        <div className="w-full">
                            <UseCustomPhoneInput value={phone} fieldActionValues={"mobile-phone"} actionValues={true} />
                        </div>
                    </div>
                </div>
            </Dropdown>
            <Dropdown
                id={"documents-section"}
                autoClose={false}
                handler={
                    <div className="w-full border-b border-gray-200 text-lg pb-2 flex justify-between">
                        <div className="first-capital font-bold">{t("documentation")}</div>
                    </div>
                }
                open={openSection2}
                setOpen={(open) => {
                    if (open) {
                        setOpenSection2(true);
                        setOpenSection1(false);
                        setOpenSection3(false);
                    } else {
                        setOpenSection2(false);
                    }
                }}
            >
                <div className={`grid grid-cols-2 gap-x-4 gap-y-4 pt-4`}>
                    <div className=" col-span-2">
                        <p className="fieldLabel">{t("type-of-document")}</p>
                        <Select
                            placeholder={t("select-type")}
                            id={`${prefix}-type-of-document`}
                            options={DOCUMENTS.map((val) => ({ value: val.id, label: val.name, id: val.id }))}
                            width="full-w"
                            value={values["type-of-document"]}
                            onChange={(e) => {
                                dispatch(changeActionValues({ ["type-of-document"]: e }));
                            }}
                        ></Select>
                    </div>
                    <InputBlock
                        title={"document-no"}
                        placeholder={"document-no"}
                        value={values["document-no"]}
                        id={"check-in-document-number"}
                        setValue={(e) => {
                            dispatch(changeActionValues({ ["document-no"]: e }));
                        }}
                    ></InputBlock>
                    <div>
                        <p className="fieldLabel">{t("date-of-issue")}</p>
                        <UseInputDate
                            data={{
                                name: `date-of-issue`,
                                id: `check-in-date-of-issue`,
                                fullwidth: true,
                                nonInitialDate: true,
                                date: values["date-of-issue"],
                                placeholder: "dd/mm/yyyy",
                                noCapitalizePlaceholder: true,
                                styleAdjust: `h-11`,
                            }}
                        />
                    </div>
                </div>
            </Dropdown>
            {customFields?.length > 0 && (
                <Dropdown
                    autoClose={false}
                    handler={
                        <div className="w-full border-b border-gray-200 text-lg pb-2 flex justify-between">
                            <div className="first-capital font-bold">{t("additional-information")}</div>
                        </div>
                    }
                    id={"custom-fields-section"}
                    open={openSection3}
                    setOpen={(open) => {
                        if (open) {
                            setOpenSection3(true);
                            setOpenSection1(false);
                            setOpenSection2(false);
                        } else {
                            setOpenSection3(false);
                        }
                    }}
                >
                    <div className={`grid grid-cols-1 gap-x-4 gap-y-4 pt-4`}>
                        {customFields.map((customField, index) => (
                            <InputBlock
                                key={`custom-field-${index}`}
                                title={customField.title}
                                value={values[`custom-field-${customField.ref}`]?.value}
                                setValue={(e) => {
                                    dispatch(
                                        changeActionValues({
                                            [`custom-field-${customField.ref}`]: { ref: customField.ref, value: e },
                                        })
                                    );
                                }}
                                id={`${prefix}-custom-field-${index}`}
                            ></InputBlock>
                        ))}
                    </div>
                </Dropdown>
            )}
        </div>
    );
};

export default NewGuestData;
