import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Icon from "components/Icon";

export const TAG_MOBILE_APP = "app";
export const TAG_WIFI_PORTAL = "wifi";
export const TAG_CHECK_IN = "check-in";

const Tag = ({ children, type, color, icon, className, tooltip, tooltipType }) => {
    const { t } = useTranslation();
    const defaultColor = "#2286EF";
    let prefix, typeColor;
    switch (type) {
        case TAG_WIFI_PORTAL:
            prefix = t("wifi-word");
            typeColor = "#0F63BD";
            break;
        case TAG_CHECK_IN:
            prefix = t("Check-in");
            typeColor = "#A7B5C4";
            break;
        case TAG_MOBILE_APP:
            typeColor = "#06376B";
            break;
        default:
            typeColor = defaultColor;
    }
    if (color !== undefined) {
        // If color is provided, override the default color with it
        typeColor = color;
    }
    if (typeColor === "default") {
        typeColor = defaultColor;
    }

    const tagClass = classNames({
        "inline-flex items-center space-x-3 rounded py-2 mr-2 px-4 text-white break-words": true,
        [className]: className,
    });
    return (
        <div
            className={tagClass}
            style={{ backgroundColor: typeColor }}
            data-tip={tooltip}
            data-for={tooltip ? tooltipType || "dangerous-html-tooltip" : null}
        >
            <span>
                {prefix ? prefix + " " : ""}
                {children}
            </span>
            {icon ? <Icon type={icon} /> : null}
        </div>
    );
};
Tag.displayName = "Tag";

export default Tag;
