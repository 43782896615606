import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { Session } from "hooks/Utils/Session";
import { routes } from "routes";

import GetLangStrings from "lang/getLangStrings";
import { setLangStrings } from "actions/uiActions";
import DisplayMenuSidebar from "hooks/Utils/DisplayMenuSidebar";

const Section = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const isLoggedIn = Session.getSessionProp("token");
    const currentMenuConfig = DisplayMenuSidebar();
    const {
        lang,
        permissions,
        sectionStyle: customContentClass,
        warnings: hasWarnings,
        warningsMinimized: hasWarningsMinimized,
    } = useSelector((state) => state.ui);
    const { impersonated: hasImpersonated } = useSelector((state) => state.auth);

    const topPx = currentMenuConfig?.hideHeader ? 0 : 72;
    const topRem = (hasWarnings ? 5 : 0) + (hasWarningsMinimized ? -3 : 0) + (hasImpersonated ? 2 : 0);
    const contentStyle = {
        height: `calc(100vh - ${topPx}px - ${topRem}rem)`,
    };

    const currentMenuClass = `${isLoggedIn ? "right-0 bg-gray-100" : ""} ${currentMenuConfig?.style?.x} ${
        currentMenuConfig?.style?.y
    }`;
    const contentClass = `overflow-auto ${customContentClass || currentMenuClass}`;

    useEffect(() => {
        //TODO langStrings is deprecated and should be removed
        const selectedLang = lang || "en";
        const langStrings = GetLangStrings(selectedLang, location?.pathname);
        dispatch(setLangStrings(langStrings));
    }, [lang, location]);

    useEffect(() => {
        if (location) {
            Session.setSessionProp("previous-location", Session.getSessionProp("current-location"));
            Session.setSessionProp("current-location", location?.pathname);
        }
    }, [location]);

    return (
        <div id="sectionContent" className={contentClass} style={contentStyle}>
            {permissions ? (
                <Routes>
                    {routes
                        .filter(
                            (route) =>
                                typeof route.section !== "undefined" ||
                                typeof route.redirect !== "undefined" ||
                                route.reload === true
                        )
                        .map((route, index) => (
                            <Route
                                key={index}
                                {...route}
                                element={
                                    route.reload === true ? (
                                        <Reload />
                                    ) : route.redirect !== undefined ? (
                                        <Navigate to={route.redirect} />
                                    ) : (
                                        <route.section />
                                    )
                                }
                            />
                        ))}
                </Routes>
            ) : null}
        </div>
    );
};

const Reload = () => {
    useEffect(() => {
        window.location.reload();
    }, []);
    return <>Reload page</>;
};

export default Section;
