import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import AppearanceHeadMenu from "./AppearancesHeadMenu";
import PreviewAppearance from "./PreviewAppearance";

//Utils
import { cleanSectionContent } from "../../../../actions/sectionActions";
import { THEME_TYPE_MOBILE } from "hooks/Utils/AppearancesUtils";
import PreviewMobileAppearance from "./PreviewMobileAppearance";
import { useNavigate as useHistory } from "react-router-dom";

const AppearanceWithMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const themeTypeMobile = appearanceSelected?.themeType === THEME_TYPE_MOBILE ? true : false;

    useEffect(() => {
        return () => {
            dispatch(cleanSectionContent());
        };
        // eslint-disable-next-line
    }, []);

    console.log(appearanceSelected);

    useLayoutEffect(() => {
        if (!appearanceSelected) {
            history("/design/appearances/list");
        }
    }, [appearanceSelected]);

    return (
        <>
            <AppearanceHeadMenu />
            {themeTypeMobile ? <PreviewMobileAppearance appearanceWithMenu={true} /> : <PreviewAppearance />}
        </>
    );
};

export default AppearanceWithMenu;
