import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UseSectionHeader from "../../../useSectionHeader";
import { useTranslation } from "react-i18next";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import { UPDATE_PRODUCT_AVAILABLE } from "../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import UseCard from "../../../useCard";
import UpdateProducts from "../../../../hooks/GraphqlCalls/Sales/UpdateProducts";
import UseSwitch from "../../../Inputs/useSwitch";
import { getProjectLangs } from "../../../../hooks/Utils/SalesUtils";
import ShopHeadMenu from "./ShopHeadMenu";
import { SalesProductSettingsProvider } from "./SalesContext";
import SalesProvider from "contexts/Sales";
import ShopProvider from "contexts/Sales/Shop";
import { setRefreshData } from "actions/tableActions";

import { GlobalContext } from "contexts/Global";

const ProductSettings = () => {
    const dispatch = useDispatch();

    const { searchHistory } = useContext(GlobalContext);

    //Consts&states
    const { t } = useTranslation();
    const salesRef = useRef(null);
    const contextRef = useRef(null);
    const refreshData = useSelector((state) => state?.sectionContent?.refreshData);
    const { id: shopId, productId } = useParams();
    const { projectLangs } = useSelector((state) => state.ui);
    const [langDefault, setLangDefault] = useState(null);
    const [productData] = useState({});
    const [shopData] = useState({});
    const [productVariantData] = useState({});
    const [productSettingsData, setProductSettingsData] = useState(null);
    const [extrasData] = useState([]);
    const [productName, setProductName] = useState(null);
    const [available, setAvailable] = useState(false);

    const productsList = `/services/sales/shop/products/${shopId}`;
    const backToProductsList = searchHistory({ lastPath: productsList }) || productsList;

    const breadcrumbs = [
        {
            name: "products",
            translate: true,
            route: backToProductsList,
        },
        {
            name: "product-list",
            translate: true,
            route: backToProductsList,
        },
    ];

    useEffect(() => {
        if (productSettingsData?.product?.enabled) {
            setAvailable(true);
        }
    }, [productSettingsData]);

    //effects
    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, []);

    const handleAvailable = (value) => {
        setAvailable(value);
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_AVAILABLE,
                params: {
                    productId: productId,
                    available: value,
                },
            })
        );
    };
    const refresh = () => {
        if (contextRef?.current) {
            contextRef.current.refresh();
        }
    };
    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshData(false));
            refresh();
        }
    }, [refreshData]);

    //renders

    //response
    return (
        <SalesProvider ref={salesRef}>
            <ShopProvider ref={contextRef} id={shopId}>
                <SalesProductSettingsProvider setParentData={setProductSettingsData}>
                    <ShopHeadMenu />
                    <UseSectionHeader
                        title={productName}
                        customBreadCrumbs={breadcrumbs}
                        navToSection={backToProductsList}
                        noCapitalize={true}
                        noTranslate={true}
                    />
                    <div className="w-full relative">
                        <div className="absolute right-0 -mt-16 flex">
                            <div className="first-capital">
                                <span className="">{t("available")}</span>
                            </div>
                            <UseSwitch
                                id={"product-settings-switch-make-available-product"}
                                checked={available}
                                action={(value) => {
                                    handleAvailable(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className=" w-full flex ">
                        <div className="w-7/12 pr-5">
                            <div className="w-full mb-5">
                                <UseCard
                                    noDefaultAdjust={true}
                                    adjust="items-center align-start"
                                    type="product-files-card"
                                    data={{ id: productId, shop: shopData }}
                                />
                            </div>
                            <div className="w-full mb-5">
                                <UseCard
                                    type="product-translations-name-description-card"
                                    data={{
                                        ...productData,
                                        setProductName,
                                        langDefault,
                                    }}
                                />
                            </div>
                            <div className=" w-full mb-5">
                                <div className="w-full flex">
                                    <div className="w-6/12 pr-2">
                                        <UseCard
                                            type="product-price-card"
                                            data={{ productId: productId, shopId: shopId }}
                                        />
                                    </div>
                                    <div className="w-6/12 pl-2">
                                        <UseCard
                                            type="product-allergens-card"
                                            data={{ productId: productId, shopId: shopId }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-5/12 pt-14">
                            <div className="w-full mb-5">
                                <UseCard type="product-settings-card" data={productVariantData} />
                            </div>
                            <div className="w-full mb-5">
                                <UseCard
                                    type="product-extras-card"
                                    data={{ extrasData: extrasData, shopData: shopData }}
                                />
                            </div>
                        </div>
                    </div>
                    <UpdateProducts />
                </SalesProductSettingsProvider>
            </ShopProvider>
        </SalesProvider>
    );
};

export default ProductSettings;
