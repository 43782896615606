import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData, cleanCheckedItems } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
import _ from "lodash";
import uuid from "react-uuid";
import { Session } from "../../Utils/Session";
import { arrangeToastMessagesUploadingFiles, arrangeToastMessagesWhenFailsBatchActions } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";

const UpdateListProducts = ({ client }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const { checkedItems } = useSelector((state) => state.table);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const jsonTitlesFailsActionsBatch = {
        "delete-product-batch": "error-deleting-items-failed",
        "set-as-available-products-batch": "error-failure-items-available",
        "set-as-unavailable-product-batch": "error-failure-items-unavailable",
    };

    const currentTokenShop = Session.getSessionProp("tokenShop");
    const currentProducts = Session.getSessionProp("vendure-products");

    const [tokenShop, setTokenShop] = useState("");
    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (currentTokenShop) {
            setTokenShop(currentTokenShop);
        }
    }, [currentTokenShop]);

    useEffect(() => {
        if (currentProducts) {
            // conso
            setTimeout(() => {
                setProducts(JSON.parse(currentProducts));
            }, 2000);
        }
    }, [currentProducts]);

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let mutationFile = false;
        let executeAnotherQuery = null;
        let multipleMutation = false;
        let mutationName = null;
        let avoidToast = false;
        let aditionalHeader = null;
        let avoidRefresh = false;
        let redirectOnSuccessUrl = null;
        const titlesFailsActionsBatch = jsonTitlesFailsActionsBatch[executeQuery?.action];
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            execute = true;

            switch (executeQuery.action) {
                case "add-product":
                    mutation = ` 
                    mutation {
                        createProduct(
                            input: {
                              enabled: false
                              translations: [
                                {
                                  languageCode: ${actionData.actionData["default-language"]}
                                  name: "${actionData.actionData["product-lang-default"].trim()}"
                                  slug: "${actionData.actionData["product-lang-default"]
                                      .trim()
                                      .toLowerCase()
                                      .replace(/\s+/g, "-")}"
                                  description: ""
                                }
                              ]
                            }
                          ) {
                            id
                            createdAt
                          }
                    }
                    `;
                    mutationName = "createProduct";
                    avoidToast = true;
                    aditionalHeader = { "vendure-token": tokenShop };
                    executeAnotherQuery = {
                        action: "create-product-variant",
                        useResponsePrevQueryData: {
                            name: "productId",
                            path: "createProduct.id",
                        },
                        extraParams: { ...executeQuery.params },
                    };
                    break;
                case "create-product-variant":
                    let taxCategoryId =
                        executeQuery.params && executeQuery.params.value && executeQuery.params.value.taxCategoryId
                            ? executeQuery.params.value.taxCategoryId
                            : null;
                    mutation = ` 
                    mutation {
                        createProductVariants(
                            input: {
                                productId:${executeQuery.params.productId}
                                translations: [{
                                    languageCode: ${actionData.actionData["default-language"]}
                                    name: "${actionData.actionData["product-lang-default"].trim()}"
                                }]
                                sku:"${uuid()}"
                                ${taxCategoryId ? " taxCategoryId:" + taxCategoryId + " " : " "}
                            }
                          ) {
                            id
                          }
                    }
                    `;
                    mutationName = "createProductVariant";
                    executeQuery.closeModal = true;
                    aditionalHeader = { "vendure-token": tokenShop };
                    redirectOnSuccessUrl = `#/services/sales/shop/${id}/product/${executeQuery.params.productId}`;
                    break;
                case "delete-product":
                    mutation = `
                    mutation {
                        deleteProduct(id: ${actionData.itemsAffected[0]}) {
                          result
                          message
                        }
                      }
                    `;
                    mutationName = "deleteProduct";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-available-product":
                    mutation = `
                    mutation {
                        updateProduct(input: {
                          id: ${actionData.itemsAffected[0]},
                          enabled: true
                        }) {
                          id
                          name
                          enabled
                        }
                      }
                    `;
                    mutationName = "updateProduct";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-unavailable-product":
                    mutation = `
                        mutation {
                            updateProduct(input: {
                              id: ${actionData.itemsAffected[0]},
                              enabled: false
                            }) {
                              id
                              name
                              enabled
                            }
                          }
                        `;
                    mutationName = "updateProduct";
                    executeQuery.closeModal = true;
                    break;
                case "delete-product-batch":
                    multipleMutation = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutation.arr.push({
                            id: item,
                            mutation: `mutation{
                            deleteProduct(id:${Number(item)}){
                              result
                              message
                            }
                          }`,
                        });
                    });
                    mutationName = "deleteProduct";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-available-products-batch":
                    multipleMutation = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutation.arr.push({
                            id: item,
                            mutation: `mutation {
                                updateProduct(input: {
                                  id: ${Number(item)},
                                  enabled: true
                                }) {
                                  id
                                  name
                                  enabled
                                }
                              }`,
                        });
                    });
                    mutationName = "updateProduct";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-unavailable-product-batch":
                    multipleMutation = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutation.arr.push({
                            id: item,
                            mutation: `mutation {
                                updateProduct(input: {
                                  id: ${Number(item)},
                                  enabled: false
                                }) {
                                  id
                                  name
                                  enabled
                                }
                              }`,
                        });
                    });
                    mutationName = "updateProduct";
                    executeQuery.closeModal = true;
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(
                    mutation,
                    executeAnotherQuery,
                    multipleMutation,
                    mutationFile,
                    mutationName,
                    avoidToast,
                    avoidRefresh,
                    aditionalHeader,
                    titlesFailsActionsBatch,
                    redirectOnSuccessUrl
                );
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (
        mutation,
        executeAnotherQuery,
        multipleMutations,
        mutationFile,
        mutationName,
        avoidToast,
        avoidRefresh,
        aditionalHeader,
        titlesFailsActionsBatch,
        redirectOnSuccessUrl
    ) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }

        let errorMutation = false;
        let toastId = "";
        let files = null;

        if (mutationFile) {
            files = [
                {
                    name: mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: mutationFile.file.size,
                },
            ];
        }

        if (multipleMutations && multipleMutations.arr && multipleMutations.arr.length > 0) {
            const successIds = [];
            const sucessResponse = [];
            const errorsIds = [];
            const errorsResponse = [];
            const errorsJsonProducts = [];
            const deleteAction = mutationName === "deleteProduct";
            const productsDeleted = "next-products-have-been-deleted";
            const productsNotDeleted = "next-products-have-not-been-deleted";
            const productsModified = "next-products-have-been-modified-successfully";
            const productsNotModified = "next-products-have-not-been-modified-successfully";

            await Promise.all(
                multipleMutations.arr.map(async (item) => {
                    const res = await executeVendureQuery(getVendureApiData(), { queryBody: item.mutation }); // Send request for each id

                    if (res && res.data && !res.data.errors) {
                        successIds.push(item.id);
                    }

                    if (res && res.data && res.data.errors) {
                        errorsIds.push(item.id);
                    }
                })
            );

            if (successIds.length > 0) {
                successIds.forEach((id) => {
                    products.forEach((element) => {
                        if (element.id === id) {
                            sucessResponse.push(element.name);
                        }
                    });
                });
                toast.success(
                    <div className=" flex flex-col">
                        <span>{capitalizeFirst(t(deleteAction ? productsDeleted : productsModified))}:</span>
                        {sucessResponse.map((item) => (
                            <span>&#x2022; {item}</span>
                        ))}
                    </div>
                );
            }

            if (errorsIds.length > 0) {
                errorsIds.forEach((errorId) => {
                    products.forEach((element) => {
                        if (element.id === errorId) {
                            errorsResponse.push(element.name);
                            errorsJsonProducts.push({
                                name: element.name,
                                productId: errorId,
                                storeId: id,
                            });
                        }
                    });
                });

                if (titlesFailsActionsBatch) {
                    let msgs = arrangeToastMessagesWhenFailsBatchActions(errorsJsonProducts);
                    toast.warning(<UseToast title={t(titlesFailsActionsBatch)} msgs={msgs} minimize={false} />, {
                        autoClose: 3500,
                        className: "use-toast fail-products-delete",
                        closeButton: true,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        icon: false,
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error(
                        <div className=" flex flex-col">
                            <span>{capitalizeFirst(t(deleteAction ? productsNotDeleted : productsNotModified))}:</span>
                            {errorsResponse.map((item) => (
                                <span>&#x2022; {item}</span>
                            ))}
                        </div>
                    );
                }
            }
            dispatch(setRefreshData(true));
            dispatch(cleanCheckedItems());
            dispatch(setLoading(false));
        } else {
            let response = "";
            if (!mutationFile) {
                response = await executeVendureQuery(
                    getVendureApiData(),
                    { queryBody: mutation },
                    typeof aditionalHeader === "object" ? aditionalHeader : {}
                );
                if (response && response.data && !response.data.errors) {
                    if (mutationName) {
                        let mutationObject = response.data[mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (executeAnotherQuery) {
                            let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                            if (executeAnotherQuery.useResponsePrevQueryData) {
                                let param = executeAnotherQuery.useResponsePrevQueryData;
                                params[param.name] = _.get(response.data, param.path);
                            }
                            dispatch(
                                setExecuteQuery({
                                    action: executeAnotherQuery.action,
                                    params: params,
                                })
                            );
                        }
                    }
                } else {
                    errorMutation = true;
                }
            } else {
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false,
                    className: "use-toast",
                    closeButton: false,
                    closeOnClick: false,
                    draggable: false,
                    hideProgressBar: true,
                    icon: false,
                });

                response = await uploadVendureMutation(getVendureApiData(), {
                    queryBody: mutation,
                    file: mutationFile.file,
                });

                if (response && response.data && !response.data.errors) {
                    if (mutationFile.mutationName) {
                        let mutationObject = response.data[mutationFile.mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (
                            mutationObject instanceof Array &&
                            mutationObject.length > 0 &&
                            executeAnotherQuery
                        ) {
                            executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject[0] };
                        }
                    }
                } else {
                    errorMutation = true;
                }
            }
        }
        if (!errorMutation && !multipleMutations) {
            if (mutationFile && !errorMutation) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });

                if (executeAnotherQuery) {
                    dispatch(
                        setExecuteQuery({
                            action: executeAnotherQuery.action,
                            params: executeAnotherQuery.params,
                        })
                    );
                }
            } else if (!avoidToast) {
                toast.success(t("operation-successful"));
            }
            if (!avoidRefresh) {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
            if (redirectOnSuccessUrl) {
                redirect(redirectOnSuccessUrl);
            }
        } else if (!multipleMutations) {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

const redirect = (path) => {
    window.location.href = path;
};

export default withApollo(UpdateListProducts);
