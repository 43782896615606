import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GetHotelTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import { useMSQuery } from "../../useMSQuery";
import { gql } from "apollo-boost";
import { POLLINTERVAL_15S } from "../../../Utils/Utils";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setTableResults,
    setRefreshData,
    setActions,
    setExtendedInfo,
    addToastInfoItem,
    removeToastInfoItem,
    setRowGroups,
} from "../../../../actions/tableActions";
import { getInputGroup } from "../../../Utils/Hotel/Monitor/ListDevicesUtils";
import { toast } from "react-toastify";
import InfoToast from "../../../../components/Section/Hotel/Monitor/InfoToast";
import Device from "../Monitoring/models/Device";
import _ from "lodash";

const RoomDetailDevices = (props) => {
    //Consts&states
    const { roomId } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentPage, perPage, refreshData, toastInfoItems } = useSelector((state) => state.table);
    const { guest } = useSelector((state) => state.sectionContent);
    const sectionName = "list-room-devices";
    const { permissions } = useSelector((state) => state.ui);
    const castEnabled = permissions && permissions.product.cast ? true : false;

    //queries&mutations
    const GET_DATA = gql`
        {
            devices: devices (page:${currentPage}, size:${perPage}, filter:{roomID:${roomId}} ){
                info {
                    count
                    page
                    size
                }
                results {
                    name
                    commercialName
                    function
                    mac
                    ref
                    type
                    wifiSignal
                    firmwareVersion
                    model
                    kind
                    warning{
                        info
                        level
                        roomID
                        actionInfo
                    }
                    location
                    roomID
                    roomTVID
                    roomTVName
                    roomNumber
                    lastActivity
                    lastActivityDate
                    tvInputID
                    ip
                    instructions
                }
            }
            tvInputs: tvInputs{
                results {
                    id
                    name
                    isDefault
                    inputs {
                        name
                        input
                        deviceType
                    }
                }
            }
        }
    `;

    //useQueries&useMutations
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    //effects
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.devices && networkStatus === 7) {
            dispatch(setLoading(true));
            const dataItems = arrangeData(data.devices.results, t);
            const tableHeaders = GetHotelTableHeaders(sectionName);
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.devices.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setHeaders(tableHeaders));
            dispatch(setRowGroups(true));
            dispatch(setActions(true));
            dispatch(setExtendedInfo(true));
            dispatch(setLoading(false));
            checkWarnings(data.devices.results);
        }
        // eslint-disable-next-line
    }, [data, networkStatus, guest]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    //arrangeData
    const arrangeData = (devicesData, t) => {
        const items = [];
        const tvInputs = data.tvInputs && data.tvInputs.results ? data.tvInputs.results : [];
        const categoriesPrevAdded = [];
        devicesData.map((deviceData) => {
            if (!deviceData.roomTVName) {
                deviceData.roomTVName = "";
            }
        });
        const devices = _.orderBy(devicesData, ["roomTVName"], ["asc"]);
        devices.map((device) => {
            let inputGroupData = getInputGroup(device.tvInputID, tvInputs);
            let categoryConfig = {
                useCategoryGroup: true,
                categoriesPrevAdded: categoriesPrevAdded,
                categoryName: "roomTVName",
            };
            items.push(
                Device(
                    {
                        device: device,
                        inputGroupData: inputGroupData,
                        categoryConfig: categoryConfig,
                        sectionName: sectionName,
                        castEnabled: castEnabled,
                        tvInputs: tvInputs,
                    },
                    t,
                    permissions
                )
            );
            if (device[categoryConfig.categoryName]) {
                categoriesPrevAdded.push(device[categoryConfig.categoryName]);
            }
        });
        return items;
    };

    //handle&functions
    const checkWarnings = (items) => {
        items.forEach((device) => {
            if (
                device &&
                device.warning &&
                device.warning.info === "running-task" &&
                !toastInfoItems.includes(device.mac)
            ) {
                dispatch(addToastInfoItem(device.mac));
                const message = device.name + " " + t("task-" + device.warning.actionInfo);
                toast.info(<InfoToast msg={message} />, {
                    autoClose: 10000,
                });
                setTimeout(() => {
                    dispatch(removeToastInfoItem(device.mac));
                }, 60000);
            }
        });
    };

    //renders

    //response
    return <></>;
};

export default RoomDetailDevices;
