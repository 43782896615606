import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ProductSettingsExtrasCardItem from "./Extras/ProductSettingsExtrasCardItem";
import { SalesContextDeprecated } from "contexts/Sales";

function ProductSettingsExtrasCard() {
    const { id: shopId, productId } = useParams();
    const { t } = useTranslation();

    const { data: dataContext } = useContext(SalesContextDeprecated);

    const currencyShop = dataContext?.channel?.currencyCode ? dataContext.channel.currencyCode : "";

    return (
        <div className="w-full my-2">
            <div className="flex items-center w-full justify-between mb-5">
                <span className="font-bold text-gray-900 text-1xl">{t("product-extras")}</span>
                <Link to={`/services/sales/shop/${shopId}/product/${productId}/extras`}>
                    <span className={`icon icon-edit cursor-pointer text-1xl`}></span>
                </Link>
            </div>
            <div className="w-full text-left mb-4">
                <span className="">{t("edit-extra-options")}</span>
            </div>
            <div>
                {dataContext?.extras?.map((item) => {
                    return <ProductSettingsExtrasCardItem itemData={item} key={item.id} currencyCode={currencyShop} />;
                })}
            </div>
        </div>
    );
}

export default ProductSettingsExtrasCard;
