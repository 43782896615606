import MediaLibrarySidebar from "../components/Sidebar/MediaLibrarySidebar";
import TemplatePreview from "../components/Section/Design/TemplatePreview";
import Templates from "../components/Section/Design/Templates";
import Designs from "../components/Section/Design/Designs";
import { MobileApparences } from "../components/Section/Design/MobileApparences";
import MediaLibrary from "../components/Section/MediaLibrary/MediaLibrary";
import FileDetail from "../components/Section/MediaLibrary/FileDetail";
import ScreensEditor from "../components/Section/Design/ScreensEditor";
import Appearances from "../components/Section/Design/Appearances/Appearances";
import AppearanceWithMenu from "../components/Section/Design/Appearances/AppearanceWithMenu";
import Fonts from "../components/Section/MediaLibrary/Fonts";
import FontStyles from "../components/Section/MediaLibrary/FontStyles";
import Tags from "../components/Section/Design/Tags";
import TagDetail from "../components/Section/Design/Tags/TagDetail";
import { newRoute, newRedirect } from "./utils";
import { Session } from "hooks/Utils/Session";

const sectionID = "design";
const packageID = "design";

const attrTags = "tags";
const attrLibrary = "library";
const attrThemes = "appearances";
const attrEditor = "interfaceEditor";
const attrTemplates = "templates";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const designRoutes = [
    route("/", {
        redirect: redirect("/interface/list"),
    }),

    route("/appearances/redirect", {
        redirect: redirect("/appearances/list"),
        attribute: attrThemes,
    }),
    route("/appearances/list", {
        hideSidebar: true,
        hideHeader: true,
        section: Appearances,
        attribute: attrThemes,
    }),
    route("/appearances/view", {
        section: AppearanceWithMenu,
        attribute: attrThemes,
    }),

    route("/interface/", {
        redirect: redirect("/interface/list"),
        attribute: attrEditor,
    }),
    route("/interface/list", {
        section: Designs,
        attribute: attrEditor,
    }),
    route("/interface/templates", {
        section: Templates,
        attribute: attrTemplates,
    }),
    route("/interface/template/preview/:id", {
        hideSidebar: true,
        hideHeader: true,
        section: TemplatePreview,
        attribute: attrTemplates,
    }),
    route("/interface/mobile", {
        section: MobileApparences,
        attribute: "mobileAppearance",
    }),

    route("/library", {
        section: MediaLibrary,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),
    route("/library/menu", {
        section: MediaLibrary,
        attribute: attrLibrary,
    }),
    route("/library/fonts/:sideBarRef", {
        section: Fonts,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),
    route("/library/fonts/:sideBarRef/:fontRef", {
        section: FontStyles,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),
    route("/library/:sideBarRef", {
        section: MediaLibrary,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),
    route("/library/:parentRef/:sideBarRef", {
        section: MediaLibrary,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),
    route("/library/:parentRef/:ref/:sideBarRef", {
        section: FileDetail,
        sidebar: MediaLibrarySidebar,
        attribute: attrLibrary,
    }),

    route("/tags", {
        section: Tags,
        attribute: attrTags,
    }),
    route("/tags/detail/:id", {
        section: TagDetail,
        attribute: attrTags,
    }),

    route("/:id/", {
        section: ScreensEditor,
        attribute: attrEditor,
    }),
    route("/:id/screen/:type/:screenid/:lang", {
        hideSidebar: true,
        hideHeader: true,
        section: ScreensEditor,
        attribute: attrEditor,
    }),
    route("/:id/screen/:type/:screenid/:lang/:landingid", {
        hideSidebar: true,
        hideHeader: true,
        section: ScreensEditor,
        attribute: attrEditor,
    }),
];
