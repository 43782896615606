import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { capitalizeFirst, composeDestination, removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { useParams } from "react-router-dom";
import { getProjectLangs } from "../../Utils/SalesUtils";

const UpdateGridChannels = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values, itemsAffected, actionData } = useSelector((state) => state.action);
    const { checkedItems, results } = useSelector((state) => state.table);
    const { projectLangs } = useSelector((state) => state.ui);
    const { gridId } = useParams();
    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            switch (executeQuery.action) {
                case "add-grid-channels":
                    execute = true;
                    setMutation(`
                    mutation {
                        createTuneGrid(
                          name: "${values["name-grid-channel"]}"
                          ${results?.length === 0 ? "destination:{all:true}" : ""}
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    executeQuery.mutationName = "createTuneGrid";
                    break;
                case "delete-grid":
                    execute = true;
                    setMutation(`
                      mutation {
                        deleteTuneGrid(ids: [${itemsAffected[0]}]) {
                          error
                          id
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "deleteTuneGrid";
                    break;
                case "delete-grids-channels":
                    const idsToDelete = filterIdsByStateEnabled(results, checkedItems);
                    execute = true;
                    setMutation(`
                      mutation {
                        deleteTuneGrid(ids: [${idsToDelete.join(",")}]) {
                          error
                          id
                          ok
                        }
                      }
                      `);
                    executeQuery.mutationName = "deleteTuneGrid";
                    break;
                case "remove-grid-from-group":
                    execute = true;
                    setMutation(`
                    mutation {
                        deleteTuneGroupFromGrid(tuneGridID: ${gridId}, tuneGroupID: ${values["id-group"]} ) {
                          error
                          id
                          ok
                        }
                      }
                      
                    `);
                    executeQuery.mutationName = "deleteTuneGroupFromGrid";
                    break;
                case "add-filter":
                    execute = true;
                    setMutation(`
                    mutation {
                        updateTuneGrid(
                          id: ${itemsAffected[0]}
                          name: "${values["name-grid-channel"]}"
                          destination: ${composeDestination({ values: values })}
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    executeQuery.mutationName = "updateTuneGrid";
                    break;
                case "add-group-to-grid":
                    execute = true;
                    const { langDefault } = getProjectLangs(projectLangs, t);
                    if (values["option-selected"] !== "new-group") {
                        setMutation(`
                    mutation {
                        addTuneGroupToGridBatch(
                            tuneGroupIDs:[ ${values["select-group-list-channels"].join(",")}]
                            tuneGridID: ${gridId}
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                        executeQuery.mutationName = "addTuneGroupToGridBatch";
                    } else {
                        setMutation(`mutation {
                        createTuneGroup(
                          name: [
                            { lang: "${langDefault}", text: "${values["name-group"]}" }
                          ]
                          tuneIDs: []
                        ) {
                          id
                          error
                          ok
                        }
                      }`);

                        executeQuery.mutationName = "createTuneGroup";
                        executeQuery.preventToast = true;
                        executeQuery.multipleQuery = "crete-group-and-add-to-grid";
                    }
                    break;
                case "change-position-group-in-grid":
                    execute = true;
                    setMutation(`
                      mutation{
                        setGroupOrderInGrid(
                            tuneGroupID: ${executeQuery.params.idGroup}
                            tuneGridID: ${gridId}
                            pos: ${executeQuery.params.newIndex + 1}
                            ){
                                id
                                error
                                ok
                            }
                      }
                      `);
                    executeQuery.mutationName = "setGroupOrderInGrid";
                    break;
                case "make-grid-enabled":
                    execute = true;
                    setMutation(`mutation {
                      updateTuneGrid(
                        id: ${values["id-grid"] ? values["id-grid"] : 0}
                        destination: { all: true }
                      ) {
                        id
                        error
                        ok
                      }
                    }
                    `);
                    executeQuery.mutationName = "updateTuneGrid";
                    break;
                case "crete-group-and-add-to-grid":
                    execute = true;
                    setMutation(`
                    mutation {
                        addTuneGroupToGridBatch(
                            tuneGroupIDs:[ ${executeQuery.params.id}]
                            tuneGridID: ${gridId}
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    executeQuery.mutationName = "addTuneGroupToGridBatch";
                    break;
                case "edit-grid-name":
                    execute = true;
                    setMutation(`mutation {
                    updateTuneGrid(
                      id: ${gridId}
                      name:"${values["edit-the-name-of-the-grid"]}"
                    ) {
                      id
                      error
                      ok
                    }
                  }
                  `);
                    executeQuery.mutationName = "updateTuneGrid";
                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            if (executeQuery.multipleQuery) {
                dispatch(
                    setExecuteQuery({
                        action: executeQuery.multipleQuery,
                        params: { id: data[executeQuery.mutationName].id ? data[executeQuery.mutationName].id : null },
                    })
                );
            } else {
                dispatch(setExecuteQuery(null));
            }

            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateGridChannels);

const filterIdsByStateEnabled = (data, idsToFilter) => {
    const filteredIds = data
        .filter((obj) => idsToFilter.includes(obj.id) && obj.isDefault === false)
        .map((obj) => obj.id);
    return filteredIds;
};
