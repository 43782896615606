import React, { useLayoutEffect } from "react";
import MenuSideBar from "./MenuSideBar";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showMainMenu } from "../../actions/uiActions";

import Sitemap from "../../sitemap";
import { UseSectionMap } from "./utils";

export default function ShopSidebar() {
    const { id, productId } = useParams();
    const dispatch = useDispatch();

    const { getItems } = UseSectionMap();

    useLayoutEffect(() => {
        dispatch(showMainMenu(false));
        return () => {
            dispatch(showMainMenu(true));
        };
        // eslint-disable-next-line
    }, []);
    const { permissions } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;
    let menuItems = getItems("sales", Sitemap.sales);
    menuItems.map((item) => {
        item.to = item.to.replace(":id", id).replace(":productId", productId);
        if (item.redirectOptions) {
            item.redirectOptions = item.redirectOptions.map((option) =>
                option.replace(":id", id).replace(":productId", productId)
            );
        }
        return item;
    });

    if (!hasOrdersManagement) {
        menuItems = menuItems.filter((item) => item.icon !== "icon-add-guest");
    }

    return (
        <>
            <MenuSideBar menuItems={menuItems} />
        </>
    );
}
