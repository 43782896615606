import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import UseInputText from "../Table/useInputText";
import { setActionInput } from "../../actions/globalActions";
import { getProjectLangs } from "../../hooks/Utils/SalesUtils";

const UseNewProduct = () => {
    const dispatch = useDispatch();
    const [langDefault, setLangDefault] = useState("");
    const { projectLangs } = useSelector((state) => state.ui);
    const { validations } = useSelector((state) => state.action);

    const { t } = useTranslation();

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
            const dataToAction = { "default-language": getProjectLangs(projectLangs, t).langDefault };
            dispatch(setActionInput(dataToAction));
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    return (
        <div>
            <div>
                <p className=" font-bold"> {t("name") + " *"} </p>
                <div className=" w-full flex items-center">
                    <div className=" w-5/12">{`${t("language:" + langDefault)} ( ${t("default-language")} )`}</div>
                    <div className=" w-9/12">
                        <UseInputText
                            inputData={{ name: "product-lang-default" }}
                            validation={validations["product-lang-default"] === false ? false : true}
                            currentValue={""}
                            id={"product-lang-default"}
                            key={langDefault}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseNewProduct;
