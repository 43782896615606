import React, { useEffect, useRef } from "react";
import Render from "zrender/render";
import { Session } from "../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../hooks/Utils/Utils";

export const wifiTexts = (t) => {
    return {
        googleButton: t("connect-with-google"),
        acceptTerms: t("accept terms", { terms: "{{terms}}" }),
        termsAndPrivacy: t("terms & policy", { terms: "{{terms}}", privacy: "{{privacy}}" }),
        terms: capitalizeFirst(t("terms & conditions")),
        privacy: capitalizeFirst(t("privacy-policy")),
        marketing: t("i-authorise-commercial-communication"),
        submit: capitalizeFirst(t("connect")),
        loginWithEmail: capitalizeFirst(t("login-with-email")),
        types: {
            email: capitalizeFirst(t("email")),
            pms: capitalizeFirst(t("room-surname")),
            code: capitalizeFirst(t("passcode")),
            open: capitalizeFirst(t("open-access")),
        },
        labels: {
            room: capitalizeFirst(t("room")),
            name: capitalizeFirst(t("name")),
            birth: capitalizeFirst(t("birthdate")),
            country: capitalizeFirst(t("country")),
            surname: capitalizeFirst(t("surname")),
            email: capitalizeFirst(t("email")),
            passcode: capitalizeFirst(t("passcode")),
        },
        placeholders: {
            room: t("example something", { something: "102" }),
            name: t("example something", { something: "John" }),
            surname: t("example something", { something: "Smith" }),
            email: t("example something", { something: "smith@gmail.com" }),
            passcode: t("example something", { something: "ayJ8kk" }),
            birth: "DD/MM/YYYY",
            country: capitalizeFirst(t("select country")),
        },
    };
};

const Widget = ({ id, type, device, deviceWidth, config, style, texts, lang, screenType }) => {
    const renderRef = useRef(new Render(type));
    const render = renderRef.current;
    const { t: tr } = useTranslation();

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const widgetRef = useRef(null);

    const className = getClassName(type, screenType);
    const printWidget = () => {
        const node = widgetRef.current;
        const widget = render.run();

        if (type === "TOPBAR" && (screenType === "MOBILE" || screenType === "GENERAL")) {
            widget.style.height = "100%";
        }

        if (node) {
            while (node.firstChild) {
                node.removeChild(node.firstChild);
            }
            if (widget) {
                node.appendChild(widget);
            }
        }
    };

    useEffect(() => {
        render.DAS = () => Session.getDasUrl();
        render.setDevice(device, deviceWidth);
        render.updateConfig(config);
        render.setStyle(style);
        render.updateTexts(texts || (type === "WIFILOGIN" ? wifiTexts(t) : null));
        printWidget();
    }, [type, device, deviceWidth, config, style, texts]);

    return <div ref={widgetRef} id={id} className={className}></div>;
};

export default Widget;

const getClassName = (type, screenType) => {
    let className = "relative";

    if (type === "TOPBAR") {
        if (screenType === "MOBILE" || screenType === "GENERAL") {
            className += " h-14";
        }
    } else {
        className += " overflow-hidden w-full h-full";
    }

    return className;
};
