import { timeFromUTC } from "date";
import React from "react";
import { useTranslation } from "react-i18next";
const UseSchedulingCell = ({ cellData }) => {
    const { t } = useTranslation();
    const scheduleDate = new Date(cellData?.scheduleDate);

    const printDays = (days) => {
        if (days) {
            const values = {
                1: "sunday",
                2: "monday",
                3: "tuesday",
                4: "wednesday",
                5: "thursday",
                6: "friday",
                7: "saturday",
            };
            let parseNumberDaysToDays = days?.map((val) => values[val]);
            return (
                <>
                    {parseNumberDaysToDays.map((val, index) => {
                        return (
                            <p key={`day-${val}`}>
                                {index > 0 ? "-" : ""}
                                {t(`${val}-short-version`)}
                            </p>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <div className="flex">
                {cellData?.scheduleType === "SPECIFIC_DAY" ? (
                    <div className="flex flex-col">
                        <p>
                            {scheduleDate.toLocaleDateString(undefined, {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                            })}
                        </p>
                        <p>{`${scheduleDate.getHours().toString().padStart(2, "0")}:${scheduleDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`}</p>
                    </div>
                ) : (
                    <div className="flex flex-col">
                        <div className="flex gap-2">
                            <div className="flex">{printDays(cellData?.scheduleDaysOfWeek)}</div>
                        </div>
                        <p>{timeFromUTC(cellData?.scheduleTime)}</p>
                    </div>
                )}
            </div>
        </>
    );
};

export default UseSchedulingCell;
