export const GetAssociatedPropertiesTableHeaders = (section, corporateAccess) => {
    let res = [];
    let properties = {
        name: "properties",
    };
    let location = { name: "location" };
    let phoneNumber = { name: "phone-number" };
    switch (section) {
        case "associated-properties":
            properties.specialWidth = "w-1/4";
            location.specialWidth = "w-1/4";
            phoneNumber.specialWidth = "w-1/4";
            if (!corporateAccess) {
                properties.iconTooltip = {
                    tooltip: "recommended-resolution-1280-720",
                    icon: "info",
                };
            }

            res.push(properties, location, phoneNumber);
            if (!corporateAccess) {
                res.push({ specialWidth: "w-1/8" }, { specialWidth: "w-1/8" });
            } else {
                res.push({ specialWidth: "w-1/4" });
            }
            break;
    }
    return res;
};
