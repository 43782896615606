import React, { useContext, useEffect, useState } from "react";

import { useSelector } from "react-redux";

//Componentes
import EmptyWidget from "./WidgetToolbars/CommonOptions/EmptyWidget";

//Utils
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { vw2px, GetThemeDefault } from "../../../../hooks/Utils/DesignUtils";
import Widget from "../Widgets/Widget";
import { EditorContext, WidgetContext } from "../../../../contexts/editor";

const WidgetIcon = () => {
    const { deviceType, editorSize, theme } = useContext(EditorContext);
    const { type, id: widgetID } = useContext(WidgetContext);

    const [iconsReady, setIconsReady] = useState(false);
    const { gridItems } = useSelector((state) => state.design);

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

    const size = (s) => vw2px(s, editorSize ? editorSize.width : null, deviceType === "Mobile" ? 3 : 1);

    const defaultBgColor = GetThemeDefault({ theme, id: "bgColor" });
    const defaultFgColor = GetThemeDefault({ theme, id: "fgColor" });

    const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
    const bgColor = style && style.bgColor ? style.bgColor : defaultBgColor;
    const fgColor = style && style.fgColor ? style.fgColor : defaultFgColor;
    const radius = style && style.radius ? style.radius : 0;

    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const icon =
        data && data.icon
            ? {
                  icon: data.icon,
                  lib: data.lib || "fa",
              }
            : null;
    const iconSize = data && data.size ? data.size : 5;

    useEffect(() => {
        document.querySelector(`#widget_zone_${widgetID}`).style.backgroundColor = "transparent";
        //document.querySelector(`#widget_zone_${widgetID}`).style.borderRadius = 0;
        // eslint-disable-next-line
    }, [gridItems]);

    useEffect(() => {
        library.add(fas, fab);
        setIconsReady(true);
    }, []);

    return (
        <>
            {iconsReady && icon ? (
                <div
                    className="flex h-full items-center justify-center"
                    style={{
                        fontSize: size(iconSize) + "px",
                        backgroundColor: bgColor,
                        color: fgColor,
                        borderRadius: radius + "rem",
                    }}
                >
                    {icon && icon.icon ? <FontAwesomeIcon icon={[icon.lib, icon.icon]} /> : null}
                </div>
            ) : (
                <EmptyWidget type={type}></EmptyWidget>
            )}
        </>
    );
};

export default WidgetIcon;
