import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLazyQuery } from "react-apollo";
import {
    MUTATION_TRANSLATIONS,
    QUERIES_TRANSLATIONS,
    TYPE_QUERY,
    getAllLangs,
    getDefaultLang,
    getOtherLangs,
    handleTranslationsChange,
    getDefaultTranslation,
} from "../utils/QueriesTranslations";
import { gql } from "apollo-boost";
import { useDispatch, useSelector } from "react-redux";
import Translations from "components/Translations";
import Loading from "components/Loading";
import TextInput from "components/TextInput";
import { toast } from "react-toastify";
import { useMutation } from "react-apollo";
import { setRefreshContentData } from "actions/sectionActions";

const SalesModalTranslationsContent = ({
    QUERY,
    VARIABLES,
    onClickSave,
    setOnClickSave,
    close: closeModal,
    setOnLoading,
}) => {
    const { projectLangs } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const defaultLang = useMemo(() => getDefaultLang(projectLangs), [projectLangs]);
    const allLangs = useMemo(() => getAllLangs(projectLangs), [projectLangs]);
    const otherLangs = useMemo(() => getOtherLangs(projectLangs), [projectLangs]);

    const [translations, setTranslations] = useState([]);
    const [internalLoading, setInternalLoading] = useState([]);

    const { data } = useFetchTranslationsZafiro({ QUERY, VARIABLES, setInternalLoading });

    const { mutateTranslations } = useMutationTranslationsZafiro({
        MUTATION: MUTATION_TRANSLATIONS[QUERY],
        setInternalLoading,
        closeModal,
    });

    const translationsRef = useRef();

    useEffect(() => {
        if (data?.length > 0) {
            setTranslations(data);
        }
    }, [data]);

    useEffect(() => {
        setOnLoading(internalLoading);
    }, [internalLoading]);

    useEffect(() => {
        if (onClickSave) {
            if (getDefaultTranslation(translations, defaultLang)?.trim() === "") {
                toast.error(t("input error"));
            } else if (QUERY === TYPE_QUERY.SHOP) {
                mutateTranslations({
                    variables: {
                        ...VARIABLES,
                        name: translations.map((t) => {
                            return {
                                lang: t.languageCode,
                                text: t.name,
                            };
                        }),
                    },
                });
                setInternalLoading(true);
            }

            setOnClickSave(false);
        }
    }, [onClickSave]);

    const onTranslationsChange = (newTranslations) => {
        handleTranslationsChange(allLangs, newTranslations, setTranslations, false, false, translations);
    };

    return internalLoading ? (
        <Loading />
    ) : (
        <>
            <div className=" mb-4">{t("edit-name-translations-shop")}</div>
            <div className=" mb-4">{t("default-language")}</div>
            <div className=" flex items-start space-x-5">
                <div className=" min-w-24 text-base font-bold">{t("language:" + defaultLang)}</div>
                <div className=" w-full">
                    <TextInput
                        id={`sales-modals-translations-default-lang`}
                        className="w-full"
                        value={getDefaultTranslation(translations, defaultLang)}
                        required={true}
                        onChange={(name) => {
                            if (translationsRef.current) {
                                translationsRef.current.changeTranslation(name, defaultLang);
                            }
                        }}
                    />
                </div>
            </div>

            <Translations
                ref={translationsRef}
                id={`sales-modals-translations`}
                languages={otherLangs}
                defaultLang={defaultLang}
                placeholder="Enter translation"
                includeDefault={false}
                design="modal"
                onChange={(translations) => {
                    onTranslationsChange(translations);
                }}
                translations={translations}
                templateContainerClassName="flex justify-end"
            />
        </>
    );
};

const useMutationTranslationsZafiro = ({ MUTATION, setInternalLoading, closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [mutateTranslations] = useMutation(gql(MUTATION), {
        onCompleted: () => {
            setTimeout(() => {
                setInternalLoading(false);
                closeModal();
                toast.success(t("operation-successful"));
                dispatch(setRefreshContentData(true));
            }, 1000);
        },
        onError: (error) => {
            toast.error(error.message);
            setInternalLoading(false);
            dispatch(setRefreshContentData(true));
            closeModal();
        },
    });

    return { mutateTranslations };
};

const useFetchTranslationsZafiro = ({ QUERY, VARIABLES, setInternalLoading }) => {
    const [fetchTranslations, { data: dataTranslations, loading, error }] = useLazyQuery(
        gql(QUERIES_TRANSLATIONS[QUERY]),
        {
            fetchPolicy: "network-only",
            onCompleted: () => {
                setTimeout(() => {
                    setInternalLoading(false);
                }, 1000);
            },
        }
    );

    const [data, setData] = useState([]);

    useEffect(() => {
        fetchTranslations({ variables: VARIABLES });
    }, [QUERY]);

    useEffect(() => {
        if (QUERY === TYPE_QUERY.SHOP && dataTranslations?.shops?.results?.[0]?.nameTranslations) {
            const transformedData = dataTranslations.shops.results[0].nameTranslations.map((translation) => ({
                languageCode: translation.lang,
                name: translation.text,
            }));
            setData(transformedData);
        }
    }, [dataTranslations]);

    return { data, loading, error };
};

const ModalContent = (props) => {
    const [onClickSave, setOnClickSave] = useState(false);
    const [onLoading, setOnLoading] = useState(true);
    const { t } = useTranslation();

    const newProps = {
        setOnClickSave,
        onClickSave,
        setOnLoading,
        ...props,
    };

    return (
        <Modal
            title={t(props?.title)}
            footer={
                <>
                    {onLoading ? null : (
                        <>
                            <Button design="blue-outline" id="modal-button-cancel" onClick={props?.close}>
                                {t("cancel")}
                            </Button>
                            <Button
                                design="blue"
                                id="modal-button-save"
                                onClick={() => {
                                    setOnClickSave(true);
                                }}
                            >
                                {t("save")}
                            </Button>
                        </>
                    )}
                </>
            }
            minWidth="20vw"
        >
            <SalesModalTranslationsContent {...newProps} />
        </Modal>
    );
};

export const useSalesModalZafiroTranslations = () => {
    const { open, close } = useModal();

    const openModal = (props) => {
        const newProps = {
            close,
            ...props,
        };

        open(<ModalContent {...newProps} />);
    };

    return {
        open: openModal,
        close,
    };
};
