import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate as useHistory } from "react-router-dom";
import { GlobalContext } from "contexts/Global";
//API

//Actions
import { setModalContent, openModal, setDropdownMenu } from "../../actions/uiActions";
import { setActionName } from "../../actions/globalActions";

//Components
import ChangePassword from "./Modals/ChangePassword";
import useComponentVisible from "../../components/useComponentVisible";

//Utils
import { Session } from "../../hooks/Utils/Session";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

const UserDropdown = () => {
    const { lang, setLang } = useContext(GlobalContext);

    const location = useLocation();
    const { t } = useTranslation();
    const { dropdownOpened } = useSelector((state) => state.ui);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //Components
    const history = useHistory();

    //States
    const [profileInitials, setProfileInitials] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [languageSelector, setLanguageSelector] = useState(false);
    const [options, setOptions] = useState([]);
    const [langOptions, setLangOptions] = useState([]);

    //Data
    const profilePicture = null;
    const emailStored = Session.getSessionProp("userEmail");
    const nameStored = Session.getSessionProp("userFullname");
    const changeLang = "language-change";

    //Actions
    const dispatch = useDispatch();

    const clickHandler = (e) => {
        if (e.currentTarget.dataset.action === "close-session") {
            Session.close();
            history("/login");
        } else if (e.currentTarget.dataset.action === "select-lang") {
            setLanguageSelector(true);
        } else if (e.currentTarget.dataset.action === "change-password") {
            showModal("change-password");
        } else if (e.currentTarget.dataset.action === "change-profile") {
            history("/select-profile");
        }
    };

    const changeLangHandler = (e) => {
        const newLang = e?.currentTarget?.dataset?.value;
        if (newLang && newLang !== lang) {
            toast.success(t("language changed to x", { name: t("language:" + newLang) }));
            setLang(newLang);
        }
        clickEvent(false);
        setLanguageSelector(false);
    };

    const backToInitial = () => {
        setLanguageSelector(false);
    };

    const showModal = (action) => {
        setIsComponentVisible(false);
        dispatch(setActionName(action));
        dispatch(setModalContent(ChangePassword(t)));
        //Launch modal
        dispatch(openModal());
    };

    const isImpersonateOrSelectProfile =
        location.pathname === "/impersonate-list" || location.pathname === "/select-profile";

    useEffect(() => {
        const SUData = Session.getSessionProp("superUserData");
        if (isImpersonateOrSelectProfile && SUData) {
            const { email, userFullname } = JSON.parse(SUData);
            Session.setSessionProp("userEmail", email);
            Session.setSessionProp("userFullname", userFullname);
            setEmail(email);
            setProfileInitials(getProfileInitials(userFullname));
            setName(userFullname);
        } else {
            if (nameStored) {
                setProfileInitials(getProfileInitials(nameStored));
                setName(nameStored);
            }
            if (emailStored) {
                setEmail(emailStored);
            }
        }
    }, [location, nameStored, emailStored]);

    useEffect(() => {
        setOptions([
            {
                action: "select-lang",
                label: `${t("lang")}: ${t("language:" + lang)}`,
                chevron: true,
            },
            {
                action: "change-password",
                label: t("change-password"),
            },
            Session.getSessionProp("superUser") && !location.pathname.includes("select-profile")
                ? {
                      action: "change-profile",
                      label: t("change profile"),
                  }
                : {},

            {
                action: "close-session",
                label: t("close-session"),
            },
        ]);
        //TODO read languages from package.json
        //TODO show native name
        setLangOptions([
            {
                action: changeLang,
                label: t("language:es"),
                value: "es",
            },
            {
                action: changeLang,
                label: t("language:en"),
                value: "en",
            },
        ]);
        // eslint-disable-next-line
    }, [lang]);

    const clickEvent = (visible) => {
        setIsComponentVisible(visible);
        if (visible) {
            dispatch(setDropdownMenu("user"));
        } else {
            dispatch(setDropdownMenu(null));
        }
    };
    useEffect(() => {
        if (dropdownOpened !== "user") {
            setIsComponentVisible(false);
        }
        // eslint-disable-next-line
    }, [dropdownOpened]);

    return (
        <div className="relative" ref={ref}>
            <div
                className="items-center flex cursor-pointer"
                id="profile-button"
                onClick={() => {
                    clickEvent(!isComponentVisible);
                    setLanguageSelector(false);
                }}
            >
                <span className="h-11 w-11 text-white inline-flex items-center justify-center rounded-full bg-zafiro-400 hover:border-zafiro-400 hover:border-2">
                    {profilePicture ? (
                        <img
                            alt={profileInitials}
                            className="h-full w-full rounded-full align-middle border-none shadow-xl"
                            src={profilePicture}
                        />
                    ) : (
                        profileInitials
                    )}
                </span>
            </div>
            {isComponentVisible ? (
                <>
                    <div className="absolute mt-3 pt-2 bg-white shadow-xl rounded-md right-0 w-fit-content min-w-64">
                        {!languageSelector ? (
                            <>
                                <div className="w-full p-4 pt-2 border-b border-gray-200 flex items-center">
                                    <div className="w-3/14 inline-block">
                                        <div className="h-14 w-14 text-white flex items-center justify-center rounded-full bg-zafiro-400 hover:border-zafiro-400 hover:border-2">
                                            {profilePicture ? (
                                                <img
                                                    alt={profileInitials}
                                                    className="h-full w-full rounded-full align-middle border-none shadow-lg"
                                                    src={profilePicture}
                                                />
                                            ) : (
                                                profileInitials
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-fit-content text-left inline-block pl-4">
                                        <div className="font-bold text-gray-900 w-12/12 block">{name}</div>
                                        {email ? <div className="text-gray-500 w-12/12 block">{email}</div> : null}
                                    </div>
                                </div>
                                {options.map((option, index) =>
                                    option.action ? (
                                        <div
                                            onClick={clickHandler}
                                            data-action={option.action}
                                            key={`${option.action}-${option.label}`}
                                            id={`menu-option-${index}`}
                                        >
                                            <div className="dropdown-item pr-8">
                                                <div className="w-full inline-block first-capital">{option.label}</div>
                                                {option.chevron ? (
                                                    <div className="inline-block icon-chevron-right"></div>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null
                                )}
                            </>
                        ) : (
                            <>
                                <div className="w-full p-4 pt-2 border-b border-gray-200 flex items-center">
                                    <div className="w-full">
                                        <div
                                            className="w-2/12 icon-chevron-left inline-block hover:text-blue-200"
                                            onClick={backToInitial}
                                            id="back-to-initial-menu-lang"
                                        ></div>
                                        <div className="w-10/12 inline-block text-left font-bold first-capital">
                                            {`${t("select")} ${t("your")} ${t("lang")}`}
                                        </div>
                                    </div>
                                </div>
                                {langOptions.map((option, index) => (
                                    <div
                                        onClick={changeLangHandler}
                                        data-value={option.value}
                                        key={`${option.value}-${option.label}`}
                                        id={`lang-option-${index}`}
                                    >
                                        <div className="w-full dropdown-item">
                                            <div className="w-2/12 inline-block"></div>
                                            {lang === option.value ? (
                                                <>
                                                    <div className="w-9/12 inline-block first-capital">
                                                        {option.label}
                                                    </div>
                                                    <div className="w-1/12 icon-tick-right inline-block"></div>
                                                </>
                                            ) : (
                                                <div className="w-10/12 inline-block first-capital">{option.label}</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </>
            ) : null}
        </div>
    );
};

const getProfileInitials = (name) => {
    //Returns profile initials
    let res;
    if (name.includes(" ")) {
        const fullNameArray = name.split(" ");
        res = fullNameArray[0].substring(0, 1) + fullNameArray[1].substring(0, 1);
    } else {
        res = name.substring(0, 1);
    }
    return res;
};

export default UserDropdown;
