import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";
import Icon from "components/Icon";

import { GlobalContext } from "contexts/Global";
import { useEditCurrencyAndTaxesModal } from "../../modals/useEditCurrencyAndTaxesModal";

const ShopSettingsCurrencyAndTaxes = () => {
    const { t } = useTranslation();

    const { id } = useParams();

    const { lang } = useContext(GlobalContext);

    const { currency, defaultTax, allCurrencies, allTaxes, allTaxZones, updateConfig } = useContext(ShopContext);

    const { open: openModalEditCurrencyAndTaxesModal } = useEditCurrencyAndTaxesModal();

    const handleCurrencyAndTaxes = () => {
        openModalEditCurrencyAndTaxesModal({
            allCurrencies,
            allTaxes,
            allTaxZones,
            values: {
                currencyCode: currency?.code,
                defaultTaxZoneId: defaultTax?.zone?.id,
                defaultTaxId: defaultTax?.id,
                defaultTaxCategoryId: defaultTax?.category?.id,
                id: id,
            },
        });
    };
    const noConfig = (
        <div className="flex items-center space-x-2">
            <Icon type="warning" size="xl" />
            <span>{t("no-data-is-set-yet")}</span>
        </div>
    );

    return (
        <SettingsCard
            title={t("currency-and-taxes")}
            edit={{
                id: "shop-settings-button-open-modal-currency-and-taxes",
                onClick: handleCurrencyAndTaxes,
            }}
        >
            <SettingsCard.Section
                title={t("currency")}
                description={currency ? `${currency.symbol} (${currency.getName(lang)})` : noConfig}
                id="shop-settings-currency"
            />
            <SettingsCard.Section
                id="shop-settings-taxes"
                title={t("taxes-zone")}
                description={defaultTax?.zone?.name || noConfig}
            />
        </SettingsCard>
    );
};

export default ShopSettingsCurrencyAndTaxes;
