import React, { useState, useEffect } from "react";
import UseSectionHeader from "../../../useSectionHeader";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import UseButton from "../../../useButton";
import UseInputDraftEditableTagsField from "../../../Inputs/UseInputDraftEditableTagsField";
import { useLazyQuery } from "react-apollo";
import UpdateAutomaticMessages from "../../../../hooks/GraphqlCalls/Sales/UpdateAutomaticMessages";
import { gql } from "apollo-boost";
import UseLoading from "../../../Table/useLoading";
import { setExecuteQuery, setRefreshContentData } from "../../../../actions/sectionActions";
import { getProjectLangs } from "../../../../hooks/Utils/SalesUtils";

const GeneralSettingsAutomaticMessagesEdition = () => {
    const { type } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const sectionName = "general-settings-automatic-messages-edition";
    const [title, setTitle] = useState("");
    const [langs, setLangs] = useState([]);
    const [langDefault, setLangDefault] = useState("en");
    const [subjectsByLang, setSubjectsByLang] = useState({});
    const [contentByLang, setContentByLang] = useState({});
    const [initialContent, setInitialContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [placeholderContent, setPlaceholderContent] = useState("");
    const [placeholderSubject, setPlaceholderSubject] = useState("");

    const GET_MESSAGES = gql(`
    query{
        shopMessages{
          results{
            content{
              lang
              text
            }
            enabled
            subject{
              lang
              text
            }
            type
          }
        }
      }
    `);

    const [executeQuery, { data, refetch }] = useLazyQuery(GET_MESSAGES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        setLoading(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (contentByLang[langDefault]) {
            setPlaceholderContent(contentByLang[langDefault]);
        }
        if (subjectsByLang[langDefault]) {
            setPlaceholderSubject(subjectsByLang[langDefault]);
        }
        // eslint-disable-next-line
    }, [contentByLang, subjectsByLang]);

    useEffect(() => {
        if (data && data.shopMessages && data.shopMessages.results) {
            const content = getInfoMessageByType(data.shopMessages.results, getTypeMessage(type), "content");
            const subject = getInfoMessageByType(data.shopMessages.results, getTypeMessage(type), "subject");

            if (content instanceof Array) {
                setInitialContent(convertArrayToObject(content));
                setContentByLang(convertArrayToObject(content));
            }

            if (subject instanceof Array) {
                setSubjectsByLang(convertArrayToObject(subject));
            }

            // setTimeout(() => {
            setLoading(false);
            // }, 1000);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (type) {
            setTitle(type);
        }
        // eslint-disable-next-line
    }, [type]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault, langs } = getProjectLangs(projectLangs, t);
            setLangs(langs);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    const breadcrumbs = [
        {
            name: "sales",
            translate: true,
            route: "/services/sales",
        },
        {
            name: "general-settings",
            translate: true,
            route: "/services/sales/general-settings",
        },
        {
            name: "automatic-messages",
            translate: true,
            route: `/services/sales/general-settings/automatic-messages/${type}`,
        },
    ];

    return (
        <div>
            <UseSectionHeader navToPreviousPage={true} title={t(`${title}-message`)} customBreadCrumbs={breadcrumbs} />
            <div className=" w-full rounded bg-white p-6 relative">
                <span className="">{t(getHeaderKeyTitle(type))}</span>
                <div className=" flex w-full mt-6 pb-36">
                    <div className=" w-1/2 border-r mr-4 border-gray-200 ">
                        <span className=" block font-bold text-lg mb-11">{capitalizeFirst(t("default-language"))}</span>
                        <div className=" px-6 flex">
                            <div className=" w-2/12">
                                <span className=" mt-8 block font-bold">
                                    {capitalizeFirst(t("language:" + langDefault))}
                                </span>
                            </div>
                            <div className=" w-10/12">
                                <div className=" mb-2 w-full flex flex-col ">
                                    <span className=" text-sm font-bold text-gray-800 block mb-2">
                                        {capitalizeFirst(t("subject"))}
                                    </span>
                                    <input
                                        className=" bg-gray-200 rounded py-2 px-4"
                                        type="text"
                                        value={subjectsByLang[langDefault] ? subjectsByLang[langDefault] : ""}
                                        onChange={(e) =>
                                            setSubjectsByLang({ ...subjectsByLang, [langDefault]: e.target.value })
                                        }
                                        id={`subject-message-${langDefault}`}
                                    ></input>
                                </div>
                                <div>
                                    <span className=" text-sm font-bold text-gray-800 block mb-2">
                                        {capitalizeFirst(t("content"))}
                                    </span>
                                    <UseInputDraftEditableTagsField
                                        parentSectionName={`${sectionName}-default-lang`}
                                        id={`content-message-${langDefault}`}
                                        setTextState={(val) => {
                                            setContentByLang({ ...contentByLang, [langDefault]: val });
                                        }}
                                        defaultTextState={
                                            initialContent[langDefault] ? initialContent[langDefault] : ""
                                        }
                                        listTagsFixed={true}
                                        typeTags="sales"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" w-1/2">
                        <span className=" font-bold text-lg ml-2">{capitalizeFirst(t("translations"))}</span>
                        <div
                            id="langs-input-container"
                            className=" w-full pl-8 pr-6 py-6 mt-2 border border-gray-300 rounded"
                            style={{ minHeight: "22.71rem", maxHeight: "22.71rem", overflow: "scroll" }}
                        >
                            {langs.map((lang) => {
                                return (
                                    <div key={lang.languageRef} className=" mb-5 flex">
                                        <div style={{ width: "14.606%" }}>
                                            <span className=" mt-8 block font-bold">
                                                {capitalizeFirst(t("language:" + lang.languageRef))}
                                            </span>
                                        </div>
                                        <div style={{ width: "85.394%" }} className=" w-10/12">
                                            <div className=" mb-2 w-full flex flex-col ">
                                                <span className=" text-sm font-bold text-gray-800 block mb-2">
                                                    {capitalizeFirst(t("subject"))}
                                                </span>
                                                <input
                                                    className=" bg-gray-200 rounded py-2 px-4"
                                                    type="text"
                                                    value={
                                                        subjectsByLang[lang.languageRef]
                                                            ? subjectsByLang[lang.languageRef]
                                                            : ""
                                                    }
                                                    onChange={(e) =>
                                                        setSubjectsByLang({
                                                            ...subjectsByLang,
                                                            [lang.languageRef]: e.target.value,
                                                        })
                                                    }
                                                    placeholder={placeholderSubject}
                                                    id={`subject-message-${lang.languageRef}`}
                                                ></input>
                                            </div>
                                            <div>
                                                <span className=" text-sm font-bold text-gray-800 block mb-2">
                                                    {capitalizeFirst(t("content"))}
                                                </span>
                                                <UseInputDraftEditableTagsField
                                                    id={`content-message-${lang.languageRef}`}
                                                    parentSectionName={`${sectionName}-${lang.languageRef}`}
                                                    setTextState={(val) => {
                                                        setContentByLang({ ...contentByLang, [lang.languageRef]: val });
                                                    }}
                                                    defaultTextState={
                                                        initialContent[lang.languageRef]
                                                            ? initialContent[lang.languageRef]
                                                            : ""
                                                    }
                                                    textPlaceholder={placeholderContent}
                                                    placeholder={capitalizeFirst(`${t("write content")}...`)}
                                                    idParentContainer="langs-input-container"
                                                    listTagsFixed={true}
                                                    typeTags="sales"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className=" w-full h-10 ">
                    <div className=" flex justify-end">
                        <UseButton
                            buttonName={"cancel"}
                            action={() => {
                                dispatch(
                                    setExecuteQuery({
                                        action: "discard-message",
                                    })
                                );
                            }}
                            adjust=" mr-4"
                            buttonColor="btn-white"
                            id={"discard-message-button"}
                        />
                        <UseButton
                            action={() => {
                                dispatch(
                                    setExecuteQuery({
                                        action: "save-msg",
                                    })
                                );
                            }}
                            buttonName={"save"}
                            adjust="px-3"
                            buttonColor="btn-blue"
                            id={"save-message-button"}
                        />
                    </div>
                </div>
                {loading ? (
                    <div className=" flex items-center z-205 bg-white justify-center absolute h-full w-full top-0 left-0">
                        <UseLoading adjust={"contents"} />
                    </div>
                ) : null}
            </div>
            <UpdateAutomaticMessages subject={subjectsByLang} content={contentByLang} type={type} />
        </div>
    );
};

const getTypeMessage = (type) => {
    if (type === "instant-success") {
        return "INSTANT_SUCCESS";
    } else if (type === "scheduled-success") {
        return "SCHEDULED_SUCCESS";
    } else if (type === "accepted") {
        return "ACCEPTED";
    } else if (type === "ready") {
        return "READY";
    } else if (type === "deleted") {
        return "DELETED";
    } else if (type === "delivery-failed") {
        return "DELIVERY_FAILED";
    }
};

const getHeaderKeyTitle = (type) => {
    if (type === "instant-success") {
        return "following-message-immediate-delivery";
    } else if (type === "scheduled-success") {
        return "following-message-scheduled delivery";
    } else if (type === "accepted") {
        return "following-message-accepted";
    } else if (type === "ready") {
        return "following-message-pick-up";
    } else if (type === "deleted") {
        return "following-message-canceled";
    } else if (type === "delivery-failed") {
        return "following-message-failed-delivery";
    }
};

function convertArrayToObject(arr) {
    return arr.reduce((acc, item) => {
        acc[item.lang] = item.text;
        return acc;
    }, {});
}

const getInfoMessageByType = (array, type, field) => {
    const result = array.find((item) => item.type === type);
    if (result) {
        return result[field];
    }
    return null;
};

export default GeneralSettingsAutomaticMessagesEdition;
