import React from "react";
import { useTranslation } from "react-i18next";

import { Session } from "hooks/Utils/Session";
import NotFound from "components/Section/NotFound";

const OldManager = () => {
    const { t } = useTranslation();

    const configData = Session.getLSProp("oldManagerConfig");
    const config = configData ? JSON.parse(configData) : {};

    const urlData = config?.url
        ? {
              path: config.url + "manager/index.php",
              autoLogin: !config.users,
          }
        : null;

    return (
        <>
            {urlData ? (
                <iframe title="manage3.8.3" className="w-full h-full absolute" src={makeURL(urlData, config)}></iframe>
            ) : (
                <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
                    <NotFound title={t("Old manager link not found")} desc={t("error-mutation")} />
                </div>
            )}
        </>
    );
};

const makeURL = (url, config) => {
    if (!url) {
        return "";
    }
    return `${window.location.origin}${url.path}${
        url?.autoLogin && config?.tokenCloud ? `?tokenCloud=${config.tokenCloud}` : ""
    }`;
};

export default OldManager;
