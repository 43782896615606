import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Session } from "hooks/Utils/Session";
import { setToken, setImpersonated } from "actions/authActions";

const Impersonated = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const { impersonated } = useSelector((state) => state.auth);

    const stopImpersonation = () => {
        const superUserData = JSON.parse(Session.getSessionProp("superUserData"));
        dispatch(setToken(superUserData.token));
        dispatch(setImpersonated(null));
        Session.endImpersonation();
        history("/impersonate-list");
    };

    return (
        <>
            {impersonated ? (
                <div id="impersonated" className="relative z-100  top-0 w-full bg-orange-100 h-12">
                    <div className=" h-12 z-10 top-0 items-center flex justify-center w-full">
                        <div className="first-capital">{t("impersonated user")}:</div>
                        <div className="ml-2 font-bold">{impersonated.fullName}</div>
                    </div>
                    <div className="absolute h-12 z-10 top-0 right-0 items-center flex mr-4 clickable underline font-bold">
                        <div onClick={() => stopImpersonation()} className="first-capital">
                            {t("stop impersonating")}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Impersonated;
