import React, { useEffect, useState } from "react";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseTable from "components/Table";
import { AssociatedProperties } from "hooks/GraphqlCalls/Hotel/models/AssociatedProperties";
import { GetAssociatedPropertiesTableHeaders } from "hooks/Utils/Table/Headers/GetAssociatedPropertiesTableHeaders";
import { useDispatch, useSelector } from "react-redux";
import {
    setLoading,
    setSearchable,
    setHeaders,
    setTableResults,
    setCount,
    setCustomConfig,
    setCustomError,
} from "actions/tableActions";
import { Session } from "hooks/Utils/Session";
import { useParams, Link } from "react-router-dom";
import { ACTIONS_MOBILE_SETTINGS } from "./MobileSettingsUtils";
import { setExecuteQuery } from "actions/sectionActions";

export const MobileAssociatedProperties = ({ data }) => {
    const projectsData = JSON.parse(Session.getSessionProp("projects"));
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sectionName = "associated-properties";
    const corporateAccess = Session.getSessionProp("projectRef") === "CORPORATE";
    const projectRef = !corporateAccess ? Session.getSessionProp("projectRef") : "";
    const { activeFilters } = useSelector((state) => state.table);
    const [projects, setProjects] = useState([]);
    const [isEdited, setIsEdited] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (!activeFilters?.search) {
            const ownProject = !corporateAccess ? data?.projects.find((val) => val.ref == projectRef) : null;
            let restProjects = !corporateAccess
                ? data?.projects.filter((val) => val.ref != projectRef)
                : data?.projects;

            restProjects = restProjects?.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            });

            if (ownProject) {
                setProjects([ownProject, ...restProjects]);
            } else {
                setProjects(restProjects);
            }
        }
    }, [data, activeFilters]);

    useEffect(() => {
        if (activeFilters?.search) {
            setProjects((projectsList) => {
                return projectsList.filter((val) =>
                    val.name.toLowerCase().includes(activeFilters?.search.toLowerCase())
                );
            });
        }
    }, [activeFilters]);

    useEffect(() => {
        //Get Data
        const dataItems = arrangeData(data);
        const tableHeaders = GetAssociatedPropertiesTableHeaders(sectionName, corporateAccess);
        //Add to store
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        dispatch(setTableResults(dataItems));
        dispatch(setLoading(false));
        dispatch(setCount(projects.length));
        dispatch(
            setCustomConfig({
                searchText: "search-for-properties",
                searchStyle: "col-span-3",
                filtersStyle: "col-span-9",
            })
        );
        dispatch(setCustomError(t("no-properties-yet")));

        // eslint-disable-next-line
    }, [projects]);

    useEffect(() => {
        const dataItems = arrangeData(data);
        dispatch(setTableResults(dataItems));
    }, [isEdited]);

    const updatePropertyData = (idProperty, image) => {
        dispatch(
            setExecuteQuery({
                action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_PROPERTY_INFORMATION,
                params: { data: { id: idProperty, image } },
            })
        );
    };

    const arrangeData = (data) => {
        return projects.map((val, index) => {
            return AssociatedProperties(
                val,
                projectsData,
                corporateAccess,
                data?.isMultiProperty,
                index,
                t,
                projectRef,
                setIsEdited,
                isEdited,
                updatePropertyData
            );
        });
    };
    return (
        <div className=" bg-white p-6">
            <div className="text-xl flex justify-between border-b border-gray-200">
                <p className="font-bold ">{capitalizeFirst(t("associated-properties"))}</p>
                {corporateAccess && (
                    <Link
                        id="go-to-associated-propeties-link"
                        to={`/all-properties/mobile-apps/${id}/associated-properties`}
                    >
                        <button
                            id="go-to-associated-propeties-btn"
                            className="text-gray-800 icon icon-edit focus:outline-none"
                        ></button>
                    </Link>
                )}
            </div>
            <p className=" mt-4">
                {t(
                    corporateAccess && data?.isMultiProperty
                        ? "write-the-phone-number-associated-to-the-whatsapp-and-add-image-of-each-one-of-them"
                        : "write-the-phone-number-associated-to-the-whatsapp-and-add-image-of-your-property"
                )}
            </p>
            <UseTable />
        </div>
    );
};
