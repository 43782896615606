import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Session } from "../../../hooks/Utils/Session";

//Actions
import { openModal, setModalContent, showGlobalLoading } from "../../../actions/uiActions";
import { changeGlobalAction, cleanAction } from "../../../actions/globalActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { setRefreshData, setReload } from "../../../actions/tableActions";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";

//Utils
import { capitalizeFirst, formatBytes } from "../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UseTabs from "../../useTabs";
import UseFile from "../../useFile";
import UseButton from "../../useButton";
import NewAsset from "../../../hooks/GraphqlCalls/MediaLibrary/modals/NewAsset";
import UpdateMediaLibrary from "../../../hooks/GraphqlCalls/MediaLibrary/UpdateMediaLibrary";
import UseEditableField from "../../../components/useEditableField";
import UseIcon from "../../useIcon";
import UseToast from "../../Notifications/useToast";
import { arrangeToastMessagesUploadingFiles } from "../../../hooks/Utils/DesignUtils";

const AssetDetail = ({ fileRef, projectLangs, assetName, setAssetName, parentRef, sideBarRef }) => {
    const { t } = useTranslation();

    //Actions
    const dispatch = useDispatch();

    //Store
    const { values, actionName, itemsAffected } = useSelector((state) => state.action);
    const { refreshData } = useSelector((state) => state.sectionContent);

    //States
    const [resetInputs, setResetInputs] = useState(false);
    const [languagesOptions, setLanguagesOptions] = useState(null);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    // const [defaultContentType, setDefaultContentType] = useState(null);
    const [assetLanguages, setAssetLanguages] = useState(null);
    const [lastUpdateInterfaz, setLastUpdateInterfaz] = useState(window.ZDate.now());
    const [updateName, setUpdateName] = useState({ status: 0, value: "" });

    //Variables
    const hasCorporateUser =
        Session.getSessionProp("hasCorporateUser") && Session.getSessionProp("hasCorporateUser") !== "false";
    const isLangEditable = (ref) =>
        hasCorporateUser || ref === "" || (projectLangs && projectLangs.indexOf(ref) !== -1);

    const GET_INFO = gql`
    {
        libraryAsset: libraryAsset(ref:"${fileRef}"){
			error,
			ok,
			response{
				contentType,
				defaultLanguage,
				folderPath,
				folderRef,
				items,
				languages{
					contentType,
					dimensions{
                        height
                        width
                    },
					imageFormat,
					language,
					lastModified,
					name,
					size,
				},
				lastModified,
				name,
				ref,
				size
			}
        }
    }
    `;
    const [executeQuery, { data }] = useLazyQuery(GET_INFO, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(capitalizeFirst(t("mutation-error")));
        },
    });

    //Functions
    const replaceAsset = async (lang, name) => {
        const modal = NewAsset(null, fileRef, lang, null, name);
        dispatch(
            changeGlobalAction({
                actionName: "media-library-update-media",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const createAsset = async (lang) => {
        let prefix = `files${lang}`;
        dispatch(showGlobalLoading(true));
        if (values[prefix] && values[prefix].length > 0) {
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            let filesUploading = [];
            let transcodingVideos = false;
            for (let i = 0; i < values[prefix].length; i++) {
                let f = values[prefix][i];
                let file = f.file;
                filesUploading.push({
                    name: file.name,
                    status: 1,
                    errorMessage: "",
                    size: f && f.file && f.file.size ? f.file.size : 0,
                });
            }
            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
            let toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false,
                className: "use-toast",
            });
            for (let i = 0; i < values[prefix].length; i++) {
                let f = values[prefix][i];
                let file = f.file;
                let typeFile = f.type;
                if (typeFile === "image") typeFile = "asset";
                const uploadUrl = Session.getUploadUrl(
                    `?type=${typeFile}&token=${Session.getSessionProp("token").replace("Bearer ", "")}&ref=${fileRef}`
                );
                const formData = new FormData();
                formData.append("file", file);
                formData.append("fileName", file.name);
                formData.append("fileLanguage", lang);
                try {
                    const uploadResponse = await fetch(uploadUrl, {
                        method: "POST",
                        body: formData,
                    }).then((uploadResponse) => uploadResponse.json());
                    if (uploadResponse && uploadResponse.ref) {
                        dispatch(setRefreshData(true));
                        dispatch(setReload(true));
                        filesUploading[i].status =
                            typeFile === "video" && uploadResponse.streamStatus !== "COMPLETE" ? 1 : 2;
                        [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    } else {
                        filesUploading[i].status = 4;
                        filesUploading[i].errorMessage =
                            uploadResponse && uploadResponse.error && uploadResponse.error.message
                                ? uploadResponse.error.message
                                : "";
                        console.error(filesUploading[i].errorMessage);
                        [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    }
                } catch (err) {
                    console.log(err);
                    filesUploading[i].status = 4;
                    [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                }
                if (filesUploading[i].status === 1) transcodingVideos = true;
                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={true} />,
                });
            }
            setTimeout(function () {
                if (!transcodingVideos) {
                    [title, msgs] = arrangeToastMessagesUploadingFiles(t, filesUploading, values[prefix].length);
                    toast.update(toastId, {
                        render: <UseToast title={title} msgs={msgs} minimize={false} />,
                        position: toast.POSITION.BOTTOM_RIGHT,
                        closeButton: true,
                        icon: false,
                        className: "use-toast",
                    });
                }
            }, 500);
        } else {
            dispatch(showGlobalLoading(false));
        }
    };

    const downloadAsset = async (lang) => {
        const link = document.createElement("a");
        link.href = Session.getDasUrl(fileRef + "?download");
        if (lang !== defaultLanguage) {
            link.href += `&lang=${lang}`;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const deleteAsset = async (lang, name, isMain) => {
        let modal = {
            id: fileRef,
            title: "media-library-delete-permanently",
            value: name,
            text: isMain ? "media-library-delete-all-permanently-text" : "media-library-delete-permanently-text",
            useGlobalLoading: true,
            inputs: [
                {
                    text: "",
                    name: "language",
                    value: lang,
                    type: "text",
                    cStyle: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete-permanently",
                    style: "red",
                    action: "media-library-delete-permanently",
                },
            ],
        };
        dispatch(
            changeGlobalAction({
                actionName: "media-library-delete-permanently",
                itemsAffected: fileRef,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const makeAsDefaultAsset = async (lang, name) => {
        let modal = {
            id: fileRef,
            title: "make-default",
            value: name,
            text: "media-library-make-to-default-text",
            useGlobalLoading: true,
            inputs: [
                {
                    text: "",
                    name: "defaultLanguage",
                    value: lang,
                    type: "text",
                    cStyle: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "accept",
                    style: "blue",
                    action: "media-library-update-media",
                },
            ],
        };
        dispatch(
            changeGlobalAction({
                actionName: "media-library-update-media",
                itemsAffected: fileRef,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    useEffect(() => {
        if (values["assetDefaultLanguage"] && values["assetDefaultLanguage"].length > 0) {
            if (!actionName && !itemsAffected) {
                if (defaultLanguage !== null && defaultLanguage !== values["assetDefaultLanguage"][0]) {
                    if (values["assetDefaultLanguage"][0] === "" && assetLanguages && assetLanguages.length > 1) {
                        toast.error(t("unable to set multi-language"));
                        setResetInputs(true);
                        setTimeout(() => setResetInputs(false));
                    } else {
                        dispatch(showGlobalLoading(true));
                        dispatch(
                            setExecuteQuery({
                                action: "media-library-update-media",
                                params: {
                                    id: fileRef,
                                    lang: values["assetDefaultLanguage"][0],
                                },
                            })
                        );
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [values]);

    const arrangeDataLangs = (assetLangs) => {
        let langs = [];
        if (projectLangs) {
            langs = [...projectLangs];
        }
        if (assetLangs) {
            // eslint-disable-next-line
            assetLangs.map((lang) => {
                if (langs.indexOf(lang.language) === -1) {
                    langs.push(lang.language);
                }
            });
        }
        let response = [];
        response.push({
            id: "",
            name: t("multi-language"),
            selected: false,
        });
        if (langs.length > 0) {
            // eslint-disable-next-line
            langs.forEach((languageRef) => {
                const name = t("language:" + languageRef);
                if (languageRef) {
                    response.push({
                        id: languageRef,
                        name: name || languageRef,
                        selected: false,
                    });
                }
            });
        }
        return response;
    };

    const handleChangeUpdateNameStatus = (new_status) => {
        setUpdateName((updateName) => ({ ...updateName, status: new_status }));
    };

    const handleChangeName = (e) => {
        const { value } = e.target;
        setUpdateName((updateName) => ({ ...updateName, value: value }));
    };

    /*const handlerTriggerUpdateNameBlur = () => {
        if (inputUpdateName && inputUpdateName.current && inputUpdateName.current.value) {
            if (inputUpdateName.current.value != assetName) {
                handleChangeUpdateNameStatus(2);
            } else {
                handleChangeUpdateNameStatus(0);
            }
        } else {
            handleChangeUpdateNameStatus(0);
        }
    };*/

    //listeners
    useEffect(() => {
        executeQuery();
        dispatch(showGlobalLoading(true));
        dispatch(cleanAction());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            //refetch();
            executeQuery();
            dispatch(setRefreshContentData(false));
            setLastUpdateInterfaz(window.ZDate.now());
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data) {
            const assetLangs =
                (data.libraryAsset && data.libraryAsset.response && data.libraryAsset.response.languages
                    ? data.libraryAsset.response.languages
                    : null) || [];
            const assetDefaultLang =
                (data.libraryAsset && data.libraryAsset.response && data.libraryAsset.response.defaultLanguage
                    ? data.libraryAsset.response.defaultLanguage
                    : null) || "";
            const assetName =
                (data.libraryAsset && data.libraryAsset.response && data.libraryAsset.response.name
                    ? data.libraryAsset.response.name
                    : null) || "";

            setDefaultLanguage(assetDefaultLang);
            setAssetName(assetName);
            setAssetLanguages(assetLangs);
            setLanguagesOptions(arrangeDataLangs(assetLangs));
            dispatch(showGlobalLoading(false));
            setUpdateName((updateName) => ({ ...updateName, value: assetName }));

            if (data.libraryAsset && !data.libraryAsset.ok && data.libraryAsset.response == null) {
                window.location = "#/design/library/" + parentRef + "/" + sideBarRef;
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (defaultLanguage !== null) {
            // eslint-disable-next-line
            languagesOptions
                .filter((option) => option.id === defaultLanguage)
                .map((option) => {
                    let obj = option;
                    obj["selected"] = true;
                    return option;
                });
        }
        // eslint-disable-next-line
    }, [defaultLanguage]);

    useEffect(() => {
        if (updateName.status === 0) {
            setUpdateName((updateName) => ({ ...updateName, value: assetName }));
        } else if (updateName.status === 1) {
            /*inputUpdateName.current = document.querySelector("#UpdateName_input");
            if (inputUpdateName) {
                inputUpdateName.current.focus();
                inputUpdateName.current.onblur = handlerTriggerUpdateNameBlur;
            }
            const listener = (event) => {
                if (event.code == "Enter" || event.code == "NumpadEnter") {
                    //event.preventDefault();
                    if (inputUpdateName.current) {
                        inputUpdateName.current.blur();
                    }
                } else if (event.code == "Escape") {
                    handleChangeUpdateNameStatus(0);
                }
            };
            document.addEventListener("keydown", listener);
            return () => {
                document.removeEventListener("keydown", listener);
            };*/
        } else if (updateName.status === 2) {
            dispatch(showGlobalLoading(true));
            dispatch(
                setExecuteQuery({
                    action: "media-library-update-media",
                    params: {
                        id: fileRef,
                        name: updateName.value,
                        defaultLanguage: "notC",
                    },
                })
            );
            handleChangeUpdateNameStatus(0);
        }
        // eslint-disable-next-line
    }, [updateName.status]);

    const _renderAssetLanguageDefault = (assetName) => {
        let response = [];
        let extension = "";
        let splitExtension = null;
        if (defaultLanguage !== null) {
            if (languagesOptions && languagesOptions.length > 0) {
                languagesOptions
                    .filter((language) => language.id === defaultLanguage)
                    // eslint-disable-next-line
                    .map((lang) => {
                        let language = null;
                        if (assetLanguages && assetLanguages.length > 0) {
                            assetLanguages
                                .filter((l) => l.language === lang.id)
                                // eslint-disable-next-line
                                .map((lang) => {
                                    language = lang;
                                });
                        }
                        extension = "";
                        if (language && language.contentType) {
                            splitExtension = language.contentType.split("/");
                            if (splitExtension && splitExtension.length >= 2) extension = splitExtension[1];
                        }

                        const defaultLangInfo = lang.id ? languagesOptions.filter((l) => l.id === lang.id)[0] : null;

                        response.push(
                            <>
                                <div className="w-full flex">
                                    <div className="w-6/12 h-96">
                                        <img
                                            alt=""
                                            src={
                                                language
                                                    ? `${Session.getDasUrl(
                                                          fileRef +
                                                              "?lang=" +
                                                              lang.id +
                                                              "&w=633&h=336&lastUpdateInterfaz=" +
                                                              lastUpdateInterfaz
                                                      )}`
                                                    : require(`../../../assets/images/icons/image.svg`)
                                            }
                                            className={`${
                                                language ? `max-h-full max-w-full` : `h-full w-full`
                                            } m-auto pl-5 pr-5 bg-gray-100`}
                                        ></img>
                                    </div>
                                    <div className="w-6/12">
                                        <div className="w-12/12">
                                            <h1 className={`section-title first-capital mb-5`}>
                                                {capitalizeFirst(t("file-information"))}
                                            </h1>
                                            {updateName.status === 0 ? (
                                                <>
                                                    <p className={`mb-5 truncate max-w-80 float-left`}>
                                                        <span className="float-left font-bold text-gray-900 first-capital overflow-hidden cursor-pointer pr-1">
                                                            {capitalizeFirst(t("name"))}:
                                                        </span>{" "}
                                                        {assetName ? assetName : language ? language.name : null}
                                                    </p>
                                                    {assetName || language ? (
                                                        <div
                                                            className={`float-left pt-1 mr-1`}
                                                            id={`edit-button-name-asset-detail`}
                                                            onClick={() => {
                                                                handleChangeUpdateNameStatus(1);
                                                            }}
                                                        >
                                                            <UseIcon
                                                                icon={{ name: "edit" }}
                                                                adjust="t-icon-headers text-gray-800"
                                                            />
                                                        </div>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <p className={`mb-5`}>
                                                    <span className="float-left font-bold text-gray-900 first-capital overflow-hidden cursor-pointer pr-1 mb-3">
                                                        {capitalizeFirst(t("name"))}:
                                                    </span>{" "}
                                                    <input
                                                        placeholder={capitalizeFirst(t("name"))}
                                                        type="text"
                                                        className={`float-left w-5/12 bg-gray-200 rounded hover:text-zafiro-400  text-gray-900  p-2 pl-4 phNoTransform -mt-2 mb-3`}
                                                        name="UpdateName"
                                                        id="UpdateName_input"
                                                        onChange={handleChangeName}
                                                        value={updateName.value}
                                                    ></input>
                                                    <div
                                                        className="float-left mx-3 -mt-2 mb-3"
                                                        onClick={() => {
                                                            handleChangeUpdateNameStatus(0);
                                                        }}
                                                    >
                                                        <UseButton buttonName="cancel" buttonColor="btn-white" />
                                                    </div>
                                                    <div
                                                        className="float-left -mt-2 mb-3"
                                                        onClick={() => {
                                                            handleChangeUpdateNameStatus(2);
                                                        }}
                                                    >
                                                        <UseButton buttonName="save" buttonColor="btn-blue" />
                                                    </div>
                                                </p>
                                            )}
                                            <p className={`mb-5 clear-left`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("file-type"))}:
                                                </span>{" "}
                                                {(extension + "").toUpperCase()}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("last-modified"))}:
                                                </span>{" "}
                                                {language
                                                    ? `${new window.ZDate(
                                                          language.lastModified
                                                      ).toLocaleDateString()} ${new window.ZDate(
                                                          language.lastModified
                                                      ).toLocaleString([], {
                                                          hour: "2-digit",
                                                          minute: "2-digit",
                                                      })}`
                                                    : null}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("file-size"))}:
                                                </span>{" "}
                                                {language ? formatBytes(language.size) : null}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("dimensions"))}:
                                                </span>{" "}
                                                {language && language.dimensions
                                                    ? `${language.dimensions.width} X ${language.dimensions.height} `
                                                    : null}
                                            </p>
                                            <h1 className={`section-title first-capital mb-5`}>
                                                {capitalizeFirst(t("lang"))}
                                            </h1>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden float-left pt-2">
                                                    {capitalizeFirst(t("file-language"))}
                                                </span>
                                                {!resetInputs && isLangEditable(lang.id) ? (
                                                    <UseEditableField
                                                        data={{
                                                            value: lang.id,
                                                            inEditionMode: true,
                                                            type: "select-with-search",
                                                            oneSelected: true,
                                                            optionData: languagesOptions.filter((item) => {
                                                                if (
                                                                    item.id &&
                                                                    !isLangEditable(item.id) &&
                                                                    (isLangEditable(defaultLanguage) ||
                                                                        defaultLanguage !== item.id)
                                                                ) {
                                                                    return false;
                                                                }
                                                                return item;
                                                            }),
                                                            styleAdjust: "float-left w-3/12 mx-2",
                                                            selectedIds: [lang.id],
                                                            name: "assetDefaultLanguage",
                                                            label: "assetDefaultLanguage",
                                                            noLabel: true,
                                                        }}
                                                    ></UseEditableField>
                                                ) : (
                                                    <strong className="float-left p-2 mr-2">
                                                        {defaultLangInfo ? defaultLangInfo.name : t("multi-language")}
                                                    </strong>
                                                )}
                                                <p className="text-gray-800 pt-2 capitalize">{t("default-file")}</p>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex pl-5 mt-3">
                                    <div
                                        id={`replace-button-asset-detail`}
                                        onClick={() => {
                                            if (isLangEditable(lang.id)) {
                                                replaceAsset(lang.id, assetName ? assetName : null);
                                            }
                                        }}
                                        className={
                                            "inline-block" +
                                            (isLangEditable(lang.id) ? " t-link cursor-pointer" : " text-gray-500")
                                        }
                                    >
                                        <div className="first-capital" data-lang="">
                                            {capitalizeFirst(t("replace"))}
                                        </div>
                                    </div>
                                    {language ? (
                                        <>
                                            &nbsp;&nbsp;&nbsp;
                                            <span className="text-gray-200">|</span>
                                            &nbsp;&nbsp;&nbsp;
                                            <div
                                                id={`download-button-asset-detail`}
                                                onClick={() => {
                                                    downloadAsset(lang.id);
                                                }}
                                                className="inline-block cursor-pointer"
                                            >
                                                <div className="t-link first-capital">
                                                    {capitalizeFirst(t("download"))}
                                                </div>
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <span className="text-gray-200">|</span>
                                            &nbsp;&nbsp;&nbsp;
                                            <div
                                                id={`delete-button-asset-detail`}
                                                onClick={() => {
                                                    if (isLangEditable(lang.id)) {
                                                        deleteAsset(null, language.name, true);
                                                    }
                                                }}
                                                className={
                                                    "inline-block" +
                                                    (isLangEditable(lang.id)
                                                        ? " t-link text-red-100 cursor-pointer"
                                                        : " text-gray-500")
                                                }
                                            >
                                                <div className="first-capital">
                                                    {capitalizeFirst(t("delete-permanently"))}
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </>
                        );
                    });
            }
        }
        return response;
    };
    const _renderAssetLanguages = () => {
        let response = [];
        let titles = [];
        let contents = [];
        if (languagesOptions && languagesOptions.length > 0 && defaultLanguage && defaultLanguage !== "") {
            languagesOptions
                .filter((lang) => lang.id !== defaultLanguage && lang.id !== "")
                // eslint-disable-next-line
                .map((lang) => {
                    const languageRef = lang.id;
                    const title = lang.name;
                    const isEditable = isLangEditable(languageRef);
                    let assetLang = null;
                    let extension = "";
                    let splitExtension = null;
                    if (assetLanguages && assetLanguages.length > 0) {
                        assetLanguages
                            .filter((language) => language.language === languageRef)
                            // eslint-disable-next-line
                            .map((lang) => {
                                assetLang = lang;
                            });
                    }
                    titles.push({
                        name: title,
                        disabled: !isEditable,
                        image: assetLang
                            ? require(`../../../assets/images/icons/save-circle-` +
                                  (isEditable ? "green" : "gray") +
                                  `.svg`)
                            : null,
                    });
                    if (assetLang && assetLang.contentType) {
                        splitExtension = assetLang.contentType.split("/");
                        if (splitExtension && splitExtension.length >= 2) extension = splitExtension[1];
                    }
                    if (assetLang) {
                        contents.push(
                            <>
                                <div className="w-full flex">
                                    <div className="w-6/12 h-96">
                                        <img
                                            alt=""
                                            src={
                                                assetLang
                                                    ? `${Session.getDasUrl(
                                                          fileRef +
                                                              "?lang=" +
                                                              assetLang.language +
                                                              "&w=633&h=336&lastUpdateInterfaz=" +
                                                              lastUpdateInterfaz
                                                      )}`
                                                    : require(`../../../assets/images/icons/image.svg`)
                                            }
                                            className={`${
                                                assetLang ? `max-h-full max-w-full` : `h-full w-full`
                                            } m-auto pl-5 pr-5 bg-gray-100`}
                                        ></img>
                                    </div>
                                    <div className="w-6/12">
                                        <div className="w-12/12">
                                            <h1 className={`section-title first-capital mb-5`}>
                                                {capitalizeFirst(t("file-information"))}
                                            </h1>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden cursor-pointer">
                                                    {capitalizeFirst(t("name"))}:
                                                </span>{" "}
                                                {assetLang ? assetLang.name : null}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("file-type"))}:
                                                </span>{" "}
                                                {(extension + "").toUpperCase()}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("last-modified"))}:
                                                </span>{" "}
                                                {assetLang
                                                    ? `${new window.ZDate(
                                                          assetLang.lastModified
                                                      ).toLocaleDateString()} ${new window.ZDate(
                                                          assetLang.lastModified
                                                      ).toLocaleString([], {
                                                          hour: "2-digit",
                                                          minute: "2-digit",
                                                      })}`
                                                    : null}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("file-size"))}:
                                                </span>{" "}
                                                {assetLang ? formatBytes(assetLang.size) : null}
                                            </p>
                                            <p className={`mb-5`}>
                                                <span className="font-bold text-gray-900 first-capital overflow-hidden">
                                                    {capitalizeFirst(t("dimensions"))}:
                                                </span>{" "}
                                                {assetLang && assetLang.dimensions
                                                    ? `${assetLang.dimensions.width} X ${assetLang.dimensions.height} `
                                                    : null}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex pl-5 mt-3">
                                    <div
                                        id={"asset-detail-make-default-button"}
                                        onClick={() => {
                                            if (isEditable) makeAsDefaultAsset(assetLang.language, assetLang.name);
                                        }}
                                        className={
                                            "inline-block " + (isEditable ? " t-link cursor-pointer" : " text-gray-500")
                                        }
                                    >
                                        <div className="first-capital">{capitalizeFirst(t("make-default"))}</div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="text-gray-200">|</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                        id={"asset-detail-replace-button"}
                                        onClick={() => {
                                            if (isEditable) replaceAsset(assetLang.language, assetLang.name);
                                        }}
                                        className={
                                            "inline-block " + (isEditable ? " t-link cursor-pointer" : " text-gray-500")
                                        }
                                    >
                                        <div className="first-capital">{capitalizeFirst(t("replace"))}</div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="text-gray-200">|</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                        onClick={() => {
                                            downloadAsset(assetLang.language);
                                        }}
                                        id={"asset-detail-download-button"}
                                        className="inline-block cursor-pointer"
                                    >
                                        <div className="t-link first-capital">{capitalizeFirst(t("download"))}</div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <span className="text-gray-200">|</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div
                                        onClick={() => {
                                            if (isEditable) deleteAsset(assetLang.language, assetLang.name);
                                        }}
                                        id={"asset-detail-delete-permanently-button"}
                                        className={
                                            "inline-block " +
                                            (isEditable ? " t-link cursor-pointer text-red-100" : " text-gray-500")
                                        }
                                    >
                                        <div className="first-capital">{capitalizeFirst(t("delete-permanently"))}</div>
                                    </div>
                                </div>
                            </>
                        );
                    } else {
                        contents.push(
                            <>
                                <div className="w-full flex">
                                    <div className="w-6/12">
                                        {isEditable ? (
                                            <>
                                                <UseFile
                                                    data={{
                                                        name: `files${languageRef}`,
                                                        value: "",
                                                        type: "file",
                                                        contentTypes: [
                                                            {
                                                                type: "image",
                                                                maxSize: 11,
                                                                multiple: false,
                                                            },
                                                        ],
                                                        ref: `media-library-update-input-media-${languageRef}`,
                                                        triggerAction: `media-library-update-media-${languageRef}`,
                                                    }}
                                                />
                                                <div
                                                    onClick={() => {
                                                        createAsset(languageRef);
                                                    }}
                                                    data-action={`media-library-update-media-${languageRef}`}
                                                ></div>
                                            </>
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        );
                    }
                });
            response.push(
                <>
                    <div className="w-full flex pl-5">
                        <div className="w-12/12 mb-5 mt-10">
                            <h1 className={`section-title first-capital mb-5`}>{capitalizeFirst(t("other-lang"))}</h1>
                        </div>
                    </div>
                    <div className="w-full pl-5">
                        <UseTabs color={`blue-600`} titles={titles} contents={contents} />
                    </div>
                </>
            );
        }
        return response;
    };
    return (
        <>
            <UpdateMediaLibrary viewMode={"assetDetail"} />
            {_renderAssetLanguageDefault(assetName)}
            {_renderAssetLanguages()}
        </>
    );
};

export default AssetDetail;
