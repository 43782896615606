import UseCheckBox from "components/Inputs/useCheckBox";
import UseSelectWithSearch from "components/useSelectWithSearch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useDispatch } from "react-redux";
import { setLoadingModalContent } from "actions/uiActions";
import { getProjectLangs, parseTranslation } from "hooks/Utils/SalesUtils";
import { useSelector } from "react-redux";
import { changeActionValues } from "actions/globalActions";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { Session } from "hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";

const UseModalAddEditStaffUser = ({ index }) => {
    const { t } = useTranslation();
    const prefixId = "modal-add-edit-staff-user-";
    const dispatch = useDispatch();

    const { projectLangs } = useSelector((state) => state.ui);
    const validations = useSelector((state) => state.action.validations);
    const { values } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { permissions } = useSelector((state) => state.ui);

    const orders = permissions?.product?.salesPremium;
    const staff = permissions?.goOutSafe?.staff;
    // const capacity = permissions?.goOutSafe?.s;
    const sales = permissions?.product?.sales;

    const [dataForm, setDataForm] = useState({ name: "", email: "", accesses: [], zones: [], shops: [] });
    const [dataSelect, setDataSelect] = useState({ shops: null, zones: null });
    const [shopsZonesNames, setShopsZonesNames] = useState({ shops: [], zones: [] });

    const GET_DATA_ZONES_SHOPS = gql`
        ${queryZonesShops(sales)}
    `;

    const [executeQuery, { data: dataZonesShops, error }] = useLazyQuery(GET_DATA_ZONES_SHOPS, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });

    useEffect(() => {
        executeQuery();
        dispatch(setLoadingModalContent(true));
    }, []);

    useEffect(() => {
        if (values) {
            setDataForm(values);
        }
    }, [values]);

    useEffect(() => {
        if (dataZonesShops?.shops || dataZonesShops?.commonzones) {
            dispatch(setLoadingModalContent(false));
            const { langDefault } = getProjectLangs(projectLangs, t);

            const shopsDataSelect = [];
            const zonesDataSelect = [];

            if (dataZonesShops?.shops?.results) {
                dataZonesShops.shops.results.forEach((item) => {
                    shopsDataSelect.push({ id: item.id, name: parseTranslation(item.name, langDefault) });
                });
            }

            if (dataZonesShops?.commonzones?.results) {
                dataZonesShops.commonzones.results.forEach((item) => {
                    zonesDataSelect.push({ id: item.id, name: item.name });
                });
            }

            setDataSelect({ shops: shopsDataSelect, zones: zonesDataSelect });
        }

        if (error) {
            dispatch(setLoadingModalContent(false));
        }
    }, [dataZonesShops, error]);

    useEffect(() => {
        dispatch(changeActionValues({ ...dataForm }));

        const arrShopsZonesNames = { shops: [], zones: [] };

        if (dataForm.shops && dataSelect.shops) {
            const filteredShopNames = dataSelect.shops
                .filter((item) => dataForm.shops.includes(item.id))
                .map((shop) => shop.name);
            arrShopsZonesNames.shops = filteredShopNames;
        }

        if (dataForm.zones && dataSelect.zones) {
            const filteredZoneNames = dataSelect.zones
                .filter((item) => dataForm.zones.includes(item.id))
                .map((zone) => zone.name);
            arrShopsZonesNames.zones = filteredZoneNames;
        }

        setShopsZonesNames(arrShopsZonesNames);
    }, [dataForm, dataSelect]);

    const getAssignmentName = () => {
        if (orders && staff) {
            return t("zones-and-shops-assigned");
        } else if (orders && !staff) {
            return t("shops-assigned");
        } else if (!orders && staff) {
            return t("zones-assigned");
        } else {
            return "";
        }
    };

    return (
        <div>
            <div>
                <span className=" block mb-2 text-gray-800 font-bold text-sm">{t("name")} *</span>
                <input
                    onChange={(e) => {
                        setDataForm({ ...dataForm, name: e.target.value });
                    }}
                    id={prefixId + "name-input"}
                    className={`rounded mb-6 w-full ${
                        validations["name"] === true ? "border border-red-100" : "border border-transparent"
                    }  bg-gray-200 px-4 py-2`}
                    type="text"
                    value={dataForm.name}
                    placeholder="Elliot Alderson"
                ></input>
            </div>

            <div>
                <span className=" block mb-2 text-gray-800 font-bold text-sm">{capitalizeFirst(t("email"))} *</span>
                <input
                    onChange={(e) => {
                        setDataForm({ ...dataForm, email: e.target.value });
                    }}
                    id={prefixId + "email-input"}
                    className={` rounded mb-6 w-full 
                        ${validations["email"] === true ? "border border-red-100" : "border border-transparent"}
                    bg-gray-200 px-4 py-2`}
                    type="text"
                    value={dataForm.email}
                    placeholder="jordan.smith@example.com"
                ></input>
            </div>

            <div>
                <span className=" font-bold text-black block mb-6">{t("accesses")} *</span>
                <div className=" flex flex-col">
                    {staff ? (
                        <UseCheckBox
                            id={prefixId + "cleaning-cb"}
                            onClick={() => {
                                updateList("accesses", 1, dataForm, setDataForm);
                            }}
                            checked={dataForm.accesses.includes(1)}
                            name={t("cleaning")}
                            error={validations["accesses"] === true ? true : false}
                        />
                    ) : null}
                    {staff ? (
                        <UseCheckBox
                            id={prefixId + "capacity-cb"}
                            onClick={() => {
                                updateList("accesses", 2, dataForm, setDataForm);
                            }}
                            checked={dataForm.accesses.includes(2)}
                            name={t("capacity")}
                            error={validations["accesses"] === true ? true : false}
                        />
                    ) : null}
                    {orders ? (
                        <UseCheckBox
                            id={prefixId + "orders-cb"}
                            onClick={() => {
                                updateList("accesses", 3, dataForm, setDataForm);
                            }}
                            checked={dataForm.accesses.includes(3)}
                            name={t("orders")}
                            error={validations["accesses"] === true ? true : false}
                        />
                    ) : null}
                </div>
            </div>

            <div className=" mt-5">
                <span className=" font-bold text-black block mb-2">{getAssignmentName()}</span>
                <span className=" block mb-6">
                    {t("select-the-zones-and-shops-that-the-user-will-be-able-to-view")}
                </span>
                <div className=" flex flex-col">
                    {staff ? (
                        <div className=" mb-6">
                            <div className=" text-gray-800 text-sm font-bold mb-2">
                                {t("zones-for-cleaning-and-capacity")}
                            </div>
                            {dataSelect?.zones ? (
                                <div>
                                    <UseSelectWithSearch
                                        data={{
                                            id: prefixId + "zones-select",
                                            name: prefixId + "zones-select",
                                            optionData: dataSelect.zones,
                                            selectPlaceHolder: t("select-an-option"),
                                            oneSelected: false,
                                            optionsAdjust: "mt-11",
                                            noSelect: true,
                                            noShowSelectedOptionsInDropDownSection: false,
                                            selectedIds: values?.zones ? values.zones : null,
                                            // nonInitialOptionData: true,
                                            tooltip: shopsZonesNames.zones.join("<br>"),
                                            disabled:
                                                dataForm?.accesses.includes(1) || dataForm?.accesses.includes(2)
                                                    ? false
                                                    : true,
                                            onChange: (val) => {
                                                updateList("zones", Number(val), dataForm, setDataForm);
                                            },
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    {orders ? (
                        <div>
                            <div className=" text-gray-800 text-sm font-bold mb-2">{t("shops-for-orders")}</div>
                            {dataSelect?.shops ? (
                                <div>
                                    <UseSelectWithSearch
                                        data={{
                                            id: prefixId + "shops-select",
                                            name: prefixId + "shops-select",
                                            optionData: dataSelect.shops,
                                            selectPlaceHolder: t("select-an-option"),
                                            oneSelected: false,
                                            optionsAdjust: "mt-11",
                                            noSelect: true,
                                            selectedIds: values?.shops ? values.shops : null,
                                            noShowSelectedOptionsInDropDownSection: false,
                                            // nonInitialOptionData: true,
                                            tooltip: shopsZonesNames.shops.join("<br>"),
                                            disabled: dataForm?.accesses.includes(3) ? false : true,
                                            onChange: (val) => {
                                                updateList("shops", Number(val), dataForm, setDataForm);
                                            },
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <ReactTooltip
                type={"light"}
                id="modal-add-edit-staff-user"
                borderColor={"#D3DAE1"}
                border={true}
                multiline={true}
                place="bottom"
            ></ReactTooltip>
        </div>
    );
};

export default UseModalAddEditStaffUser;

const queryZonesShops = (sales) => {
    return `
query {
    ${
        sales
            ? `shops(noUserPerms: true) {
        results {
            id
            name: nameTranslations {
                lang
                text
            }
        }
    }`
            : ""
    }
    commonzones(page: 1, size: 10000) {
        results {
            id
            name
            parentCategoryName
        }
    }
}`;
};

const updateList = (key, value, dataForm, setDataForm) => {
    if (dataForm[key].includes(value)) {
        const newList = dataForm[key].filter((item) => item !== value);
        setDataForm({ ...dataForm, [key]: newList });
    } else {
        const newList = [...dataForm[key], value];
        setDataForm({ ...dataForm, [key]: newList });
    }
};
