import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Components
import UseButton from "../useButton";
import UseInputText from "./useInputText";
//ACtions
import { setRefreshData } from "../../actions/tableActions";
import { showRowForm } from "../../actions/sectionActions";
import { cleanAction } from "../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { withApollo } from "react-apollo";
import { escapeSpecialChars, removeApolloCacheKeys } from "../../hooks/Utils/Utils";

const UseCZFormCategoryCreate = ({ client }) => {
    const { t } = useTranslation();
    //Store data
    const { rowFormContent } = useSelector((state) => state.table);
    const { actionData } = useSelector((state) => state.action);

    //States
    const [validationName, setValidationName] = useState(true);

    //API
    const MUTATION = gql`
        mutation {
            createCZCategory( name: {text: "${escapeSpecialChars(actionData.name)}" lang: "${Session.getSessionProp(
        "managerDefLang"
    )}"}) {
            error
            id
            ok
            }
        }`;

    const [createCategory, { data }] = useMutation(MUTATION);

    //Actions
    const dispatch = useDispatch();

    const rowFormClickHandler = (e) => {
        const actionName = e.currentTarget.dataset.action;

        if (actionName !== "save") {
            dispatch(showRowForm(false));
            dispatch(cleanAction());
        } else {
            //Validation

            const validationProcess = () => {
                let validatedName = true;
                // eslint-disable-next-line
                rowFormContent.inputs.map((input) => {
                    if (actionData[input.name] === "") {
                        validatedName = false;
                    } else {
                        validatedName = true;
                    }
                    setValidationName(validatedName);
                });
                return validatedName;
            };

            if (validationProcess()) {
                createCategory();
            } else {
                toast.error(t("enter category name"));
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (actionData && actionData.name && actionData.name !== "") {
            setValidationName(true);
        }
    }, [actionData]);

    useEffect(() => {
        const item = document.querySelector("#input_categoryName");
        if (item) {
            item.focus();
        }
    }, []);

    useEffect(() => {
        if (data && data.createCZCategory && data.createCZCategory.error === "") {
            toast.success(t("category x created", { name: actionData.name }));
            //Add to view
            dispatch(setRefreshData(true));
            dispatch(showRowForm(false));
            dispatch(cleanAction());
            removeApolloCacheKeys(client.cache, ["cz_categories", "commonzones"]);
        } else if (data && data.createCZCategory && data.createCZCategory.error !== "") {
            toast.error(t("category x not created", { name: actionData.name }));
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            {rowFormContent ? (
                <div className="w-full bg-white border-b border-gray-200 h-16 flex items-center">
                    {rowFormContent && rowFormContent.inputs
                        ? rowFormContent.inputs.map((input, index) => (
                              <div className="w-1/4 pl-4 pr-10" key={index}>
                                  {input.type === "text" ? (
                                      <UseInputText
                                          id="input_categoryName"
                                          inputData={input}
                                          validation={validationName}
                                          setValidation={setValidationName}
                                      />
                                  ) : null}
                              </div>
                          ))
                        : null}
                    <div className="inline-block w-1/4 pr-4">
                        <div className="w-full"></div>
                    </div>
                    <div className="inline-block w-1/4">
                        <div className="w-full"></div>
                    </div>
                    {rowFormContent && rowFormContent.buttons ? (
                        <div className="w-2/6 text-right whitespace-no-wrap">
                            {rowFormContent.buttons.map((button, index) => (
                                <div
                                    className={`inline-block ${
                                        index === 0 ? "pl-4 pr-2" : "pl-2 pr-4"
                                    } whitespace-normal`}
                                    data-action={button.name}
                                    key={index}
                                    onClick={rowFormClickHandler}
                                >
                                    <UseButton buttonName={button.name} buttonColor={button.color} />
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

export default withApollo(UseCZFormCategoryCreate);
