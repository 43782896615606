import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UseSelectMultipleWithSearchOptionsQuery from "../useSelectMultipleWithSearchOptionsQuery";
import UseSelect from "../Inputs/useSelect";
import { useSelector, useDispatch } from "react-redux";
import { ALARM_RECIPIENTS_TYPE, ALARM_TYPE } from "../../hooks/Utils/Customers/AlarmUtils";
import UseInputDateHour from "../useInputDateHour";
import { SessionDateToNew } from "../../date";
import { changeActionValues } from "../../actions/globalActions";

// const _roomsQuery = `
// {
//     data: hotelRooms(filter:{status:"occupied"}){
//         info{count}
//         results{id room name guests{id name}}
//       }
// }`;
const _roomsQuery = `
{
    data : occupiedRoomsInfo {
        name
        stayGuestsRoom {
            id
            stayGuest {
                name
                surname
            }
        }
    }
}`;

const _guestQuery = `
{
    data: guests(filter: {checkedOut: false}  mainGuest: true orderBy:{field:"name", criteria:"ASC"}){
        info{count}
        results{id name surname title alarmRooms:rooms{name number}}
    }
}`;

const UseModalNewAlarm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { permissions } = useSelector((state) => state.ui);
    const { values } = useSelector((state) => state.action);
    const { data, channels } = useSelector((state) => state.sectionContent);
    const [alarmRecipientType, setAlarmRecipientType] = useState(ALARM_RECIPIENTS_TYPE.ROOM);
    const [alarmType, setAlarmType] = useState(null);
    const { isFromGuestDetail, isFromRoomDetail } = values;
    const guestAccess = permissions && permissions.product && permissions.product.guest;

    useEffect(() => {
        if (values && values[ALARM_RECIPIENTS_TYPE.ID]) {
            setAlarmRecipientType(values[ALARM_RECIPIENTS_TYPE.ID]);
        }
        if (values && values[ALARM_TYPE.ID]) {
            setAlarmType(values[ALARM_TYPE.ID]);
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (isFromGuestDetail) {
            dispatch(changeActionValues({ [ALARM_RECIPIENTS_TYPE.ID]: ALARM_RECIPIENTS_TYPE.GUEST }));
            dispatch(changeActionValues({ guest: [data.guestID] }));
        } else if (isFromRoomDetail) {
            dispatch(changeActionValues({ [ALARM_RECIPIENTS_TYPE.ID]: ALARM_RECIPIENTS_TYPE.ROOM }));
            dispatch(changeActionValues({ room: [values["stayGuestRoomID"]] }));
        } else {
            dispatch(changeActionValues({ guest: [] }));
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {isFromGuestDetail || isFromRoomDetail ? null : (
                <>
                    <UseSelect
                        selectData={{
                            id: ALARM_RECIPIENTS_TYPE.ID,
                            name: ALARM_RECIPIENTS_TYPE.ID,
                            label: ALARM_RECIPIENTS_TYPE.ID,
                            data: getRecipientTypes({guestAccess, t}),
                            activeValue: ALARM_RECIPIENTS_TYPE.ROOM,
                        }}
                    />
                    <div className="mt-4"></div>
                    <div className={`${alarmRecipientType === ALARM_RECIPIENTS_TYPE.GUEST ? "hidden" : ""}`}>
                        <UseSelectMultipleWithSearchOptionsQuery
                            dataSelect={{
                                name: ALARM_RECIPIENTS_TYPE.ROOM,
                                text: ["room", "*"],
                                selectPlaceHolder: "select room",
                                optionsQuery: _roomsQuery,
                                oneSelected: true,
                                sort: true,
                                id: ALARM_RECIPIENTS_TYPE.ROOM,
                            }}
                        />
                    </div>
                    <div className={`${alarmRecipientType === ALARM_RECIPIENTS_TYPE.ROOM ? "hidden" : ""}`}>
                        <UseSelectMultipleWithSearchOptionsQuery
                            dataSelect={{
                                name: ALARM_RECIPIENTS_TYPE.GUEST,
                                text: ["guest", "*"],
                                selectPlaceHolder: "select guest",
                                optionsQuery: _guestQuery,
                                oneSelected: true,
                                id: ALARM_RECIPIENTS_TYPE.GUEST,
                            }}
                        />
                    </div>
                </>
            )}

            <div className="mt-4">{t("{{capitalize, capitalize}}", { capitalize: t("set-alarm") })} *</div>
            <UseInputDateHour
                data={{
                    name: "",
                    date: new window.ZDate().toLocaleDateString("en-Gb", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }),
                    hour: new window.ZDate().toLocaleTimeString("es-ES", {
                        hour: "2-digit",
                        minute: "2-digit",
                    }),
                    minDate: SessionDateToNew(),
                    id: "modal-new-alarm",
                }}
            />
            <div className="mt-4"></div>
            <UseSelect
                selectData={{
                    id: ALARM_TYPE.ID,
                    placeHolder: "select-alarm-type",
                    label: ["type", "*"],
                    data: [
                        { value: ALARM_TYPE.BEEP, text: t("Beep") },
                        { value: ALARM_TYPE.TVCHANNEL, text: t("tv-channel") },
                    ],
                }}
            />
            <div className="mt-4"></div>
            <div className={`${alarmType === ALARM_TYPE.TVCHANNEL ? "" : "hidden"}`}>
                <UseSelectMultipleWithSearchOptionsQuery
                    dataSelect={{
                        name: ALARM_TYPE.TVCHANNEL,
                        text: ["tv-channel", "*"],
                        selectPlaceHolder: "select-channel",
                        optionData: channels
                            ? channels.sort((a, b) => String(a.name).localeCompare(String(b.name)))
                            : [],
                        oneSelected: true,
                        id: ALARM_TYPE.TVCHANNEL,
                    }}
                />
            </div>
        </>
    );
};

const getRecipientTypes = (props = {}) => {
    const {guestAccess = false, t} = props;
    const response = [
        { value: ALARM_RECIPIENTS_TYPE.ROOM, text: t("room") }
    ];
    if(guestAccess){
        response.push({ value: ALARM_RECIPIENTS_TYPE.GUEST, text: t(ALARM_RECIPIENTS_TYPE.GUEST) })
    }
    return response;
}

export default UseModalNewAlarm;
