import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData } from "../../Utils/Integrations/useVendure";
import _ from "lodash";

const UpdateTaxZones = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const dispatch = useDispatch();

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        let addedTaxCategoriesIndexes;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            let taxes = null;
            switch (executeQuery.action) {
                case "delete-tax-zone":
                    taxes = executeQuery.params ? executeQuery.params.value : null;
                    mutation = "mutation { ";
                    if (taxes && taxes.length > 0) {
                        // eslint-disable-next-line
                        taxes.map((tax, index) => {
                            if (tax.id && tax.id > 0) {
                                mutation += `
                                    taxRate${index}: deleteTaxRate(id:"${tax.id}"){
                                        result
                                        message
                                    }
                                    taxCategory${index}: deleteTaxCategory(id:"${tax.category.id}"){
                                        result
                                        message
                                    }
                                `;
                            }
                        });
                    }
                    mutation += `
                        deleteZone(id:"${actionData.itemsAffected[0]}"){
                            result
                            message
                        }
                    `;
                    mutation += "}";
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "deleteZone";
                    break;
                case "add-tax-zone":
                    mutation = `
                        mutation{
                            createZone(input:{
                                name:"${actionData.values["name"]}"
                            }){
                                id
                            }
                        }
                    `;
                    executeAnotherQuery = {
                        action: "edit-tax-zone",
                        useResponsePrevQueryData: {
                            name: "id",
                            path: "createZone.id",
                        },
                        extraParams: null,
                    };
                    executeQuery.closeModal = false;
                    executeQuery.mutationName = "createZone";
                    break;
                case "edit-tax-zone":
                    taxes = actionData.values["taxes"];
                    const taxZoneId =
                        executeQuery.params && executeQuery.params.id
                            ? executeQuery.params.id
                            : actionData.itemsAffected[0];
                    addedTaxCategoriesIndexes = [];
                    mutation = "mutation { ";
                    mutation += `
                        updateZone(input:{
                            id:"${taxZoneId}",
                            name:"${actionData.values["name"]}"
                        }){
                            id
                        }
                    `;
                    if (taxes && taxes.length > 0) {
                        taxes.forEach((tax, index) => {
                            if (tax.enabled) {
                                if (tax.id && tax.id > 0) {
                                    mutation += `
                                        taxCategory${index}: updateTaxCategory(input:{
                                            id:"${tax.categoryId}"
                                            name:"${tax.name}"
                                        }){
                                            id
                                        }
                                        taxRate${index}: updateTaxRate(input:{
                                            id:"${tax.id}"
                                            name:"${tax.name}"
                                            value:${tax.value}
                                        }){
                                            id
                                        }
                                    `;
                                } else {
                                    mutation += `
                                        taxCategory${index}: createTaxCategory(input:{
                                            name:"${tax.name}"
                                        }){
                                            id
                                        }
                                    `;
                                    addedTaxCategoriesIndexes.push(index);
                                }
                            } else {
                                if (tax.id && tax.id > 0) {
                                    mutation += `
                                        taxRate${index}: deleteTaxRate(id:"${tax.id}"){
                                            result
                                            message
                                        }
                                        taxCategory${index}: deleteTaxCategory(id:"${tax.categoryId}"){
                                            result
                                            message
                                        }
                                    `;
                                }
                            }
                        });
                    }
                    mutation += "}";
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "updateZone";
                    if (addedTaxCategoriesIndexes && addedTaxCategoriesIndexes.length > 0) {
                        executeQuery.closeModal = false;
                        executeAnotherQuery = {
                            action: "create-tax-rates",
                            useResponsePrevQueryData: {
                                name: "taxCategoriesAdded",
                            },
                            extraParams: {
                                id: taxZoneId,
                                addedTaxCategoriesIndexes: addedTaxCategoriesIndexes,
                            },
                        };
                    }
                    break;
                case "create-tax-rates":
                    taxes = actionData.values["taxes"];
                    addedTaxCategoriesIndexes = executeQuery.params.addedTaxCategoriesIndexes;
                    const taxCategoriesAdded = executeQuery.params.taxCategoriesAdded;
                    mutation = "mutation { ";
                    if (taxCategoriesAdded && addedTaxCategoriesIndexes && addedTaxCategoriesIndexes.length > 0) {
                        addedTaxCategoriesIndexes.forEach((addedTaxCategoryIndex) => {
                            const categoryAdded = _.get(taxCategoriesAdded, `taxCategory${addedTaxCategoryIndex}`);
                            const tax = taxes[addedTaxCategoryIndex];
                            if (categoryAdded) {
                                mutation += `
                                    taxRate${addedTaxCategoryIndex}: createTaxRate(input:{
                                        name:"${tax.name}"
                                        value:${tax.value}
                                        categoryId:"${categoryAdded.id}"
                                        zoneId:"${executeQuery.params.id}"
                                        enabled:true
                                    }){
                                        id
                                    }
                                `;
                            }
                        });
                    }
                    mutation += "}";
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "createTaxRate";
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(mutation, executeAnotherQuery);
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (mutation, executeAnotherQuery) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: mutation });
        if (response && response.data && !response.data.errors) {
            if (executeQuery.mutationName) {
                const mutationObject = response.data[executeQuery.mutationName];
                if (mutationObject && mutationObject.result && mutationObject.result.toUpperCase().includes("NOT")) {
                    errorMutation = true;
                }
            }
        } else {
            errorMutation = true;
        }
        if (!errorMutation) {
            if (executeAnotherQuery) {
                const params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    const param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = !param.path
                        ? response.data
                        : Array.isArray(param.path)
                        ? getMultiple(response.data, param.path, null)
                        : _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            } else {
                toast.success(t("operation-successful"));
                dispatch(setExecuteQuery(null));
                dispatch(cleanAction());
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
                if (executeQuery.cacheKeyToDelete) {
                    removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
                }
            }
        } else {
            dispatch(setExecuteQuery(null));
            dispatch(setLoading(false));
            toast.error(t("mutation-error"));
        }
    };

    return null;
};

const getMultiple = (obj, paths, defaultValue) => {
    const values = paths.map((path) => _.get(obj, path)).filter((v) => !_.isUndefined(v));

    return values.length ? values : [defaultValue];
};

export default withApollo(UpdateTaxZones);
