import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData, cleanCheckedItems } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
// import _ from "lodash";
import uuid from "react-uuid";
import { Session } from "../../Utils/Session";
import { arrangeToastMessagesUploadingFiles, arrangeToastMessagesWhenFailsBatchActions } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";

const UpdateShop = ({ client }) => {
    const { t } = useTranslation();
    // const { id } = useParams();
    const dispatch = useDispatch();
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const { checkedItems } = useSelector((state) => state.table);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const jsonTitlesFailsActionsBatch = {
        "delete-shops-batch": "error-deleting-items-failed",
        "set-as-available-shops-batch": "error-failure-items-available",
        "set-as-unavailable-shops-batch": "error-failure-items-unavailable",
    };

    const vendureShops = Session.getSessionProp("vendure-shops");
    const [shops, setShops] = useState("");

    useEffect(() => {
        if (vendureShops) {
            setShops(JSON.parse(vendureShops));
        }
    }, [vendureShops]);

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let mutationFile = false;
        let executeAnotherQuery = null;
        let multipleMutation = false;
        let mutationName = null;
        let avoidToast = false;
        let aditionalHeader = null;
        let avoidRefresh = false;
        const titlesFailsActionsBatch = jsonTitlesFailsActionsBatch[executeQuery?.action];
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            execute = true;

            switch (executeQuery.action) {
                case "set-as-unavailable-shop":
                    mutation = ` 
                        mutation{
                            updateChannel(input:{
                            id:${Number(actionData.itemsAffected[0])},
                                customFields:{
                                is_active: false
                                }
                            }){
                                __typename
                            }
                        }
                    `;
                    mutationName = "updateChannel";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-available-shop":
                    mutation = ` 
                        mutation{
                            updateChannel(input:{
                            id:${Number(actionData.itemsAffected[0])},
                                customFields:{
                                is_active: true
                                }
                            }){
                                __typename
                            }
                        }
                    `;
                    mutationName = "updateChannel";
                    executeQuery.closeModal = true;
                    break;
                case "add-shop":
                    //TODO DefaultLanguageCode
                    const tokenShop = uuid();
                    mutation = ` 
                    mutation{
                        createChannel(input:{
                          code:"${
                              actionData["actionData"] && actionData["actionData"]["add-shop-name"]
                                  ? actionData["actionData"]["add-shop-name"]
                                  : ""
                          }",
                          token:"${tokenShop}"
                          defaultLanguageCode:en,
                          pricesIncludeTax:false,
                          currencyCode:${actionData["values"]["add-shop-currency"]}
                          defaultTaxZoneId:${actionData["values"]["add-shop-tax-default"]}
                          defaultShippingZoneId:${actionData["values"]["add-shop-tax-default"]}
                          customFields: {
                            default_taxId: ${
                                actionData && actionData["values"] && actionData["values"]["add-shop-tax-rate-selected"]
                                    ? actionData["values"]["add-shop-tax-rate-selected"]
                                    : null
                            }
                            default_tax_categoryId: ${
                                actionData && actionData["values"] && actionData["values"]["add-shop-tax-category"]
                                    ? actionData["values"]["add-shop-tax-category"]
                                    : null
                            }
                          }
                        }){
                          __typename
                        }
                      } 
                    `;
                    executeAnotherQuery = { action: "create-default-shipping-method", params: { tokenShop } };
                    mutationName = "createChannel";
                    executeQuery.closeModal = true;
                    break;
                case "edit-shop":
                    //TODO DefaultLanguageCode
                    mutation = `
                      mutation {
                        updateChannel(
                          input: { 
                            id: ${actionData["values"]["id-shop"]}, 
                            code: "${
                                actionData["actionData"] && actionData["actionData"]["add-shop-name"]
                                    ? actionData["actionData"]["add-shop-name"]
                                    : ""
                            }" ,
                          defaultLanguageCode:en,
                          currencyCode:${actionData["values"]["add-shop-currency"]}
                          defaultTaxZoneId:${actionData["values"]["add-shop-taxes-zone"]}
                          defaultShippingZoneId:${actionData["values"]["add-shop-taxes-zone"]}
                          customFields: {
                            default_taxId: ${
                                actionData && actionData["values"] && actionData["values"]["add-shop-tax-rate-selected"]
                                    ? actionData["values"]["add-shop-tax-rate-selected"]
                                    : null
                            }
                          }
                        }
                        ) {
                          __typename
                        }
                      }
                      `;
                    mutationName = "updateChannel";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-available-shops-batch":
                    multipleMutation = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutation.arr.push({
                            id: item,
                            mutation: `mutation{
                                updateChannel(input:{
                                id:${Number(item)},
                                    customFields:{
                                    is_active: true
                                    }
                                }){
                                    __typename
                                }
                            }`,
                        });
                    });
                    mutationName = "updateChannel";
                    executeQuery.closeModal = true;
                    break;
                case "set-as-unavailable-shops-batch":
                    multipleMutation = {
                        arr: [],
                    };
                    checkedItems.forEach((item) => {
                        multipleMutation.arr.push({
                            id: item,
                            mutation: `mutation{
                            updateChannel(input:{
                            id:${Number(item)},
                                customFields:{
                                is_active: false
                                }
                            }){
                                __typename
                            }
                        }`,
                        });
                    });
                    mutationName = "updateChannel";
                    executeQuery.closeModal = true;
                    break;
                case "set-state-shop":
                    mutation = `mutation{
                        updateChannel(input:{
                        id:${Number(Number(executeQuery.params.id))},
                            customFields:{
                            is_active: ${executeQuery.params.active}
                            }
                        }){
                            __typename
                        }
                    }`;
                    mutationName = "updateChannel";
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(
                    mutation,
                    executeAnotherQuery,
                    multipleMutation,
                    mutationFile,
                    mutationName,
                    avoidToast,
                    avoidRefresh,
                    aditionalHeader,
                    titlesFailsActionsBatch
                );
            }, 1000);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (
        mutation,
        executeAnotherQuery,
        multipleMutations,
        mutationFile,
        mutationName,
        avoidToast,
        avoidRefresh,
        aditionalHeader,
        titlesFailsActionsBatch
    ) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }

        let errorMutation = false;
        let toastId = "";
        let files = null;

        if (mutationFile) {
            files = [
                {
                    name: mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: mutationFile.file.size,
                },
            ];
        }

        if (multipleMutations && multipleMutations.arr && multipleMutations.arr.length > 0) {
            const successIds = [];
            const sucessResponse = [];
            const errorsIds = [];
            const errorsResponse = [];
            const errorsJsonStores = [];
            const deleteAction = mutationName === "deleteChannel";
            const shopsDeleted = "next-shops-have-been-deleted";
            const shopsNotDeleted = "next-shops-have-not-been-deleted";
            const shopsModified = "next-shops-have-been-modified-successfully";
            const shopsNotModified = "next-shops-have-not-been-modified-successfully";

            await Promise.all(
                multipleMutations.arr.map(async (item) => {
                    const res = await executeVendureQuery(getVendureApiData(), { queryBody: item.mutation }); // Send request for each id

                    if (res && res.data && !res.data.errors) {
                        successIds.push(item.id);
                    }

                    if (res && res.data && res.data.errors) {
                        errorsIds.push(item.id);
                    }
                })
            );

            if (successIds.length > 0) {
                successIds.forEach((id) => {
                    shops.forEach((element) => {
                        if (element.id === id) {
                            sucessResponse.push(element.name);
                        }
                    });
                });
                toast.success(
                    <div className=" flex flex-col">
                        <span>{capitalizeFirst(t(deleteAction ? shopsDeleted : shopsModified))}:</span>
                        {sucessResponse.map((item) => (
                            <span>&#x2022; {item}</span>
                        ))}
                    </div>
                );
            }

            if (errorsIds.length > 0) {
                errorsIds.forEach((errorId) => {
                    shops.forEach((element) => {
                        if (element.id === errorId) {
                            errorsResponse.push(element.name);
                            errorsJsonStores.push({
                                name: element.name,
                                storeId: errorId,
                            });
                        }
                    });
                });

                if (titlesFailsActionsBatch) {
                    let msgs = arrangeToastMessagesWhenFailsBatchActions(errorsJsonStores);
                    toast.warning(<UseToast title={t(titlesFailsActionsBatch)} msgs={msgs} minimize={false} />, {
                        autoClose: 3500,
                        className: "use-toast fail-products-delete",
                        closeButton: true,
                        closeOnClick: false,
                        draggable: false,
                        hideProgressBar: true,
                        icon: false,
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                } else {
                    toast.error(
                        <div className=" flex flex-col">
                            <span>{capitalizeFirst(t(deleteAction ? shopsNotDeleted : shopsNotModified))}:</span>
                            {errorsResponse.map((item) => (
                                <span>&#x2022; {item}</span>
                            ))}
                        </div>
                    );
                }
            }
            dispatch(setRefreshData(true));
            dispatch(cleanCheckedItems());
            dispatch(setLoading(false));
        } else {
            let response = "";
            if (!mutationFile) {
                response = await executeVendureQuery(
                    getVendureApiData(),
                    { queryBody: mutation },
                    typeof aditionalHeader === "object" ? aditionalHeader : {}
                );
                if (response && response.data && !response.data.errors) {
                    if (mutationName) {
                        let mutationObject = response.data[mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (executeAnotherQuery) {
                            executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject };
                            dispatch(
                                setExecuteQuery({
                                    action: executeAnotherQuery.action,
                                    params: executeAnotherQuery.params,
                                })
                            );
                        }
                    }
                } else {
                    errorMutation = true;
                }
            } else {
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: false,
                    className: "use-toast",
                    closeButton: false,
                    closeOnClick: false,
                    draggable: false,
                    hideProgressBar: true,
                    icon: false,
                });

                response = await uploadVendureMutation(getVendureApiData(), {
                    queryBody: mutation,
                    file: mutationFile.file,
                });

                if (response && response.data && !response.data.errors) {
                    if (mutationFile.mutationName) {
                        let mutationObject = response.data[mutationFile.mutationName];
                        if (
                            mutationObject &&
                            mutationObject.result &&
                            mutationObject.result.toUpperCase().includes("NOT")
                        ) {
                            errorMutation = true;
                        } else if (
                            mutationObject instanceof Array &&
                            mutationObject.length > 0 &&
                            executeAnotherQuery
                        ) {
                            executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject[0] };
                        }
                    }
                } else {
                    errorMutation = true;
                }
            }
        }
        if (!errorMutation && !multipleMutations) {
            if (mutationFile && !errorMutation) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });

                if (executeAnotherQuery) {
                    dispatch(
                        setExecuteQuery({
                            action: executeAnotherQuery.action,
                            params: executeAnotherQuery.params,
                        })
                    );
                }
            } else if (!avoidToast) {
                toast.success(t("operation-successful"));
            }
            if (!avoidRefresh) {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        } else if (!multipleMutations) {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

export default withApollo(UpdateShop);
