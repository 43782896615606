import { useEffect } from "react";
import { Session } from "hooks/Utils/Session";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

const useFeedbackSettings = () => {
    const projectData = JSON.parse(Session.getSessionProp("currentHotel")) || {};
    const projectId = projectData?.id || null;

    const GET_FEEDBACK_SETTINGS = gql`
        query GetFeedbackSettings($projectID: Int64!, $lang: LanguageCode) {
            getFeedbackSetting(projectID: $projectID, lang: $lang) {
                feedbackEmails
                users {
                    id
                }
                minLevelSendEmail
                satisfactionQuestionTID
                satisfactionQuestion
                sendEmail
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_FEEDBACK_SETTINGS, {
        variables: { projectID: projectId, lang: "all" },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (projectId) {
            executeQuery();
        }
    }, []);

    return {
        data,
        loading,
        error,
        refetch,
    };
};

export default useFeedbackSettings;
